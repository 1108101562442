<div *ngIf="selectedOffer" class="container overflow-auto">
  <!-- DESKTOP -->
  <div class="offer-tiles-wrapper gap-3 p-3 mb-35 mx-auto d-none d-md-block d-md-flex">
    <div
      *ngFor="let offer of offers; trackBy: trackById"
      floAnalytics
      class="offer-tile"
      tagName="Radio Button"
      (click)="updateSelectedOffer(offer)"
    >
      <flo-offer-selection-card
        [offer]="offer"
        [isSelected]="offer.id === selectedOffer.id"
      >
      </flo-offer-selection-card>
    </div>
  </div>

  <!-- MOBILE -->
  <div class="offers-mobile-wrapper d-md-none">
    <div class="offers-tiles-wrapper flex-column">
      <div
        *ngFor="let offer of offers; trackBy: trackById"
        floAnalytics
        tagName="Radio Button"
        (click)="updateSelectedOffer(offer)"
      >
        <flo-offer-selection-card
          [offer]="offer"
          [isSelected]="offer.id === selectedOffer.id"
        >
        </flo-offer-selection-card>
      </div>
    </div>
  </div>
</div>
