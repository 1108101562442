import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { ReplaySubject } from 'rxjs'
import { startWith, shareReplay } from 'rxjs/operators'

@Component({
  selector: 'flo-unmute-button',
  templateUrl: './unmute-button.component.html',
  styleUrls: ['./unmute-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnMuteButtonComponent {
  private readonly isMutedSubject = new ReplaySubject<boolean>(1)
  public readonly isMuted$ = this.isMutedSubject.pipe(startWith(false))
  private readonly audioAvailableSubject = new ReplaySubject<boolean>(1)
  public readonly audioAvailable$ = this.audioAvailableSubject.pipe(startWith(true), shareReplay(1))

  @Input()
  set isMuted(isMuted: boolean) {
    this.isMutedSubject.next(isMuted)
  }

  @Input()
  set audioAvailable(audioAvailable: boolean) {
    this.audioAvailableSubject.next(audioAvailable)
  }

  @Output()
  readonly muteToggleClicked = new EventEmitter<any>()

  public muteClicked(e: Event): void {
    this.muteToggleClicked.emit()
  }
}
