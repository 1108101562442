import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { GrapplingResultCardModel } from '@flocasts/experience-service-types'

@Component({
  selector: 'flo-grappling-result-card',
  templateUrl: './grappling-result-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GrapplingResultCardComponent {
  @Input()
  public readonly result: GrapplingResultCardModel
}
