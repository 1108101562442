import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { LinkModel } from '@flocasts/experience-service-types'
import { trackById } from 'src/app/shared/utility-functions/track-by-id.utility'

/**
 * Render a dropdown menu containing a list of BFF LinkModels
 * Will be styled the same way as the standard dropdown menu
 */
@Component({
  selector: 'flo-link-menu',
  templateUrl: './link-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkMenuComponent {
  @Input()
  id: string

  /**
   * The dropdown options
   */
  @Input()
  options: Array<LinkModel> = []

  // Track by to reduce DOM repainting as new options are dynamically loaded
  readonly trackById = trackById
}
