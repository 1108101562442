import { LinkModel } from '@flocasts/experience-service-types'

/*
 * Given the links to render on desktop, and the amount of available space to render them in
 * Determine how many links we're able to render. The remainder will be placed into the overflow ellipsis dropdown
 */
export const getVisibleLinkCount = (primaryLinksDesktop: LinkModel[], primaryLinksContainerWidth: number): number => {
  const CHARACTER_WIDTH = 9
  const LINK_PADDING = 24
  const primaryLinkTitles = primaryLinksDesktop.map(link => link.title)
  // A character is about 9px wide
  // We also need to account for spacing between the links, which is about 2 * 12px padding
  const primaryLinkWidths = primaryLinkTitles
    .map(title => title?.length ?? 0)
    .map(charCount => charCount * CHARACTER_WIDTH + LINK_PADDING)

  // Iteratively accumulate the total space required by the links
  // Once it exceeds the available space in the container, abort and return the number of links we were able to fit
  // If that never happens, then we can fit them all
  let sum = 0
  for (let i = 0; i < primaryLinkWidths.length; i++) {
    sum += primaryLinkWidths[i]
    if (sum > primaryLinksContainerWidth) {
      return i - 1
    }
  }
  return primaryLinkTitles.length
}
