import { PlatformService } from '../../singleton-services/platform.service'
import { Subscription } from 'rxjs'
import { Directive, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core'
import { getScrollListener } from './scroll-listener'
import { lazyLoadImage } from './lazyload-image'
import { makeStateKey, TransferState } from '@angular/platform-browser'
import { startWith } from 'rxjs/operators'

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[lazyLoad]'
})
export class LazyLoadImageDirective implements OnDestroy, OnInit {
  @Input()
  lazyLoad: string // The image to be lazy loaded
  @Input()
  defaultImage: string // The image to be displayed before lazyImage is loaded
  @Input()
  errorImage: string // The image to be displayed if lazyImage load fails
  @Input()
  scrollTarget = this.ps.isBrowser ? window : undefined
  @Input()
  scrollObservable: any // Pass your own scroll emitter
  @Input()
  offset: number // The number of px a image should be loaded before it is in view port
  @Output()
  // tslint:disable:no-output-native
  readonly load = new EventEmitter<boolean>() // Callback when an image is loaded
  scrollSubscription: Subscription

  constructor(
    private elementRef: ElementRef,
    private ngZone: NgZone,
    private ps: PlatformService,
    private renderer: Renderer2,
    private transferState: TransferState
  ) {}

  ngOnInit() {
    const cacheKey = makeStateKey<number>(`LazyImage-${this.lazyLoad}`)
    if (this.ps.isServer) {
      this.renderer.setProperty(this.elementRef.nativeElement, 'src', this.lazyLoad)
      this.transferState.set(cacheKey, 1)
    } else {
      if (this.transferState.get(cacheKey, undefined)) {
        this.elementRef.nativeElement.src = this.lazyLoad
      } else {
        this.ngZone.runOutsideAngular(() => {
          if (this.scrollObservable) {
            this.scrollSubscription = this.scrollObservable
              .pipe(
                startWith(''),
                lazyLoadImage(
                  this.elementRef.nativeElement,
                  this.lazyLoad,
                  this.defaultImage,
                  this.errorImage,
                  this.offset
                )
              )
              .subscribe((success: any) => {
                this.load.emit(success)
              })
          } else {
            const scrollListener = getScrollListener(this.scrollTarget)
            if (!scrollListener) return
            this.scrollSubscription = scrollListener
              .pipe(
                lazyLoadImage(
                  this.elementRef.nativeElement,
                  this.lazyLoad,
                  this.defaultImage,
                  this.errorImage,
                  this.offset
                )
              )
              .subscribe(success => {
                this.load.emit(success)
              })
          }
        })
      }
    }
  }

  ngOnDestroy() {
    const subs: ReadonlyArray<Subscription> = [this.scrollSubscription]
    subs.filter(subscription => subscription).forEach(subscription => subscription.unsubscribe())
  }
}
