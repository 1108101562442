import { Component } from '@angular/core'

@Component({
  selector: 'flo-horizontal-buttons-skeleton',
  template: `
    <div class="d-flex flex-row-reverse align-self-stretch">
      <h1 *ngFor="let number of [0, 1]" class="me-35 h1-skeleton" style="min-width:150px;height:36px"></h1>
    </div>
  `
})
export class HorizontalButtonsSkeletonComponent {}
