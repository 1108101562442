import { VerticalService } from '../../singleton-services/vertical.service'
import { iif, Observable } from 'rxjs'
import { Injectable, Injector, Inject, PLATFORM_ID } from '@angular/core'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { take, switchMap } from 'rxjs/operators'
import { isPlatformBrowser } from '@angular/common'
import { API_REQUESTS_THAT_NEED_SITE_ID_QUERY_PARAM } from 'src/app/app.config'

@Injectable()
export class HttpVerticalInterceptor implements HttpInterceptor {
  constructor(private injector: Injector, @Inject(PLATFORM_ID) private platformId: string) {}
  private getSiteApiUrl(requestUrl: string, fallbackUrl: string, siteHost?: string, siteId?: number): string {
    if (!siteHost || !siteHost?.startsWith('http')) return fallbackUrl
    const url = new URL(`/api/${requestUrl}`, siteHost)

    if (!!siteId && requestRequiresSiteId(requestUrl)) {
      url.searchParams.append('site_id', String(siteId))
    }
    return String(url)
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return (
      (req.url.includes('http') && next.handle(req)) ||
      this.injector.get(VerticalService).siteSettings$.pipe(
        take(1),
        switchMap(ss => {
          const isBrowser = isPlatformBrowser(this.platformId)
          const host = isBrowser ? '' : `http://127.0.0.1:${process.env.PORT}`
          const url = `${host}/${req.url.replace('./', '')}`
          return iif(
            () => req.url.includes('./'),
            next.handle(req.clone({ url })),
            next.handle(req.clone({ url: this.getSiteApiUrl(req.url, url, ss.api_url, ss.site_id) }))
          )
        })
      )
    )
  }
}

/**
 * Asserts whether the inbound request contains an endpoint that requires SiteId
 */
export function requestRequiresSiteId(request: string): boolean {
  return API_REQUESTS_THAT_NEED_SITE_ID_QUERY_PARAM.some(endpoint => request.includes(endpoint))
}
