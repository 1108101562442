import { LiveEventsService } from '../../singleton-services/live-events.service'
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { catchError, take } from 'rxjs/operators'
import { LiveEvent } from '@flocasts/flosports30-types/dist/entity'
import { getLiveIdFromRedirectQueryParam } from 'src/app/funnel/navigation/navigation.utility'

@Injectable()
export class LiveQueryParamResolver implements Resolve<LiveEvent | undefined> {
  constructor(private les: LiveEventsService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LiveEvent | undefined> {
    const redirect = route.queryParams.redirect
    const liveId = redirect && getLiveIdFromRedirectQueryParam(redirect)

    if (liveId) {
      return this.les.getLiveEvent(liveId).pipe(
        take(1),
        catchError(() => {
          return of(undefined)
        })
      )
    }

    return of(undefined)
  }
}
