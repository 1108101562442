import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

import { PaywallComponent } from './paywall.component'

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [PaywallComponent],
  exports: [PaywallComponent]
})
export class PaywallModule {}
