<div class="batman-wrapper" *ngIf="landingPageProps">
  <div class="hero-wrapper batman-theme" *ngIf="partialRender.hero">
    <flo-funnel-checkout-header
      [showDarkLogo]="false"
      [showPrompt]="landingPageProps.showLoginPrompt"
      [analyticsProps]="analyticsProps"
    ></flo-funnel-checkout-header>
    <flo-batman-hero [landingPageProps]="landingPageProps" [analyticsProps]="analyticsProps">
    </flo-batman-hero>
  </div>
  <div
    *ngIf="partialRender.calendar && (headline$ | async) as headline"
    class="batman-container calendar-container"
    data-test="paywall-calendar-container"
    [ngClass]="{ 'is-generic-funnel': !headline.isLiveFunnel }"
  >
    <h2 *ngIf="headline.isLiveFunnel" class="batman-header">{{headline?.text}}</h2>
    <flo-landing-batman-calendar [siteId]="landingPageProps.siteSettings.site_id"> </flo-landing-batman-calendar>
  </div>
  <div *ngIf="partialRender.carousel" class="batman-container features-wrapper">
    <flo-batman-features
      [carouselBackgroundGen]="carouselBackgroundGen"
      [carouselData]="carouselData"
      [showCarousel]="showCarousel"
      [siteId]="landingPageProps.siteSettings.site_id"
    ></flo-batman-features>
  </div>
  <div
    *ngIf="partialRender.devices"
    class="batman-lower-container devices-container"
    data-test="checkout-devices-container"
  >
    <h2 class="batman-header funnel-title">Watch anytime, on any device.</h2>
    <p class="batman-subheader funnel-subheader">
      Watch on any web browser or the FloSports app — available on the following
      platforms.
    </p>
    <flo-offered-devices></flo-offered-devices>
  </div>
  <div
    *ngIf="partialRender.faq"
    class="batman-lower-container faq-container"
    data-test="checkout-faq-container"
  >
    <h2 class="batman-header funnel-title">Questions?</h2>
    <p class="batman-subheader funnel-subheader"
      >If you still need help after reading the FAQs, please
      <a
        floAnalytics
        name="Customer Support Contact Form"
        [analyticsProperties]="analyticsProps"
        href="{{ CUSTOMER_SERVICE_URL }}"
        target="_blank"
        >contact us</a
      >.</p
    >
    <flo-faq [settings]="landingPageProps.siteSettings"></flo-faq>
  </div>
</div>
