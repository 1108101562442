import { Observable } from 'rxjs'
import { Injectable } from '@angular/core'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http'
import { PlatformService } from '../../singleton-services/platform.service'
import { tap } from 'rxjs/operators'
import { getHeadersMap } from './http-transfer-state.utility'
import { CookieService } from 'src/app/singleton-services/cookie.service'
import { API_FASTLY_CUSTOM_HEADERS } from 'src/app/app.config'

@Injectable()
export class HttpApiCustomResponseHeadersInterceptor implements HttpInterceptor {
  constructor(private platformService: PlatformService, private cookieService: CookieService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          const headers = getHeadersMap(event.headers)

          API_FASTLY_CUSTOM_HEADERS.forEach(header => {
            if (headers[header]) {
              const cookieValue = this.cookieService.get(header)

              if (!cookieValue) {
                const headerValue = headers[header]
                this.cookieService.set(header, headerValue[0])
              } else {
                const headerValue = headers[header]
                if (headerValue[0] !== cookieValue) {
                  this.cookieService.remove(header)
                  this.cookieService.set(header, headerValue[0])
                }
              }
            }
          })
        }
      })
    )
  }
}
