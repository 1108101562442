import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { distinctUntilChanged, shareReplay, map, take } from 'rxjs/operators'
import { ActivatedRoute } from '@angular/router'

export interface IFloDebugService {
  readonly debugOn$: Observable<boolean>
  readonly debugOff$: Observable<boolean>
  toggle(): void
}

@Injectable()
export class FloDebugService implements IFloDebugService {
  constructor(private readonly ar: ActivatedRoute) {
    this.ar.queryParams
      .pipe(
        map(a => !!a.flotest || !!a.flosite),
        distinctUntilChanged(),
        shareReplay(1)
      )
      .subscribe(val => this.source.next(val))
  }
  private readonly source = new BehaviorSubject<boolean>(false)
  private readonly debugDisplayStatus$ = this.source.pipe(distinctUntilChanged(), shareReplay(1))

  public readonly debugOn$ = this.debugDisplayStatus$.pipe(map(val => val === true))
  public readonly debugOff$ = this.debugDisplayStatus$.pipe(map(val => val === false))

  public toggle() {
    this.source.next(!this.source.getValue())
  }
}
