<div class="prompt">
  <p *ngIf="headerText" class="prompt-text">{{ headerText }}</p>
  <button
    floAnalytics
    [analyticsProperties]="analyticsProps"
    name="Show More"
    class="btn-chevron"
    (click)="emitClick()"
    >
    <span class="primary-color-text" aria-hidden="true">
      <flo-svg-icon [width]="20" icon="icon-chevron-down"></flo-svg-icon>
    </span>
  </button>
</div>
