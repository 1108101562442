import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

export function leaguesModule() {
  return import('./leagues/leagues.module').then(m => m.LeaguesModule)
}

export function searchModule() {
  return import('./search/search.module').then(m => m.SearchModule)
}

export function videosModule() {
  return import('./videos/videos.module').then(m => m.VideosModule)
}

export function videoEmbedModule() {
  return import('./video-embed/video-embed.module').then(m => m.VideoEmbedModule)
}

export function accountModule() {
  return import('./account-settings/account-settings.module').then(m => m.AccountSettingsModule)
}

export function changeOfferModule() {
  return import('./change-offer/change-offer.module').then(m => m.ChangeOfferModule)
}

export function resetPasswordModule() {
  return import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule)
}

export function RedeemModule() {
  return import('./redeem/redeem.module').then(m => m.RedeemModule)
}

export function claimAccountModule() {
  return import('./claim-account/claim-account.module').then(m => m.ClaimAccountModule)
}

export function articlesModule() {
  return import('./articles/articles.module').then(m => m.ArticlesModule)
}

export function collectionsModule() {
  return import('./collections/collections.module').then(m => m.CollectionsModule)
}

export function eventsModule() {
  return import('./events/events.module').then(m => m.EventsModule)
}

export function rankingsModule() {
  return import('./rankings/rankings.module').then(m => m.RankingsModule)
}

export function standingsModule() {
  return import('./standings/standings.module').then(m => m.StandingsModule)
}

export function resultsModule() {
  return import('./results/results.module').then(m => m.ResultsModule)
}

export function trainingModule() {
  return import('./training/training.module').then(m => m.TrainingModule)
}

export function flofilmsModule() {
  return import('./films/films.module').then(m => m.FloFilmsModule)
}

export function athletesModule() {
  return import('./athletes/arena-wrestling/arena-athletes.module').then(m => m.ArenaAthletesModule)
}

export function teamsModule() {
  return import('./teams/teams.module').then(m => m.TeamsModule)
}

export function liveBetaModule() {
  return import('./live/live.module').then(m => m.LiveModule)
}

export function manifestModule() {
  return import('./manifest/manifest.module').then(m => m.ManifestModule)
}

export function watchModule() {
  return import('./watch/watch.module').then(m => m.WatchModule)
}

export function favoritesModule() {
  return import('./favorites/favorites.module').then(m => m.FavoritesModule)
}

export function peopleModule() {
  return import('./people/people.module').then(m => m.PeopleModule)
}

export function giftSubscriptionModule() {
  return import('./gift-subscription/gift-subscription.module').then(m => m.GiftSubscriptionModule)
}

export function sportsModule() {
  return import('./sports/sports.module').then(m => m.SportsModule)
}

export function conferencesModule() {
  return import('./conferences/conferences.module').then(m => m.ConferencesModule)
}

export const routes: Routes = [
  {
    path: 'leagues',
    loadChildren: leaguesModule
  },
  {
    path: 'search',
    loadChildren: searchModule
  },
  {
    path: 'live',
    loadChildren: liveBetaModule
  },
  {
    path: 'video',
    loadChildren: videosModule
  },
  {
    path: 'embed/:embedHash',
    loadChildren: videoEmbedModule
  },
  {
    path: 'account',
    loadChildren: accountModule
  },
  {
    path: 'account/choose-favorites',
    loadChildren: favoritesModule
  },
  // This redirection was added here (instead of account-settings-routing.module.ts) because there is an issue with the left rail
  {
    path: 'upgrade',
    redirectTo: 'account/change-offer'
  },
  {
    path: 'account/change-offer',
    loadChildren: changeOfferModule
  },
  {
    path: 'reset-password',
    loadChildren: resetPasswordModule
  },
  {
    path: 'forgot-password',
    loadChildren: resetPasswordModule
  },
  {
    path: 'redeem',
    loadChildren: RedeemModule
  },
  {
    path: 'claim-account',
    loadChildren: claimAccountModule
  },
  {
    path: 'articles',
    loadChildren: articlesModule
  },
  {
    path: 'collections',
    loadChildren: collectionsModule
  },
  {
    path: 'events',
    loadChildren: eventsModule
  },
  {
    path: 'athletes',
    loadChildren: athletesModule
  },
  {
    path: 'teams',
    loadChildren: teamsModule
  },
  {
    path: 'rankings',
    loadChildren: rankingsModule
  },
  {
    path: 'standings',
    loadChildren: standingsModule
  },
  {
    path: 'results',
    loadChildren: resultsModule
  },
  {
    path: 'training',
    loadChildren: trainingModule
  },
  {
    path: 'films',
    loadChildren: flofilmsModule
  },
  {
    path: 'manifest.json',
    loadChildren: manifestModule
  },
  {
    path: 'replays',
    redirectTo: 'events',
    pathMatch: 'full'
  },
  {
    path: 'watch',
    loadChildren: watchModule
  },
  {
    path: 'people',
    loadChildren: peopleModule
  },
  {
    path: 'gift',
    loadChildren: giftSubscriptionModule
  },
  {
    path: 'home',
    redirectTo: '/'
  },
  {
    path: 'sports',
    loadChildren: sportsModule
  },
  {
    path: 'conferences',
    loadChildren: conferencesModule
  }
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'disabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
