import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SegmentedControlComponent } from './segmented-control.component'
import { AnalyticsModule } from '../../shared/analytics/analytics.module'
import { AnalyticsDirective } from '../../../testing/mock-analytics.directive'
import { CustomLinkModule } from '../custom-link/custom-link.directive.module'

@NgModule({
  declarations: [SegmentedControlComponent, AnalyticsDirective],
  exports: [SegmentedControlComponent],
  imports: [CommonModule, AnalyticsModule, CustomLinkModule]
})
export class SegmentedControlModule {}
