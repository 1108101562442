import { Injectable } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { map, skip, take, tap } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  public isDarkTheme$ = this.activatedRoute.queryParamMap.pipe(
    map(params => params.get('theme') === 'dark'),
    // This is a BehaviorSubject, so the initial value pushed to the sequence is actually an empty object.
    // So the first emission is always false, even when ?theme=true is present.
    // Therefore, we skip the first and always empty value.
    skip(1),
    // We only want to take the first emission after the skip
    // because we want to put the user into theme mobile web view mode
    // despite the query param possibly being stripped out by future client-side navigation.
    take(1)
  )

  constructor(private readonly activatedRoute: ActivatedRoute) {}
}
