import { AdSizes } from './advertising-service/ad-config'
import { SegmentTrackDisplayAds } from '../interfaces/segment-track-display-ads.interface'
import { SlotRenderEndedEvent } from './advertising-service/gpt.service'
import { TrackPageDetails } from '../analytics/services/segment.service'

export const removeLeadingSlash = (path: string): string => {
  return path.replace(/^\//, '')
}

export const AD_SKIN_SIZE: AdSizes = [
  [1600, 1400],
  [2000, 1400]
]
export const AD_LEADERBOARD_LARGE_FLEX_SIZE: AdSizes = [
  [728, 90],
  [970, 250],
  [970, 90]
]

export const AD_LEADERBOARD_LARGE_SIZE: AdSizes = [
  [728, 90],
  [970, 90]
]

export const AD_LEADERBOARD_SMALL_SIZE: AdSizes = [[728, 90]]

export const AD_RECTANGLE_LARGE_SIZE: AdSizes = [
  [300, 250],
  [300, 600]
]

export const AD_RECTANGLE_SMALL_SIZE: AdSizes = [[300, 250]]

export const AD_RECTANGLE_FLEX_SIZE: AdSizes = [
  [300, 250],
  [320, 50]
]

export const AD_ADHESION_SIZE: AdSizes = [[320, 50]]

export const AD_INTERSTITIAL_SIZE: AdSizes = [[1, 2]]

/**
 * Build out Segment, Ad Viewed track call
 */
export const createTrackDisplayAdProperties = (
  slotRender: SlotRenderEndedEvent,
  analyticsTracking: TrackPageDetails | undefined,
  isRefreshable: boolean
): SegmentTrackDisplayAds => {
  const adPath = slotRender.slot.getAdUnitPath()
  return {
    ad_type: 'display',
    name: adPath.endsWith('atf') ? 'skin' : 'display',
    ad_path: adPath,
    ad_size: slotRender.size,
    advertiser_id: slotRender.advertiserId,
    advertiser_creative_id: slotRender.creativeId,
    campaign_id: slotRender.campaignId,
    is_empty: slotRender.isEmpty,
    line_item_id: slotRender.lineItemId,
    tracking: analyticsTracking,
    ad_call_id: slotRender.slot.getEscapedQemQueryId(),
    viewable: isRefreshable
  }
}
export const MILESPLIT_SITE_NAME = 'Milesplit'
export const MILESPLIT_AD_PATH = 'MileSplit'
/**
 * This takes the site name and converts it to the site code for GPT pubads.
 *
 * @example
 * assert.strictEqual(
 *   buildSiteCode('flo dogs site'),
 *   'flodogssite.3'
 * )
 *
 * @see {@link https://developers.google.com/doubleclick-gpt/guides/get-started GPT Docs - Get Started}
 */
export const buildSiteCode = (siteName: string) => {
  /**
   * MS in the ad server is built out entirely different than Flo site paths unfortunately.
   * Everything today for MS needs to live under “MileSplit” top level, not milesplit.3
   * There is discussion that we update MS to align closer with flo paths,
   * but this would be an entire site overhaul -Brandt Best 09/21
   */
  if (siteName === MILESPLIT_SITE_NAME) {
    return MILESPLIT_AD_PATH
  } else {
    return `${siteName.replace(/\s+/g, '').toLowerCase()}.3`
  }
}

export const DFP_CUSTOM_PARAMS_KEY = 'cust_params'

export const addUrlParamsToDFPUrl = (url: string, urlParams: Record<string, unknown>) => {
  const parsedUrl = new URL(url)
  const existingCustParam = parsedUrl.searchParams.get(DFP_CUSTOM_PARAMS_KEY)
  Object.entries(urlParams).forEach(param => {
    if (existingCustParam && param[0] === DFP_CUSTOM_PARAMS_KEY) {
      parsedUrl.searchParams.set(param[0], `${existingCustParam}&${param[1]}`)
    } else {
      parsedUrl.searchParams.set(param[0], `${param[1]}`)
    }
  })
  return parsedUrl.toString()
}
