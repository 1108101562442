<a
  *ngIf="link"
  data-test="flo-link"
  class="link-wrapper text-nowrap"
  [ngClass]="link.style | floCssClassSanitize"
  floAnalytics
  [analyticsProperties]="link.analytics"
  [tagName]="tagName"
  [floCustomLink]="link.url"
  [customLinkQueryParams]="queryParams"
  (click)="handleClick(link)"
  tabIndex="-1"
>
  <button
    [ngClass]="link.state === 'selected' ? 'selected' : ''"
    class="link-button">
    <ng-container *ngIf="!!link.asset; else noAsset">
      <ng-container *ngIf="link.asset | floIsIcon">
        <flo-svg-icon
          [ngClass]="link.asset.type === 'icon' ? link.asset?.style : '' | floCssClassSanitize"
          [icon]="link.asset.style"
          data-test="link-icon"
        >
        </flo-svg-icon>
        <span *ngIf="link.title" class="link-text" data-test="icon-link-title">{{link.title}}</span>
        <span *ngIf="!link.title && link.asset?.text" class="link-text" data-test="icon-asset-text">{{link.asset?.text}}</span>
        <span *ngIf="link.secondaryAsset | floIsIcon" class="secondary-icon-container">
          <flo-svg-icon
          [icon]="link.secondaryAsset.style"
          data-test="link-icon-secondary"
          width="1.45em"
          height="1.45em"
          >
          </flo-svg-icon>
        </span>
      </ng-container>
      <ng-container *ngIf="link.asset | floIsImage">
        <flo-image
          style="height: 24px; width: 24px;"
          class="object-fit-cover"
          *ngIf="link.asset | floIsImage"
          [image]="link.asset"
          data-test="link-image"
        >
        </flo-image>
        <span *ngIf="link.title" class="link-text" data-test="image-link-title">{{link.title}}</span>
      </ng-container>
    </ng-container>

    <ng-template #noAsset>
      <span *ngIf="link.title" class="link-text">{{link.title}}</span>
    </ng-template>
  </button>
</a>
