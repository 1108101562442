import { HttpClient } from '@angular/common/http'
import { UntypedFormControl, ValidatorFn } from '@angular/forms'
import { Inject, Injectable } from '@angular/core'
import { debounceTime, flatMap, map } from 'rxjs/operators'
import { of, Scheduler } from 'rxjs'
import {
  CORE_API_USERS_CHECK_EMAIL_EXISTS_PATH,
  CORE_API_USERS_CHECK_USERNAME_EXISTS_PATH,
  RXJS_SCHEDULER
} from 'src/app/app.config'

// tslint:disable:no-null-keyword
@Injectable()
export class DuplicateAsyncValidators {
  public static readonly DEBOUNCE_TIME_SECONDS = 1000

  constructor(private http: HttpClient, @Inject(RXJS_SCHEDULER) private scheduler: Scheduler) {}

  debounce = <T>() => debounceTime<T>(DuplicateAsyncValidators.DEBOUNCE_TIME_SECONDS, this.scheduler)

  // TODO: Add :ValidatorFn to methods
  // TODO: this method and validateEmailDoesNotExist should probably be combined
  public validateEmailExists(excludeDisabledUser = false) {
    return (control: UntypedFormControl) => {
      if (control && control.value) {
        const url = `users/check-email-exists/${control.value}?excludeDisabledUser=${excludeDisabledUser ? 1 : 0}`
        return this.http
          .get<{
            exists: boolean
            unclaimedAccount: boolean
            migratedSite: string
          }>(url)
          .pipe(
            this.debounce(),
            map(email => {
              if (email.unclaimedAccount) {
                return { unclaimed_account: { customText: email.migratedSite } }
              } else if (!email.exists) {
                return { email_does_not_exist: true }
              }
              return null
            })
          )
      }
      return of(null)
    }
  }

  // TODO: write tests around new values
  // TODO: this method and validateEmailExists should probably be combined
  public validateEmailDoesNotExist(excludeDisabledUser = false) {
    return (control: UntypedFormControl) => {
      if (control && control.value) {
        const url = `users/check-email-exists/${control.value}?excludeDisabledUser=${excludeDisabledUser ? 1 : 0}`
        return of(control.value).pipe(
          this.debounce(),
          flatMap(() =>
            this.http.get<{
              exists: boolean
              unclaimedAccount: boolean
              migratedSite: string
            }>(url)
          ),
          map(email => {
            if (email.unclaimedAccount) {
              return { unclaimed_account: { customText: email.migratedSite } }
            } else if (email.exists) {
              return { email_exists: true }
            }
            return null
          })
        )
      }
      return of(null)
    }
  }

  public validateUsernameDoesNotExist() {
    return (control: UntypedFormControl) => {
      if (control && control.value) {
        return of(control.value).pipe(
          this.debounce(),
          flatMap(username =>
            this.http.get<{ exists: boolean }>(`${CORE_API_USERS_CHECK_USERNAME_EXISTS_PATH}/${username}`)
          ),
          map(username => {
            if (username.exists) {
              return { username_exists: true }
            }
            return null
          })
        )
      }
      return of(null)
    }
  }

  // TODO: Write tests around this
  public validateUnclaimedAccountExists(): ValidatorFn {
    return (control: UntypedFormControl) => {
      if (control && control.value) {
        const url = `${CORE_API_USERS_CHECK_EMAIL_EXISTS_PATH}/${control.value}?excludeDisabledUser=0`
        return of(control.value).pipe(
          this.debounce(),
          flatMap(() =>
            this.http.get<{
              exists: boolean
              unclaimedAccount: boolean
              migratedSite: string
            }>(url)
          ),
          map(email => {
            return email.unclaimedAccount
              ? {
                  unclaimed_account: { customText: email.migratedSite }
                }
              : null
          })
        )
      }
      return of(null)
    }
  }
}
