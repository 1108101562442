import { Pipe, PipeTransform } from '@angular/core'
import { HeadlineDetailPartsModel } from '@flocasts/experience-service-types'
import { getSubtitleWithRelativeDate } from '../../shared/utility-functions/string.utility'

@Pipe({ name: 'headlineFooter' })
export class HeadlineFooterPipe implements PipeTransform {
  public transform(footer: HeadlineDetailPartsModel): string {
    const baseText = getSubtitleWithRelativeDate(footer.displayDate, footer.isPremium) // TODO: Can add user.isPremium
    const durationText = !!footer.formattedVideoDuration ? ` · ${footer.formattedVideoDuration}` : ''

    return `${baseText}${durationText}`
  }
}
