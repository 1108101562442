import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input } from '@angular/core'
import { HTMLViewWithAnchorPointsModel } from '@flocasts/experience-service-types'
import { trackById } from 'src/app/shared/utility-functions/track-by-id.utility'

@Component({
  selector: 'flo-html-view',
  templateUrl: './html-view.component.html',
  styles: [
    `
      .btn.cta-scroll-to-top {
        position: fixed;
        bottom: 12%;
        left: 50%;
        border-radius: 25px;
        box-shadow: 1px 1px 10px #0003;
        transform: translate(-50%, 50%);
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HtmlViewComponent {
  @Input() htmlView: HTMLViewWithAnchorPointsModel
  isButtonVisible = false

  constructor(private el: ElementRef, private cdr: ChangeDetectorRef) {}

  public trackById = trackById

  // Determines when to display the scroll to top button
  // Displays when the user listens to when the user scrolls through the cms. Determined by the offset.
  handleScroll(isScrolledPastElement: boolean) {
    this.isButtonVisible = isScrolledPastElement
  }

  // autoscroll when user clicks on the anchor tag or scroll to top button
  scroll(id: number | string) {
    const el: HTMLElement = this.el.nativeElement.querySelector(`[id='${id}']`)

    if (el) {
      const elementPosition = el.getBoundingClientRect().top + window.scrollY
      window.scrollTo({ top: elementPosition - 50, behavior: 'smooth' }) // subtract the navbar height
    }
  }
}
