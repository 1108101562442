import { AuthService } from '../../singleton-services/auth/auth.service'
import { Inject, Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { combineLatest, Observable, of } from 'rxjs'
import { tap, switchMap } from 'rxjs/operators'
import { CheckoutNavigationService } from '../services/checkout-navigation.service'
import { VerticalService } from '../../singleton-services/vertical.service'
import { PlatformService } from '../../singleton-services/platform.service'
import { HttpParams } from '@angular/common/http'
import { WINDOW } from '../../app.injections'
import { buildMileSplitJoinURL } from '../services/external-routing.utility'
import { AccountService } from 'src/app/singleton-services/account.service'
import { StripeSubscriptionStatus } from '../subscriptions/subscriptions.common'

export function buildMileSplitRedirectURL(redirect: string): string {
  let params = new HttpParams()
  params = params.set('next', `https://accounts.milesplit.com${redirect}`)

  if (redirect.length > 0) {
    return `https://www.milesplit.com/join?${params.toString()}`
  }
  return 'https://www.milesplit.com/join'
}

@Injectable()
export class LoginSignupGuard implements CanActivate {
  constructor(
    private readonly authService: AuthService,
    private readonly vs: VerticalService,
    private readonly checkoutNavService: CheckoutNavigationService,
    @Inject(WINDOW) window: unknown,
    private readonly ps: PlatformService,
    private readonly router: Router,
    private accountService: AccountService
  ) {}

  private redirectMileSplitUsers(nextPath: string, redirect: string): void {
    const baseURL = nextPath ? buildMileSplitJoinURL(nextPath.toLowerCase()) : 'https://www.milesplit.com/join'

    const destination = redirect ? buildMileSplitRedirectURL(redirect) : buildMileSplitJoinURL(baseURL)

    if (this.ps.isBrowser) {
      window.location.href = destination
    } else {
      this.router.navigateByUrl(destination)
    }
  }

  canActivate(ars: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // TODO: FLO-10749 -- Look into removing isMileSplit$ from this logic
    return combineLatest([
      this.authService.userIdentity$,
      this.vs.isMileSplit$,
      this.vs.isTrackWrestling$,
      this.authService.isPremium$,
      this.accountService.activeSubscription$
    ]).pipe(
      switchMap(([user, isMileSplit, isTrackWrestling, isPremium, activeSubscription]) => {
        const queryParams = ars.queryParams
        const nextPath = queryParams && queryParams.next
        const redirect = queryParams && queryParams.redirect

        if (!user) {
          if (isMileSplit && state.url.includes('signup')) {
            return of(false).pipe(
              tap(() => {
                this.redirectMileSplitUsers(nextPath, redirect)
              })
            )
          }

          if (isTrackWrestling && state.url.includes('login')) {
            return of(false).pipe(
              tap(() => {
                this.checkoutNavService.redirectTrackWrestlingUsersForLogin()
              })
            )
          }

          return of(true)
        }

        if (isPremium) {
          return of(false).pipe(tap(() => this.checkoutNavService.navigateToFinalDestination(queryParams)))
        }

        if (!isPremium) {
          // Allow free users in new funnel experience to see the benefits of signing up
          if (state.url.includes('signup')) {
            if (!isMileSplit) {
              return of(true)
            }

            return of(false).pipe(
              tap(() => {
                this.redirectMileSplitUsers(nextPath, redirect)
              })
            )
          }

          return of(false).pipe(
            tap(() =>
              this.checkoutNavService.navigateAfterLogin(!!user, !activeSubscription, queryParams, ars.data.liveEvent)
            )
          )
        }

        return of(true)
      })
    )
  }
}
