import {
  ANON_REQUEST_PATTERNS,
  AUTH_BEARER_HOSTS,
  HttpJWTInterceptor,
  AUTH_HEADERS_NO_SEND
} from './services/http-authorization-interceptor.service'
import { HttpXFloFlagsAllInterceptor } from './services/http-x-flo-flags-all-interceptor.service'
import { StripeSubscription } from './models/subscription.model'
import { MediumContentComponent } from './medium-content/medium-content.component'
import { FacebookAccessTokenService } from './services/facebook-access-token.service'
import { DuplicateAsyncValidators } from './validators/duplicate-async.validators'
import { FormService } from './services/form.service'
import { LiveEventResolver } from './services/live-event.resolver'
import { LiveQueryParamResolver } from './services/live-query-param.resolver'
import { VideoService } from './services/video.service'
import { SectionHeaderComponent } from './section-header/section-header.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { FloPipeModule } from './pipes/flo-pipe.module'
import { SmallContentComponent } from './small-content/small-content.component'
import { DynamicStyleService } from './services/dynamic-style.service'
import { LazyLoadImageModule } from './lazy-image/lazyload-image.module'
import { AdsModule } from './ads/ads.module'
import { MatchValidator } from './validators/match.validator'
import { ServerResponseService } from './services/server-response.service'
import { SpotlightComponent } from './spotlight/spotlight.component'
import { CommonModule } from '@angular/common'
import { ModuleWithProviders, NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { FooterService } from './footer/footer.service'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { PASSTHROUGH_DOMAINS } from '../singleton-services/url.service'
import { HttpVerticalInterceptor } from './services/http-vertical-interceptor.service'
import { FooterComponent } from './footer/footer.component'
import { HttpGlobalHeaderInterceptor } from './services/http-global-header-interceptor.service'
import { HttpNotFoundInterceptor, NOT_FOUND_REDIRECT_IGNORES } from './services/http-not-found-interceptor.service'
import { HttpForbiddenInterceptorService } from './services/http-forbidden-interceptor.service'
import {
  HttpApiExperimentViewedInterceptor,
  HTTP_API_EXPERIMENT_INCLUDED_URLS
} from './services/http-api-experiment-viewed-interceptor'
import { DynamicFaviconService } from './services/dynamic-favicon.service'
import { NodeService } from './services/node.service'
import { NewsletterWidgetComponent } from './newsletter-widget/newsletter-widget.component'
import { ElementInViewportDirective } from './directives/element-in-viewport.directive'
import { MinifierService } from './services/css-minifier.service'
import { VideoPlayerModule } from './video-player/video-player.module'
import { DEFAULT_HTTP_TIMEOUT, HttpTimeoutInterceptor } from './services/http-timeout-interceptor.service'
import { VideoGridComponent } from './video-grid/video-grid.component'
import { SearchInputComponent } from './search-input/search-input.component'
import { ListImageComponent } from './list-image/list-image.component'
import {
  IAnalyticsData,
  IAuthServiceConfig,
  ITokenSchema,
  IUserIdentity
} from '../singleton-services/auth/auth.interfaces'
import { DynamicContentDirective } from './directives/dynamic-content.directive'
import { FormErrorsService } from './services/form-errors.service'
import { CarouselComponent } from './carousel/carousel.component'
import { ImageOverlayComponent } from './image-overlay/image-overlay.component'
import { ClaimAccountErrorComponent } from './claim-account-error.component'
import { CouponService } from './services/coupon.service'
import { AnalyticsModule } from './analytics/analytics.module'
import { FloFullscreenModule } from '@flosportsinc/ng-fullscreen'
import { GeoIPModule } from './geo-ip/geo-ip.module'
import { FloActivateExperimentOnClickDirective } from './experimentation/activate-experiment-on-click.directive'
export { StripeSubscription }
import { CtaFooterComponent } from './footer/cta-footer/cta-footer.component'
import { FloSelectInputModule } from './select-input/select-input.module'
import { FormErrorsModule } from './form-errors/form-errors.module'
import { ThumbnailModule } from './thumbnails/thumbnail.module'
import { TabsModule } from '../libs/common/tabs/tabs.module'
import { AUTH_CONFIG } from '../singleton-services/auth/auth.tokens'
import { HttpStabilityInterceptor } from 'src/app/libs/common/http-stability.interceptor'
import { DraftQueryParamsInterceptor } from '../libs/common/draft-query-params.interceptor'
import { FloModalModule } from './modal/modal.module'
import { LazyLoadModule } from '../../../projects/lazy-load/src/lib/lazy-load.module'
import { TooltipWhenEllipsisModule } from '../libs/tooltip-when-ellipsis/tooltip-when-ellipsis.directive.module'
import { MapPipeModule } from '../libs/map-pipe/map-pipe.module'
import { FullscreenDetectorModule } from '../../../projects/fullscreen-detector/src/lib/fullscreen-detector.module'
import { IsoStringToDatePipeModule } from '../libs/iso-string-to-date-pipe/iso-string-to-date-pipe.module'
import { OsanoService } from './services/osano.service'
import { SearchInputNextGenComponent } from './search-input-next-gen/search-input.component'
import { OldSvgModule } from '../libs/_deprecated/svg/old-svg.module'
import { SvgModule } from './svg/svg.module'
import { FloHlsModule } from '../hls/hls.module'
import { NavIdQueryParamInterceptor } from '../libs/common/navId-query-param.interceptor'
import { GiftService } from './services/gift.service'
import { RedeemValidator } from './validators/redeem.validator'
import { EmailPaymentsValidator } from './validators/email-payments.validator'
import { HttpApiCustomResponseHeadersInterceptor } from './services/http-api-custom-response-headers-interceptor'

export const authConfg: IAuthServiceConfig = {
  authTokenStorageKey: 'jwt_token',
  authTokenRefreshStorageKey: 'jwt_refresh_token',
  authTokenPayloadKey: 'token',
  tokenSchema: {
    analyticsData: 'analytics_data',
    id: 'id',
    email: 'email',
    username: 'username',
    firstName: 'firstName',
    lastName: 'lastName',
    roles: 'roles',
    roleDelimeter: ',',
    adminRoleNames: ['ROLE_ADMIN'],
    adFreeRoleNames: ['ROLE_AD_FREE'],
    subscriptionStatus: 'subscription_status'
  },
  userFactory: (tokenJson: any, schema: ITokenSchema): IUserIdentity => {
    if (!tokenJson) throw new Error('')
    if (!schema) throw new Error('')

    // tslint:disable-next-line:readonly-array
    const roles = (tokenJson[schema.roles] as string[]) || []
    const roleSet = new Set<string>()

    Array.isArray(roles) ? roles.forEach(role => roleSet.add(role)) : roleSet.add(roles)

    const user: IUserIdentity = {
      claims: tokenJson,
      analyticsData: tokenJson[schema.analyticsData] as IAnalyticsData,
      id: tokenJson[schema.id] as string,
      username: tokenJson[schema.username] as string,
      email: tokenJson[schema.email] as string,
      roles: roleSet,
      tokenHash: JSON.stringify(tokenJson),
      subscriptionStatus: tokenJson[schema.subscriptionStatus] as string,
      isInRole(name: string) {
        return roleSet.has(name)
      },
      isAdmin() {
        return schema.adminRoleNames.some(role => roleSet.has(role))
      },
      isPremium() {
        return tokenJson.isPremiumSubscriber
      },
      isAdFree() {
        return schema.adFreeRoleNames.some(role => roleSet.has(role))
      }
    }

    return user
  }
}

// force Auth Bearer token usage if pattern matches
export function authBearerOverrides() {
  return [
    /live-api-3.flosports.tv/,
    /dev-live-api-3.flosports.tv/,
    /floarena-api.flowrestling.org/,
    /identity-api.flosports.tv/,
    /staging-floarena-api.flowrestling.org/,
    /staging-identity-api.flosports.tv/,
    /staging-live-api-3.flosports.tv/,
    /staging-api.*.*\/api\/cards|users|subscription|surveys/,
    /staging-api.flosports.tv/,
    /statx.stag.flokubernetes.com/,
    /statx.flokubernetes.com/,
    /user-preferences.stag.flokubernetes.com/,
    /user-preferences.flokubernetes.com/,
    /experiences\/web\/watch/,
    /experiences\/web\/home/,
    /experiences\/web\/entity-hub/,
    /experiences\/web\/schedule\/tab/,
    /experiences\/web\/event-hub/,
    /experiences\/web\/partials\/navigation/,
    /experiences\/web\/partials\/navigation\/flyout/,
    /experiences\/web\/partials\/offers/,
    /experiences\/web\/partials\/event-ticker/,
    /entitlements.*\.flokubernetes\.com/,
    /payments.*\.flokubernetes\.com/,
    /continue-watching.flokubernetes.com/,
    /continue-watching.stag.flokubernetes.com/,
    /offers.stag-aws.flokubernetes.com/,
    /offers.aws.flokubernetes.com/
  ]
}

export function noAuthHeaderPatterns() {
  return [/nodes\/*.*\/views/gi, /player-log-service/gi]
}

@NgModule({
  imports: [
    MapPipeModule,
    TabsModule,
    FloHlsModule,
    VideoPlayerModule,
    OldSvgModule,
    SvgModule,
    FloFullscreenModule,
    AnalyticsModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    LazyLoadImageModule,
    FloPipeModule,
    AdsModule,
    GeoIPModule,
    FloSelectInputModule,
    FormErrorsModule,
    ThumbnailModule,
    FloModalModule,
    LazyLoadModule,
    TooltipWhenEllipsisModule,
    FullscreenDetectorModule,
    IsoStringToDatePipeModule
  ],
  declarations: [
    ImageOverlayComponent,
    CarouselComponent,
    FooterComponent,
    CtaFooterComponent,
    SpotlightComponent,
    SmallContentComponent,
    MediumContentComponent,
    SectionHeaderComponent,
    NewsletterWidgetComponent,
    ElementInViewportDirective,
    VideoGridComponent,
    SearchInputComponent,
    SearchInputNextGenComponent,
    DynamicContentDirective,
    ListImageComponent,
    ClaimAccountErrorComponent,
    FloActivateExperimentOnClickDirective
  ],
  exports: [
    MapPipeModule,
    FloFullscreenModule,
    FloHlsModule,
    AnalyticsModule,
    FloModalModule,
    OldSvgModule,
    SvgModule,
    ImageOverlayComponent,
    CarouselComponent,
    CommonModule,
    RouterModule,
    FloPipeModule,
    FormsModule,
    ReactiveFormsModule,
    LazyLoadImageModule,
    AdsModule,
    FooterComponent,
    CtaFooterComponent,
    SpotlightComponent,
    SmallContentComponent,
    MediumContentComponent,
    SectionHeaderComponent,
    NewsletterWidgetComponent,
    ElementInViewportDirective,
    VideoGridComponent,
    SearchInputComponent,
    SearchInputNextGenComponent,
    DynamicContentDirective,
    ListImageComponent,
    ClaimAccountErrorComponent,
    GeoIPModule,
    FloActivateExperimentOnClickDirective,
    FloSelectInputModule,
    FormErrorsModule,
    LazyLoadModule,
    FullscreenDetectorModule,
    ThumbnailModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpStabilityInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DraftQueryParamsInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NavIdQueryParamInterceptor,
      multi: true
    },
    { provide: AUTH_CONFIG, useValue: authConfg },
    { provide: DEFAULT_HTTP_TIMEOUT, useValue: 20000 },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTimeoutInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpVerticalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpXFloFlagsAllInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpGlobalHeaderInterceptor,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpJWTInterceptor, multi: true },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpNotFoundInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpForbiddenInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpApiExperimentViewedInterceptor,
      multi: true
    },
    {
      provide: PASSTHROUGH_DOMAINS,
      useValue: ['localhost', 'flokubernetes', 'ngrok', '192.168', '127.0.0.1']
    },
    { provide: AUTH_BEARER_HOSTS, useFactory: authBearerOverrides },
    { provide: AUTH_HEADERS_NO_SEND, useFactory: noAuthHeaderPatterns },
    {
      provide: ANON_REQUEST_PATTERNS,
      useValue: {
        '*': ['assets/', 'logos/', '.svg', '.js', '.json']
      }
    },
    {
      provide: NOT_FOUND_REDIRECT_IGNORES,
      useValue: [
        /live-api/,
        /claim-account/,
        /coupons/,
        /svg/,
        /api\/live-events/,
        /legacy-core\/live-events/,
        /api\/events/,
        /legacy-core\/events/,
        /extras\/schedule\.html/,
        /nodes\/\d+\/related/,
        /videos\/\d+\/geo/,
        /experiences\/web\/event-hub/,
        /grappling\/entities/, // statx
        /category-selections/, // user-prefs
        /categories\/externalId/, // user-prefs
        /user-customizations/, // user-prefs
        /subscriptions/, // new payments infrastructure
        /api\/products/, // Core API products / plans
        /consolidated/, // Ignore 404s for Live Player right rail
        /gift-subscription\/[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}\/validate/, // gift subscription
        /users\/omalley-beneficiary\/([\s\S]*)$/ // O'Malley User Check, 404s if user is not eligible
      ]
    },
    {
      provide: HTTP_API_EXPERIMENT_INCLUDED_URLS,
      useValue: []
    },
    FooterService,
    ServerResponseService,
    VideoService,
    LiveEventResolver,
    LiveQueryParamResolver,
    FormService,
    FormErrorsService,
    DuplicateAsyncValidators,
    DynamicStyleService,
    DynamicFaviconService,
    FacebookAccessTokenService,
    NodeService,
    MatchValidator,
    MinifierService,
    CouponService,
    OsanoService,
    GiftService,
    RedeemValidator,
    EmailPaymentsValidator
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule
    }
  }
}
