import { GrowthBook, Attributes, Context } from '@growthbook/growthbook'
import { Injectable } from '@angular/core'
import { SegmentService } from '../../analytics/services/segment.service'
import { LocationService } from 'src/app/singleton-services/location.service'
import { combineLatest, from, map, Observable, startWith } from 'rxjs'
import { CookieService } from 'src/app/singleton-services/cookie.service'
import { TransferState } from '@angular/platform-browser'
import { EnvironmentService } from 'src/app/singleton-services/environment.service'
import { FeatureKeys, GrowthBookService, GrowthBookTransferStateKey } from './growthbook.utility'
import { SegmentEvents } from '../../analytics/models/segment-events.model'

/**
 * Service to wrap the GrowthBook SDK
 */
@Injectable({
  providedIn: 'root'
})
export class GrowthBookBrowserService implements GrowthBookService {
  constructor(
    private readonly env: EnvironmentService,
    private readonly ts: TransferState,
    private readonly segment: SegmentService,
    private readonly cookie: CookieService,
    private readonly location: LocationService
  ) {}

  private growthBook = new GrowthBook({
    apiHost: this.env.config.growthBook.apiHost,
    clientKey: this.env.config.growthBook.clientKey,
    enableDevMode: this.env.config.growthBook.enableDevMode,
    subscribeToChanges: this.env.config.growthBook.subscribeToChanges,
    backgroundSync: this.env.config.growthBook.backgroundSync,
    trackingCallback: (experiment, result) => {
      this.segment.track(SegmentEvents.EXPERIMENT_VIEWED, {
        experimentId: experiment.key,
        experimentName: experiment.name,
        variationId: result.key,
        experimentSource: 'Web App',
        nonInteraction: 1,
        visitor_id: this.cookie.visitorId,
        // For backwards compat with BI reporting, set segment tag to new visitor id
        anonymous_id: this.cookie.visitorId
      })
    }
  })

  /**
   * This is the magic that makes Growthbook work without a SSR flash.
   * We take the growthbook that was initialized on the server and start this stream with it.
   * Then once the client loads the growthbook features,
   * we replace the server growthbook with the client growthbook.
   */
  private gbServer = new GrowthBook(this.ts.get(GrowthBookTransferStateKey, {} as Context))
  private gb$ = from(this.growthBook.loadFeatures())
    .pipe(map(_ => this.growthBook))
    // Note: This startWith operator MUST come last or there will be a slight flash.
    .pipe(startWith(this.gbServer))

  public isFeatureOn(featureKey: FeatureKeys): Observable<boolean> {
    return combineLatest([this.gb$, this.setAttributes$]).pipe(
      map(([gb, _]) => {
        if (gb?.isOn(featureKey)) {
          return true
        }
        return false
      })
    )
  }

  /**
   * Must update attributes before calling isOn() for most accurate bucketing.
   * We should only map attributes that are available on both server and client as the same value.
   */
  private setAttributes$ = combineLatest([this.gb$, this.segment.supplementalData$]).pipe(
    map(([gb, data]) => {
      const attributes: Attributes = {
        visitorId: this.cookie.visitorId,
        userId: data.userIdentity?.id,
        loggedIn: !!data.userIdentity,
        employee: data.userIdentity?.email?.endsWith('@flosports.tv'),
        url: this.location.href,
        path: this.location.pathname,
        email: data.userIdentity?.email,
        siteId: data.site_id,
        vertical: data.vertical,
        siteName: data.site_name,
        liveEventStatus: data.live_event_status,
        liveId: data.live_id,
        company: 'flosports'
        /**
         * In a follow up PR, set country with fastly header value
         * country
         * In a follow up PR, imply from user agent, if avail on server
         * browser, deviceType
         */
      }
      gb?.setAttributes(attributes)
    })
  )
}
