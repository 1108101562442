<div class="modal-wrapper" *ngIf="active">
  <div
    class="overlay-backdrop"
    [class.active]="active"
    (click)="closeModal()"
  ></div>
  <div class="overlay-wrapper">
    <div class="dialog-container" [style.width.px]="width">
      <span class="close-icon">
        <i
          floSvg="close_x_mark"
          class="icon d-flex justify-content-center color-400"
          (click)="closeModal()"
        ></i>
      </span>
      <ng-content></ng-content>
    </div>
  </div>
</div>
