<ng-container *ngIf="image && primaryLabel && href">
  <a
    floAnalytics
    [name]='"Avatar Card"'
    [analyticsProperties]="analyticsProperties"
    [floCustomLink]='href'
    data-test="avatar-card"
    class="avatar-card-container">
      <div
        class="avatar-card-container__card d-flex flex-column flex-lg-row">
        <div class="d-flex justify-content-center align-items-center me-0 me-lg-2 pe-0 pe-lg-1 mb-2 mb-lg-0">
          <img
            [src]="image | floImage: 200 | async"
            class="avatar-card-container__image no-skeleton rounded-circle"
            loading="lazy"
            alt="{{ primaryLabel }}"
            data-test="avatar-card-image">
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center align-items-lg-start">
          <div class="avatar-card-container__primary-label h6 mb-0 font-normal text-center text-lg-start text-line-clamp-2 apply-text-hover">{{ primaryLabel }}</div>
          <div
            *ngIf="secondaryLabel"
            class="avatar-card-container__secondary-label h6 mb-0 font-normal text-center text-lg-start text-line-clamp-2"
            data-test="avatar-card-secondary-label">{{ secondaryLabel }}</div>
        </div>
      </div>
  </a>
</ng-container>
