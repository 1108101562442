import { Inject, Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { WINDOW } from '../app.injections'
import { PlatformService } from './platform.service'

interface PickupPicker {
  ready(cb: () => void): void
  render(): void
}

@Injectable({
  providedIn: 'root'
})
export class PickupService {
  private readonly pickup: PickupPicker

  constructor(
    // tslint:disable-next-line:ban-types
    private readonly platformService: PlatformService,
    @Inject(WINDOW) private readonly window: Window
  ) {
    if (this.platformService.isServer) {
      this.pickup = {} as unknown as PickupPicker
    } else {
      this.pickup = (window as any).pickup as PickupPicker
    }
  }

  // TODO: waiting on PickUp team to create load event to build this dynamically
  public load(): Observable<PickupPicker | undefined> {
    if (this.platformService.isServer) {
      return of(undefined)
    }
    return this.window.window?.hasOwnProperty('pickup') ? of(this.pickup) : of(undefined)
  }
}
