<ng-container *ngIf="competitor?.action?.url; else showCompetitorDetailsText">
  <a *ngIf="competitor"
  floAnalytics
  tagName="Link"
  [elementName]="competitor.name"
  data-test="wrestling-competitor-detail-link"
  [routerLink]="competitor.action?.url ?? []">
  <div class="w-competitor-detail w-100 primary-color-text-hover">
    <ng-container [ngTemplateOutlet]="competitorDetails"></ng-container>
  </div>
</a>
</ng-container>

<ng-template #showCompetitorDetailsText>
  <div *ngIf="competitor" data-test="wrestling-competitor-detail-text">
    <div class="w-competitor-detail w-100">
      <ng-container [ngTemplateOutlet]="competitorDetails"></ng-container>
    </div>
</div>
</ng-template>

<!--Reusable HTML content-->
<ng-template #competitorDetails>
  <div>
    <div data-test="athleteInfoDesktop" class="w-competitor-detail__name-seed d-flex align-items-baseline w-100" [ngClass]="{ 'justify-content-start': alignElement === 'left', 'justify-content-end': alignElement === 'right'}">
      <span *ngIf="competitor.rank" class="w-competitor-detail__team-seed caption me-1" >{{competitor.rank}}</span>
      <h2 class="d-none d-md-flex w-competitor-detail__name font-bold footnote text-truncate mb-0 pt-md-0" [ngClass]="{'apply-text-hover text-decoration-underline': !!competitor.action?.url}">{{competitor.name}}</h2>
      <h2 class="d-md-none w-competitor-detail__name font-bold footnote text-truncate mb-0 pt-md-0" [ngClass]="{'apply-text-hover text-decoration-underline': !!competitor.action?.url}">{{competitor.shortName}}</h2>
    </div>
    <div *ngIf="competitor.teamName" class="w-competitor-detail__team d-flex" [ngClass]="{ 'justify-content-start': alignElement === 'left', 'justify-content-end': alignElement === 'right'}">
      <div class="caption text-truncate">{{competitor.teamName}}</div>
    </div>
  </div>
</ng-template>