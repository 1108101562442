import { FloFetchFillModule, LOADERS, StringDict, ILoaderFactory } from '@flosportsinc/ng-fetch-fill'
import { NgModule } from '@angular/core'
import { ok, fail } from 'typescript-monads'
import { map, flatMap } from 'rxjs/operators'
import { GeoIPComponent } from './geo-ip.component'
import { GeoIPDirective } from './geo-ip.directive'
import { GeoIPService } from './geo-ip.service'
import { dedupe } from '../functions/dedupe'
import { AuthService } from '../../singleton-services/auth/auth.service'
import { OldSvgModule } from 'src/app/libs/_deprecated/svg/old-svg.module'

interface GeoIPResponseItem {
  readonly id: number
  readonly statusCode: number
  readonly message: string
}
type GeoIPResponseItemResultSide = StringDict<GeoIPResponseItem, string>

export function geoip(gs: GeoIPService, auth: AuthService): ILoaderFactory {
  const lambda = (items: ReadonlyArray<string>) =>
    auth.isAdmin$.pipe(
      flatMap(isAdmin => {
        return gs.fetch(dedupe(items.filter(Boolean))).pipe(
          map(res =>
            res.map(geoItems =>
              geoItems.reduce((acc, geoItem) => {
                return {
                  ...acc,
                  [`${geoItem.id}`]:
                    geoItem.statusCode === 451 && !isAdmin ? ok(geoItem) : fail<GeoIPResponseItem, string>('OK')
                }
              }, {} as GeoIPResponseItemResultSide)
            )
          )
        )
      })
    )

  return {
    key: 'geo',
    func: lambda
  }
}

@NgModule({
  imports: [FloFetchFillModule, OldSvgModule],
  declarations: [GeoIPComponent, GeoIPDirective],
  exports: [FloFetchFillModule, GeoIPComponent, GeoIPDirective],
  providers: [
    GeoIPService,
    {
      multi: true,
      provide: LOADERS,
      useFactory: geoip,
      deps: [GeoIPService, AuthService]
    }
  ]
})
export class GeoIPModule {}
