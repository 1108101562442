import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { FloResponse } from '@flocasts/flosports30-types/dist/flo-rest-api/response'
import type { Money, Plan } from '@flocasts/flosports30-types/dist/subscriptions'
import { Observable, of } from 'rxjs'
import { map, switchMap, tap } from 'rxjs/operators'
import { LOGGER_SERVICE } from '../../logger/logger.config'
import { LoggerService } from '../../logger/logger.interface'
import { IUserIdentity } from '../../singleton-services/auth/auth.interfaces'
import { AuthService } from '../../singleton-services/auth/auth.service'
import { PaymentMade, SegmentEvents } from '../analytics/models/segment-events.model'
import { SegmentService } from '../analytics/services/segment.service'
import { capitalizeFirstChar } from '../utility-functions/string.utility'
import { CouponService } from './coupon.service'
import { SubscriptionService } from './subscription.service'
import { ITokenResponse } from './user.service'
import { CORE_API_SUBSCRIPTIONS_PAYMENT_INTENT_PATH } from 'src/app/app.config'

const CONFIRM_SUBSCRIPTION_ERROR = 'Failed to create subscription.'

@Injectable()
export class PayService {
  constructor(
    private readonly authService: AuthService,
    private readonly stripeSubscribeService: SubscriptionService,
    private readonly segmentService: SegmentService,
    @Inject(LOGGER_SERVICE) private readonly logger: LoggerService,
    private readonly couponService: CouponService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly http: HttpClient
  ) {}

  public createPaymentIntent(
    plan: number,
    captcha: string,
    couponId?: string
  ): Observable<FloResponse<{ clientSecret: string; paymentIntentId: string }, {}>> {
    const user = this.authService.getUserFromStoredToken() as IUserIdentity
    return this.http.post<FloResponse<{ clientSecret: string; paymentIntentId: string }, {}>>(
      CORE_API_SUBSCRIPTIONS_PAYMENT_INTENT_PATH,
      {
        plan,
        user: user.id,
        coupon: couponId,
        captcha
      }
    )
  }

  public confirmSubscription(
    plan: Plan,
    paymentIntent: string,
    captchaToken: string,
    couponId?: string
  ): Observable<undefined | string> {
    const user = this.authService.getUserFromStoredToken()

    if (!user) {
      return of(`${CONFIRM_SUBSCRIPTION_ERROR} Invalid token.`)
    }

    const paymentDetails: Money = {
      currency: plan.price.currency,
      amount: plan.price.amount,
      scale: plan.price.scale,
      localized_text: plan.price.localized_text
    }

    return this.stripeSubscribeService
      .createSubscription(Number(user.id), plan.id, paymentIntent, captchaToken, couponId)
      .pipe(
        switchMap((tokenResponse: ITokenResponse) =>
          this.authService.setToken(tokenResponse.token).pipe(map(() => tokenResponse))
        ),
        switchMap((tokenResponse: ITokenResponse) =>
          this.authService.setRefreshToken(tokenResponse.refresh_token, tokenResponse.refresh_token_exp)
        ),
        tap(() => this.couponService.clearCoupon()),
        tap(() => {
          const planType = `${capitalizeFirstChar(plan.period)}ly`
          this.onPaymentMadeSegmentCall(
            paymentDetails.amount,
            paymentDetails.localized_text,
            paymentDetails.currency,
            'Stripe',
            this.activatedRoute.snapshot.queryParamMap.has('embeddedPurchase') ? 'Android' : 'Web',
            planType
          )
        })
      )
  }

  onPaymentMadeSegmentCall(
    paymentAmount: number | undefined,
    revenue: string | undefined,
    currency: string | undefined,
    paymentProcessor: 'Stripe',
    platform: 'Android' | 'Web',
    paymentLevel: string,
    isGifter?: boolean
  ) {
    const analyticProps: PaymentMade = {
      name: SegmentEvents.PAYMENT_MADE,
      properties: {
        payment_amount: paymentAmount,
        revenue,
        currency,
        payment_processor: paymentProcessor,
        platform,
        payment_level: paymentLevel,
        universal: true,
        is_gifter: isGifter
      }
    }
    // Need to pass true as second arg, for TUNE HasOffers Postback
    this.segmentService.trackEvent(analyticProps, true)
  }
}
