import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core'
import { WINDOW } from 'src/app/app.injections'

@Component({
  selector: 'flo-error',
  templateUrl: 'error.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorComponent {
  constructor(@Inject(WINDOW) private readonly window: Window) {}

  @Input() public btnPrimaryText: string
  @Input() public btnPrimarySegmentName: string
  @Input() public btnSecondaryText: string
  @Input() public btnSecondarySegmentName: string
  @Output() public readonly btnPrimaryClicked = new EventEmitter()
  @Output() public readonly btnSecondaryClicked = new EventEmitter()

  public handlePrimaryClicked = () => {
    // subject.observers.length tells us how many subscribers there are
    // If there are none, we know the consumer has not defined an output for btnPrimaryClicked
    // And we go with the default behavior (reload)
    if (this.btnPrimaryClicked.observers.length) {
      this.btnPrimaryClicked.emit()
    } else {
      this.window.location.reload()
    }
  }
}
