import { Observable } from 'rxjs'
import { DeviceService } from '../../singleton-services/device.service'
import { Pipe, PipeTransform } from '@angular/core'
import { map } from 'rxjs/operators'
import { HttpParams } from '@angular/common/http'

export const DEFAULT_QUALITY = '80'

const createImgQuery = (width: number) => (quality = DEFAULT_QUALITY): HttpParams =>
  new HttpParams({
    fromObject: { width: width.toString(), quality: quality.toString() }
  })

@Pipe({ name: 'floImage' })
export class FloImagePipe implements PipeTransform {
  constructor(private ds: DeviceService) {}

  /**
   * ImagePipe - Builds a url with query params used to fetch a progressive image at that width and quality
   *
   * @example
   * [imageUrl]="view.url | floImage: 617"
   * <!-- url.com/img.png?width=617&quality=80 -->
   */
  public transform(url: string, desktopSize?: number, mobileSize?: number, quality?: number): Observable<string> {
    const q = quality ? quality.toString() : DEFAULT_QUALITY

    return this.ds.isMobile$.pipe(
      map(isMobile => {
        if (isMobile && mobileSize) {
          return `${url}?${createImgQuery(mobileSize)(q)}`
        } else if (desktopSize) {
          return `${url}?${createImgQuery(desktopSize)(q)}`
        } else {
          return url
        }
      })
    )
  }
}
