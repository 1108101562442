import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild,
  NgZone
} from '@angular/core'
import { combineLatest, merge, of, ReplaySubject, Subject, fromEvent, SchedulerLike } from 'rxjs'
import {
  distinctUntilChanged,
  filter,
  flatMap,
  map,
  mergeMap,
  shareReplay,
  skipWhile,
  switchMap,
  take,
  takeUntil,
  throttleTime,
  withLatestFrom
} from 'rxjs/operators'

import { IVolumeControls } from '../../video-player/models/volume-controls.model'
import { VideoAdsService } from '../services/video-ads.service'
import { IVideo } from '../../models/video.model'
import { IdGeneratorService } from '../../../singleton-services/id-generator.service'
import { YOUBORA_IMA_ADAPTER } from '../../analytics/directives/video-analytics.directive'
import { RXJS_SCHEDULER } from 'src/app/app.config'
import * as preRollFunctions from '../../functions/preroll-ads'
import { DOCUMENT } from '@angular/common'
import { SegmentService } from '../../analytics/services/segment.service'
import { SegmentTrackVodPrerollAds } from '../../interfaces/segment-track-vod-preroll-ads.interface'
import { PubmaticService } from '../advertising-service/pubmatic.service'
import { notNullOrUndefined } from '../../functions/type-guards'
import { normalizeVolume } from '../ad-volume.utility'

@Component({
  selector: 'flo-ad-video-preroll',
  template: `
    <div class="ad-video-preroll d-none" #adVideoPrerollElement>
      <div class="video-ad-container" [id]="adContainerId$ | async" #adContainerElement></div>
    </div>
  `,
  styles: [
    `
      .ad-video-preroll {
        height: 100%;
        width: 100%;
        position: relative;
      }
    `
  ]
})
export class AdVideoPrerollComponent implements OnDestroy, AfterViewInit {
  @ViewChild('adVideoPrerollElement')
  set adVideoPrerollElement(elementRef: ElementRef<HTMLDivElement>) {
    if (elementRef && elementRef.nativeElement) this._adVideoPrerollElement.next(elementRef.nativeElement)
  }

  @ViewChild('adContainerElement')
  set adContainerElement(elementRef: ElementRef<HTMLDivElement>) {
    if (elementRef && elementRef.nativeElement) this._adContainerElement.next(elementRef.nativeElement)
  }

  @Input()
  set video(video: IVideo) {
    this._video.next(video)
  }

  @Input()
  set analyticsPlugin(analyticsPlugin: any) {
    this._analyticsPlugin.next(analyticsPlugin)
  }

  @Input()
  set volumeControls(volumeControls: IVolumeControls) {
    this._volumeControls.next(volumeControls)
  }

  @Input()
  set enablePreroll(enablePreroll: boolean) {
    this._enablePreroll.next(!!enablePreroll)
  }

  @Input()
  set contentVideoElement(contentVideoElement: HTMLVideoElement) {
    this._contentVideoElement.next(contentVideoElement)
  }

  constructor(
    private readonly zone: NgZone,
    private readonly renderer2: Renderer2,
    private readonly videoAdsService: VideoAdsService,
    private readonly idGeneratorService: IdGeneratorService,
    private readonly segmentService: SegmentService,
    private readonly pubmaticService: PubmaticService,
    @Inject(YOUBORA_IMA_ADAPTER) private readonly adAnalyticsAdapter: any,
    @Inject(RXJS_SCHEDULER) private readonly scheduler: SchedulerLike,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}
  private readonly ngAfterViewInit$ = new Subject<void>()
  private readonly ngOnDestroy$ = new Subject<void>()
  private readonly _volumeControls = new ReplaySubject<IVolumeControls>(1)
  private readonly _contentVideoElement = new ReplaySubject<HTMLVideoElement>(1)
  private readonly _video = new ReplaySubject<IVideo>(1)
  private readonly _adEvent = new Subject<string>()
  private readonly _enablePreroll = new ReplaySubject<boolean>(1)
  public readonly _adVideoPrerollElement = new ReplaySubject<HTMLDivElement>(1)

  private readonly _adContainerElement = new ReplaySubject<HTMLDivElement>(1)
  private readonly _analyticsPlugin = new ReplaySubject<any>(1)

  @Output()
  public readonly adEvent$ = this._adEvent

  private readonly adVideoPrerollElement$ = this._adVideoPrerollElement.pipe(
    shareReplay(1),
    distinctUntilChanged(),
    takeUntil(this.ngOnDestroy$)
  )

  private readonly adContainerElement$ = this._adContainerElement.pipe(
    shareReplay(1),
    distinctUntilChanged(),
    takeUntil(this.ngOnDestroy$)
  )

  private readonly analyticsPlugin$ = this._analyticsPlugin

  private readonly openwrapScript$ = this.ngAfterViewInit$.pipe(
    map(() => this.pubmaticService.pwt),
    filter(notNullOrUndefined),
    take(1),
    shareReplay(1)
  )

  private readonly video$ = this._video.pipe(filter<IVideo>(Boolean), shareReplay(1), takeUntil(this.ngOnDestroy$))

  private readonly volumeControls$ = this._volumeControls.pipe(
    filter<IVolumeControls>(Boolean),
    shareReplay(1),
    takeUntil(this.ngOnDestroy$)
  )

  private readonly contentVideoElement$ = this._contentVideoElement.pipe(
    filter<HTMLVideoElement>(Boolean),
    shareReplay(1),
    takeUntil(this.ngOnDestroy$)
  )

  private readonly owAdProps$ = combineLatest([this.contentVideoElement$, this.video$]).pipe(
    switchMap(([_, video]) => {
      return this.videoAdsService.getPrerollAdTagUrl$(video)
    }),
    distinctUntilChanged(),
    shareReplay(1),
    take(1),
    takeUntil(this.ngOnDestroy$)
  )

  private readonly owBids$ = this.owAdProps$.pipe(
    withLatestFrom(this.contentVideoElement$),
    map(([{ iu, adParams }, video]) => this.pubmaticService.formatOwBidConfig(video, iu)),
    shareReplay(1)
  )

  private adTagUrl$ = combineLatest([this.owBids$, this.openwrapScript$]).pipe(
    withLatestFrom(this.owAdProps$),
    mergeMap(([[owBids, owScript], owParams]) =>
      this.pubmaticService.generateDFPUrl$(owBids, owScript, owParams.adParams)
    ),
    distinctUntilChanged(),
    shareReplay(1)
  )

  public readonly adContainerId$ = of(`ad-container-${this.idGeneratorService.getUniqueId()}`)

  private readonly adContainer$ = combineLatest([this.adContainerElement$, this.contentVideoElement$]).pipe(
    preRollFunctions.flatMapAdDisplayContainer(this.videoAdsService),
    take(1),
    shareReplay(1)
  )

  private readonly adsLoader$ = this.adContainer$.pipe(
    switchMap(adContainer => this.videoAdsService.getAdsLoader$(adContainer)),
    shareReplay(1),
    takeUntil(this.ngOnDestroy$)
  )

  public readonly registerContentCompleteListener = combineLatest([this.adsLoader$, this.contentVideoElement$])
    .pipe(
      flatMap(([adsLoader, contentVideoElement]: readonly [google.ima.AdsLoader, HTMLVideoElement]) =>
        fromEvent(contentVideoElement, 'ended').pipe(map(() => adsLoader))
      ),
      takeUntil(this.ngOnDestroy$)
    )
    .subscribe(e => {
      e.contentComplete()
    })

  private readonly adsRequest$ = this.adTagUrl$.pipe(
    withLatestFrom(this.contentVideoElement$),
    preRollFunctions.flatMapGetAdsRequest(this.videoAdsService),
    preRollFunctions.distinctUntilAdTagUrlChanged(),
    shareReplay(1),
    takeUntil(this.ngOnDestroy$)
  )

  private readonly adsManager$ = combineLatest([this.adsLoader$, this.videoAdsService.videoAdsLibrary$]).pipe(
    switchMap(([adsLoader, videoAdsLibrary]) =>
      preRollFunctions.fromAdsEvent(adsLoader, videoAdsLibrary.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED)
    ),
    withLatestFrom(this.contentVideoElement$),
    preRollFunctions.mapGetAdsManager(),
    shareReplay(1),
    takeUntil(this.ngOnDestroy$)
  )

  private readonly adsLoaderError$ = this.adsLoader$.pipe(
    withLatestFrom(this.videoAdsService.videoAdsLibrary$),
    preRollFunctions.flatMapAdErrors(),
    shareReplay(1),
    takeUntil(this.ngOnDestroy$)
  )

  private readonly adsManagerError$ = this.adsManager$.pipe(
    withLatestFrom(this.videoAdsService.videoAdsLibrary$),
    preRollFunctions.flatMapAdErrors(),
    shareReplay(1),
    takeUntil(this.ngOnDestroy$)
  )

  private readonly pauseContentRequest$ = this.adsManager$.pipe(
    withLatestFrom(this.videoAdsService.videoAdsLibrary$),
    flatMap(([adsManager, videoAdsLibrary]) =>
      preRollFunctions.fromAdsEvent(adsManager, videoAdsLibrary.AdEvent.Type.CONTENT_PAUSE_REQUESTED)
    ),
    takeUntil(this.ngOnDestroy$)
  )

  private readonly resumeContentRequest$ = this.adsManager$.pipe(
    withLatestFrom(this.videoAdsService.videoAdsLibrary$),
    flatMap(([adsManager, videoAdsLibrary]) =>
      preRollFunctions.fromAdsEvent(adsManager, videoAdsLibrary.AdEvent.Type.CONTENT_RESUME_REQUESTED)
    ),
    shareReplay(1),
    takeUntil(this.ngOnDestroy$)
  )

  private readonly videoAdClicked$ = this.adsManager$.pipe(
    withLatestFrom(this.videoAdsService.videoAdsLibrary$),
    flatMap(([adsManager, videoAdsLibrary]) =>
      preRollFunctions.fromAdsEvent(adsManager, videoAdsLibrary.AdEvent.Type.CLICK)
    ),
    takeUntil(this.ngOnDestroy$),
    shareReplay(1)
  )

  private readonly videoAdPaused$ = this.adsManager$.pipe(
    withLatestFrom(this.videoAdsService.videoAdsLibrary$),
    flatMap(([adsManager, videoAdsLibrary]) => {
      return preRollFunctions.fromAdsEvent(adsManager, videoAdsLibrary.AdEvent.Type.PAUSED)
    }),
    takeUntil(this.ngOnDestroy$),
    shareReplay(1)
  )

  private readonly videoAdResumed$ = this.adsManager$.pipe(
    withLatestFrom(this.videoAdsService.videoAdsLibrary$),
    flatMap(([adsManager, videoAdsLibrary]) => {
      return preRollFunctions.fromAdsEvent(adsManager, videoAdsLibrary.AdEvent.Type.RESUMED)
    }),
    takeUntil(this.ngOnDestroy$),
    shareReplay(1)
  )

  private readonly videoAdFirstQuartile$ = this.adsManager$.pipe(
    withLatestFrom(this.videoAdsService.videoAdsLibrary$),
    flatMap(([adsManager, videoAdsLibrary]) => {
      return preRollFunctions.fromAdsEvent(adsManager, videoAdsLibrary.AdEvent.Type.FIRST_QUARTILE)
    }),
    takeUntil(this.ngOnDestroy$),
    shareReplay(1)
  )

  private readonly videoAdMidPoint$ = this.adsManager$.pipe(
    withLatestFrom(this.videoAdsService.videoAdsLibrary$),
    flatMap(([adsManager, videoAdsLibrary]) => {
      return preRollFunctions.fromAdsEvent(adsManager, videoAdsLibrary.AdEvent.Type.MIDPOINT)
    }),
    takeUntil(this.ngOnDestroy$),
    shareReplay(1)
  )

  private readonly videoAdThirdQuartile$ = this.adsManager$.pipe(
    withLatestFrom(this.videoAdsService.videoAdsLibrary$),
    flatMap(([adsManager, videoAdsLibrary]) => {
      return preRollFunctions.fromAdsEvent(adsManager, videoAdsLibrary.AdEvent.Type.THIRD_QUARTILE)
    }),
    takeUntil(this.ngOnDestroy$),
    shareReplay(1)
  )

  private readonly adImpression$ = this.adsManager$.pipe(
    withLatestFrom(this.videoAdsService.videoAdsLibrary$),
    preRollFunctions.flatMapAdImpression(),
    takeUntil(this.ngOnDestroy$),
    shareReplay(1)
  )

  private readonly adVolumeChanged$ = this.adsManager$.pipe(
    withLatestFrom(this.videoAdsService.videoAdsLibrary$),
    preRollFunctions.flatMapVolumeChanged(),
    takeUntil(this.ngOnDestroy$),
    shareReplay(1)
  )

  private readonly isAdPaused$ = merge(
    this.videoAdPaused$.pipe(map(() => true)),
    this.videoAdResumed$.pipe(map(() => false))
  )

  private readonly videoAdsEventsList$ = this.adsManager$.pipe(
    withLatestFrom(this.videoAdsService.videoAdsLibrary$),
    map(([_, videoAdsLibrary]) => {
      return preRollFunctions.mapAdEventsToSegment(videoAdsLibrary)
    }),
    takeUntil(this.ngOnDestroy$),
    shareReplay(1)
  )

  public readonly logAdEvents = merge(
    this.pauseContentRequest$,
    this.resumeContentRequest$,
    this.videoAdClicked$,
    this.videoAdPaused$,
    this.videoAdResumed$,
    this.videoAdFirstQuartile$,
    this.videoAdMidPoint$,
    this.videoAdThirdQuartile$,
    this.adsLoaderError$,
    this.adsManagerError$,
    this.adVolumeChanged$
  )
    .pipe(
      skipWhile((adEvent: google.ima.AdEvent): boolean => !adEvent || !adEvent.getAd),
      withLatestFrom(this.videoAdsEventsList$, this.video$),
      map(([adEvent, segmentEventsMap, video]: any) => [adEvent, segmentEventsMap[adEvent.type], video]),
      takeUntil(this.ngOnDestroy$)
    )
    .subscribe(([adEvent, adEventName, video]) => {
      const context: SegmentTrackVodPrerollAds = {
        name: 'Pre Roll',
        creative_ad_id: adEvent.getAd().getCreativeAdId(),
        ad_id: adEvent.getAd().getAdId(),
        node_id: video.id,
        node_type: video.type,
        ad_type: video.type,
        action: adEvent.type === 'click' ? 'Click' : undefined
      }
      this.segmentService.trackVODPrerollAd(context, adEventName)
    })

  public readonly logAdImpression = this.adImpression$.subscribe(() => {
    this._adEvent.next('adImpression')
  })

  public readonly logAdClicked = this.videoAdClicked$.subscribe(() => this._adEvent.next('adClick'))

  public readonly isAdResumable = combineLatest([fromEvent(this.document, 'visibilitychange'), this.isAdPaused$])
    .pipe(withLatestFrom(this.adsManager$), takeUntil(this.ngOnDestroy$))
    .subscribe(([[document, isAdPaused], adsManager]: [[any, boolean], google.ima.AdsManager]) => {
      if (document.target.visibilityState === 'visible' && isAdPaused) adsManager.resume()
    })

  public readonly normalizeAdVolume = combineLatest([this.adsManager$, this.adVolumeChanged$])
    .pipe(takeUntil(this.ngOnDestroy$))
    .subscribe(([adsManager, _]) => {
      const currentVolume = adsManager.getVolume()
      const normalizedVolume = normalizeVolume(currentVolume, 0.6)
      adsManager.setVolume(normalizedVolume)
      this._adEvent.next('volumeChanged')
    })

  public readonly requestAd = combineLatest([this.adsRequest$, this.adsLoader$])
    .pipe(throttleTime(30 * 1000, this.scheduler), takeUntil(this.ngOnDestroy$))
    .subscribe(([adsRequest, adsLoader]: readonly [google.ima.AdsRequest, google.ima.AdsLoader]) => {
      this.zone.runOutsideAngular(() => {
        adsLoader.requestAds(adsRequest)
        this._adEvent.next('adRequest')
      })
    })

  public readonly initializeAdsManager = combineLatest([this.adsManager$, this.contentVideoElement$])
    .pipe(takeUntil(this.ngOnDestroy$))
    .subscribe(([adsManager, contentVideoElement]) => {
      this.videoAdsService.initAdsManager(adsManager, contentVideoElement)
    })

  public readonly initializeAdAnalytics = combineLatest([this.analyticsPlugin$, this.adsManager$])
    .pipe(takeUntil(this.ngOnDestroy$))
    .subscribe(([plugin, adsManager]) => {
      this.zone.runOutsideAngular(() => {
        plugin.setAdsAdapter(new this.adAnalyticsAdapter(adsManager))
      })
    })

  public readonly startAd = this.pauseContentRequest$
    .pipe(withLatestFrom(this.adContainerElement$, this.adVideoPrerollElement$, this.contentVideoElement$))
    .subscribe(([contentPauseRequest, adContainerElement, adVideoPrerollElement, contentVideoElement]) => {
      const renderer2 = this.renderer2
      renderer2.removeClass(adVideoPrerollElement, 'd-none')
      renderer2.setStyle(contentVideoElement, 'visibility', 'hidden')
      // TODO: Bug causes adsManager.init to receive width=0, height=0. This removes the style Google gives it
      Array.from(adContainerElement.children).forEach((element: HTMLElement) => {
        renderer2.removeAttribute(element, 'style')
      })
      const insertedElements = preRollFunctions.flatten(preRollFunctions.getDescendantElements(adContainerElement))

      insertedElements.forEach((element: HTMLElement) => {
        renderer2.removeClass(element, 'd-none')
        renderer2.addClass(element, 'ad-video-inserted-element')
      })
      renderer2.removeClass(adContainerElement, 'd-none')
      this._adEvent.next('adStarted')
    })

  public readonly stopAd = merge(this.resumeContentRequest$, this.adsLoaderError$, this.adsManagerError$)
    .pipe(withLatestFrom(this.adContainerElement$, this.contentVideoElement$, this.adVideoPrerollElement$))
    .subscribe(([resumeContentRequestOrError, adContainerElement, contentVideoElement, adVideoPrerollElement]) => {
      const renderer2 = this.renderer2
      const insertedElements = preRollFunctions.flatten(preRollFunctions.getDescendantElements(adContainerElement))
      insertedElements.forEach((element: HTMLElement) => {
        renderer2.addClass(element, 'd-none')
        renderer2.removeClass(element, 'ad-video-inserted-element')
      })
      renderer2.addClass(adVideoPrerollElement, 'd-none')
      renderer2.setStyle(contentVideoElement, 'visibility', 'visible')
      this._adEvent.next('adComplete')
    })

  public readonly setVolume = combineLatest([this.volumeControls$, this.adsManager$])
    .pipe(takeUntil(this.ngOnDestroy$))
    .subscribe(([volumeControls, adsManager]) => {
      volumeControls.isMuted ? adsManager.setVolume(0) : adsManager.setVolume(volumeControls.volume || 1)
    })

  public readonly handleAdsError = merge(this.adsLoaderError$, this.adsManagerError$).subscribe(adErrorEvent => {
    this._adEvent.next('adError')
    this.destroyAdsManager()
  })

  public readonly initializeVideoAdsService = this.ngAfterViewInit$
    .pipe(take(1))
    .subscribe(() => this.videoAdsService.init(this.renderer2))

  public ngAfterViewInit() {
    this.ngAfterViewInit$.next()
    this.ngAfterViewInit$.complete()
  }

  public ngOnDestroy() {
    this.ngOnDestroy$.next()
    this.ngOnDestroy$.complete()
    this._volumeControls.complete()
    this._contentVideoElement.complete()
  }

  private destroyAdsManager = () => this.adsManager$.pipe(take(1)).subscribe(adsManager => adsManager.destroy())
}
