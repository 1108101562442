import { Context } from '@growthbook/growthbook'
import { InjectionToken } from '@angular/core'
import { makeStateKey } from '@angular/platform-browser'
import { Observable } from 'rxjs'

export const GROWTHBOOK_SERVICE = new InjectionToken<GrowthBookService>('GROWTHBOOK_SERVICE')

export const GrowthBookTransferStateKey = makeStateKey<Context>('growthbook')

export type FeatureKeys = 'feature-flag-large-signup-button' | 'upfront-annual-price-display'

export interface GrowthBookService {
  isFeatureOn(featureKey: FeatureKeys): Observable<boolean>
}
