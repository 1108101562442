import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { CORE_API_ACTIVATION_CODES_PATH } from 'src/app/app.config'

export interface IActivationCodeService {
  sendActivationCode(code: string): Observable<any>
}

@Injectable()
export class ActivationCodeService implements IActivationCodeService {
  constructor(private http: HttpClient) {}

  /**
   * Make a POST request with the activation code.
   *
   * 200 - Successful
   * 400 - Bad request
   * 401 - Not Authenticated
   */
  public sendActivationCode(code: string) {
    return this.http.post(CORE_API_ACTIVATION_CODES_PATH, { activation_code: code })
  }
}
