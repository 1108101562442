import { animate, AnimationTriggerMetadata, group, query, style, transition, trigger } from '@angular/animations'

export const routeAnimation: AnimationTriggerMetadata = trigger('routerState', [
  transition('none => *', [
    // denies any animation from 'none' state
  ]),
  transition('* => none', [
    // any route with animation route data of none is not animated
  ]),
  transition('* <=> *', [
    // Initial state of new route
    query(
      ':enter',
      style({
        position: 'fixed',
        width: '100%',
        opacity: 0
      }),
      { optional: true }
    ),
    // fades current route
    query(
      ':leave',
      [
        style({
          position: 'fixed',
          width: '100%',
          opacity: 1
        }),
        animate('0.15s', style({ opacity: 0 }))
      ],
      { optional: true }
    ),
    // fades in new activated route
    query(
      ':enter',
      [
        style({
          position: 'fixed',
          width: '100%',
          opacity: 0
        }),
        animate('0.15s', style({ opacity: 1 }))
      ],
      { optional: true }
    )
  ])
])

export const footerAnimation: AnimationTriggerMetadata = trigger('footerAnimation', [
  transition('* => *', [
    group([
      style({
        position: 'fixed',
        width: '100%',
        opacity: 0
      }),
      animate('0.2s 450ms ease-out', style({ opacity: 1 }))
    ])
  ])
])
