import { Inject, Injectable } from '@angular/core'
import { LoggerService, LogMessageContext } from './logger.interface'
import { Logger } from '@datadog/browser-logs'
import { DATADOG_BROWSER_INSTANCE } from './logger.config'

@Injectable()
export class BrowserLoggerService implements LoggerService {
  public constructor(@Inject(DATADOG_BROWSER_INSTANCE) private readonly logger: Logger) {}

  public debug(msg: string, context: LogMessageContext): void {
    this.logger.debug(msg, context)
  }

  public error(msg: string, context: LogMessageContext): void {
    this.logger.error(msg, context)
  }

  public fatal(msg: string, context: LogMessageContext): void {
    this.logger.error(`FATAL: ${msg}`, context)
  }

  public info(msg: string, context: LogMessageContext): void {
    this.logger.info(msg, context)
  }

  public trace(msg: string, context: LogMessageContext): void {
    this.logger.log(msg, context)
  }

  public warn(msg: string, context: LogMessageContext): void {
    this.logger.warn(msg, context)
  }
}
