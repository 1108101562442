<div class="unmute-video-container" (click)="muteClicked($event)">
  <span *ngIf="(audioAvailable$ | async) === false" class="unmute-title">
    <i
      class="toggle-mute"
      floSvg="volume_mute"
      title="Unmute"
      *ngIf="isMuted$ | async"
    ></i>
    <i
      class="toggle-mute"
      floSvg="volume_high"
      title="Mute"
      *ngIf="(isMuted$ | async) === false"
    ></i>
    No Audio Available</span
  >
  <span
    *ngIf="(isMuted$ | async) && (audioAvailable$ | async)"
    class="unmute-title"
  >
    <i class="toggle-mute" floSvg="volume_mute" title="Unmute"></i> Click to
    unmute</span
  >
  <span
    *ngIf="(isMuted$ | async) === false && (audioAvailable$ | async)"
    class="unmute-title"
  >
    <i class="toggle-mute" floSvg="volume_high" title="Mute"></i> Click to
    mute</span
  >
</div>
