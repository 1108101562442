import { ChangeDetectionStrategy, Component, OnDestroy, Renderer2 } from '@angular/core'
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router'
import { WebFlyOutViewModel, WebNavigationViewModel } from '@flocasts/experience-service-types'
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  shareReplay,
  startWith,
  Subject,
  switchMap
} from 'rxjs'
import { notNullOrUndefined } from 'src/app/shared/functions/type-guards'
import { AuthService } from 'src/app/singleton-services/auth/auth.service'
import { SiteNavigationDataService } from './site-navigation-data.service'
import { ExperimentationService } from 'src/app/shared/experimentation/experimentation.service'
import {
  EXP_NEXT_GEN_NAVIGATION_V2,
  EXP_NEXT_GEN_NAVIGATION_V2_FLOCOLLEGE,
  EXP_NEXT_GEN_NAVIGATION_V2_FLOCOLLEGE_VARIATION,
  EXP_NEXT_GEN_NAVIGATION_V2_FLOLIVE,
  EXP_NEXT_GEN_NAVIGATION_V2_FLOLIVE_VARIATION,
  EXP_NEXT_GEN_NAVIGATION_V2_TRACK,
  EXP_NEXT_GEN_NAVIGATION_V2_TRACK_VARIATION,
  EXP_NEXT_GEN_NAVIGATION_V2_VARIATION,
  EXP_NEXT_GEN_NAVIGATION_V2_VARSITY,
  EXP_NEXT_GEN_NAVIGATION_V2_VARSITY_VARIATION,
  EXP_NEXT_GEN_NAVIGATION_V2_WRESTLING,
  EXP_NEXT_GEN_NAVIGATION_V2_WRESTLING_VARIATION,
  FEAT_GIFTING_ID,
  FEAT_GIFTING_VARIATION
} from 'src/app/shared/experimentation/experiment.model'
import { shouldFetchNav } from './site-navigation.functions'
import { PageMetaService } from '../page-meta/page-meta.service'
import { takeUntil, tap } from 'rxjs/operators'
import { convertSEOMetadataToWebsiteMeta } from '../page-meta/page-meta.utility'
import { FavoriteService } from 'src/app/singleton-services/user-preferences/favorite.service'
import { VerticalService } from 'src/app/singleton-services/vertical.service'
import { VERTICALS_SHOWING_NAV_V2 } from '../navigation/nav.constants'

@Component({
  selector: 'flo-site-navigation',
  templateUrl: './site-navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SiteNavigationComponent implements OnDestroy {
  private ngOnDestroy$ = new Subject<void>()
  private showFlyOut = new BehaviorSubject<boolean>(false)
  public showFlyOut$ = this.showFlyOut.asObservable()
  public siteId$ = this.verticalService.siteId$.pipe(shareReplay(1))

  public inNavV2Variation$: Observable<boolean> = combineLatest([
    this.experimentationService.isInVariation(EXP_NEXT_GEN_NAVIGATION_V2, EXP_NEXT_GEN_NAVIGATION_V2_VARIATION),
    this.experimentationService.isInVariation(
      EXP_NEXT_GEN_NAVIGATION_V2_WRESTLING,
      EXP_NEXT_GEN_NAVIGATION_V2_WRESTLING_VARIATION
    ),
    this.experimentationService.isInVariation(
      EXP_NEXT_GEN_NAVIGATION_V2_TRACK,
      EXP_NEXT_GEN_NAVIGATION_V2_TRACK_VARIATION
    ),
    this.experimentationService.isInVariation(
      EXP_NEXT_GEN_NAVIGATION_V2_VARSITY,
      EXP_NEXT_GEN_NAVIGATION_V2_VARSITY_VARIATION
    ),
    this.experimentationService.isInVariation(
      EXP_NEXT_GEN_NAVIGATION_V2_FLOLIVE,
      EXP_NEXT_GEN_NAVIGATION_V2_FLOLIVE_VARIATION
    ),
    this.experimentationService.isInVariation(
      EXP_NEXT_GEN_NAVIGATION_V2_FLOCOLLEGE,
      EXP_NEXT_GEN_NAVIGATION_V2_FLOCOLLEGE_VARIATION
    )
  ]).pipe(
    map((isInVariations: boolean[]): boolean => isInVariations.some(item => Boolean(item))),
    shareReplay(1)
  )

  public showNavV2$: Observable<boolean> = combineLatest([this.inNavV2Variation$, this.siteId$]).pipe(
    map(([inNavV2Variation, siteId]): boolean => {
      return VERTICALS_SHOWING_NAV_V2.includes(siteId) ? true : inNavV2Variation
    }),
    shareReplay(1)
  )

  public inGiftingVariation$ = this.experimentationService
    .isInVariation(FEAT_GIFTING_ID, FEAT_GIFTING_VARIATION)
    .pipe(shareReplay(1))

  /*
   * On any router event, re-fetch the nav
   * This updates the selected primary link
   * and in the future will update the sub navigation
   */
  private readonly urlAfterNavigationEndEvent$: Observable<string> = this.router.events.pipe(
    filter((event): event is NavigationEnd => event instanceof NavigationEnd),
    startWith(this.router),
    map((routeEvent: NavigationEnd) => {
      return routeEvent?.urlAfterRedirects ?? routeEvent?.url
    }),
    distinctUntilChanged((prevUrl, url) => !shouldFetchNav(prevUrl, url)),
    filter(notNullOrUndefined),
    shareReplay(1)
  )

  public navModel$: Observable<WebNavigationViewModel | undefined> = combineLatest([
    this.authService.loggedIn$.pipe(startWith(false)),
    this.authService.isPremium$.pipe(startWith(false)),
    this.urlAfterNavigationEndEvent$,
    this.showNavV2$
  ]).pipe(
    switchMap(([_loggedIn, _isPremium, url, showNavV2]) => this.siteNavigationDataService.getNav(url, showNavV2)),
    shareReplay(1)
  )

  /**
   * Fetch the flyout when:
   * A click is made to open the flyout menu and either of user loggedIn or premium has changed
   *
   * This allows us to lazily fetch the flyout when absolutely necessary
   */
  public flyOut$: Observable<WebFlyOutViewModel | undefined> = combineLatest([
    this.showFlyOut$,
    this.authService.loggedIn$,
    this.authService.isPremium$,
    this.favoriteService.hasUpdatedFavorites$.pipe(startWith(false)),
    this.inGiftingVariation$
  ]).pipe(
    filter(([show]) => show),
    // Strip out showFlyOut so we don't re-fetch every time we toggle it
    map(([_, isLoggedIn, isPremium, hasUpdatedFavorites, showGifting]) => [
      isLoggedIn,
      isPremium,
      hasUpdatedFavorites,
      showGifting
    ]),
    distinctUntilChanged(
      (
        [prevIsLoggedIn, prevIsPremium, prevHasUpdatedFavorites, _prevShowGifting],
        [isLoggedIn, isPremium, hasUpdatedFavorites, showGifting]
      ) => {
        return (
          prevIsLoggedIn === isLoggedIn &&
          prevIsPremium === isPremium &&
          prevHasUpdatedFavorites === hasUpdatedFavorites
        )
      }
    ),
    switchMap(([_loggedIn, _isPremium, _hasUpdatedFavorites, showGifting]) =>
      this.siteNavigationDataService.getFlyOut(showGifting)
    ),
    // TODO: This causes a double-fetch, how can we avoid this?
    tap(() => this.favoriteService.setHasUpdatedFavorites(false)),
    shareReplay(1)
  )

  constructor(
    private readonly siteNavigationDataService: SiteNavigationDataService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly renderer2: Renderer2,
    private readonly experimentationService: ExperimentationService,
    private readonly pageMetaService: PageMetaService,
    private readonly favoriteService: FavoriteService,
    private readonly verticalService: VerticalService
  ) {}

  public handleClick(showFlyOut: boolean) {
    this.showFlyOut.next(showFlyOut)
    if (showFlyOut) {
      this.renderer2.setStyle(document.body, 'overflow', 'hidden')
      this.renderer2.setStyle(document.body, 'touchAction', 'none')
    } else {
      this.renderer2.setStyle(document.body, 'overflow', 'visible')
      this.renderer2.setStyle(document.body, 'touchAction', 'auto')
    }
  }

  private hasNavId$ = this.activatedRoute.queryParamMap.pipe(map(paramMap => paramMap.has('nav_id')))

  /**
   * Detect whether the page has a nav_id query param and, if so,
   * update page meta dynamically from BFF's "seo" property on the NavModel.
   * This is to get extra SEO juice for pages like filtered schedules or filtered news. (e.g., "Junior Hockey News")
   *
   * If a page has set isMetaClearable to false, then this stream will not emit since the page is handling meta itself
   * and will not be overwritten here.
   *
   * All pages without a nav_id query param rely on previous, older, more traditional ways of setting meta/SEO.
   *
   * We first have to clearMeta() and then setPageMeta().
   * The reason we can't rely on clearMeta() in ngOnDestroy() is because this component isn't destroyed like others.
   */
  private setPageMeta = combineLatest([
    this.hasNavId$,
    this.navModel$,
    this.pageMetaService.isMetaClearable$.pipe(startWith(true))
  ])
    .pipe(
      filter(([hasNavId, _, isMetaClearable]) => !!hasNavId && isMetaClearable),
      map(([_, navModel]) => navModel?.seo),
      distinctUntilChanged(),
      filter(notNullOrUndefined),
      takeUntil(this.ngOnDestroy$)
    )
    .subscribe(seo => {
      this.pageMetaService.clearMeta()
      this.pageMetaService.setPageMeta(convertSEOMetadataToWebsiteMeta(seo))
    })

  public ngOnDestroy(): void {
    this.ngOnDestroy$.next()
    this.ngOnDestroy$.complete()
  }
}
