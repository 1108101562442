import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { TabComponent } from './components/tab/tab.component'
import { TabsComponent } from './components/tabs/tabs.component'
import { CommonModule } from '@angular/common'
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap'
import { SvgModule } from 'src/app/shared/svg/svg.module'

@NgModule({
  imports: [RouterModule, CommonModule, NgbDropdownModule, SvgModule],
  declarations: [TabComponent, TabsComponent],
  exports: [TabComponent, TabsComponent]
})
export class TabsModule {}
