import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core'
import { LinkModel } from '@flocasts/experience-service-types'

@Component({
  selector: 'flo-link',
  templateUrl: './link.component.html',
  styleUrls: [
    './link.component.scss',
    './link-styles/link-cta-primary.component.scss',
    './link-styles/link-navigation-primary.component.scss',
    './link-styles/link-navigation-primary-aux.component.scss',
    './link-styles/link-navigation-sub.component.scss',
    './link-styles/link-icon.component.scss',
    './link-styles/link-icon-text-cell.component.scss',
    './link-styles/link-dropdown-item.component.scss',
    './link-styles/link-tag.component.scss',
    './link-styles/link-open.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkComponent implements OnChanges {
  queryParams: Record<string, string> | undefined
  ngOnChanges(): void {
    /** Combine BFF mergeParams and client query params together.
     * Only set query params if the url does not already contain query params.
     * Url gets priority in the case the BFF mistakenly sets both.
     */
    if (!!this.link?.url && !this.link?.url?.includes('?')) {
      if (!!this.link?.mergeParams) this.queryParams = this.link.mergeParams
      if (!!this.clientQueryParams) this.queryParams = { ...this.queryParams, ...this.clientQueryParams }
    }
  }
  @Input() link?: LinkModel

  /**
   * Query params should be set on the BFF on the action.mergeParams prop.
   * But sometimes the BFF doesn't know about ALL client qps,
   * like in the case of marketing UTM parameters.
   */
  @Input()
  clientQueryParams?: Record<string, string>

  /**
   * This input is primarily needed for analytics purposes to set 'Button Clicked'
   * vs. 'Link Clicked' vs 'Tab Clicked' etc. on our segment track calls, but can be used anytime the
   * we need the link to act as some other element. Right now, this component
   * is set up with an anchor tag wrapping a button tag, so there was a need
   * for being able to specify whether the <flo-link> is truly serving as a
   * link or as a button. We also need to use LinkModels as tabs for some components.
   */
  @Input() tagName = 'Link'

  @Output() linkClick = new EventEmitter<LinkModel>()

  /**
   * If a url is provided, the link component will automatically route to that url via the floCustomLinkDirective.
   * However, by design, sometimes the url will be null.
   * In this case, we will want to emit up so we can make an API request with the api_call property instead.
   * @param link The link model.
   */
  handleClick(link: LinkModel) {
    /** Should only emit when no link url, otherwise floCustomLinkDirective handles the click event (by routing) */
    if (!link.url) {
      this.linkClick.emit(link)
    }
  }
}
