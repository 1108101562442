import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SkeletonComponent } from './skeleton.component'
import { EventCollectionSkeletonComponent } from '../_route/event-collection-skeleton/event-collection-skeleton.component'
import { FavoriteSkeletonComponent } from '../_route/favorite-skeleton/favorite-skeleton.component'
import { FunnelSharedModule } from 'src/app/funnel/shared/funnel-shared.module'
import { CategoryListSkeletonComponent } from '../_route/category-list-skeleton/category-list-skeleton.component'
import { MyStuffSkeletonComponent } from '../_partial/my-stuff/my-stuff-skeleton.component'
import { VerticalButtonsSkeletonComponent } from '../_partial/two-vertical-buttons-skeleton/vertical-buttons-skeleton.component'
import { HorizontalButtonsSkeletonComponent } from '../_partial/horizontal-buttons-skeleton/horizontal-buttons-skeleton.component'
import { StandingsListSkeletonComponent } from '../_page/rankings-list-skeleton/standings-list-skeleton.component'
import { StandingsListViewAllSkeletonComponent } from '../_page/standings-list-view-all-skeleton/standings-list-view-all-skeleton.component'
import { SearchSkeletonComponent } from '../_page/search/search-skeleton.component'
import { EntityHubSkeletonComponent } from '../_route/entity-hub-skeleton/entity-hub-skeleton.component'
import { FlyOutSkeletonComponent } from '../_partial/fly-out/fly-out-skeleton.component'
import { VerticalButtonsFullWidthSkeletonComponent } from '../_partial/two-vertical-buttons-skeleton/vertical-buttons-full-width.skeleton.component'

@NgModule({
  imports: [CommonModule, FunnelSharedModule],
  declarations: [
    SkeletonComponent,
    EventCollectionSkeletonComponent,
    EntityHubSkeletonComponent,
    FavoriteSkeletonComponent,
    CategoryListSkeletonComponent,
    MyStuffSkeletonComponent,
    VerticalButtonsSkeletonComponent,
    VerticalButtonsFullWidthSkeletonComponent,
    HorizontalButtonsSkeletonComponent,
    StandingsListSkeletonComponent,
    StandingsListViewAllSkeletonComponent,
    SearchSkeletonComponent,
    FlyOutSkeletonComponent
  ],
  exports: [SkeletonComponent]
})
export class SkeletonModule {}
