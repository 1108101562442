import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { MetaGuard } from 'meta'
import { HomeComponent } from './home.component'
import { HOME_ANALYTICS_TAG } from './services/home-analytics.service'

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [MetaGuard],
    data: {
      shouldShowEventTicker: true,
      /**
       * This property is set so we can prevent automatic page calls from being
       * made from App Component.
       *
       * @see outExcludedRoutes
       */
      segment: HOME_ANALYTICS_TAG,
      meta: {
        title: 'meta_home_page_title',
        description: 'meta_home_page_description'
      }
    }
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule {}
