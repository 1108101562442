import {
  InstallmentSubscription,
  PaymentResponseModel,
  StripeSubscriptionResponse,
  StripeSubscriptionStatus
} from '@flocasts/payments'
import { isValid, parseISO } from 'date-fns'
import { MinimalSubscription, MinimalPlan } from './subscriptions.interface'
import { Offer, PeriodEnum, PlatformEnum } from '@flocasts/offers'

export const isStripeSubscriptionResponse = (payment: PaymentResponseModel): payment is StripeSubscriptionResponse => {
  return payment.offers[0].type === PlatformEnum.Stripe
}

/**
 * if user is active and is on a monthly plan or is on a vertical specific plan
 */
export function canUpgradeSubscription(isActive: boolean, offer: Offer): boolean {
  if (!isActive || offer.installment) return false

  return offer.period !== PeriodEnum.Year
}

/**
 * If user subscription is active or in trial, they can cancel
 */
export function canCancelSubscription(status: string): boolean {
  return status === StripeSubscriptionStatus.Active || status === StripeSubscriptionStatus.Trial
}

export function getInstallmentSubscription(payment: PaymentResponseModel): InstallmentSubscription | undefined {
  return isStripeSubscriptionResponse(payment) && payment.installmentSubscription
    ? payment.installmentSubscription
    : undefined
}

/**
 * Render new Payment into MinimalSubscription object for backwards compatibility with SupplementedUserSubscription
 */
export const renderMinimalUserSubscription = (
  payment: PaymentResponseModel,
  stripeKey: string,
  now = new Date()
): MinimalSubscription | undefined => {
  const endDate = parseISO(payment.accessEndDate)
  if (!isValid(endDate)) return undefined

  const offer = payment.offers[0]
  const isActive = payment.status === 'active'

  const minimalPlan: MinimalPlan = {
    type: 'plan',
    id: offer.id,
    product_id: isStripeSubscriptionResponse(payment) ? payment.stripeId : null,
    name: offer.name,
    monthly: offer.period === 'month',
    yearly: offer.period === 'year',
    period: offer.period === 'month' ? 'month' : 'year', // for backwards compatibility this has to be coerced to 'month' or 'year'
    cost_per_month: {
      localized_text: offer.price.monthly.localizedText,
      currency: offer.price.monthly.currency,
      amount: offer.price.monthly.amount,
      scale: offer.price.monthly.scale
    },
    payment_provider: {
      public_key: stripeKey
    }
  }

  const installmentSubscription: InstallmentSubscription | undefined = getInstallmentSubscription(payment)

  return {
    id: payment.id,
    status: payment.status,
    customer_id: isStripeSubscriptionResponse(payment) ? payment.customerId : undefined,
    end_date: payment.accessEndDate,
    created_at: payment.createdAt,
    is_active: isActive,
    is_expired: endDate < now,
    is_stripe: offer.type === PlatformEnum.Stripe,
    plan: minimalPlan,
    offers: payment.offers,
    site_id: offer.siteId,
    card_last4: isStripeSubscriptionResponse(payment) && payment.cardLast4 ? payment.cardLast4 : undefined,
    purchase_date: payment.createdAt,
    card_type: isStripeSubscriptionResponse(payment) && payment.cardType ? payment.cardType : undefined,
    plan_is_yearly: offer.period === 'year',
    can_upgrade: isStripeSubscriptionResponse(payment) && canUpgradeSubscription(isActive, offer),
    can_cancel: canCancelSubscription(payment.status),
    paused_subscription: undefined, // we will not support paused_subscription in our new system
    is_paused: false, // we will not support paused_subscription in our new system
    discount: isStripeSubscriptionResponse(payment) && payment.discount ? payment.discount : undefined,
    is_canceled: payment.status === StripeSubscriptionStatus.Cancelled,
    upcoming_payment_date:
      isStripeSubscriptionResponse(payment) && payment.upcomingPaymentDate ? payment.upcomingPaymentDate : undefined,
    installment_subscription: installmentSubscription
  }
}
