import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { WrestlingCompetitorDetailComponent } from './wrestling-competitor-detail/wrestling-competitor-detail.component'
import { WrestlingMatchReplayComponent } from './wrestling-match-replay/wrestling-match-replay.component'
import { WrestlingResultCardComponent } from './wrestling-result-card.component'
import { AnalyticsModule } from 'src/app/shared/analytics/analytics.module'
import { RouterModule } from '@angular/router'
import { CustomLinkModule } from 'src/app/libs/custom-link/custom-link.directive.module'

@NgModule({
  imports: [CommonModule, AnalyticsModule, RouterModule, CustomLinkModule],
  exports: [WrestlingResultCardComponent],
  declarations: [WrestlingCompetitorDetailComponent, WrestlingMatchReplayComponent, WrestlingResultCardComponent]
})
export class WrestlingResultCardModule {}
