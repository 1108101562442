<div class="video-player-container" *ngIf="video$ | async as video">
  <div class="overlay rounded" *ngIf="showOverlay$ | async">
  </div>
  <div class="ui loader loading-icon" *ngIf="showLoadingIcon$ | async"></div>
  <div *ngIf="(playBtnOverlay$ | async)"
       class="overlay-play-container"
       (click)="_isPaused.next(false)">
    <i class="overlay-play-btn"
      floSvg="vid_play_icon"></i>
  </div>
  <img
    class="poster"
    [src]="video.asset?.url | floImage: 400 | async"
    [alt]="video.short_title"
    *ngIf="showPoster$ | async"
  />
  <flo-paywall
    *ngIf="
      (hasAccess$ | async) === false && (error$ | async) === null
    "
    class="paywall"
    [loggedIn]="false"
    contentType="video"
    [overlayed]="true"
  ></flo-paywall>
  <div
    #videoPlayerContainer
    class="video-player"
    data-test="video-container"
    *ngIf="showVideo$ | async"
  >
    <flo-unmute-button
      *ngIf="
        (showMuteButton$ | async) && (volumeControls$ | async) as volumeControls
      "
      [isMuted]="volumeControls.isMuted"
      [audioAvailable]="!video.no_audio"
      (click)="_volumeControls.next({ isMuted: !volumeControls.isMuted })"
    ></flo-unmute-button>
    <video
      data-test="video-component"
      floDetectIosSafariFullscreen
      floKeyboardControls
      [videoHTMLElement]="contentVideoElement$ | async"
      [videoActive]="inFocus$ | async"
      *ngIf="showVideoElement$ | async"
      [hidden]="showPoster$ | async"
      #contentVideoElement
      class="video-display"
      floMse
      (floMseClientChange)="_hlsInstance.next($event)"
      [poster]="video?.asset?.url"
      preload="metadata"
      playsinline
      floVideoAnalytics
      (analyticsPlugin$)="_analyticsPlugin.next($event)"
      (click)="_videoElementClick.next($event)"
      [videoAnalyticsData]="videoAnalyticsData$ | async"
      [muted]="isAutoplay$ | async"
      [src]="videoPlaylist$ | async"
      (error)="_error.next($event)"
      [attr.id]="video.id"
    >
      <p>I'm sorry; your browser doesn't support HTML5 video.</p>
    </video>
    <img
      *ngIf="video?.watermark?.url as watermarkSrc"
      [src]="watermarkSrc"
      class="watermark no-skeleton"
    />
    <flo-video-player-controls
      *ngIf="(isIE$ | async) === false"
      [isLive]="isLive$ | async"
      [videoPlayState]="videoPlayState$ | async"
      [volumeStateInput]="volumeControls$ | async"
      [qualityControlState]="qualityControlState$ | async"
      [config]="videoPlayerControlsConfig$ | async"
      [segmentAnalytics]="segmentAnalytics$ | async"
      [videoBufferAmount]="videoBufferAmount$ | async"
      [isVOD]="true"
      [videoCurrentTime]="videoCurrentTime$ | async"
      [fullScreenElement]="videoPlayerContainer$ | async"
      (clickedSkipBackward)="_videoSkipped.next($event)"
      (clickedSkipForward)="_videoSkipped.next($event)"
      (clickedPlayToggle)="_isPaused.next($event)"
      (clickedShareMenu)="_isShareMenuOpen.next($event)"
      (selectedShareItem)="_shareItem.next($event)"
      (videoPositionChange)="_videoPosition.next($event)"
      (qualityChanged)="_selectedQuality.next($event)"
      (volumeState)="_volumeControls.next($event)"
      [ngClass]="{ fadeInOut: showControls$ | async }"
      class="video-player-controls"
    ></flo-video-player-controls>
    <flo-ad-video-preroll
      *ngIf="(showAd$ | async) && (error$ | async) === null"
      class="ad-video"
      [contentVideoElement]="contentVideoElement$ | async"
      [volumeControls]="volumeControls$ | async"
      [analyticsPlugin]="analyticsPlugin$ | async"
      [video]="video"
      (adEvent$)="_adEvent.next($event)"
    >
    </flo-ad-video-preroll>
  </div>
  <div
    *ngIf="error$ | async as error"
    class="video-player error"
    [ngSwitch]="error.code"
  >
    <div class="fadeIn error-state">
      <flo-video-error
        *ngSwitchCase="vpsErrorCode.STREAM_NOT_FOUND"
        title="Not Found"
      >
        <p>We couldn't find this video.</p>
        <p *ngIf="(errorTimer$ | async) === null"
          >Please select another video.</p
        >
        <p *ngIf="errorTimer$ | async as errorTimer">
          Next video in: {{ errorTimer }} seconds
        </p>
      </flo-video-error>
      <flo-video-error
        *ngSwitchCase="vpsErrorCode.UNKNOWN_ERROR"
        title="An Error Occurred"
      >
        <p *ngIf="(errorTimer$ | async) === null"
          >Please select another video.</p
        >
        <p *ngIf="errorTimer$ | async as errorTimer">
          Next video in: {{ errorTimer }} seconds
        </p>
      </flo-video-error>
      <flo-video-error
        *ngSwitchCase="vpsErrorCode.GEO_UNAVAILABLE"
        title="Restricted Viewing Area"
      >
        <p>Unfortunately, this video is unavailable in your location.</p>
        <p>We apologize for the inconvenience.</p>
        <p *ngIf="(errorTimer$ | async) === null"
          >Please select another video.</p
        >
        <p *ngIf="errorTimer$ | async as errorTimer">
          Next video in: {{ errorTimer }} seconds
        </p>
      </flo-video-error>
      <flo-video-error *ngSwitchDefault title="An Error Occurred">
        <p *ngIf="(errorTimer$ | async) === null"
          >Please select another video.</p
        >
        <p *ngIf="errorTimer$ | async as errorTimer">
          Next video in: {{ errorTimer }} seconds
        </p>
      </flo-video-error>
    </div>
  </div>
</div>
