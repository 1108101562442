import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ContainerComponent } from './container.component'
import { SegmentedControlModule } from '../segmented-control/segmented-control.module'
import { FiltersModule } from '../filters/filters.module'
import { TableModule } from '../table/table.module'
import { FloCssClassSanitizePipeModule } from '../_pipes/css-class-sanitize/css-class-sanitize.pipe.module'
import { LegendModule } from '../legend/legend.module'
import { PaginationModule } from '../pagination/pagination.module'
import { EventCardModule } from '../list-items/event-card/event-card.module'
import { ElementIsVisibleModule } from '../element-is-visible/element-is-visible.module'
import { MessageModule } from '../message/message.module'
import { StaticContentCardModule } from '../content-card/static-content-card/static-content-card.module'
import { ImageWithButtonsModule } from '../image-with-buttons/image-with-buttons.module'
import { HtmlViewModule } from '../html-view/html-view.module'
import { GrapplingResultCardModule } from 'src/app/events/components/event-detail/event-tabs/event-hub-results/results-tab/grappling-results/grappling-result-card/grappling-result-card.module'
import { WrestlingResultCardModule } from 'src/app/events/components/event-detail/event-tabs/event-hub-results/results-tab/wrestling-results/wrestling-result-card/wrestling-result-card.module'
import { SlimHeaderModule } from '../slim-header/slim-header.module'
import { ExpandableTextModule } from '../expandable-text/expandable-text.module'
import { AdComponentModule } from '../ad/ad.component.module'
import { TitledListModule } from '../titled-list/titled-list.module'
import { DecoratedTextCellModule } from '../decorated-text-cell/decorated-text-cell.module'
import { TwoColumnContainerComponent } from './two-column-container/two-column-container.component'

@NgModule({
  imports: [
    CommonModule,
    TableModule,
    FiltersModule,
    SegmentedControlModule,
    FloCssClassSanitizePipeModule,
    LegendModule,
    PaginationModule,
    EventCardModule,
    ElementIsVisibleModule,
    MessageModule,
    StaticContentCardModule,
    ImageWithButtonsModule,
    HtmlViewModule,
    GrapplingResultCardModule,
    WrestlingResultCardModule,
    SlimHeaderModule,
    ExpandableTextModule,
    AdComponentModule,
    DecoratedTextCellModule,
    TitledListModule
  ],
  declarations: [ContainerComponent, TwoColumnContainerComponent],
  exports: [ContainerComponent, TwoColumnContainerComponent]
})
export class ContainerModule {}
