<!-- anchor where the scroll to top button scrolls to when clicked -->
<div id="topSection"></div>

<!-- anchor tags -->
<ng-container *ngIf="!!htmlView.anchorTags && htmlView.anchorTags.length > 0">
  <div class="row">
    <ul>
      <li *ngFor="let anchorTag of htmlView.anchorTags; trackBy: trackById" class="d-flex">
        <a *ngIf="anchorTag.id && anchorTag.text"
            [routerLink]="[]"
            (click)="scroll(anchorTag.id)"
            tagName="Link"
            floAnalytics>
          <h3 class="primary-color-text">{{anchorTag.text}}</h3>
        </a>
      </li>
    </ul>
  </div>
  <hr/>
</ng-container>

<!-- html content -->
<section
  *ngIf="!!htmlView.htmlContent"
  class="content full-content font-family-regular pt-3 text-700"
  data-test="html-content"
  floVerticalScrollListener
  [offset]="-1500"
  (scrolledPastElement)="handleScroll($event)"
>
  <div [attr.id]='htmlView.id' [innerHtml]="htmlView.htmlContent | floSanitizeHtml" floDynamicContent></div>
</section>

<!-- scroll to top button -->
<button class="cta-scroll-to-top btn btn-secondary justify-content-center" (click)="scroll('topSection')" [style.display]="isButtonVisible ? 'block' : 'none'">
  <h5 class="primary-color-text m-0">Back to Top</h5>
</button>
