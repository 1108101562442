import { Inject, Injectable, NgZone } from '@angular/core'
import type { Slot } from './gpt.service'
import { bindCallback, EMPTY, Observable } from 'rxjs'
import { LOGGER_SERVICE } from '../../../logger/logger.config'
import { LoggerService } from '../../../logger/logger.interface'
import { map } from 'rxjs/operators'
import { addUrlParamsToDFPUrl } from '../ads.utility'

const itHasPWT = (u: unknown): u is HasPWT => {
  return typeof u === 'object' && u !== null && 'PWT' in u
}

@Injectable({
  providedIn: 'root'
})
export class PubmaticService {
  private w: unknown = window
  public get pwt(): PWT | null {
    return itHasPWT(this.w) ? this.w.PWT : null
  }

  constructor(private readonly zone: NgZone, @Inject(LOGGER_SERVICE) private readonly logger: LoggerService) {}

  public a9BidsReceived(): void {
    const pwt = this.pwt
    if (pwt != null) pwt.a9_BidsReceived = true
  }

  /**
   * Need to call this before refreshing bids.
   */
  public removeKeyValuePairsFromGPTSlots(slots: readonly Slot[]): Observable<void> {
    const pwt = this.pwt
    if (pwt == null) return EMPTY
    return this.zone.runOutsideAngular(() => {
      return new Observable<void>(subscriber => {
        this.logger.trace('PubmaticService: removing key value pairs')
        pwt.removeKeyValuePairsFromGPTSlots(slots)
        subscriber.next(undefined)
        subscriber.complete()
      })
    })
  }

  public requestBids(slots: readonly Slot[]): Observable<void> {
    const pwt = this.pwt
    if (pwt == null) return EMPTY
    pwt.ow_BidsReceived = false
    return this.zone.runOutsideAngular(() => {
      return new Observable<void>(subscriber => {
        const confs = pwt.generateConfForGPT(slots)
        this.logger.trace('PubmaticService: got confs', { confs })
        pwt.requestBids(confs, adUnits => {
          this.logger.trace('PubmaticService: bids received', { adUnits })
          pwt.addKeyValuePairsToGPTSlots(adUnits)
          pwt.ow_BidsReceived = true
          subscriber.next(undefined)
          subscriber.complete()
        })
      })
    })
  }

  public formatOwBidConfig = (videoElement: HTMLVideoElement, adUnitId: string): ReadonlyArray<OpenWrapConfig> => [
    {
      code: 'video-1',
      divId: videoElement.id,
      adUnitId,
      adUnitIndex: '0', // mandatory
      mediaTypes: {
        // mandatory
        // tslint:disable:object-literal-key-quotes
        video: {
          // mandatory
          mimes: ['video/mp4'], // mandatory
          playerSize: [640, 360], // mandatory when using OpenWrap versions with Prebid 4.x and later
          w: 640, // Only needed for OpenWrap versions with Prebid 3.x
          h: 360 // Only needed for OpenWrap versions with Prebid 3.x
        }
      },
      sizes: [[640, 360]] // mandatory
    }
  ]

  public generateDFPUrl$ = (owBids: ReadonlyArray<OpenWrapConfig>, owScript: PWT, owParams: Record<string, unknown>) =>
    bindCallback(owScript.requestBids)(owBids).pipe(
      map(cb => owScript.generateDFPURL(cb[0], {})),
      map(owGeneratedDFPUrl => addUrlParamsToDFPUrl(owGeneratedDFPUrl, owParams))
    )
}

type HasPWT = {
  PWT: PWT
}

/**
 * @see {@link https://community.pubmatic.com/display/OP/OpenWrap+JavaScript+API#OpenWrapJavaScriptAPI-PWT.generateConfForGPT}
 */
type PWT = {
  ow_BidsReceived: boolean | undefined | null
  a9_BidsReceived: boolean | undefined | null

  readonly requestBids: RequestBids
  readonly generateConfForGPT: GenerateConfForGPT
  readonly generateDFPURL: GenerateDFPURL
  readonly addKeyValuePairsToGPTSlots: AddKeyValuePairsToGPTSlots
  readonly removeKeyValuePairsFromGPTSlots: RemoveKeyValuePairsFromGPTSlots
}

type RequestBids = (configs: ReadonlyArray<OpenWrapConfig>, cb: (adUnits: ReadonlyArray<BidData>) => void) => void

type GenerateDFPURL = (bidData: BidData, config: Record<string, unknown>) => string

type GenerateConfForGPT = (slots: readonly Slot[]) => ReadonlyArray<OpenWrapConfig>

type AddKeyValuePairsToGPTSlots = (adUnits: readonly BidData[]) => void

type RemoveKeyValuePairsFromGPTSlots = (slots: readonly Slot[]) => void

export type OpenWrapConfig = {
  readonly code: string
  readonly divId: string
  readonly adUnitId: string
  readonly adUnitIndex: string
  readonly sizes: ReadonlyArray<[number, number]>
  readonly mediaTypes: {
    readonly banner?: {
      readonly sizes: ReadonlyArray<[number, number]>
    }
    readonly video?: {
      readonly playerSize: ReadonlyArray<number>
      readonly mimes: ReadonlyArray<string>
      readonly w: number
      readonly h: number
    }
  }
}

type BidData = {
  readonly wb: {
    readonly adHtml: string
    readonly width: number
    readonly height: number
    readonly netEcpm: string
    readonly grossEcpm: string
    readonly adapterID: string
  }
  readonly kvp: {
    readonly pwtsid: string
    readonly pwtsz: string
    [k: string]: string | undefined
  }
}
