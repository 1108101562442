import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

@Component({
  selector: 'flo-faq-question',
  templateUrl: './faq-question.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FaqQuestionComponent {
  @Input()
  question: string

  public showAnswerContainer = false
}
