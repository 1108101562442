import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnChanges, Output } from '@angular/core'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { SegmentEvents, ToggleClickedByKeyboard } from 'src/app/shared/analytics/models/segment-events.model'
import { SegmentService } from 'src/app/shared/analytics/services/segment.service'
import { FilterOptionModel, FilterModel } from '@flocasts/experience-service-types'

@Component({
  selector: 'flo-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  animations: [
    trigger('isToggleOn', [
      state('false', style({ transform: 'translateX(0%)', left: '3px' })),
      state('true', style({ transform: 'translateX(150%)' })),
      transition('true <=> false', [animate('120ms ease-in-out')])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleComponent implements OnChanges {
  @Input() toggleFilter: FilterModel
  isToggleOn: boolean
  @Input() toggleKey: string
  @Input() toggleOutline = false
  @Output() toggledTo = new EventEmitter<FilterOptionModel>()

  constructor(private segmentService: SegmentService) {}

  ngOnChanges(): void {
    this.isToggleOn = this.toggleFilter.options[0].isSelected
  }

  toggleClick(): void {
    this.isToggleOn = !this.isToggleOn
    this.toggledTo.emit(this.toggleFilter.options[0])
  }

  @HostListener('document:keyup', ['$event'])
  toggleKeypress(event: KeyboardEvent) {
    // Only toggle if nothing is focused
    // This prevents issues where typing in a text input would flip the toggle
    if (document.activeElement === document.body) {
      if (event.key === this.toggleKey) {
        this.toggleClick()
        const keyboardEvent: ToggleClickedByKeyboard = {
          name: SegmentEvents.TOGGLE_CLICKED,
          properties: {
            name: this.toggleFilter.analytics?.name ?? 'toggle',
            action: 'Keyboard Input'
          }
        }
        this.segmentService.trackEvent(keyboardEvent)
      }
    }
  }
}
