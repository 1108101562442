import { Inject, Injectable } from '@angular/core'
import { makeStateKey, TransferState } from '@angular/platform-browser'
import { EnvironmentService } from '../../singleton-services/environment.service'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { catchError, distinctUntilChanged, map, startWith, tap } from 'rxjs/operators'
import { LOGGER_SERVICE } from 'src/app/logger/logger.config'
import { LoggerService } from 'src/app/logger/logger.interface'
import { FirebaseLiveStreamService } from './interfaces'
import { AngularFireDatabase } from '@angular/fire/compat/database'

interface CachedHttp<T> {
  body: T
}

@Injectable()
export class BrowserFirebaseLiveStreamService implements FirebaseLiveStreamService {
  private readonly readFromCacheSource = new BehaviorSubject(true)

  constructor(
    private readonly fireDb: AngularFireDatabase,
    private ts: TransferState,
    private es: EnvironmentService,
    @Inject(LOGGER_SERVICE) private readonly logger: LoggerService
  ) {}

  private readonly turnOffCache = () => this.readFromCacheSource.next(false)

  public readonly universalObject = <T>(
    path: string,
    changeDetector: (x: T, y: T) => boolean
  ): Observable<T | undefined> => {
    const cached = this.ts.get<CachedHttp<T> | undefined>(this.cacheKey(path), undefined)

    const base = this.fireDb
      .object<T>(path)
      .valueChanges()
      .pipe(
        map(a => (typeof a === 'number' ? a : a ? a : undefined)),
        catchError(err => {
          this.logger.error(`Failed to get live stream from Firebase on Client: ${err.message}`, err.error)
          return of(undefined)
        })
      )

    return this.readFromCacheSource.getValue() && cached
      ? base.pipe(
          tap(() => this.turnOffCache()),
          startWith(cached.body),
          distinctUntilChanged(changeDetector)
        )
      : base
  }

  private readonly cacheKey = <T>(path: string) => {
    const fbConfig = this.es.config.firebase
    const dbUrl = fbConfig && fbConfig.databaseURL

    return makeStateKey<T>(`G.${dbUrl}/${path}.json?`)
  }
}
