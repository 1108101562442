import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { CardModel } from '@flocasts/experience-service-types'
import { trackById } from 'src/app/shared/utility-functions/track-by-id.utility'

@Component({
  selector: 'flo-continue-watching',
  templateUrl: './continue-watching.component.html',
  styleUrls: ['./continue-watching.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContinueWatchingComponent {
  @Input() readonly videos: CardModel[]

  public trackById = trackById
}
