import { Component, Input } from '@angular/core'

@Component({
  selector: 'flo-vertical-buttons-full-width-skeleton',
  template: `
    <div>
      <h1 *ngFor="let number of createArray(numberOfButtons)" class="pb-3 w-100 h1-skeleton" style="height: 42px"></h1>
    </div>
  `
})
export class VerticalButtonsFullWidthSkeletonComponent {
  @Input() numberOfButtons = 1

  createArray(num: number) {
    return new Array(num).fill(0).map((_, index) => index + 1)
  }
}
