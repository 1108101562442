import {
  CardModel,
  CMSResultModel,
  ContainerModel,
  ContainerPartial,
  EntityHubLayout,
  EventCardModel,
  EventTickerViewModel,
  FilterableContainer,
  FilterableGridPartial,
  FilterablePartial,
  FilterModel,
  GrapplingResultCardModel,
  GridContainer,
  GridContainerPartial,
  IconModel,
  ImageModel,
  ImageWithButtonsModel,
  Layout,
  LinkModel,
  ListPartial,
  MessageModel,
  MultipleSectionsViewModel,
  OffersListPartialModel,
  Partial,
  SearchModel,
  SectionModel,
  TabModel,
  TextModel,
  TwoColumnContainerModel,
  VideoPlayerCardPartial,
  ViewComponent,
  ViewComponentModels,
  VodMetadata,
  WebFlyOutViewModel,
  WebNavigationViewModel,
  WrestlingResultCardModel
} from '@flocasts/experience-service-types'
import { FloResponse } from '@flocasts/flosports30-types/dist/flo-rest-api/response'
import {
  PaymentIntentResponse,
  PendingSubscriptionResponse,
  PendingSubscriptionError
} from '../../funnel/pay/payments.interfaces'

/**
 * Take in a nullable type A and ensure the value is actually A.
 * This can be used in streams to filter out possible null and undefined.
 *
 * Note: This works to filter out EXACTLY null or EXACTLY undefined,
 * not other "falsy" values like empty arrays, empty objects, etc.
 */
export function notNullOrUndefined<A>(a: A | null | undefined): a is NonNullable<A> {
  return a != null
}

/**
 * Take in any type and return whether it is a string.
 */
export function isString(a: unknown): a is string {
  return typeof a === 'string'
}

type contentType = 'article' | 'collection' | 'event' | 'ranking' | 'result' | 'video'
/**
 * Safely ensure a mapping of unknown route data to a type. Event | Article | Collection  (or undefined)
 * Works well with resolver data to ensure we know the type.
 * provide the type, the route data, and the type of content you expect.
 * dives into the route data object to find the node which is the prop declared in the routing module for the resolver
 * then finds the data object returned from the api
 * and then gets the type prop(event, article...etc)
 * then matches the type with the provided type in <T>
 * ex: map(data => safelyMapRouteDataToType<Event>(data, 'event'))
 */
export const safelyMapRouteDataToType = <T>(data: unknown, type: contentType) => {
  const hasNode = (u: unknown): u is { node: unknown } => {
    return Object.prototype.hasOwnProperty.call(u, 'node')
  }
  const hasData = (u: unknown): u is { node: { data: unknown } } => {
    return hasNode(u) && Object.prototype.hasOwnProperty.call(u.node, 'data')
  }
  const isINode = (u: unknown): u is { type?: string } => {
    return Object.prototype.hasOwnProperty.call(u, 'type')
  }
  const isType = (u: { type?: string }) => {
    return u.type?.toLowerCase() === type
  }

  if (hasData(data) && isINode(data.node.data) && isType(data.node.data)) {
    return data.node.data as T
  }

  return undefined
}

export const isFilterModel = (model: ViewComponentModels): model is FilterModel => {
  return model.type === 'filter'
}

export const isLinkModel = (model: ViewComponent): model is LinkModel => {
  return model.type === 'link'
}

export const isGrapplingResultCard = (resultTab: ViewComponentModels): resultTab is GrapplingResultCardModel => {
  return resultTab.type === 'card:grappling-result'
}

export const isCMSResult = (resultTab: ViewComponentModels): resultTab is CMSResultModel => {
  return resultTab.type === 'cms-results'
}

export const isWrestlingResultCard = (resultTab: ViewComponentModels): resultTab is WrestlingResultCardModel => {
  return resultTab.type === 'card:wrestling-result'
}

export const isSearchSection = (section: SectionModel<ViewComponentModels>): section is SectionModel<SearchModel> => {
  return section.id === 'search'
}

export const isSearchModel = (model: ViewComponentModels): model is SearchModel => {
  return (
    (model as SearchModel).id === 'search' &&
    (model as SearchModel).type === 'search' &&
    !!(model as SearchModel).clearAction &&
    !!(model as SearchModel).action
  )
}

export const isTextModel = (model: SectionModel<ViewComponentModels> | ViewComponentModels): model is TextModel => {
  return (
    (model as TextModel).type === 'text' &&
    (model as TextModel).text !== undefined &&
    (model as TextModel).id !== undefined
  )
}

export const isFiltersSection = (
  section: SectionModel<ViewComponentModels>
): section is SectionModel<FilterModel | LinkModel> => {
  return section.id === 'filters'
}

export const isGrapplingResultCardSection = (
  section: SectionModel<ViewComponentModels>
): section is SectionModel<GrapplingResultCardModel> => {
  return section.itemsStyle === 'card:grappling-result'
}

export const isCMSResultsSection = (
  section: SectionModel<ViewComponentModels>
): section is SectionModel<CMSResultModel> => {
  return section.itemsStyle === 'cms-results'
}

export const isEventCardSection = (section: SectionModel<ViewComponent>): section is SectionModel<EventCardModel> => {
  return (
    (section.id === 'main-banners' || section.id === 'main-hero') &&
    !!section.items?.find(item => item.type === 'card:event')
  )
}

export const isEventCard = (
  section: SectionModel<EventCardModel | TabModel<Layout | Partial | null>> | MessageModel
): section is SectionModel<EventCardModel> => {
  return section.id === 'results'
}

export const isTabModel = (
  section: SectionModel<EventCardModel | TabModel<Layout | Partial | null>> | MessageModel
): section is SectionModel<TabModel<Layout | Partial | null>> => {
  return section.id === 'tabs'
}

export const assetIsIconModel = (asset: ImageModel | IconModel | null | undefined): asset is IconModel => {
  return !!asset && asset.type === 'icon'
}

export const assetIsImageModel = (asset: ImageModel | IconModel | null | undefined): asset is ImageModel => {
  return !!asset && asset.type === 'image'
}

export const isEventTickerViewModel = (
  viewModel: EventTickerViewModel | null | undefined
): viewModel is EventTickerViewModel => {
  return (
    !!viewModel && 'sections' in viewModel && 'forward' in viewModel && 'backward' in viewModel && 'action' in viewModel
  )
}

export const isNavigationViewModel = (
  viewModel: WebNavigationViewModel | null | undefined
): viewModel is WebNavigationViewModel => {
  return !!viewModel && viewModel.type === 'nav:web'
}

export const isFlyOutViewModel = (
  viewModel: WebFlyOutViewModel | null | undefined
): viewModel is WebFlyOutViewModel => {
  return !!viewModel && viewModel.type === 'fly-out:web'
}

/**
 * Return a boolean representing if an item is of type card
 * SmallContentCardComponentProps accepts type: 'card' | 'collection' | 'video'
 */
interface CollectionCardModel extends CardModel {
  type: 'card'
}
export const isTypeCard = (item: CardModel): item is CollectionCardModel => item.type === 'card'

export const isMessageModelSection = (section: ViewComponentModels): section is SectionModel<MessageModel> => {
  return section.type === 'message'
}

export const isMessageModel = (section: ViewComponentModels): section is MessageModel => {
  return section.type === 'message'
}

export const isEntityHubLayout = (response?: Partial | Layout): response is EntityHubLayout => {
  return response?.type === 'layout:entity-hub'
}

export const isMultipleSectionsViewModel = (response: Partial | Layout): response is MultipleSectionsViewModel => {
  return response.type === 'layout:multiple-sections'
}

export const isListPartial = (response: Partial | Layout | null): response is ListPartial => {
  return response?.type === 'partial:list'
}

export const isFilterablePartial = (response: Partial | Layout | null | undefined): response is FilterablePartial => {
  return response?.type === 'partial:filterable'
}

export const isFilterableGridPartial = (
  response: Partial | Layout | null | undefined
): response is FilterableGridPartial => {
  return response?.type === 'partial:filterable-grid'
}

export const isContainerPartial = (response: Partial | Layout | null | undefined): response is ContainerPartial => {
  return response?.type === 'partial:container'
}

export const isGridContainerPartial = (
  response: Partial | Layout | null | undefined
): response is GridContainerPartial => {
  return response?.type === 'partial:grid:container'
}

export const isContainerModel = (response: { type: string } | null): response is ContainerModel => {
  return response?.type === 'container'
}

export const isGridContainer = (response: { type: string } | null): response is GridContainer => {
  return response?.type === 'grid:container'
}

export const isFilterableContainer = (response: { type: string } | null): response is FilterableContainer => {
  return response?.type === 'container:filterable'
}

export function isTwoColumnContainerModel(response: { type: string } | null): response is TwoColumnContainerModel {
  return response?.type === 'container:two-column'
}

export const isFilterableGridContainer = (response: { type: string } | null): response is FilterableContainer => {
  return response?.type === 'grid:filterable'
}

export const isVodMetadata = (model: { type?: string } | null): model is VodMetadata => {
  return model?.type === 'vod-metadata'
}

export const isCardModel = (model: ViewComponent): model is CardModel => model?.type === 'card'

export const isImageWithButtonsModel = (model: ViewComponent): model is ImageWithButtonsModel =>
  model?.type === 'image-with-buttons'

export const isVideoPlayerCardPartial = (model: Partial | null | undefined): model is VideoPlayerCardPartial =>
  model?.type === 'partial:card:video-player'

export const isOfferListPartialModel = (model: Partial): model is OffersListPartialModel =>
  model?.type === 'partial:offers-list'

export const isPaginationLink = (item: ViewComponent): boolean => isLinkModel(item) && item.style === 'paginate'

export const isPaymentIntentResponseFromPaymentsService = (
  response: FloResponse<{ clientSecret: string; paymentIntentId: string }, {}> | PaymentIntentResponse
): response is PaymentIntentResponse => {
  return 'paymentIntentId' in response && 'clientSecret' in response
}

export const isPendingSubscriptionResponse = (
  response: PendingSubscriptionResponse | PendingSubscriptionError
): response is PendingSubscriptionResponse => {
  return 'confirmationCode' in response
}
