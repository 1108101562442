import { NgModule } from '@angular/core'
import { AnalyticsModule } from '../../shared/analytics/analytics.module'
import { FloPipeModule } from '../../shared/pipes/flo-pipe.module'
import { ResponsiveImageModule } from '../../shared/responsive-image/responsive-image.module'
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'
import { LandingPageComponent } from './landing-page.component'
import { BatmanHeroComponent } from '../shared/hero/batman-hero.component'
import { BatmanCalendarComponent } from '../shared/calendar/batman-calendar.component'
import { BatmanCalendarListItemComponent } from '../shared/calendar/batman-calendar-list-item/batman-calendar-list-item.component'
import { BatmanFeatureComponent } from '../shared/batman-feature/batman-feature.component'
import { CalendarTileComponent } from '../shared/calendar-tile/calendar-tile.component'
import { ChevronComponent } from './components/chevron/chevron.component'
import { FaqQuestionComponent } from '../faq/faq-question/faq-question.component'
import { FaqComponent } from '../faq/faq.component'
import { OfferedDevicesComponent } from '../shared/offered-devices/offered-devices.component'
import { BatmanDataService } from './batman-data.service'
import { LazyLoadImageModule } from '../../shared/lazy-image/lazyload-image.module'
import { FunnelSharedModule } from '../shared/funnel-shared.module'
import { SharedModule } from '../../shared/shared.module'
import { OldSvgModule } from 'src/app/libs/_deprecated/svg/old-svg.module'

@NgModule({
  imports: [
    CommonModule,
    AnalyticsModule,
    FloPipeModule,
    ResponsiveImageModule,
    RouterModule,
    OldSvgModule,
    LazyLoadImageModule,
    FunnelSharedModule,
    SharedModule
  ],
  declarations: [
    LandingPageComponent,
    BatmanHeroComponent,
    BatmanCalendarComponent,
    BatmanCalendarListItemComponent,
    BatmanFeatureComponent,
    CalendarTileComponent,
    ChevronComponent,
    FaqComponent,
    FaqQuestionComponent,
    OfferedDevicesComponent
  ],
  exports: [
    LandingPageComponent,
    BatmanHeroComponent,
    BatmanCalendarComponent,
    BatmanCalendarListItemComponent,
    BatmanFeatureComponent,
    CalendarTileComponent,
    ChevronComponent,
    FaqComponent,
    FaqQuestionComponent,
    OfferedDevicesComponent
  ],
  providers: [BatmanDataService]
})
export class LandingPageModule {}
