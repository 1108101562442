import { GptService } from './gpt.service'
import { InjectionToken } from '@angular/core'

export const AD_PAGE_VIEW = new InjectionToken<AdPageViewService>('flo.AD_PAGE_VIEW')

export interface AdPageViewService {
  /**
   * Indicate to GPT that a completely new set of ads can be loaded.
   *
   * When GPT loads ads with the same correlator on a page, it will try to load
   * as many ads from a single line item as possible if they are set to do so.
   * When the correlator changes, it will stop trying to use the same ad set when
   * new ads are loaded. This should be called any time the user moves to a new
   * "page".
   */
  change(): void
}

export class ServerAdPageViewService implements AdPageViewService {
  constructor() {}

  /**
   * No-op since ads do not operate in the server platform.
   */
  change(): void {
    // noop
  }
}

export class BrowserAdPageViewService implements AdPageViewService {
  constructor(private readonly gpt: GptService) {}

  /**
   * @inheritDoc
   */
  public change(): void {
    this.gpt.changePageView()
  }
}
