import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { ExtraLargeContentCardModel } from '@flocasts/experience-service-types'

@Component({
  selector: 'flo-extra-large-content-card',
  templateUrl: './extra-large-content-card.component.html',
  styleUrls: ['./extra-large-content-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExtraLargeContentCardComponent {
  @Input() item: ExtraLargeContentCardModel
}
