<div *ngIf="view$ | async as view">
  <i
    floSvg="cog"
    title="adjust video quality"
    (click)="toggleDropdown($event)"
    floAnalytics
    tagName="Player Control"
    events="click"
    [analyticsProperties]="view?.analyticQualityPickerProps"
  ></i>
  <ul class="quality-dropdown" [ngClass]="{ show: dropdownExpanded }">
    <li
      *ngFor="
        let level of view?.qualityLevels;
        let i = index;
        trackBy: trackByQuality
      "
      class="quality-item"
      (click)="changeQuality(level.id)"
      [ngClass]="{ active: level?.selectedQuality }"
      floAnalytics
      tagName="Player Control"
      events="click"
      [analyticsProperties]="getQualityAnalyticsProperties(level.label)"
    >
      <span>
        {{ level.label }}
      </span>
    </li>
  </ul>
</div>
