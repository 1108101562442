import { Inject, Injectable } from '@angular/core'
import { UserAgentService } from './user-agent.service'
import { PlatformService } from './platform.service'
import { breakpointsInPixels } from '../app.config'
import { combineLatest, fromEvent, Observable, of } from 'rxjs'
import { map, startWith } from 'rxjs/operators'
import { WINDOW } from '../app.injections'

export type DeviceType = 'Desktop' | 'Tablet' | 'Mobile' | 'Unknown'

export interface IDeviceService {
  /**
   * Returns an Observable of when the window is considered in "mobile" mode.
   */
  isMobile$: Observable<boolean | undefined>

  /**
   * Returns an Observable of when the window was loaded in "mobile" mode.
   */
  loadedFromMobile$: Observable<boolean | undefined>

  /**
   * Returns an Observable of when the window was loaded in "tablet" mode.
   */
  loadedFromTablet$: Observable<boolean | undefined>

  /**
   * Returns an Observable of when the window was loaded in "desktop" mode.
   */
  loadedFromDesktop$: Observable<boolean | undefined>

  /**
   * Returns an Observable for the window load event.
   */
  windowLoaded$: Observable<Window>

  /**
   * Returns an Observable for window resize events.
   */
  windowResized$: Observable<Window>
}

/**
 * @deprecated
 * This device service is outdated and buggy.
 * Please use media queries, intersection observer and other browser-supported techniques instead.
 */
@Injectable({
  providedIn: 'root'
})
export class DeviceService implements IDeviceService {
  public windowResized$ = this.platformService.isBrowser
    ? fromEvent(this.window, 'resize').pipe(map((event: MouseEvent) => event.target as Window))
    : of<Window>({} as Window)

  public windowLoaded$ = this.platformService.isBrowser
    ? fromEvent(this.window, 'load').pipe(map((event: Event) => event.currentTarget as Window))
    : of<Window>({} as Window)

  public isMobile$: Observable<undefined | boolean> = this.windowResized$.pipe(
    startWith(undefined),
    map(win => {
      if (this.platformService.isBrowser) {
        return this.window?.innerWidth <= deviceMaxWidths.mobile
      }
      if (this.userAgentService.userAgent) {
        return this.userAgentService.userAgent.isMobile
      }

      return false
    })
  )

  public isTablet$ = this.windowResized$.pipe(
    startWith(undefined),
    map(win => {
      if (this.platformService.isBrowser) {
        return this.window?.innerWidth <= deviceMaxWidths.tablet && this.window?.innerWidth > deviceMaxWidths.mobile
      }
      if (this.userAgentService.userAgent) {
        return this.userAgentService.userAgent.isTablet
      }

      return false
    })
  )

  public isDesktop$ = this.windowResized$.pipe(
    startWith(undefined),
    map(_ => {
      if (this.platformService.isBrowser) {
        return this.window?.innerWidth > deviceMaxWidths.tablet
      }
      if (this.userAgentService.userAgent) {
        return this.userAgentService.userAgent.isDesktop
      }

      return false
    })
  )

  public device$ = combineLatest([this.isMobile$, this.isTablet$, this.isDesktop$]).pipe(
    map(([isMobile, isTablet, isDesktop]: [isMobile: boolean, isTablet: boolean, isDesktop: boolean]) => {
      return {
        isMobile,
        isTablet,
        isDesktop
      }
    })
  )

  public loadedFromMobile$ = this.windowLoaded$.pipe(
    startWith(undefined),
    map(win => {
      if (this.platformService.isBrowser) {
        return this.window?.innerWidth <= deviceMaxWidths.mobile
      }

      if (this.userAgentService.userAgent) {
        return this.userAgentService.userAgent.isMobile
      }

      return false
    })
  )

  public loadedFromTablet$ = this.windowLoaded$.pipe(
    startWith(undefined),
    map(win => {
      if (this.platformService.isBrowser) {
        return this.window?.innerWidth > deviceMaxWidths.mobile && this.window?.innerWidth <= deviceMaxWidths.tablet
      }

      if (this.userAgentService.userAgent) {
        return this.userAgentService.userAgent.isTablet
      }

      return false
    })
  )

  public loadedFromDesktop$ = this.windowLoaded$.pipe(
    startWith(undefined),
    map(win => {
      if (this.platformService.isBrowser) {
        return this.window?.innerWidth > deviceMaxWidths.tablet
      }

      if (this.userAgentService.userAgent) {
        return this.userAgentService.userAgent.isDesktop
      }

      return false
    })
  )

  public loadedDevice$: Observable<DeviceType> = combineLatest([
    this.loadedFromDesktop$,
    this.loadedFromTablet$,
    this.loadedFromMobile$
  ]).pipe(
    map(([desktop, tablet, mobile]): DeviceType => {
      if (desktop) {
        return 'Desktop'
      } else if (tablet) {
        return 'Tablet'
      } else if (mobile) {
        return 'Mobile'
      } else {
        return 'Unknown'
      }
    })
  )

  /**
   * Creates a new DeviceService with the injected PlatformService and UserAgentService.
   */
  constructor(
    private platformService: PlatformService,
    private userAgentService: UserAgentService,
    @Inject(WINDOW) private readonly window: Window
  ) {}
}

export const deviceMaxWidths = {
  mobile: breakpointsInPixels.medium - 1,
  tablet: breakpointsInPixels.large - 1
}
