import { Directive, ElementRef, Input, OnInit } from '@angular/core'
import { OldSvgLoaderService } from './old-svg-loader.service'
import { take } from 'rxjs/operators'

const insertSvg = (el: HTMLElement, svg: string) => {
  el.innerHTML = svg
}

/**
 * @deprecated - We are now using the SVG Sprite Sheet
 * @see SvgIconComponent
 */
@Directive({
  selector: '[floSvg]'
})
export class OldSvgDirective implements OnInit {
  @Input() public floSvg: string

  constructor(private el: ElementRef, private svgLoader: OldSvgLoaderService) {}

  ngOnInit() {
    this.svgLoader
      .load(this.floSvg)
      .pipe(take(1))
      .subscribe(svg => insertSvg(this.el.nativeElement, svg))
  }
}
