import { SiteIds } from '../../models/site.model'
import { LogoData } from './logo-data.interface'

/**
 * This is a map containing the name (as the id), maxWidth, and title of the logo SVG
 * to reference in the logo sprite sheet from the SvgIconComponent.
 *
 * The SvgIconComponent pulls data from this map to know what SVG to render
 * and how wide the svg should be. This is necessary because each logo SVG
 * has different viewBox dimensions, and therefore need variable maxWidth attributes.
 *
 * For docs on updating the logoDataMap and logo sprite sheet, please reference the confluence doc below:
 * @link https://flocasts.atlassian.net/wiki/spaces/ENG/pages/2605614625/Working+with+SVG+in+the+WebApp
 */

export const logoDataMap = new Map<number, LogoData>([
  [SiteIds.FLOTRACK, { id: 'flotrack-hawk', maxWidth: 125.61, title: 'FloTrack' }],
  [SiteIds.FLOWRESTLING, { id: 'flowrestling-hawk', maxWidth: 179.08, title: 'FloWrestling' }],
  [SiteIds.FLOGYMNASTICS, { id: 'flogymnastics-hawk', maxWidth: 191.43, title: 'FloGymnastics' }],
  [SiteIds.FLOHOOPS, { id: 'flohoops-hawk', maxWidth: 127.84, title: 'FloHoops' }],
  [SiteIds.FLOGRAPPLING, { id: 'flograppling-hawk', maxWidth: 175.18, title: 'FloGrappling' }],
  [SiteIds.FLOCHEER, { id: 'flocheer-hawk', maxWidth: 124.55, title: 'FloCheer' }],
  [SiteIds.FLOELITE, { id: 'floelite-hawk', maxWidth: 113.76, title: 'FloElite' }],
  [SiteIds.FLOSPORTS, { id: 'flosports-hawk', maxWidth: 132.68, title: 'FloSports' }],
  [SiteIds.FLOSOFTBALL, { id: 'flosoftball-hawk', maxWidth: 162.08, title: 'FloSoftball' }],
  [SiteIds.FLOVOLLEYBALL, { id: 'flovolleyball-hawk', maxWidth: 188.24, title: 'FloVolleyball' }],
  [SiteIds.FLOCOMBAT, { id: 'flocombat-hawk', maxWidth: 142.42, title: 'FloCombat' }],
  [SiteIds.FLOMARCHING, { id: 'flomarching-hawk', maxWidth: 167.06, title: 'FloMarching' }],
  [SiteIds.FLOSWIMMING, { id: 'floswimming-hawk', maxWidth: 167.2, title: 'FloSwimming' }],
  [SiteIds.FLOHOCKEY, { id: 'flohockey-hawk', maxWidth: 142, title: 'FloHockey' }],
  [SiteIds.FLORODEO, { id: 'florodeo-hawk', maxWidth: 126.55, title: 'FloRodeo' }],
  [SiteIds.FLORACING, { id: 'floracing-hawk', maxWidth: 133.12, title: 'FloRacing' }],
  [SiteIds.FLOVOICE, { id: 'flovoice-hawk', maxWidth: 118.07, title: 'FloVoice' }],
  [SiteIds.FLORUGBY, { id: 'florugby-hawk', maxWidth: 127.05, title: 'FloRugby' }],
  [SiteIds.FLOLIVE, { id: 'flolive-hawk', maxWidth: 101.25, title: 'FloLive' }],
  [SiteIds.FLODANCE, { id: 'flodance-hawk', maxWidth: 126.06, title: 'FloDance' }],
  [SiteIds.FLOBIKES, { id: 'flobikes-hawk', maxWidth: 116.39, title: 'FloBikes' }],
  [SiteIds.FLOFOOTBALL, { id: 'flofootball-hawk', maxWidth: 163.4, title: 'FloFootball' }],
  [SiteIds.FLOBOWLING, { id: 'flobowling-hawk', maxWidth: 153.12, title: 'FloBowling' }],
  [SiteIds.FLOBASEBALL, { id: 'flobaseball-hawk', maxWidth: 161.6, title: 'FloBaseball' }],
  [SiteIds.FLOFC, { id: 'flofc-hawk', maxWidth: 80.82, title: 'FloFC' }],
  [SiteIds.FLODOGS, { id: 'flodogs-hawk', maxWidth: 111.97, title: 'FloDogs' }],
  [SiteIds.FLOCOLLEGE, { id: 'flocollege-hawk', maxWidth: 149.01, title: 'FloCollege' }]
])
