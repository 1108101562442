import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FlyOutComponent } from './fly-out.component'
import { ContainerModule } from '../container/container.module'
import { LinkModule } from '../link/link.module'
import { FlyOutCtaAccountComponent } from './ctas/fly-out-cta-account.component'
import { SvgModule } from 'src/app/shared/svg/svg.module'
import { FloCssClassSanitizePipeModule } from '../_pipes/css-class-sanitize/css-class-sanitize.pipe.module'
import { NavModule } from '../navigation/nav.module'
import { DropdownModule } from '../dropdown/dropdown.module'
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap'
import { AnalyticsModule } from 'src/app/shared/analytics/analytics.module'
import { SkeletonModule } from '../skeletons/skeleton/skeleton.module'

@NgModule({
  imports: [
    CommonModule,
    ContainerModule,
    LinkModule,
    SvgModule,
    FloCssClassSanitizePipeModule,
    NavModule,
    DropdownModule,
    NgbDropdownModule,
    AnalyticsModule,
    SkeletonModule
  ],
  declarations: [FlyOutComponent, FlyOutCtaAccountComponent],
  exports: [FlyOutComponent, FlyOutCtaAccountComponent]
})
export class FlyOutModule {}
