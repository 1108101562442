import { SearchTab } from 'src/app/search/search.interfaces'

export enum SegmentEvents {
  /**
   * User submits email & password to go to pay card.
   */
  ACCOUNT_CREATED = 'Account Created - Client',

  /**
   * User successfully logs in (via email or facebook).
   */
  ACCOUNT_LOGGED_IN = 'Account Logged In - Client',

  /**
   * User logs out.
   */
  ACCOUNT_LOGGED_OUT = 'Account Logged Out',

  /**
   * User is using an ad blocker.
   */
  AD_BLOCKED = 'Ad Blocked',

  /**
   * User clicks on ad.
   */
  AD_CLICKED = 'Ad Clicked',

  /**
   * The ad has started playing.
   */
  AD_STARTED = 'Ad Started',

  /**
   * The ad has paused.
   */
  AD_PAUSED = 'Ad Paused',

  /**
   * The ad has resumed.
   */
  AD_RESUMED = 'Ad Resumed',

  /**
   * Fired when the ad playhead crosses first quartile.
   */
  AD_FIRST_QUARTILE = 'Ad First Quartile Completed',

  /**
   * Fired when the ad playhead crosses midpoint.
   */
  AD_MIDPOINT = 'Ad Midpoint Completed',

  /**
   * Fired when the ad playhead crosses third quartile.
   */
  AD_THIRD_QUARTILE = 'Ad Third Quartile Completed',

  /**
   * The ad has completed.
   */
  AD_COMPLETED = 'Ad Completed',

  /**
   * The ad has errored out.
   */
  AD_ERRORED = 'Ad Errored',

  /**
   * Ad slot requested to be filled (not to be confused with GPT ad viewability)
   */
  AD_VIEWED = 'Ad Viewed',

  /**
   * User clicks a button
   */
  BUTTON_CLICKED = 'Button Clicked',

  /**
   * User clicks a date in calendar date-picker
   */
  CALENDAR_DATE_CLICKED = 'Filter Clicked',

  /**
   * User clicks a month in month-picker
   */
  CALENDAR_MONTH_CLICKED = 'Filter Clicked',

  /**
   * When a coupon is applied
   */
  COUPON_APPLIED = 'Coupon Applied',

  /**
   * User clicks an option within the dropdown
   */
  DROPDOWN_OPTION_SELECTED = 'Dropdown Option Selected',

  /**
   * User clicks an option within the dropdown
   */
  DROPDOWN_OPTION_DESELECTED = 'Dropdown Option Deselected',

  /**
   * Error displays while user is filling out form
   */
  ERROR_DISPLAYED = 'Error Displayed',

  /**
   * When an experiment is viewed by a user
   */
  EXPERIMENT_VIEWED = 'Experiment Viewed',

  /**
   * User submits a form
   */
  FORM_SUBMITTED = 'Form Submitted',

  /**
   * Infinite scroll fires and requests a batch of data
   */
  INFINITE_SCROLL = 'Infinite Scroll',

  /**
   * User clicks into an input field
   */
  INPUT_FIELD_CLICKED = 'Input Field Clicked',

  /**
   * User clicks a link
   */
  LINK_CLICKED = 'Link Clicked',

  /**
   * User closes a modal
   */
  MODAL_DISMISSED = 'Modal Dismissed',

  /**
   * User views a modal
   */
  MODAL_DISPLAYED = 'Modal Displayed',

  /**
   * Performance metrics have been recorded for a page
   */
  NAVIGATION_TIMED = 'Navigation Timed',

  /**
   * User subscribes for newsletter via newsletter widget
   */
  NEWSLETTER_SUBSCRIBED = 'Newsletter Subscribed',

  /**
   * User views a newsletter widget
   */
  NEWSLETTER_WIDGET_DISPLAYED = 'Newsletter Widget Displayed',

  /**
   * When user successfully updates their credit card
   */
  PAYMENT_CARD_UPDATED = 'Payment Card Updated',

  /**
   * User successfully submits payment.
   */
  PAYMENT_MADE = 'Payment Made - Client',
  GIFT_REDEEMED = 'Gift Redeemed - Client',

  /**
   * User performs a search on our site.
   */
  SEARCH_QUERY_MADE = 'Search Query Made',

  /**
   * Results returned from a search query.
   */
  SEARCH_RESULTS = 'Search Results',

  /**
   * When the x is clicked or tapped on the search input form.
   */
  SEARCH_CLEARED = 'Search Cleared',

  /**
   * When a user gets special pricing offered (via queryParam)
   */
  SPECIAL_PRICING_APPLIED = 'Special Pricing Applied',

  /**
   * When a user views a preview of a live stream
   */
  STREAM_PREVIEW_DISPLAYED = 'Stream Preview Displayed',

  /**
   * User clicks a button
   */
  TAB_CLICKED = 'Tab Clicked',

  /*
   * User clicks a toggle
   */
  TOGGLE_CLICKED = 'Toggle Clicked'
}

// Dropdown Events
export enum DROPDOWN_NAME_TYPE {
  SORT_BY = 'Sort By'
}

export enum DROPDOWN_OPTION_TYPE {
  MOST_WATCHED = 'Most Watched',
  MOST_RECENT = 'Most Recent',
  MOST_RELEVANT = 'Most Relevant'
}

/*
 * Many segment events below will have two name properties
 * The outer name represents the Event Name
 * The inner name under the `properties` object
 * represents the name of the particular element that has had an action
 */

export interface SegmentDropdownOptionSelected {
  name: SegmentEvents.DROPDOWN_OPTION_SELECTED
  properties: {
    name: DROPDOWN_NAME_TYPE
    sort_by: DROPDOWN_OPTION_TYPE
    node: undefined
  }
}

export interface SegmentSearch {
  name: SegmentEvents.SEARCH_QUERY_MADE | SegmentEvents.SEARCH_CLEARED
  properties: {
    search_term: string
    name: string
  }
}

/**
 * Represents the Search Results event in Segment.
 * This event is fired when a user performs a search on the Search Page exclusively
 * https://flocasts.atlassian.net/browse/OFP-1723
 */
export interface SegmentSearchResults {
  name: SegmentEvents.SEARCH_RESULTS
  properties: {
    search_term: string | undefined
    search_tabs: readonly SearchTab[] | undefined
    results: { node_id: number; node_type: string; title: string }[] | null
  }
}

export interface InfiniteScroll {
  name: SegmentEvents.INFINITE_SCROLL
  properties: {
    page: number
  }
}

export interface ButtonClicked {
  name: SegmentEvents.BUTTON_CLICKED
  properties: {
    name: string
    text?: string
    action?: 'Click'
  }
}

export interface PaymentMade {
  name: SegmentEvents.PAYMENT_MADE
  properties: {
    revenue: string | undefined
    currency: string | undefined
    payment_amount?: number
    payment_processor: 'Stripe'
    payment_level: string
    universal: true
    platform: 'Android' | 'Web'
    is_gifter: boolean | undefined
  }
}

export interface GiftRedeemed {
  name: SegmentEvents.GIFT_REDEEMED
  properties: {
    payment_level: string
    is_gifter: boolean
  }
}

export interface SegmentInputFieldClicked {
  name: SegmentEvents.INPUT_FIELD_CLICKED
  properties: {
    name: string
  }
}

export interface SegmentLinkClicked {
  name: SegmentEvents.LINK_CLICKED
  properties: {
    name: string
    item_order?: number
    array_length?: number
    node_id?: number
    node_type?: string
    primary_association_id?: number | null
    primary_association_title?: string | null
    is_premium?: boolean
  }
}

export interface SegmentCalendarDateClicked {
  name: SegmentEvents.CALENDAR_DATE_CLICKED
  properties: {
    name: string // YYYY-MM-DD
  }
}

export interface SegmentTabClicked {
  name: SegmentEvents.TAB_CLICKED
  properties: {
    name: string
  }
}

export interface ToggleClickedByKeyboard {
  name: SegmentEvents.TOGGLE_CLICKED
  properties: {
    name: string
    action: 'Keyboard Input'
  }
}

/**
 * Full list of segment track event types
 * for trackEvent(event: SegmentTrackEvent)
 */

export type SegmentTrackEvent =
  | SegmentDropdownOptionSelected
  | SegmentInputFieldClicked
  | SegmentLinkClicked
  | SegmentSearch
  | SegmentTabClicked
  | InfiniteScroll
  | ButtonClicked
  | PaymentMade
  | GiftRedeemed
  | SegmentCalendarDateClicked
  | ToggleClickedByKeyboard
  | SegmentSearchResults
