import { NgModule } from '@angular/core'

import { CommonModule } from '@angular/common'
import { ElementIsVisibleDirective } from './element-is-visible.directive'

// tslint:disable-next-line:readonly-array

@NgModule({
  imports: [CommonModule],
  declarations: [ElementIsVisibleDirective],
  exports: [ElementIsVisibleDirective]
})
export class ElementIsVisibleModule {}
