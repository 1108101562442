import { Injectable, Inject, InjectionToken } from '@angular/core'
import { parse } from 'uri-js'
import { LoggingService } from 'src/app/singleton-services/logging/logging.service'
import { maybe } from 'typescript-monads'

export interface IUrlService {
  // Returns hostname with TLD only
  // ex: api.flodogs.com => flodogs.com
  parseBaseDomain(url: string): string

  // Returns hostname with no TLD
  // ex: api.flodogs.com => flodogs
  parseRootDomain(url: string): string

  // decodes a URL formatted string into a human readable version
  decode(url?: string | number | boolean): string | undefined
  getHost(url: string): string | undefined
}

export const maybeParsedHost = (url: string) =>
  maybe(parse(url.includes('://') ? url : `http://${url}`)).flatMapAuto(p => p.host)

export const splitHostFromUrl = (url: string) => url.split('.').slice(-2)

const FALLBACK_HOST = 'flodogs'
const HOST_FAILURE_MSG = 'Unable to determine url host'
export const PASSTHROUGH_DOMAINS = new InjectionToken<ReadonlyArray<string>>('fs.wl.ck.hosts')

/**
 * @Deprecated
 *
 * We prefer utility function files rather than adding functions to services
 * @see url.functions.ts
 */
@Injectable({
  providedIn: 'root'
})
export class UrlService implements IUrlService {
  constructor(private ls: LoggingService, @Inject(PASSTHROUGH_DOMAINS) private whitelisted: ReadonlyArray<string>) {}

  getComponents = (url: string) => parse(url)
  getHost = (url: string) => this.getComponents(url).host

  decode(url?: string): string | undefined {
    try {
      return maybe(url)
        .filter(u => typeof u === 'string' || typeof u === 'number' || typeof u === 'boolean')
        .map(decodeURI)
        .valueOrUndefined()
    } catch (e) {
      this.ls.warn(`URI failed to parse: ${url}, returning undefined`, e)
      return undefined
    }
  }

  parseBaseDomain = (url: string) =>
    maybeParsedHost(url)
      .map(splitHostFromUrl)
      .map(host => host.join('.'))
      .valueOrThrow(`${HOST_FAILURE_MSG}-${url}`)

  public parseRootDomain(url: string) {
    return this.whitelisted.some(w => url.includes(w))
      ? FALLBACK_HOST
      : maybeParsedHost(url)
          .map(splitHostFromUrl)
          .flatMapAuto(host => host[0])
          .valueOrThrow(`${HOST_FAILURE_MSG}-${url}`)
  }
}
