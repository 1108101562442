import { Component, ChangeDetectionStrategy, EventEmitter, Input, Output } from '@angular/core'
import { Router } from '@angular/router'
import { LinkModel } from '@flocasts/experience-service-types'

@Component({
  selector: 'flo-slim-header',
  templateUrl: `./slim-header.component.html`,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SlimHeaderComponent {
  @Input() public title: string
  @Input() public cta: string | undefined

  @Input() public link: LinkModel

  @Output() public ctaClicked = new EventEmitter<boolean>()

  constructor(private readonly router: Router) {}

  public ctaClick(link: LinkModel) {
    if (this.ctaClicked.observed) {
      this.ctaClicked.emit(true)
    } else if (!!link.url) {
      this.router.navigateByUrl(link.url)
    }
  }
}
