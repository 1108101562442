import { VodMetadata } from '@flocasts/experience-service-types'
import { isVodMetadata } from 'src/app/shared/functions/type-guards'
import { IVideoScrubber } from '../../../../live/components/video-player/video-player.interfaces'
import { VideoInput } from './video-player.component'
import Hls from 'hls.js'

export const showVideoPreRollAd = (
  videoStatus: number | undefined,
  adBlockerIsActive: boolean,
  didPlayAd: boolean,
  isPrerollEligible?: boolean
): boolean | undefined => {
  const isDraft = !isPrerollEligible && videoStatus === 0
  return !adBlockerIsActive && !didPlayAd && !isDraft
}

/**
 * This function standardizes the rendition string to this format 'WxH@bitrate'
 * For instance if width is 1080, height is 700 and bitrate is 5000000
 * the output would be 1080x700@5000kbps
 *
 * @param width - video's width
 * @param height - video's height
 * @param bitrate - video's bitrate
 */
export function buildRenditionString(
  width?: string | number,
  height?: string | number,
  bitrate?: string | number
): string | null {
  let rendition = ''

  if (width && height) {
    rendition = width + 'x' + height
  } else if (width) {
    rendition = String(width)
  }

  if (typeof bitrate !== 'number' || isNaN(bitrate) || bitrate < 1) {
    return rendition
  } else if (rendition && rendition !== '') {
    rendition += '@' + Number(bitrate / 1000) + 'kbps'
  } else {
    rendition = Number(bitrate / 1000) + 'kbps'
  }

  return rendition || null
}

/**
 * TODO: Could this be more generic
 * IVideoScrubber is also an outdated interface name that could maybe be tweaked.
 */
export const getFlooredTime = (videoTime: IVideoScrubber): IVideoScrubber => {
  return {
    ...videoTime,
    currentTime: Math.floor(videoTime.currentTime)
  }
}

/**
 * This functions extracts the video's bitrate fron the hlsInstance
 * @param hls - video's hlsInstance
 */
export const getBitrateFromHls = (hls: Hls): number | undefined => {
  const currentLevel = hls.currentLevel
  const index = currentLevel >= 0 ? currentLevel : hls?.loadLevel
  return hls.levels[index]?.bitrate
}

/*
 * String comes from query params so we can't be sure it's a valid time
 * Confirm it's a number and return that number, otherwise default to 0 (beginning of video)
 */
export const getSecondsFromString = (value: string): number => {
  if (!!value) {
    const seconds: number = Number(value)
    if (!isNaN(seconds) && seconds > 0) {
      return Math.floor(seconds)
    }
  }
  return 0
}

/*
 * Given a valid number in seconds (0 < seconds < duration)
 * Return start time of the video. Otherwise return 0
 */
export const getStartTime = (seconds: number, duration: number): number => {
  return seconds > duration || seconds < 0 ? 0 : seconds
}

/**
 * Maps new BFF VodMetadata to legacy VideoInput type
 * or simply returns the VideoInput for backwards compat.
 * Many of these properties are not used in the VOD player or the BFF returns them as optional properties.
 * Therefore there are multiple ts-ignores since the types are incompatible.
 * @param video The video.
 * @returns VideoInput needed for the VOD player.
 */
export const getVideoInput = (video: VideoInput | VodMetadata): VideoInput => {
  if (isVodMetadata(video)) {
    return {
      id: video.nodeId,
      playlist: video.playlist,
      playlist_no_audio: video.playlist,
      no_audio: video.noAudio,
      short_title: video.shortTitle || undefined,
      premium: video.isPremium,
      shareable_link: video.shareableLink || undefined,
      title: video.title,
      is_preroll_eligible: video.isPrerollEligible,
      collectionSlug: video.analytics?.collectionSlug,
      aggregated_node_ids: video.adTargeting?.aggregatedNodeIds || [],
      aggregated_category_slugs: video.adTargeting?.aggregatedCategorySlugs || [],
      node: {
        id: video.nodeId,
        // @ts-ignore created_at isn't used by the VOD player, but is a required prop.
        created_at: video.startDate ? new Date(video.startDate) : video.startDate,
        primary_event_association: {
          // @ts-ignore primary_event_assocation isn't used by the VOD player, but is a required prop.
          id: video.analytics?.primaryAssociationId || null,
          // @ts-ignore title is a required field, but optional on the VOD player
          title: video.analytics?.primaryAssociationTitle || null,
          author: null,
          // @ts-ignore slug is a required field on VideoInput but not used in the VOD player
          slug: video.analytics?.collectionSlug || null,
          // @ts-ignore slug_uri is a required field on VideoInput but not used in the VOD player
          slug_uri: video.analytics?.slugUri || null
        },
        primary_event_or_series_association: {
          // @ts-ignore primary_event_or_series_association is used by the VOD player anlaytics, but only if an id exists.
          // This is a nullable field on BFF so the types do not align.
          id: video.analytics?.primaryAssociationId || null,
          // @ts-ignore title is a required field, but optional on the VOD player
          title: video.analytics?.primaryAssociationTitle || null,
          // @ts-ignore short_title is a required field, but optional on the VOD player
          short_title: video.analytics?.primaryAssociationShortTitle || null,
          author: null,
          // @ts-ignore slug is a required field on VideoInput but not used in the VOD player
          slug: video.analytics?.collectionSlug || null,
          // @ts-ignore slug_uri is a required field on VideoInput but not used in the VOD player
          slug_uri: video.analytics?.slugUri || null
        },
        tags: []
      },
      check_geo_restriction: video.checkGeoRestriction,
      // @ts-ignore slug is a required field on VideoInput but not used in the VOD player
      slug: video.analytics?.collectionSlug || null,
      // @ts-ignore slug_uri is a required field on VideoInput but not used in the VOD player
      slug_uri: video.analytics?.slugUri || null,
      playlist_with_ads: null,
      watermark: null,
      author: null,
      asset:
        !!video.assetUrl && !!video.assetId
          ? {
              id: video.assetId,
              title: video.title,
              url: video.assetUrl
            }
          : undefined
    }
  }
  return video
}
