import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ExtraLargeContentCardComponent } from './extra-large-content-card.component'
import { AnalyticsModule } from '../../../shared/analytics/analytics.module'
import { RouterModule } from '@angular/router'
import { ThumbnailModule } from '../../../shared/thumbnails/thumbnail.module'
import { CustomLinkModule } from '../../custom-link/custom-link.directive.module'
import { FloPipeModule } from '../../../shared/pipes/flo-pipe.module'

@NgModule({
  imports: [CommonModule, AnalyticsModule, RouterModule, ThumbnailModule, CustomLinkModule, FloPipeModule],
  declarations: [ExtraLargeContentCardComponent],
  exports: [ExtraLargeContentCardComponent]
})
export class ExtraLargeContentCardModule {}
