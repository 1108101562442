import { ParamMap } from '@angular/router'
import { EMPTY, Observable, of } from 'rxjs'

/** Get a value from a map, or immediately complete */
export const getOrComplete =
  (key: string) =>
  (m: ParamMap): Observable<string> => {
    const val = m.get(key)
    return val === null ? EMPTY : of(val)
  }

/**
 * Get the last, valid element of a query param array
 *
 * Use: for params, if multiple query string params of the same key are
 * present, CookieService.prototype.get() returns an array. For instance,
 * /whatever?sp=abc&redirect=example&sp=dce , get() will return ['abc', 'dce'].
 * This can cause problems with things like special pricing, so we only
 * want the final element in the array, if it is an array.
 *
 */
export function getLastValidParamValue(param: unknown): string | undefined {
  if (Array.isArray(param)) {
    for (let i = param.length - 1; i >= 0; i--) {
      const qp: string | undefined | null = param[i]
      if (!!qp && qp?.length !== 0) {
        return qp
      }
    }
  }
  if (typeof param === 'string') return param
  return param as undefined
}
