<div class="event-ticker-wrapper" data-test="event-ticker">
  <ng-container *ngIf="eventTickerViewModel">
    <div class="event-ticker w-100 d-flex flex-grow-1 justify-content-between bg-white">
      <swiper [config]="swiperConfig">
        <ng-container *ngFor="let section of eventTickerViewModel.sections; trackBy: trackById;">
          <ng-container *ngFor="let item of section.items; trackBy: trackById; let first = first; let last = last;">
            <ng-template swiperSlide>
              <div
                class="event-item w-100 d-flex flex-column bg-white py-2 px-3 h-100 primary-color-text-hover border-end border-grey-300"
                [ngClass]="{'border-start': first, 'border-end-0': last && section.items.length > 3}">
                <a class="w-100 h-100" floAnalytics [analyticsProperties]="item.action?.analytics"
                  [floCustomLink]="item.action.url">
                  <div class="footnote text-truncate color-700 d-flex">
                    <ng-container *ngIf="item.favoriteIcon">
                      <div class="favorites-star d-flex align-items-center color-ignite">
                        <flo-svg-icon
                          [icon]="item.favoriteIcon.style"
                          [title]="item.favoriteIcon.text"
                          [altText]="item.favoriteIcon.accessibility.altText"
                          height="13"
                          class="d-flex align-items-center color-ignite svg-vertical-align-middle"
                        ></flo-svg-icon>
                      </div>
                    </ng-container>
                    <flo-event-status [props]="item.label1Parts" showBadge="true"></flo-event-status>
                  </div>
                  <h6 [ngClass]="{
                    'text-truncate': !!item.subtitle1,
                    'text-line-clamp-2': !item.subtitle1
                  }" class="h6 mb-0 title-max-width apply-text-hover" data-test="event-ticker-title">
                    {{ item.title }}
                  </h6>
                  <div *ngIf="item.subtitle1" class="subtitle1 font-size-2">{{ item.subtitle1 }}</div>
                </a>
              </div>
            </ng-template>
          </ng-container>
        </ng-container>
        <ng-template swiperSlide>
          <div class="d-flex right-section h-100 d-flex d-md-none">
            <ng-container *ngTemplateOutlet="scheduleLink"></ng-container>
          </div>
        </ng-template>
      </swiper>

      <!-- scroll buttons and full schedule link - shown at medium breakpoint and up -->
      <div class="d-none d-md-flex border-start border-grey-300">
        <div
          *ngIf="eventTickerViewModel.forward && eventTickerViewModel.backward"
          class="nav-icons-wrapper d-flex flex-column bg-white color-500 cursor-pointer h-100 align-items-center">
          <a floAnalytics [tagName]="'Button'" [analyticsProperties]="eventTickerViewModel.backward.analytics"
            class="svg-vertical-align-middle d-flex flex-1 px-2 {{swiperBackwardId}}">
            <flo-svg-icon class="d-block m-auto px-1" icon="{{backwardIcon}}" width="1em"></flo-svg-icon>
          </a>
          <a floAnalytics [tagName]="'Button'" [analyticsProperties]="eventTickerViewModel.forward.analytics"
            class="svg-vertical-align-middle d-flex flex-1 px-2 {{swiperForwardId}} border-top border-grey-300">
            <flo-svg-icon class="d-block m-auto px-1" icon="{{forwardIcon}}" width="1em"></flo-svg-icon>
          </a>
        </div>
        <ng-container *ngTemplateOutlet="scheduleLink"></ng-container>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #scheduleLink>
  <div *ngIf="!!eventTickerViewModel && !!eventTickerViewModel.action" class="d-flex bg-200 justify-content-center align-items-center border-start border-grey-300 color-900 subhead-bold schedule-link">
    <flo-link [link]="eventTickerViewModel.action"></flo-link>
  </div>
</ng-template>
