import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { MessageModel } from '@flocasts/experience-service-types'

@Component({
  selector: 'flo-message',
  template: `
    <ng-container *ngIf="message">
      <div data-test="message" class="color-900 d-flex flex-column">
        <span class="headline text-center">{{ message.subtitle1 }}</span>
        <span data-test="no-results-text" class="h2 text-center bold">{{ message.title }}</span>
        <p class="headline text-center">{{ message.text }}</p>
        <ng-container *ngIf="message.action">
          <a floAnalytics [routerLink]="message.action.url" class="text-center">
            {{ message.action.title }}
          </a>
        </ng-container>
      </div>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageComponent {
  @Input() message: MessageModel
}
