<nav class="primary-nav-container bg-900" data-test="navigation" aria-label="primary navigation">
  <div class="d-flex px-3 primary-nav">
    <div class="d-flex align-items-center flex-grow-1">
      <div class="d-inline-flex align-items-center h-100 pe-1">
        <flo-link *ngIf="nav?.hamburgerLink" [link]="nav.hamburgerLink" tagName="Button" (click)="handleHamburgerClicked()" data-test="hamburger-icon" aria-label="open flyout menu"></flo-link>
        <a data-test="nav-logo-wrapper" *ngIf="nav?.logoLink" floAnalytics [analyticsProperties]="nav.logoLink.analytics" [floCustomLink]="nav.logoLink.url" class="primary-logo ms-1 ms-lg-25 me-lg-4 d-flex w-100 h-100 align-items-center flex-grow-1" tabindex="0">
          <flo-svg-logo class="d-none d-lg-inline-flex logo" [siteId]="siteId" data-test="logo"></flo-svg-logo>
          <flo-svg-logo class="d-inline-flex d-lg-none logo" [siteId]="siteId" [isResponsive]="true" data-test="logo"></flo-svg-logo>
        </a>
      </div>
      <div #primaryLinksContainer data-test="nav-primary-links" class="d-flex align-items-center flex-grow-1">
        <ng-container *ngIf="(amountOfLinksShown$ | async) as amountOfLinksShown">
          <flo-link
            [id]="primaryLink.id"
            *ngFor="let primaryLink of nav?.primaryLinksDesktop | slice:0:(amountOfLinksShown); trackBy: trackById"
            class="h-100 d-none d-lg-block"
            [link]="primaryLink">
          </flo-link>
          <div *ngIf="(amountOfLinksShown) < nav?.overflowLinks.length" class="d-flex align-items-center">
            <div class="d-none d-lg-flex align-items-center">
              <a
                class="icon-ellipsis-dropdown"
                role="button"
                ngbDropdown
                ngbDropdownToggle
              >
                <flo-link-menu
                  ngbDropdownMenu
                  id="nav-overflow-dropdown"
                  [options]="nav?.overflowLinks | slice:(amountOfLinksShown)"
                >
                </flo-link-menu>
                <flo-svg-icon
                  *ngIf="nav?.overflowIcon"
                  [icon]="nav.overflowIcon.style"
                  width="1.5em"
                  class="svg-vertical-align-middle color-400 h-100 d-flex align-items-center"
                >
                </flo-svg-icon>
              </a>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div data-test="aux-links-desktop" class="d-none d-lg-flex align-items-center">
      <div class="d-flex align-items-center h-100">
        <flo-link class="aux-links-desktop h-100" *ngFor="let auxLinkDesktop of nav?.auxLinksDesktop; trackBy: trackById;" [link]="auxLinkDesktop"></flo-link>
        <a
          *ngIf="nav?.accountLink && nav?.accountDropdownLinks"
          role="button"
          class="h-100 border-start border-end border-grey-800"
          data-test="account-link"
          ngbDropdown
          #accountDropdown="ngbDropdown"
          ngbDropdownAnchor
          (mouseenter)="accountDropdown.open()"
          (mouseleave)="accountDropdown.close()"
        >
          <flo-link-menu
            ngbDropdownMenu
            id="account-dropdown"
            [options]="nav.accountDropdownLinks"
          >
          </flo-link-menu>
          <flo-link *ngIf="nav?.accountLink" [link]="nav.accountLink"></flo-link>
        </a>
      </div>
      <flo-link *ngIf="nav?.ctaButton" [link]="nav.ctaButton" class="ms-25"></flo-link>
    </div>
    <div data-test="aux-links-mobile" class="d-flex d-lg-none align-items-center ms-25 gap-3 gap-sm-25">
      <flo-link class="d-xs-block d-sm-none" *ngFor="let item of nav?.auxLinksMobile | removeTitle:(!!nav?.ctaButton); trackBy: trackById;" [link]="item"></flo-link>
      <flo-link class="d-none d-sm-block" *ngFor="let item of nav?.auxLinksMobile; trackBy: trackById;" [link]="item"></flo-link>
      <flo-link *ngIf="nav?.ctaButton" [link]="nav.ctaButton" class="mobile-primary-nav-subscribe-button"></flo-link>
    </div>
  </div>
  <div *ngIf="nav?.primaryLinksMobile" class="d-flex d-lg-none px-2 overflow-auto border-top border-grey-800 primary-nav-mobile-links">
    <flo-link *ngFor="let item of nav.primaryLinksMobile; trackBy: trackById;" [link]="item"></flo-link>
  </div>
</nav>
