import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs'
import { share, startWith, map, tap } from 'rxjs/operators'

export interface SelectInputOptions {
  [key: string]: string | object
}

@Component({
  selector: 'flo-select-input',
  styleUrls: ['./select-input.component.scss'],
  templateUrl: './select-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SelectInputComponent,
      multi: true
    }
  ]
})
export class SelectInputComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
  @Input()
  set options(val: SelectInputOptions) {
    this.optionsSource.next(val)
  }

  get select() {
    return this._select.nativeElement as HTMLSelectElement
  }

  constructor(private renderer: Renderer2) {}
  private valueSub = new Subscription()
  private optionsSource = new BehaviorSubject<SelectInputOptions>({})
  private selectedSource = new Subject()
  private options$ = this.optionsSource.pipe(share())

  @Output() readonly selected$ = this.selectedSource.pipe(share())

  @Input() label: string
  @Input() labelClass: string
  @Input() initial = ''
  @ViewChild('select') _select: any

  view$: Observable<any>
  onChange(_: any) {
    // void
  }

  ngAfterViewInit() {
    this.valueSub = combineLatest([
      this.options$.pipe(startWith(this.optionsSource.getValue())),
      this.selected$.pipe(startWith(this.initial))
    ])
      .pipe(
        map(([options, selected]) => {
          return {
            options: Object.keys(options || {}).map(key => {
              return {
                selectValue: key,
                shownValue: options[key],
                selected: key === selected
              }
            })
          }
        }),
        tap(a => {
          this.removeOptionElements()
          a.options
            .map(option => this.createOptionElement(option))
            .forEach(elm => {
              this.renderer.appendChild(this.select, elm)
            })
        })
      )
      .subscribe()
  }

  change($event: any) {
    this.onChange($event.target.value)
    this.selectedSource.next($event.target.value)
  }

  writeValue(obj: any): void {
    this.selectedSource.next(obj)
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn
  }

  registerOnTouched(fn: any): void {
    // not implemented
  }

  ngOnDestroy() {
    this.valueSub.unsubscribe()
  }

  createOptionElement(option: any): HTMLOptionElement {
    const element = this.renderer.createElement('option') as HTMLOptionElement
    if (option.selected) this.renderer.setAttribute(element, 'selected', option.selected.toString())
    this.renderer.setProperty(element, 'value', option.selectValue.toString())
    this.renderer.setValue(element, option.shownValue.toString())
    element.innerText = option.shownValue.toString()
    return element
  }

  removeOptionElements(): void {
    Array.from(this.select.children).forEach(el => this.renderer.removeChild(this.select, el))
  }
}
