import { Pipe, PipeTransform } from '@angular/core'
import { convertSecondsToDuration } from '../functions/date-time'

/** Transforms a given number of seconds into an h:m:s timestamp. */
@Pipe({ name: 'floTime' })
export class FloTimePipe implements PipeTransform {
  public transform(n: number) {
    return convertSecondsToDuration(n)
  }
}
