import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { SiteIds } from 'src/app/shared/models/site.model'

@Component({
  selector: 'flo-simple-header',
  templateUrl: './simple-header.component.html',
  styleUrls: ['./simple-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleHeaderComponent {
  @Input()
  public siteId: SiteIds

  constructor() {}
}
