import { LiveEventsService } from '../../singleton-services/live-events.service'
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { catchError, take } from 'rxjs/operators'
import { HttpErrorResponse } from '@angular/common/http'
import { LiveEvent } from '@flocasts/flosports30-types/dist/entity'
import { getIdFromSlug } from '../utility-functions/slug.utility'

@Injectable()
export class LiveEventResolver implements Resolve<any> {
  constructor(private les: LiveEventsService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<LiveEvent | { error: number; message: string }> {
    const liveId = getIdFromSlug(route.params.id)

    return this.les.getLiveEvent(liveId).pipe(
      take(1),
      catchError((err: HttpErrorResponse) => {
        return of({ error: err.status, message: err.message })
      })
    )
  }
}
