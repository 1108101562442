import { Inject, Injectable } from '@angular/core'
import { combineLatest, Observable } from 'rxjs'
import { VerticalService } from '../../singleton-services/vertical.service'
import { map, shareReplay, take } from 'rxjs/operators'
import { ResponsiveSources } from '../../shared/responsive-image/responsive-image.interface'
import { HttpClient } from '@angular/common/http'
import { LOGGER_SERVICE } from '../../logger/logger.config'
import { LoggerService } from '../../logger/logger.interface'
import { GROWTHBOOK_SERVICE, GrowthBookService } from 'src/app/shared/experimentation/growthbook/growthbook.utility'

export interface ResponsiveCarouselInstance extends CarouselInstance {
  sources?: ResponsiveSources
}

export type CarouselBackgroundStyleGen = (inst: CarouselInstance) => Record<string, string>

export const getCarouselBackgroundImage = (carouselInstance: CarouselInstance) => `url(${carouselInstance.imageUrl})`

export const carouselBackgroundGen =
  (exp: boolean): CarouselBackgroundStyleGen =>
  (carouselInstance: CarouselInstance) =>
    exp
      ? ({} as Record<string, string>)
      : {
          'background-image': getCarouselBackgroundImage(carouselInstance)
        }

export interface HeroData {
  title: string
  secondLine: string
  imageUrl: string
  logos: string
  mobileLogos: string
  videoUrl?: string
  buttonClass?: string
  thankYouPage?: ThankYouPageData
}

interface ThankYouPageData {
  welcomeContent: WelcomeContentModel
}

export interface WelcomeContentModel {
  description: string
  bgImageUrl: string
  videoUrl?: string
  isFavoriteFeatureVertical: boolean
}

export interface CarouselInstance {
  title: string
  paragraph: string
  label: string
  imageUrl: string
}

export interface BatmanDatum {
  hero: HeroData
  calendarHeadline: string
  carousel: ReadonlyArray<CarouselInstance>
}

interface IBatmanData {
  [key: number]: BatmanDatum
}

@Injectable()
export class BatmanDataService {
  private siteId$: Observable<number | undefined> = this.verticalService.siteId$.pipe(shareReplay(1))

  private getBatmanData$: Observable<IBatmanData> = this.http
    .get<IBatmanData>('./batman-data.json')
    .pipe(take(1), shareReplay(1))

  private batmanData$: Observable<BatmanDatum> = combineLatest([this.siteId$, this.getBatmanData$]).pipe(
    map(([siteId, batman]) => {
      if (!siteId) {
        this.logger.error('No site id returned in Batman Data Service')
        return batman[9001]
      }

      return batman[siteId]
    })
  )

  public getCalendarHeadline$: Observable<string> = this.batmanData$.pipe(
    map(data => data.calendarHeadline),
    take(1)
  )

  private readonly buttonClass$ = this.gb.isFeatureOn('upfront-annual-price-display').pipe(
    map(isOn => {
      return isOn ? 'btn-xlg' : ''
    }),
    shareReplay(1)
  )

  public readonly heroData$: Observable<HeroData> = combineLatest([this.batmanData$, this.buttonClass$]).pipe(
    map(([data, buttonClass]) => ({
      ...data.hero,
      buttonClass
    })),
    shareReplay()
  )

  public carouselData$: Observable<readonly ResponsiveCarouselInstance[]> = this.batmanData$.pipe(
    map(data => data.carousel),
    shareReplay()
  )

  constructor(
    private readonly verticalService: VerticalService,
    private readonly http: HttpClient,
    @Inject(GROWTHBOOK_SERVICE) private readonly gb: GrowthBookService,
    @Inject(LOGGER_SERVICE) private readonly logger: LoggerService
  ) {}
}
