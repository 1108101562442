/**
 * @deprecated `@ngx-meta/core` is deprecated. Use `PageMetaService` instead.
 * @see PageMetaService
 */
export enum PageTitlePositioning {
  /**
   * append page title after application name
   */
  AppendPageTitle = 0,
  /**
   * prepend page title before application name
   */
  PrependPageTitle = 10
}
