import { Injectable } from '@angular/core'
import { LocalStorageService } from 'ngx-webstorage'
import { TransferState } from '@angular/platform-browser'
import { SVG_TRANSFER_KEY } from './old-svg.tokens'
import { environment } from 'src/environments/environment'

export interface OldISvgCacheService {
  store(key: string, value: string): void
  retrieve(key: string): string | undefined
}

@Injectable({
  providedIn: 'root'
})
export class OldSvgCacheService implements OldISvgCacheService {
  private readonly CACHE_STATE_PREFIX = 'svg-cache-bust-'

  constructor(private lss: LocalStorageService, private ts: TransferState) {}

  public store(key: string, value: string) {
    this.lss.store(this.getCacheStateKey(key), environment.svgCacheBuster)
    this.lss.store(key, value)
  }

  public retrieve(key: string): string | undefined {
    const cacheState = this.lss.retrieve(this.getCacheStateKey(key)) as string | undefined
    if (cacheState !== environment.svgCacheBuster) {
      return undefined
    }
    const locallyCached = this.lss.retrieve(key) as string | undefined
    return locallyCached || this.readTransferStateCache(key)
  }

  private getCacheStateKey(s: string): string {
    return this.CACHE_STATE_PREFIX + s
  }

  private readTransferStateCache(name: string) {
    const raw = this.ts.get<{ [key: string]: string } | undefined>(SVG_TRANSFER_KEY, undefined)
    return raw && raw[name]
  }
}
