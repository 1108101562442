import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { OfferSelectionCardModel } from '@flocasts/experience-service-types'
import { trackById } from 'src/app/shared/utility-functions/track-by-id.utility'

@Component({
  selector: 'flo-offer-selection-card',
  templateUrl: './offer-selection-card.component.html',
  styleUrls: ['./offer-selection-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OfferSelectionCardComponent {
  /**
   * Card displaying offer pricing information
   */
  @Input()
  offer: OfferSelectionCardModel

  /**
   * Indicates if the offer is selected by the user
   */
  @Input()
  isSelected = false

  readonly trackById = trackById
}
