import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { LazyLoadModule } from 'lazy-load'
import { AdComponent } from './ad.component'
import { FloCssClassSanitizePipeModule } from '../_pipes/css-class-sanitize/css-class-sanitize.pipe.module'

@NgModule({
  imports: [CommonModule, LazyLoadModule, FloCssClassSanitizePipeModule],
  declarations: [AdComponent],
  exports: [AdComponent]
})
export class AdComponentModule {}
