import { SEOMetadata } from '@flocasts/experience-service-types'
import { WebsiteMeta } from './page-meta.interface'

/**
 * Function to convert experience service's SEOMetadata
 * to the WebsiteMeta established internally before experience service existed.
 */
export function convertSEOMetadataToWebsiteMeta(seoMetadata: SEOMetadata): WebsiteMeta {
  return {
    type: 'website',
    twitterCard: 'summary_large_image', // seoMetadata.card
    title: seoMetadata.title ?? '',
    description: seoMetadata.description ?? '',
    canonicalUrl: seoMetadata.canonicalUrl ?? '',
    siteName: null,
    image: {
      url: seoMetadata.imageUrl ?? '',
      /** @see DEFAULT_OG_IMAGE_HEIGHT */
      height: seoMetadata.imageHeight,
      /** @see DEFAULT_OG_IMAGE_WIDTH */
      width: seoMetadata.imageWidth
    }
  }
}
