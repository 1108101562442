import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { CouponStatus, CouponStatusService } from './coupon-status.service'
import { combineLatest, Observable } from 'rxjs'
import { map } from 'rxjs/operators'

interface ICouponStatusView {
  symbol: string | undefined
  message: string | undefined
  show: boolean
  messageClasses: any
}

@Component({
  selector: 'flo-coupon-status',
  templateUrl: './coupon-status.component.html',
  styleUrls: ['./coupon-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CouponStatusComponent {
  @Input() public couponHasBeenApplied = false

  public readonly messageClasses$: Observable<Record<string, boolean>> = this.couponStatusService.couponStatus$.pipe(
    map(status => ({
      'flo-coupon': true,
      'coupon-success': this.couponHasBeenApplied && status === CouponStatus.VALID,
      'coupon-failure': status === CouponStatus.ERROR
    }))
  )

  public readonly couponMessageSymbol$: Observable<string | undefined> = this.couponStatusService.couponStatus$.pipe(
    map(status => {
      switch (status) {
        case CouponStatus.VALID:
          return 'check'
        case CouponStatus.ERROR:
        case CouponStatus.NONE:
        default:
          return undefined
      }
    })
  )

  public readonly couponMessage$: Observable<string | undefined> = this.couponStatusService.message$

  public readonly showCoupon$: Observable<boolean> = this.couponStatusService.showCoupon$

  public readonly view$: Observable<ICouponStatusView> = combineLatest([
    this.couponMessageSymbol$,
    this.couponMessage$,
    this.showCoupon$,
    this.messageClasses$
  ]).pipe(
    map(([symbol, message, show, messageClasses]) => {
      return {
        symbol,
        message,
        show,
        messageClasses
      }
    })
  )

  public constructor(private readonly couponStatusService: CouponStatusService) {}
}
