import { Pipe, PipeTransform } from '@angular/core'
import { DatePipe } from '@angular/common'
import { legacyParse } from '@date-fns/upgrade/v2'

@Pipe({ name: 'floDate' })
export class FloDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(startDate: string, endDate?: string, month?: string, day?: string): string | null {
    /**
     * Parse will convert the value to a consistent instance of Date
     * because every browser does things differently (Fixes Safari and Firefox issues)
     */
    const momentStartDate = legacyParse(startDate)

    let momentEndDate
    if (endDate) {
      momentEndDate = legacyParse(endDate)
    }
    const _startDate = this.datePipe.transform(momentStartDate, 'MMM d')
    const _endDate = this.datePipe.transform(momentEndDate, 'MMM d')
    const startMonth = this.datePipe.transform(momentStartDate, 'M')
    const endMonth = this.datePipe.transform(momentEndDate, 'M')
    const startDay = this.datePipe.transform(momentStartDate, 'd')
    const startDayOfWeek = this.datePipe.transform(momentStartDate, 'EEEE')
    const endDay = this.datePipe.transform(momentEndDate, 'd')
    const bigMonth = this.datePipe.transform(momentStartDate, 'MMM')
    let displayDate: string | null

    if (month === 'month') {
      displayDate = bigMonth
    } else if (day === 'day') {
      displayDate = startDay
    } else if (day === 'dayOfWeek') {
      displayDate = startDayOfWeek
    } else if (!_endDate) {
      displayDate = _startDate
    } else if (startMonth === endMonth) {
      startDay === endDay ? (displayDate = _startDate) : (displayDate = `${_startDate}-${_endDate.slice(4, 7)}`)
    } else {
      displayDate = `${_startDate} - ${_endDate}`
    }
    return displayDate
  }
}
