import { Inject, Injectable } from '@angular/core'
import { Router, Params, ParamMap } from '@angular/router'
import { EnvironmentService } from '../../singleton-services/environment.service'
import { LocationService } from '../../singleton-services/location.service'
import {
  buildTrackWrestlingCreateAccountURL,
  buildTrackWrestlingLoginURL,
  ensureUrlHttps
} from './external-routing.utility'
import { ExternalRoutingService } from './external-routing.service'
import { LiveEvent } from '@flocasts/flosports30-types/dist/entity'
import {
  determineFinalDestinationFromQueryParams,
  shouldRouteToPlans
} from 'src/app/funnel/navigation/navigation.utility'
import { WINDOW } from 'src/app/app.injections'
import { LiveEventStatus } from '../../events/events.enum'

export interface IFinalNavigation {
  url: string
  isExternal: boolean
  type: 'redirect' | 'go' | 'next'
}

export interface ICheckoutNavigationService {
  navigateAfterLandingPage(
    queryParams: ParamMap,
    isInNewPricingArchVariation: boolean,
    liveEventRedirect?: { status: LiveEventStatus; eventId: number }
  ): void
  navigateAfterCreatingAccount(queryParams: Params, liveEvent: LiveEvent): void
  navigateAfterLogin(userIsLoggedIn: boolean, isFreeUser: boolean, queryParams: Params, liveEvent?: LiveEvent): void
  navigateToFinalDestination(queryParams: Params): void
}

@Injectable()
export class CheckoutNavigationService implements ICheckoutNavigationService {
  constructor(
    private router: Router,
    private readonly externalRoutingService: ExternalRoutingService,
    private readonly environmentService: EnvironmentService,
    private readonly ls: LocationService,
    @Inject(WINDOW) private readonly window: Window
  ) {}
  public navigateAfterLandingPage(
    queryParams: Params,
    isInNewPricingArchVariation: boolean,
    liveEventRedirect?: { status: LiveEventStatus; eventId: number }
  ): void {
    // If live event is PreAir or Concluded, redirect to event hub
    const qp =
      liveEventRedirect && liveEventRedirect.status !== LiveEventStatus.Live
        ? { ...queryParams, redirect: `/events/${liveEventRedirect.eventId}` }
        : queryParams

    if (isInNewPricingArchVariation) {
      this.router.navigate(['/account/change-offer/plans'], { queryParams: qp, queryParamsHandling: 'merge' })
    } else {
      this.router.navigate(['/create-account'], { queryParams: qp, queryParamsHandling: 'merge' })
    }
  }

  public navigateAfterCreatingAccount(queryParams: Params, liveEvent?: LiveEvent): void {
    const shouldSkipPlans = !shouldRouteToPlans(true, queryParams, liveEvent)
    if (shouldSkipPlans) {
      this.navigateToFinalDestination(queryParams)
    } else {
      this.router.navigate(['plans'], { queryParams })
    }
  }

  public navigateAfterLogin(userIsLoggedIn: boolean, isFreeUser: boolean, queryParams: Params, liveEvent?: LiveEvent) {
    // userIsLoggedIn ensures that if this were to somehow get invoked with an anonymous user,
    // they will never get routed to the plans page.
    if (userIsLoggedIn && shouldRouteToPlans(isFreeUser, queryParams, liveEvent)) {
      this.router.navigate(['plans'], { queryParams })
    } else {
      this.navigateToFinalDestination(queryParams)
    }
  }

  public navigateToPlans(queryParams: Params) {
    this.router.navigate(['plans'], { queryParams })
  }

  public navigateToFinalDestination(queryParams: Params) {
    const finalDestination = determineFinalDestinationFromQueryParams(queryParams)
    finalDestination.isExternal
      ? (this.window.location.href = finalDestination.url)
      : this.router.navigateByUrl(finalDestination.url)
  }

  public redirectTrackWrestlingUsersForCreateAccount(next: string | undefined): void {
    const redirectUrl = ensureUrlHttps(`${this.ls.location}/plans`)
    const destination = buildTrackWrestlingCreateAccountURL(redirectUrl, this.environmentService.config.env, next)
    this.externalRoutingService.redirectUser(destination)
  }

  public redirectTrackWrestlingUsersForLogin(): void {
    const destination = buildTrackWrestlingLoginURL(this.environmentService.config.env)
    this.externalRoutingService.redirectUser(destination)
  }
}
