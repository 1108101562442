import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core'
import {
  DecoratedTextCellModel,
  FilterModel,
  IconModel,
  ImageModel,
  LinkModel,
  NavModel,
  SearchModel,
  TextModel,
  WebDecoratedTextCellModel
} from '@flocasts/experience-service-types'
import { isLinkModel } from 'src/app/shared/functions/type-guards'
import { trackById } from 'src/app/shared/utility-functions/track-by-id.utility'

type NavItemType =
  | IconModel
  | TextModel
  | LinkModel
  | FilterModel
  | SearchModel
  | ImageModel
  | WebDecoratedTextCellModel
  | DecoratedTextCellModel

@Component({
  selector: 'flo-nav-sub',
  templateUrl: './nav-sub.component.html',
  styleUrls: ['./nav-sub.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubNavComponent implements OnChanges {
  @Input() readonly nav: NavModel
  @Input() selectedLink: LinkModel | undefined
  @Input() tagName?: string

  // Alert upstream consumers that a tab was clicked in case data needs to be re-fetched
  @Output() linkClicked = new EventEmitter<LinkModel>()

  navItems: Array<NavItemType>

  ngOnChanges(): void {
    if (!!this.selectedLink) {
      this.setLinkActive(this.selectedLink)
    } else {
      this.navItems = this.nav.items
    }
  }

  readonly trackById = trackById

  public handleLinkClick(link: LinkModel) {
    // The selected link has changed, a new link has been clicked.
    this.selectedLink = undefined
    // Perform optimistic update of UI selected state
    // If the clicked link causes a re-fetch of the nav, it will update via @Input and angular's change detection
    // For example, in the case of site nav, each click re-fetches the nav and it will rerender
    // However for entity hubs, tabs selection is client side only so we need this code to update the tabs
    this.setLinkActive(link)
    this.linkClicked.emit(link)
  }

  /**
   * Set the provided link active and remove active state on the other links.
   * @param link The selected link.
   */
  private setLinkActive = (link: LinkModel) => {
    this.navItems = this.nav.items.map((item: NavItemType): NavItemType => {
      if (isLinkModel(item)) {
        return item.id === link.id ? { ...item, state: 'selected' } : { ...item, state: 'unselected' }
      } else {
        return item
      }
    })
  }
}
