<div class="value-prop-wrapper centered" [ngClass]="{
  'svg-label': svgLabel
}">
  <flo-svg-icon
    *ngIf="svgLabel && label === 'checkmark'"
    class="d-flex svg-wrapper checkmark money-green svg-vertical-align-text-top"
    icon="icon-check-mark"
    [width]="svgWidth">
  </flo-svg-icon>
  <flo-svg-icon
    *ngIf="svgLabel && label === 'checkmark-green'"
    class="d-flex svg-wrapper checkmark money-green svg-vertical-align-text-top"
    icon="icon-check-mark"
    [width]="svgWidth">
  </flo-svg-icon>
  <flo-svg-icon
    *ngIf="svgLabel && label === 'x-mark'"
    class="d-flex svg-wrapper x-mark"
    width="24"
    icon="icon-times">
  </flo-svg-icon>
  <div *ngIf="!svgLabel" class="text-label subheader">
    {{ label }}
  </div>
  <p class="value-prop">{{ proposition }}</p>
</div>
