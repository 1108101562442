import { Injectable } from '@angular/core'

export interface IDynamicFaviconService {
  buildFavicons(url: string): ReadonlyArray<IDynamicFavicon>
}

export interface IDynamicFavicon {
  size: string
  url: string
}

@Injectable()
export class DynamicFaviconService implements IDynamicFaviconService {
  public buildFavicons(url: string): ReadonlyArray<IDynamicFavicon> {
    if (url === '') return []

    return [
      {
        size: '16x16',
        url: `${url}favicon-16x16.png`
      },
      {
        size: '32x32',
        url: `${url}favicon-32x32.png`
      },
      {
        size: '96x96',
        url: `${url}favicon-96x96.png`
      }
    ]
  }
}
