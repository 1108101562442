import { legacyParse } from '@date-fns/upgrade/v2'
import { addDays, format, intervalToDuration, isFuture } from 'date-fns'
import * as O from 'fp-ts/Option'
import * as P from 'fp-ts/pipeable'

/**
 * Safely create a valid date from a string or date.
 *
 * @see safeCreateDateU Use `safeCreateDateU` if you want to return undefined for invalid dates
 */
export function safeCreateDate(date: string | Date | undefined | null): O.Option<Date> {
  return P.pipe(
    O.fromNullable(date),
    O.chain((u: unknown) => {
      return typeof u === 'string' ? O.some(new Date(u)) : u instanceof Date ? O.some(u) : O.none
    }),
    O.chain((d: Date) => {
      return isNaN(d.getMilliseconds()) ? O.none : O.some(d)
    })
  )
}

/**
 * Create a valid Date or return undefined
 *
 * @see safeCreateDate Use `safeCreateDate` if you want to work Date in the Option wrapper
 */
export function safeCreateDateU(date: string | Date | undefined): Date | undefined {
  return O.toUndefined(safeCreateDate(date))
}

/** Create a string representation of a date, safe for all browsers. */
export const safeDateString = (s: string | number | Date, formatString = DEFAULT_UNICODE_TOKEN): string =>
  format(legacyParse(s), formatString)

export const DEFAULT_UNICODE_TOKEN = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"

/**
 * Formats a date to string
 * Will return a string relative to date if less than 4 days from todays date and not in the future
 * Otherwise will return Date as a string in format 'MMM d' if it is the same year as today
 * or 'MMM d, yyyy' if the year is different from today
 * @param date first input is type Date
 * @returns a formatted Date as a string (ex. 'Jan 1', 'Jan 1, 2018', '1d ago' )
 */
export function getFormattedDate(date: Date): string {
  const isLessThanFourDaysAgo = addDays(date, 4) > new Date()

  if (isLessThanFourDaysAgo && !isFuture(date)) {
    return getRelativeDate(date)
  } else {
    const dateFormat = new Date().getFullYear() === date.getFullYear() ? 'MMM d' : 'MMM d, yyyy'
    return format(date, dateFormat)
  }
}

/**
 * Takes a date and compares it to today's date then returns the difference in either days, hours, or minutes
 * @param date first input is type Date that will be compared to today's date
 * @returns a relative time from todays date (ex. 1d ago, 1h ago, or 1m ago)
 */
export function getRelativeDate(date: Date): string {
  const { days, hours, minutes } = intervalToDuration({ start: date, end: new Date() })

  if (days && days > 0) {
    return `${days}d ago`
  } else if (hours && hours > 0) {
    return `${hours}h ago`
  } else if (minutes && minutes > 0) {
    return `${minutes}m ago`
  } else {
    return '1m ago'
  }
}
