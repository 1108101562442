import { CUSTOMER_SERVICE_URL } from 'src/app/account-settings/customer-service'
import { SiteIds } from '../../shared/models/site.model'

// tslint:disable:max-line-length
export function getFAQ(verticalName: string | undefined, verticalId: number | undefined) {
  // If vertical.id is not present in JSON list, use the default
  const site = {
    id: verticalId ?? SiteIds.FLODOGS,
    name: verticalName ? verticalName : 'FloDogs'
  }

  addSiteName(site.name)

  return faqDataDict[site.id] ? faqDataDict[site.id] : faqDataDict[SiteIds.FLODOGS]
}

function addSiteName(siteName: string): void {
  const moreQuestionsAnswer = `
  <p>Feel free to <a href="${CUSTOMER_SERVICE_URL}" target="_blank">Contact us</a>.</p>`

  const question3DefaultAnswer = `
    Yes, you can access your account and subscription from any of the supported devices listed above. If you would like to stream from multiple devices at the same time, make sure they’re on the same WiFi connection or IP address.
  `
  faqMap.MORE_QUESTIONS.answer = moreQuestionsAnswer
  faqMap.QUESTION_3_DEFAULT.answer = question3DefaultAnswer
}

export const faqMap: any = {
  QUESTION_1_DEFAULT: {
    question: 'What is included with a subscription?',
    answer: `<p>Your subscription gives you access to:</p>
        <ul>
          <li>Live event streams</li>
          <li>Event replays</li>
          <li>Live scores, results, highlights, and news</li>
          <li>Schedules, standings, rosters, and athlete profiles</li>
          <li>Our full library of award-winning content, including Flo Originals</li>
        </ul>`
  },
  QUESTION_2_DEFAULT: {
    question: 'Which devices can I use to watch live events and videos?',
    answer: `
    <p><strong>Web</strong></p>
      <ul>
        <li>Watch on any desktop, laptop, tablet, or mobile browser</li>
        <li>We recommend watching on the latest version of Google Chrome or Mozilla Firefox</li>
      </ul>

    <p><strong>Mobile Apps</strong></p>
      <ul>
        <li><a class="offered-devices" href="https://itunes.apple.com/app/id1149210891">Apple Store</a> (iPhone, iPad, Apple TV)</li>
        <li><a class="offered-devices" href="https://play.google.com/store/apps/details?id=tv.flosports">Google Play Store</a> (Android phones and tablets)</li>
      </ul>
    <p><strong>Connected TV Apps</strong></p>
      <ul>
        <li><a class="offered-devices" href="https://channelstore.roku.com/details/5133f57828836289f24c9e76e1397640/flosports">Roku Channel Store</a> (most Roku <a class="offered-devices" href="https://support.flosports.tv/s/article/What-Devices-and-Browsers-can-I-stream-from#:~:text=Our%20two%20most%20preferred%20browsers%20are%20Google%20Chrome,issues%2C%20no%20picture%2C%20no%20sound%2C%20black%20screen%2C%20etc">devices</a>)</li>
        <li><a class="offered-devices" href="https://www.amazon.com/FloSports-Inc/dp/B07Q3T4JWK">Amazon Fire</a> (Amazon Fire TV and Fire TV stick)</li>
      </ul>
`
  },
  QUESTION_3_DEFAULT: {
    question: 'Can I access my account on multiple devices?',
    answer: `<p>Yes, you can access your account and subscription from any of the supported devices listed above. If you would like to stream from multiple devices at the same time, make sure they’re on the same WiFi connection or IP address.</p>`
  },
  QUESTION_4_DEFAULT: {
    question: 'Can I watch multiple streams from a live event at the same time?',
    answer: `<p>Yes, you can watch up to 12 streams on one or multiple devices, connected to the same WiFi network or IP address. For example, you can stream on your iPhone, another on your laptop, and another on a Connected TV device like Roku at the same time.</p>`
  },
  QUESTION_5_DEFAULT: {
    question: 'Why are some videos unavailable in my location or country?',
    answer: `
    <p>FloSports streams thousands of events every year. Occasionally, events are restricted to specific geographical regions based on contractual agreements with rights holders and we aren’t able to stream to all geographical locations.</p>
    <p>If a stream or event replay is not available in your location, we will display an event "is not available in your location" message on the Event’s page or our Schedule pages. If you believe this is an error, check your VPN or proxy settings and try turning the setting to 'Off'.</p>`
  },
  QUESTION_6_DEFAULT: {
    question: 'Can I cancel anytime?',
    answer: `
    <p>Yes, you can cancel anytime. Your subscription will remain active through the remainder of the last billing cycle.</p>
    <p>Visit the Account Details > Subscription page to make a change to your subscription.</p>`
  },
  MORE_QUESTIONS: {
    question: 'More questions?',
    answer: `</p>Feel free to <a href="${CUSTOMER_SERVICE_URL}" target="_blank">Contact us</a>.</p>`
  }
}

export const faqDataDict: any = {
  [SiteIds.FLODOGS]: [
    faqMap.QUESTION_1_DEFAULT,
    faqMap.QUESTION_2_DEFAULT,
    faqMap.QUESTION_3_DEFAULT,
    faqMap.QUESTION_4_DEFAULT,
    faqMap.QUESTION_5_DEFAULT,
    faqMap.QUESTION_6_DEFAULT,
    faqMap.MORE_QUESTIONS
  ],
  [SiteIds.FLOTRACK]: [
    faqMap.QUESTION_1_DEFAULT,
    faqMap.QUESTION_2_DEFAULT,
    faqMap.QUESTION_3_DEFAULT,
    faqMap.QUESTION_4_DEFAULT,
    faqMap.QUESTION_5_DEFAULT,
    faqMap.QUESTION_6_DEFAULT,
    faqMap.MORE_QUESTIONS
  ],
  [SiteIds.FLOVOLLEYBALL]: [
    faqMap.QUESTION_1_DEFAULT,
    faqMap.QUESTION_2_DEFAULT,
    faqMap.QUESTION_3_DEFAULT,
    faqMap.QUESTION_4_DEFAULT,
    faqMap.QUESTION_5_DEFAULT,
    faqMap.QUESTION_6_DEFAULT,
    faqMap.MORE_QUESTIONS
  ],
  [SiteIds.VARSITY]: [
    faqMap.QUESTION_2_DEFAULT,
    faqMap.QUESTION_3_DEFAULT,
    faqMap.QUESTION_4_DEFAULT,
    faqMap.QUESTION_5_DEFAULT,
    faqMap.QUESTION_6_DEFAULT,
    faqMap.MORE_QUESTIONS
  ],
  [SiteIds.FLOCHEER]: [
    faqMap.QUESTION_1_DEFAULT,
    faqMap.QUESTION_2_DEFAULT,
    faqMap.QUESTION_3_DEFAULT,
    faqMap.QUESTION_4_DEFAULT,
    faqMap.QUESTION_5_DEFAULT,
    faqMap.QUESTION_6_DEFAULT,
    faqMap.MORE_QUESTIONS
  ],
  [SiteIds.FLOGRAPPLING]: [
    faqMap.QUESTION_1_DEFAULT,
    faqMap.QUESTION_2_DEFAULT,
    faqMap.QUESTION_3_DEFAULT,
    faqMap.QUESTION_4_DEFAULT,
    faqMap.QUESTION_5_DEFAULT,
    faqMap.QUESTION_6_DEFAULT,
    faqMap.MORE_QUESTIONS
  ],
  [SiteIds.FLOMARCHING]: [
    faqMap.QUESTION_1_DEFAULT,
    faqMap.QUESTION_2_DEFAULT,
    faqMap.QUESTION_3_DEFAULT,
    faqMap.QUESTION_4_DEFAULT,
    faqMap.QUESTION_5_DEFAULT,
    faqMap.QUESTION_6_DEFAULT,
    faqMap.MORE_QUESTIONS
  ],
  [SiteIds.FLOGYMNASTICS]: [
    faqMap.QUESTION_1_DEFAULT,
    faqMap.QUESTION_2_DEFAULT,
    faqMap.QUESTION_3_DEFAULT,
    faqMap.QUESTION_4_DEFAULT,
    faqMap.QUESTION_5_DEFAULT,
    faqMap.QUESTION_6_DEFAULT,
    faqMap.MORE_QUESTIONS
  ],
  [SiteIds.FLOWRESTLING]: [
    faqMap.QUESTION_1_DEFAULT,
    faqMap.QUESTION_2_DEFAULT,
    faqMap.QUESTION_3_DEFAULT,
    faqMap.QUESTION_4_DEFAULT,
    faqMap.QUESTION_5_DEFAULT,
    faqMap.QUESTION_6_DEFAULT,
    faqMap.MORE_QUESTIONS
  ],
  [SiteIds.FLOSOFTBALL]: [
    faqMap.QUESTION_1_DEFAULT,
    faqMap.QUESTION_2_DEFAULT,
    faqMap.QUESTION_3_DEFAULT,
    faqMap.QUESTION_4_DEFAULT,
    faqMap.QUESTION_5_DEFAULT,
    faqMap.QUESTION_6_DEFAULT,
    faqMap.MORE_QUESTIONS
  ],
  [SiteIds.FLORUGBY]: [
    faqMap.QUESTION_1_DEFAULT,
    faqMap.QUESTION_2_DEFAULT,
    faqMap.QUESTION_3_DEFAULT,
    faqMap.QUESTION_4_DEFAULT,
    faqMap.QUESTION_5_DEFAULT,
    faqMap.QUESTION_6_DEFAULT,
    faqMap.MORE_QUESTIONS
  ],
  [SiteIds.FLOBIKES]: [
    faqMap.QUESTION_1_DEFAULT,
    faqMap.QUESTION_2_DEFAULT,
    faqMap.QUESTION_3_DEFAULT,
    faqMap.QUESTION_4_DEFAULT,
    faqMap.QUESTION_5_DEFAULT,
    faqMap.QUESTION_6_DEFAULT,
    faqMap.MORE_QUESTIONS
  ],
  [SiteIds.FLOCOMBAT]: [
    faqMap.QUESTION_1_DEFAULT,
    faqMap.QUESTION_2_DEFAULT,
    faqMap.QUESTION_3_DEFAULT,
    faqMap.QUESTION_4_DEFAULT,
    faqMap.QUESTION_5_DEFAULT,
    faqMap.QUESTION_6_DEFAULT,
    faqMap.MORE_QUESTIONS
  ],
  [SiteIds.FLODANCE]: [
    faqMap.QUESTION_1_DEFAULT,
    faqMap.QUESTION_2_DEFAULT,
    faqMap.QUESTION_3_DEFAULT,
    faqMap.QUESTION_4_DEFAULT,
    faqMap.QUESTION_5_DEFAULT,
    faqMap.QUESTION_6_DEFAULT,
    faqMap.MORE_QUESTIONS
  ],
  [SiteIds.FLOELITE]: [
    faqMap.QUESTION_1_DEFAULT,
    faqMap.QUESTION_2_DEFAULT,
    faqMap.QUESTION_3_DEFAULT,
    faqMap.QUESTION_4_DEFAULT,
    faqMap.QUESTION_5_DEFAULT,
    faqMap.QUESTION_6_DEFAULT,
    faqMap.MORE_QUESTIONS
  ],
  [SiteIds.FLOFOOTBALL]: [
    faqMap.QUESTION_1_DEFAULT,
    faqMap.QUESTION_2_DEFAULT,
    faqMap.QUESTION_3_DEFAULT,
    faqMap.QUESTION_4_DEFAULT,
    faqMap.QUESTION_5_DEFAULT,
    faqMap.QUESTION_6_DEFAULT,
    faqMap.MORE_QUESTIONS
  ],
  [SiteIds.FLOHOCKEY]: [
    faqMap.QUESTION_1_DEFAULT,
    faqMap.QUESTION_2_DEFAULT,
    faqMap.QUESTION_3_DEFAULT,
    faqMap.QUESTION_4_DEFAULT,
    faqMap.QUESTION_5_DEFAULT,
    faqMap.QUESTION_6_DEFAULT,
    faqMap.MORE_QUESTIONS
  ],
  [SiteIds.FLOHOOPS]: [
    faqMap.QUESTION_1_DEFAULT,
    faqMap.QUESTION_2_DEFAULT,
    faqMap.QUESTION_3_DEFAULT,
    faqMap.QUESTION_4_DEFAULT,
    faqMap.QUESTION_5_DEFAULT,
    faqMap.QUESTION_6_DEFAULT,
    faqMap.MORE_QUESTIONS
  ],
  [SiteIds.FLOBOWLING]: [
    faqMap.QUESTION_1_DEFAULT,
    faqMap.QUESTION_2_DEFAULT,
    faqMap.QUESTION_3_DEFAULT,
    faqMap.QUESTION_4_DEFAULT,
    faqMap.QUESTION_5_DEFAULT,
    faqMap.QUESTION_6_DEFAULT,
    faqMap.MORE_QUESTIONS
  ],
  [SiteIds.FLORACING]: [
    faqMap.QUESTION_1_DEFAULT,
    faqMap.QUESTION_2_DEFAULT,
    faqMap.QUESTION_3_DEFAULT,
    faqMap.QUESTION_4_DEFAULT,
    faqMap.QUESTION_5_DEFAULT,
    faqMap.QUESTION_6_DEFAULT,
    faqMap.MORE_QUESTIONS
  ],
  [SiteIds.FLORODEO]: [
    faqMap.QUESTION_1_DEFAULT,
    faqMap.QUESTION_2_DEFAULT,
    faqMap.QUESTION_3_DEFAULT,
    faqMap.QUESTION_4_DEFAULT,
    faqMap.QUESTION_5_DEFAULT,
    faqMap.QUESTION_6_DEFAULT,
    faqMap.MORE_QUESTIONS
  ],
  [SiteIds.FLOSWIMMING]: [
    faqMap.QUESTION_1_DEFAULT,
    faqMap.QUESTION_2_DEFAULT,
    faqMap.QUESTION_3_DEFAULT,
    faqMap.QUESTION_4_DEFAULT,
    faqMap.QUESTION_5_DEFAULT,
    faqMap.QUESTION_6_DEFAULT,
    faqMap.MORE_QUESTIONS
  ],
  [SiteIds.FLOVOICE]: [
    faqMap.QUESTION_1_DEFAULT,
    faqMap.QUESTION_2_DEFAULT,
    faqMap.QUESTION_3_DEFAULT,
    faqMap.QUESTION_4_DEFAULT,
    faqMap.QUESTION_5_DEFAULT,
    faqMap.QUESTION_6_DEFAULT,
    faqMap.MORE_QUESTIONS
  ],
  [SiteIds.FLOLIVE]: [
    faqMap.QUESTION_1_DEFAULT,
    faqMap.QUESTION_2_DEFAULT,
    faqMap.QUESTION_3_DEFAULT,
    faqMap.QUESTION_4_DEFAULT,
    faqMap.QUESTION_5_DEFAULT,
    faqMap.QUESTION_6_DEFAULT,
    faqMap.MORE_QUESTIONS
  ],
  [SiteIds.FLOBASEBALL]: [
    faqMap.QUESTION_1_DEFAULT,
    faqMap.QUESTION_2_DEFAULT,
    faqMap.QUESTION_3_DEFAULT,
    faqMap.QUESTION_4_DEFAULT,
    faqMap.QUESTION_5_DEFAULT,
    faqMap.QUESTION_6_DEFAULT,
    faqMap.MORE_QUESTIONS
  ],
  [SiteIds.FLOFC]: [
    faqMap.QUESTION_1_DEFAULT,
    faqMap.QUESTION_2_DEFAULT,
    faqMap.QUESTION_3_DEFAULT,
    faqMap.QUESTION_4_DEFAULT,
    faqMap.QUESTION_5_DEFAULT,
    faqMap.QUESTION_6_DEFAULT,
    faqMap.MORE_QUESTIONS
  ]
}
