import { PlatformService } from '../../singleton-services/platform.service'
import { Observable } from 'rxjs'
import { Injectable } from '@angular/core'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'

@Injectable()
export class HttpGlobalHeaderInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(
      req.clone({
        headers: req.headers.set('X-301-Location', 'web').set('x-flo-app', 'flosports-webapp')
      })
    )
  }
}
