export enum VideoPlayerScreenErrorCode {
  AUTHENTICATION_REQUIRED,
  UNAUTHORIZED,
  FORBIDDEN,
  STREAM_NOT_FOUND,
  GEO_UNAVAILABLE,
  REVOKED_SHARED_OR_MAX_CLIENT,
  REVOKED_BANNED_USER,
  STREAM_COMPLETED,
  UNKNOWN_ERROR,
  PRE_AIR,
  INTERMISSION,
  CONCLUDED,
  CONCLUDED_WITH_DVR,
  CONCLUDED_FOR_DAY,
  CONCLUDED_FOR_DAY_WITH_ARCHIVES,
  FATAL_HLS
}
