<div class="container watch__featured-cards--header bg-900 mt-4 ps-md-0 dark-theme">
  <h2 class="watch__featured-cards--title overtext font-family-heavy color-200 mb-1 text-line-clamp-2 py-3">My Stuff</h2>
  <div class="favorites-container d-flex">
          <ng-container *ngFor="let item of [0,1,2,3];">
    <div class="favorites-container__card d-flex align-items-center me-3 p-3">
      <div class="favorites-container__card-circle skeleton col-3"></div>
      <div class="favorites-container__card-content-container col-9">
        <h4 class="favorites-container__card-title h1-skeleton w-100"></h4>
        <p class="favorites-container__card-subTitle mb-0 h1-skeleton w-50"></p>
      </div>
    </div>
            </ng-container>
  </div>
</div>
