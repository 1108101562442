import { Inject, Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { GeoIPResponse, GeoIPResponseItem } from '../shared/geo-ip/geo-ip.service'
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http'
import { catchError, map } from 'rxjs/operators'
import { EnvironmentService } from './environment.service'
import { LOGGER_SERVICE } from '../logger/logger.config'
import { LoggerService } from '../logger/logger.interface'

@Injectable({
  providedIn: 'root'
})
export class GeoBlockingService {
  constructor(
    private readonly http: HttpClient,
    private readonly environmentService: EnvironmentService,
    @Inject(LOGGER_SERVICE) private readonly logger: LoggerService
  ) {}

  /**
   * Take in an array of live event ids to check,
   * and return an array of GeoResponseItems that did NOT have a 200 OK response.
   *
   * In the component make sure the PlatformService isBrowser is true before calling this method.
   */
  public getGeoBlockedLiveEvents = (
    idsToCheck: ReadonlyArray<number>
  ): Observable<ReadonlyArray<GeoIPResponseItem>> => {
    // Extra precaution. In case an empty array comes in, don't execute any code/calls.
    if (idsToCheck.length <= 0) {
      return of([])
    }

    return this.http
      .get<GeoIPResponse>(`${this.environmentService.config.endpoints.live}/events/geo`, {
        params: new HttpParams({
          fromObject: { eventIds: idsToCheck.join(',') }
        }),
        headers: new HttpHeaders({ Authorization: 'Bearer' })
      })
      .pipe(
        map((res: GeoIPResponse) => res.data),
        map((items: GeoIPResponseItem[]) => items.filter(item => item.statusCode !== 200)),
        catchError((err: HttpErrorResponse) => {
          this.logger.error('WatchService GeoBlocking Error: ', err)
          return of([])
        })
      )
  }
}
