import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { EventCardHorizontalComponent } from './event-card-horizontal/event-card-horizontal.component'
import { EventStatusModule } from '../../events/components/event-status/event-status.module'
import { CustomLinkModule } from '../../custom-link/custom-link.directive.module'
import { AnalyticsModule } from 'src/app/shared/analytics/analytics.module'
import { EventCardVerticalComponent } from './event-card-vertical/event-card-vertical.component'
import { RouterModule } from '@angular/router'
import { ThumbnailModule } from '../../../shared/thumbnails/thumbnail.module'
import { IfLoadedModule } from 'src/app/shared/directives/if-loaded/if-loaded.module'
import { SvgModule } from 'src/app/shared/svg/svg.module'

@NgModule({
  declarations: [EventCardHorizontalComponent, EventCardVerticalComponent],
  exports: [EventCardHorizontalComponent, EventCardVerticalComponent],
  imports: [
    CommonModule,
    CustomLinkModule,
    EventStatusModule,
    AnalyticsModule,
    RouterModule,
    ThumbnailModule,
    IfLoadedModule,
    SvgModule
  ]
})
export class EventCardModule {}
