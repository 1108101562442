import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { finalize } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { HttpStabilityService } from './http-stability.service'

/*
  Track the number of http requests in flight
*/
@Injectable()
export class HttpStabilityInterceptor implements HttpInterceptor {
  constructor(private readonly httpStabilityService: HttpStabilityService) {}
  public intercept(httpRequest: HttpRequest<any>, httpHandler: HttpHandler): Observable<HttpEvent<any>> {
    this.httpStabilityService.incrementHttpRequestCount()
    return httpHandler.handle(httpRequest).pipe(
      finalize(() => {
        this.httpStabilityService.decrementHttpRequestCount()
      })
    )
  }
}
