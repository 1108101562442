import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ExpandableTextComponent } from './expandable-text.component'
import { SharedModule } from 'src/app/shared/shared.module'

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [ExpandableTextComponent],
  exports: [ExpandableTextComponent]
})
export class ExpandableTextModule {}
