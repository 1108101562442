import { ChangeDetectionStrategy, Component, Input, TrackByFunction } from '@angular/core'
import { EventsService } from '../../../events/services/events.service'
import { BehaviorSubject, iif, Observable } from 'rxjs'
import { filter, flatMap, map, shareReplay, switchMap, tap } from 'rxjs/operators'
import { FloEvent } from '@flocasts/flosports30-types/dist/entity'
import { FloApiResponseCollection } from '../../../shared/models/api-response.model'
import { SiteIds } from 'src/app/shared/models/site.model'
import { GeoBlockingService } from 'src/app/singleton-services/geo-blocking.service'
import { GeoIPResponseItem } from 'src/app/shared/geo-ip/geo-ip.service'
import { PlatformService } from 'src/app/singleton-services/platform.service'

const areThereMoreEvents = (limit: number, total: number) => {
  return limit < total
}

export interface ILandingPageCalendar {
  tiledEvents: ReadonlyArray<FloEvent>
  listEvents: ReadonlyArray<FloEvent>
  moreEvents: boolean
}

@Component({
  selector: 'flo-landing-batman-calendar',
  templateUrl: './batman-calendar.component.html',
  styleUrls: ['./batman-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BatmanCalendarComponent {
  constructor(
    private eventsService: EventsService,
    private geoBlockingService: GeoBlockingService,
    private readonly platformService: PlatformService
  ) {}

  @Input()
  siteId: number

  public buttonProps = {
    component: 'LandingBatmanCalendarComponent',
    subpage_category: 'Batman'
  }

  private DEFAULT_LIMIT = this.eventsService.DEFAULT_EVENT_LIMIT
  private eventsLimitSource = new BehaviorSubject<number>(this.DEFAULT_LIMIT)
  private eventsLimit$ = this.eventsLimitSource.asObservable()
  private initialConfig$: Observable<FloApiResponseCollection<FloEvent>> = this.eventsService.liveAndUpcomingEvents$
  public geoBlockedTiledIds = new BehaviorSubject<number[]>([])
  public geoBlockedListIds = new BehaviorSubject<number[]>([])
  public geoBlockedTiledIds$ = this.geoBlockedTiledIds.pipe(
    filter(_ => this.platformService.isBrowser),
    switchMap(this.geoBlockingService.getGeoBlockedLiveEvents),
    map((geoIpResponseItem: GeoIPResponseItem[]) => geoIpResponseItem.map(res => res.id)),
    shareReplay(1)
  )
  public geoBlockedListIds$ = this.geoBlockedListIds.pipe(
    filter(_ => this.platformService.isBrowser),
    switchMap(this.geoBlockingService.getGeoBlockedLiveEvents),
    map((geoIpResponseItem: GeoIPResponseItem[]) => geoIpResponseItem.map(res => res.id)),
    shareReplay(1)
  )

  public config$: Observable<ILandingPageCalendar> = this.eventsLimit$.pipe(
    flatMap(limit => {
      return iif(() => limit > this.DEFAULT_LIMIT, this.updateConfig(limit), this.initialConfig$)
    }),
    map(events => {
      const eventsMeta = events.meta
      const eventsList = events.data
      const tiledEvents = eventsList.slice(0, 4)
      const listEvents = eventsList.slice(4)
      const geoBlockedTiledIds = tiledEvents
        .filter(
          (event): event is FloEvent & { live_id: number } => event.check_geo_restriction && event.live_id !== null
        )
        .map(event => event.live_id)
      const geoBlockedListIds = listEvents
        .filter(
          (event): event is FloEvent & { live_id: number } => event.check_geo_restriction && event.live_id !== null
        )
        .map(event => event.live_id)

      this.geoBlockedTiledIds.next(geoBlockedTiledIds)
      this.geoBlockedListIds.next(geoBlockedListIds)

      return {
        tiledEvents,
        listEvents,
        moreEvents: areThereMoreEvents(eventsMeta.limit || 0, eventsMeta.total)
      }
    }),
    shareReplay(1)
  )

  private updateConfig(limit: number): Observable<FloApiResponseCollection<FloEvent>> {
    return this.eventsService.getEventsNowAndUpcoming(
      1,
      limit,
      true,
      undefined,
      this.siteId === SiteIds.TRACK_WRESTLING ? SiteIds.FLOWRESTLING : this.siteId,
      undefined,
      this.eventsService.timeZone
    )
  }

  public updateLimit(): void {
    const val = this.eventsLimitSource.getValue()
    this.eventsLimitSource.next(val + 10)
  }

  trackByUpcomingEvent: TrackByFunction<any> = (index: number, item: any) => {
    return item.short_title
  }
}
