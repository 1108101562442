import { Observable, of } from 'rxjs'
import { filter, tap, take, mergeMap, map, catchError } from 'rxjs/operators'

export function isVisible(element: HTMLElement | null, threshold = 0, _window: Window = window) {
  if (!element) return false
  const rect = element.getBoundingClientRect()
  // Is the element in viewport but larger then viewport itself
  const elementLargerThenViewport = rect.top <= threshold && rect.bottom >= -threshold
  // Is the top of the element in the viewport
  const topInsideViewport = rect.top >= 0 && rect.top <= _window.innerHeight
  // Is the bottom of the element in the viewport
  const belowInsideViewport = rect.bottom >= 0 && rect.bottom <= _window.innerHeight
  // Is the right side of the element in the viewport
  const rightsideInViewport = rect.right >= -threshold && rect.right - threshold <= _window.innerWidth
  // Is the left side of the element is the viewport
  const leftsideInViewport = rect.left >= -threshold && rect.left - threshold <= _window.innerWidth

  return (
    elementLargerThenViewport ||
    ((topInsideViewport || belowInsideViewport) && (rightsideInViewport || leftsideInViewport))
  )
}

function loadImage(imagePath: string): Observable<HTMLImageElement> {
  return Observable.create((observer: any) => {
    const img = new Image()
    img.src = imagePath
    img.onload = () => {
      observer.next(imagePath)
      observer.complete()
    }
    img.onerror = err => {
      observer.error(undefined)
    }
  })
}

function setImage(element: HTMLImageElement, imagePath: string) {
  const isImgNode = element.nodeName.toLowerCase() === 'img'
  if (isImgNode) {
    element.src = imagePath
  } else {
    element.style.backgroundImage = `url('${imagePath}')`
  }
  return element
}

function setLoadedStyle(element: HTMLElement) {
  const styles = element.className
    .split(' ')
    .filter(s => !!s)
    .filter(s => s !== 'ng-lazyloading')
  styles.push('ng-lazyloaded')
  element.className = styles.join(' ')
  return element
}

export function lazyLoadImage(
  image: HTMLImageElement,
  imagePath: string,
  defaultImagePath: string,
  errorImgPath: string,
  offset: number
) {
  if (defaultImagePath) {
    setImage(image, defaultImagePath)
  }
  return (scrollObservable: Observable<Event>) => {
    return scrollObservable.pipe(
      filter(() => isVisible(image, offset)),
      take(1),
      mergeMap(() => loadImage(imagePath)),
      tap(() => setImage(image, imagePath)),
      map(() => true),
      catchError(() => {
        if (errorImgPath) {
          setImage(image, errorImgPath)
        }
        image.className += ' ng-failed-lazyloaded'
        return of(false)
      }),
      tap(() => setLoadedStyle(image))
    )
  }
}
