import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { DropdownModule } from '../dropdown/dropdown.module'
import { FiltersComponent } from './filters.component'
import { SvgModule } from '../../shared/svg/svg.module'
import { AnalyticsModule } from '../../shared/analytics/analytics.module'
import { RouterModule } from '@angular/router'
import { ToggleModule } from '../toggle/toggle.module'

@NgModule({
  imports: [CommonModule, DropdownModule, SvgModule, AnalyticsModule, RouterModule, ToggleModule],
  declarations: [FiltersComponent],
  exports: [FiltersComponent]
})
export class FiltersModule {}
