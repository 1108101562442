import { ChangeDetectionStrategy, Component, Input, OnInit, TrackByFunction } from '@angular/core'
import * as jsonData from './faq-data'
import { SiteSettings } from 'src/app/shared/models/site-settings.model'

@Component({
  selector: 'flo-faq',
  templateUrl: './faq.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FaqComponent implements OnInit {
  @Input()
  settings: SiteSettings

  public faqData: ReadonlyArray<{ [key: string]: string }>

  ngOnInit() {
    this.faqData = jsonData.getFAQ(this.settings.site_name, this.settings.site_id)
  }

  trackByFAQ: TrackByFunction<string> = (index: number, faq: any) => {
    return faq.question
  }
}
