import { NgModule } from '@angular/core'
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap'
import { DropdownComponent } from './dropdown.component'
import { CommonModule } from '@angular/common'
import { AnalyticsModule } from 'src/app/shared/analytics/analytics.module'
import { SvgModule } from 'src/app/shared/svg/svg.module'
import { DropdownMenuComponent } from './dropdown-menu.component'
import { LinkModule } from '../link/link.module'
import { LinkMenuComponent } from './link-menu.component'
import { CustomLinkModule } from '../custom-link/custom-link.directive.module'

@NgModule({
  imports: [CommonModule, NgbDropdownModule, SvgModule, AnalyticsModule, LinkModule, CustomLinkModule],
  declarations: [DropdownComponent, DropdownMenuComponent, LinkMenuComponent],
  exports: [DropdownComponent, DropdownMenuComponent, LinkMenuComponent]
})
export class DropdownModule {}
