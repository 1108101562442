import { NgModule, ModuleWithProviders } from '@angular/core'
import { EnvironmentService } from '../../singleton-services/environment.service'
import { LOGGER_SERVICE } from 'src/app/logger/logger.config'
import { TransferState } from '@angular/platform-browser'
import { BrowserFirebaseLiveStreamService } from './browser-firebase-live-stream.service'
import { FLO_FIREBASE_LIVE_STREAM_SERVICE } from './tokens'
import { AngularFireDatabase } from '@angular/fire/compat/database'
@NgModule({
  providers: [
    {
      provide: FLO_FIREBASE_LIVE_STREAM_SERVICE,
      useClass: BrowserFirebaseLiveStreamService,
      deps: [AngularFireDatabase, TransferState, EnvironmentService, LOGGER_SERVICE]
    }
  ]
})
export class FirebaseBrowserModule {
  static forRoot(): ModuleWithProviders<FirebaseBrowserModule> {
    return {
      ngModule: FirebaseBrowserModule
    }
  }
}
