import { Inject, Injectable } from '@angular/core'
import { EnvConfig } from 'src/environments/environment.interface'
import { ENV_CONFIG } from '../app.config'

export interface IEnvironmentService {
  config: EnvConfig
}

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService implements IEnvironmentService {
  constructor(@Inject(ENV_CONFIG) private _config: any) {}

  get config(): EnvConfig {
    return this._config
  }
}
