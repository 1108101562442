import { FLO_INITIAL_QUERY_PARAM_MAP } from './queryparams.tokens'
import { startWith, shareReplay, debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { Injectable, Inject } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { QueryParamMap } from './queryparams.interface'

import * as A from 'fp-ts/Array'
import { monoidAll } from 'fp-ts/Monoid'

/**
 * @deprecated Do not use this service to access query params. Inject ActivatedRoute
 * and use the queryParamMap instead. Or use @RouterService instead. queryParams and params have been deprecated
 * by Angular.
 */
@Injectable({ providedIn: 'root' })
export class FloQueryParamsService {
  constructor(
    private readonly ar: ActivatedRoute,
    @Inject(FLO_INITIAL_QUERY_PARAM_MAP)
    private readonly initialParamMap: QueryParamMap
  ) {}

  public queryParams$ = this.ar.queryParams.pipe(
    debounceTime(0),
    startWith(this.initialParamMap),
    distinctUntilChanged((x, y) => {
      const compareUnknownParams = A.foldMap(monoidAll)((key: string) => x[key] === y[key])
      return compareUnknownParams(Object.keys(x)) && compareUnknownParams(Object.keys(y))
    }),
    shareReplay(1)
  )
}
