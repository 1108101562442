import { InjectionToken } from '@angular/core'
import { Firestore } from '@angular/fire/firestore'
import { SportDataService } from './sport-data.interface'

export const SPORT_DATA_SERVICE = new InjectionToken<SportDataService>('app.firestore.sport.data.service')
export const SPORT_DATA_FIRESTORE_TOKEN = new InjectionToken<Firestore>('flo.sport.data.firestore')

export const STATUS_STARTED = 20
export const STATUS_COMPLETED = 100

export const getQuery2 = (liveEventId: number, liveStreamId: number) => {
  return {
    structuredQuery: {
      from: [
        {
          collectionId: 'summary'
        }
      ],
      where: {
        compositeFilter: {
          op: 'AND',
          filters: [
            {
              fieldFilter: {
                field: {
                  fieldPath: 'statusCode'
                },
                op: 'GREATER_THAN_OR_EQUAL',
                value: {
                  integerValue: STATUS_STARTED
                }
              }
            },
            {
              fieldFilter: {
                field: {
                  fieldPath: 'statusCode'
                },
                op: 'LESS_THAN',
                value: {
                  integerValue: STATUS_COMPLETED
                }
              }
            },
            {
              fieldFilter: {
                field: {
                  fieldPath: 'identity.liveEventId'
                },
                op: 'EQUAL',
                value: {
                  integerValue: liveEventId
                }
              }
            },
            {
              fieldFilter: {
                field: {
                  fieldPath: 'identity.liveStreamId'
                },
                op: 'EQUAL',
                value: {
                  integerValue: liveStreamId
                }
              }
            }
          ]
        }
      },
      orderBy: [
        {
          field: {
            fieldPath: 'statusCode'
          },
          direction: 'ASCENDING'
        },
        {
          field: {
            fieldPath: 'lastUpdated'
          },
          direction: 'DESCENDING'
        },
        {
          field: {
            fieldPath: '__name__'
          },
          direction: 'DESCENDING'
        }
      ],
      limit: 5
    }
  }
}

export const getQuery1 = (liveEventId: number) => {
  return {
    structuredQuery: {
      from: [
        {
          collectionId: 'summary'
        }
      ],
      where: {
        compositeFilter: {
          op: 'AND',
          filters: [
            {
              fieldFilter: {
                field: {
                  fieldPath: 'statusCode'
                },
                op: 'GREATER_THAN_OR_EQUAL',
                value: {
                  integerValue: STATUS_STARTED
                }
              }
            },
            {
              fieldFilter: {
                field: {
                  fieldPath: 'statusCode'
                },
                op: 'LESS_THAN',
                value: {
                  integerValue: STATUS_COMPLETED
                }
              }
            },
            {
              fieldFilter: {
                field: {
                  fieldPath: 'identity.liveEventId'
                },
                op: 'EQUAL',
                value: {
                  integerValue: liveEventId
                }
              }
            }
          ]
        }
      },
      orderBy: [
        {
          field: {
            fieldPath: 'statusCode'
          },
          direction: 'ASCENDING'
        },
        {
          field: {
            fieldPath: '__name__'
          },
          direction: 'ASCENDING'
        }
      ],
      limit: 1
    }
  }
}
