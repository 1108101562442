import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { CardModel } from '@flocasts/experience-service-types'

export interface SmallContentCardComponentProps extends CardModel {
  type: 'card' | 'collection' | 'video'
  progress?: string | undefined
}

@Component({
  selector: 'flo-small-content-card',
  templateUrl: './small-content-card.component.html',
  styleUrls: ['./small-content-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmallContentCardComponent {
  @Input() props: SmallContentCardComponentProps | null
}
