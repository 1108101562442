import { UrlMatchResult, UrlSegment } from '@angular/router'
import * as O from 'fp-ts/Option'
import { pipe } from 'fp-ts/function'
import { ID_VALIDATION_RULE_KEYS, VALIDATION_RULES_FOR_ID_PARAMS } from './regex.utility'

/** Matches the ID at the beginning of a slug into the `id` group. */
export const SLUG_ID_REGEX = /^(?<id>\d+)/

/**
 * Obtain the entity ID from a slug for the entity.
 *
 * @throws Error if slug cannot be correctly parsed.
 * @example
 * getIdFromSlug('12345-foo-bar') === 12345
 *
 * TODO: Add more specific error handling (empty string, not a number, doesn't match SLUG_ID_REGEX, etc.)
 * @see {@link https://flocasts.atlassian.net/browse/CXP-3456}
 */
export function getIdFromSlug(slug: string): number {
  try {
    const nodeId = +slug.split('-')[0]
    return nodeId
  } catch (err) {
    throw new Error(`Failed to parse slug: "${slug}"`)
  }
}

/**
 * Obtain the entity ID from a slug for the entity in Option.
 *
 * @example
 * import * as O from 'fp-ts/Option'
 *
 * getIdFromSlugOption('12345-foo-bar') === O.some(12345)
 */
export function getIdFromSlugOption(slug: string): O.Option<number> {
  return pipe(
    O.fromNullable(slug.match(SLUG_ID_REGEX)),
    O.chain(matches => O.fromNullable(matches.groups?.id)),
    O.map(Number.parseInt),
    O.filter(id => !isNaN(id))
  )
}

/**
 * Match a path against a set of rules.
 * The purpose is to 404 early instead of making a request to the server.
 * @param url The url segment in question.
 * @example 12345-the-best-event-ever
 * @example 12345
 * @param paramId The param id to validate.
 * @example 'slug'
 * @example 'id'
 * @param rules The rules to validate the param against.
 * @returns UrlMatchResult if passes url validation or null and Angular redirects to 404.
 */
export function pathMatcher(
  url: UrlSegment[],
  paramId: 'slug' | 'nodeId' | 'collectionId' | 'article-slug' | 'rankingSlug',
  rules: ID_VALIDATION_RULE_KEYS[]
): UrlMatchResult | null {
  if (url.length === 0) return null
  const param = url[0].path
  let pass = true
  rules.forEach(key => {
    const rule = VALIDATION_RULES_FOR_ID_PARAMS[key]
    // Fail if any rule fails
    if (!rule.test(param)) {
      pass = false
    }
  })

  if (pass) {
    return {
      consumed: url,
      posParams: {
        [paramId]: new UrlSegment(param, {})
      }
    }
  }

  return null
}
