import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { ReplaySubject } from 'rxjs'
import { FloEvent } from '@flocasts/flosports30-types/dist/entity'

@Component({
  selector: 'flo-calendar-tile',
  templateUrl: './calendar-tile.component.html',
  styleUrls: ['./calendar-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarTileComponent {
  public readonly event$ = new ReplaySubject<FloEvent | undefined | null>(1)

  @Input()
  public set event(value: FloEvent) {
    this.event$.next(value)
  }

  @Input()
  public geoBlockedIds: ReadonlyArray<number> | null
}
