import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
  Input,
  Inject,
  PLATFORM_ID
} from '@angular/core'
import { isPlatformBrowser } from '@angular/common'
import { WINDOW } from '../../app.injections'
import { fromEvent, Subscription, tap, throttleTime } from 'rxjs'

/** this directive emits when the element this directive is placed on is intersecting/comes into view. It either outputs
 * a string or void for the parent to act on.
 * There is also the floLazyLoad directive that does a lot of this same logic but lazy load only emits once
 * This emits anytime it comes into view since it is useful for infinite scrolling/pagination.
 * We hope to merge them and refactor th two into one down the line
 */

@Directive({
  selector: '[floElementIsVisible]'
})
export class ElementIsVisibleDirective implements AfterViewInit, OnDestroy {
  @Input() public valueToOutput?: string
  /**
   * We can pass an optional threshold, representing the percentage of the element that is visible
   * The observer will only report changes to visibility when the threshold is crossed.
   * {@see https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API}
   */
  @Input() threshold?: number | number[]
  @Output() public elementIsVisible = new EventEmitter<string | boolean | [string, IntersectionObserverEntry]>()

  private _intersectionObserver: IntersectionObserver
  private readonly isBrowser: boolean
  private resizeSub: Subscription

  constructor(
    @Inject(WINDOW) window: unknown,
    private _element: ElementRef,
    // tslint:disable-next-line:ban-types
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId)
  }

  public ngAfterViewInit() {
    if (this.isBrowser) {
      this.resizeSub = fromEvent(window, 'resize')
        .pipe(
          // Throttle the observable so we don't fire off IntersectionObserver on every single pixel
          throttleTime(500),
          /*
           * Resizing the screen can change intersections, so we need to fire this off
           * When we get a window resize event.
           */
          tap(() => this.observeIntersections())
        )
        .subscribe()

      this.observeIntersections()
    }
  }

  public ngOnDestroy() {
    if (this._intersectionObserver) {
      this._intersectionObserver.disconnect()
    }
    if (this.resizeSub) {
      this.resizeSub.unsubscribe()
    }
  }

  private checkForIntersection = (entries: Array<IntersectionObserverEntry>) => {
    entries.forEach((entry: IntersectionObserverEntry) => {
      const isIntersecting = entry.isIntersecting && entry.target === this._element.nativeElement

      if (isIntersecting) {
        if (this.valueToOutput) {
          if (this.threshold) {
            this.elementIsVisible.emit([this.valueToOutput, entry])
          } else {
            this.elementIsVisible.emit(this.valueToOutput)
          }
        } else {
          this.elementIsVisible.emit(true)
        }
      }
    })
  }

  private observeIntersections = () => {
    const options: IntersectionObserverInit | {} = this.threshold ? { threshold: this.threshold } : {}
    if (this.hasCompatibleBrowser()) {
      this._intersectionObserver = new IntersectionObserver(entries => {
        this.checkForIntersection(entries)
      }, options)
      this._intersectionObserver.observe(this._element.nativeElement as Element)
    }
  }

  public hasCompatibleBrowser(): boolean {
    if (!this.isBrowser) return false

    const hasIntersectionObserver = 'IntersectionObserver' in (window as Window)
    const userAgent = window.navigator.userAgent
    const matches = userAgent.match(/Edge\/(\d*)\./i)

    const isEdge = !!matches && matches.length > 1
    const isEdgeVersion16OrBetter = isEdge && !!matches && parseInt(matches[1], 10) > 15

    return hasIntersectionObserver && (!isEdge || isEdgeVersion16OrBetter)
  }
}
