<div *ngIf="section" class="pinned-links">
  <!-- Mobile/Tablet Avatars -->
  <div class="pinned-links__items d-flex d-lg-none flex-nowrap">
    <div
      *ngFor="let link of section.items; trackBy: trackById"
      class="pinned-links__items--link border border-grey-300 bg-white rounded me-35">
      <flo-avatar-card
        [image]="link.thumbnailImage.url"
        [href]="link.action.url"
        [primaryLabel]="link.title"
        [secondaryLabel]="link.subtitle"
        [analyticsProperties]="link.action?.analytics"
      ></flo-avatar-card>
    </div>
  </div>
  <!-- Desktop Avatars -->
  <div class="pinned-links__items d-none d-lg-block">
    <div *ngFor="let link of section.items; let i = index; trackBy: trackById"
         class="pinned-links__items--link no-card primary-color-text-hover"
         [ngClass]="{ 'mt-25': i > 0 }"
    >
      <flo-avatar-card
        [image]="link.thumbnailImage.url"
        [href]="link.action.url"
        [primaryLabel]="link.title"
        [secondaryLabel]="link.subtitle"
        [analyticsProperties]="link.action?.analytics"
      ></flo-avatar-card>
    </div>
  </div>
</div>
