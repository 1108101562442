<div *ngIf="section" class="d-flex flex-column w-100">
  <div class="collection-card-title pb-3" data-test="collection-card-title">
    <a
      floAnalytics
      [analyticsProperties]="section.action?.analytics"
      [floCustomLink]="section.action?.url"
      class="w-100 primary-color-text-hover hover-target d-flex flex-row justify-content-between align-items-center"
    >
      <h4 class="heavy mb-1 flex-grow-1 me-35 apply-text-hover" data-test="collection-title">{{ section.title }}</h4>
      <span *ngIf="section.action?.url"
            class="subhead color-500 text-end flex-shrink-0 d-flex flex-column justify-content-end pb-1 apply-hover-underline"
            data-test="collection-view-all">{{ section.action?.title ?? 'View All' }}</span>
    </a>
    <p class="w-100 subhead color-700 d-none d-md-block mb-0 text-truncate" *ngIf="section.description">{{ section.description }}</p>
  </div>
  <div class="w-100 scroll-wrapper d-flex flex-row flex-nowrap">
    <ng-container *ngFor="let item of items; let i = index; first as isFirst; trackBy: trackById">
      <div *ngIf="i < 2" class="small-content-card flex-grow-1" [ngClass]="{ 'is-first': isFirst, 'is-second': i ===1 }" data-test="desktop-small-cards">
        <flo-small-content-card [props]="item"></flo-small-content-card>
      </div>
      <div *ngIf="i >= 3" class="d-block d-sm-none small-content-card" [ngClass]="{ 'is-first': isFirst, 'is-second': i === 1 }" data-test="mobile-small-cards">
        <flo-small-content-card [props]="item"></flo-small-content-card>
      </div>
    </ng-container>
  </div>
</div>
