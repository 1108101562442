import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { NotFoundComponent } from './not-found.component'
import { MetaGuard } from 'meta'

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '**',
        component: NotFoundComponent,
        canActivate: [MetaGuard],
        data: {
          meta: {
            title: 'meta_not_found_page_title',
            description: 'meta_not_found_page_description'
          }
        }
      }
    ]),
    CommonModule
  ],

  exports: [RouterModule, NotFoundComponent],

  declarations: [NotFoundComponent]
})
export class NotFoundModule {}
