import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HomeAnalyticsService } from './services/home-analytics.service'
import { HeadlinesModule } from './headlines/headlines.module'
import { PinnedLinksFavoritesModule } from './pinned-links-favorites/pinned-links-favorites.module'
import { HomeDataService } from './services/home-data.service'
import { ExtraLargeContentCardModule } from 'src/app/libs/content-card/extra-large-content-card/extra-large-content-card.module'
import { EventCardModule } from 'src/app/libs/list-items/event-card/event-card.module'
import { StaticContentCardModule } from 'src/app/libs/content-card/static-content-card/static-content-card.module'
import { MapPipeModule } from 'src/app/libs/map-pipe/map-pipe.module'
import { LazyLoadModule } from 'projects/lazy-load/src/public-api'
import { ErrorModule } from 'src/app/libs/error/error.module'
import { HomeComponent } from './home.component'
import { CustomLinkModule } from '../libs/custom-link/custom-link.directive.module'
import { CollectionContentCardModule } from './collection-content-card/collection-content-card.module'
import { HomeRoutingModule } from './home-routing.module'
import { ContinueWatchingComponent } from './continue-watching/continue-watching.component'
import { ContentCardModule } from '../libs/content-card/content-card.module'
import { CwItemToSmallContentCardModelPipe } from './continue-watching/cw-item-to-small-content-card.pipe'
import { PersonalizationCtaModule } from '../libs/personalization-cta/personalization-cta.module'
import { PromoBannerComponent } from './promo-banner/promo-banner.component'
import { FloPipeModule } from '../shared/pipes/flo-pipe.module'
import { SharedModule } from '../shared/shared.module'

@NgModule({
  imports: [
    HomeRoutingModule,
    CommonModule,
    ExtraLargeContentCardModule,
    HeadlinesModule,
    PinnedLinksFavoritesModule,
    EventCardModule,
    CollectionContentCardModule,
    ContentCardModule,
    StaticContentCardModule,
    MapPipeModule,
    LazyLoadModule,
    ErrorModule,
    CustomLinkModule,
    PersonalizationCtaModule,
    FloPipeModule,
    SharedModule
  ],
  exports: [HomeComponent, PromoBannerComponent],
  declarations: [HomeComponent, ContinueWatchingComponent, CwItemToSmallContentCardModelPipe, PromoBannerComponent],
  providers: [HomeAnalyticsService, HomeDataService]
})
export class HomeModule {}
