import {
  AfterViewInit,
  ChangeDetectorRef,
  ComponentFactory,
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  Injector,
  OnDestroy,
  Renderer2,
  ViewContainerRef
} from '@angular/core'
import { PollDaddyService } from '../../singleton-services/polldaddy.service'
import { TwitterService } from '../../singleton-services/twitter.service'
import { VideoPlayerByCodeComponent } from '../video-player/components/video-player-by-code/video-player-by-code.component'
import { take } from 'rxjs/operators'
import { LiveEventCtaService } from '../../singleton-services/cta/live-event-cta.service'
import { PickupService } from '../../singleton-services/pickup.service'
import { IFrameResizeService } from '../../singleton-services/iframe-resize.service'

@Directive({
  selector: '[floDynamicContent]'
})
export class DynamicContentDirective implements AfterViewInit, OnDestroy {
  constructor(
    public readonly pollDaddyService: PollDaddyService,
    public readonly twitterService: TwitterService,
    public readonly renderer: Renderer2,
    public readonly el: ElementRef,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly fr: ComponentFactoryResolver,
    private readonly cd: ChangeDetectorRef,
    private readonly ctaService: LiveEventCtaService,
    private readonly pickupService: PickupService,
    private readonly iFrameResizerService: IFrameResizeService
  ) {}

  ngAfterViewInit() {
    this.handleVideoEmbeds()
    this.loadPollDaddy()
    this.loadTwitter()
    this.loadEventCta()
    this.loadPickupPoll()
    this.handleIFrameResizing()
  }

  loadPollDaddy() {
    this.pollDaddyService.load(this.renderer).pipe(take(1)).subscribe()
  }

  loadTwitter() {
    if (this.twitterElements.length > 0) {
      this.twitterService
        .load(this.renderer)
        .pipe(take(1))
        .subscribe(twttr => twttr.widgets.load())
    }
  }

  loadEventCta() {
    const elements = this.liveEventCtaElements
    if (elements.length > 0) {
      this.ctaService.updateCTAs(elements, this.viewContainerRef)
    }
  }

  loadPickupPoll() {
    if (this.pickupPollElements.length > 0) {
      this.pickupService
        .load()
        .pipe(take(1))
        .subscribe(pickup => {
          if (!!pickup) {
            pickup.ready(() => {
              pickup.render()
            })
          }
        })
    }
  }

  handleVideoEmbeds() {
    this.getVideoEmbedContainers.forEach(container => {
      const iframe = container.querySelector('iframe') as HTMLIFrameElement
      const src = iframe && iframe.attributes && (iframe.attributes as any).src && (iframe.attributes as any).src.value
      const hash = src && /^\/embed\//.test(src) && src.replace('/embed/', '')
      if (!hash) return
      const compRef = this.addVideoPlayerComponent(
        this.videoComponentFactory,
        this.viewContainerRef.parentInjector,
        hash
      )
      if (!!container.parentNode) {
        container.parentNode.replaceChild(compRef.location.nativeElement, container)
      }
      compRef.changeDetectorRef.detectChanges()
    })
    this.cd.detectChanges()
  }

  /**
   * Using iframe resizer lib
   * @see {@link https://github.com/davidjbradshaw/iframe-resizer}
   */
  handleIFrameResizing() {
    if (this.fullScreenIFrames && this.fullScreenIFrames.length > 0) {
      this.fullScreenIFrames.forEach(container => {
        this.iFrameResizerService.resizeIframe(container)
      })
    }
  }

  ngOnDestroy() {
    this.pollDaddyService.destroyAll()
    this.iFrameResizerService.close()
  }

  get getVideoEmbedContainers() {
    return Array.from(
      (this.el.nativeElement as HTMLElement).querySelectorAll('.froala-video')
    ) as ReadonlyArray<HTMLDivElement>
  }

  get twitterElements() {
    return Array.from(this.el.nativeElement.querySelectorAll('blockquote.twitter-tweet'))
  }

  get liveEventCtaElements(): HTMLElement[] {
    return this.el.nativeElement.querySelectorAll('.fr-cta-card')
  }

  get videoComponentFactory() {
    return this.fr.resolveComponentFactory(VideoPlayerByCodeComponent)
  }

  get pickupPollElements(): ReadonlyArray<HTMLElement> {
    return this.el.nativeElement.querySelectorAll('.pickup-js-prop-picker')
  }

  /**
   * .fr-fullscreen class comes from the froala CMS to indicate
   * a fullscreen iframe should be present
   */
  get fullScreenIFrames() {
    return Array.from(this.el.nativeElement.querySelectorAll('.fr-fullscreen')) as ReadonlyArray<HTMLElement>
  }

  addVideoPlayerComponent(
    factory: ComponentFactory<VideoPlayerByCodeComponent>,
    injector: Injector,
    embedHash: string
  ) {
    const component = factory.create(this.viewContainerRef.parentInjector)
    component.instance.embedHash = embedHash
    this.viewContainerRef.insert(component.hostView)
    return component
  }
}
