import { IExperiments } from './experimentation.service'
import { SiteIds } from '../models/site.model'
import { USER_STATUS } from '../../singleton-services/auth/auth.interfaces'
import { StripeSubscriptionStatus } from '../subscriptions/subscriptions.common'

const ALL_EXPERIMENTS = {} as IExperiments

export const FEAT_LIVE_ADS_MAYDAY_ID = 'flo_13081_live_ads_mayday_mayday'
export const FEAT_LIVE_ADS_MAYDAY_CONTROL = '0'
export const FEAT_LIVE_ADS_MAYDAY_VARIATION = '1'
ALL_EXPERIMENTS[FEAT_LIVE_ADS_MAYDAY_ID] = {
  id: FEAT_LIVE_ADS_MAYDAY_ID,
  name: 'FLO-13081: Live Ads Mayday',
  variations: [
    {
      variationId: FEAT_LIVE_ADS_MAYDAY_CONTROL,
      variationName: 'Control'
    },
    {
      variationId: FEAT_LIVE_ADS_MAYDAY_VARIATION,
      variationName: 'Variation: Turn Off Live Ads'
    }
  ]
}

export const ALL_EXPERIMENT_DEFINITIONS = ALL_EXPERIMENTS

/**
 * CXP-1521: New athlete profile collection pages.
 * Feature flag branched from Entity Hubs/Entity Collections
 * because first release was only teams and leagues collection pages
 * and did not include people pages.
 */
export const EXP_NEW_ATHLETE_PROFILES_ID = 'cxp_1521_new_athlete_profiles'
export const EXP_NEW_ATHLETE_PROFILES_VARIATION = '1'
export const EXP_NEW_ATHLETE_PROFILES_CONTROL = '0'
ALL_EXPERIMENTS[EXP_NEW_ATHLETE_PROFILES_ID] = {
  id: EXP_NEW_ATHLETE_PROFILES_ID,
  name: 'New Athlete Entity Collections',
  variations: [
    {
      variationId: EXP_NEW_ATHLETE_PROFILES_CONTROL,
      variationName: 'Control: Old Athlete Profiles'
    },
    {
      variationId: EXP_NEW_ATHLETE_PROFILES_VARIATION,
      variationName: 'Variation: New Athlete Entity Collections'
    }
  ]
}

/**
 * CXP-1521: New collection entity pages for /collections/id and collections/tag routes.
 * Feature flag branched from Entity Hubs/Entity Collections
 * because first release was only teams and leagues collection pages,
 * did not include collection id or collection tag pages.
 */
export const EXP_NEW_COLLECTION_ENTITY_HUBS_ID = 'cxp_1521_new_collection_entity_hubs'
export const EXP_NEW_COLLECTION_ENTITY_HUBS_VARIATION = '1'
export const EXP_NEW_COLLECTION_ENTITY_HUBS_CONTROL = '0'
ALL_EXPERIMENTS[EXP_NEW_COLLECTION_ENTITY_HUBS_ID] = {
  id: EXP_NEW_COLLECTION_ENTITY_HUBS_ID,
  name: 'New Collection Entity Hubs',
  variations: [
    {
      variationId: EXP_NEW_COLLECTION_ENTITY_HUBS_CONTROL,
      variationName: 'Control: Old Collection Pages'
    },
    {
      variationId: EXP_NEW_COLLECTION_ENTITY_HUBS_VARIATION,
      variationName: 'Variation: New Collection Entities'
    }
  ]
}

/**
 * CXP-1714: Homepage Create Free Account
 */
export const EXP_HOMEPAGE_CREATE_FREE_ACCOUNT_ID = 'cx_1714_hp_create_free_account_lead_gen'
export const EXP_HOMEPAGE_CREATE_FREE_ACCOUNT_VARIATION = '1'
export const EXP_HOMEPAGE_CREATE_FREE_ACCOUNT_CONTROL = '0'
ALL_EXPERIMENTS[EXP_HOMEPAGE_CREATE_FREE_ACCOUNT_ID] = {
  id: EXP_HOMEPAGE_CREATE_FREE_ACCOUNT_ID,
  name: 'CXP-1714: Homepage Create Free Account',
  variations: [
    {
      variationId: EXP_HOMEPAGE_CREATE_FREE_ACCOUNT_CONTROL,
      variationName: 'Control: Hide Create Free Account on Homepage'
    },
    {
      variationId: EXP_HOMEPAGE_CREATE_FREE_ACCOUNT_VARIATION,
      variationName: 'Variation: Show Create Free Account on Homepage'
    }
  ],
  criteria: [
    {
      type: 'user',
      inclusive: true,
      data: [USER_STATUS.ANONYMOUS]
    },
    {
      type: 'vertical',
      inclusive: true,
      data: [
        SiteIds.FLOHOOPS,
        SiteIds.FLOFOOTBALL,
        SiteIds.FLOHOCKEY,
        SiteIds.FLOSOFTBALL,
        SiteIds.FLOGRAPPLING,
        SiteIds.FLOWRESTLING,
        SiteIds.FLORACING,
        SiteIds.FLORUGBY,
        SiteIds.FLOBASEBALL
      ]
    }
  ]
}

/**
 * CXP-2285: Next Gen Navigation V2
 */
export const EXP_NEXT_GEN_NAVIGATION_V2 = 'cxp_2285_next_gen_navigation_v2'
export const EXP_NEXT_GEN_NAVIGATION_V2_CONTROL = '0'
export const EXP_NEXT_GEN_NAVIGATION_V2_VARIATION = '1'
ALL_EXPERIMENTS[EXP_NEXT_GEN_NAVIGATION_V2] = {
  id: EXP_NEXT_GEN_NAVIGATION_V2,
  name: 'CXP-2285: Next Gen Navigation v2',
  variations: [
    {
      variationId: EXP_NEXT_GEN_NAVIGATION_V2_CONTROL,
      variationName: 'Control: Show v1 of next gen navigation'
    },
    {
      variationId: EXP_NEXT_GEN_NAVIGATION_V2_VARIATION,
      variationName: 'Variation: Show v2 of next gen navigation'
    }
  ]
}

/**
 * CXP-3168: Next Gen Navigation V2 (Wrestling)
 */
export const EXP_NEXT_GEN_NAVIGATION_V2_WRESTLING = 'cxp_3168_next_gen_navigation_wrestling_v2'
export const EXP_NEXT_GEN_NAVIGATION_V2_WRESTLING_CONTROL = '0'
export const EXP_NEXT_GEN_NAVIGATION_V2_WRESTLING_VARIATION = '1'
ALL_EXPERIMENTS[EXP_NEXT_GEN_NAVIGATION_V2_WRESTLING] = {
  id: EXP_NEXT_GEN_NAVIGATION_V2_WRESTLING,
  name: 'CXP-3168: Next Gen Navigation v2 (Wrestling)',
  variations: [
    {
      variationId: EXP_NEXT_GEN_NAVIGATION_V2_WRESTLING_CONTROL,
      variationName: 'Control: Show v1 of next gen navigation for wrestling'
    },
    {
      variationId: EXP_NEXT_GEN_NAVIGATION_V2_WRESTLING_VARIATION,
      variationName: 'Variation: Show v2 of next gen navigation for wrestling'
    }
  ],
  criteria: [
    {
      type: 'vertical',
      inclusive: true,
      data: [SiteIds.FLOWRESTLING]
    }
  ]
}

/**
 * CXP-3169: Next Gen Navigation V2 (Track)
 */
export const EXP_NEXT_GEN_NAVIGATION_V2_TRACK = 'cxp_3169_next_gen_navigation_v2_track'
export const EXP_NEXT_GEN_NAVIGATION_V2_TRACK_CONTROL = '0'
export const EXP_NEXT_GEN_NAVIGATION_V2_TRACK_VARIATION = '1'
ALL_EXPERIMENTS[EXP_NEXT_GEN_NAVIGATION_V2_TRACK] = {
  id: EXP_NEXT_GEN_NAVIGATION_V2_TRACK,
  name: 'CXP-3169: Next Gen Navigation v2 (Track)',
  variations: [
    {
      variationId: EXP_NEXT_GEN_NAVIGATION_V2_TRACK_CONTROL,
      variationName: 'Control: Show v1 of next gen navigation for track'
    },
    {
      variationId: EXP_NEXT_GEN_NAVIGATION_V2_TRACK_VARIATION,
      variationName: 'Variation: Show v2 of next gen navigation for track'
    }
  ],
  criteria: [
    {
      type: 'vertical',
      inclusive: true,
      data: [SiteIds.FLOTRACK]
    }
  ]
}

/**
 * CXP-3169: Next Gen Navigation V2 (Track)
 */
export const EXP_NEXT_GEN_NAVIGATION_V2_VARSITY = 'cxp_3838_next_gen_navigation_v2_varsity'
export const EXP_NEXT_GEN_NAVIGATION_V2_VARSITY_CONTROL = '0'
export const EXP_NEXT_GEN_NAVIGATION_V2_VARSITY_VARIATION = '1'
ALL_EXPERIMENTS[EXP_NEXT_GEN_NAVIGATION_V2_VARSITY] = {
  id: EXP_NEXT_GEN_NAVIGATION_V2_VARSITY,
  name: 'CXP-3838: Next Gen Navigation v2 (Varsity)',
  variations: [
    {
      variationId: EXP_NEXT_GEN_NAVIGATION_V2_VARSITY_CONTROL,
      variationName: 'Control: Show v1 of next gen navigation for varsity'
    },
    {
      variationId: EXP_NEXT_GEN_NAVIGATION_V2_VARSITY_VARIATION,
      variationName: 'Variation: Show v2 of next gen navigation for varsity'
    }
  ],
  criteria: [
    {
      type: 'vertical',
      inclusive: true,
      data: [SiteIds.VARSITY]
    }
  ]
}

/**
 * CXP-4840: Next Gen Navigation V2 (FloLive)
 */
export const EXP_NEXT_GEN_NAVIGATION_V2_FLOLIVE = 'cxp_4840_next_gen_navigation_v2_flolive'
export const EXP_NEXT_GEN_NAVIGATION_V2_FLOLIVE_CONTROL = '0'
export const EXP_NEXT_GEN_NAVIGATION_V2_FLOLIVE_VARIATION = '1'
ALL_EXPERIMENTS[EXP_NEXT_GEN_NAVIGATION_V2_FLOLIVE] = {
  id: EXP_NEXT_GEN_NAVIGATION_V2_FLOLIVE,
  name: 'CXP-4840: Next Gen Navigation v2 (FloLive)',
  variations: [
    {
      variationId: EXP_NEXT_GEN_NAVIGATION_V2_FLOLIVE_CONTROL,
      variationName: 'Control: Show v1 of next gen navigation for FloLive'
    },
    {
      variationId: EXP_NEXT_GEN_NAVIGATION_V2_FLOLIVE_VARIATION,
      variationName: 'Variation: Show v2 of next gen navigation for FloLive'
    }
  ],
  criteria: [
    {
      type: 'vertical',
      inclusive: true,
      data: [SiteIds.FLOLIVE]
    }
  ]
}

/**
 * CXP-5324: Next Gen Navigation V2 (FloCollege)
 */
export const EXP_NEXT_GEN_NAVIGATION_V2_FLOCOLLEGE = 'cxp_5324_next_gen_navigation_v2_flocollege'
export const EXP_NEXT_GEN_NAVIGATION_V2_FLOCOLLEGE_CONTROL = '0'
export const EXP_NEXT_GEN_NAVIGATION_V2_FLOCOLLEGE_VARIATION = '1'
ALL_EXPERIMENTS[EXP_NEXT_GEN_NAVIGATION_V2_FLOCOLLEGE] = {
  id: EXP_NEXT_GEN_NAVIGATION_V2_FLOCOLLEGE,
  name: 'CXP-5324: Next Gen Navigation v2 (FloCollege)',
  variations: [
    {
      variationId: EXP_NEXT_GEN_NAVIGATION_V2_FLOCOLLEGE_CONTROL,
      variationName: 'Control: Show v1 of next gen navigation for FloCollege'
    },
    {
      variationId: EXP_NEXT_GEN_NAVIGATION_V2_FLOCOLLEGE_VARIATION,
      variationName: 'Variation: Show v2 of next gen navigation for FloCollege'
    }
  ],
  criteria: [
    {
      type: 'vertical',
      inclusive: true,
      data: [SiteIds.FLOCOLLEGE]
    }
  ]
}

/**
 * CXP-1958: Sub Promo Banner
 */
export const EXP_SUB_PROMO_BANNER = 'cxp_1958_sub_promo_banner'
export const EXP_SUB_PROMO_BANNER_CONTROL = '0'
export const EXP_SUB_PROMO_BANNER_VARIATION = '1'
ALL_EXPERIMENTS[EXP_SUB_PROMO_BANNER] = {
  id: EXP_SUB_PROMO_BANNER,
  name: 'CXP-1958: Sub Promo Banner',
  variations: [
    {
      variationId: EXP_SUB_PROMO_BANNER_CONTROL,
      variationName: 'Control: Hide sub promo banner on FloWrestling'
    },
    {
      variationId: EXP_SUB_PROMO_BANNER_VARIATION,
      variationName: 'Variation: Show sub promo banner on FloWrestling'
    }
  ],
  criteria: [
    {
      type: 'user',
      inclusive: true,
      data: [USER_STATUS.ANONYMOUS]
    },
    {
      type: 'vertical',
      inclusive: true,
      data: [SiteIds.FLOWRESTLING]
    }
  ]
}

/**
 * CXP-2154: Expanded Sub Promo Banner
 */
export const EXP_EXPANDED_SUB_PROMO_BANNER = 'cxp_2154_expanded_sub_promo_banner'
export const EXP_EXPANDED_SUB_PROMO_BANNER_CONTROL = '0'
export const EXP_EXPANDED_SUB_PROMO_BANNER_VARIATION = '1'
ALL_EXPERIMENTS[EXP_EXPANDED_SUB_PROMO_BANNER] = {
  id: EXP_EXPANDED_SUB_PROMO_BANNER,
  name: 'CXP-2154: Expanded Sub Promo Banner',
  variations: [
    {
      variationId: EXP_EXPANDED_SUB_PROMO_BANNER_CONTROL,
      variationName: 'Control: Hide SPB for Racing, Grappling, Hockey, Track, Cheer, and Varsity'
    },
    {
      variationId: EXP_EXPANDED_SUB_PROMO_BANNER_VARIATION,
      variationName: 'Variation: Show SPB for Racing, Grappling, Hockey, Track, Cheer, and Varsity'
    }
  ],
  criteria: [
    {
      type: 'user',
      inclusive: true,
      data: [USER_STATUS.ANONYMOUS]
    },
    {
      type: 'vertical',
      inclusive: true,
      data: [
        SiteIds.FLORACING,
        SiteIds.FLOGRAPPLING,
        SiteIds.FLOHOCKEY,
        SiteIds.FLOTRACK,
        SiteIds.VARSITY,
        SiteIds.FLOCHEER
      ]
    }
  ]
}

/**
 * CXP-2491: Sub Promo Banner Test #3
 */
export const EXP_SUB_PROMO_BANNER_TEST_3 = 'cxp_2491_sub_promo_banner_test_3'
export const EXP_SUB_PROMO_BANNER_TEST_3_CONTROL = '0'
export const EXP_SUB_PROMO_BANNER_TEST_3_VARIATION = '1'
ALL_EXPERIMENTS[EXP_SUB_PROMO_BANNER_TEST_3] = {
  id: EXP_SUB_PROMO_BANNER_TEST_3,
  name: 'CXP-2491: Sub Promo Banner Test #3',
  variations: [
    {
      variationId: EXP_SUB_PROMO_BANNER_TEST_3_CONTROL,
      variationName: 'Control: Hide SPB for Marching, Baseball, Rugby, and Bikes'
    },
    {
      variationId: EXP_SUB_PROMO_BANNER_TEST_3_VARIATION,
      variationName: 'Variation: Show SPB for Marching, Baseball, Rugby, and Bikes'
    }
  ],
  criteria: [
    {
      type: 'user',
      inclusive: true,
      data: [USER_STATUS.ANONYMOUS]
    },
    {
      type: 'vertical',
      inclusive: true,
      data: [SiteIds.FLOMARCHING, SiteIds.FLOBASEBALL, SiteIds.FLORUGBY, SiteIds.FLOBIKES]
    }
  ]
}

/**
 * CXP-2265: Live Like on FloCheer
 */
export const FEAT_SHOW_LIVE_LIKE_ON_CHEER_ID = 'cxp_2265_live_like_flo_cheer'
export const FEAT_SHOW_LIVE_LIKE_ON_CHEER_CONTROL = '0'
export const FEAT_SHOW_LIVE_LIKE_ON_CHEER_VARIATION = '1'
ALL_EXPERIMENTS[FEAT_SHOW_LIVE_LIKE_ON_CHEER_ID] = {
  id: FEAT_SHOW_LIVE_LIKE_ON_CHEER_ID,
  name: 'CXP-2265: Live Like on FloCheer',
  variations: [
    {
      variationId: FEAT_SHOW_LIVE_LIKE_ON_CHEER_CONTROL,
      variationName: 'Control: Hide Live Like on FloCheer'
    },
    {
      variationId: FEAT_SHOW_LIVE_LIKE_ON_CHEER_VARIATION,
      variationName: 'Variation: Show Live Like on FloCheer'
    }
  ],
  criteria: [
    {
      type: 'vertical',
      inclusive: true,
      data: [SiteIds.FLOCHEER]
    }
  ]
}

/**
 * CXP-2618: Use New Pricing Architecture Feature Flag
 * Entitlements, Payments, Offers
 */
export const FEAT_PRICING_ARCH_ID = 'cxp_2618_feat_pricing_arch'
export const FEAT_PRICING_ARCH_VARIATION = '1'
export const FEAT_PRICING_ARCH_CONTROL = '0'
export const FEAT_PRICING_ARCH_VERTICALS = [
  SiteIds.FLOBASEBALL,
  SiteIds.FLOBIKES,
  SiteIds.FLOBOWLING,
  SiteIds.FLOCHEER,
  SiteIds.FLOCOLLEGE,
  SiteIds.FLOCOMBAT,
  SiteIds.FLODANCE,
  SiteIds.FLODOGS,
  SiteIds.FLOELITE,
  SiteIds.FLOFC,
  SiteIds.FLOFOOTBALL,
  SiteIds.FLOGRAPPLING,
  SiteIds.FLOGYMNASTICS,
  SiteIds.FLOHOCKEY,
  SiteIds.FLOHOOPS,
  SiteIds.FLOMARCHING,
  SiteIds.FLORACING,
  SiteIds.FLORODEO,
  SiteIds.FLORUGBY,
  SiteIds.FLOSOFTBALL,
  SiteIds.FLOSWIMMING,
  SiteIds.FLOTRACK,
  SiteIds.FLOVOLLEYBALL,
  SiteIds.FLOVOICE,
  SiteIds.VARSITY
]
ALL_EXPERIMENTS[FEAT_PRICING_ARCH_ID] = {
  id: FEAT_PRICING_ARCH_ID,
  name: 'CXP-2618: Use New Pricing Architecture Feature Flag',
  variations: [
    {
      variationId: FEAT_PRICING_ARCH_CONTROL,
      variationName: 'Control: Use existing pricing architecture'
    },
    {
      variationId: FEAT_PRICING_ARCH_VARIATION,
      variationName: 'Variation: Enable the new pricing architecture'
    }
  ],
  criteria: [
    {
      type: 'subscription',
      inclusive: false,
      data: [StripeSubscriptionStatus.CANCELLED, StripeSubscriptionStatus.ACTIVE]
    },
    {
      type: 'vertical',
      inclusive: true,
      data: FEAT_PRICING_ARCH_VERTICALS
    }
  ]
}

/**
 * OFP-696: Site Settings Pass-Through
 */
export const FEAT_SITE_SETTINGS_PASS_THROUGH_ID = 'ofp_696_site_settings_pass_through'
export const FEAT_SITE_SETTINGS_PASS_THROUGH_CONTROL = '0'
export const FEAT_SITE_SETTINGS_PASS_THROUGH_VARIATION = '1'
ALL_EXPERIMENTS[FEAT_SITE_SETTINGS_PASS_THROUGH_ID] = {
  id: FEAT_SITE_SETTINGS_PASS_THROUGH_ID,
  name: 'Use BFF pass through endpoint to retrieve site settings',
  variations: [
    {
      variationId: FEAT_SITE_SETTINGS_PASS_THROUGH_CONTROL,
      variationName: 'Control: Use Core API to retrieve site settings'
    },
    {
      variationId: FEAT_SITE_SETTINGS_PASS_THROUGH_VARIATION,
      variationName: 'Variation: Use BFF to retrieve site settings'
    }
  ]
}

/**
 * CXP-3347: FloHockey CAD Pricing
 */
export const EXP_HOCKEY_CAD_ID = 'cxp_3347_hockey_cad_v2'
export const EXP_HOCKEY_CAD_CONTROL = '0'
export const EXP_HOCKEY_CAD_VARIATION_1 = '1'
ALL_EXPERIMENTS[EXP_HOCKEY_CAD_ID] = {
  id: EXP_HOCKEY_CAD_ID,
  name: 'CXP-3347 V2: CAD pricing on FloHockey',
  variations: [
    {
      variationId: EXP_HOCKEY_CAD_CONTROL,
      variationName: 'Control: Show USD'
    },
    {
      variationId: EXP_HOCKEY_CAD_VARIATION_1,
      variationName: 'Variation 1: Show CAD'
    }
  ],
  criteria: [
    {
      type: 'vertical',
      inclusive: true,
      data: [SiteIds.FLOHOCKEY]
    }
  ]
}

/**
 * CXP-3402: FloWrestling Event Hub Brackets
 */
export const EXP_FLOWRESTLING_EVENT_HUB_BRACKETS_ID = 'cxp_3402_bracket_convergence_web'
export const EXP_FLOWRESTLING_EVENT_HUB_BRACKETS_CONTROL = '0'
export const EXP_FLOWRESTLING_EVENT_HUB_BRACKETS_VARIATION = '1'
ALL_EXPERIMENTS[EXP_FLOWRESTLING_EVENT_HUB_BRACKETS_ID] = {
  id: EXP_FLOWRESTLING_EVENT_HUB_BRACKETS_ID,
  name: 'CXP-3402: FloWrestling bracket convergence web',
  variations: [
    {
      variationId: EXP_FLOWRESTLING_EVENT_HUB_BRACKETS_CONTROL,
      variationName: 'Control: Hide Brackets tab on event hub'
    },
    {
      variationId: EXP_FLOWRESTLING_EVENT_HUB_BRACKETS_VARIATION,
      variationName: 'Variation: Show Brackets tab on event hub'
    }
  ],
  criteria: [
    {
      type: 'vertical',
      inclusive: true,
      data: [SiteIds.FLOWRESTLING]
    }
  ]
}

/**
 * CXP-2922: Favorites on primary schedule page (Hockey)
 */
export const EXP_FLOHOCKEY_SCHEDULE_FAVORITES_ID = 'cxp_3922_flohockey_schedule_favorites_added'
export const EXP_FLOHOCKEY_SCHEDULE_FAVORITES_CONTROL = '0'
export const EXP_FLOHOCKEY_SCHEDULE_FAVORITES_VARIATION = '1'
ALL_EXPERIMENTS[EXP_FLOHOCKEY_SCHEDULE_FAVORITES_ID] = {
  id: EXP_FLOHOCKEY_SCHEDULE_FAVORITES_ID,
  name: 'CXP-3922: Prioritize favorites on schedules (Hockey)',
  variations: [
    {
      variationId: EXP_FLOHOCKEY_SCHEDULE_FAVORITES_CONTROL,
      variationName: 'Control: Favorites not prioritized for schedules'
    },
    {
      variationId: EXP_FLOHOCKEY_SCHEDULE_FAVORITES_VARIATION,
      variationName: 'Variation: Favorites prioritized for schedules'
    }
  ],
  criteria: [
    {
      type: 'user',
      inclusive: false,
      data: [USER_STATUS.ANONYMOUS]
    },
    {
      type: 'vertical',
      inclusive: true,
      data: [SiteIds.FLOHOCKEY]
    }
  ]
}

/**
 * OFP-712: OFP Powers Athletes List Page
 */
export const FEAT_OFP_DATA_ATHLETES_LIST_ID = 'ofp_712_ofp_powers_athletes_list_page'
export const FEAT_OFP_DATA_ATHLETES_LIST_CONTROL = '0'
export const FEAT_OFP_DATA_ATHLETES_LIST_VARIATION = '1'
ALL_EXPERIMENTS[FEAT_OFP_DATA_ATHLETES_LIST_ID] = {
  id: FEAT_OFP_DATA_ATHLETES_LIST_ID,
  name: 'OFP-712: OFP Powers Athletes List Page',
  variations: [
    {
      variationId: FEAT_OFP_DATA_ATHLETES_LIST_CONTROL,
      variationName: 'Control: Athletes list page data powered by Core API'
    },
    {
      variationId: FEAT_OFP_DATA_ATHLETES_LIST_VARIATION,
      variationName: 'Variation: Athletes list page data powered by OFP'
    }
  ]
}

/**
 * OFP-1150: OFP Powers Article List Page
 */
export const FEAT_OFP_DATA_ARTICLE_LIST_ID = 'ofp_1150_ofp_powers_article_list_page'
export const FEAT_OFP_DATA_ARTICLE_LIST_CONTROL = '0'
export const FEAT_OFP_DATA_ARTICLE_LIST_VARIATION = '1'
ALL_EXPERIMENTS[FEAT_OFP_DATA_ARTICLE_LIST_ID] = {
  id: FEAT_OFP_DATA_ARTICLE_LIST_ID,
  name: 'OFP-1150: OFP Powers Article List Page',
  variations: [
    {
      variationId: FEAT_OFP_DATA_ARTICLE_LIST_CONTROL,
      variationName: 'Control: Article list page data powered by Core API'
    },
    {
      variationId: FEAT_OFP_DATA_ARTICLE_LIST_VARIATION,
      variationName: 'Variation: Article list page data powered by OFP'
    }
  ]
}

/*
 * CXP-7258: Support OFP rollout to 25%
 */
export const FEAT_OFP_DATA_SEARCH_ID = 'cxp_7258_ofp_powers_search_25'

export const FEAT_OFP_DATA_SEARCH_CONTROL = '0'
export const FEAT_OFP_DATA_SEARCH_VARIATION = '1'
ALL_EXPERIMENTS[FEAT_OFP_DATA_SEARCH_ID] = {
  id: FEAT_OFP_DATA_SEARCH_ID,
  name: 'CXP-7258: OFP Powers Search (25%)',
  variations: [
    {
      variationId: FEAT_OFP_DATA_SEARCH_CONTROL,
      variationName: 'Control: Search powered by Core API'
    },
    {
      variationId: FEAT_OFP_DATA_SEARCH_VARIATION,
      variationName: 'Variation: Search powered by OFP'
    }
  ],
  criteria: [
    {
      type: 'vertical',
      inclusive: true,
      data: [SiteIds.FLOWRESTLING]
    }
  ]
}

/**
 * Feature flag for event ticker
 */
export const FEAT_EVENT_TICKER_ID = 'feat_event_ticker'
export const FEAT_EVENT_TICKER_CONTROL = '0' // Do not show
export const FEAT_EVENT_TICKER_VARIATION = '1'
ALL_EXPERIMENTS[FEAT_EVENT_TICKER_ID] = {
  id: FEAT_EVENT_TICKER_ID,
  name: 'Event Ticker',
  variations: [
    {
      variationId: FEAT_EVENT_TICKER_CONTROL,
      variationName: 'Control: Hide Event Ticker'
    },
    {
      variationId: FEAT_EVENT_TICKER_VARIATION,
      variationName: 'Variation: Show Event Ticker'
    }
  ]
}

/**
 * Feature flag Gifting
 *
 * @see {@link https://flocasts.atlassian.net/browse/CXP-3886}
 */
export const FEAT_GIFTING_ID = 'CXP-3886_feat_gifting'
export const FEAT_GIFTING_CONTROL = '0' // Do not show
export const FEAT_GIFTING_VARIATION = '1'
ALL_EXPERIMENTS[FEAT_GIFTING_ID] = {
  id: FEAT_GIFTING_ID,
  name: 'CXP-3886 Gifting',
  variations: [
    {
      variationId: FEAT_GIFTING_CONTROL,
      variationName: 'Control: Hide Gifting'
    },
    {
      variationId: FEAT_GIFTING_VARIATION,
      variationName: 'Variation: Show Gifting'
    }
  ],
  criteria: [
    {
      type: 'vertical',
      inclusive: true,
      data: FEAT_PRICING_ARCH_VERTICALS
    }
  ]
}

/**
 * CXP-4854: FloGrappling Installment Experiment
 */
export const EXP_FLOGRAPPLING_INSTALLMENT_ID = 'cxp_5140_flograppling_installment_experiment'
export const EXP_FLOGRAPPLING_INSTALLMENT_CONTROL = '0'
export const EXP_FLOGRAPPLING_INSTALLMENT_VARIATION_1 = '1'
export const EXP_FLOGRAPPLING_INSTALLMENT_VARIATION_2 = '2'
ALL_EXPERIMENTS[EXP_FLOGRAPPLING_INSTALLMENT_ID] = {
  id: EXP_FLOGRAPPLING_INSTALLMENT_ID,
  name: 'CXP-4854: FloGrappling Installment Experiment',
  variations: [
    {
      variationId: EXP_FLOGRAPPLING_INSTALLMENT_CONTROL,
      variationName: 'Control: Show annual and monthly offers in horizontal layout'
    },
    {
      variationId: EXP_FLOGRAPPLING_INSTALLMENT_VARIATION_1,
      variationName: 'Variation 1: Show annual and monthly offers in vertical layout'
    },
    {
      variationId: EXP_FLOGRAPPLING_INSTALLMENT_VARIATION_2,
      variationName: 'Variation 2: Show annual, monthly and installment offers in vertical layout'
    }
  ],
  criteria: [
    {
      type: 'vertical',
      inclusive: true,
      data: [SiteIds.FLOGRAPPLING]
    }
  ]
}

/**
 * CXP-6205: Increase on yearly pricing
 * Increase yearly pricing by to $155.88/yr (12.99/mo)
 */
export const EXP_YEARLY_PRICING_INCREASE_ID = 'cxp_6205_yearly_price_increase_experiment'
export const EXP_YEARLY_PRICING_INCREASE_SP_NAME_CONTROL = 'yearly_15588'
export const EXP_YEARLY_PRICING_INCREASE_CONTROL = '0'
export const EXP_YEARLY_PRICING_INCREASE_VARIATION = '1'
ALL_EXPERIMENTS[EXP_YEARLY_PRICING_INCREASE_ID] = {
  id: EXP_YEARLY_PRICING_INCREASE_ID,
  name: 'CXP-6205: Yearly Price Increase Experiment',
  variations: [
    {
      variationId: EXP_YEARLY_PRICING_INCREASE_CONTROL,
      variationName: 'Control: $150.00/yr'
    },
    {
      variationId: EXP_YEARLY_PRICING_INCREASE_VARIATION,
      variationName: 'Variation: $155.88/yr'
    }
  ],
  criteria: [
    {
      type: 'vertical',
      inclusive: true,
      data: [SiteIds.FLOHOCKEY, SiteIds.FLOMARCHING, SiteIds.FLORUGBY, SiteIds.FLOWRESTLING]
    }
  ]
}

/**
 * CXP-6882: Temporary update the signup hero text for FloGrappling ADCC event
 * Feature is turned on July 24th 2024 to August 19th 2024
 */
export const FEAT_ADCC_FUNNEL_ID = 'cxp_6882_ADCC_signup_feature'
export const FEAT_ADCC_FUNNEL_CONTROL = '0'
export const FEAT_ADCC_FUNNEL_VARIATION = '1'
ALL_EXPERIMENTS[FEAT_ADCC_FUNNEL_ID] = {
  id: FEAT_ADCC_FUNNEL_ID,
  name: 'CXP-6882: ADCC Funnel Feat',
  variations: [
    {
      variationId: FEAT_ADCC_FUNNEL_CONTROL,
      variationName: 'Control: Default signup hero text'
    },
    {
      variationId: FEAT_ADCC_FUNNEL_VARIATION,
      variationName: 'Variation: Limited time ADCC signup hero text'
    }
  ],
  criteria: [
    {
      type: 'vertical',
      inclusive: true,
      data: [SiteIds.FLOGRAPPLING]
    }
  ]
}

/**
 * PE-1446: Display FloWrestling Brackets UI: https://flocasts.atlassian.net/browse/PE-1446
 */
export const EXP_FLOWRESTLING_BRACKETS_ID = 'pe_1446_display_flowrestling_brackets_ui'
export const EXP_FLOWRESTLING_BRACKETS_CONTROL = '0'
export const EXP_FLOWRESTLING_BRACKETS_VARIATION = '1'
ALL_EXPERIMENTS[EXP_FLOWRESTLING_BRACKETS_ID] = {
  id: EXP_FLOWRESTLING_BRACKETS_ID,
  name: 'PE-1446: Display FloWrestling Brackets UI',
  variations: [
    {
      variationId: EXP_FLOWRESTLING_BRACKETS_CONTROL,
      variationName: 'Control: Hide FloWrestling Brackets UI'
    },
    {
      variationId: EXP_FLOWRESTLING_BRACKETS_VARIATION,
      variationName: 'Variation: Display FloWrestling Brackets UI'
    }
  ],
  criteria: [
    {
      type: 'vertical',
      inclusive: true,
      data: [SiteIds.FLOWRESTLING]
    }
  ]
}

/**
 * CXP-6434: New Thank You Page Feature
 */
export const FEAT_NEW_THANK_YOU_PAGE_ID = 'cxp_6434_new_thank_you_page_feature'
export const FEAT_NEW_THANK_YOU_PAGE_CONTROL = '0'
export const FEAT_NEW_THANK_YOU_PAGE_VARIATION = '1'
ALL_EXPERIMENTS[FEAT_NEW_THANK_YOU_PAGE_ID] = {
  id: FEAT_NEW_THANK_YOU_PAGE_ID,
  name: 'CXP-6434: New Thank You Page Feature',
  variations: [
    {
      variationId: FEAT_NEW_THANK_YOU_PAGE_CONTROL,
      variationName: 'Control: Old Thank You Page'
    },
    {
      variationId: FEAT_NEW_THANK_YOU_PAGE_VARIATION,
      variationName: 'Variation: New Thank You Page'
    }
  ],
  criteria: [
    {
      type: 'vertical',
      inclusive: true,
      data: [SiteIds.FLORACING]
    }
  ]
}

export const EXP_FLOCOLLEGE_PROD_ID = 'cxp_6651_flo_college_prod'
export const EXP_FLOCOLLEGE_PROD_CONTROL = '0'
export const EXP_FLOCOLLEGE_PROD_VARIATION = '1'
ALL_EXPERIMENTS[EXP_FLOCOLLEGE_PROD_ID] = {
  id: EXP_FLOCOLLEGE_PROD_ID,
  name: 'CXP-6651: Display FloCollege Site',
  variations: [
    {
      variationId: EXP_FLOCOLLEGE_PROD_CONTROL,
      variationName: 'Control: Show FloCollege'
    },
    {
      variationId: EXP_FLOCOLLEGE_PROD_VARIATION,
      variationName: 'Variation: Hide FloCollege - Redirect'
    }
  ],
  criteria: [
    {
      type: 'vertical',
      inclusive: true,
      data: [SiteIds.FLOCOLLEGE]
    }
  ]
}
