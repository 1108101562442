import { AuthService } from '../../singleton-services/auth/auth.service'
import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router'
import { combineLatest, Observable, of } from 'rxjs'
import { tap, flatMap } from 'rxjs/operators'
import { CheckoutNavigationService } from '../services/checkout-navigation.service'
import { VerticalService } from '../../singleton-services/vertical.service'
import { AccountService } from '../../singleton-services/account.service'
import { getReactivationSubscription } from '../../funnel/pay/pay-form/pay-form.utility'

@Injectable()
export class CreateAccountGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private vs: VerticalService,
    private checkoutNavService: CheckoutNavigationService,
    private router: Router,
    private accountService: AccountService
  ) {}

  canActivate(ars: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // TODO: FLO-10749 -- Look into removing isMileSplit$ from this logic
    return combineLatest([
      this.authService.userIdentity$,
      this.accountService.allUserSubscriptions$,
      this.vs.siteId$,
      this.vs.isMileSplit$,
      this.vs.isTrackWrestling$,
      this.authService.isPremium$
    ]).pipe(
      flatMap(([userIdentity, subscriptions, siteId, isMileSplit, isTrackWrestling, isPremium]) => {
        const queryParams = ars.queryParams
        const nextPath = queryParams && queryParams.next

        // Let all anonymous users through except for Milesplit & TW
        if (!userIdentity) {
          return !isMileSplit && !isTrackWrestling
            ? of(true)
            : of(false).pipe(
                tap(() => {
                  isTrackWrestling
                    ? this.checkoutNavService.redirectTrackWrestlingUsersForCreateAccount(nextPath)
                    : this.router.navigate(['signup'], { queryParams })
                })
              )
        }

        // Premium Users
        if (userIdentity && isPremium) {
          return of(false).pipe(tap(() => this.checkoutNavService.navigateToFinalDestination(queryParams)))
        }

        // Free Users
        if (userIdentity && !isPremium) {
          // With previous subscription we can fast-track
          if (getReactivationSubscription(subscriptions, siteId)) {
            return of(false).pipe(tap(() => this.router.navigate(['pay'], { queryParams })))
          }
          return of(false).pipe(
            tap(() => this.checkoutNavService.navigateAfterCreatingAccount(queryParams, ars.data.liveEvent))
          )
        }
        return of(true)
      })
    )
  }
}
