import { Inject, Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http'
import { FloApiResponse } from '../../shared/models/api-response.model'
import { EnvironmentService } from '../../singleton-services/environment.service'
import { map, catchError, switchMap } from 'rxjs/operators'
import { IRealtimeEventResponseWithEventId, IRealtimeEventResponse } from '../models/realtime-event-response.model'

import { LOGGER_SERVICE } from '../../logger/logger.config'
import { LoggerService } from '../../logger/logger.interface'
import { EMPTY, Observable, of } from 'rxjs'
import { EventHub, LiveHub } from '@flocasts/flosports30-types/dist/entity'
import { FloResponse } from '@flocasts/flosports30-types/dist/flo-rest-api/response'
import { LiveApiEvent } from '@flocasts/flosports30-types/dist/live-api'
import { AuthenticatedStreamResponse } from '@flocasts/flosports30-types/dist/live-api'
import { IMaybe } from 'typescript-monads/interfaces'
import { maybe } from 'typescript-monads'
import { FLO_FIREBASE_LIVE_STREAM_SERVICE } from 'src/app/shared/firebase/tokens'
import { FirebaseLiveStreamService } from 'src/app/shared/firebase/interfaces'
import {
  EXPERIENCE_API_LEGACY_CORE_EVENTS_PATH,
  EXPERIENCE_API_LEGACY_CORE_LIVE_EVENTS_PATH,
  EXPERIENCE_API_VERSION
} from 'src/app/app.config'
import { VerticalService } from 'src/app/singleton-services/vertical.service'

export interface LiveEventAdMetadata {
  level?: string
  gender?: string
  division?: string
  conference?: string
  event_type?: string
  governing_body?: string
}

export interface AdMetadata {
  adMetadata: LiveEventAdMetadata | null
}

// TODO: gut out the eventStatus logic when we get 'INTERDAY' status sent from firebase
const responseToResponseWithId =
  (id: number) =>
  (res?: IRealtimeEventResponse): IMaybe<IRealtimeEventResponseWithEventId> =>
    maybe(res).map(s => {
      const isDVR = s.isDVR
      const eventStatus =
        isDVR && !!s.startAtNext && s.eventStatus === 'PRE-AIR' && s.startAt !== s.startAtNext
          ? 'INTERDAY'
          : s.eventStatus

      return {
        ...s,
        liveEventId: id,
        isDVR,
        eventStatus
      }
    })

@Injectable()
export class LiveEventService {
  constructor(
    private readonly http: HttpClient,
    @Inject(FLO_FIREBASE_LIVE_STREAM_SERVICE) private readonly fb: FirebaseLiveStreamService,
    private readonly es: EnvironmentService,
    private readonly verticalService: VerticalService,
    @Inject(LOGGER_SERVICE) private readonly logger: LoggerService
  ) {}

  readonly timeZone = () => {
    try {
      return Intl.DateTimeFormat().resolvedOptions().timeZone
    } catch (err) {
      return ''
    }
  }

  /**
   * GET event from API, using @flocasts/flosports30-types EventHub.
   */
  public readonly getEvent$ = (eventId: number): Observable<EventHub> => {
    this.logger.trace(`LiveEventService: Requesting Event`, {
      eventId
    })
    return this.verticalService.siteId$.pipe(
      switchMap(siteId => {
        return this.http
          .get<FloApiResponse<EventHub, {}>>(`${EXPERIENCE_API_LEGACY_CORE_EVENTS_PATH}/${eventId}`, {
            params: { version: EXPERIENCE_API_VERSION, site_id: siteId }
          })
          .pipe(map(a => a.data))
      })
    )
  }

  readonly getEventSchedule = (eventId: number) => {
    return this.verticalService.siteId$.pipe(
      switchMap(siteId => {
        return (
          this.http
            .get<string | undefined>(`${EXPERIENCE_API_LEGACY_CORE_EVENTS_PATH}/${eventId}/extras/schedule.html`, {
              responseType: 'text' as any,
              params: { version: EXPERIENCE_API_VERSION, site_id: siteId }
            })
            // Swallow errors, we don't need to log every 404
            .pipe(catchError(_ => of(undefined)))
        )
      })
    )
  }

  readonly consolidatedBaseUrl = (id: string | number) =>
    `${EXPERIENCE_API_LEGACY_CORE_LIVE_EVENTS_PATH}/${id}/consolidated`

  readonly getLiveEventConsolidatedRaw$ = (url: string) => (params?: any) =>
    this.http
      .get<FloResponse<LiveHub, {}>>(url, { params: { ...params, version: EXPERIENCE_API_VERSION } })
      .pipe(map(a => a.data))

  readonly getLiveEventConsolidated$ = (id: string | number) =>
    this.getLiveEventConsolidatedRaw$(this.consolidatedBaseUrl(id))()

  readonly getLiveEventConsolidatedWithParams$ = (id: number) => (params: any) =>
    this.getLiveEventConsolidatedRaw$(this.consolidatedBaseUrl(id))(params)

  readonly getLiveEventConsolidatedWithLiveEventsOnly$ = (id: number) =>
    this.getLiveEventConsolidatedWithParams$(id)({
      live_only: true,
      recently_ended: true,
      upcoming_limit: 70
    })

  readonly getLiveEventRealtimeStream = (id: number) =>
    this.fb
      .universalObject<IRealtimeEventResponse | undefined>(`events/${id}`, () => false)
      .pipe(map(responseToResponseWithId(id)))

  readonly destroyStreamToken = (tokenId: string) =>
    this.http
      .delete<FloResponse<AuthenticatedStreamResponse, {}>>(`${this.es.config.endpoints.live}/tokens/${tokenId}`, {})
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.logger.error('An error occurred while deleting stream token', {
            err
          })
          return EMPTY
        })
      )

  readonly getAuthenticatedStream = (streamId: string) => {
    return this.http
      .post<FloResponse<AuthenticatedStreamResponse, {}>>(
        `${this.es.config.endpoints.live}/streams/${streamId}/tokens`,
        {
          adTracking: {
            appName: 'flosports-web'
          }
        }
      )
      .pipe(map(res => res.data))
  }

  readonly getLiveEventWithStreams = (liveId: string) =>
    this.http.get<FloResponse<LiveApiEvent, {}>>(`${this.es.config.endpoints.live}/events/${liveId}`, {
      params: new HttpParams()
        .set('embed', 'stream')
        .set(
          'fields',
          'premium,startAtNext,dvrEnabled,eventStatusMessage,id,name,slug,startDate,endDate,adTech,status,enableTattoo,startAt,endAt'
        )
    })

  readonly getLiveEventAdMetadata = (liveId: number) =>
    this.http
      .get<FloResponse<AdMetadata, {}>>(`${this.es.config.endpoints.live}/events/${liveId}`, {
        params: new HttpParams().set('fields', 'adMetadata')
      })
      .pipe(map(res => res.data))
}
