import { NgModule, ModuleWithProviders, PLATFORM_ID } from '@angular/core'
import { TransferState } from '@angular/platform-browser'
import { EnvironmentService } from 'src/app/singleton-services/environment.service'
import { SegmentService } from '../../analytics/services/segment.service'
import { CookieService } from 'src/app/singleton-services/cookie.service'
import { LocationService } from 'src/app/singleton-services/location.service'
import { GrowthBookBrowserService } from './growthbook-browser.service'
import { GROWTHBOOK_SERVICE } from './growthbook.utility'

@NgModule({
  providers: [
    {
      provide: GROWTHBOOK_SERVICE,
      useClass: GrowthBookBrowserService,
      deps: [EnvironmentService, TransferState, SegmentService, CookieService, LocationService]
    }
  ]
})
export class GrowthBookBrowserModule {
  static forRoot(): ModuleWithProviders<GrowthBookBrowserModule> {
    return {
      ngModule: GrowthBookBrowserModule
    }
  }
}
