import { Directive, ElementRef } from '@angular/core'
import { FullscreenStatusService } from './fullscreen-status.service'

/**
 * Put this directive on videos to globally detect when they are fullscreen in iOS Safari.
 *
 * This directive communicates the state of the host element to the
 * `FullscreenStatusService`, which can inform all other components and
 * services about whether the application has any element in fullscreen mode.
 *
 * iOS Safari only has a mechanism for checking if an element is fullscreen,
 * unlike all other browsers which provide this information via `document`. As
 * a result, instead of attaching event listeners to every fullscreenable
 * element on the page, this directive can be applied to only those elements
 * which are expected to go full screen.
 *
 * Currently, this directive is limited to videos to avoid any
 * unexpected conditions. It can be extended if it needs to report for other
 * elements in iOS Safari.
 *
 * @see FullscreenStatusService
 */
@Directive({
  selector: 'video[floDetectIosSafariFullscreen]'
})
export class DetectIosSafariFullscreenDirective {
  constructor(
    el: ElementRef<HTMLVideoElement>,
    private readonly fullscreenStatus: FullscreenStatusService,
  ) {
    // safari iOS fullscreen detection
    el.nativeElement.addEventListener('webkitbeginfullscreen', () => this.beginFullscreen())
    el.nativeElement.addEventListener('webkitendfullscreen', () => this.endFullscreen())
  }

  private beginFullscreen(): void {
    this.fullscreenStatus.beginFullscreen()
  }

  private endFullscreen(): void {
    this.fullscreenStatus.endFullscreen()
  }
}
