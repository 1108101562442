import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CollectionContentCardComponent } from './collection-content-card.component'
import { ContentCardModule } from '../../libs/content-card/content-card.module'
import { RouterModule } from '@angular/router'
import { AnalyticsModule } from '../../shared/analytics/analytics.module'
import { CustomLinkModule } from '../../libs/custom-link/custom-link.directive.module'

@NgModule({
  imports: [CommonModule, ContentCardModule, RouterModule, AnalyticsModule, CustomLinkModule],
  declarations: [CollectionContentCardComponent],
  exports: [CollectionContentCardComponent]
})
export class CollectionContentCardModule {}
