import { Inject, Injectable } from '@angular/core'
import { CookieService } from 'src/app/singleton-services/cookie.service'
import * as crypto from 'crypto-js'
import { WINDOW } from 'src/app/app.injections'

/**
 * ire is a callback function provided by the Impact SDK
 * {@link (https://integrations.impact.com/impact-brand/docs/integrate-with-stripe) }
 */
interface Impact {
  ire(eventName: string, data: object | ((data: string) => void)): void
}

/**
 * The Impact marketing tool is a platform that manages affiliate marketing partner.
 * The value of this tool is that it allows us to move to a rev share % as opposed to a flat fee payout for our partner marketing links.
 *
 * Must check if platform is browser before calling Impact service
 */
@Injectable({
  providedIn: 'root'
})
export class ImpactService {
  constructor(private readonly cookieService: CookieService, @Inject(WINDOW) private readonly window: Window) {}

  getClickId(): string {
    if (!this.cookieService.get('impactClickId')) this.generateClickId()
    return this.cookieService.get('impactClickId')
  }

  generateClickId(): void {
    const w = this.window as Window & Impact
    w.ire('generateClickId', (clickId: string) => {
      this.cookieService.set('impactClickId', clickId)
    })
  }

  /**
   * Call the identify function provided by Impact
   */
  identify(): void {
    const customerId = this.cookieService.get('impactCustomerId') ?? ''
    const customerEmail = this.cookieService.get('impactCustomerEmail') ?? ''

    const w = this.window as Window & Impact
    w.ire('identify', {
      customerId,
      customerEmail
    })
  }

  /**
   * Set the customer info in the cookies and call the identify function provided by Impact
   */
  setCustomerInfo(customerId: string, customerEmail: string): void {
    const hashEmail = crypto.SHA1(customerEmail)

    this.cookieService.set('impactCustomerId', customerId)
    this.cookieService.set('impactCustomerEmail', hashEmail)
    this.identify()
  }
}
