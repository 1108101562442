import { ChangeDetectionStrategy, Component, Input, TrackByFunction } from '@angular/core'
import { StepperModel } from './stepper.interface'

@Component({
  selector: 'flo-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepperComponent {
  @Input()
  steps: StepperModel[]

  trackById: TrackByFunction<any> = (index: number, item: StepperModel) => {
    return item.id
  }
}
