import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HtmlViewComponent } from './html-view.component'
import { SharedModule } from 'src/app/shared/shared.module'
import { VerticalScrollListenerModule } from '../_directives/vertical-scroll-listener/vertical-scroll-listener.module'

@NgModule({
  imports: [CommonModule, VerticalScrollListenerModule, SharedModule],
  declarations: [HtmlViewComponent],
  exports: [HtmlViewComponent]
})
export class HtmlViewModule {}
