import { Injectable, NgZone } from '@angular/core'
import { buildCanonicalUrl, buildHomeSchema, CANONICAL_ID, SCHEMA_WEBSITE } from './seo.utility'
import { UpdateHeadService } from './update-head.service'

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  constructor(private updateHeadService: UpdateHeadService, private readonly zone: NgZone) {}

  // Set a specific SEO-Friendly Canonical URL
  // IMPORTANT: Remember to call removeCanonicalUrl on component destroy
  public setCanonicalUrl(url: string): void {
    this.updateHeadService.addElementToHead(buildCanonicalUrl(url))
  }

  public removeCanonicalUrl(): void {
    this.updateHeadService.removeElement(CANONICAL_ID)
  }

  // Set a specific WebSite Id and json schema at recommendation from Google
  public setHomeSchema(siteName?: string, siteUrl?: string): void {
    const homeSchema = buildHomeSchema(siteName, siteUrl)
    this.zone.runOutsideAngular(() => {
      this.updateHeadService.addElementToHead(homeSchema)
    })
  }

  public removeHomeSchema(): void {
    this.zone.runOutsideAngular(() => {
      this.updateHeadService.removeElement(SCHEMA_WEBSITE)
    })
  }
}
