import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  Output,
  PLATFORM_ID
} from '@angular/core'
import { combineLatest, Subject } from 'rxjs'
import { distinctUntilChanged, startWith, takeUntil, tap, withLatestFrom } from 'rxjs/operators'
import { isPlatformBrowser } from '@angular/common'

/**
 * This directive will fire an emitter when the user has vertically scrolled to a horizontal point on the page
 *
 * horizontal point - the directive's element top Y value (static number)
 * user scrolling point - top of viewport + offset (this value increases as user scrolls down, decreases as user scrolls up)
 *
 * @example when the right rail touches the bottom of the header (offset = 60px), event emitter emits true.
 * If the user scrolls back up and the rail no longer touches the header, the event emitter emits false
 */
@Directive({
  selector: '[floVerticalScrollListener]'
})
export class VerticalScrollListenerDirective implements AfterViewInit, OnDestroy {
  /**
   * Added distance from top of viewport
   */
  @Input() offset = 0

  /**
   * User has scrolled down past the top Y value of element
   */
  @Output()
  readonly scrolledPastElement = new EventEmitter<boolean>()

  /**
   * Tracking the window scroll event. This value will change while scrolling.
   */
  private readonly onScrollSource = new Subject<number>()
  private readonly onScroll$ = this.onScrollSource.pipe()

  /**
   * The top Y position of the element is not accessible until the AfterViewInit lifecycle.
   * This value will not change while scrolling.
   * getBoundingClientRect().top returns the top Y value of the element relative to the top of the viewport
   * @link https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect
   */
  private readonly afterViewInitSource = new Subject<void>()
  private readonly afterViewInit$ = this.afterViewInitSource.pipe(
    tap(() => {
      this.elementTopYValue = (this.el.nativeElement as HTMLElement)?.getBoundingClientRect()?.top || 0
    })
  )
  private elementTopYValue = 0
  private readonly ngOnDestroySource = new Subject<void>()
  private readonly ngOnDestroy$ = this.ngOnDestroySource.pipe()

  private readonly scrolledPastSource = new Subject<boolean>()
  private readonly scrolledPast$ = this.scrolledPastSource.pipe(
    startWith(false),
    distinctUntilChanged(),
    tap(value => {
      this.scrolledPastElement.emit(value)
    })
  )

  /**
   * Guarantees we start listening to the scroll event only after we have the element's location on the page
   * which is only accessible in the AfterViewInit lifecycle hook
   */
  // tslint:disable-next-line:no-unused-variable
  private whenScrollHappens$ = combineLatest([this.onScroll$, this.afterViewInit$])
    .pipe(withLatestFrom(this.scrolledPast$), takeUntil(this.ngOnDestroy$))
    .subscribe(([[windowTopYValue, _], scrolledPast]) => {
      // the Y position of the scrolling window >= the static top Y position of the directive's element
      if (windowTopYValue + this.offset >= this.elementTopYValue) {
        // If window has scrolled past the element for the firs time
        if (!scrolledPast) {
          this.triggerOnEnter()
        }
      } else {
        // If user has scrolled past element but is now reverse scrolling
        if (scrolledPast) {
          this.triggerOnLeave()
        }
      }
    })

  private readonly isBrowser: boolean

  constructor(
    private readonly el: ElementRef,
    // tslint:disable-next-line:ban-types
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId)
  }

  private triggerOnEnter(): void {
    this.scrolledPastSource.next(true)
  }

  private triggerOnLeave(): void {
    this.scrolledPastSource.next(false)
  }

  ngAfterViewInit(): void {
    if (this.isBrowser) {
      this.afterViewInitSource.next()
    }
  }

  ngOnDestroy(): void {
    this.ngOnDestroySource.next()
    this.ngOnDestroySource.complete()
    this.onScrollSource.complete()
    this.afterViewInitSource.complete()
  }

  // tslint:disable:no-unused-variable
  @HostListener('window:scroll', ['$event.target.defaultView.pageYOffset'])
  onScroll(pageYOffset: number): void {
    if (this.isBrowser) {
      this.onScrollSource.next(pageYOffset)
    }
  }
}
