import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormErrorsService } from '../services/form-errors.service'
import { FormErrorsComponent } from './form-errors.component'
import { ReactiveFormsModule } from '@angular/forms'
import { SvgModule } from '../svg/svg.module'

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, SvgModule],
  declarations: [FormErrorsComponent],
  providers: [FormErrorsService],
  exports: [FormErrorsComponent]
})
export class FormErrorsModule {}
