import { EXTERNAL_SITE_IDS, SiteIds, TRACK_WRESTLING_PROD_URL, TRACK_WRESTLING_STAG_URL } from '../models/site.model'

// Not exported. See isExternalSiteAllowedRoute
const trackWrestlingAllowedRoutesRegEx = new RegExp(
  '(account\\/.*)|^(\\/(forgot-password|reset-password|logout|signup|confirm-email|claim-account|plans|pay|thank-you)($|\\/.*))'
)
const isTrackWrestlingAllowedRoute = (url: string, route: string) => {
  return url.includes('trackwrestling') && trackWrestlingAllowedRoutesRegEx.test(route)
}

// Not exported. See isExternalSiteAllowedRoute
const mileSplitAllowedRoutesRegex = new RegExp(
  '(.*live\\/.*\\/(thank-you|pay|login|signup).*)|(account\\/.*)|' +
    '(\\/(forgot-password|reset-password|thank-you|logout|pay|login|signup|register)($|\\/.*))|(^\\/live\\/.*$)'
)
const isMileSplitAllowedRoute = (url: string, route: string) => {
  return url.includes('milesplit') && mileSplitAllowedRoutesRegex.test(route)
}

export const isExternalSiteAllowedRoute = (url: string, route: string) => {
  return isMileSplitAllowedRoute(url, route) || isTrackWrestlingAllowedRoute(url, route)
}

export const isExternalSite = (siteId: number | undefined): boolean => {
  return !!siteId && EXTERNAL_SITE_IDS.includes(siteId)
}

export const getExternalHostURL = (
  siteId: number | undefined,
  tokenDomain: string | undefined,
  env: string
): string => {
  if (siteId === SiteIds.TRACK_WRESTLING) return getTrackWrestlingHostByEnvironment(env)
  if (siteId === SiteIds.MILESPLIT) return `https://www${tokenDomain || '.milesplit.com'}`
  return `https://www.flosports.tv` // Fail-Safe. Should never occur.
}

export const getTrackWrestlingHostByEnvironment = (env: string): string => {
  return env !== 'prod' ? TRACK_WRESTLING_STAG_URL : TRACK_WRESTLING_PROD_URL
}

/**
 * Build external Login Account url for redirecting to TW
 * @param env - env to determine whether we are on prod, stag, or in local development
 * @returns Returns external TW login url with encoded next path
 */
export const buildTrackWrestlingLoginURL = (env: string): string => {
  return `${getTrackWrestlingHostByEnvironment(env)}/login`
}

/**
 * Build external Create Account url for redirecting to TW
 * @param redirectUrl - redirect url so TW knows where to route users in the webapp
 * @param env - env to determine whether we are on prod, stag, or in local development
 * @param next - next url parameter
 * @returns Returns external TW create account url with redirect
 */
export const buildTrackWrestlingCreateAccountURL = (
  redirectUrl: string,
  env: string,
  next: string | undefined
): string => {
  const trackWrestlingHost = `${getTrackWrestlingHostByEnvironment(env)}/login?view=signup`
  const nextPath = next ? next : ''

  if (nextPath.length > 0) {
    const encodedNextPath = encodeURIComponent(nextPath)
    const encodedRedirectUrl = encodeURIComponent(`${redirectUrl}?next=${encodedNextPath}`)
    return `${trackWrestlingHost}&redirectUrl=${encodedRedirectUrl}`
  }

  return `${trackWrestlingHost}&redirectUrl=${encodeURIComponent(redirectUrl)}`
}

export const buildMileSplitJoinURL = (next: string): string => {
  const isValidNext = next.length > 0 && next.includes('.milesplit.') && next.indexOf('http') === 0
  return isValidNext ? `https://${new URL(next).hostname}/join` : 'https://www.milesplit.com/join'
}

export const ensureUrlHttps = (url: string): string => {
  return url.includes('http:') ? url.replace(/^http:/, 'https:') : url
}
