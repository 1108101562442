import { FloDateYearPipe } from './flodateyear.pipe'
import { FloMoneyPipe } from './flo-money.pipe'
import { DatePipe } from '@angular/common'
import { NgModule } from '@angular/core'
import { FloTimePipe } from './flotime.pipe'
import { FloKeysPipe } from './flokeys.pipe'
import { FloTruncatePipe } from './flo-truncate.pipe'
import { FloArraySeparatorPipe } from './flo-array-separator.pipe'
import { FloVideoUrlPipe, FloVideoUrlQueryParamsPipe } from './flo-event-url.pipe'
import { FloPricePeriodPipe } from './flo-price-period.pipe'
import { ImagePipeModule } from './image.pipe.module'
import { DatePipeModule } from './date.pipe.module'
import { FloRelativeDatePipe } from './flo-relative-date.pipe'
import { FloSanitizeHtmlPipeModule } from 'src/app/libs/_pipes/sanitize-html/sanitize-html.pipe.module'
import { FloTypeGuardModule } from './flo-type-guard-pipe.module'

@NgModule({
  imports: [ImagePipeModule, DatePipeModule, FloTypeGuardModule, FloSanitizeHtmlPipeModule],
  declarations: [
    FloDateYearPipe,
    FloTimePipe,
    FloKeysPipe,
    FloMoneyPipe,
    FloTruncatePipe,
    FloArraySeparatorPipe,
    FloVideoUrlPipe,
    FloVideoUrlQueryParamsPipe,
    FloPricePeriodPipe,
    FloRelativeDatePipe
  ],
  exports: [
    DatePipeModule,
    FloDateYearPipe,
    FloTimePipe,
    FloKeysPipe,
    FloMoneyPipe,
    FloTruncatePipe,
    FloArraySeparatorPipe,
    FloVideoUrlPipe,
    FloVideoUrlQueryParamsPipe,
    FloPricePeriodPipe,
    ImagePipeModule,
    FloRelativeDatePipe,
    FloSanitizeHtmlPipeModule,
    FloRelativeDatePipe
  ],
  providers: [DatePipe]
})
export class FloPipeModule {}
