import { REQUEST } from '@nguniversal/express-engine/tokens'
import { PlatformService } from './platform.service'
import { Inject, Injectable } from '@angular/core'
import { CookieAttributes, getJSON, remove, set } from 'js-cookie'
import { BehaviorSubject, Observable } from 'rxjs'
import { LocationService } from './location.service'
import { UrlService } from './url.service'
import { maybe } from 'typescript-monads'
import { IdGeneratorService } from './id-generator.service'

export interface ICookieService {
  cookies$: Observable<{ [key: string]: any }>
  getAll(): any
  get(name: string): any
  set(name: string, value: any, options?: CookieAttributes): void
  remove(name: string, options?: CookieAttributes): void
}

/**
 * I wanted to call this anonymousId, but didn't want to confuse with Segment's anonymousId,
 * which is only a client-side id and is not sufficient for consistent bucketing.
 * Generating a random id for the user and storing in a cookie
 * so Growthbook doesn't rebucket users on every page load.
 * The cookie will persist across page loads and SSR + browser.
 * If a user is logged in, use their userId in addition to their visitorId,
 * so they are bucketed consistently across devices.
 */
export const COOKIE_VISITOR_ID = 'x-flo-visitor-id'

@Injectable({
  providedIn: 'root'
})
export class CookieService implements ICookieService {
  private cookieSource = new BehaviorSubject<{ [key: string]: any }>(this.getAll())
  public cookies$ = this.cookieSource.asObservable()

  constructor(
    private ps: PlatformService,
    private ls: LocationService,
    private url: UrlService,
    private readonly uuidService: IdGeneratorService,
    @Inject(REQUEST) private req: any
  ) {}

  public get visitorId(): string {
    const id = this.get(COOKIE_VISITOR_ID) || this.uuidService.getUniqueId()

    // Store the visitor cookie if not already there
    if (!this.get(COOKIE_VISITOR_ID)) {
      this.set(COOKIE_VISITOR_ID, id)
    }

    return id
  }

  private defaultOptions: CookieAttributes = {
    domain: maybe(this.ls.hostname).map(this.url.parseBaseDomain).valueOrUndefined()
  }

  public set(name: string, value: any, options?: CookieAttributes): void {
    if (this.ps.isBrowser) {
      set(name, value, {
        ...this.defaultOptions,
        ...options
      })
      this.updateSource()
    }
  }

  public remove(name: string, options?: CookieAttributes): void {
    if (this.ps.isBrowser) {
      remove(name, {
        ...this.defaultOptions,
        ...options
      })
      this.updateSource()
    } else {
      // TODO: server side cookie handling
    }
  }

  public get(name: string): any {
    if (this.ps.isBrowser) {
      return getJSON(name)
    } else {
      if (this.req && this.req.cookies) return this.req.cookies[name]
    }
  }

  /**
   * Performs a check to see if the experiment varition id value exists in the x-flo-ab-forced cookie.
   */
  public hasAbForcedCookie(experimentId: string): boolean {
    if (this.ps.isServer) {
      return this.req.headers['x-flo-ab-forced']?.includes(`${experimentId}-1`) || false
    }
    if (this.ps.isBrowser) {
      return getJSON('x-flo-ab-forced')?.includes(`${experimentId}-1`) || false
    }
    return false
  }

  public getAll(): any {
    if (this.ps.isBrowser) {
      return getJSON()
    } else {
      if (this.req) return this.req.cookies
    }
  }

  private updateSource() {
    this.cookieSource.next(this.getAll())
  }
}
