import * as A from 'fp-ts/Array'
import * as Eq from 'fp-ts/Eq'
import { TrackPageDetails } from '../../analytics/services/segment.service'

export type HasInstance<A> = { instance: A }

/**
 * The Google PubAds directory path from which ads will be loaded.
 *
 * @example
 * '/43625987/flodogs.3'
 *
 * @see {@link https://developers.google.com/doubleclick-gpt/guides/get-started#ad-unit-path Ad Unit Path}
 */
export type AdDirectory = string

/**
 * This value provides the allowed sizes for a slot.
 *
 * Each pair of [width, height] in the array is a size at which an ad is allowed
 * to load. Be mindful of layout shift. If you provide more than one size, your
 * skeleton must have the largest width and height from the sizes.
 *
 * @example
 * [[200, 200], [100, 250]]
 * // Skeleton must be 200px wide, 250px tall for these sizes
 */
export type AdSizes = Array<[number, number]>

/**
 * The Slot ID is the unique identifier for an ad we want to load on the site.
 *
 * You may see this referred to as Div ID, Slot Element ID, Ad Slot ID, or something
 * similar. In all cases, they refer to the same thing: The unique identifier for
 * the ad for GPT, as well as the element ID that will be on the ad div in the DOM.
 *
 * This value will be randomized when loading through the AdComponent to avoid slot
 * conflicts. Slot conflicts will prevent ads from loading.
 */
export type SlotID = string

/** This is the ID of one of our content nodes, used for targeting ads. */
type NodeID = number

/** This is the slug for a category attached to content, used for targeting ads. */
type CategorySlug = string

/** This is the ID of a live event. */
type LiveId = number

/** All possible types for a display ad */
export type AdTypes =
  | 'leaderboard-large'
  | 'leaderboard-small'
  | 'rectangle-small'
  | 'rectangle-large-sticky'
  | 'rectangle-mobile'
  | 'adhesion'
  | 'skin'
  | 'content-mobile'
  | 'interstitial'

/** A set of valid ad targeting keys. */
export interface AdTargeting {
  /** An array of all content IDs to target for an ad. */
  contentIds?: ReadonlyArray<NodeID>

  /** An array of all category slugs to target for an ad. */
  categorySlugs?: ReadonlyArray<CategorySlug>

  /** The Live Event ID to target for an ad. */
  liveId?: LiveId

  /** True if this user has a premium account, false if anonymous or free. */
  isPremium?: boolean

  /** Position (1,2,3,etc) of the ad if it appears within a long list (see: WatchComponent) */
  adPosition?: number

  /** Node info needed for segment tracking */
  analyticsTracking?: TrackPageDetails

  /** Optional string indicating control or variation for an experiment. */
  experiment?: string
}

// Deep equality check for Ad Targeting
export const EqAdTargeting: Eq.Eq<AdTargeting> = Eq.getStructEq({
  isPremium: Eq.eqBoolean,
  liveId: Eq.eqNumber,
  contentIds: A.getEq(Eq.eqNumber),
  categorySlugs: A.getEq(Eq.eqString)
})

// tslint:disable-next-line:class-name
interface _AdTargeting {
  contentIds: ReadonlyArray<string>
  categorySlugs: ReadonlyArray<string>
  liveId?: number
  isPremium?: boolean
  adPosition?: number
  analyticsTracking?: TrackPageDetails
  experiment?: string
}

/** Use this constructor to create Ad Targeting kv-pairs for AdConfigs. */
function _AdTargeting(targets: AdTargeting): _AdTargeting {
  return {
    contentIds: targets.contentIds?.map(String) ?? [],
    categorySlugs: targets.categorySlugs ?? [],
    liveId: targets.liveId,
    isPremium: targets.isPremium,
    adPosition: targets.adPosition,
    experiment: targets.experiment,
    analyticsTracking: targets.analyticsTracking
      ? {
          premium: targets.analyticsTracking.premium,
          live_event: targets.analyticsTracking.live_event,
          id: targets.analyticsTracking.id,
          node: {
            primary_event_association: targets.analyticsTracking.node?.primary_event_association ?? undefined
          },
          type: targets.analyticsTracking.type,
          author: targets.analyticsTracking.author
        }
      : undefined
  }
}

/**
 * An AdConfig defines all of the necessary information to define and target a slot in GPT.
 *
 * @see {@link https://developers.google.com/doubleclick-gpt/reference#googletag.defineSlot defineSlot}
 * @see {@link https://developers.google.com/doubleclick-gpt/guides/key-value-targeting Ad Targeting}
 */
export interface AdConfig {
  adDirectory: AdDirectory
  sizes: AdSizes
  adSlotId: SlotID
  targeting?: _AdTargeting
}

/**
 * Converts loadAd arguments into a convenient data structure for performing ad loading.
 *
 * For more information about argument purposes, with some examples, see the
 * AdConfig docs.
 *
 * @see AdConfig
 */
export function AdConfig(
  adDirectory: AdDirectory,
  sizes: AdSizes,
  adSlotId: SlotID,
  adTargeting?: AdTargeting
): AdConfig {
  return {
    adDirectory,
    adSlotId,
    sizes: A.copy(sizes),
    targeting: _AdTargeting(adTargeting ?? {})
  }
}
