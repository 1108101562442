<div *ngIf="section" class="headlines">
  <div class="headlines__title">
    <h6 class="heavy">{{ section.title }}</h6>
  </div>
  <div class="headlines__items">
    <div *ngFor="let headline of section.items; trackBy: trackById" class="d-flex mt-25">
      <div class="headlines__items--middot me-2">
        <span>&middot;</span>
      </div>
      <div class="headlines__items--headline">
        <a floAnalytics [floCustomLink]="headline.action.url" [analyticsProperties]="headline.action?.analytics" class="subhead color-900 hover-text-primary mb-0 text-line-clamp-2">{{ headline.title }}</a>
        <p class="footnote color-500 mb-0">{{ headline.footer | headlineFooter }}</p>
      </div>
    </div>
  </div>
</div>
