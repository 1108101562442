import { Params } from '@angular/router'
import { LiveEvent } from '@flocasts/flosports30-types/dist/entity'
import { IFinalNavigation } from 'src/app/shared/services/checkout-navigation.service'

/* checks for the redirect= queryParam starting with /live/ */
export const redirectIsLive = (redirect: string) => redirect.match(/^\/live.*$/)
export const redirectIsAccountSettings = (redirect: string) => redirect.match(/^\/account.*$/)
export const redirectIsFavorites = (redirect: string) => redirect.match(/^\/account\/choose-favorites.*$/)
export const redirectIsActivate = (redirect: string) => redirect.match(/^\/activate.*$/)
export const redirectIsOmalley = (redirect: string) => redirect.match(/^\/redeem\/omalley.*$/)
export const nextIsArena = (next: string) => next.match(/arena.*$/)
export const redirectIsMileSplit = (redirect?: string) => redirect && redirect.includes('http')

/**
 * @param isFreeUser - a user that does not have a subscription
 * @param queryParam - the query parameters from the URL
 * @param liveEvent - the live event the user is trying to access
 */
export const shouldRouteToPlans = (isFreeUser: boolean, queryParam: Params = {}, liveEvent?: LiveEvent) => {
  if (
    queryParam.redirect &&
    (redirectIsAccountSettings(queryParam.redirect) ||
      redirectIsActivate(queryParam.redirect) ||
      redirectIsOmalley(queryParam.redirect))
  ) {
    return false
  }

  if (queryParam.next && nextIsArena(queryParam.next)) {
    return false
  }

  // if in free paywall
  if (queryParam.fp) {
    return false
  }
  // if in gift subscription
  if (queryParam.giftSub) {
    return false
  }
  // if in free registration
  if (queryParam.register) {
    return false
  }

  /**
   * If free user is accessing a free live event, skip Plans routes
   * Otherwise if a free user, redirect to Plans routes
   */
  if (isFreeUser) {
    if (!!queryParam.redirect) {
      const isLiveEventFree = liveEvent && !liveEvent.premium
      return !isLiveEventFree
    } else {
      return true
    }
  }

  return false
}

/**
 * parses the /live/43101-some-slug/ redirect queryParam to return only 43101
 */
export const getLiveIdFromRedirectQueryParam = (redirect: string): number | undefined => {
  if (redirectIsLive(redirect)) {
    const parsed = redirect.split('/')[2]
    return +parsed.split('-')[0]
  }

  return undefined
}

/**
 * We have three different query params for redirecting users
 * 1. redirect - used with paywalls/premium content and when detected, high probability user will be redirected to plans page. @see shouldRouteToPlans
 * 2. next - used mainly for redirecting to external sites
 * 3. go - used for redirecting within the app to non-premium content
 * ex: When a user is on the events page and clicks login button in the header,
 * we set ?go=/events so they can be returned there after logging in
 * @param queryParams The query params in the url
 */
export const determineFinalDestinationFromQueryParams = (queryParams: Params): IFinalNavigation => {
  const redirectUrl = queryParams.redirect
  const nextUrl = queryParams.next
  const goUrl = queryParams.go

  if (!!redirectUrl && redirectIsLive(redirectUrl)) {
    return {
      url: redirectUrl,
      isExternal: false,
      type: 'redirect'
    }
  }

  if (!!nextUrl || redirectIsMileSplit(redirectUrl)) {
    return {
      url: nextUrl || redirectUrl,
      isExternal: true,
      type: nextUrl ? 'next' : 'redirect'
    }
  }

  return {
    url: redirectUrl || goUrl || '/',
    isExternal: false,
    type: redirectUrl ? 'redirect' : 'go'
  }
}
