import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { LinkModel, NavModel } from '@flocasts/experience-service-types'
import { trackById } from 'src/app/shared/utility-functions/track-by-id.utility'

@Component({
  selector: 'flo-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavComponent {
  @Input() nav: NavModel
  @Input() selectedLink: LinkModel | undefined
  @Input() siteId?: number
  // Optional tagName to override the segment tracking of individual items in the nav
  @Input() tagName?: string

  // Pass clicked link up to parent for tracking selected state
  @Output() navLinkClicked = new EventEmitter<LinkModel>()

  readonly trackById = trackById

  public handleLinkClicked(link: LinkModel) {
    this.navLinkClicked.emit(link)
  }
}
