import { VerticalService } from '../../singleton-services/vertical.service'
import { Injectable } from '@angular/core'
import { combineLatest, map } from 'rxjs'
import { SiteSettings } from '../models/site-settings.model'
import { ExperimentationService } from '../experimentation/experimentation.service'
import { FEAT_GIFTING_ID, FEAT_GIFTING_VARIATION } from '../experimentation/experiment.model'

const footerItemNames: Partial<SiteSettings> = {
  gift_url: 'Gift a Subscription',
  contact_url: 'Customer Support',
  careers_url: 'Careers',
  faq_url: 'FAQ',
  flosports_url: 'About FloSports',
  california_privacy_url: 'California Privacy Policy',
  privacy_url: 'Privacy Policy',
  tos_url: 'Terms of Use'
}

@Injectable()
export class FooterService {
  constructor(
    private readonly verticalService: VerticalService,
    private readonly experimentationService: ExperimentationService
  ) {}

  private isInGiftingVariation$ = this.experimentationService.isInVariation(FEAT_GIFTING_ID, FEAT_GIFTING_VARIATION)

  public footer$ = combineLatest([this.isInGiftingVariation$, this.verticalService.siteSettings$]).pipe(
    map(([isGiftingVariation, settings]) => {
      return Object.keys(footerItemNames)
        .filter(key => filterFunction(key, settings, isGiftingVariation))
        .map(key => ({
          // can safely assume that key is in the following objects because of the
          // subset type of footerItemNames and the filter above
          // @ts-expect-error
          name: footerItemNames[key],
          // @ts-expect-error
          href: settings[key]
        }))
    })
  )
}

/**
 * Filter function for footer items
 * Ensures that the itemKey is in the siteSettings and that the itemKey is not 'gift_url' if the
 * isGiftVariation feature flag is false
 *
 * exported solely for testing
 */
export const filterFunction = (itemKey: string, siteSettings: SiteSettings, isGiftVariation: boolean) => {
  return itemKey in siteSettings && !(!isGiftVariation && itemKey === 'gift_url')
}
