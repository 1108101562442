import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core'
import { distinctUntilChanged, map, shareReplay, tap } from 'rxjs/operators'
import { combineLatest, ReplaySubject } from 'rxjs'
import { IVolumeControls } from '../../models/volume-controls.model'
import { maybe } from 'typescript-monads'

@Component({
  selector: 'flo-video-volume-control',
  templateUrl: './video-volume-control.component.html',
  styleUrls: ['./video-volume-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoVolumeControlComponent implements OnChanges {
  @Input()
  get eventAnalytics(): any {
    return {
      ...this._eventAnalytics
    }
  }

  set eventAnalytics(val: any) {
    this._eventAnalytics = val
  }
  private _eventAnalytics: any
  private volumeSource = new ReplaySubject<number>(1)
  private volumeValue$ = this.volumeSource.pipe(distinctUntilChanged(), shareReplay(1))
  private volumeMutedSource = new ReplaySubject<boolean>(1)
  private volumeMuted$ = this.volumeMutedSource.pipe(distinctUntilChanged(), shareReplay(1))
  public isMuted = true
  @Input()
  volumeState: IVolumeControls

  @Output()
  readonly volumeDragged = new EventEmitter<number>()
  @Output()
  readonly volumeView = new EventEmitter<IVolumeControls>()

  view$ = combineLatest([this.volumeValue$, this.volumeMuted$]).pipe(
    map(([volumeValue, volumeMuted]) => {
      this.isMuted = volumeMuted
      const volume = volumeMuted ? 0 : volumeValue
      return {
        volume,
        volumeValue,
        volumeMuted,
        highVolume: volume <= 1 && volume >= 0.67,
        midVolume: volume <= 0.66 && volume >= 0.33,
        lowVolume: volume <= 0.32 && volume > 0,
        mutedVolume: volume === 0,
        analyticProps: {
          name: volumeMuted ? 'Mute' : 'Unmute',
          ...this.eventAnalytics
        }
      }
    }),
    tap(volume => {
      this.volumeView.next({
        volume: volume.volumeValue,
        isMuted: volume.volumeMuted
      })
    }),
    shareReplay(1)
  )

  _volumeChanged(volume: number) {
    this.isMuted = volume < 0
    this.volumeMutedSource.next(this.isMuted)
  }

  _volumeDragged(volume: number) {
    this.toggleVolumeIcon(volume)
  }

  toggleVolumeIcon(volume: number) {
    this.volumeSource.next(volume)
  }

  _muteVolume() {
    this.toggleMute()
  }

  toggleMute() {
    this.isMuted = !this.isMuted
    this.volumeMutedSource.next(this.isMuted)
  }

  ngOnChanges(changes: SimpleChanges) {
    maybe(changes.volumeState)
      .flatMap(change => maybe<Required<IVolumeControls>>(change.currentValue))
      .tapSome(control => {
        this.volumeSource.next(control.volume)
        this.volumeMutedSource.next(control.isMuted)
      })
  }
}
