import { AdConfig, AdDirectory, AdSizes, SlotID } from './ad-config'
import { Slot, SlotSizes } from './gpt.service'

/**
 * The datum required to pass to fetch bids.
 *
 * @see {@link https://ams.amazon.com/webpublisher/uam/docs/reference/api-reference.html#apstagfetchbidsbidconfig-callback}
 */
export interface ApsSlotData {
  slotID: SlotID
  slotName: AdDirectory
  sizes: AdSizes
}

/**
 * The type of the slots returned from fetchBids.
 *
 * They do include other information, but we have no business interacting with it.
 * The intent is to send it directly to gpt for targeting.
 *
 * @see {@link https://ams.amazon.com/webpublisher/uam/docs/reference/api-reference.html#apstagfetchbidsbidconfig-callback}
 */
export type ApsTargetedSlot = Pick<ApsSlotData, 'slotID'>

/** Construct the data needed to make a fetchBids call. */
export function ApsSlotData(conf: AdConfig): ApsSlotData {
  return {
    slotName: conf.adDirectory,
    sizes: conf.sizes,
    slotID: conf.adSlotId
  }
}

/**
 * Convert an array of SlotSizes (what gpt returns from getSizes()) into AdSizes
 */
function convertSlotSizesToAdSizes(sizes: ReadonlyArray<SlotSizes>): AdSizes {
  return sizes.map((size: SlotSizes) => [size.width, size.height])
}

/** Also constructs the data needed to make a fetchBids call,
 * but from existing Slot information. Used when we refresh bids
 * and need the Slot information, not the initial config (on load)
 */
export function refreshApsSlotData(slots: readonly Slot[]): ReadonlyArray<ApsSlotData> {
  return slots.map((slot: Slot) => {
    return {
      sizes: convertSlotSizesToAdSizes(slot.getSizes()),
      slotName: slot.getAdUnitPath(),
      slotID: slot.getSlotElementId()
    }
  })
}
