import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import type { ImageWithButtonsModel } from '@flocasts/experience-service-types'

@Component({
  selector: 'flo-image-with-buttons',
  templateUrl: './image-with-buttons.component.html',
  styleUrls: ['./image-with-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageWithButtonsComponent {
  @Input()
  public model: ImageWithButtonsModel

  @Input()
  public geoBlockedIds: ReadonlyArray<number>
}
