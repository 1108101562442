<div [ngClass]="model.style | floCssClassSanitize">
  <ng-container *ngIf="!!model.image && model.image.type === 'image'">
    <div class="pb-3">
      <flo-image [image]="model.image"></flo-image>
    </div>
  </ng-container>

  <ng-container *ifLoaded="geoBlockedIds; template: 'vertical-buttons-full-width'">
    <div class="d-flex flex-row flex-xl-column gap-2">
      <ng-container *ngIf="!!model.cta1 && model.cta1.type === 'link' && geoBlockedIds.length === 0">
        <div class="col pb-2 btn-full-width">
          <flo-link [link]="model.cta1" tagName="Button"></flo-link>
        </div>
      </ng-container>
      <ng-container *ngIf="!!model.cta2 && model.cta2.type ==='link' && geoBlockedIds.length === 0">
        <div class="col pb-2 btn-full-width">
          <flo-link [link]="model.cta2" tagName="Button"></flo-link>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>