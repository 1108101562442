import { HttpClient, HttpParams } from '@angular/common/http'
import { FloApiResponse } from '../models/api-response.model'
import { AuthService } from '../../singleton-services/auth/auth.service'
import type { IUserIdentity } from '../../singleton-services/auth/auth.interfaces'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, of, Subject } from 'rxjs'
import { shareReplay, take, flatMap, tap, filter } from 'rxjs/operators'
import { UserSubscriptionResponse } from '../subscriptions/subscriptions.interface'
import {
  CORE_API_CLAIM_ACCOUNT_PATH,
  CORE_API_USERS_NEWSLETTER_PATH,
  CORE_API_USERS_PATH,
  CORE_API_USERS_RESET_PASSWORD_CHECK_TOKEN_PATH,
  CORE_API_RESET_PASSWORD_PATH,
  CORE_API_FORGOT_PASSWORD_PATH
} from 'src/app/app.config'
import { SiteIds } from '../models/site.model'

export interface IUserService {
  updateInformation(user: IUserInfo, userId: number): Observable<UserSubscriptionResponse>
  updatePassword(currentPassword: string, newPassword: string): Observable<object | undefined>
  refreshUserData(): Observable<FloApiResponse<IUserData> | undefined>
  uploadProfilePicture(picture: FormData): Observable<IUserIdentity | undefined>
  signupForNewsletter(email: string, siteId: number | undefined): any
  checkToken(token: string): any
  sendForgotPasswordEmail(email: string): Observable<string>
  resetPassword(token: string, password: string): any
  sendClaimAccountEmail(email: string): any
  setPassword(token: string, password: string): any
}

export interface ITokenResponse {
  token: string
  exp: number
  refresh_token: string
  refresh_token_exp: number
  user: IUserIdentity
  user_id: number
}

export interface IUserInfo {
  country: string
  email: string
  first_name: string
  last_name: string
  phone: string
  username: string
  zip: number
}

export interface IMigratedUserInfo {
  email: string
  migrated_site: string
}

export interface IUserData {
  profile_picture_url_small: string
  profile_picture_url_medium: string
  profile_picture_url_large: string
  id: number
  roles: ReadonlyArray<string>
  enabled: boolean
  username: string
  email: string
  first_name: string
  last_name: string
  gender: string
  birthday: string
  country: string
  zip: number
  phone: string
  site_id: number
  user_subscriptions: ReadonlyArray<object>
  site_ids: ReadonlyArray<object>
  universal: boolean
  new_facebook_user: boolean
  created_at: string
}

@Injectable()
export class UserService implements IUserService {
  public migratedUserInfoSource = new BehaviorSubject<IMigratedUserInfo | undefined>(undefined)
  public migratedUserInfo$ = this.migratedUserInfoSource.pipe(shareReplay(1))
  private userResponseSubject = new Subject<FloApiResponse<IUserData> | undefined>()
  public userResponse$ = this.userResponseSubject.asObservable()

  constructor(private http: HttpClient, private authService: AuthService) {}

  public updateInformation(user: IUserInfo, userId: number): Observable<UserSubscriptionResponse> {
    return this.http.patch<UserSubscriptionResponse>(`users/${userId}`, user)
  }

  public updatePassword(currentPassword: string, newPassword: string): Observable<object | undefined> {
    return this.authService.userIdentity$.pipe(
      flatMap(user => {
        if (user) {
          return this.http.patch(`${CORE_API_USERS_PATH}/${user.id}/password`, {
            current_password: currentPassword,
            plain_password: newPassword
          })
        } else {
          return of(undefined)
        }
      })
    )
  }

  /**
   * @deprecated
   * @see Use AccountService userData$ and updateUserData.
   * @see {@link https://flocasts.atlassian.net/browse/FLO-11666}
   */
  public refreshUserData(): Observable<FloApiResponse<IUserData> | undefined> {
    return this.authService.userIdentity$.pipe(
      take(1),
      flatMap((user: IUserIdentity) => this.http.get<FloApiResponse<IUserData>>(`${CORE_API_USERS_PATH}/${user.id}`)),
      tap(user => this.userResponseSubject.next(user))
    )
  }

  // random number appended to POST body because FormData has no toString() serialization for StateTransfer cache
  public uploadProfilePicture(picture: FormData): Observable<IUserIdentity | undefined> {
    picture.append('ran', Math.random().toString())
    return this.authService.userIdentity$.pipe(
      take(1),
      filter<IUserIdentity>(Boolean),
      flatMap(user =>
        this.authService.authorizeViaHttp(
          this.http.post<any>(`${CORE_API_USERS_PATH}/${user.id}/profile-picture`, picture)
        )
      )
    )
  }

  public signupForNewsletter(email: string, siteId: number | undefined) {
    const params = new HttpParams({
      fromObject: {
        site_id: siteId || SiteIds.FLODOGS
      }
    })
    return this.http.post(
      `${CORE_API_USERS_NEWSLETTER_PATH}`,
      {
        email
      },
      { params }
    )
  }

  /**
   * Make a GET request to check if a token is good or expired/bad.
   *
   * 202 - Successful (token is valid)
   * 417 - Expectation failed (token is invalid
   */
  public checkToken(token: string) {
    if (token) {
      return this.http.get(`${CORE_API_USERS_RESET_PASSWORD_CHECK_TOKEN_PATH}/${token}`)
    } else {
      return of(false) // TODO: maybe just return here
    }
  }

  public sendForgotPasswordEmail(email: string): Observable<string> {
    return this.http.post(CORE_API_FORGOT_PASSWORD_PATH, { email }, { responseType: 'text' })
  }

  /**
   * Make a PATCH request to reset a user's password
   *
   * 204 - Successful
   * 400 - Password is invalid
   * 404 - Not found (bad token). (Note: No user should even be able to view the form with an invalid token.)
   * 500 - Unknown server error
   */
  public resetPassword(token: string, password: string) {
    return this.http.patch(`${CORE_API_RESET_PASSWORD_PATH}/${token}`, {
      plain_password: password
    })
  }

  /**
   * Make a POST request to send an email containing a token to claim a migrated account.
   *
   * 202 - Successful
   * 400 - "Validation failed" (invalid email address)
   * 403 - "User cannot be claimed" (email exists, but either it's not a migrated account or it's already claimed)
   * 404 - "User does not exist" (email not found at all in our database)
   */
  public sendClaimAccountEmail(email: string) {
    return this.http.post(CORE_API_CLAIM_ACCOUNT_PATH, { email })
  }

  /**
   * Make a PATCH request to update the migrated user's password.
   *
   * 204 - Successful
   * 404 - Not found (using a bad token, an expired token, using same token for an already-claimed account)
   */
  public setPassword(token: string, password: string) {
    return this.http.patch(`${CORE_API_CLAIM_ACCOUNT_PATH}/${token}`, {
      plain_password: password
    })
  }
}
