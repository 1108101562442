<ng-container *ngIf="!!container">
  <div
    class="flo-container"
    [ngClass]="[
      container.style | floCssClassSanitize,
      container.distribution | floCssClassSanitize,
    ]"
    [style.height]="height"
  >
    <ng-container *ngIf="container.title?.type === 'text'">
      <h5 class="h5 m-0">{{ container.title?.text }}</h5>
    </ng-container>
    <ng-container
      *ngFor="let item of container.items; trackBy: trackById; let first = first;"
    >
      <!-- recursive - when type is container -->
      <ng-container *ngIf="item.type === 'container'">
        <flo-container [container]="item"></flo-container>
      </ng-container>

      <ng-container *ngIf="item.type === 'image-with-buttons'">
        <flo-image-with-buttons
          [model]="item"
          [geoBlockedIds]="geoBlockedIds"
        ></flo-image-with-buttons>
      </ng-container>

      <ng-container *ngIf="item.type === 'title-with-action'">
        <div class="w-100 border-bottom" [ngClass]="{ 'mt-5': !first }">
          <flo-slim-header
            [title]="item.title"
            [link]="item.action"
          ></flo-slim-header>
        </div>
      </ng-container>

      <ng-container *ngIf="item.type === 'ad'">
        <div class="my-3" [ngClass]="{ 'mt-0': first, 'h-100': item.stickToTop }">
          <flo-ad [ad]="item"></flo-ad>
        </div>
      </ng-container>

      <ng-container *ngIf="item.type === 'cell:decorated-text-web'">
        <div class="border-bottom border-grey-300" [ngClass]="{'border-md-top': first}">
          <flo-decorated-text-cell [item]="item"></flo-decorated-text-cell>
        </div>
      </ng-container>

      <ng-container *ngIf="item.type === 'nav'">
        <div class="pb-4">
          <flo-titled-list [listItems]="item"></flo-titled-list>
        </div>
      </ng-container>

      <!-- filters -->
      <ng-container *ngIf="item.type === 'filter'">
        <!-- wrapping in an array since the filter component only takes an array of filters -->
        <!-- We never specify output observer here, we always want to route  -->
        <flo-filters [items]="[item]"></flo-filters>
      </ng-container>

      <!-- segmented control -->
      <ng-container
        *ngIf="item.type === 'segmented-control'"
        class="segmented-control-grid"
      >
        <flo-segmented-control
          [segmentedControl]="item"
        ></flo-segmented-control>
      </ng-container>

      <!-- message -->
      <ng-container *ngIf="item?.type === 'message'">
        <div class="container-item">
          <flo-message [message]="item"></flo-message>
        </div>
      </ng-container>

      <!-- table -->
      <ng-container *ngIf="item.type === 'table'">
        <div class="container-item mx-n3 mx-sm-0">
          <flo-table [table]="item"></flo-table>
        </div>
      </ng-container>

      <!-- legend -->
      <ng-container *ngIf="item.type === 'legend'">
        <div class="container-item">
          <flo-legend [legend]="item"></flo-legend>
        </div>
      </ng-container>

      <!-- pagination -->
      <ng-container *ngIf="item.type === 'pagination'">
        <div class="container-item">
          <flo-pagination [pagination]="item"></flo-pagination>
        </div>
      </ng-container>

      <!-- event card -->
      <ng-container
        *ngIf="
          item.type === 'card:event' && item.style === 'horizontal:footer-link'
        "
      >
        <div class="py-35 border-bottom">
          <flo-event-card-horizontal
            [item]="item"
            [geoBlockedIds]="[]"
          ></flo-event-card-horizontal>
        </div>
      </ng-container>

      <!-- card -->
      <ng-container
        *ngIf="item.type === 'card' && item.style === 'featured-card:alternate'"
      >
        <div class="py-35 border-bottom">
          <flo-static-content-card [card]="item"></flo-static-content-card>
        </div>
      </ng-container>

      <!-- grappling result card -->
      <ng-container *ngIf="item.type === 'card:grappling-result'">
        <div class="grappling-result-card">
          <div class="py-3 border-bottom">
            <flo-grappling-result-card
              [result]="item"
            ></flo-grappling-result-card>
          </div>
        </div>
      </ng-container>

      <!-- two column container -->
      <ng-container *ngIf="item.type === 'container:two-column'">
          <flo-two-column-container [model]="item"></flo-two-column-container>
      </ng-container>

      <!-- wrestling result card -->
      <ng-container *ngIf="item.type === 'card:wrestling-result'">
        <div class="py-1 border-bottom">
          <flo-wrestling-result-card
            [wrestlingResult]="item"
          ></flo-wrestling-result-card>
        </div>
      </ng-container>

      <!-- html view-->
      <ng-container
        *ngIf="
          item.type === 'html-view' ||
          item.type === 'html-view-with-anchor-points'
        "
      >
        <flo-html-view [htmlView]="item"></flo-html-view>
      </ng-container>

      <!-- expandable text -->
      <ng-container *ngIf="item.type === 'expandable-text'">
        <div class="py-2">
          <flo-expandable-text [expandableText]="item"></flo-expandable-text>
        </div>
      </ng-container>

      <!-- infinite scroll -->
      <!-- this should always be at the bottom of this component -->
      <ng-container *ngIf="item.type === 'link' && item.style === 'paginate'">
        <div
          data-test="container-pagination-spinner"
          class="d-flex spinner-container w-100"
        >
          <div class="spinner-border text-light d-flex m-auto"></div>
        </div>
        <div
          floElementIsVisible
          [valueToOutput]="item"
          (elementIsVisible)="emitNextPage($event)"
        ></div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
