import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import type { PaginationModel } from '@flocasts/experience-service-types'
import { trackById } from '../../shared/utility-functions/track-by-id.utility'

@Component({
  selector: 'flo-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginationComponent {
  @Input()
  public pagination: PaginationModel

  readonly trackById = trackById
}
