<div class="two-column-container d-flex flex-column flex-md-row">
  <div data-test="two-column-left" *ngIf="model.leftCol" class="w-100 w-md-50"
       [ngClass]="{ 'w-100': !model.rightCol }">
      <flo-container [container]="model.leftCol"></flo-container>
  </div>
  <div data-test="two-column-right" *ngIf="model.rightCol" class="w-100 w-md-50"
       [ngClass]="{ 'w-100': !model.leftCol }">
    <flo-container [container]="model.rightCol"></flo-container>
  </div>
</div>
