import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

import { OldSvgDirective } from './old-svg.directive'

/**
 * @deprecated use the new SVG sprite sheets
 * @see SvgModule
 */
@NgModule({
  imports: [CommonModule],
  declarations: [OldSvgDirective],
  exports: [OldSvgDirective]
})
export class OldSvgModule {}
