import { NgModule } from '@angular/core'
import { ExperimentationWidgetComponent } from './experimentation-widget/experimentation-widget.component'
import { ExperimentationService } from './experimentation.service'
import { FloFlaggerService } from './floflagger.service'
import { CommonModule } from '@angular/common'
import { FloModalModule } from '../modal/modal.module'

@NgModule({
  imports: [CommonModule, FloModalModule],
  declarations: [ExperimentationWidgetComponent],
  exports: [ExperimentationWidgetComponent],
  providers: [FloFlaggerService, { provide: ExperimentationService, useClass: FloFlaggerService }]
})
export class FloExperimentationModule {}
