import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { NavModule } from '../navigation/nav.module'
import { SiteNavigationDataService } from './site-navigation-data.service'
import { SiteNavigationComponent } from './site-navigation.component'
import { FlyOutModule } from '../fly-out/fly-out.module'
import { SubnavigationModule } from '../subnavigation/subnavigation.module'
import { PrimaryNavigationModule } from '../primary-navigation/primary-navigation.module'

@NgModule({
  imports: [CommonModule, NavModule, FlyOutModule, SubnavigationModule, PrimaryNavigationModule],
  exports: [SiteNavigationComponent],
  declarations: [SiteNavigationComponent],
  providers: [SiteNavigationDataService]
})
export class SiteNavigationModule {}
