<div class="bg-white">
  <!-- leaderboard ad -->
<div
  class="w-100 justify-content-center align-items-center d-none d-lg-flex py-3"
>
  <div
    class="leaderboard-center skeleton leaderboard-skeleton"
  ></div>
</div>


<main class="container container-fluid min-vh-100 pt-4">
  <div class="row">
    <div class="col-12 col-lg-8 pt-3 border-lg-right mb-10">
      <div class="row justify-content-between pt-3 border-md-bottom ">
        <!-- heading -->
        <div class="col-6 col-md-4">
          <h1 class="h1-skeleton w-100 py-4 mb-4"></h1>
        </div>
        <!-- search -->
        <div class="col-12 col-md-4">
          <div class="skeleton w-100 pb-5 mb-2"></div>
        </div>
      </div>
      <div class="d-block d-md-none mx-3 skeleton"></div>

      <div class="pt-5 pb-3"></div>
      <div class="row">
        <!-- individual avatar -->
        <div *ngFor="let avatar of [0,1,2,3,4,5,6,7,8,9,10,11]" class="col-4 col-md-2 d-flex flex-column align-items-center mb-4" >
          <!-- avatar circular thumbnail -->
          <h1 class="skeleton favorite-skeleton__avatar rounded-circle mb-4"></h1>
          <!-- avatar title -->
          <h1 class="h1-skeleton w-100"></h1>
        </div>
      </div>
    </div>
    <div class="d-none d-md-block col-lg-4 pt-3">
      <!-- right rail rectangle ad -->
      <div class="skeleton ad-rectangle-skeleton"></div>
    </div>
  </div>
</main>
</div>
