import { NgModule } from '@angular/core'
import { GrapplingResultCardComponent } from './grappling-result-card.component'
import { AnalyticsModule } from 'src/app/shared/analytics/analytics.module'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'

@NgModule({
  imports: [CommonModule, AnalyticsModule, RouterModule],
  exports: [GrapplingResultCardComponent],
  declarations: [GrapplingResultCardComponent]
})
export class GrapplingResultCardModule {}
