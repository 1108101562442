<div class="d-flex subnavigation-wrapper">
  <div class="subnavigation-content d-flex align-items-center gap-lg-3">
    <ng-container *ngIf="subnavigation.parentAsset && !subnavigation.parentSelector && !subnavigation.parentLabel">
      <ng-container *ngTemplateOutlet="image"></ng-container>
    </ng-container>
    <ng-container *ngIf="subnavigation.parentSelector && subnavigation.parentLabel && !subnavigation.parentAsset">
      <div class="d-block d-lg-none">
        <ng-container *ngTemplateOutlet="dropdown"></ng-container>
      </div>
      <div class="d-none d-lg-block">
        <ng-container *ngTemplateOutlet="label"></ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="subnavigation.parentSelector && !subnavigation.parentLabel">
      <ng-container *ngTemplateOutlet="dropdown"></ng-container>
    </ng-container>
    <ng-container *ngIf="subnavigation.parentLabel && !subnavigation.parentSelector">
      <ng-container *ngTemplateOutlet="label"></ng-container>
    </ng-container>
    <flo-nav [nav]="subnavigation.nav"></flo-nav>
  </div>
</div>

<ng-template #dropdown>
  <flo-dropdown
    *ngIf="(subnavigation.parentSelector) as select"
    [id]="select.id"
    [title]="select.title"
    [options]="select.options"
    [analytics]="select.analytics"
    [routeWithActionUrls]="true"
    [clearable]="false"
    class="dropdown-small"
    >
  </flo-dropdown>
</ng-template>
<ng-template #label>
  <div *ngIf="(subnavigation.parentLabel) as label" [ngClass]="label.style | floCssClassSanitize" class="subnavigation-label">{{label.text}}</div>
</ng-template>
<ng-template #image>
  <flo-image *ngIf="(subnavigation.parentAsset) as image" class="subnavigation-image object-fit-cover" [image]="image"></flo-image>
</ng-template>
