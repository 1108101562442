<div class="promo-banner-container promo-banner-{{promoBannerData?.vertical}} w-100 p-3">
  <div *ngIf="promoBannerData?.vertical !== 'varsity'" class="d-flex justify-content-between" data-test="flo-hawk-and-sign-in">
    <div class="color-100">
      <flo-svg-icon icon="flo_hawk" width="2rem" height="2rem" class="text-primary"></flo-svg-icon>
    </div>
    <button
      class="btn btn-outline-light"
      floAnalytics
      name="Promo Banner Sign In"
      (click)="login()"
      >Log In</button>
  </div>
  <div class="d-flex flex-column my-5">
    <h2 class="promo-banner-header font-family-bold color-100 text-center">{{promoBannerData?.header}}</h2>
    <p class="promo-banner-description headline color-100 text-center" [innerHTML]="promoBannerData?.description"></p>
    <div class="d-flex justify-content-center mt-25">
      <button
        class="btn btn-primary btn-sm btn-sub"
        floAnalytics
        name="Promo Banner Subscribe Button"
        (click)="join()"
        queryParamsHandling="merge">
        Sign Up</button>
    </div>
  </div>
</div>
