<div class="calendar-wrapper" *ngIf="config$ | async as config">
  <div class="calendar-tiles-wrapper horizontal-scroll">
    <flo-calendar-tile
      *ngFor="let upcomingEvent of config.tiledEvents; trackBy: trackByUpcomingEvent"
      class="scroll-item"
      [geoBlockedIds]="geoBlockedTiledIds$ | async"
      [event]="upcomingEvent"
    ></flo-calendar-tile>
  </div>
  <div class="more-events-list-wrapper" *ngIf="config.listEvents?.length > 0">
    <flo-landing-batman-list-item
      *ngFor="let listEvent of config.listEvents; trackBy: trackByUpcomingEvent"
      [geoBlockedIds]="geoBlockedListIds$ | async"
      [event]="listEvent"
    ></flo-landing-batman-list-item>
  </div>
  <div *ngIf="config.moreEvents" class="chevron-wrapper">
    <flo-chevron
      headerText="More Upcoming Events"
      [analyticsProps]="buttonProps"
      (chevronClicked)="updateLimit()"
    ></flo-chevron>
  </div>
</div>
