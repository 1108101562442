<!-- prettier-ignore -->
<div [ngClass]="headerClasses">
  <h2 *ngIf="promptOverride; else defaultPrompt">
    {{ promptOverride }}
  </h2>
  <ng-template #defaultPrompt>
    <h2>
      Unlock this {{ contentType }}, live events, and more with a subscription!
    </h2>
  </ng-template>
</div>

<a
  *ngIf="joinButtonTextOverride; else defaultJoinButton"
  class="btn-primary btn btn-lg"
  data-test="get-started"
  [routerLink]="joinRoute"
  [queryParams]="{ redirect: redirect }"
>
  {{ joinButtonTextOverride }}
</a>
<ng-template #defaultJoinButton>
  <a
    class="btn-primary btn"
    data-test="paywall-button"
    [routerLink]="joinRoute"
    [queryParams]="{ redirect: redirect }"
  >
    Sign Up
  </a>
</ng-template>

<p *ngIf="!loggedIn" class="m-0">
  <span *ngIf="loginButtonTextOverride; else defaultLoginButtonText">{{
    loginButtonTextOverride
  }}</span>
  <ng-template #defaultLoginButtonText>
    <span *ngIf="!loginButtonTextOverride">Already a subscriber?</span>
  </ng-template>
  <a
    id="paywall-login-button"
    data-test="paywall-login"
    [routerLink]="loginRoute"
    [queryParams]="{ redirect: redirect }"
  >
    Log In</a
  >
</p>
