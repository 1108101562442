import { AuthService } from '../../singleton-services/auth/auth.service'
import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router'
import { Observable, of } from 'rxjs'
import { tap, flatMap } from 'rxjs/operators'
import { CheckoutNavigationService } from '../services/checkout-navigation.service'

@Injectable()
export class ActivateGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private checkoutNavService: CheckoutNavigationService
  ) {}

  canActivate(ars: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.user$.pipe(
      flatMap(user => {
        const queryParams = ars.queryParams
        if (user.loggedIn) {
          return of(true)
        } else {
          return of(false).pipe(
            tap(() =>
              this.router.navigate(['/login'], {
                queryParams: { ...queryParams, redirect: '/activate' }
              })
            )
          )
        }
      })
    )
  }
}
