import { AnalyticsModel } from '@flocasts/experience-service-types'
import { AnalyticsProperties } from '../models/analytics-properties.interface'
import { ElementRef } from '@angular/core'
import { snakeCase } from 'snake-case'

/**
 * Flattens the extraContent nested object property in AnalyticsModel
 * Returns a flat object for the analytics directive to consume
 * type: analytics, is removed from the response
 */
const flattenAnalyticsProperties = (analyticsProperties: AnalyticsModel) => {
  const { extraContext, type, videoAnalytics, ...defaultProperties } = analyticsProperties
  return { ...defaultProperties, ...extraContext }
}

const snakeCaseProperties = (ap: AnalyticsProperties) => {
  const newObject: AnalyticsProperties = {}
  Object.entries(ap).map(([key, value]) => (newObject[snakeCase(key)] = value))
  return newObject
}

const isAnalyticsModel = (analytics: AnalyticsProperties | AnalyticsModel | undefined): analytics is AnalyticsModel => {
  return analytics?.type === 'analytics'
}

export const buildTrackCallProperties = (
  properties: AnalyticsProperties | AnalyticsModel,
  elementName?: string | null
) => {
  const isTypeAnalyticsModel: boolean = isAnalyticsModel(properties)
  if (isTypeAnalyticsModel) {
    return snakeCaseProperties(flattenAnalyticsProperties(properties as AnalyticsModel))
  } else {
    return {
      ...properties,
      name: elementName || properties.name
    }
  }
}

export const getNameAttribute = (elementRef: ElementRef): string | null => {
  const element = elementRef.nativeElement as HTMLElement
  return element.getAttribute('name')
}

export const getTextNode = (elementRef: ElementRef): string => {
  const element = elementRef.nativeElement as HTMLElement
  const textItem = element.attributes.getNamedItem('text')

  if (textItem) {
    return textItem.value
  }

  return (element.textContent || '').trim()
}
