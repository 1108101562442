import { AfterViewInit, Directive, ElementRef, Inject, Input, OnDestroy, Renderer2 } from '@angular/core'
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router'
import { Observable, Observer, Scheduler, Subscription } from 'rxjs'
import { debounceTime, filter, map, scan, shareReplay, startWith, take } from 'rxjs/operators'

import { SegmentService } from '../services/segment.service'
import { SegmentEvents } from '../models/segment-events.model'
import { SegmentTag } from '../models/segment-tag.model'
import { AnalyticsProperties } from '../models/analytics-properties.interface'
import { StringDictionary } from '../../models/generics'
import { RXJS_SCHEDULER } from '../../../app.config'
import { capitalizeFirstChar } from '../../utility-functions/string.utility'
import { AnalyticsModel } from '@flocasts/experience-service-types'
import { buildTrackCallProperties, getNameAttribute, getTextNode } from './analytics.directive.utility'

/**
 * # AnalyticsDirective
 * Automatically send track calls during interactions with elements.
 *
 * @remarks
 * ## Requirements
 * In order to send track calls, the route on which the directive appears must
 * include a Segment Tag in the route data. See ArticlesRoutingModule for an
 * example. Please be sure to use SegmentTagV2 as the type of your tag.
 *
 * @see ArticlesRoutingModule An example of SegmentTagV2 in route data
 * @see SegmentTagV2
 */
@Directive({
  selector: '[floAnalytics]'
})
export class AnalyticsDirective implements AfterViewInit, OnDestroy {
  constructor(
    private elRef: ElementRef,
    private renderer: Renderer2,
    private segment: SegmentService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(RXJS_SCHEDULER) private scheduler: Scheduler
  ) {}

  private get eventsToTrack(): ReadonlyArray<string> {
    if (!this.events) {
      return ['click']
    } else if (!Array.isArray(this.events)) {
      return this.shortcuts[this.events as string] || ([this.events] as any)
    } else {
      return this.events
    }
  }

  @Input()
  events: string | ReadonlyArray<string>
  @Input()
  analyticsProperties: AnalyticsProperties | AnalyticsModel = {}
  @Input()
  tagName: string
  @Input()
  elementName: string
  @Input()
  eventName?: string

  private subscriptions: ReadonlyArray<Subscription> = []

  private eventsDict: StringDictionary = {
    click: 'Clicked',
    focus: 'Clicked',
    mouseenter: 'Entered',
    mouseleave: 'Left',
    scroll: 'Scroll'
  }

  private tagTextNodes: ReadonlyArray<string> = ['A', 'BUTTON']

  private tagDict: StringDictionary = {
    INPUT: 'Input Field',
    BUTTON: 'Button',
    A: 'Link',
    'FLO-SELECT-INPUT': 'Dropdown',
    SELECT: 'Dropdown',
    OPTION: 'Dropdown Option'
  }

  // This could be used later if we find ourselves wanting to track the same sets of events.
  private shortcuts: { [key: string]: ReadonlyArray<string> } = {
    standard: ['click', 'mouseenter', 'mouseleave']
  }

  private segmentRouteData$ = this.router.events.pipe(
    filter(navigationEvent => navigationEvent instanceof NavigationEnd),
    map(() => this.traverseDownToLeafNode(this.activatedRoute)),
    startWith(this.traverseDownToLeafNode()),
    filter<ActivatedRouteSnapshot>((route: ActivatedRouteSnapshot) => !!route && !!route.data && !!route.data.segment),
    map<ActivatedRouteSnapshot, SegmentTag>(route => route.data.segment),
    map(segmentData => {
      const { name, ...noName } = segmentData
      return noName as SegmentTag
    }),
    shareReplay(1)
  )

  ngAfterViewInit() {
    // see FormErrorsComponent - any element with .error-msg class sends "Error Displayed" event
    if (this.elRef.nativeElement.classList.contains('error-msg')) {
      this.trackError(this.elRef.nativeElement.textContent.trim())
    }
    this.eventsToTrack.forEach(eventName => this.listenToEvent(eventName))
  }

  private observeEvent(eventName: string): Observable<Event> {
    return new Observable((observer: Observer<Event>) =>
      this.renderer.listen(this.elRef.nativeElement, eventName, (event: Event) => observer.next(event))
    )
  }

  private listenToEvent(eventName: string): void {
    if (!eventName) {
      return
    } else if (eventName === 'scroll') {
      const subscription = this.observeEvent(eventName)
        .pipe(
          debounceTime(100, this.scheduler),
          scan((lastEvent: Event, currentEvent: Event) => {
            const scrollLeft = currentEvent && currentEvent.target && (currentEvent.target as any).scrollLeft
            this.setScrollDirection(lastEvent, currentEvent)
            /*
             * What we want is to return currentEvent so that we can compare the
             * scroll positions of this event and the next one, but DOM event
             * emitters actually emit the same event object in memory with
             * updated properties, breaking the required immutability and
             * treating lastEvent and currentEvent as the same object. As a
             * workaround, we create and return a new object with the properties
             * we care about. Conventional cloning techniques such as
             * Object.assign fall short here because they only handle an object's
             * own enumerable properties, which does not include the properties
             * we need
             */
            return { type: currentEvent.type, target: { scrollLeft } } as any
          }),
          debounceTime(1000, this.scheduler)
        )
        .subscribe((event: Event) => this.trackEvent(event))
      this.subscriptions = this.subscriptions.concat(subscription)
    } else {
      this.renderer.listen(this.elRef.nativeElement, eventName, (event: Event) => this.trackEvent(event))
    }
  }

  private setScrollDirection(lastEvent: Event, currentEvent: Event): void {
    if (!lastEvent || !currentEvent || !lastEvent.target || !currentEvent.target) {
      const direction = 'Right'
      this.analyticsProperties = {
        ...this.analyticsProperties,
        direction
      }
    } else {
      const firstPosition = (lastEvent.target as any).scrollLeft
      const secondPosition = (currentEvent.target as any).scrollLeft
      if (firstPosition > secondPosition) {
        const direction = 'Left'
        this.analyticsProperties = {
          ...this.analyticsProperties,
          direction
        }
      } else {
        const direction = 'Right'
        this.analyticsProperties = {
          ...this.analyticsProperties,
          direction
        }
      }
    }
  }

  private traverseDownToLeafNode(route = this.activatedRoute) {
    let currentRoute = route
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild
    }
    return currentRoute.snapshot
  }

  private trackEvent(event: Event): void {
    const tag = this.tagName || this.tagDict[this.elRef.nativeElement.tagName] || ''
    const eventName = this.eventName || `${tag} ${this.eventsDict[event.type as string] || ''}`.trim()
    const text = this.tagTextNodes.includes(this.elRef.nativeElement.tagName) ? getTextNode(this.elRef) : undefined
    const analytics = buildTrackCallProperties(
      this.analyticsProperties,
      this.elementName || getNameAttribute(this.elRef)
    )

    this.segmentRouteData$.pipe(take(1)).subscribe(segmentData => {
      this.segment.track(eventName, {
        action: event.type && capitalizeFirstChar(event.type),
        text,
        ...segmentData,
        ...analytics
      })
    })
  }

  private trackError(message: string) {
    this.segment.track(SegmentEvents.ERROR_DISPLAYED, { message })
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe())
  }
}
