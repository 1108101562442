import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { WrestlingResultCardModel } from '@flocasts/experience-service-types'

@Component({
  selector: 'flo-wrestling-result-card',
  templateUrl: './wrestling-result-card.component.html',
  styleUrls: ['./wrestling-result-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WrestlingResultCardComponent {
  @Input() wrestlingResult: WrestlingResultCardModel
}
