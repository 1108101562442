import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { FilterOptionModel, WebSubnavigationViewModel } from '@flocasts/experience-service-types'

@Component({
  selector: 'flo-subnavigation',
  templateUrl: './subnavigation.component.html',
  styleUrls: ['./subnavigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubnavigationComponent {
  @Input() subnavigation: WebSubnavigationViewModel
}
