import { NgModule } from '@angular/core'
import { LazyLoadDirective } from './lazy-load.directive'
import { LazyComponent } from './lazy.component'
import { CommonModule } from '@angular/common'

@NgModule({
  declarations: [LazyLoadDirective, LazyComponent],
  exports: [LazyLoadDirective, LazyComponent],
  imports: [
    CommonModule,
  ],
})
export class LazyLoadModule {}
