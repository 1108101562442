import { Injectable } from '@angular/core'
import { UserPreferenceDataService } from './user-preference-data.service'
import { combineLatest, Observable, of, Subject } from 'rxjs'
import { flatMap, map, shareReplay, switchMap, tap } from 'rxjs/operators'
import { Favorite, Favorites } from './types'
import { VerticalService } from 'src/app/singleton-services/vertical.service'
import { Category, DataPagination, SiteCombinedSelections } from '@flocasts/user-preferences-types'

@Injectable({
  providedIn: 'root'
})
export class FavoriteService {
  constructor(
    private readonly verticalService: VerticalService,
    private readonly userPrefDataService: UserPreferenceDataService
  ) {}

  private _categorySelections: ReadonlyArray<SiteCombinedSelections> | []

  private hasUpdatedFavorites = new Subject<boolean>()
  public hasUpdatedFavorites$ = this.hasUpdatedFavorites.asObservable()

  public getAllFavorites(
    page: number = 1,
    limit: number = 30,
    categorySelectionIndex: number,
    search?: string
  ): Observable<Favorites> {
    return combineLatest([this.verticalService.isFavoriteFeatureVertical$, this.verticalService.siteId$]).pipe(
      flatMap(([isFavoriteFeatureVertical, siteId]) => {
        return siteId && isFavoriteFeatureVertical
          ? combineLatest([
              this.userPrefDataService.getCategorySelections(siteId).pipe(shareReplay(1)),
              this.userPrefDataService.getUserCustomizations(siteId)
            ]).pipe(
              switchMap(([categorySelections, userCustomizations]) => {
                this._categorySelections = categorySelections || []
                return categorySelections && categorySelectionIndex < categorySelections.length
                  ? this.getFavorites(
                      userCustomizations?.map(x => this.mapToFavorite(x.category, x.id, true, true)) || [],
                      page,
                      limit,
                      categorySelectionIndex,
                      search
                    )
                  : of(undefined)
              })
            )
          : of(undefined)
      })
    )
  }

  public updateFavorite(
    id: number | undefined,
    favoriteId: number | undefined,
    selected: boolean
  ): Observable<Favorite | void | undefined> {
    if (!!selected && id) {
      return this.userPrefDataService
        .postUserCustomization(id)
        .pipe(map(res => res && this.mapToFavorite(res?.category, res?.id, true, true)))
    }
    if (!selected && favoriteId) {
      return this.userPrefDataService.deleteUserCustomization(favoriteId)
    }

    return of(undefined)
  }

  public getFavorites(
    myFavorites: Favorite[],
    page: number = 1,
    limit: number = 30,
    categorySelectionIndex: number,
    search?: string
  ): Observable<Favorites | undefined> {
    return this._categorySelections?.length > 0 && this._categorySelections[categorySelectionIndex]
      ? this.getCategoriesAsFavorites(
          this._categorySelections[categorySelectionIndex],
          page,
          limit,
          myFavorites,
          search
        )
      : of(undefined)
  }

  private getCategoriesAsFavorites(
    categorySelection: SiteCombinedSelections,
    page: number,
    limit: number,
    myFavorites: Favorite[] | undefined,
    search?: string
  ): Observable<Favorites | undefined> {
    return this.userPrefDataService
      .getCategoriesByUrl(categorySelection.url, page, limit, search)
      .pipe(map(categories => this.mapToFavorites(categories, myFavorites, categorySelection)))
  }

  private mapToFavorites(
    categories: DataPagination<Category> | undefined,
    myFavorites: Favorite[] | undefined,
    categorySelection: SiteCombinedSelections
  ): Favorites | undefined {
    if (!categories) return undefined
    return {
      label: categorySelection.label,
      steps: this._categorySelections.length,
      options: {
        ...categories,
        data:
          categories?.data.map(cat => {
            const myFavorite = myFavorites?.find(x => x.id === cat.id)
            return this.mapToFavorite(cat, myFavorite?.favoriteId, !!myFavorite)
          }) || []
      },
      myFavorites
    }
  }

  private mapToFavorite(
    category: Category,
    userCustomizationId: number | undefined,
    selected: boolean,
    removable?: boolean
  ): Favorite {
    return {
      id: category.id,
      favoriteId: userCustomizationId,
      primaryLabel: category.primaryLabel,
      secondaryLabel: category.secondaryLabel,
      image: category.image,
      selected,
      removable
    }
  }

  public setHasUpdatedFavorites(value: boolean) {
    this.hasUpdatedFavorites.next(value)
  }
}
