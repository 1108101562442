<div class="event-result-card d-sm-flex w-100">
  <div class="w-100 d-flex flex-column">
    <a
      [floCustomLink]="item.action?.url"
      floAnalytics
      [analyticsProperties]="item.action?.analytics"
      data-test="event-card-item-link"
      class="event-card-item-link flex-grow-1 color-900 primary-color-text-hover"
    >
      <div
        class="
          event-result-text
          w-100
          pe-sm-3
          mx-0
          align-self-stretch align-items-end
          hover-text-primary
        "
      >
        <div class="px-0 my-0">
          <div
            class="event-status footnote color-900"
            data-attribute="event-status"
          >
          <!-- label1Parts is deprecated -->
            <ng-container *ngIf="!!item.label1Parts">
              <flo-event-status
                [props]="item.label1Parts"
                showBadge="true"
              ></flo-event-status>
            </ng-container>

            <ng-container *ngIf="!!item.label1Icon">
              <flo-svg-icon
                class="badge-live svg-vertical-align-middle"
                width="26px"
                [icon]="item.label1Icon.style"
                data-test="event-status-badge"
              >
              </flo-svg-icon>
              &nbsp;
            </ng-container>
            <ng-container *ngIf="item.label1">
              <span class="footnote">{{ item.label1 }}</span>
            </ng-container>
            <ng-container *ngIf="item.favoriteIcon as favoriteIcon">
              <flo-svg-icon
                class="favorite-icon svg-vertical-align-middle ms-2"
                width="16px"
                [icon]="favoriteIcon.style"
                [altText]="favoriteIcon.accessibility?.altText"
              ></flo-svg-icon>
            </ng-container>
          </div>
          <div class="text-line-clamp-2">
            <h4
              class="my-0 h4 bold-font apply-text-hover"
              [textContent]="item.title"
            ></h4>
          </div>
          <div
            class="
              color-900
              flex-row
              justify-content-between
              footnote
              pb-2 pb-sm-1
              text-truncate
            "
          >
            <span [textContent]="item.subtitle1"></span>
          </div>

          <div
            *ngIf="!!item.footer1 && !item.footerLinks"
            class="d-none d-sm-flex flex-row justify-content-between pt-2"
          >
            <span
              class="footnote text-600 text-truncate"
              data-test="event-card-item-location"
              [textContent]="item.footer1"
            ></span>
          </div>
        </div>
      </div>
    </a>
    <div *ngIf="!!item.footerLinks && !item.footer1" class="d-none d-sm-flex pe-3">
      <span class="text-truncate">
        <ng-container *ngFor="let link of item.footerLinks; let isLast = last; trackBy: trackById;">
          <a
            floAnalytics
            class="footer-link footnote text-600"
            [floCustomLink]="link.url"
            eventName="Link Clicked"
            [analyticsProperties]="link.analytics"
            data-test="event-footer-link"
          >{{ link.title }}</a><span *ngIf="!isLast">&nbsp;·&nbsp;</span>
        </ng-container>
      </span>
    </div>
  </div>
  <ng-container *ifLoaded="geoBlockedIds; template: 'two-vertical-buttons'">
    <div
      data-test="ctas-container"
      *ngIf="
        (showCta1 && !geoBlockedIds?.includes(isCta1GeoBlockableId)) ||
        (showCta2 && !geoBlockedIds?.includes(isCta2GeoBlockableId))
      "
      class="
        border-sm-left border-grey-300
        ps-sm-3
        pe-0
        pt-1 pt-sm-0
        d-flex
        flex-row-reverse flex-sm-column
        align-self-stretch
      "
    >
      <a
        *ngIf="showCta1 && !geoBlockedIds?.includes(isCta1GeoBlockableId)"
        [floCustomLink]="item.cta1?.url"
        floAnalytics
        eventName="Button Clicked"
        [analyticsProperties]="item.cta1?.analytics"
        data-test="event-cta-1"
        class="flex-fill flex-sm-grow-0 cta-1"
      >
        <button
          [ngClass]="cta1Class"
          class="btn btn-sm mb-2 w-100"
          [textContent]="item.cta1?.title"
        ></button>
      </a>
      <a
        *ngIf="showCta2 && !geoBlockedIds?.includes(isCta2GeoBlockableId)"
        [floCustomLink]="item.cta2?.url"
        floAnalytics
        eventName="Button Clicked"
        [analyticsProperties]="item.cta2?.analytics"
        data-test="event-cta-2"
        class="flex-fill flex-sm-grow-0 pe-2 pe-sm-0 cta-2"
      >
        <button
          [ngClass]="cta2Class"
          class="btn btn-sm w-100"
          [textContent]="item.cta2?.title"
        ></button>
      </a>
    </div>
  </ng-container>
</div>
