import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map, shareReplay, switchMap } from 'rxjs/operators'
import { HttpClient, HttpParams } from '@angular/common/http'
import { catch301Errors, FloApiResponseCollection } from '../../shared/models/api-response.model'
import { Video } from '../../shared/models/video.model'
import { EventHubGetProvider } from '../../funnel/signup/signup.interface'
import { VerticalService } from '../../singleton-services/vertical.service'
import { SiteIds } from '../../shared/models/site.model'
import { FloEvent } from '@flocasts/flosports30-types/dist/entity'
import { FloResponse } from '@flocasts/flosports30-types/dist/flo-rest-api/response'
import { SearchMeta } from '../../singleton-services/search-data.service.interface'
import { EXPERIENCE_API_LEGACY_CORE_EVENTS_PATH, EXPERIENCE_API_VERSION } from 'src/app/app.config'

@Injectable({
  providedIn: 'root'
})
export class EventsService implements EventHubGetProvider {
  constructor(private http: HttpClient, private readonly verticalService: VerticalService) {}

  /**
   * @deprecated avoid using
   * Same code is in live/event.service
   */
  public get timeZone(): any {
    try {
      return Intl.DateTimeFormat().resolvedOptions().timeZone
    } catch (err) {
      return ''
    }
  }

  public DEFAULT_EVENT_LIMIT = 14

  /**
   * @deprecated
   */
  public liveAndUpcomingEvents$: Observable<FloApiResponseCollection<FloEvent>> = this.verticalService.siteId$
    .pipe(
      switchMap(siteId =>
        this.getEventsNowAndUpcoming(
          undefined,
          this.DEFAULT_EVENT_LIMIT,
          true,
          undefined,
          siteId === SiteIds.TRACK_WRESTLING ? SiteIds.FLOWRESTLING : siteId
        )
      )
    )
    .pipe(shareReplay(1))

  public getEvent(id: number): Observable<FloResponse<FloEvent, {}>> {
    return this.verticalService.siteId$.pipe(
      switchMap(siteId => {
        return this.http
          .get<FloResponse<FloEvent, {}>>(`${EXPERIENCE_API_LEGACY_CORE_EVENTS_PATH}/${id}`, {
            params: { version: EXPERIENCE_API_VERSION, site_id: siteId },
            observe: 'response'
          })
          .pipe(resp => catch301Errors(resp))
      }),
      map(resp => {
        return resp.body ?? ({} as FloResponse<FloEvent, {}>)
      })
    )
  }

  public getEventVideos(
    id: number,
    sort: string,
    limit: number,
    page?: number
  ): Observable<FloResponse<ReadonlyArray<Video>, SearchMeta>> {
    return this.verticalService.siteId$.pipe(
      switchMap(siteId => {
        return this.http
          .get<FloResponse<ReadonlyArray<Video>, SearchMeta>>(
            `${EXPERIENCE_API_LEGACY_CORE_EVENTS_PATH}/${id}/associations/videos`,
            {
              params: new HttpParams()
                .set('sort', sort)
                .set('limit', limit.toString())
                .set('version', EXPERIENCE_API_VERSION)
                .set('site_id', siteId.toString())
            }
          )
          .pipe(catch301Errors)
      })
    )
  }

  /**
   * Gets events that are now and upcoming
   * Only specify siteId when you wish to override the site domain
   * @example
   *  getEventsNowAndUpcoming(1, 10, true);
   * // returns all events for the current site domain (/events/now-and-upcoming)
   * @example
   *  getEventsNowAndUpcoming(1, 10, true, undefined, 2);
   * // returns all events for a different site (/events/now-and-upcoming?site_id=2)
   *
   * @deprecated
   */
  public getEventsNowAndUpcoming(
    page?: number,
    limit?: number,
    liveOnly?: boolean,
    recentlyEnded?: boolean,
    siteId?: number,
    status?: string,
    future?: number
  ): Observable<FloApiResponseCollection<FloEvent>> {
    let params = new HttpParams()
    params = params.set('version', EXPERIENCE_API_VERSION)
    params = page ? params.set('page', page.toString()) : params
    params = limit ? params.set('limit', limit.toString()) : params
    params = liveOnly ? params.set('live_only', '1') : params
    params = recentlyEnded ? params.set('recently_ended', '1') : params
    params = siteId ? params.set('site_id', siteId.toString()) : params
    params = status ? params.set('status', status) : params
    params = future ? params.set('future', future.toString()) : params
    return this.http.get<FloApiResponseCollection<FloEvent>>(
      `${EXPERIENCE_API_LEGACY_CORE_EVENTS_PATH}/now-and-upcoming`,
      { params }
    )
  }
}
