import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { AdVideoPrerollComponent } from './ad-video-preroll/ad-video-preroll.component'
import { VideoAdsService } from './services/video-ads.service'
import { OldSvgModule } from 'src/app/libs/_deprecated/svg/old-svg.module'

@NgModule({
  imports: [CommonModule, OldSvgModule],
  declarations: [AdVideoPrerollComponent],
  providers: [VideoAdsService],
  exports: [AdVideoPrerollComponent]
})
export class AdsModule {}
