import { DefaultUrlSerializer, UrlTree } from '@angular/router'
import { Injectable } from '@angular/core'

@Injectable()
export class CustomUrlSerializer extends DefaultUrlSerializer {
  // In angular router, parentheses are used for matrix parameters
  // (https://github.com/angular/angular/issues/10280#issuecomment-264322869). We could urlencode parentheses in all
  // the links or we can use this url serializer to take parentheses in urls and encode them before they get routed.

  parse(url: string): UrlTree {
    // Encode parentheses
    // Use the default serializer.
    return super.parse(url.replace(/\(/g, '%28').replace(/\)/g, '%29'))
  }

  serialize(tree: UrlTree): string {
    return super.serialize(tree).replace(/%28/g, '(').replace(/%29/g, ')')
  }
}
