<ng-container *ngIf="hideFloCollegeProd$ |async; else defaultView">
  <iframe width="100%" height="100%" style="min-height:100vh; pointer-events: none;" [src]="'https://www.flosports.tv/' | floSanitizeResourceUrl" data-test="iframe-cxp-6651"></iframe>
</ng-container>
<ng-template #defaultView>
  <ng-container *ngIf="{
    shouldShowEventTicker: shouldShowEventTicker$ | async,
    isMinimal: isMinimal$ | async,
    isDarkTheme: isDarkTheme$ | async,
    showFooter: showFooter$ | async,
    showHeader: showHeader$ | async,
    showSkeletons: showSkeletons$ | async,
    skeletonTheme: skeletonTheme$ | async,
    isVarsity: isVarsity$ | async,
    shouldShowPromoBanner: shouldShowPromoBanner$ | async,
    isHomePage: isHomePage$ | async
  } as subscriptions">
    <div
      class="view-mode"
      [class.dark-theme]="subscriptions.isDarkTheme"
      [class.has-event-ticker]="subscriptions.shouldShowEventTicker"
      [class.varsity-theme]="subscriptions.isVarsity"
      [class.show-header]="subscriptions.showHeader"
      [class.home-page]="subscriptions.isHomePage"
      [class.has-sub-promo-banner]="subscriptions.shouldShowPromoBanner"
    >
      <ng-container *ngIf="subscriptions.shouldShowPromoBanner">
        <flo-promo-banner
          class="d-md-none"
          [siteId]="(siteSettings$ | async)?.site_id"
        ></flo-promo-banner>
      </ng-container>
      <div *ngIf="subscriptions.shouldShowEventTicker" class="w-100 border-bottom border-grey-300 bg-grey-200 event-ticker event-ticker-sticky">
        <flo-event-ticker
          swiperId="mobile-new-event-ticker"
          [eventTickerViewModel]="eventTickerVm$ | async"
        ></flo-event-ticker>
      </div>
      <div class="site-navigation-header-container">
        <!-- Event ticker container for desktop where it is sticky and above the nav -->
        <flo-site-navigation *ngIf="subscriptions.showHeader"></flo-site-navigation>
      </div>

      <div
        class="flex-1"
        [ngClass]="{ 'content-wrapper': subscriptions.showFooter }"
      >
        <ng-container *ngIf="subscriptions.showSkeletons; else routerOutlet">
          <flo-skeleton [theme]="subscriptions.skeletonTheme"></flo-skeleton>
        </ng-container>
        <ng-template #routerOutlet>
          <router-outlet
            #route="outlet"
            (activate)="changeCorrelator()"
          ></router-outlet>
        </ng-template>
      </div>
      <flo-footer
        class="flo-footer"
        [links]="fs.footer$ | async"
        *ngIf="subscriptions.showFooter"
      ></flo-footer>
    </div>
    <flo-debug-menu></flo-debug-menu>
  </ng-container>
</ng-template>
