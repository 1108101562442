import { DataPagination, Category } from '@flocasts/user-preferences-types'

export type Favorite = Pick<Category, 'primaryLabel' | 'secondaryLabel' | 'image'> & {
  id: number
  favoriteId?: number
  selected: boolean
  removable?: boolean
  disabled?: boolean
}

export type Favorites =
  | {
      label?: string
      steps: number
      options: DataPagination<Favorite> | undefined
      myFavorites: Favorite[] | undefined
    }
  | undefined

export type FavoriteClickEvent = {
  id: number
  favoriteId?: number
  selected: boolean
}

export const FAVORITES_ITEMS_PER_PAGE = 30

/**
 * User Prefs content types that we display (and allow favoriting) on the webapp.
 * the phone app allows (and shows) others that we do not (ex: favorite site)
 */
export const FAVORITE_COLLECTION_TYPES = ['person', 'team', 'contentcategory']
