<div class="d-flex flex-row justify-content-center h-100"
     [ngStyle]="styles"
>
  <div
    class="h-100 invisible detector"
    #viewContainer
    [preRender]="false"
    [threshold]="threshold"
    (floLazyLoad)="triggerLoad()"
  ></div>
  <!-- Element gets injected here -->
</div>
