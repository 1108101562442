import { ModuleWithProviders, NgModule } from '@angular/core'
import { datadogLogs, Logger, LogsEvent, StatusType } from '@datadog/browser-logs'
import { EnvironmentService } from '../singleton-services/environment.service'
import { LoggingService } from '../singleton-services/logging/logging.service'
import { BrowserLoggerService } from './browser-logger.service'
import { DATADOG_BROWSER_INSTANCE, LOGGER_SERVICE } from './logger.config'

export const INCLUDED_HOSTS = ['milesplit', 'trackwrestling', 'flo', 'varsity']
export const EXCLUDED_ERROR_MESSAGES = [
  'Invalid credentials.',
  'The network connection was lost.',
  'cancelled',
  'FB is not defined',
  'ResizeObserver loop limit exceeded'
]

/**
 * Datadog browser logger middleware that determines whether or not we log an event
 * If true, we log the event in DataDog
 * If false, we do not log the event in DataDog
 * @param log The LogsEvent
 * @returns true|false
 */
export const beforeSend = (log: LogsEvent) => {
  // Exclude certain noisy error messages
  if (log.status === 'error') {
    if (EXCLUDED_ERROR_MESSAGES.includes(log.message)) {
      return false
    }

    if (!!log.http) {
      // Exclude 400 errors, api will monitor those
      // Exclude status code 0 errors, these are aborted requests, loss of connectivity, etc.
      if ((log.http.status_code >= 400 && log.http.status_code < 500) || log.http.status_code === 0) {
        return false
      }
      // Exclude errors not from flosport domains (like ads)
      if (log.http.url) {
        // Passing the url into new URL, we are able to get the base url/origin i.e. (https://flohoops.com)
        // (This is more foolproof than using a regex.)
        const origin = new URL(log.http.url).origin
        // Check to see if origin (https://flohoops.com) contains any of our hosts, like 'flo'
        // If it does the array will be ['flo']
        // If it doesn't (say the origin is https://adserver.org) the array will be []
        // Check the array length to know whether or not to log
        return INCLUDED_HOSTS.filter(host => origin.includes(host)).length > 0
      }
    }
  }

  return true
}

export function browserLoggerFactory(env: EnvironmentService): Logger {
  datadogLogs.init({
    clientToken: 'pubef0cda6c2629295ef46b4cf3d06e703c',
    forwardErrorsToLogs: false,
    sampleRate: 40,
    env: env.config.env,
    service: env.config.appName,
    beforeSend
  })

  datadogLogs.setLoggerGlobalContext({ env: env.config.env })
  datadogLogs.addLoggerGlobalContext('browser', true)
  datadogLogs.logger.setLevel(env.config.browserLogger.level || StatusType.warn)
  datadogLogs.logger.setHandler(['console', 'http'])

  return datadogLogs.logger
}

@NgModule({
  providers: [
    {
      provide: LoggingService,
      useExisting: LOGGER_SERVICE
    },
    {
      provide: DATADOG_BROWSER_INSTANCE,
      useFactory: browserLoggerFactory,
      deps: [EnvironmentService]
    },
    {
      provide: LOGGER_SERVICE,
      useClass: BrowserLoggerService,
      deps: [DATADOG_BROWSER_INSTANCE]
    }
  ]
})
export class BrowserLoggerModule {
  static forRoot(): ModuleWithProviders<BrowserLoggerModule> {
    return {
      ngModule: BrowserLoggerModule
    }
  }
}
