import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { trackById } from '../../shared/utility-functions/track-by-id.utility'
import { ContainerModel, LinkModel } from '@flocasts/experience-service-types'

@Component({
  selector: 'flo-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss', './styles/hockey-standings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContainerComponent {
  @Input()
  container: ContainerModel

  @Input()
  public geoBlockedIds: ReadonlyArray<number>

  /**
   * Option to set the height for the container. ex: '100px' or '100%'
   * Defaults to the size of the content inside the container.
   */
  @Input()
  height?: string

  @Output()
  nextPage = new EventEmitter<LinkModel>()

  public trackById = trackById

  public emitNextPage(link: LinkModel) {
    this.nextPage.emit(link)
  }
}
