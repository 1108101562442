<div *ngIf="videos" data-test="continue-watching" class="continue-watching">
  <!-- Mobile/Tablet -->
  <div class="continue-watching__items d-flex d-lg-none flex-nowrap">
    <div
      *ngFor="let video of videos; trackBy: trackById"
      class="continue-watching__items--link rounded me-35">
      <flo-small-content-card [props]="video | cwItemToSmallContentCardModel"></flo-small-content-card>
    </div>
  </div>
  <!-- Desktop -->
  <div class="continue-watching__items d-none d-lg-block">
    <div *ngFor="let video of videos; let i = index; trackBy: trackById"
         class="continue-watching__items--link no-card primary-color-text-hover"
         [ngClass]="{ 'mt-25': i > 0 }"
    >
    <flo-small-content-card [props]="video | cwItemToSmallContentCardModel"></flo-small-content-card>
    </div>
  </div>
</div>