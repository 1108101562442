import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core'
import { WebPrimaryNavigationViewModel } from '@flocasts/experience-service-types'
import { Subject } from 'rxjs'

import { trackById } from 'src/app/shared/utility-functions/track-by-id.utility'
import { PlatformService } from 'src/app/singleton-services/platform.service'
import { getVisibleLinkCount } from './primary-navigation.functions'

@Component({
  selector: 'flo-primary-navigation',
  templateUrl: './primary-navigation.component.html',
  styleUrls: ['./primary-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrimaryNavigationComponent implements AfterViewInit, OnDestroy {
  @Input() nav: WebPrimaryNavigationViewModel
  @Input() siteId: number

  @Output() showFlyOut = new EventEmitter<boolean>()

  @ViewChild('primaryLinksContainer')
  primaryLinksContainer: ElementRef
  amountOfLinksShown = new Subject<number>()
  amountOfLinksShown$ = this.amountOfLinksShown.asObservable()
  resizeObserver: ResizeObserver

  constructor(private readonly platformService: PlatformService) {}

  ngAfterViewInit(): void {
    if (this.platformService.isBrowser) {
      this.resizeObserver = new ResizeObserver(entries => {
        for (const entry of entries) {
          if (entry.contentBoxSize) {
            const containerWidth = Number(entry.contentBoxSize[0].inlineSize)
            this.amountOfLinksShown.next(getVisibleLinkCount(this.nav.primaryLinksDesktop, containerWidth))
          }
        }
      })
      this.resizeObserver.observe(this.primaryLinksContainer.nativeElement)
    }
  }

  ngOnDestroy(): void {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }
  }

  readonly trackById = trackById

  public handleHamburgerClicked() {
    this.showFlyOut.emit(true)
  }
}
