import { Injectable } from '@angular/core'
import { PlatformService } from './platform.service'
import { IFrameComponent } from 'iframe-resizer'

@Injectable({
  providedIn: 'root'
})
export class IFrameResizeService {
  // Cannot use iFrameResize function because of build issues, but we can use the types.
  private resizedIFrames: IFrameComponent[] = []
  private components: IFrameComponent[]

  constructor(private readonly platformService: PlatformService) {}

  public resizeIframe(container: HTMLElement): void {
    const iframe = container.querySelector('iframe') as HTMLIFrameElement
    if (this.platformService.isBrowser) {
      /**
       * Using the iFrameResize function from the iframe-resizer library does not work correctly
       * with our angular webpack script optimization.
       * Instead we use the iframe-resizer script injected via angular.json scripts array.
       * This prevents our app from freezing up after the iframe resizer is initialized,
       * whether by visiting a page with Game Center, Brackets, etc.
       */
      // @ts-ignore
      this.components = iFrameResize(
        {
          checkOrigin: false,
          heightCalculationMethod: 'documentElementOffset',
          log: false
        },
        iframe
      )
    }

    if (this.components && this.components.length > 0) {
      this.resizedIFrames.push(this.components[0])
    }
  }

  public close() {
    if (this.resizedIFrames && this.resizedIFrames.length > 0) {
      this.resizedIFrames.forEach(iframe => {
        if (iframe.iFrameResizer) {
          iframe.iFrameResizer.close()
        }
      })
    }
  }
}
