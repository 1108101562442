import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core'
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  fromEvent,
  fromEventPattern,
  interval,
  merge,
  Observable,
  of,
  ReplaySubject,
  SchedulerLike,
  Subject,
  Subscription,
  timer
} from 'rxjs'
import {
  catchError,
  delay,
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  mergeMap,
  sample,
  scan,
  shareReplay,
  skip,
  startWith,
  switchMap,
  switchMapTo,
  take,
  takeUntil,
  takeWhile,
  withLatestFrom
} from 'rxjs/operators'
import Hls, { Level } from 'hls.js'

import { IVideo } from '../../../models/video.model'
import { IVolumeControls } from '../../models/volume-controls.model'
import { IUserIdentity, ResourceType } from '../../../../singleton-services/auth/auth.interfaces'
import { AuthService } from '../../../../singleton-services/auth/auth.service'
import { AdBlockService } from '@flosportsinc/ng-ad-block'
import { SegmentService } from '../../../analytics/services/segment.service'
import { BROWSERS, UserAgentService } from '../../../../singleton-services/user-agent.service'
import { VerticalService } from '../../../../singleton-services/vertical.service'
import { IVideoQuality } from '../../models/video-quality.model'
import { IVideoSkip } from '../video-player-controls/video-player-controls.component'
import { VideoPlayerScreenErrorCode } from '../../models/video-player-screen-error-code.model'
import { ViewCountService } from '../../../../singleton-services/view-count.service'
import { VideoService } from '../../../services/video.service'
import { PlatformService } from '../../../../singleton-services/platform.service'
import { youboraCdns } from '../../../analytics/models/youbora-cdns'
import { RXJS_SCHEDULER } from '../../../../app.config'
import { UpdateHeadService } from '../../../../singleton-services/seo/update-head.service'
import { buildVideoSchema, CANONICAL_ID, getCanonicalUrl } from '../../../../singleton-services/seo/seo.utility'
import { HasNodeRelation } from '../../../models/node.model'
import { HasShareableOrExternalLink } from '../../../../singleton-services/seo/structured-data.interface'
import { OptionalCollectionSlug } from '../../../../collections/collection.facade'
import {
  buildRenditionString,
  getBitrateFromHls,
  getFlooredTime,
  getSecondsFromString,
  getStartTime,
  getVideoInput,
  showVideoPreRollAd
} from './video-player.utility'
import { FloEvent } from '@flocasts/flosports30-types/dist/entity'
import * as videoAdFunctions from '../../../functions/video-ads'
import { ContinueWatchingService } from 'src/app/singleton-services/continue-watching.service'
import { IVideoScrubber } from '../../../../live/components/video-player/video-player.interfaces'
import { notNullOrUndefined } from 'src/app/shared/functions/type-guards'
import { RouterService } from 'src/app/singleton-services/router.service'
import { WINDOW } from 'src/app/app.injections'
import { VodMetadata } from '@flocasts/experience-service-types'

export const STRUCTURED_VIDEO_SCHEMA_ID = 'videoStructuredSchema'

interface HasTitle {
  title: string
}

export type VideoInput = HasShareableOrExternalLink &
  HasNodeRelation &
  HasTitle &
  OptionalCollectionSlug &
  Pick<
    IVideo,
    | 'premium'
    | 'id'
    | 'check_geo_restriction'
    | 'slug'
    | 'slug_uri'
    | 'no_audio'
    | 'playlist_no_audio'
    | 'playlist'
    | 'playlist_with_ads'
    | 'asset'
    | 'short_title'
    | 'watermark'
    | 'status'
    | 'author'
    // These were added for compatibility with bff/experience-service type
    | 'type'
    | 'is_preroll_eligible'
  >

@Component({
  selector: 'flo-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoPlayerComponent implements OnInit, OnDestroy {
  constructor(
    private readonly authService: AuthService,
    private readonly platformService: PlatformService,
    private readonly viewCountService: ViewCountService,
    private readonly adBlockService: AdBlockService,
    private readonly segmentService: SegmentService,
    private readonly verticalService: VerticalService,
    private readonly userAgentService: UserAgentService,
    private readonly videoService: VideoService,
    @Inject(WINDOW) private readonly window: Window,
    private readonly zone: NgZone,
    private readonly routerService: RouterService,
    private updateHeadService: UpdateHeadService,
    @Inject(RXJS_SCHEDULER)
    private readonly scheduler: SchedulerLike,
    private readonly continueWatchingService: ContinueWatchingService
  ) {}

  @ViewChild('contentVideoElement')
  set contentVideoElement(contentVideoElement: ElementRef<HTMLVideoElement>) {
    if (contentVideoElement && contentVideoElement.nativeElement) {
      this._contentVideoElement.next(contentVideoElement.nativeElement)
    }
  }

  @ViewChild('videoPlayerContainer')
  set videoPlayerContainer(videoPlayerContainer: ElementRef<HTMLDivElement>) {
    if (videoPlayerContainer && videoPlayerContainer.nativeElement) {
      this._videoPlayerContainer.next(videoPlayerContainer.nativeElement)
    }
  }

  @Input()
  set volumeControls(volumeControls: IVolumeControls) {
    if (volumeControls) this._volumeControls.next(volumeControls)
  }

  @Input()
  set isAutoPlayed(isAutoplay: boolean) {
    this._isAutoplay.next(!!isAutoplay)
  }

  @Input()
  set isPlaylist(isPlaylist: boolean) {
    this._isPlaylist.next(!!isPlaylist)
  }

  @Input()
  set video(video: VideoInput | VodMetadata) {
    this._video.next(getVideoInput(video))
  }

  @Input()
  set isLive(isLive: boolean) {
    this._isLive.next(!!isLive)
  }

  @Input()
  public set currentVideoId(videoId: number) {
    if (!!videoId) this._currentVideoId.next(videoId)
  }
  // ReplaySubject allows connecting streams to receive the previous value even
  // if there are no subscribers. This allows child components to subscribe
  // once they have initialized, and we don't have to deal with lifecycle hooks

  private readonly _isAutoplay = new ReplaySubject<boolean>(1)
  private readonly _isPlaylist = new ReplaySubject<boolean>(1)
  private readonly _isLive = new ReplaySubject<boolean>(1)
  private readonly _contentVideoElement = new ReplaySubject<HTMLVideoElement>(1)
  private readonly _videoPlayerContainer = new ReplaySubject<HTMLDivElement>(1)
  private readonly _video = new ReplaySubject<VideoInput>(1)
  public readonly _error = new Subject<ErrorEvent>()
  public readonly _hlsInstance = new ReplaySubject<Hls>(1)
  public readonly _isPaused = new ReplaySubject<boolean>(1)
  public readonly _selectedQuality = new ReplaySubject<number>(1)
  public readonly _shareItem = new ReplaySubject<any>(1)
  public readonly _videoPosition = new ReplaySubject<number>(1)
  public readonly _videoSkipped = new ReplaySubject<IVideoSkip>(1)
  public readonly _volumeControls = new ReplaySubject<IVolumeControls>(1)
  public readonly _videoElementClick = new ReplaySubject<MouseEvent>(1)
  public readonly _isShareMenuOpen = new ReplaySubject<boolean>(1)
  public readonly _adEvent = new Subject<string>()
  public readonly _analyticsPlugin = new ReplaySubject<any>(1)

  public readonly vpsErrorCode = VideoPlayerScreenErrorCode
  private readonly ngOnDestroy$ = new Subject<void>()
  @Output()
  public readonly contentVideoElement$ = this._contentVideoElement.pipe(
    filter(() => this.platformService.isBrowser),
    distinctUntilChanged((oldElement, newElement) => oldElement.id === newElement.id),
    shareReplay(1)
  )
  private readonly selectedQuality$ = this._selectedQuality
  private readonly videoPosition$ = this._videoPosition
  public readonly videoPlayerContainer$ = this._videoPlayerContainer
  public readonly video$: Observable<VideoInput> = this._video.pipe(
    distinctUntilChanged((oldVideo, newVideo) => oldVideo.id === newVideo.id),
    shareReplay(1)
  )

  private readonly videoAborted$ = this.contentVideoElement$.pipe(
    switchMap(videoElement => fromEvent(videoElement, 'abort')),
    shareReplay(1),
    takeUntil(this.ngOnDestroy$)
  )

  public readonly hlsInstance$ = this._hlsInstance.pipe(
    filter<Hls>(Boolean),
    filter(() => this.platformService.isBrowser)
  )
  public readonly analyticsPlugin$ = this._analyticsPlugin
  public readonly isLive$ = this._isLive.pipe(startWith(false))
  public readonly adEvent$ = this._adEvent

  public readonly isAutoplay$ = this.video$.pipe(
    switchMap(() => this._isAutoplay),
    startWith(false),
    shareReplay(1),
    distinctUntilChanged()
  )

  public readonly isPlaylist$ = this.video$.pipe(
    switchMap(() => this._isPlaylist),
    startWith(false),
    shareReplay(1),
    distinctUntilChanged()
  )

  private readonly adComplete$ = this.adEvent$.pipe(
    filter(adEvent => adEvent === 'adComplete' || adEvent === 'adError')
  )

  private adStart$ = this.adEvent$.pipe(filter(adEvent => adEvent === 'adStarted'))

  public isAdPlaying$ = merge(
    this.adStart$.pipe(mapTo(true)),
    this.adComplete$.pipe(mapTo(false)),
    this.adBlockService.isAnAdBlockerActive().pipe(
      filter(adBlockerIsActive => adBlockerIsActive === true),
      mapTo(false)
    )
  ).pipe(startWith(false))

  private readonly isMobile$ = of(this.userAgentService.userAgent.isMobile)
  public readonly isIE$ = of(this.userAgentService.userAgent.browser === BROWSERS.IE)

  public readonly videoPlayToggle$ = this._videoElementClick.pipe(
    withLatestFrom(this.isMobile$),
    filter(([mouseEvent, isMobile]) => !isMobile),
    map(([mouseEvent, isMobile]) => mouseEvent.srcElement),
    map((videoElement: HTMLVideoElement) => !videoElement.paused)
  )

  public readonly adsEnabled$ = combineLatest([
    this.video$,
    this.adBlockService.isAnAdBlockerActive(),
    merge(
      this.video$.pipe(
        map(video => video.id),
        distinctUntilChanged(),
        map(() => false)
      ),
      this.adComplete$.pipe(map(() => true))
    )
  ]).pipe(
    map(([video, adBlockerIsActive, didPlayAd]) => {
      return showVideoPreRollAd(video.status, adBlockerIsActive, didPlayAd, video.is_preroll_eligible)
    })
  )

  private readonly isPaused$ = merge(this._isPaused, this.videoPlayToggle$).pipe(shareReplay(1))

  @Output()
  readonly played = new Subject<void>()

  private readonly userIdentity$: Observable<IUserIdentity | undefined> = this.authService.userIdentity$.pipe(
    shareReplay(1),
    startWith(undefined),
    takeUntil(this.ngOnDestroy$)
  )

  private readonly segmentAnonymousId$ = of(this.segmentService.anonymousId)

  @Input()
  public isEmbedded = false

  public readonly _currentVideoId = new ReplaySubject<number | undefined>(1)
  public readonly currentVideoId$ = this._currentVideoId.pipe(startWith(undefined))

  private readonly geoblockError$ = combineLatest([this.video$, this.authService.isAdmin$]).pipe(
    filter(() => this.platformService.isBrowser),
    switchMap(combined =>
      !!combined[0].check_geo_restriction && !combined[1]
        ? this.videoService.isVideoGeoblocked$(combined[0])
        : this.video$.pipe(mapTo(false))
    ),
    map(isVideoGeoblocked => (isVideoGeoblocked ? { code: this.vpsErrorCode.GEO_UNAVAILABLE } : null)),
    shareReplay(1)
  )

  public readonly hasAccess$ = this.video$.pipe(
    mergeMap(video => {
      return this.authService.hasAccess({ ...video, premium: video.premium ?? false }, ResourceType.Node)
    }),
    shareReplay(1)
  )

  public readonly segmentAnalytics$ = this.video$.pipe(
    map(video => {
      return {
        title: video.title,
        id: video.id
      }
    })
  )

  private readonly videoEnded$ = this.contentVideoElement$.pipe(
    switchMap(videoElement => fromEvent(videoElement, 'ended')),
    shareReplay(1),
    takeUntil(this.ngOnDestroy$)
  )

  private readonly videoWaiting$ = this.contentVideoElement$.pipe(
    switchMap(videoElement => fromEvent(videoElement, 'waiting')),
    shareReplay(1),
    takeUntil(this.ngOnDestroy$)
  )

  private readonly videoCanplay$ = this.contentVideoElement$.pipe(
    switchMap(videoElement => fromEvent(videoElement, 'canplay')),
    shareReplay(1),
    takeUntil(this.ngOnDestroy$)
  )

  private readonly videoPlaying$ = this.contentVideoElement$.pipe(
    switchMap(videoElement => fromEvent(videoElement, 'playing')),
    shareReplay(1),
    takeUntil(this.ngOnDestroy$)
  )

  private readonly videoPlay$ = this.contentVideoElement$.pipe(
    switchMap(videoElement => fromEvent(videoElement, 'play')),
    shareReplay(1),
    takeUntil(this.ngOnDestroy$)
  )

  private readonly videoPause$ = this.contentVideoElement$.pipe(
    switchMap(videoElement => fromEvent(videoElement, 'pause')),
    shareReplay(1),
    takeUntil(this.ngOnDestroy$)
  )

  public readonly videoVolumeChange$ = this.contentVideoElement$.pipe(
    switchMap(videoElement => fromEvent(videoElement, 'volumechange')),
    map(volumeChange => {
      const videoElement = volumeChange.srcElement as HTMLVideoElement
      const videoVolume = {
        volume: videoElement.volume,
        isMuted: videoElement.muted
      }
      return videoVolume
    }),
    shareReplay(1),
    takeUntil(this.ngOnDestroy$)
  )

  public readonly volumeControls$ = merge(this._volumeControls, this.videoVolumeChange$).pipe(
    scan((oldVolume, newVolume) => ({ ...oldVolume, ...newVolume }), {
      volume: 1,
      isMuted: false
    }),
    startWith({
      volume: 1,
      isMuted: false
    }),
    shareReplay(1)
  )

  public readonly siteName$ = this.verticalService.siteSettings$.pipe(
    map(siteSettings => siteSettings.site_name),
    shareReplay(1)
  )

  public updateRendition = this.contentVideoElement$
    .pipe(
      switchMap(contentVideoElement => fromEvent(contentVideoElement, 'resize').pipe(map(() => contentVideoElement))),
      withLatestFrom(this.hlsInstance$),
      map(([video, hls]) => {
        const bitrate = getBitrateFromHls(hls)
        return buildRenditionString(video.videoWidth, video.videoHeight, bitrate)
      }),
      withLatestFrom(this.analyticsPlugin$)
    )
    .subscribe(([rendition, analyticsPlugin]) => {
      analyticsPlugin.setOptions({ 'content.rendition': rendition })
    })

  public videoError$ = this.video$.pipe(switchMap(() => this._error.pipe(startWith(null))))

  public error$ = merge(this.videoError$, this.geoblockError$).pipe(shareReplay(1))

  public readonly errorTimer$ = this.isPlaylist$.pipe(
    filter(isPlaylist => isPlaylist === true),
    switchMap(() => this.error$.pipe(filter(Boolean))),
    switchMap(error => (error ? this.countDownFrom(10) : EMPTY))
  )

  // Hack to refresh video element in order to refresh hls
  // TODO: modify hls directive to emit a new instance when source url is updated
  public readonly showVideoElement$ = this.zone.runOutsideAngular(() =>
    merge(
      this.video$.pipe(mapTo(false)),
      this.geoblockError$.pipe(
        delay(5, this.scheduler),
        map(error => !error)
      )
    ).pipe(
      withLatestFrom(this.error$, this.hasAccess$),
      map(([refreshedVideo, error, isAuthorized]) => refreshedVideo && !error && isAuthorized),
      shareReplay(1)
    )
  )

  public readonly qualityControlState$ = this.hlsInstance$.pipe(
    switchMap(hls => fromHlsEvent(hls, Hls.Events.MANIFEST_PARSED)),
    map(
      ([eventName, hlsData]) =>
        ({
          levels: hlsData.levels.map((level: Level, index: number) => ({
            label: level.height,
            id: index
          })),
          manualLevel: -1
        } as IVideoQuality)
    )
  )

  public readonly setQuality = this.selectedQuality$
    .pipe(withLatestFrom(this.hlsInstance$), takeUntil(this.ngOnDestroy$))
    .subscribe(([selectedQuality, hls]) => (hls.currentLevel = selectedQuality))

  public readonly setCurrentTime = this._videoSkipped
    .pipe(withLatestFrom(this.contentVideoElement$), takeUntil(this.ngOnDestroy$))
    .subscribe(([videoSkip, videoElement]: readonly [IVideoSkip, HTMLVideoElement]) => {
      videoElement.currentTime = videoElement.currentTime + videoSkip.skipTime
    })

  public readonly videoPlayerControlsConfig$ = of({
    showLiveIndicator: false,
    showSkipBackwardBtn: true,
    showSkipFowardBtn: true,
    showShareBtn: true,
    showSettingsBtn: true,
    showFullscreenBtn: true
  })

  public readonly volume$ = this.volumeControls$.pipe(
    map(volume => volume.volume),
    catchError(() => of(0)),
    shareReplay(1)
  )

  public readonly videoPlayState$ = merge(this.videoPause$, this.videoPlay$).pipe(
    map(event => event.srcElement),
    filter<HTMLVideoElement>(Boolean),
    map(videoElement => videoElement.paused),
    map(isPaused => ({ isPaused })),
    shareReplay(1)
  )

  public readonly setVolume = combineLatest([this.volumeControls$, this.contentVideoElement$]).subscribe(
    ([volumeControls, contentVideoElement]: readonly [IVolumeControls, HTMLVideoElement]) => {
      if (volumeControls.volume) contentVideoElement.volume = volumeControls.volume
      contentVideoElement.muted = !!volumeControls.isMuted
    }
  )

  public readonly videoCurrentTime$: Observable<IVideoScrubber> = this.contentVideoElement$.pipe(
    switchMap(contentVideoElement =>
      merge(
        fromEvent(contentVideoElement, 'loadedmetadata'),
        fromEvent(contentVideoElement, 'timeupdate'),
        fromEvent(contentVideoElement, 'seeking')
      )
    ),
    map(event => {
      const videoElement = event.target as HTMLVideoElement
      const timeObject = {
        currentTime: videoElement.currentTime,
        duration: videoElement.duration
      }
      return timeObject
    }),
    shareReplay(1),
    takeUntil(this.ngOnDestroy$)
  )

  /**
   * A stream (for continue-watching POST requests) that keeps track of:
   * A) when 10 seconds has been reached and user is logged in
   * B) every 5 seconds after that
   *
   * It does this by limiting the "firehose" videoCurrentTime$ stream that emits multiple times every second.
   */
  public readonly continueWatchingHeartbeat$ = combineLatest([this.videoCurrentTime$, this.userIdentity$]).pipe(
    map(([videoTime, userId]) => ({ ...getFlooredTime(videoTime), userId })),
    filter(videoTime => videoTime.currentTime >= 10 && !!videoTime.userId?.id && videoTime.currentTime % 5 === 0),
    distinctUntilChanged((prev, curr) => prev.currentTime === curr.currentTime),
    takeUntil(this.ngOnDestroy$)
  )

  /**
   * send a post request to the continue watching service to save the users video time
   */
  private readonly trackContinueWatching: Subscription = combineLatest([
    this.userIdentity$,
    this.continueWatchingHeartbeat$
  ])
    .pipe(withLatestFrom(this.video$), takeUntil(this.ngOnDestroy$))
    .subscribe(([[userId, cwHeartBeat], video]) => {
      this.continueWatchingService.saveProgress({
        userId: Number(userId?.id),
        nodeId: video.id,
        progressSeconds: cwHeartBeat.currentTime,
        videoTotalSeconds: cwHeartBeat.duration
      })
    })

  public readonly videoBufferAmount$ = this.contentVideoElement$.pipe(
    switchMap(contentVideoElement => fromEvent(contentVideoElement, 'progress')),
    map(event => event.srcElement),
    filter((videoElement: HTMLVideoElement) => !!videoElement && videoElement.readyState > 1),
    map((videoElement: HTMLVideoElement) => {
      const lastBufferedTime = videoElement.buffered.end(videoElement.buffered.length - 1)
      const bufferAmount = (lastBufferedTime / videoElement.duration) * 100
      return bufferAmount
    }),
    takeUntil(this.ngOnDestroy$)
  )

  public readonly setVideoPosition = this.videoPosition$
    .pipe(withLatestFrom(this.contentVideoElement$), takeUntil(this.ngOnDestroy$))
    .subscribe(([position, videoElement]: readonly [number, HTMLVideoElement]) => {
      videoElement.currentTime = position
    })

  private readonly adStarted$ = this.adEvent$.pipe(filter(adEvent => adEvent === 'adStarted'))

  private readonly didVideoStart$ = merge(
    this.video$.pipe(mapTo(false)),
    this.videoPlaying$.pipe(mapTo(true)),
    this.adStarted$.pipe(mapTo(true))
  ).pipe(startWith(false), distinctUntilChanged(), shareReplay(1))

  public showMuteButton$ = combineLatest([
    this.didVideoStart$.pipe(map(didVideoStart => !!didVideoStart)),
    this.volumeControls$.pipe(map(volumeControls => !!volumeControls.isMuted)),
    this.isAdPlaying$,
    this.video$
  ]).pipe(
    map(([didVideoStart, isMuted, isAdPlaying, video]) => (didVideoStart && isMuted) || isAdPlaying || video.no_audio),
    shareReplay(1),
    takeUntil(this.ngOnDestroy$)
  )

  private readonly contentVideoIsWaiting$ = merge(
    this.videoWaiting$.pipe(mapTo(true)),
    this.videoPlaying$.pipe(mapTo(false)),
    this.videoCanplay$.pipe(mapTo(false))
  ).pipe(startWith(false), distinctUntilChanged(), shareReplay(1))

  // filtering out no_audio videos. If video has no_audio dont show toggle unmute button.
  public toggleMute$ = this.video$.pipe(
    filter(video => !video.no_audio),
    switchMap(video =>
      combineLatest([
        this.volumeControls$.pipe(filter(volumeControls => volumeControls.isMuted === true)),
        this.isAdPlaying$.pipe(startWith(false)),
        this.didVideoStart$
      ])
    ),
    map(([isMuted, adPlaying, videoStart]) => videoStart && !adPlaying && isMuted),
    shareReplay(1)
  )

  public readonly showVideo$ = this.hasAccess$.pipe(filter(() => this.platformService.isBrowser))

  public readonly showControls = combineLatest([this.contentVideoElement$, this.isIE$]).subscribe(
    ([contentVideoElement, isIE]) => (contentVideoElement.controls = isIE)
  )

  public readonly playContent$ = this.contentVideoElement$.pipe(
    switchMap(contentVideoElement =>
      merge(
        this.isAutoplay$.pipe(filter(isAutoplay => isAutoplay === true)),
        this.isPaused$.pipe(filter(isPaused => isPaused === false))
      ).pipe(map(() => contentVideoElement))
    ),
    shareReplay(1)
  )

  // prevent hls directive from loading previous video's playlist
  // emit undefined for each video and hold the playlist url until the user is ready to play
  // this prevents pre-loding videos on pages that would use excessive bandwidth for multiple videos
  public readonly videoPlaylist$ = merge(
    this.video$.pipe(
      map(video => (video.no_audio ? video.playlist_no_audio : video.playlist)),
      sample(this.playContent$),
      shareReplay(1)
    ),
    this.video$.pipe(mapTo(undefined))
  )

  public readonly videoAnalyticsData$ = this.contentVideoElement$.pipe(
    switchMap(contentVideoElement => fromEvent(contentVideoElement, 'resize').pipe(map(() => contentVideoElement))),
    withLatestFrom(
      this.userIdentity$,
      this.video$,
      this.hasAccess$,
      this.isLive$,
      this.segmentAnonymousId$,
      this.siteName$,
      this.hlsInstance$
    ),
    map(([contentVideoElement, userIdentity, video, isAuthorized, isLive, segmentAnonymousId, siteName, hls]) => {
      const eventOrSeries = (video.node && video.node.primary_event_or_series_association) || ({} as FloEvent)
      const bitrate = getBitrateFromHls(hls)
      const rendition = buildRenditionString(contentVideoElement.videoWidth, contentVideoElement.videoHeight, bitrate)
      const playlist = video.playlist
      const edge = (/https?:\/\/(.+?)\//.exec(playlist) || [])[1] || playlist || ''
      const cdnNameIndex = youboraCdns
        .map(youboraCdn => youboraCdn.name)
        .map(name => name.toLowerCase().replace(' ', ''))
        .findIndex(name => edge.indexOf(name) > -1)
      const cdn = (youboraCdns[cdnNameIndex] || { name: undefined }).name
      const username = (userIdentity && userIdentity.id) || '0'

      return {
        'content.title': video.title,
        'content.rendition': rendition,
        'extraparam.1': siteName,
        'extraparam.2': eventOrSeries.id,
        'extraparam.3': `${isAuthorized ? '' : '*'}${
          eventOrSeries.short_title || eventOrSeries.title || 'untitled or no association'
        }`,
        'extraparam.6': isAuthorized ? 'Full View' : 'Preview',
        'extraparam.7': video.collectionSlug,
        'extraparam.10': video.id,
        'extraparam.11': video.premium,
        'extraparam.12': segmentAnonymousId,
        'content.isLive': isLive,
        username,
        'content.cdn': cdn,
        'content.resource': playlist
      }
    })
  )

  public readonly showLoadingIcon$ = combineLatest([
    this.hasAccess$,
    this.isAdPlaying$,
    this.contentVideoIsWaiting$
  ]).pipe(
    map(([isAuthorized, isAdPlaying, contentVideoIsWaiting]) => isAuthorized && !isAdPlaying && contentVideoIsWaiting),
    shareReplay(1),
    startWith(true),
    distinctUntilChanged()
  )

  public readonly incrementViewcount = combineLatest([this.playContent$, this.video$])
    .pipe(distinctUntilChanged((oldTuple, newTuple) => oldTuple[1].id === newTuple[1].id))
    .subscribe(([contentVideoElement, video]) => {
      if (video.id) this.viewCountService.incrementViewCount(video.id)
    })

  private readonly isGoogleIMAValid$ = this.video$.pipe(
    videoAdFunctions.mapVideoAdsLibrary(this.window),
    map(googleIMA => googleIMA !== undefined)
  )

  public readonly play = this.playContent$
    .pipe(
      withLatestFrom(this.adsEnabled$, this.video$, this.isGoogleIMAValid$),
      switchMap(([contentVideoElement, adsEnabled, video, isGoogleIMAValid]) =>
        adsEnabled && isGoogleIMAValid
          ? this.adComplete$.pipe(map(() => [contentVideoElement, video]))
          : of([contentVideoElement, video]).pipe(
              // hack to wait for ??? causing subsequent videos to mute when adblock is on
              // I think maybe something about trying to call play() before contentVideoElement is ready?
              // but the promise doesn't give us any error to inspect
              // so we have no way of knowing whether it fails to play because of MEI or because of something else
              // possibly a symptom of refreshing video element for hls
              delay(100, this.scheduler)
            )
      )
    )
    // tslint:disable-next-line: readonly-array
    .subscribe(([contentVideoElement, _]: [HTMLVideoElement, VideoInput]) => {
      contentVideoElement.play().catch(_e => {
        contentVideoElement.muted = true
        contentVideoElement.play()
      })
    })

  public readonly showAd$ = this.adsEnabled$.pipe(
    switchMap(adsEnabled =>
      adsEnabled
        ? merge(
            this.playContent$.pipe(mapTo(true)),
            this.videoAborted$.pipe(mapTo(false)),
            this.adComplete$.pipe(mapTo(false))
          )
        : of(false)
    ),
    withLatestFrom(this.error$),
    map(([showAd, error]) => showAd && !error),
    shareReplay(1)
  )

  public readonly pauseContent = merge(this.isPaused$.pipe(filter(isPaused => isPaused === true)))
    .pipe(withLatestFrom(this.contentVideoElement$))
    .subscribe(([play, contentVideoElement]) => {
      contentVideoElement.pause()
    })

  public readonly showPoster$ = combineLatest([this.didVideoStart$, this.error$]).pipe(
    map(([didVideoStart, error]) => didVideoStart === false && !error),
    shareReplay(1),
    startWith(true),
    distinctUntilChanged()
  )

  public readonly showOverlay$ = this.showPoster$.pipe(startWith(true), distinctUntilChanged(), shareReplay(1))

  private videoPlaybackStarted$ = merge(this.playContent$.pipe(mapTo(true), take(1)), this.isAutoplay$).pipe(
    shareReplay(1)
  )

  public playBtnOverlay$ = combineLatest([this.videoPlaybackStarted$, this.hasAccess$]).pipe(
    map(([show, auth]) => !show && !!auth),
    shareReplay(1)
  )

  // hide controls until video has been played and has started or until the user hovers over video.
  public showControls$ = combineLatest([
    this.videoPlayerContainer$.pipe(
      switchMap(videoPlayer => merge(fromEvent(videoPlayer, 'mousemove'), fromEvent(videoPlayer, 'touchmove'))),
      switchMapTo(timer(4000, this.scheduler).pipe(mapTo(false), startWith(true), takeUntil(this.ngOnDestroy$))),
      startWith(true)
    ),
    this.videoPlayState$.pipe(startWith({ isPaused: true })),
    this.playBtnOverlay$
  ]).pipe(
    map(
      ([mouseEvent, playState, playBtnOverlay]) =>
        (mouseEvent && !playBtnOverlay) || (playState.isPaused && !playBtnOverlay)
    )
  )

  public muteInitialAd = this.adEvent$
    .pipe(
      filter(adEvent => adEvent === 'adRequest'),
      takeUntil(this.didVideoStart$.pipe(filter(didVideoStart => didVideoStart === true)))
    )
    .subscribe(() => this._volumeControls.next({ isMuted: true }))

  private readonly errorTimeout$ = this.errorTimer$.pipe(filter(tick => tick === 0))

  public readonly emitPlayed = merge(this.videoEnded$, this.errorTimeout$).subscribe((event: Event) =>
    this.played.next()
  )

  private countDownFrom = (seconds: number) =>
    interval(1000, this.scheduler).pipe(
      map(tick => seconds - tick),
      takeWhile((countdown: number) => countdown >= 0),
      // tslint:disable-next-line:rxjs-no-unsafe-takeuntil
      takeUntil(
        this.video$.pipe(
          distinctUntilChanged((a, b) => a.id === b.id),
          skip(1)
        )
      ),
      startWith(seconds),
      shareReplay(1),
      takeUntil(this.ngOnDestroy$)
    )

  public ngOnDestroy(): void {
    this.ngOnDestroy$.next()
    this._error.complete()
    this._hlsInstance.complete()
    this._isAutoplay.complete()
    this.ngOnDestroy$.complete()
    this._isPaused.complete()
    this._contentVideoElement.complete()
    this._video.complete()
    this._volumeControls.complete()
    this.played.complete()
    this.updateHeadService.removeElement(CANONICAL_ID)
    this.updateHeadService.removeElement(STRUCTURED_VIDEO_SCHEMA_ID)
  }

  public ngOnInit(): void {
    combineLatest([
      this.video$.pipe(filter<VideoInput>(Boolean), takeUntil(this.ngOnDestroy$)),
      this.currentVideoId$.pipe(takeUntil(this.ngOnDestroy$))
    ]).subscribe(([video, currentVideoId]) => {
      if (!this.isEmbedded || (currentVideoId !== undefined && currentVideoId === video.id)) {
        const videoSchema = buildVideoSchema(video as IVideo, STRUCTURED_VIDEO_SCHEMA_ID)
        this.updateHeadService.addElementToHead(videoSchema)
      }
      if (!this.isEmbedded) {
        const canonicalUrlElement = getCanonicalUrl(video)
        if (canonicalUrlElement !== undefined) {
          this.updateHeadService.addElementToHead(canonicalUrlElement)
        }
      }
    })
  }

  /**
   * Check if our video is the video actively being used by the user.
   * Useful for components like articles when multiple videos are on page at the same time
   */
  private inScope = false
  private inFocus = false
  public inFocus$ = new BehaviorSubject(false)

  @HostListener('click', ['$event'])
  handleVideoScopeFocus(event: Event) {
    // set scope to prevent page scroll
    this.inScope = true
    this.inFocus = true
    this.inFocus$.next(this.inFocus)
  }

  @HostListener('document:click', ['$event'])
  handleVideoScopeBlur(event: Event) {
    if (!this.inScope) {
      this.inFocus = false
      this.inFocus$.next(this.inFocus)
    }
    this.inScope = false
  }

  // Extract the 'startTime' query parameter from the current route
  public startTime$: Observable<string> = this.routerService.queryParamMap$.pipe(
    map(params => params.get('startTime')),
    filter(notNullOrUndefined),
    takeUntil(this.ngOnDestroy$)
  )

  /* Using the 'startTime' query parameter value
   *  If it is a valid number (positive number that is less than the video's duration)
   *  then set the video's current time to that value
   *  Otherwise, set current time to zero
   *
   */
  public readonly setCurrentTimeFromQueryParam: Subscription = combineLatest([
    this.contentVideoElement$,
    this.startTime$,
    this.videoCurrentTime$
  ])
    .pipe(take(1))
    .subscribe(([video, startTime, videoTime]) => {
      if (!!startTime) {
        video.currentTime = getStartTime(getSecondsFromString(startTime), videoTime.duration)
        this.routerService.removeQueryParam('startTime')
      }
    })
}

export const fromHlsEvent = (hls: Hls, eventName: string | any) =>
  fromEventPattern(
    (callback: any) => hls.on(eventName, callback),
    (callback: any) => hls.off(eventName, callback)
  )
