import { Injectable } from '@angular/core'
import { VerticalService } from '../../singleton-services/vertical.service'
import { from, Observable, of } from 'rxjs'
import { concatMap, flatMap } from 'rxjs/operators'

declare const FB: any

export class FacebookPermissionsInterface {
  permission: string
  status: string
}

export class FacebookUserFieldsInterface {
  email: string
  id: string
}

export class FacebookTokenInterface {
  accessToken: string
  userID: number
}

@Injectable()
export class FacebookAccessTokenService {
  constructor(private verticalService: VerticalService) {}

  public getAccessToken(): Observable<FacebookTokenInterface | undefined> {
    return this.verticalService.siteSettings$.pipe(
      flatMap(settings => {
        FB.init({
          appId: settings.facebook_app_id,
          cookie: false,
          xfbml: true,
          version: 'v2.10'
        })

        return from(
          new Promise<{ authResponse: FacebookTokenInterface }>(resolve => FB.login(resolve, { scope: 'email' }))
        ).pipe(
          concatMap(({ authResponse }) => {
            if (!authResponse) return of(undefined)
            return of(authResponse)
          })
        )
      })
    )
  }

  public fetchPermissions(): Observable<ReadonlyArray<FacebookPermissionsInterface>> {
    return Observable.create((observer: any) => {
      FB.api('/me/permissions', (response: { data: ReadonlyArray<FacebookPermissionsInterface> }) => {
        observer.next(response.data)
        observer.complete()
        return response
      })
    })
  }

  public verifyEmailPermissions(permissions: ReadonlyArray<FacebookPermissionsInterface>) {
    let hasEmailPermission = false
    permissions.forEach((permission: FacebookPermissionsInterface) => {
      if (permission.permission === 'email' && permission.status === 'granted') {
        hasEmailPermission = true
        // stop the loop
        return
      }
    })
    return hasEmailPermission
  }

  public rerequest(): Observable<FacebookTokenInterface | undefined> {
    // The `auth_type: reauthenticate` config allows the user to select permissions again in case they chose to deny us
    // access to their email on their first time through.
    return from(
      new Promise<{ authResponse: FacebookTokenInterface }>(resolve =>
        FB.login(resolve, { scope: 'email', auth_type: 'rerequest' })
      )
    ).pipe(
      concatMap(({ authResponse }) => {
        if (!authResponse) return of(undefined)
        return of(authResponse)
      })
    )
  }

  public fetchUserFields(fbToken: FacebookTokenInterface | undefined): Observable<FacebookUserFieldsInterface> {
    return Observable.create((observer: any) => {
      if (!fbToken) return
      // facebook expects a comma separated string ex: 'email, name, age'
      const userFields = 'email'

      FB.api(`/${fbToken.userID}`, { fields: userFields }, (response: FacebookUserFieldsInterface) => {
        observer.next(response)
        observer.complete()
      })
    })
  }
}
