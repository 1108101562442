import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ImageWithButtonsComponent } from './image-with-buttons.component'
import { ImageModule } from '../image/image.component.module'
import { LinkModule } from '../link/link.module'
import { IfLoadedModule } from 'src/app/shared/directives/if-loaded/if-loaded.module'
import { FloCssClassSanitizePipeModule } from '../_pipes/css-class-sanitize/css-class-sanitize.pipe.module'

@NgModule({
  imports: [CommonModule, ImageModule, LinkModule, IfLoadedModule, FloCssClassSanitizePipeModule],
  declarations: [ImageWithButtonsComponent],
  exports: [ImageWithButtonsComponent]
})
export class ImageWithButtonsModule {}
