import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Type,
  ComponentFactoryResolver,
  ViewChild,
  ViewContainerRef,
  ComponentRef,
  DoCheck,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core'
// tslint:disable-next-line:no-unused-variable
import { LazyLoadDirective } from './lazy-load.directive'
import { combineLatest, Subject, Subscription } from 'rxjs'
import { mergeMap, take, tap } from 'rxjs/operators'

/**
 * Lazily load a component dynamically when it is in view.
 *
 * You MUST avoid layout shifts by creating a skeleton for the sake of the user
 * experience. This means using elements around flo-lazy which
 * will prevent shifting. This is critical above the fold, as layout shifts
 * there will harm SEO performance as well as the user experience.
 *
 * By wrapping the LazyLoadDirective, we can dynamically load components above
 * or below the fold after the rest of the DOM content has loaded. This provides
 * a substantial performance improvement over loading these elements during the
 * initial content loading, even for elements above the fold.
 *
 * @see LazyLoadDirective
 */
@Component({
  selector: 'flo-lazy',
  templateUrl: './lazy.component.html',
  styleUrls: ['./lazy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LazyComponent<A extends object, I extends object = Partial<A>> implements DoCheck, OnDestroy {
  @ViewChild('viewContainer', { read: ViewContainerRef, static: true }) private readonly viewContainer: ViewContainerRef

  /**
   * Emits the reference to the dynamically loaded component after loading it.
   */
  @Output() public readonly componentLoaded = new EventEmitter<ComponentRef<A>>()

  /**
   * Set the lazy loading threshold. Valid values 0.0-1.0 inclusive.
   *
   * @example
   * <!-- Will load the component when it is 25 percent in view -->
   * <flo-lazy [threshold]="0.25" ...></flo-lazy>
   *
   * @see LazyLoadDirective.threshold
   */
  @Input() public threshold = 0.0

  /**
   * Set the height of the detector.
   *
   * It will often be best to set this as the minimum height of the component
   * to be loaded. This makes the threshold function more predictably.
   *
   * @param h The height in pixels.
   */
  @Input() public set detectorHeight(h: number) {
    this.styles['min-height'] = `${h}px`
  }

  /**
   * Set the width of the detector.
   *
   * In case you need to trigger a lazy load on horizontal scrolling with a
   * non-zero threshold, you can set the width of the detector. By default, it
   * will be one pixel.
   *
   * @param w The width in pixels.
   */
  @Input() public set detectorWidth(w: number) {
    this.styles['min-width'] = `${w}px`
  }

  /**
   * Used to control the minHeight and minWidth of the skeleton and detector.
   */
  public styles: Record<string, string> = {
    'min-width': '1px',
    'min-height': '1px',
  }

  /**
   * Pass the constructor for the component you wish to load.
   *
   * You may either pass a promise of the constructor using ES6 dynamic module
   * loading, or you can pass the constructor synchronously using normal
   * imports. See the Angular documentation for more information about Dynamic
   * Component Loading {@link https://angular.io/guide/dynamic-component-loader}
   *
   * @example
   * <flo-lazy
   * [componentConstructor]="AdRectangleComponent"
   * ></flo-lazy>
   */
  @Input() public set componentConstructor(c: Promise<Type<A>> | Type<A>) {
    if (c) {
      this.componentConstructorSubject.next(c)
      this.componentConstructorSubject.complete()
    }
  }
  private readonly componentConstructorSubject = new Subject<Promise<Type<A>> | Type<A>>()

  /**
   * Pass any props you want to set when the component initializes.
   *
   * A value must always be provided in order to load the component, even if it
   * is an empty object.
   *
   * Keep in mind that the type of this input is marked as a Partial. Component
   * inputs should always be considered possibly undefined, and in this case
   * there is no safe, generic way to check for required inputs without limiting
   * the use of outputs and other public properties. You may use a specialized
   * interface for setting the inputs if you create one.
   *
   * This setter will not update the component. If you need to update props, do
   * so through the ref emitted by `componentLoaded`.
   */
  @Input() public set initialProps(i: I) {
    if (i) {
      this.initialPropsSubject.next(i)
      this.initialPropsSubject.complete()
    }
  }
  private readonly initialPropsSubject = new Subject<I>()

  private refSubject = new Subject<ComponentRef<A>>()

  private loadSubject = new Subject<void>()

  // tslint:disable-next-line
  private readonly loadComponent: Subscription = combineLatest([
    this.componentConstructorSubject,
    this.initialPropsSubject,
    this.loadSubject,
  ]).pipe(
    tap(_ => this.viewContainer.clear()),
    mergeMap(async ([cons, props]) => {
      const constructor = await cons
      const ref = this.viewContainer.createComponent(this.cfr.resolveComponentFactory(constructor))

      // Set all of the initial props on the component
      Object.entries(props).forEach(([key, value]) => {
        ;(ref.instance as Record<string, unknown>)[key] = value
      })

      ref.changeDetectorRef.detectChanges()
      this.refSubject.next(ref)
    }),
    take(1)
  ).subscribe()

  private readonly doCheckSubject = new Subject<void>()
  // tslint:disable-next-line
  private readonly doCheck: Subscription = combineLatest([
    this.refSubject,
    this.doCheckSubject,
  ]).pipe(
    take(1)
  ).subscribe(([ref]) => {
    this.componentLoaded.next(ref)
    this.componentLoaded.complete()
    this.doCheckSubject.complete()
    this.refSubject.complete()
  })

  constructor(private readonly cfr: ComponentFactoryResolver) {}

  /**
   * Trigger the creation of the component in the view container.
   *
   * When triggered, the view container will be cleared, and the constructor
   * passed through the input will be constructed. The created instance will
   * have its initial properties set on creation. This method will only load
   * a component once.
   */
  public triggerLoad(): void {
    this.loadSubject.next()
    this.loadSubject.complete()
  }

  public ngDoCheck() {
    this.doCheckSubject.next()
  }

  public ngOnDestroy() {
    this.viewContainer.clear()
    this.doCheckSubject.complete()
  }
}
