import { NgModule } from '@angular/core'
import { VideoPlayIconComponent } from './video-play-icon.component'
import { CommonModule } from '@angular/common'
import { SvgModule } from '../../shared/svg/svg.module'
import { FloPipeModule } from '../../shared/pipes/flo-pipe.module'

@NgModule({
  declarations: [VideoPlayIconComponent],
  imports: [CommonModule, SvgModule, FloPipeModule],
  exports: [VideoPlayIconComponent]
})
export class VideoPlayIconModule {}
