import { isValid, parseISO } from 'date-fns'
import { getFormattedDate } from './date.utility'

// Capitalize the first letter of a string.
export const capitalizeFirstChar = (str: string) => {
  return `${str.charAt(0).toUpperCase() + str.slice(1)}`
}

/**
 * Get a subtitle with relative date and optional "Pro" text.
 * @example "2h ago, Pro"
 */
export const getSubtitleWithRelativeDate = (
  date: string | null,
  contentIsPremium: boolean,
  userIsPremium?: boolean
): string => {
  const dateParsed = parseISO(date ?? '')
  const dateText = isValid(dateParsed) ? getFormattedDate(dateParsed) : ''
  const premiumText = contentIsPremium && !userIsPremium ? (isValid(dateParsed) ? ', Pro' : 'Pro') : ''

  return `${dateText}${premiumText}`
}

/**
 * Check if string is a negative number
 * @param s string
 */
export function isStringNegativeNumber(s: string): boolean {
  const isNegative = Math.sign(Number(s))
  return isNegative === -1
}
