import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { TitledListComponent } from './titled-list.component'
import { DecoratedTextCellModule } from '../decorated-text-cell/decorated-text-cell.module'
import { LinkModule } from '../link/link.module'

@NgModule({
  imports: [CommonModule, DecoratedTextCellModule, LinkModule],
  declarations: [TitledListComponent],
  exports: [TitledListComponent]
})
export class TitledListModule {}
