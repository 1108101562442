import { Component } from '@angular/core'

@Component({
  selector: 'flo-vertical-buttons-skeleton',
  template: `
    <div class="w-100 ps-sm-3 pe-0 pt-1 pt-sm-0 d-flex flex-row-reverse flex-sm-column align-self-stretch">
      <h1 *ngFor="let number of [0, 1]" class="me-2 h1-skeleton" style="min-width:150px;height:36px"></h1>
    </div>
  `
})
export class VerticalButtonsSkeletonComponent {}
