export interface StepperModel {
  id: number
  name: string
  isCompleted: boolean
  isCurrent: boolean
  path: string
}

export const giftStepper: StepperModel[] = [
  {
    id: 1,
    name: 'Pick a Plan',
    isCompleted: true,
    isCurrent: false,
    path: '/gift/offers'
  },
  {
    id: 2,
    name: 'Info',
    isCompleted: true,
    isCurrent: false,
    path: '/gift/info'
  },
  {
    id: 3,
    name: 'Pay',
    isCompleted: true,
    isCurrent: false,
    path: '/gift/pay'
  }
]

export const funnelStepper: StepperModel[] = [
  {
    id: 1,
    name: 'Create Account',
    isCompleted: true,
    isCurrent: false,
    path: '/create-account'
  },
  {
    id: 2,
    name: 'Choose Plan',
    isCompleted: true,
    isCurrent: false,
    path: '/plans'
  },
  {
    id: 3,
    name: 'Payment',
    isCompleted: true,
    isCurrent: false,
    path: '/pay'
  }
]
