import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core'
import { isPlatformBrowser } from '@angular/common'
import { LOGGER_SERVICE } from '../../../logger/logger.config'
import { LoggerService } from '../../../logger/logger.interface'
import { AdConfig, AdDirectory, AdSizes, SlotID } from './ad-config'
import { WINDOW } from '../../../app.injections'
import { Lazy } from 'fp-ts/function'
import { Observable, Subject } from 'rxjs'
import { UserIdentifierService } from './user-identifier.service'
import { filter, take } from 'rxjs/operators'
import { notNullOrUndefined } from '../../functions/type-guards'

/**
 * The GPT Service wraps the google publisher tags library.
 *
 * In order to simplify usage of GPT, we observerize all of its public methods
 * which we use. This ensures that we can avoid most of the array-based
 * asynchrony that allows us to safely use GPT despite loading the library
 * asynchronously. Abstracting away the direct usage of GPT makes everything more
 * clear for consumers and prevents any of the many bugs that occur when deviating
 * even slightly from its documented usage.
 */
@Injectable({
  providedIn: 'root'
})
export class GptService {
  /** The actual gpt.js library. */
  private readonly gpt: GPT

  private readonly slotVisibilityChanged = new Subject<SlotVisibilityChangedEvent>()
  public readonly slotVisibilityChanged$ = this.slotVisibilityChanged.asObservable()
  private readonly slotRenderEnded = new Subject<SlotRenderEndedEvent>()
  public readonly slotRenderEnded$ = this.slotRenderEnded.asObservable()

  /** Should be set true to indicate that the correlator should be updated */
  private pageViewChanged = false

  // prettier-ignore
  public constructor(
    @Inject(WINDOW) window: unknown,
    // tslint:disable-next-line:ban-types
    @Inject(PLATFORM_ID) platformId: Object,
    @Inject(LOGGER_SERVICE) private readonly log: LoggerService,
    private readonly ngZone: NgZone,
    private readonly userIdentifier: UserIdentifierService,
  ) {

    // We stub out googletag in non-browser environments, just in case.
    if (!isPlatformBrowser(platformId)) this.gpt = { cmd: [] } as unknown as GPT
    this.gpt = (window as any).googletag

    this.pushCommand(() => {
      this.userIdentifier.hashEmail$.pipe(filter(notNullOrUndefined), take(1)).subscribe(hashedEmail => {
        // set SHA-256 hash of user's email as the ID
        this.gpt.pubads().setPublisherProvidedId(hashedEmail)
      })
      this.gpt.pubads().addEventListener('slotVisibilityChanged', event => this.slotVisibilityChanged.next(event))
      this.gpt.pubads().addEventListener('slotRenderEnded', event => this.slotRenderEnded.next(event))
    })
  }

  /**
   * On the next slot request, update the correlator to obtain a new page view.
   *
   * This essentially tells GPT that the browser is on a "new page" this is used
   * to ensure ads on a page are roadblocked, but allow a new set of ads to load
   * once the page view changes.
   */
  public changePageView(): void {
    this.log.trace('GPT: Page View Updated')
    this.pageViewChanged = true
  }

  /**
   * Constructs an ad slot with a given ad unit path and size and associates it
   * with the ID of a div element on the page that will contain the ad.
   * @see {@link https://developers.google.com/doubleclick-gpt/reference#googletag.defineSlot GPT Docs - googletag.defineSlot}
   * @see AdConfig
   */
  public defineSlot(config: AdConfig): Observable<Slot> {
    return new Observable<Slot>(observer => {
      this.pushCommand(() => {
        this.log.trace('GPT: Defining Ad Slot', { config })
        const slot = this.gpt.defineSlot(config.adDirectory, config.sizes, config.adSlotId)
        if (slot !== null) {
          slot.addService(this.gpt.pubads())
          observer.next(slot)
        } else {
          this.log.fatal('GPT: Got null for slot', { config })
        }
        observer.complete()
      })
    })
  }

  /**
   * @see {@link https://developers.google.com/doubleclick-gpt/reference#googletag.destroySlots GPT Docs - googletag.destroySlots}
   * @see Slot
   */
  public destroySlots(slots?: Slot[]): Observable<boolean> {
    return new Observable(subscriber => {
      this.pushCommand(() => {
        const result = this.gpt.destroySlots(slots)
        this.log.trace('GPT: Slot Destroy Attempt', {
          slots: slots ? slots.map(s => s.getSlotElementId()) : 'all'
        })
        subscriber.next(result)
        subscriber.complete()
      })
    })
  }

  /**
   * @see {@link https://developers.google.com/doubleclick-gpt/reference#googletag.display GPT Docs - googletag.display}
   * @see SlotID
   */
  public display(adSlotId: SlotID): Observable<void> {
    return new Observable(subscriber => {
      this.pushCommand(() => {
        const none = this.gpt.display(adSlotId)
        this.log.trace('GPT: Display Ad Called', { adSlotId })
        subscriber.next(none)
        subscriber.complete()
      })
    })
  }

  // tslint:disable:max-line-length
  /**
   * @see {@link https://developers.google.com/doubleclick-gpt/reference#googletag.PubAdsService_refresh GPT Docs - googletag.PubAdsService.refresh}
   * @see PubAdsService.refresh
   */
  // tslint:enable:max-line-length
  public refreshPubAds(slots?: Slot[] | null): Observable<void> {
    return new Observable(subscriber => {
      this.pushCommand(() => {
        this.log.trace('GPT: Refresh PubAds Slots', { slots: slots ?? [] })
        const none = this.gpt.pubads().refresh(slots, { changeCorrelator: this.pageViewChanged })
        this.pageViewChanged = false
        subscriber.next(none)
        subscriber.complete()
      })
    })
  }

  /**
   * Push a new command to the gpt lib outside of the angular zone.
   *
   * Because GPT is going to manipulate the DOM for us, we don't have to trigger
   * change detection when we make GPT calls. This is good for performance, since
   * this could trigger very large change detection events. This method ensures
   * that we push to the async-safe `cmd` array.
   *
   * @see GPT.cmd
   */
  private pushCommand(lazy: Lazy<void>): number {
    return this.ngZone.runOutsideAngular(() => this.gpt.cmd.push(lazy))
  }
}

/**
 * **WARNING: Only call methods from inside a pushed command!**
 *
 * This is the Google Publisher Tag library (gpt.js). It is loaded asynchronously
 * from index.html. This means that all command calls must guarantee that GPT is
 * actually loaded. It provides the `cmd` array to allow this.
 *
 * These types are purely to help with developing using GPT. They should not be
 * considered documentation. Visit the GPT reference page to confirm that you are
 * correctly calling methods and using best practices.
 *
 * @see GPT.cmd
 * @see {@link https://developers.google.com/doubleclick-gpt/reference Google Publisher Tag Reference}
 */
interface GPT {
  /**
   * This is an array of commands to execute.
   *
   * By pushing a command to this array, once the gpt.js library finishes loading,
   * it will begin processing the added commands. The cmd array is always defined
   * by a script in index.html. This means that the only safe way to call gpt methods
   * is by using them in a command pushed to this array.
   *
   * @example
   * // Do this
   * this.gpt.cmd.push(() => {
   *   this.gpt.display('foo-ad')
   * })
   *
   * // Do **NOT** do this
   * this.gpt.display('foo-ad')
   */
  cmd: (() => void)[]
  defineSlot(adDirectory: AdDirectory, sizes: AdSizes, adElementId: SlotID): Slot
  display(adElementId: SlotID): void
  pubads(): PubAdsService
  enableServices(): void
  destroySlots(slots?: Slot[]): boolean
}

export interface GPTEvent {
  serviceName: string
  slot: Slot
}

export interface SlotResponseReceivedEvent extends GPTEvent {}

/** @see {@link https://developers.google.com/publisher-tag/reference#googletag.events.slotrenderendedevent} */
export interface SlotRenderEndedEvent extends GPTEvent {
  /** @see {@link https://developers.google.com/publisher-tag/reference#googletag.events.SlotRenderEndedEvent_advertiserId} */
  advertiserId: number | null
  campaignId: number
  creativeId: number | null
  isEmpty: boolean
  lineItemId: number
  size: ReadonlyArray<number>
  slot: Slot
}

/** @see {@link https://developers.google.com/publisher-tag/reference#googletag.events.slotvisibilitychangedevent} */
export interface SlotVisibilityChangedEvent extends GPTEvent {
  /** @see {@link https://developers.google.com/publisher-tag/reference#googletag.events.SlotVisibilityChangedEvent_inViewPercentage} */
  inViewPercentage: number
}

export interface Events {
  slotResponseReceived: SlotResponseReceivedEvent
  slotVisibilityChanged: SlotVisibilityChangedEvent
  slotRenderEnded: SlotRenderEndedEvent
}

interface PubAdsService {
  addEventListener<A extends keyof Events>(evtName: A, handler: (evt: Events[A]) => void): this
  disableInitialLoad(): void
  getSlots(): Slot[]
  refresh(slots?: Slot[] | null, conf?: { changeCorrelator: boolean }): void
  setPublisherProvidedId(ppid?: string): void
}

/**
 * This is what is returned from the method slot.getSizes()
 */
export interface SlotSizes {
  width: number
  height: number
}

export interface Slot {
  addService(service: PubAdsService): Slot
  clearTargeting(): void
  getSlotElementId(): SlotID
  setTargeting(k: string, v: string | string[]): void
  updateTargetingFromMap(map: Record<string, string | readonly string[]>): void
  getEscapedQemQueryId(): string
  getAdUnitPath(): string
  getSizes(): ReadonlyArray<SlotSizes>
}
