import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { IsoStringToDatePipe } from './iso-string-to-date.pipe'

@NgModule({
  declarations: [IsoStringToDatePipe],
  exports: [IsoStringToDatePipe],
  imports: [CommonModule]
})
export class IsoStringToDatePipeModule {}
