import { NgModule } from '@angular/core'
import { ModalComponent } from './base-modal/base-modal.component'
import { StandardModalComponent } from './standard-modal/standard-modal.component'
import { CommonModule } from '@angular/common'
import { OldSvgModule } from 'src/app/libs/_deprecated/svg/old-svg.module'

@NgModule({
  imports: [CommonModule, OldSvgModule],
  declarations: [ModalComponent, StandardModalComponent],
  exports: [ModalComponent, StandardModalComponent]
})
export class FloModalModule {}
