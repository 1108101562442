<!-- prettier-ignore -->
<div *ngIf="props$ | async" data-test="event-status" class="event-status-container">
  <ng-container *ngIf="showLiveBadge$ | async">
    <flo-svg-icon data-test="badge-live" icon="badge-live" width="26px" class="badge-live svg-vertical-align-middle"></flo-svg-icon>&nbsp;
  </ng-container>
  <ng-container *ngIf="displayText$ | async as displayText">
    <span [ngClass]="displayTextClass$ | async" class="align-middle" [textContent]="displayText"></span>
  </ng-container>
  <span class="align-middle" *ngIf="showMiddot$ | async">&nbsp;&middot;&nbsp;</span>
  <span class="fw-normal align-middle" *ngIf="displayDate$ | async as displayDate" [textContent]="displayDate"></span>
</div>
