<div class="dropdown-form" data-test="sort-by">
  <span class="dropdown-label">{{ label }}</span>
  <ng-content select="span"></ng-content>
  <div class="dropdown rounded border-grey-300">
    <select
      #select
      class="dropdown-btn ps-2"
      (change)="change($event)"
      floAnalytics
      name="Sort By"
      events="click"
    ></select>
    <span
      floSvg="icon-angle-down"
      class="primary-color-text svg-icon-xl"
      aria-hidden="true"
    ></span>
  </div>
</div>
