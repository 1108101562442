export type ID_VALIDATION_RULE_KEYS =
  | 'MIN_LENGTH_5'
  | 'NUMERICAL_ID'
  | 'OFP_ID_WITH_OPTIONAL_SLUG'
  | 'CORE_ID_OR_OFP_ID'
  | 'CORE_ID_WITH_OPTIONAL_SLUG'

/**
 * @example 12345
 * @example 12345-the-best-event-ever
 */
export const CORE_ID_RULES: ID_VALIDATION_RULE_KEYS[] = ['CORE_ID_WITH_OPTIONAL_SLUG']

/**
 * @example 9v4D4eu4V0tNuhf5
 * @example 99v4D4eu4V0tNuhf5
 * @example 99v4D4eu4V0tNuhf5-the-best-event-ever
 * @example 999v4D4eu4V0tNuhf5
 * @example 999v4D4eu4V0tNuhf5-the-best-event-ever
 */
export const OFP_ID_RULES: ID_VALIDATION_RULE_KEYS[] = ['OFP_ID_WITH_OPTIONAL_SLUG']

/**
 * @example 9v4D4eu4V0tNuhf5
 * @example 9v4D4eu4V0tNuhf5-the-best-event-ever
 * @example 12345
 * @example 12345-the-best-event-ever
 */
export const CORE_ID_OR_OFP_ID_RULES: ID_VALIDATION_RULE_KEYS[] = ['CORE_ID_OR_OFP_ID']

export const VALIDATION_RULES_FOR_ID_PARAMS: Record<ID_VALIDATION_RULE_KEYS, RegExp> = {
  MIN_LENGTH_5: /^.{5,}$/,
  NUMERICAL_ID: /^\d+$/,
  CORE_ID_WITH_OPTIONAL_SLUG: /^[0-9]{5,}(-[a-zA-Z0-9]+)*$/,
  OFP_ID_WITH_OPTIONAL_SLUG: /^[0-9]{1,3}[a-zA-Z0-9]{15}(-[a-zA-Z0-9]+)*$/,
  CORE_ID_OR_OFP_ID: /^[0-9]{1,3}[a-zA-Z0-9]{15}(-[a-zA-Z0-9]+)*$|^[0-9]{5,}(-[a-zA-Z0-9]+)*$/
}
