import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SlimHeaderComponent } from './slim-header.component'
import { RouterModule } from '@angular/router'
import { AnalyticsModule } from 'src/app/shared/analytics/analytics.module'
import { SvgModule } from 'src/app/shared/svg/svg.module'
import { FloCssClassSanitizePipeModule } from '../_pipes/css-class-sanitize/css-class-sanitize.pipe.module'

@NgModule({
  declarations: [SlimHeaderComponent],
  exports: [SlimHeaderComponent],
  imports: [CommonModule, RouterModule, AnalyticsModule, FloCssClassSanitizePipeModule, SvgModule]
})
export class SlimHeaderModule {}
