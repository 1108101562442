import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject, Subject } from 'rxjs'
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap
} from 'rxjs/operators'
import { CouponService, ICoupon } from './coupon.service'
import { SiteIds } from '../models/site.model'
import { ActivatedRoute, Params } from '@angular/router'
import { CookieService } from '../../singleton-services/cookie.service'
import { SegmentService } from '../analytics/services/segment.service'
import { SegmentEvents } from '../analytics/models/segment-events.model'
import { VerticalService } from '../../singleton-services/vertical.service'
import { AccountService } from '../../singleton-services/account.service'
import type { FloResponse } from '@flocasts/flosports30-types/dist/flo-rest-api/response'
import type { Plan, UpgradePreview } from '@flocasts/flosports30-types/dist/subscriptions'
import { calculateAnnualMarkup, getDefaultSelectedPlan } from '../../funnel/plans/legacy-plans/legacy-plans.utility'
import { notNullOrUndefined } from '../functions/type-guards'
import { LoggerService } from 'src/app/logger/logger.interface'
import { LOGGER_SERVICE } from 'src/app/logger/logger.config'
import { ExperimentationService } from '../experimentation/experimentation.service'
import { getLastValidParamValue } from '../utility-functions/param-map.utility'
import {
  EXP_HOCKEY_CAD_ID,
  EXP_HOCKEY_CAD_VARIATION_1,
  EXP_YEARLY_PRICING_INCREASE_ID,
  EXP_YEARLY_PRICING_INCREASE_SP_NAME_CONTROL,
  EXP_YEARLY_PRICING_INCREASE_VARIATION
} from '../experimentation/experiment.model'
import { EnvironmentService } from '../../singleton-services/environment.service'
import { Offer } from '@flocasts/offers'
import { CORE_API_PLANS_PATH, CORE_API_SUBSCRIPTIONS_PATH } from 'src/app/app.config'

export const SPECIAL_PRICING_COOKIE = 'flo-sp'
export const COUPON_COOKIE = 'flo-coupon'
export const SPECIAL_PRICING_PARAM = 'sp'

interface IEntitlement {
  display_name: string
  label: string
  authorized: boolean
}

/**
 * @deprecated use Product from flosports30-types
 */
export interface IProduct {
  id: number
  name: string
  entitlements?: ReadonlyArray<IEntitlement>
  plans: ReadonlyArray<Plan>
  yearly_plans?: ReadonlyArray<Plan>
  has_one_shown_plan?: boolean
  has_one_shown_yearly_plan?: boolean
  legacy_product_status?: boolean
  lowest_plan_price: number
  default_selection?: boolean
}

export interface IProductService {
  products$: Observable<ReadonlyArray<IProduct | Offer>>
  selectedProduct$: Observable<IProduct | Offer | undefined>
  hasOneShownPlan$: Observable<boolean | undefined>
}

export const LEGACY_CHURN_PREVENTION_COUPON = '20offyearlycp'
export const CHURN_PREVENTION_COUPON = 'CS20'

@Injectable()
export class ProductService implements IProductService {
  constructor(
    private readonly http: HttpClient,
    private readonly couponService: CouponService,
    private readonly vs: VerticalService,
    private readonly ar: ActivatedRoute,
    private readonly cookieService: CookieService,
    private readonly segment: SegmentService,
    private readonly accountService: AccountService,
    @Inject(LOGGER_SERVICE) private readonly log: LoggerService,
    private readonly experimentationService: ExperimentationService,
    private readonly es: EnvironmentService
  ) {}

  private specialPricingQueryParam$ = this.ar.queryParams.pipe(
    map(mapQueryParamsToSpQueryParam),
    distinctUntilChanged(),
    filter(Boolean),
    shareReplay(1)
  )

  private specialPricingCookie$ = this.cookieService.cookies$.pipe(
    map(mapCookiesToSpCookie),
    distinctUntilChanged(),
    shareReplay(1)
  )

  // CXP-3347: CAD pricing on Hockey
  private isInHockeyCADVariation$ = this.experimentationService.isInVariation(
    EXP_HOCKEY_CAD_ID,
    EXP_HOCKEY_CAD_VARIATION_1,
    false
  )

  /**
   * CXP-6205: Yearly Pricing Experiment.
   * We use this to enable the proper special pricing value for FloWrestling.
   * Applies to FloWrestling only because it is the only vertical on the old pricing architecture.
   */
  public isInFloWrestlingYearlyPriceVariation$ = this.experimentationService.isInVariation(
    EXP_YEARLY_PRICING_INCREASE_ID,
    EXP_YEARLY_PRICING_INCREASE_VARIATION,
    false
  )

  private specialPricingCombined$ = combineLatest([
    this.specialPricingQueryParam$.pipe(startWith(undefined)),
    this.specialPricingCookie$.pipe(startWith(undefined)),
    this.isInFloWrestlingYearlyPriceVariation$
  ]).pipe(shareReplay(1))

  public specialPricingValue$: Observable<string | undefined> = this.specialPricingCombined$.pipe(
    map(([spParam, spCookie, isInFloWrestlingYearlyPriceVariation]) => {
      if (isInFloWrestlingYearlyPriceVariation) {
        return EXP_YEARLY_PRICING_INCREASE_SP_NAME_CONTROL
      } else if (!spParam && spCookie) {
        return spCookie
      } else if (spParam) {
        this.cookieService.set(SPECIAL_PRICING_COOKIE, spParam, { expires: 1 })
        return spParam
      }

      return undefined
    }),
    take(1),
    shareReplay(1)
  )

  /**
   * If there's a sp=XX queryParam but NO flo-sp cookie,
   * explicitly handle segment tracking and cookie-setting side effects here.
   */
  public trackEventAndSetCookie = this.specialPricingCombined$
    .pipe(
      filter(([param, cookie]) => !!param && !cookie),
      map(([param]) => param),
      take(1)
    )
    .subscribe(val => {
      this.segment.track(SegmentEvents.SPECIAL_PRICING_APPLIED, {
        special_pricing_id: val
      })
    })

  private params$ = combineLatest([
    this.couponService.coupon$,
    this.isInHockeyCADVariation$,
    this.specialPricingValue$.pipe(startWith(undefined)),
    this.accountService.userData$
  ]).pipe(
    map(([coupon, isInHockeyCADVariation, specialPricingValue, userData]) =>
      getParams(isInHockeyCADVariation, coupon, specialPricingValue, userData?.email)
    ),
    shareReplay(1)
  )

  /** PRODUCT DATA */

  public products$ = this.params$.pipe(
    switchMap(params => this.getProducts(params)),
    shareReplay(1)
  )

  // In self-cancellation flow, for churn prevention, apply coupon code when fetching products
  public readonly legacyProductsWithChurnPreventionCoupon$ = this.params$.pipe(
    map(params => params.set('coupon', LEGACY_CHURN_PREVENTION_COUPON)),
    switchMap(params => this.getProducts(params)),
    shareReplay(1)
  )

  // In self-cancellation flow, for churn prevention, apply coupon code when fetching products
  public readonly offersWithChurnPreventionCoupon$ = this.params$.pipe(
    map(params => params.set('coupon', CHURN_PREVENTION_COUPON)),
    switchMap(params => this.getOffers(params)),
    shareReplay(1)
  )

  private selectedProductSource = new Subject<IProduct>()
  public selectedProduct$ = this.products$.pipe(
    switchMap(products => {
      return this.selectedProductSource.pipe(
        startWith(products.filter(product => product.default_selection).pop() || products[0])
      )
    }),
    shareReplay(1)
  )
  /** END PRODUCT DATA */

  /** PLAN DATA */
  public plans$ = this.products$.pipe(
    map(mapProductsToDefaultProduct),
    map(mapProductToPlans),
    map(sortPlans),
    map(mapPlansToPlanResponse),
    shareReplay(1)
  )

  public monthlyPlan$: Observable<Plan | undefined> = this.selectedProduct$.pipe(
    map(product => findPlanFromProduct(product, false)),
    shareReplay(1)
  )

  public yearlyPlan$: Observable<Plan | undefined> = this.selectedProduct$.pipe(
    map(product => findPlanFromProduct(product)),

    shareReplay(1)
  )

  /**
   * Returns monthly to yearly upgrade invoice data
   * This stores the response so multiple stripe calls can be prevented
   */
  private upgradeInvoicePreviewSubject = new BehaviorSubject<UpgradePreview | undefined>(undefined)
  private upgradeInvoicePreview$ = this.upgradeInvoicePreviewSubject.asObservable()

  /**
   * Returns monthly to yearly upgrade invoice data or undefined if errors
   */
  public getUpgradeInvoicePreview$ = (
    userSubscriptionId: number | string | undefined
  ): Observable<UpgradePreview | undefined> => {
    return this.upgradeInvoicePreview$.pipe(
      switchMap(res => {
        return res !== undefined
          ? of(res)
          : this.http
              .get<FloResponse<UpgradePreview, {}>>(
                `${CORE_API_SUBSCRIPTIONS_PATH}/${userSubscriptionId}/preview-upgrade`
              )
              .pipe(
                catchError((error: HttpErrorResponse) => {
                  this.log.error('An error occurred while fetching user subscription upgrade invoice', {
                    error
                  })

                  return of(undefined)
                }),
                filter(s => notNullOrUndefined(s)),
                map((summary: FloResponse<UpgradePreview, {}>) => summary.data),
                tap(data => this.upgradeInvoicePreviewSubject.next(data))
              )
      })
    )
  }

  /**
   * Defaults to plan's default selection (often yearly),
   * but is the plan a user has selected
   */
  private selectedPlanSource = new ReplaySubject<Plan | undefined>(1)
  private _selectedPlan$ = this.selectedPlanSource.asObservable()
  public selectedPlan$ = this.plans$.pipe(
    switchMap(a => {
      return this._selectedPlan$.pipe(startWith(getDefaultSelectedPlan(a)))
    }),
    shareReplay(1)
  )

  public planProps$ = this.vs.siteSettings$.pipe(
    map(settings => {
      return getPlanProps(settings.site_id, settings.site_name)
    }),
    shareReplay(1)
  )

  public includedPlanProps$ = of(getIncludedPlanProps())

  public amountSaved$ = this.selectedPlan$.pipe(
    map((plan: Plan) => calculateAnnualMarkup(plan?.annual_markup_money, plan?.annual_savings_money)),
    shareReplay(1)
  )

  public savingsProp$ = this.amountSaved$.pipe(map(amount => `Save ${amount} per year`))
  /** END PLAN DATA */

  public hasOneShownPlan$ = this.selectedProduct$.pipe(
    map(product => {
      if (!(product instanceof Offer)) {
        return product.has_one_shown_plan
      }
      return undefined
    }),
    shareReplay(1)
  )

  public updateSelectedPlan(plan?: Plan) {
    this.selectedPlanSource.next(plan)
  }

  /**
   * In fastly, we intercept the /api/products call and conditionally add
   * a 'currency' query string param based on the users geocode. This can alter
   * the products returned by Core API
   *
   * @see {@link
   * https://github.com/flocasts/terraform-fastly/blob/master/terraform/fastly/modules/vcl-platform-api/filter-queryparams.vcl#L6}
   */
  private getProducts(params: HttpParams): Observable<ReadonlyArray<IProduct>> {
    return this.http
      .get<FloResponse<ReadonlyArray<IProduct>, {}>>('products', {
        params
      })
      .pipe(map(a => a.data || []))
  }

  public getOffers(params: HttpParams): Observable<ReadonlyArray<Offer>> {
    return this.http
      .get<FloResponse<ReadonlyArray<Offer>, {}>>(`${this.es.config.endpoints.offers}/offers`, {
        params
      })
      .pipe(map(offersResponse => offersResponse.data || []))
  }

  /**
   * Return a single plan with calculated discount (coupon)
   * ex: /api/plans/305?coupon=20_REACTIVATE_OFF
   * @param planId: number
   * @param couponId
   */
  public getPlanWithDiscount(planId: string | number, couponId: string): Observable<FloResponse<Plan, {}>> {
    const params = new HttpParams().set('coupon', couponId)

    return this.http.get<FloResponse<Plan, {}>>(`${CORE_API_PLANS_PATH}/${planId}`, {
      params
    })
  }
}

// Defaults to yearly plan
export const findPlanFromProduct = (product: IProduct, findYearly = true) => {
  return product.plans.find(plan => plan.yearly === findYearly)
}

const getParams = (
  isInHockeyCADVariation: boolean,
  coupon?: ICoupon,
  specialPricingValue?: string,
  userEmail?: string
) => {
  let params = new HttpParams().set('provider', 'stripe')

  if (isInHockeyCADVariation) {
    params = params.set('currency', 'CAD')
  }

  if (userEmail) {
    params = params.set('email', userEmail)
  }

  if (coupon) {
    params = params.set('coupon', coupon.id)
  }

  if (specialPricingValue) {
    params = params.set('sp', specialPricingValue)
  }

  return params
}

export const mapProductsToDefaultProduct = (products: ReadonlyArray<IProduct>) => {
  const legacyProduct = products
    .filter(product => product.legacy_product_status === null || product.legacy_product_status)
    .pop()
  const newDefaultProduct = products.filter(product => product.default_selection).pop()

  return !!legacyProduct ? legacyProduct : newDefaultProduct
}

export const mapProductToPlans = (product?: IProduct) => {
  return (product && product.plans) || []
}

/*
 * sort by lowest cost per month (best value) to highest
 */
export const sortPlans = (plans: ReadonlyArray<Plan>) => {
  const plansCopy = [...plans] // prevent mutating the array
  return plansCopy.sort((a, b) => a.cost_per_month.amount - b.cost_per_month.amount)
}

export const mapPlansToPlanResponse = (plans: ReadonlyArray<Plan>) => {
  return {
    plans,
    publicKey: plans[0].payment_provider && plans[0].payment_provider.public_key
  }
}

export const findLowestPlanPriceFromProducts = (products: ReadonlyArray<IProduct>): number => {
  return products
    .map(product => product && product.lowest_plan_price)
    .reduce((prev: number, current: number) => {
      return prev < current ? prev : current
    })
}

const mapQueryParamsToSpQueryParam = (queryParams: Params): string | undefined => {
  return getLastValidParamValue(queryParams.sp)
}

const mapCookiesToSpCookie = (cookies: Record<string, string | undefined>) => {
  return cookies[SPECIAL_PRICING_COOKIE]
}

// TODO (Tech Debt: FLO-10844): We already have an idea of `funnel_plan_props` that is returned in the API (plan object).
// The API uses Stripe metadata or just "default".
// We've never utilized the Stripe metadata for this, but we could, and then delete all this FE stuff.
export const getPlanProps = (siteId: number | undefined, siteName = 'FloSports') => {
  const defaultProps: ReadonlyArray<string> = [
    'Stream unlimited events, live or on-demand',
    `Access the full library of ${siteName} Originals`,
    'Get HD-quality streams and videos',
    'Watch on web or the FloSports app—available on iOS, Android, Apple TV, Roku, Amazon Fire, and Chromecast'
  ]

  return siteId ? planPropsDict[siteId] || defaultProps : defaultProps
}

export const getIncludedPlanProps = () => {
  return [
    'HD quality live streams and video on demand',
    'Access the full library of FloSports originals',
    'Watch on web or the FloSports app -- available on iOS, Android, Apple TV, Roku, Amazon Fire, and Chromecast'
  ]
}

const planPropsDict: { [key: string]: ReadonlyArray<string> } = {
  [SiteIds.FLOCHEER]: [
    'Stream unlimited cheer and dance events',
    'Access the full library of FloCheer Originals',
    'Get HD-quality streams and videos',
    'Watch on web or the FloSports app—available on iOS, Android, Apple TV, Roku, Amazon Fire, and Chromecast'
  ],
  [SiteIds.FLOGRAPPLING]: [
    'Stream unlimited jiu-jitsu and grappling events, live or on-demand',
    'Access the full library of FloGrappling Originals',
    'Get HD-quality streams and videos',
    'Watch on web or the FloSports app—available on iOS, Android, Apple TV, Roku, Amazon Fire, and Chromecast'
  ],
  [SiteIds.FLOGYMNASTICS]: [
    'Stream unlimited gymnastics events, live or on-demand',
    'Access the full library of FloGymnastics Originals',
    'Get HD-quality streams and videos',
    'Watch on web or the FloSports app—available on iOS, Android, Apple TV, Roku, Amazon Fire, and Chromecast'
  ],
  [SiteIds.FLOMARCHING]: [
    'Stream unlimited marching events',
    'Access the full library of FloMarching Originals',
    'Get HD-quality streams and videos',
    'Watch on web or the FloSports app—available on iOS, Android, Apple TV, Roku, Amazon Fire, and Chromecast'
  ],
  [SiteIds.FLORUGBY]: [
    'Stream unlimited rugby events, live or on-demand',
    'Access all rankings, results, and highlights',
    'Get HD-quality streams and videos',
    'Watch on web or the FloSports app—available on iOS, Android, Apple TV, Roku, Amazon Fire, and Chromecast'
  ],
  [SiteIds.FLOSOFTBALL]: [
    'Stream unlimited softball events, live or on-demand',
    'Access the full library of FloSoftball Originals',
    'Get HD-quality streams and videos',
    'Watch on web or the FloSports app—available on iOS, Android, Apple TV, Roku, Amazon Fire, and Chromecast'
  ],
  [SiteIds.FLOTRACK]: [
    'Stream unlimited track and field events, live or on-demand',
    'Access the full library of FloTrack Originals',
    'Get HD-quality streams and videos',
    'Watch on web or the FloSports app—available on iOS, Android, Apple TV, Roku, Amazon Fire, and Chromecast'
  ],
  [SiteIds.FLOVOLLEYBALL]: [
    'Stream unlimited volleyball events, live or on-demand',
    'Access the full library of FloVolleyball Originals',
    'Get HD-quality streams and videos',
    'Watch on web or the FloSports app—available on iOS, Android, Apple TV, Roku, Amazon Fire, and Chromecast'
  ],
  [SiteIds.FLOWRESTLING]: [
    'Stream unlimited wrestling events, live or on-demand',
    'Access the full library of FloWrestling Originals',
    'Get HD-quality streams and videos',
    'Watch on web or the FloSports app—available on iOS, Android, Apple TV, Roku, Amazon Fire, and Chromecast'
  ],
  [SiteIds.VARSITY]: [
    'Stream unlimited cheer and dance events',
    'Access the full library of Varsity TV Originals',
    'Get HD-quality streams and videos',
    'Watch on web or the FloSports app—available on iOS, Android, Apple TV, Roku, Amazon Fire, and Chromecast'
  ],
  [SiteIds.FLOBASEBALL]: [
    'Stream unlimited baseball events, live or on-demand',
    'Access all news, results, and highlights',
    'Get HD-quality streams and videos',
    'Watch on web or the FloSports app—available on iOS, Android, Apple TV, Roku, Amazon Fire, and Chromecast'
  ],
  [SiteIds.FLOFC]: [
    'Stream unlimited soccer events, live or on-demand',
    'Access all highlights, recaps, and news',
    'Get HD-quality streams and videos',
    'Watch on web or the FloSports app—available on iOS, Android, Apple TV, Roku, Amazon Fire, and Chromecast'
  ],
  [SiteIds.FLOBIKES]: [
    'Stream unlimited cycling events, live or on-demand',
    'Access the full library of FloBikes Originals',
    'Get HD-quality streams and videos',
    'Watch on web or the FloSports app—available on iOS, Android, Apple TV, Roku, Amazon Fire, and Chromecast'
  ],
  [SiteIds.FLORACING]: [
    'Live streams of more than 1,000 Racing events per year',
    'On-demand replays from the biggest dirt tracks, short tracks, drag races, and more',
    'Live results, news, recaps, highlights and interviews with your favorite drivers',
    'Watch anytime, anywhere, on any device - iOS, Android, & Smart TV apps'
  ]
}
