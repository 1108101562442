import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { IfLoadedDirective } from './if-loaded.directive'

@NgModule({
  imports: [CommonModule],
  exports: [IfLoadedDirective],
  declarations: [IfLoadedDirective]
})
export class IfLoadedModule {}
