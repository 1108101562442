import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChildren
} from '@angular/core'
import {
  VideoPlayerControlsConfig,
  VideoPlayerControlsFullscreenToggleClickEvent,
  VideoPlayerControlsPlayToggleClickEvent
} from './video-player-controls.interfaces'
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs'
import { map } from 'rxjs/operators'
import { IVolumeControls } from '../../models/volume-controls.model'
import { maybe } from 'typescript-monads'
import { IVideoQuality } from '../../models/video-quality.model'
import { IVideoScrubber } from '../../../../live/components/video-player/video-player.interfaces'

export const DEFAULT_CONFIG = {
  showSkipBackwardBtn: true,
  showSkipFowardBtn: true,
  showLiveIndicator: true,
  showFullscreenBtn: true,
  showSettingsBtn: true,
  showShareBtn: true
}

export interface PlayState {
  volume: number
  isPaused: boolean
}

export interface IVideoSkip {
  skipTime: number
}

@Component({
  selector: 'flo-video-player-controls',
  templateUrl: './video-player-controls.component.html',
  styleUrls: ['./video-player-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoPlayerControlsComponent implements OnChanges {
  @Input()
  get config(): VideoPlayerControlsConfig {
    return {
      ...DEFAULT_CONFIG,
      ...this._config
    }
  }

  set config(val: VideoPlayerControlsConfig) {
    this._config = val
  }

  @Input()
  get segmentAnalytics(): any {
    return {
      ...this._segmentAnalytics
    }
  }

  set segmentAnalytics(val: any) {
    this._segmentAnalytics = val
  }
  private _config: VideoPlayerControlsConfig
  private _segmentAnalytics: any
  private shareMenuSource = new BehaviorSubject<boolean>(false)
  private videoBufferAmountSource = new BehaviorSubject<number>(0)
  private qualityMenuSource = new BehaviorSubject<IVideoQuality>({
    levels: [],
    manualLevel: -1
  })

  private videoPlayToggleSource = new BehaviorSubject<boolean>(true)
  private videoCurrentTimeSource = new BehaviorSubject<IVideoScrubber>({
    currentTime: 0,
    duration: 0
  })
  private volumeStateSource = new ReplaySubject<IVolumeControls>(1)
  private fullScreenSource = new BehaviorSubject<boolean>(false)
  private shareMenu$ = this.shareMenuSource.asObservable()
  private qualityMenu$ = this.qualityMenuSource.asObservable()
  private videoPlaying$ = this.videoPlayToggleSource.asObservable()
  private volumeState$ = this.volumeStateSource
  private fullScreen$ = this.fullScreenSource.asObservable()
  private videoCurrentTime$ = this.videoCurrentTimeSource.asObservable()
  private videoBufferAmount$ = this.videoBufferAmountSource.asObservable()

  @ViewChildren('clickOutAvoid')
  clickOutIgnoredElements: ReadonlyArray<any> = []

  @Input()
  title: string
  @Input()
  isLive = false
  @Input()
  videoPlayState: VideoPlayerControlsPlayToggleClickEvent
  @Input()
  volumeStateInput: IVolumeControls
  @Input()
  qualityControlState: IVideoQuality
  @Input()
  isFullScreen = false
  @Input()
  videoCurrentTime: IVideoScrubber
  @Input()
  videoBufferAmount: number
  @Input()
  isVOD = true
  @Input()
  fullScreenElement: any

  @Output()
  readonly clickedFullscreenToggle = new EventEmitter<VideoPlayerControlsFullscreenToggleClickEvent>()
  @Output()
  readonly clickedPlayToggle = new EventEmitter<boolean>()
  @Output()
  readonly clickedShareMenu = new EventEmitter<boolean>()
  @Output()
  readonly selectedShareItem = new EventEmitter<any>()
  @Output()
  readonly qualityChanged = new EventEmitter<number>()
  @Output()
  readonly volumeState = new EventEmitter<IVolumeControls>()
  @Output()
  readonly pauseVideoOnSeeking = new EventEmitter<boolean>()
  @Output()
  readonly videoPositionChange = new EventEmitter<number>()
  @Output()
  readonly clickedSkipForward = new EventEmitter<IVideoSkip>()
  @Output()
  readonly clickedSkipBackward = new EventEmitter<IVideoSkip>()

  view$ = combineLatest(
    this.shareMenu$,
    this.qualityMenu$,
    this.videoPlaying$,
    this.volumeState$,
    this.fullScreen$,
    this.videoCurrentTime$,
    this.videoBufferAmount$
  ).pipe(
    map(([shareMenu, qualityControl, videoPlaying, volumeState, fullScreen, currentTime, bufferAmount]) => {
      const analytics = this.segmentAnalytics ? this.segmentAnalytics : ''
      return {
        showShareMenu: shareMenu,
        isVideoPlaying: videoPlaying,
        volumeState,
        qualityControl,
        fullScreen,
        analyticPropsPlayBtn: {
          name: videoPlaying ? 'Pause' : 'Play',
          ...analytics
        },
        analyticPropsFullScreen: {
          name: fullScreen ? 'Close Full Screen' : 'Open Full Screen',
          ...analytics
        },
        analyticPropsSkipForward: {
          name: 'Skip Forward',
          ...analytics
        },
        analyticPropsSkipBack: {
          name: 'Skip Backwards',
          ...analytics
        },
        analytics,
        bufferAmount,
        currentTime,
        duration: currentTime.duration,
        videoDuration: Math.floor(currentTime.duration) || 0,
        videoCurrentTime: Math.floor(currentTime.currentTime)
      }
    })
  )

  @HostListener('document:click', ['$event.srcElement'])
  onClick(srcElement: any) {
    if (
      this.clickOutIgnoredElements.some(a => a.nativeElement && (a.nativeElement as HTMLElement).contains(srcElement))
    ) {
      return
    } else {
      this.shareMenuSource.next(false)
    }
  }

  _clickedFullscreenToggle() {
    this.clickedFullscreenToggle.next({ open: !this.isFullScreen })
  }

  _clickedSkipForward() {
    this.clickedSkipForward.next({ skipTime: 10 })
  }

  _clickedSkipBackward() {
    this.clickedSkipBackward.next({ skipTime: -10 })
  }

  _clickedShareMenu(evt: Event) {
    this.toggleShareMenu()
  }

  _clickedQualityMenu(evt: Event) {
    this.closeShareMenu()
  }

  _clickedPlayIcon() {
    this.clickedPlayToggleIcon()
    this.togglePlayIcon()
  }

  _clickedPauseIcon() {
    this.clickedPlayToggleIcon()
    this.togglePlayIcon()
  }

  closeShareMenu() {
    this.shareMenuSource.next(false)
  }

  clickedPlayToggleIcon() {
    this.clickedPlayToggle.next(!this.videoPlayToggleSource.getValue())
  }

  toggleShareMenu() {
    this.shareMenuSource.next(!this.shareMenuSource.getValue())
  }

  togglePlayIcon() {
    this.videoPlayToggleSource.next(!this.videoPlayToggleSource.getValue())
  }

  setVolumeState(volume: IVolumeControls) {
    this.volumeState.next(volume)
  }

  videoQualityChange(quality: number) {
    this.qualityChanged.next(quality)
  }

  _pauseVideoOnSeeking(pause: boolean) {
    this.pauseVideoOnSeeking.next(pause)
  }

  _videoPositionChange(position: number) {
    this.videoPositionChange.next(position)
  }

  ngOnChanges(changes: SimpleChanges) {
    maybe(changes.videoPlayState)
      .flatMap(change => maybe<PlayState>(change.currentValue))
      .tapSome(val => this.videoPlayToggleSource.next(val.isPaused))

    maybe(changes.videoCurrentTime)
      .flatMap(change => maybe<IVideoScrubber>(change.currentValue))
      .tapSome(val => this.videoCurrentTimeSource.next(val))

    maybe(changes.volumeStateInput)
      .flatMap(change => maybe<IVolumeControls>(change.currentValue))
      .tapSome(val => this.volumeStateSource.next(val))

    maybe(changes.isFullScreen)
      .flatMap(change => maybe<boolean>(change.currentValue))
      .tapSome(val => this.fullScreenSource.next(val))

    maybe(changes.qualityControlState)
      .flatMap(change => maybe(change.currentValue))
      .tapSome(val => this.qualityMenuSource.next(val))

    maybe(changes.videoBufferAmount)
      .flatMap(change => maybe(change.currentValue))
      .tapSome(val => this.videoBufferAmountSource.next(val))
  }
}
