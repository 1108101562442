import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import {
  distinctUntilChanged,
  map,
  shareReplay,
  take,
  tap,
  catchError,
  startWith,
  timeout,
  mergeMap
} from 'rxjs/operators'
import { combineLatest, Observable, of } from 'rxjs'
import { VerticalService } from '../../singleton-services/vertical.service'
import { FloApiResponse } from '../models/api-response.model'
import { CookieService } from '../../singleton-services/cookie.service'
import { SegmentService } from '../analytics/services/segment.service'
import { SegmentEvents } from '../analytics/models/segment-events.model'
import { filterDeepRouterDataByPredicate } from '../rx-pipes/router'
import { LoggingService } from 'src/app/singleton-services/logging/logging.service'
import { CouponStatusUtility } from '../../funnel/coupon/coupon-status.utility'
import { EnvironmentService } from 'src/app/singleton-services/environment.service'
import { ExperimentationService } from '../experimentation/experimentation.service'
import { FEAT_PRICING_ARCH_ID, FEAT_PRICING_ARCH_VARIATION } from '../experimentation/experiment.model'

/**
 * @deprecated Use the new CouponC interface and CouponService instead
 * @see CouponC
 * @see {@link https://github.com/flocasts/flosports-webapp/blob/master/src/client/app/shared/coupon/coupon.io.ts#L105}
 */
export interface ICoupon {
  id: string
  name: string
  amount_off: number | undefined
  percent_off: number | undefined
}

interface ICouponResponse {
  readonly data: ICoupon
}

/**
 * @deprecated You won't need this if you use the new coupon service.
 * @see {@link https://github.com/flocasts/flosports-webapp/blob/master/src/client/app/shared/coupon/coupon.service.ts}
 */
const isCouponResponse = (response: ICouponResponse | CouponError | undefined): response is FloApiResponse<ICoupon> => {
  return response !== undefined && 'data' in response && isCoupon(response.data)
}

/**
 * @deprecated this function uses a deprecated type.
 * @see {@link https://github.com/flocasts/flosports-webapp/blob/master/src/client/app/shared/coupon/coupon.utility.ts#L30}
 */
export const isCoupon = (coupon: ICoupon | ICouponResponse | CouponError | undefined): coupon is ICoupon => {
  return (
    coupon !== undefined &&
    'id' in coupon &&
    'name' in coupon &&
    'amount_off' in coupon &&
    'percent_off' in coupon &&
    (coupon.amount_off !== undefined || coupon.percent_off !== undefined)
  )
}

export enum CouponErrorCondition {
  NOT_FOUND = 'not-found',
  EXPIRED = 'expired',
  SERVER_ERROR = 'server-error'
}

export interface CouponError {
  condition: CouponErrorCondition
}

/**
 * @deprecated this function uses a deprecated type.
 * @see {@link https://github.com/flocasts/flosports-webapp/blob/master/src/client/app/shared/coupon/coupon.utility.ts#L30}
 */
export const isCouponError = (coupon: ICouponResponse | ICoupon | CouponError | undefined): coupon is CouponError => {
  return coupon !== undefined && 'condition' in coupon
}

export interface ICouponRouteData {
  showRibbon: boolean
  hasBeenApplied?: boolean
}

const COUPON_COOKIE = 'flo-coupon'

/**
 * @deprecated There is a new implementation for fetching coupons.
 * @see {@link https://github.com/flocasts/flosports-webapp/blob/master/src/client/app/shared/coupon/coupon.service.ts}
 */
@Injectable()
export class CouponService {
  private readonly couponQueryParam$: Observable<string | null> = this.ar.queryParamMap.pipe(
    distinctUntilChanged(),
    map<ParamMap, string | null>(queryParams => queryParams.get('c')),
    take(1)
  )

  private readonly couponCookie$: Observable<any> = this.cookieService.cookies$.pipe(
    map<Record<string, any>, any>(cookies => cookies[COUPON_COOKIE]),
    take(1)
  )

  /**
   * @deprecated Use the `getById` method on the new CouponService
   * @see {@link https://github.com/flocasts/flosports-webapp/blob/master/src/client/app/shared/coupon/coupon.service.ts}
   */
  public readonly couponOrError$: Observable<ICoupon | CouponError | undefined> = combineLatest([
    this.couponQueryParam$,
    this.couponCookie$,
    this.experimentationService.isInVariation(FEAT_PRICING_ARCH_ID, FEAT_PRICING_ARCH_VARIATION, false)
  ]).pipe(
    tap(([couponQueryParam, couponInCookie]) => {
      if (couponQueryParam && !couponInCookie) {
        this.segmentService.track(SegmentEvents.COUPON_APPLIED, {
          coupon_id: couponQueryParam
        })
      }
    }),
    map(([couponQueryParam, couponInCookie, isInVariation]) => [
      couponQueryParam || couponInCookie || undefined,
      isInVariation
    ]),
    mergeMap(([value, isInVariation]: [string, boolean]) => this.fetchCoupon(value, isInVariation)),
    tap((value: ICoupon | CouponError | undefined) => this.saveCoupon(value)),
    shareReplay(1)
  )

  /**
   * @deprecated Use couponOrError$ instead to avoid throwing away errors.
   * @see CouponService.couponOrError$
   */
  public readonly coupon$: Observable<ICoupon | undefined> = this.couponOrError$.pipe(
    map(coupon => (isCouponError(coupon) ? undefined : coupon)),
    shareReplay(1)
  )

  public readonly couponRouteData$: Observable<ICouponRouteData | undefined> = this.router.events.pipe(
    filterDeepRouterDataByPredicate(this.ar)(a => a.data),
    map(data => data.coupon),
    startWith({ showRibbon: true, hasBeenApplied: false }), // have to start with true so it shows up on first page server-loaded in funnel
    shareReplay(1)
  )

  constructor(
    private readonly http: HttpClient,
    private readonly cookieService: CookieService,
    private readonly vs: VerticalService,
    private readonly es: EnvironmentService,
    private readonly segmentService: SegmentService,
    private readonly ar: ActivatedRoute,
    private readonly router: Router,
    private readonly logger: LoggingService,
    private readonly couponStatusUtility: CouponStatusUtility,
    private readonly experimentationService: ExperimentationService
  ) {}

  public clearCoupon() {
    this.vs.tokenDomain$.subscribe(domain => {
      this.cookieService.remove(COUPON_COOKIE, {
        domain
      })
    })
  }

  private fetchCoupon(
    couponId: string | undefined,
    isInVariation: boolean
  ): Observable<ICoupon | CouponError | undefined> {
    if (!couponId) return of(undefined)

    const url = isInVariation ? `${this.es.config.endpoints.offers}/coupons/${couponId}` : `coupons/${couponId}`

    return (
      this.http
        // TODO: Meta should be optional on FloApiResponse because it does not appear in this response, or there's an API bug
        .get<ICouponResponse | ICoupon>(url)
        .pipe(
          timeout(8000),
          catchError<ICouponResponse | ICoupon, Observable<CouponError | undefined>>((err: HttpErrorResponse) => {
            this.couponStatusUtility.hideCouponErrorSource.next(false)
            this.logger.error('A coupon error has occurred.', err)
            if (err.status === 404) {
              return of({ condition: CouponErrorCondition.NOT_FOUND })
            }

            if (err.status === 410) {
              return of({ condition: CouponErrorCondition.EXPIRED })
            }

            if (err.status === 500) {
              return of({ condition: CouponErrorCondition.SERVER_ERROR })
            }

            return of(undefined)
          }),
          map((res: ICouponResponse | ICoupon | CouponError | undefined) => {
            if (res === undefined) return res
            if (isCoupon(res)) return res as ICoupon
            if (isCouponResponse(res)) return res.data as ICoupon
            if (isCouponError(res)) return res as CouponError

            return undefined
          })
        ) as Observable<ICoupon | CouponError | undefined>
    )
  }

  private saveCoupon(coupon: ICoupon | CouponError | undefined): void {
    if (coupon && isCoupon(coupon)) {
      this.vs.tokenDomain$.subscribe(domain => {
        this.cookieService.set(COUPON_COOKIE, coupon.id, {
          secure: false,
          expires: 1,
          domain,
          path: '/'
        })
      })
    }
  }
}
