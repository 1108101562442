<div class="w-result-card d-flex align-items-center justify-content-between" *ngIf="wrestlingResult">
  <div class="w-result-card__competitor-detail col-3 col-sm-4 p-0 pe-1">
    <flo-wrestling-competitor-detail [competitor]="wrestlingResult.competitor1" alignElement="left">
    </flo-wrestling-competitor-detail>
  </div>

  <div data-test="w1" class="w-result-card__winner-text text-center caption font-heavy money-green">
    {{ wrestlingResult.competitor1.winnerText }}
  </div>

  <div class="w-result-card__match-replay">
    <flo-wrestling-match-replay
      [outcome]="wrestlingResult.outcome"
      [overtime]="wrestlingResult.overtime"
      [weightClass]="wrestlingResult.weightClass"
      [action]="wrestlingResult.action"
    >
    </flo-wrestling-match-replay>
  </div>

  <div data-test="w2" class="w-result-card__winner-text text-center caption font-heavy money-green">
    {{ wrestlingResult.competitor2.winnerText }}
  </div>

  <div class="w-result-card__competitor-detail col-3 col-sm-4 p-0 ps-1">
    <flo-wrestling-competitor-detail [competitor]="wrestlingResult.competitor2" alignElement="right">
    </flo-wrestling-competitor-detail>
  </div>
</div>