<ng-container *ngIf="!!segmentedControl">
  <div
    class="btn-group w-100"
    data-test="segmented-control">
    <a *ngFor="let option of segmentedControl.options; trackBy: trackById"
      class="btn"
      (click)="handleClick(option)"
      [ngClass]="{'active': option.state === 'selected'}"
      [floCustomLink]="option.url"
      floAnalytics
      [analyticsProperties]="option.analytics"
      tagName="Button"
    >
      {{option.title}}
    </a>
  </div>
</ng-container>