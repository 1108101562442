import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { LocationService } from 'src/app/singleton-services/location.service'

/*
  Add nav_id query param to requests
  If there exists a query param ?nav_id=XYZ in the route, we want to pass it along to any BFF requests
*/
@Injectable()
export class NavIdQueryParamInterceptor implements HttpInterceptor {
  constructor(private readonly locationService: LocationService) {}
  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const href = this.locationService.href
    const isBFFRequest = req.url.includes('experiences/web/')

    const queryParams = req.urlWithParams.includes('?') ? req.urlWithParams.split('?')[1] : ''
    const params = new HttpParams({ fromString: queryParams })

    if (isBFFRequest) {
      const url = new URL(href)
      const coreNavId = url.searchParams.get('nav_id')

      if (coreNavId && !params.has('nav_id')) {
        // If outgoing request is to BFF and current route has nav_id query param from core
        // Forward the nav_id query param from core to the nav_id query param for BFF request
        // However, if the request already has a nav_id query param then use that instead
        const clonedReq = req.clone({ params: req.params.set('nav_id', coreNavId) })
        return next.handle(clonedReq)
      }
    }
    return next.handle(req)
  }
}
