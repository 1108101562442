import { Injectable } from '@angular/core'

@Injectable()
export class FormErrorsService {
  public errorMessageDict: { [key: string]: { [key: string]: string } } = {
    loginFormEmail: FormErrorsMap.EMAIL_LOGIN,
    loginFormPassword: FormErrorsMap.PASSWORD_LOGIN,
    signupFormEmail: FormErrorsMap.EMAIL_SIGNUP,
    signupFormPassword: FormErrorsMap.PASSWORD_SIGNUP,
    forgotPassEmail: FormErrorsMap.FORGOT_PASSWORD,
    eventReminderEmail: FormErrorsMap.EMAIL,
    payFormName: FormErrorsMap.CARDHOLDER_NAME,
    claimAccountEmail: FormErrorsMap.EMAIL_CLAIM_ACCOUNT,
    resetPassword: FormErrorsMap.RESET_PASSWORD,
    confirmPassword: FormErrorsMap.CONFIRM_PASSWORD,
    activationCode: FormErrorsMap.ACTIVATE,
    newsletterFormEmail: FormErrorsMap.NEWSLETTER,
    redeemCode: FormErrorsMap.REDEEM
  }
}

class FormErrorsMap {
  static EMAIL = {
    required: 'Email address is a required field',
    minlength: 'Email must be at least 6 characters in length',
    maxlength: 'Email must not exceed 75 characters in length',
    email_format: 'Invalid email address, please try again',
    server_error: 'Error processing your request'
  }

  static EMAIL_LOGIN = {
    ...FormErrorsMap.EMAIL,
    minlength: 'Invalid email address. Forgot it? Email support@flosports.tv',
    email_format: 'Invalid email address. Forgot it? Email support@flosports.tv',
    email_does_not_exist: 'Email address not found'
  }

  static EMAIL_SIGNUP = {
    ...FormErrorsMap.EMAIL,
    minlength: 'Email must be at least 6 characters in length',
    maxlength: 'Email must not exceed 75 characters in length',
    email_format: 'Invalid email address, please try again',
    email_exists: 'Email address is already taken',
    invalid_email: 'Invalid email address, please try again'
  }

  static EMAIL_CLAIM_ACCOUNT = {
    ...FormErrorsMap.EMAIL,
    user_cannot_be_claimed:
      'An account with this email already exists. Please contact support@flosports.tv if you need help accessing it.',
    user_does_not_exist: 'Email address not found. Please contact support@flosports.tv.',
    unknown_error: 'An unknown error occurred. Please try again.'
  }

  static PASSWORD = {
    required: 'Password must be at least 6 characters in length'
  }

  static PASSWORD_LOGIN = {
    ...FormErrorsMap.PASSWORD,
    minlength: 'Password must be at least 6 characters in length',
    maxlength: 'Password must not exceed 125 characters in length',
    server_error: 'Invalid email/password'
  }

  static PASSWORD_SIGNUP = {
    ...FormErrorsMap.PASSWORD,
    minlength: 'Password must be at least 6 characters in length',
    maxlength: 'Password must not exceed 125 characters in length',
    captcha_error: 'An error occurred (code: RC3). Please try again or contact support.',
    server_error: 'Error creating user. Please try again or contact support.'
  }

  static FORGOT_PASSWORD = {
    ...FormErrorsMap.EMAIL,
    minlength: 'Whoops! Emails must be 6 - 75 characters.',
    email_format: 'Invalid email address. Forgot it? Email support@flosports.tv',
    email_does_not_exist: 'Email not found. Forgot it? Email support@flosports.tv',
    server_error: 'An unknown error occurred. Please try again.'
  }

  static RESET_PASSWORD = {
    ...FormErrorsMap.PASSWORD,
    minlength: 'Password must be at least 6 characters in length',
    maxlength: 'Password must not exceed 125 characters in length'
  }

  static CONFIRM_PASSWORD = {
    ...FormErrorsMap.RESET_PASSWORD,
    server_error: 'An unknown error occurred. Please try again.'
  }

  static CARDHOLDER_NAME = {
    required: 'Name is required'
  }

  static ACTIVATE = {
    unauthenticated: 'Please login first and try again.',
    invalid: 'That code is invalid or expired. Try again or request a new code.',
    activation_code_format: 'A valid activation code is required.'
  }

  static NEWSLETTER = {
    ...FormErrorsMap.EMAIL,
    email_exists: 'You are already subscribed!'
  }

  static REDEEM = {
    invalid_format_redeem_code: 'Enter your 16-digit redemption code without dashes',
    minlength: 'Enter your 16-digit redemption code without dashes',
    maxlength: 'Enter your 16-digit redemption code without dashes',
    required: 'Enter your 16-digit redemption code without dashes'
  }
}
