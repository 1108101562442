import { Injectable } from '@angular/core'
import { OfferSelectionCardModel } from '@flocasts/experience-service-types'
import {
  ReplaySubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap
} from 'rxjs'
import { PartialsDataService } from 'src/app/singleton-services/partials/partials-data.service'
import { isOfferListPartialModel, notNullOrUndefined } from '../shared/functions/type-guards'
import { CouponService } from '../shared/services/coupon.service'
import { ProductService } from '../shared/services/product.service'
import { VerticalService } from './vertical.service'
import { SiteIds } from '../shared/models/site.model'
import {
  EXP_YEARLY_PRICING_INCREASE_ID,
  EXP_YEARLY_PRICING_INCREASE_SP_NAME_CONTROL,
  EXP_YEARLY_PRICING_INCREASE_VARIATION,
  FEAT_PRICING_ARCH_ID,
  FEAT_PRICING_ARCH_VARIATION
} from '../shared/experimentation/experiment.model'
import { ExperimentationService } from '../shared/experimentation/experimentation.service'

@Injectable({
  providedIn: 'root'
})
export class OffersService {
  constructor(
    private readonly couponService: CouponService,
    private readonly partialsDataService: PartialsDataService,
    private readonly verticalService: VerticalService,
    private readonly productService: ProductService,
    private readonly experimentationService: ExperimentationService
  ) {}

  /**
   * CXP-6205: Increase Yearly Pricing Experiment.
   * We use this to enable the proper special pricing value.
   */
  public isInYearlyPriceVariation$ = this.experimentationService.isInVariation(
    EXP_YEARLY_PRICING_INCREASE_ID,
    EXP_YEARLY_PRICING_INCREASE_VARIATION,
    false
  )

  public offers$ = combineLatest([
    this.verticalService.siteId$,
    this.couponService.coupon$,
    this.productService.specialPricingValue$,
    this.isInYearlyPriceVariation$
  ]).pipe(
    switchMap(([siteId, coupon, specialPricingValue, isInYearlyPriceVariation]) => {
      const specialPrice = this.determineSpecialPrice(specialPricingValue, siteId, isInYearlyPriceVariation)
      return this.partialsDataService.getOffersListPartial(siteId, coupon, specialPrice)
    }),
    filter(notNullOrUndefined),
    filter(isOfferListPartialModel),
    map(res => res?.data),
    distinctUntilChanged(),
    shareReplay(1)
  )

  public offersLength$ = this.offers$.pipe(map(offers => offers.length))

  // TODO: Refactor the two ReplaySubjects below into one variable
  // that can keep track of the selectedOffer values on the funnel
  // and the change offer flow

  /**
   * This variable keeps track of the user's currently selected offer in the funnel,
   * and will update any time a user selects a new offer on the /plans route.
   *
   * Below we are piping off of the offers$ stream and using a switchMap because
   * we want the value of selectedOffer$ to default to the first value of the
   * offers array. So this value is dependent on the offers stream's initial emit.
   */
  private selectedOffer = new ReplaySubject<OfferSelectionCardModel | undefined>()
  private _selectedOffer$ = this.selectedOffer.asObservable()
  public selectedOffer$ = this.offers$.pipe(
    switchMap(offers => this._selectedOffer$.pipe(startWith(offers.find(offer => offer.defaultSelection)))),
    shareReplay(1)
  )

  public updateSelectedOffer(offer: OfferSelectionCardModel) {
    this.selectedOffer.next(offer)
  }

  /**
   * This variable keeps track of the user's selected offer on the /account/chang-offer/plans route,
   * and will update any time a user selects a new offer on the /account/change-offer/plans route.
   */
  private selectedChangeOffer = new ReplaySubject<OfferSelectionCardModel | undefined>()
  private _selectedChangeOffer$ = this.selectedChangeOffer.asObservable()
  public selectedChangeOffer$ = this.offers$.pipe(
    switchMap(offers => this._selectedChangeOffer$.pipe(startWith(offers.find(offer => offer.defaultSelection)))),
    shareReplay(1)
  )

  public updateSelectedChangeOffer(offer: OfferSelectionCardModel) {
    this.selectedChangeOffer.next(offer)
  }

  /**
   * Special pricing is used for conference / partner deals as well as pricing experimentation and unique
   * pricing per vertical.
   *
   * @param specialPricingValue
   * @param siteId
   * @param isInYearlyPriceVariation CXP-6205 Increase on yearly pricing
   * @private
   */
  private determineSpecialPrice(
    specialPricingValue: string | undefined,
    siteId: number,
    isInYearlyPriceVariation: boolean
  ): string | undefined {
    let specialPrice = specialPricingValue

    if (specialPricingValue === 'conf-partner') {
      // Conference partner special pricing takes precedence
      specialPrice = 'conf-partner'
    } else if (isInYearlyPriceVariation) {
      specialPrice = 'June 2024 Annual Price Increase'
    } else if (siteId === SiteIds.FLOFOOTBALL) {
      // CXP-4067 Football in new architecture - different monthly price from standard
      specialPrice = 'FloFootball Monthly'
    } else if (siteId === SiteIds.FLORACING) {
      specialPrice = '2f422278-63af-4c88-9557-06649eb871ef'
    }

    return specialPrice
  }
}
