<div class="skeleton-container">
  <div class="container bg-unset favorite-skeleton overflow-x-hidden">
    <div class="col-12 d-flex flex-column justify-content-center align-items-center">
      <!-- Title Skeleton -->
      <h1 class="h1-skeleton col-10 col-md-4 pb-4"></h1>
      <!-- Subtitle Skeleton -->
      <h1 class="h1-skeleton col-3 col-md-2 mt-4 mb-5 pb-4"></h1>
      <!-- Search Bar Skeleton -->
      <h1 class="h1-skeleton w-100 mt-1 pb-5"></h1>
      <!-- Favorites List Skeletons -->
      <div class="pt-3 mb-5 d-flex">
        <div class="col-2 d-flex flex-column align-items-center">
          <h1 class="h1-skeleton favorite-skeleton__avatar rounded-circle mb-4"></h1>
          <h1 class="h1-skeleton"></h1>
        </div>
        <div class="col-2 d-flex flex-column align-items-center">
          <h1 class="h1-skeleton favorite-skeleton__avatar rounded-circle mb-4"></h1>
          <h1 class="h1-skeleton"></h1>
        </div>
        <div class="col-2 d-flex flex-column align-items-center">
          <h1 class="h1-skeleton favorite-skeleton__avatar rounded-circle mb-4"></h1>
          <h1 class="h1-skeleton"></h1>
        </div>
        <div class="col-2 d-flex flex-column align-items-center">
          <h1 class="h1-skeleton favorite-skeleton__avatar rounded-circle mb-4"></h1>
          <h1 class="h1-skeleton"></h1>
        </div>
        <div class="col-2 d-flex flex-column align-items-center">
          <h1 class="h1-skeleton favorite-skeleton__avatar rounded-circle mb-4"></h1>
          <h1 class="h1-skeleton"></h1>
        </div>
        <div class="col-2 d-flex flex-column align-items-center">
          <h1 class="h1-skeleton favorite-skeleton__avatar rounded-circle mb-4"></h1>
          <h1 class="h1-skeleton"></h1>
        </div>
      </div>
      <!-- See More Button Skeletons -->
      <h1 class="h1-skeleton col-4 pb-5"></h1>
    </div>
    <!-- Favorite Footer Skeleton -->
    <div class="favorite-skeleton__footer vw-100 position-fixed fixed-bottom d-flex align-items-center flex-column bg-white">
      <div class="container bg-unset d-flex flex-column align-items-start px-5">
        <!-- Favorite Footer Title Skeleton -->
        <div class="col-4 col-md-2 pt-3 pt-md-5 mb-2 mb-md-4 ps-0">
          <h1 class="h1-skeleton"></h1>
        </div>
        <div class="col-12 d-flex flex-column flex-md-row ps-0 justify-content-between">
          <!-- Favorite Footer Avatar Skeleton -->
          <h1 class="h1-skeleton favorite-skeleton__footer-avatar rounded-circle mb-3 mb-md-4"></h1>
          <!-- Favorite Footer Finish Button Skeleton -->
          <h1 class="h1-skeleton col-12 col-md-2 pb-4"></h1>
        </div>
      </div>
    </div>
  </div>
</div>
