import { REQUEST } from '@nguniversal/express-engine/tokens'
import { PlatformService } from './platform.service'
import { Inject, Injectable } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import * as express from 'express'

export interface ILocationService {
  location: string
  href: string | undefined
  pathname: string | undefined
  referrer: string | undefined
  hostname: string | undefined
  reload(): void
}

@Injectable({
  providedIn: 'root'
})
export class LocationService implements ILocationService {
  private req: express.Request
  private doc: HTMLDocument

  constructor(private platformService: PlatformService, @Inject(DOCUMENT) document: any, @Inject(REQUEST) req: any) {
    this.req = req
    this.doc = document
  }

  public get location(): string {
    if (this.platformService.isBrowser) {
      return this.doc.location.origin
    } else {
      return `${this.req.protocol}://${this.req.hostname}`
    }
  }

  public get href(): string {
    if (this.platformService.isBrowser) {
      return this.doc.location.href
    } else {
      return `${this.req.protocol}://${this.req.hostname}${this.req.originalUrl || ''}`
    }
  }

  public set href(url: string) {
    if (this.platformService.isBrowser && url) {
      this.doc.location.href = url
    }
  }

  public get pathname(): string | undefined {
    if (this.platformService.isBrowser) {
      return this.doc.location.pathname
    } else {
      return this.req.path
    }
  }

  public get referrer(): string | undefined {
    if (this.platformService.isBrowser) {
      return this.doc.referrer
    } else {
      return this.req.header('referrer') || ''
    }
  }

  public get hostname(): string | undefined {
    if (this.platformService.isBrowser) {
      return this.doc.location.hostname
    } else {
      return this.req.hostname
    }
  }

  public reload(): void {
    if (this.platformService.isBrowser) {
      this.doc.location.reload()
    }
  }
}
