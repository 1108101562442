import { Inject, Injectable, NgZone, Renderer2 } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { maybe } from 'typescript-monads'
import { asapScheduler, BehaviorSubject, forkJoin, fromEvent, Observable, of, timer, throwError } from 'rxjs'
import { filter, map, mergeMap, take } from 'rxjs/operators'
import { v3 as hashV3 } from 'murmurhash'
import { PlatformService } from './platform.service'

export interface DOMInjectable {
  inHead: boolean
  element: string
  value?: string
  attributes?: { [key: string]: string | boolean }
}

export interface ResourcesState {
  [hash: string]: ResourceStatus
}

export const enum ResourceStatus {
  READY = 'ready',
  ERROR = 'error',
  PENDING = 'pending',
  INJECTED = 'injected',
  TIMEOUT = 'timeout'
}

function createElement<T extends HTMLElement>(renderer: Renderer2, injectable: DOMInjectable): T | undefined {
  if (!injectable || !injectable.element) return undefined
  const elm = renderer.createElement(injectable.element) as T
  const id = hashV3(JSON.stringify(injectable)).toString()

  setElementHash(renderer, elm, id)
  if (injectable.value) renderer.setValue(elm, injectable.value)

  Object.keys(injectable.attributes || {}).forEach(key =>
    renderer.setAttribute(elm, key, (injectable.attributes || {})[key] as any)
  )

  return elm
}

function getElementsHash(el: HTMLElement) {
  return maybe(el.getAttribute('id')).valueOr('')
}

function setElementHash(rd: Renderer2, el: HTMLElement, hash: string) {
  rd.setAttribute(el, 'data-hash', hash)
  rd.setAttribute(el, 'id', hash)
}

export interface IInjectionService {
  inject<T extends HTMLElement = HTMLElement>(renderer: Renderer2, injectable: DOMInjectable): Observable<T>
  injectCollection(
    renderer: Renderer2,
    injectables: ReadonlyArray<DOMInjectable>
  ): Observable<ReadonlyArray<HTMLElement>>
  injectScripts(
    renderer: Renderer2,
    sources: ReadonlyArray<string>,
    inHead: boolean
  ): Observable<ReadonlyArray<HTMLElement>>
  injectExternalStylesheets(
    renderer: Renderer2,
    sources: ReadonlyArray<string>,
    inHead: boolean
  ): Observable<ReadonlyArray<HTMLElement>>
  injectInlineStylesheets(
    renderer: Renderer2,
    styles: ReadonlyArray<string>,
    inHead: boolean
  ): Observable<ReadonlyArray<HTMLElement>>
}

@Injectable({
  providedIn: 'root'
})
export class InjectionService implements IInjectionService {
  private resourcesState$ = new BehaviorSubject<ResourcesState>({})
  private DEFAULT_TIMEOUT_THRESHOLD = 1000 * 60

  private updateResourcesState(hash: string, resourceStatus: ResourceStatus): void {
    const resourcesState = this.resourcesState$.getValue()
    resourcesState[hash] = resourceStatus
    this.resourcesState$.next(resourcesState)
  }

  private getResourceStatus(hash: string): ResourceStatus {
    const resourcesState = this.resourcesState$.getValue()
    const resourceStatus = resourcesState[hash]
    return resourceStatus
  }

  constructor(
    @Inject(DOCUMENT) private doc: HTMLDocument,
    private ngZone: NgZone,
    private readonly platformService: PlatformService
  ) {}

  public inject<T extends HTMLElement = HTMLElement>(renderer: Renderer2, injectable: DOMInjectable): Observable<T> {
    const elm = createElement<T>(renderer, injectable) as HTMLElement
    if (!elm || typeof elm.getAttribute !== 'function') {
      return throwError('InjectionService: cannot inject invalid element')
    }
    const existingElement = this.existing(elm)
    const element = existingElement || elm

    if (!existingElement) {
      injectable.inHead ? renderer.appendChild(this.doc.head, element) : renderer.appendChild(this.doc.body, element)
    }

    const hash = getElementsHash(element)
    const elementStatus = this.getResourceStatus(hash)
    if (!elementStatus) {
      this.updateResourcesState(hash, ResourceStatus.INJECTED)
    }

    const rel = element.getAttribute('rel')
    const src = element.getAttribute('src')
    if (rel === 'icon') {
      // TODO: find a better solution for icons and server-side injected scripts
      // icons don't prevent javascript execution, but they do get cached
      // and sometimes don't emit a load event. Assume for icons that once
      // the dom is injected the icon will load successfully
      this.updateResourcesState(hash, ResourceStatus.READY)
    } else if (!src && !(element as any).href) {
      this.updateResourcesState(hash, ResourceStatus.READY)
    } else {
      fromEvent(element, 'progress')
        .pipe(take(1))
        .subscribe(() => this.updateResourcesState(hash, ResourceStatus.PENDING))

      fromEvent(element, 'load')
        .pipe(take(1))
        .subscribe(() => this.updateResourcesState(hash, ResourceStatus.READY))

      fromEvent(element, 'error')
        .pipe(take(1))
        .subscribe(() => this.updateResourcesState(hash, ResourceStatus.ERROR))

      this.ngZone.runOutsideAngular(() => {
        timer(this.DEFAULT_TIMEOUT_THRESHOLD, asapScheduler)
          .pipe(take(1))
          .subscribe(() => this.timeoutElement(element))
      })
    }

    return this.resourcesState$.pipe(
      map((resourcesState: ResourcesState) => resourcesState[hash]),
      mergeMap(() => this.throwIfFailed(element)),
      filter((resourceStatus: ResourceStatus) => resourceStatus === ResourceStatus.READY),
      map(() => element as T)
    )
  }

  private timeoutElement(element: HTMLElement): void {
    const hash = getElementsHash(element)
    const resourceStatus = this.getResourceStatus(hash)
    // TODO: check if resource contents have already loaded and we missed the load event (ie. favicon)
    if (![ResourceStatus.READY, ResourceStatus.ERROR].includes(resourceStatus)) {
      this.updateResourcesState(hash, ResourceStatus.TIMEOUT)
    }
  }

  private throwIfFailed(element: HTMLElement): Observable<ResourceStatus> {
    const hash = getElementsHash(element)
    const resourceStatus = this.getResourceStatus(hash)
    if (this.platformService.isBrowser && [ResourceStatus.ERROR, ResourceStatus.TIMEOUT].includes(resourceStatus)) {
      return throwError(
        `InjectionService: Resource failed to load: id - ${hash} - ${(element as any).src || (element as any).href}`
      )
    } else {
      return of(resourceStatus)
    }
  }

  private existing(elm: HTMLElement) {
    return this.doc.getElementById(getElementsHash(elm))
  }

  public injectCollection(
    renderer: Renderer2,
    injectables: ReadonlyArray<DOMInjectable>
  ): Observable<readonly HTMLElement[]> {
    return forkJoin(injectables.map(injectable => this.inject(renderer, injectable).pipe(take(1))))
  }

  public injectScripts(
    renderer: Renderer2,
    sources: ReadonlyArray<string>,
    inHead = false
  ): Observable<readonly HTMLElement[]> {
    const injectables = sources.map(source => {
      return {
        inHead,
        element: 'script',
        attributes: {
          src: source,
          type: 'text/javascript'
        }
      }
    })
    return this.injectCollection(renderer, injectables)
  }

  public injectExternalStylesheets(renderer: Renderer2, sources: ReadonlyArray<string>, inHead = false) {
    const injectables = sources.map(source => {
      return {
        inHead,
        element: 'link',
        attributes: {
          href: source,
          rel: 'stylesheet'
        }
      }
    })
    return this.injectCollection(renderer, injectables)
  }

  public injectInlineStylesheets(renderer: Renderer2, styles: ReadonlyArray<string>, inHead = false) {
    const injectables = styles.map(value => {
      return {
        inHead,
        value,
        element: 'style'
      }
    })
    return this.injectCollection(renderer, injectables)
  }
}
