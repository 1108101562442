import { Directive, OnDestroy, OnInit } from '@angular/core'
import { HostListener } from '@angular/core'
import { Input } from '@angular/core'
import { combineLatest, Observable, ReplaySubject, Subject } from 'rxjs'
import { filter, takeUntil } from 'rxjs/operators'

@Directive({
  selector: '[floKeyboardControls]'
})
export class VideoKeyboardControlsDirective implements OnInit, OnDestroy {
  private isActiveVideo$ = new ReplaySubject<boolean>(1)
  private videoElement$ = new ReplaySubject<VideoElementControl>(1)
  private readonly ngOnDestroy$ = new Subject<void>()

  @Input()
  public set videoActive(isActive: boolean) {
    if (isActive != null) {
      this.isActiveVideo$.next(isActive)
    }
  }

  @Input('videoHTMLElement')
  public set videoElement(element: VideoElementControl) {
    this.videoElement$.next(element)
  }

  public keyboardEvent$: ReplaySubject<KeyboardEventControl> = new ReplaySubject(1)

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEventControl) {
    this.keyboardEvent$.next(event)
  }

  ngOnInit() {
    combineLatest([this.isActiveVideo$, this.videoElement$, this.keyboardEvent$])
      .pipe(
        filter(([isSelectedVideo]) => isSelectedVideo),
        takeUntil(this.ngOnDestroy$)
      )
      .subscribe(([selectedVideo, videoElement, keyboardEvent]) => {
        handleKeyboardEvents(videoElement, keyboardEvent)
      })
  }

  ngOnDestroy() {
    this.ngOnDestroy$.next()
    this.ngOnDestroy$.complete()
  }
}

export const handleKeyboardEvents = (videoElem: VideoElementControl, keyboardEvent: KeyboardEventControl): void => {
  switch (keyboardEvent.code) {
    case 'ArrowLeft':
      keyboardEvent.preventDefault()
      // scrub
      videoElem.currentTime = videoElem.currentTime - 5
      break
    case 'ArrowRight':
      keyboardEvent.preventDefault()
      // scrub
      videoElem.currentTime = videoElem.currentTime + 5
      break
    case 'Space':
      keyboardEvent.preventDefault()
      // pause
      !videoElem.paused ? videoElem.pause() : videoElem.play()
      break
    case 'KeyM':
      keyboardEvent.preventDefault()
      // mute
      videoElem.muted = !videoElem.muted
      break
    case 'KeyF':
      keyboardEvent.preventDefault()
      // fullscreen; ESC already works for exiting fullscreen
      videoElem.requestFullscreen()
  }
}

export interface KeyboardEventControl extends Partial<KeyboardEvent> {
  code: string
  preventDefault(): void
}

export interface VideoElementControl extends Partial<HTMLVideoElement> {
  requestFullscreen(): Promise<void>
  pause(): void
  play(): Promise<void>
  currentTime: number
  muted: boolean
  paused: boolean
}
