import { Injectable } from '@angular/core'
import {
  CouponErrorCondition,
  CouponService,
  ICoupon,
  isCoupon,
  isCouponError
} from '../../shared/services/coupon.service'
import { combineLatest, Observable } from 'rxjs'
import { map, shareReplay } from 'rxjs/operators'
import { AuthService } from '../../singleton-services/auth/auth.service'
import { CouponStatusUtility } from './coupon-status.utility'

const makeSavingsString = (coupon: ICoupon, isApplied: boolean): string => {
  const savings = coupon.amount_off ? `$${Number(coupon.amount_off.toFixed(2)) / 100}` : `${coupon.percent_off}%`

  return isApplied ? `${savings} discount was applied.` : `${savings} discount will be applied at checkout.`
}

export enum CouponStatus {
  NONE,
  VALID,
  ERROR
}

@Injectable()
export class CouponStatusService {
  public readonly couponStatus$: Observable<CouponStatus> = this.couponService.couponOrError$.pipe(
    map(coupon => {
      if (coupon === undefined) return CouponStatus.NONE
      if (isCouponError(coupon)) return CouponStatus.ERROR
      if (isCoupon(coupon)) return CouponStatus.VALID

      throw new Error('Unable to project coupon status. Invalid coupon output.')
    }),
    shareReplay(1)
  )

  public readonly showCoupon$: Observable<boolean> = combineLatest([
    this.couponStatus$,
    this.couponService.couponRouteData$,
    this.auth.isPremium$,
    this.couponStatusUtility.hideCouponError$
  ]).pipe(
    map(([status, couponRouteData, isPremium, hideError]) => {
      return (
        status !== CouponStatus.NONE &&
        !isPremium &&
        couponRouteData &&
        couponRouteData.showRibbon &&
        !(status === CouponStatus.ERROR && hideError)
      )
    }),
    map(showCoupon => (showCoupon === undefined ? false : showCoupon)),
    shareReplay(1)
  )

  public readonly hasBeenApplied$: Observable<boolean> = this.couponService.couponRouteData$.pipe(
    map(data => data && data.hasBeenApplied),
    map(hasBeenApplied => (hasBeenApplied === undefined ? false : hasBeenApplied))
  )

  public constructor(
    private readonly couponService: CouponService,
    private readonly auth: AuthService,
    private readonly couponStatusUtility: CouponStatusUtility
  ) {}

  public message$: Observable<string | undefined> = combineLatest([
    this.couponService.couponOrError$,
    this.hasBeenApplied$
  ]).pipe(
    map(([coupon, hasBeenApplied]) => {
      if (coupon === undefined) return undefined
      if (isCouponError(coupon)) {
        switch (coupon.condition) {
          case CouponErrorCondition.EXPIRED:
            return 'This discount offer is no longer valid.'
          case CouponErrorCondition.NOT_FOUND:
            return 'That coupon does not exist.'
          default:
            return undefined
        }
      }
      if (isCoupon(coupon)) return makeSavingsString(coupon, hasBeenApplied)
      return undefined
    }),
    shareReplay(1)
  )
}
