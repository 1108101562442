import { Directive, HostBinding, HostListener, Input } from '@angular/core'
import { Router } from '@angular/router'

@Directive({
  selector: 'a[floCustomLink]'
})
export class CustomLinkDirective {
  @HostBinding('href') public href = ''
  @HostBinding('target') target = ''

  // Sets url to show on mouse hover
  @Input('floCustomLink')
  set floCustomLink(url: string) {
    this.href = url
  }

  /**
   * If query params are provided separately from the url, we use navigate instead of navigateByUrl
   */
  @Input()
  customLinkQueryParams?: Record<string, string>

  constructor(private router: Router) {}

  @HostListener('click', ['$event.button', '$event.ctrlKey', '$event.metaKey'])
  public onClick(): boolean {
    if (!this.href) return false

    if (!this.href.startsWith('http')) {
      if (!!this.customLinkQueryParams) {
        this.router.navigate([this.href], { queryParams: this.customLinkQueryParams })
      } else {
        this.router.navigateByUrl(this.href, {})
      }

      // returning false prevents the click action from occurring
      return false
    }

    this.target = '_blank'
    return true
  }
}
