import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Inject, Injectable, NgZone, Optional } from '@angular/core'
import { Router } from '@angular/router'
import { JwtHelperService } from '@auth0/angular-jwt'
import { BehaviorSubject, Observable, Scheduler, combineLatest, of, throwError, timer } from 'rxjs'
import {
  catchError,
  distinctUntilChanged,
  filter,
  flatMap,
  map,
  shareReplay,
  switchMap,
  take,
  tap
} from 'rxjs/operators'
import {
  CORE_API_REFRESH_TOKENS_PATH,
  CORE_API_TOKENS_PATH,
  CORE_API_USERS_PATH,
  RXJS_SCHEDULER
} from 'src/app/app.config'
import { WINDOW } from 'src/app/app.injections'
import { LOGGER_SERVICE } from 'src/app/logger/logger.config'
import { LoggerService } from 'src/app/logger/logger.interface'
import { APIAnalyticsData } from '../../shared/interfaces/segment-data.interface'
import { FacebookTokenInterface } from '../../shared/services/facebook-access-token.service'
import { ITokenResponse } from '../../shared/services/user.service'
import { StripeSubscriptionStatus, checkSubscriptionStatus } from '../../shared/subscriptions/subscriptions.common'
import { CookieService } from '../cookie.service'
import { EnvironmentService } from '../environment.service'
import { PlatformService } from '../platform.service'
import { VerticalService } from '../vertical.service'
import {
  EntitlementResource,
  EntitlementsResponse,
  IAuthService,
  IAuthServiceConfig,
  IUser,
  IUserIdentity,
  ResourceType,
  getHighestTierForUser
} from './auth.interfaces'
import { AUTH_CONFIG } from './auth.tokens'
import { SiteSettings } from 'src/app/shared/models/site-settings.model'
import { notNullOrUndefined } from 'src/app/shared/functions/type-guards'
import { PartialsDataService } from '../partials/partials-data.service'

@Injectable({
  providedIn: 'root'
})
export class AuthService implements IAuthService {
  private jwtHelper = new JwtHelperService()
  private TIMED_REFRESH_INTERVAL = 3600000 // Every 1 hr.
  public userIdentitySource = new BehaviorSubject<IUserIdentity | undefined>(this.getUserFromStoredToken())
  public userIdentity$ = this.userIdentitySource.pipe(shareReplay(1))

  constructor(
    @Inject(AUTH_CONFIG) private config: IAuthServiceConfig,
    private cookieService: CookieService,
    private env: EnvironmentService,
    private vs: VerticalService,
    private router: Router,
    private http: HttpClient,
    private ps: PlatformService,
    private partialsDataService: PartialsDataService,
    @Inject(LOGGER_SERVICE) private readonly logger: LoggerService,
    @Inject(WINDOW) private readonly window: Window,
    private zone: NgZone,
    @Optional()
    @Inject(RXJS_SCHEDULER)
    scheduler: Scheduler
  ) {
    if (!config) {
      throw new Error('Missing config')
    }
    if (!config.tokenSchema) {
      throw new Error('Missing config.tokenSchema')
    }
    if (!config.authTokenStorageKey) {
      throw new Error('Missing config.authTokenStorageKey')
    }

    if (this.ps.isBrowser) {
      // Has JWT Token but no Refresh Token
      if (this.getValidToken(this.getTokenFromStore()) && !this.getRefreshTokenFromStore()) {
        this.logout()
      }

      this.cookieService.cookies$.pipe(map(cookies => cookies?.[this.config.authTokenStorageKey])).subscribe(token => {
        this.updateUser(this.getValidToken(token))
      })

      this.zone
        .runOutsideAngular<Observable<IUserIdentity | undefined>>(() =>
          timer(0, this.TIMED_REFRESH_INTERVAL, scheduler).pipe(flatMap(() => this.refreshViaToken()))
        )
        .subscribe()
    }
  }

  /*** ENTITLEMENTS METHODS ***/

  /**
   * Check if user is entitled, or "has access" to a specific resource
   */
  public hasAccess(resource: EntitlementResource, type: ResourceType): Observable<boolean> {
    if (!resource.premium) {
      return of(true)
    }

    return this.user$.pipe(
      switchMap(user => {
        if (!user || !user.loggedIn) return of(false)
        return this.getEntitlements(type, resource.id)
      })
    )
  }

  /**
   * Check if a user has a premium subscription
   */
  private userHasPremiumAccess$: Observable<boolean> = combineLatest([this.vs.siteId$, this.userIdentity$]).pipe(
    switchMap(([siteId, userIdentity]) => {
      return !!userIdentity ? this.getEntitlements(ResourceType.Site, siteId) : of(false)
    }),
    shareReplay(1)
  )

  /**
   * Call /:type/:nodeId entitlements endpoint
   *
   * Bearer Token auth headers added in middleware, no need to include here
   */
  private getEntitlements(resource: ResourceType, nodeId: string | number) {
    const url = `${this.env.config.endpoints.entitlements}/${resource}/${nodeId}`
    return this.http.get<EntitlementsResponse>(url).pipe(
      map(_ => true),
      catchError((error: HttpErrorResponse) => {
        // If user is not entitled to content, Entitlements returns 403
        // If the user is not logged in, Entitlements returns 401
        if (error.status !== 403 && error.status !== 401) {
          this.logger.error('Error accessing Entitlements Service', { error })
          // TODO update when vertical specific entitlements are enabled - https://flocasts.atlassian.net/browse/CXP-5095
          return this.partialsDataService.getSubscriptionsListPartial(true).pipe(
            map(subs => (!!subs && !!subs.data ? subs?.data?.items?.length > 0 : false)),
            catchError(_ => {
              // If there is an error from the fallback partialsDataService call,
              // simply return false here.
              return of(false)
            })
          )
        }
        return of(false)
      })
    )
  }

  /* END OF ENTITLEMENTS METHODS */

  public user$: Observable<IUser> = combineLatest([this.userIdentity$, this.userHasPremiumAccess$]).pipe(
    map(([userIdentity, isPremium]) => ({
      id: userIdentity?.id,
      loggedIn: !!userIdentity,
      isAdmin: !!userIdentity?.isAdmin() ?? false,
      isPremium,
      subscriptionStatus: userIdentity?.subscriptionStatus,
      analyticsData: !!userIdentity
        ? {
            ...userIdentity?.analyticsData,
            // overriding the account_type property here with
            // the value from the entitlements service
            account_type: isPremium ? 'Premium' : 'Free',
            id: userIdentity?.id
          }
        : undefined
    })),
    shareReplay(1)
  )

  public loggedIn$ = this.user$.pipe(
    map(user => !!user.loggedIn),
    distinctUntilChanged(),
    shareReplay(1)
  )
  public isAdmin$ = this.user$.pipe(
    map(user => user.isAdmin),
    distinctUntilChanged(),
    shareReplay(1)
  )
  public isPremium$ = this.user$.pipe(
    map(user => user.isPremium),
    distinctUntilChanged(),
    shareReplay(1)
  )
  public analyticsData$ = this.user$.pipe(
    map(user => user.analyticsData),
    filter(notNullOrUndefined),
    distinctUntilChanged(),
    shareReplay(1)
  )

  public userTier$ = this.user$.pipe(
    filter(Boolean),
    map(getHighestTierForUser),
    distinctUntilChanged(),
    shareReplay(1)
  )

  public userIsPastDue$ = this.user$.pipe(
    map(user => user.subscriptionStatus),
    map(status => checkSubscriptionStatus(StripeSubscriptionStatus.PAST_DUE, status)),
    distinctUntilChanged(),
    shareReplay(1)
  )

  public refreshViaToken(): Observable<IUserIdentity | undefined> {
    const token = this.getRefreshTokenFromStore()

    return token
      ? this.authorizeViaHttp(this.http.post<ITokenResponse>(CORE_API_REFRESH_TOKENS_PATH, { token })).pipe(
          catchError(() => of(undefined))
        )
      : of(undefined)
  }

  public getTokenFromStore(): string {
    return this.cookieService.get(this.config.authTokenStorageKey)
  }

  public getRefreshTokenFromStore(): string {
    return this.cookieService.get(this.config.authTokenRefreshStorageKey)
  }

  public logout(redirect?: string) {
    this.cookieService.remove(this.config.authTokenStorageKey)
    this.cookieService.remove(this.config.authTokenRefreshStorageKey)
    this.cookieService.remove('impactCustomerId')
    this.cookieService.remove('impactCustomerEmail')
    this.cookieService.remove('subscription_start_date')

    if (this.ps.isBrowser && !!redirect) {
      this.window.location.href = redirect
    } else {
      this.router.navigateByUrl(redirect || '/')
    }
  }

  public getValidToken(token: string | undefined): any {
    if (!token) {
      return undefined
    }
    try {
      const decodedToken = this.jwtHelper.decodeToken(token)
      return decodedToken && !this.jwtHelper.isTokenExpired(token) ? decodedToken : undefined
    } catch (e) {
      this.logger.error('Failed to decode JWT', { error: e, token })
      return undefined
    }
  }

  private updateUser(decodedToken: any): void {
    decodedToken
      ? this.userIdentitySource.next(this.config.userFactory(decodedToken, this.config.tokenSchema))
      : this.userIdentitySource.next(undefined)
  }

  //  TODO: Figure out a solution to not use this directly on guards. ONLY use directly in case of emergency
  public getUserFromStoredToken(): IUserIdentity | undefined {
    const token = this.getValidToken(this.getTokenFromStore())
    return token ? this.config.userFactory(token, this.config.tokenSchema) : undefined
  }

  public setToken(rawToken: string): Observable<IUserIdentity | undefined> {
    if (rawToken) {
      this.setTokenRaw(rawToken)
      return of(this.getUserFromStoredToken())
    } else {
      return of(undefined)
    }
  }

  private setTokenRaw(rawToken: string, _domain?: string) {
    const expires = this.getTokenExpirationDate(rawToken)

    if (!expires) return

    this.cookieService.set(this.config.authTokenStorageKey, rawToken, {
      secure: this.env.config.useSecureCookies,
      path: '/',
      expires
    })
  }

  getTokenExpirationDate(rawToken: string): Date | null {
    return this.jwtHelper.getTokenExpirationDate(rawToken)
  }

  public setRefreshToken(token: string, expiresTime: number): Observable<undefined> {
    if (!token || !expiresTime) {
      return of(undefined)
    }

    return this.vs.tokenDomain$.pipe(
      map(domain => {
        this.setRefreshTokenRaw(token, expiresTime)
        return undefined
      })
    )
  }

  private setRefreshTokenRaw(rawToken: string, expiry: number, _domain?: string) {
    this.cookieService.set(this.config.authTokenRefreshStorageKey, rawToken, {
      secure: this.env.config.useSecureCookies,
      path: '/',
      expires: new Date(expiry * 1000)
    })
  }

  public authorizeViaHttp(tokenResponse: Observable<ITokenResponse>): Observable<IUserIdentity | undefined> {
    return tokenResponse.pipe(
      tap(tokenData =>
        this.setRefreshToken(tokenData.refresh_token, tokenData.refresh_token_exp).pipe(take(1)).subscribe()
      ),
      flatMap(tokenData => {
        // Set the jwt_token then set the refresh_token
        // TODO: Refactor to not use `this.refreshing` flag which is needed because listening to cookies$
        return this.setToken(tokenData.token)
      })
    )
  }

  public signupUser(
    email: string,
    username: string,
    password: string,
    captchaToken: string,
    mileSplitRefCode?: string,
    mileSplitSiteId?: string,
    segmentData?: APIAnalyticsData
  ): Observable<IUserIdentity | undefined> {
    const body = {
      email,
      username,
      plain_password: password,
      ms_ref_code: mileSplitRefCode,
      ms_sub_site_id: mileSplitSiteId,
      segment_data: segmentData,
      captcha: captchaToken
    }

    return this.authorizeViaHttp(this.http.post<ITokenResponse>(CORE_API_USERS_PATH, body)).pipe(
      catchError(err => {
        if (err.status < 200 || err.status > 404) {
          if (err.status === 401) {
            return throwError(err)
          } else {
            return throwError(err)
          }
        }
        return throwError(err)
      })
    )
  }

  public generateToken(email: string, password: string): Observable<ITokenResponse> {
    return this.http.post<ITokenResponse>(CORE_API_TOKENS_PATH, { email, password })
  }

  public facebookLogin(
    authResponse: FacebookTokenInterface | undefined,
    mileSplitRefCode?: string,
    mileSplitSiteId?: string
  ): Observable<
    | {
        user: IUserIdentity
        facebook_id: number
        new_facebook_user?: boolean
      }
    | undefined
  > {
    if (!authResponse) return of(undefined)
    return this.vs.siteSettings$.pipe(
      flatMap((settings: any) => {
        const body = {
          token: authResponse.accessToken,
          ms_ref_code: mileSplitRefCode,
          ms_sub_site_id: mileSplitSiteId
        }
        return this.http.post(`${settings.api_url}/api/facebook-tokens?site_id=${settings.site_id}`, body).pipe(
          flatMap((res: ITokenResponse) =>
            this.setRefreshToken(res.refresh_token, res.refresh_token_exp).pipe(map(() => res))
          ),
          flatMap((res: ITokenResponse) =>
            this.setToken(res.token).pipe(
              map((user: IUserIdentity | undefined) => {
                return { user, res }
              })
            )
          ),
          filter((userTokenObject: { user: IUserIdentity; res: ITokenResponse }) => userTokenObject.user !== undefined),
          map((userTokenObject: { user: IUserIdentity; res: ITokenResponse }) => {
            return {
              user: userTokenObject.user,
              facebook_id: authResponse.userID,
              new_facebook_user: userTokenObject.res.user.new_facebook_user
            }
          }),
          catchError(err => {
            if (err.status < 200 || err.status > 404) {
              if (err.status === 401) {
                return throwError(err)
              } else {
                return throwError(err)
              }
            }
            return throwError(err)
          })
        )
      })
    )
  }

  public googleLogin(googleAuthToken: string): Observable<{ user: IUserIdentity }> {
    return this.vs.siteSettings$.pipe(
      flatMap((settings: SiteSettings) => {
        return this.http.post<ITokenResponse>(`${settings.api_url}/api/google-tokens`, { token: googleAuthToken }).pipe(
          flatMap((res: ITokenResponse) => {
            return this.setRefreshToken(res.refresh_token, res.refresh_token_exp).pipe(map(() => res))
          }),
          flatMap((res: ITokenResponse) =>
            this.setToken(res.token).pipe(
              map((user: IUserIdentity | undefined) => {
                return { user, res }
              })
            )
          ),
          filter((userTokenObject: { user: IUserIdentity; res: ITokenResponse }) => userTokenObject.user !== undefined),
          map((userTokenObject: { user: IUserIdentity; res: ITokenResponse }) => {
            return {
              user: userTokenObject.user
            }
          }),
          catchError(err => {
            console.log('FLO AUTHENTICATION FAILED')
            return throwError(err)
          })
        )
      })
    )
  }
}
