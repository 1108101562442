import { PersonNode } from '../../shared/models/node.model'
import { ArticleSchema } from './article-structured-data.model'
import { CanonicalLinkElement, StructuredSchemaElement } from './head-element.interface'
import { IVideo } from '../../shared/models/video.model'
import { VideoStructuredSchema } from './video-structured-data.interface'
import { LiveEventSchemaProps, LiveEventStructuredSchema } from './live-event-structured-data.interface'
import { HasShareableOrExternalLink } from './structured-data.interface'
import { formatISODuration } from 'date-fns'
import { PersonSchema } from './person-schema.interface'
import { ArticleMeta, TAG_DATA_ATTRIBUTE, TAG_DATA_ATTRIBUTE_VALUE } from '../../libs/page-meta/page-meta.interface'
import type { LiveEvent } from '@flocasts/flosports30-types/dist/entity'
import { HtmlMetaElement } from '@flocasts/experience-service-types'
import { SiteSettings } from 'src/app/shared/models/site-settings.model'

export const getAuthorType = (name: string): string => {
  const nameLowerCase = name.toLowerCase()
  if (
    nameLowerCase === null ||
    nameLowerCase === '' ||
    nameLowerCase.includes('flosports') ||
    (nameLowerCase.startsWith('flo') && nameLowerCase.endsWith('staff'))
  ) {
    return 'Organization'
  }
  return 'Person'
}

export const getAuthorName = (first: string, last: string) => {
  return `${first} ${last}`
}

/**
 * new function for formatting article schema data using flosports30-types Article and page-meta.service
 */
export const formatArticleMetaSchema = (article: ArticleMeta): string => {
  const DEFAULT_DATE = '2020-01-01'

  const authorName = getAuthorName(article.authorFirstname ?? 'Flosports', article.authorLastname ?? 'Staff')

  const schema: ArticleSchema = {
    '@context': 'https://schema.org/',
    '@type': 'NewsArticle',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': article.canonicalUrl
    },
    identifier: `${article.id}`,
    headline: article.title,
    image: [article.image.url],
    datePublished: article.datePublished ?? DEFAULT_DATE,
    dateModified: article.modifiedAt ?? DEFAULT_DATE,
    author: {
      '@type': getAuthorType(authorName),
      name: authorName
    },
    publisher: {
      '@type': 'Organization',
      name: 'FloSports',
      logo: {
        '@type': 'ImageObject',
        url: 'https://www.flosports.tv/wp-content/uploads/2019/09/FloSports-Hawk.png'
      }
    },
    description: article.description
  }

  const isPremium: boolean | undefined = 'premium' in article && article.premium
  if (isPremium) {
    schema.isAccessibleForFree = 'False'
    schema.hasPart = {
      '@type': 'WebPageElement',
      isAccessibleForFree: 'False',
      cssSelector: 'content-paywall'
    }
  }
  return JSON.stringify(schema)
}

const getAssetUrl = (asset: { url: string } | undefined): string => {
  return !!asset && asset.url ? asset.url : DEFAULT_IMG
}

const getVideoThumbnailUrl = (video: IVideo): string => {
  return (
    (!!video.asset && video.asset.url) ||
    (!!video.node && !!video.node.site && video.node.site.hero_image) ||
    DEFAULT_IMG
  )
}

export const formatLiveEventSchema = (liveEvent: LiveEventSchemaProps): string => {
  const schema: LiveEventStructuredSchema = {
    '@context': 'https://schema.org/',
    '@type': 'VideoObject',
    description: (liveEvent.seo_description as string) || `Watch ${liveEvent.title} live!`,
    name: liveEvent.title,
    thumbnailUrl: getAssetUrl(liveEvent.asset),
    uploadDate: liveEvent.start_date_time || new Date().toString(),
    duration: 'PT12H1M55S', // fake it
    publication: {
      '@type': 'BroadcastEvent',
      name: liveEvent.title,
      isAccessibleForFree: false,
      isLiveBroadcast: true,
      startDate: liveEvent.start_date_time,
      endDate: liveEvent.end_date_time
    }
  }

  if (liveEvent.siteName) {
    schema.publication.publishedBy = liveEvent.siteName

    if (liveEvent.siteName.toLowerCase() === 'floracing') {
      schema.contentURL = 'https://d6fm3yzmawlcs.cloudfront.net/funnelAssets/videos/floracing_signup.mp4'
    }
  }

  return JSON.stringify(schema)
}

/**
 * Formats the JSON+LD for videos, see
 * @see VideoObject {@link https://developers.google.com/search/docs/data-types/video#structured-data-type-definitions}
 * @see {@link https://support.google.com/webmasters/answer/156442#file-types}
 */
export const formatVideoSchema = (video: IVideo): string => {
  const durationInt = video.duration > 0 ? Math.floor(video.duration) : 30
  const schema: VideoStructuredSchema = {
    '@context': 'http://schema.org/',
    '@type': 'VideoObject',
    description: video.description_plain || video.title,
    name: video.title,
    thumbnailUrl: getVideoThumbnailUrl(video),
    uploadDate: video.publish_start_date || new Date().toString(),
    contentUrl: video.playlist,
    duration: formatISODuration({ seconds: durationInt || 0 }),
    interactionStatistic: {
      '@type': 'InteractionCounter',
      interactionType: {
        '@type': 'http://schema.org/WatchAction'
      },
      userInteractionCount: video.views
    }
  }
  if ('premium' in video && video.premium === true) {
    schema.isAccessibleForFree = 'False'
    schema.hasPart = {
      '@type': 'WebPageElement',
      isAccessibleForFree: 'False',
      cssSelector: 'content-paywall'
    }
  }
  return JSON.stringify(schema)
}

/**
 * Formats the ld+json for persons
 * @see Person {@link https://schema.org/Person}
 */
export const formatPersonSchema = (person: PersonNode): string => {
  const name = person.name || person.title
  const schema: PersonSchema = {
    '@context': 'https://schema.org/',
    '@type': 'Person',
    name,
    description: person.description,
    image: getAssetUrl(person.asset),
    url: person.shareable_link || '',
    gender: person.gender === 'f' ? 'Female' : 'Male',
    givenName: name.split(' ')[0],
    familyName: name.split(' ').length > 1 ? name.split(' ')[1] : undefined,
    sameAs: person.wikimedia_url || undefined
  }
  return JSON.stringify(schema)
}

/**
 * Formats the ld+json for the WebSite usage on homepage
 * @see WebSite {@link https://schema.org/WebSite}
 * @link https://developers.google.com/search/docs/appearance/site-names
 */
export const formatWebsiteSchema = (name?: string, url?: string): string => {
  const schema = {
    '@context': 'https://schema.org/',
    '@type': 'WebSite',
    name: name || 'FloSports',
    url: url || ''
  }
  return JSON.stringify(schema)
}

/**
 * to add another custom html element to the HEAD, create a css ID for it and return an IHeadElement of it
 */
export const DEFAULT_IMG = 'https://d6fm3yzmawlcs.cloudfront.net/ogImages/FloLive-1920x1080.jpg'
export const CANONICAL_ID = 'canonical'
export const STRUCTURED_SCHEMA_ID = 'structuredSchema'
export const SCHEMA_WEBSITE = 'schema-website'

/**
 * @todo This will be replaced by API returning slug_uri for live events in another ticket
 * @see {@link https://flocasts.atlassian.net/browse/FLO-12209}
 */
export const addSlugToUrl = (url: string, slug: string): string => {
  return `${url}-${slug}`
}

/**
 * Build a canonical url HeadElement object
 *
 * @example
 * <link id="canonical" rel="canonical" href="https://www.flodogs.com/live/123-best-doggo">
 */
export const buildCanonicalUrl = (canonicalUrl: string): CanonicalLinkElement => {
  return {
    id: CANONICAL_ID,
    tag: 'link',
    attributes: [
      {
        name: 'rel',
        value: 'canonical'
      },
      {
        name: 'href',
        value: canonicalUrl
      },
      {
        name: TAG_DATA_ATTRIBUTE,
        value: TAG_DATA_ATTRIBUTE_VALUE
      }
    ]
  }
}

export const getCanonicalUrl = (node: HasShareableOrExternalLink): CanonicalLinkElement | undefined => {
  if (!node.shareable_link && !node.external_url) return
  // If external_url is present, we want to use that to construct the canonicalurl
  // Otherwise, use shareable_link
  const canonicalUrl = (node.external_url ?? node.shareable_link) as string
  return buildCanonicalUrl(canonicalUrl)
}

export function makeBaseSchema(altId: string | undefined): StructuredSchemaElement {
  return {
    id: altId || STRUCTURED_SCHEMA_ID,
    tag: 'script',
    innerText: '',
    attributes: [
      {
        name: 'type',
        value: 'application/ld+json'
      }
    ]
  }
}

export const buildHomeSchema = (siteName?: string, siteUrl?: string) => {
  const schema = makeBaseSchema(SCHEMA_WEBSITE)
  schema.innerText = formatWebsiteSchema(siteName, siteUrl)
  return schema
}

export const generateArticleSchema = (article: ArticleMeta, altId?: string): StructuredSchemaElement => {
  const schema = makeBaseSchema(altId)
  schema.innerText = formatArticleMetaSchema(article)
  return schema
}

export const buildVideoSchema = (video: IVideo, altId?: string): StructuredSchemaElement => {
  const schema = makeBaseSchema(altId)
  schema.innerText = formatVideoSchema(video)
  return schema
}

export const buildPersonSchema = (person: PersonNode, altId?: string): StructuredSchemaElement => {
  const schema = makeBaseSchema(altId)
  schema.innerText = formatPersonSchema(person)
  return schema
}

export const STRUCTURED_LIVE_EVENT_SCHEMA_ID = 'liveEventStructuredSchema'

export const buildLiveEventSchema = (liveEvent: LiveEventSchemaProps): StructuredSchemaElement => {
  const schema = makeBaseSchema(STRUCTURED_LIVE_EVENT_SCHEMA_ID)
  const formattedSchema = formatLiveEventSchema(liveEvent)

  schema.innerText = formattedSchema
  return schema
}

// overwrite the canonical URL with a generic one (ex: 'signup' or 'login')
// unless it is a live event
export function createGenericCanonicalUrl(
  genericEndpoint: string,
  siteSettings: SiteSettings,
  liveEvent: LiveEvent | undefined
) {
  return liveEvent && liveEvent.live_event_url && liveEvent.slug
    ? addSlugToUrl(liveEvent.live_event_url, liveEvent.slug)
    : `${siteSettings.core_site_url}/${genericEndpoint}`
}

export function mapSchemaTypeFromBFF(schema: HtmlMetaElement): StructuredSchemaElement {
  return {
    id: STRUCTURED_SCHEMA_ID,
    tag: schema.tag === 'script' ? schema.tag : 'script',
    attributes: [{ name: 'type', value: 'application/ld+json' }],
    innerText: schema.innerText || ''
  }
}
