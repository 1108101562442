import { GlobalErrorHandler } from './singleton-services/error-handler.service'
import { SharedModule } from './shared/shared.module'
import { AppRoutingModule } from './app-routing.module'
import { FunnelModule } from './funnel/funnel.module'
import { NotFoundModule } from './not-found/not-found.module'
import { EnvironmentService } from './singleton-services/environment.service'
import { AppComponent } from './app.component'
import { BrowserModule, makeStateKey } from '@angular/platform-browser'
import { VerticalService } from './singleton-services/vertical.service'
import { AdBlockService } from '@flosportsinc/ng-ad-block'
import { ErrorHandler, Injector, NgModule } from '@angular/core'
import { HttpClientModule } from '@angular/common/http'
import { UrlSerializer } from '@angular/router'
import { CustomUrlSerializer } from './shared/services/url-serializer.service'
import { AnalyticsModule } from './shared/analytics/analytics.module'
import { SegmentService } from './shared/analytics/services/segment.service'
import { HomeModule } from './home/home.module'
import { NgxWebstorageModule } from 'ngx-webstorage'
import { MetaLoader, MetaModule, MetaStaticLoader, PageTitlePositioning } from 'meta'
import { FloQueryParamsModule } from './shared/queryparams/queryparams.module'
import { FloPerformanceMetricsModule } from './shared/performance/performance-metrics.module'
import { FLO_PERF_TRACKING_PROVIDER } from './shared/performance/performance-metrics.tokens'
import { IPerformance } from './shared/performance/performance-metrics.utility'
import { SegmentEvents } from './shared/analytics/models/segment-events.model'
import { TabsModule } from './libs/common/tabs/tabs.module'
import { RXJS_SCHEDULER } from './app.config'
import { FloDebugModule } from './shared/debug/debug.module'
import { EVENT_HUB_GET_PROVIDER } from './funnel/signup/signup.token'
import { EventsService } from './events/services/events.service'
import { SkeletonModule } from './libs/skeletons/skeleton/skeleton.module'
import { FirebaseModule } from './shared/firebase/firebase.module'
import { CLIENT_CACHE_BYPASS_REGEX } from './shared/services/http-client-transfer-state.interceptor'
import { EventTickerModule } from './libs/event-ticker/event-ticker.module'
import { RecaptchaV3Module, RECAPTCHA_BASE_URL, RECAPTCHA_V3_SITE_KEY } from '@flocasts/ng-recaptcha'
import { environment } from 'src/environments/environment'
import { OLD_SVG_REQUEST_PATTERN } from './libs/_deprecated/svg/old-svg-loader.service'
import { SiteNavigationModule } from './libs/site-navigation/site-navigation.module'
import { FloMseModule } from './hls/mse.module'
import { FloSanitizeResourceUrlModule } from './libs/_pipes/sanitize-url/sanitize-resource-url.pipe.module'

export const REQ_KEY = makeStateKey<string>('req')

export const performanceTrackFn =
  (injector: Injector) =>
  (perfData: IPerformance): void => {
    const ss = injector.get(SegmentService)
    ss.track(SegmentEvents.NAVIGATION_TIMED, {
      action: 'Navigate',
      name: 'Page Load',
      ...perfData
    })
  }

export function metaFactory(vs: VerticalService, environmentService: EnvironmentService): MetaLoader {
  return new MetaStaticLoader({
    callback: (key: string) => vs.translate(key),
    pageTitlePositioning: PageTitlePositioning.PrependPageTitle,
    pageTitleSeparator: environmentService.config.pageTitleSeparator,
    applicationName: 'site_name',
    applicationUrl: 'core_site_url',
    defaults: {
      title: 'meta_sport_name',
      description: 'meta_sport_name',
      'og:image': 'meta_default_image',
      'og:type': 'meta_default_type',
      'og:locale': 'en_US',
      'og:locale:alternate': 'en_US',
      'twitter:card': 'meta_twitter_card_type',
      'theme-color': '#272A2B'
    }
  })
}

export function reqPatternFactory() {
  return (name: string) => {
    return RegExp('(http|https)://').test(name) ? name : `./assets/svg/${name}.svg`
  }
}
@NgModule({
  imports: [
    FloMseModule,
    FloQueryParamsModule,
    AnalyticsModule,
    FloPerformanceMetricsModule,
    AppRoutingModule,
    BrowserModule.withServerTransition({ appId: 'flo-app' }),
    HomeModule,
    FunnelModule,
    HttpClientModule,
    NgxWebstorageModule.forRoot(),
    NotFoundModule,
    FloDebugModule,
    SharedModule.forRoot(),
    TabsModule,
    SkeletonModule,
    EventTickerModule,
    FloSanitizeResourceUrlModule,
    MetaModule.forRoot({
      provide: MetaLoader,
      useFactory: metaFactory,
      deps: [VerticalService, EnvironmentService]
    }),
    FirebaseModule.forRoot(),
    RecaptchaV3Module,
    SiteNavigationModule
  ],
  providers: [
    { provide: RXJS_SCHEDULER, useValue: undefined },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    { provide: OLD_SVG_REQUEST_PATTERN, useFactory: reqPatternFactory },
    AdBlockService,
    {
      provide: FLO_PERF_TRACKING_PROVIDER,
      useFactory: performanceTrackFn,
      deps: [Injector]
    },
    {
      provide: EVENT_HUB_GET_PROVIDER,
      useExisting: EventsService
    },
    {
      provide: CLIENT_CACHE_BYPASS_REGEX,
      useValue: [
        /experiences\/web\/watch/,
        /experiences\/web\/home/,
        /experiences\/web\/schedule\/tab/,
        /experiences\/web\/entity-hub/,
        /experiences\/web\/event-hub/,
        /experiences\/web\/partials\/navigation/,
        /experiences\/web\/partials\/navigation\/flyout/,
        /experiences\/web\/partials\/event-ticker/,
        /experiences\/web\/partials\/offers/
      ]
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha?.siteKey
    },
    {
      provide: RECAPTCHA_BASE_URL,
      useValue: 'https://www.recaptcha.net/recaptcha/enterprise.js'
    }
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  exports: [AppComponent]
})
export class AppModule {}
