import { UntypedFormGroup } from '@angular/forms'
import { Injectable } from '@angular/core'

@Injectable()
export class MatchValidator {
  public static areEqual(fieldName1: string, fieldName2: string, errorType: string) {
    return (form: UntypedFormGroup): { [key: string]: boolean } | null => {
      const field1 = form.get(fieldName1)
      const field2 = form.get(fieldName2)

      if ((field1 && field1.value) !== (field2 && field2.value)) {
        const error: { [key: string]: boolean } = {}
        error[errorType] = true
        return error
      }

      // tslint:disable-next-line:no-null-keyword
      return null
    }
  }
  public areEqual(fieldName1: string, fieldName2: string, errorType: string) {
    return (form: UntypedFormGroup): { [key: string]: string } | undefined => {
      const field1 = form.get(fieldName1)
      const field2 = form.get(fieldName2)

      if ((field1 && field1.dirty && field1.value) !== (field2 && field2.value)) {
        const str1 = (field1 as any).value
        const str2 = (field2 as any).value
        let i = 0
        if (str1 && str2) {
          // Checks character by character for exact match
          if (str1.length <= str2.length) {
            while (str2.length > i) {
              if (str1.charAt(i) !== str2.charAt(i)) {
                const error: any = {}
                error[errorType] = true
                return error
              }
              i++
            }
          }
          // Checks lengths match
          if (str1.length > str2.length) {
            const error: any = {}
            error.length_mismatch = true
            return error
          }
        }
      }
      return undefined
    }
  }
}
