import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { ITokenResponse, UserService } from './user.service'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { IUserSubscription } from '../../account-settings/account-settings.interfaces'
import { ISubscriptionDiscount } from '../../account-settings/subscriptions/subscription-detail/cancel-subscription/churn-prevention/churn-prevention.component'
import { FloApiResponse } from '../models/api-response.model'
import { ISubscriptionRefundAndDiscountRequest } from '../../libs/_deprecated/subscription-detail/old-subscription-detail.component'
import { LOGGER_SERVICE } from '../../logger/logger.config'
import { LoggerService } from '../../logger/logger.interface'
import {
  MinimalSubscription,
  PayUpgradeResponse,
  RefundAndDiscountResponse,
  UserDataResponse,
  UserSubscriptionResponse
} from '../subscriptions/subscriptions.interface'
import { EnvironmentService } from 'src/app/singleton-services/environment.service'
import { PaymentResponseModel } from '@flocasts/payments'
import { renderMinimalUserSubscription } from '../subscriptions/subscriptions.utility'
import {
  CORE_API_STRIPE_SUBSCRIPTIONS_DOWNGRADE_PATH,
  CORE_API_SUBSCRIPTIONS_PATH,
  CORE_API_SUBSCRIPTIONS_REFUND_AND_DISCOUNT_PATH
} from 'src/app/app.config'

@Injectable()
export class SubscriptionService {
  constructor(
    @Inject(LOGGER_SERVICE) private readonly ls: LoggerService,
    private readonly http: HttpClient,
    private readonly userService: UserService,
    private readonly es: EnvironmentService
  ) {}

  public cancelStripeSubscription(stripeSubscriptionId: number): Observable<any> {
    return this.http.delete(`${CORE_API_SUBSCRIPTIONS_PATH}/${stripeSubscriptionId}`)
  }

  public updateCard(
    stripeToken: string,
    stripeSubscriptionId: string,
    couponId?: string
  ): Observable<void | HttpErrorResponse> {
    return this.http
      .patch('cards', {
        token: stripeToken,
        stripe_subscription: stripeSubscriptionId,
        couponId
      })
      .pipe(
        mergeMap(() => this.userService.refreshUserData()),
        map(() => void 0)
      )
  }

  /**
   * @deprecated - paymentsService.upgradeSubscription is how subscriptions are updated in the new system
   * @see {@link PaymentsService}
   *
   * @param subscriptionId
   * @param stripeToken
   */
  public updateSubscriptionToYearly(
    subscriptionId: number,
    stripeToken?: string
  ): Observable<PayUpgradeResponse | undefined> {
    return this.http
      .put<PayUpgradeResponse | undefined>(`stripe-subscriptions/${subscriptionId}/upgrade`, { token: stripeToken })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.ls.error(err.message, err.error)
          throw err
        })
      )
  }

  public updateSubscriptionWithDiscount(
    stripeSubscriptionId: number,
    body: ISubscriptionDiscount
  ): Observable<IUserSubscription | string> {
    return this.http.put<IUserSubscription>(`${CORE_API_SUBSCRIPTIONS_PATH}/${stripeSubscriptionId}`, body).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse && err.status === 400) {
          try {
            return of(err.error.errors.message)
          } catch (e) {
            this.ls.error(e)
          }
        }

        return of('There was an error with the server.')
      })
    )
  }

  /**
   * @deprecated - Not supported in new payment/entitlements system
   *
   * @param subscriptionId
   * @param days
   */
  public pauseSubscription(subscriptionId: number, days: number): Observable<UserDataResponse | string> {
    const body = {
      duration: days
    }
    return this.http.put<IUserSubscription>(`${CORE_API_SUBSCRIPTIONS_PATH}/${subscriptionId}/pause`, body).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse && err.status === 400) {
          try {
            return of(err.error.errors.message)
          } catch (e) {
            this.ls.error(e)
          }
        }

        return of('There was an error with the server.')
      })
    )
  }

  public resumeSubscription(stripeSubscriptionId: number): Observable<UserSubscriptionResponse> {
    return this.http
      .put<UserDataResponse>(`${CORE_API_SUBSCRIPTIONS_PATH}/${stripeSubscriptionId}/resume`, undefined)
      .pipe(
        catchError((err: any) => {
          const error = err.error
          const message = (error && error.message) || 'There was an error with the server.'

          return of({ error, message })
        })
      )
  }

  /**
   * @deprecated - Use paymentsService.reactivateSubscription for subscriptions in the new architecture
   * @see {@link PaymentsService}
   *
   * @param stripeSubscriptionId
   * @param body
   */
  public legacyReactivateSubscription(
    stripeSubscriptionId: number,
    body?: { coupon: string }
  ): Observable<UserSubscriptionResponse> {
    return this.http
      .put<UserDataResponse>(`${CORE_API_SUBSCRIPTIONS_PATH}/${stripeSubscriptionId}/revert-cancellation`, body)
      .pipe(
        catchError((err: any) => {
          const error = err.error
          const message = (error && error.message) || 'There was an error with the server.'

          return of({ error, message })
        })
      )
  }

  /**
   * @deprecated - paymentsService.upgradeSubscription is how subscriptions are updated in the new system
   * @see {@link PaymentsService}
   *
   * @param subscriptionId
   * @param cancelAtPeriodEnd
   */
  public downgradeUserToMonthly = (
    subscriptionId: number,
    cancelAtPeriodEnd = false
  ): Observable<UserSubscriptionResponse> => {
    const data = {
      stripe_subscription: subscriptionId,
      cancel_at_period_end: cancelAtPeriodEnd
    }

    return this.http.post<FloApiResponse<UserDataResponse>>(CORE_API_STRIPE_SUBSCRIPTIONS_DOWNGRADE_PATH, data).pipe(
      map(res => res.data),
      catchError((err: any) => {
        const error = err.error
        const message = (error && error.message) || 'There was an error with the server.'

        return of({ error, message })
      })
    )
  }

  /**
   * @deprecated - This functionality is only supported by Customer Service in the new architecture
   * @param subscriptionId
   * @param cancelAtPeriodEnd
   */
  public refundAndDiscountSubscription(
    body: ISubscriptionRefundAndDiscountRequest
  ): Observable<UserSubscriptionResponse> {
    return this.http
      .post<FloApiResponse<RefundAndDiscountResponse>>(CORE_API_SUBSCRIPTIONS_REFUND_AND_DISCOUNT_PATH, body)
      .pipe(
        map(res => res.data),
        catchError((err: any) => {
          const error = err.error
          const message = (error && error.message) || 'There was an error with the server.'

          return of({ error, message })
        })
      )
  }

  /**
   * @deprecated - @see {@link PaymentsService}
   *
   * @param userId
   * @param plan
   * @param setupIntent
   * @param captchaToken
   * @param couponId
   */
  public createSubscription(
    userId: number,
    plan: number,
    setupIntent: string,
    captchaToken: string,
    couponId?: string
  ) {
    let body = {
      captcha: captchaToken,
      plan,
      user: userId,
      type: 'stripe',
      paymentIntent: setupIntent
    }
    if (couponId) {
      body = {
        ...body,
        // @ts-expect-error because TypeScript thinks body is a type for some reason
        coupon: couponId
      }
    }
    return this.http.post<ITokenResponse>(CORE_API_SUBSCRIPTIONS_PATH, body)
  }

  /**
   * GET /users/{id}/subscriptions from payments service
   * and transform response to SupplementedUserSubscription[]
   * This only exists for backwards compatibility with the old subscription infrastructure where SupplementedUserSubscription is expected
   *
   * JWT is sent in headers automagically
   *
   * @see {@link https://payments.stag-aws.flokubernetes.com/docs#/default/UsersController_getUserSubscriptions}
   *
   */
  public getAllSubscriptions(userId: string, onlyActive = false): Observable<(MinimalSubscription | undefined)[]> {
    return this.http
      .get<PaymentResponseModel[]>(`${this.es.config.endpoints.payments}/users/${userId}/subscriptions`, {
        params: new HttpParams().set('onlyActive', onlyActive)
      })
      .pipe(
        map((payments: PaymentResponseModel[]) => {
          if (payments.length === 0) return []
          return payments.map((payment: PaymentResponseModel) =>
            renderMinimalUserSubscription(payment, this.es.config.stripeKey)
          )
        }),
        catchError(err => {
          // 404 error is expected if user has no subscriptions
          if (err.status !== 404) this.ls.error('Payments GET error in SubscriptionService ', err)
          return of([])
        })
      )
  }

  public getSubscriptionById(subscriptionId: string): Observable<MinimalSubscription | undefined> {
    return this.http
      .get<PaymentResponseModel>(`${this.es.config.endpoints.payments}/subscriptions/${subscriptionId}`)
      .pipe(
        map((subscription: PaymentResponseModel) =>
          renderMinimalUserSubscription(subscription, this.es.config.stripeKey)
        ),
        catchError(err => {
          // 404 error is expected if user has no subscriptions
          if (err.status !== 404) this.ls.error('Unable to retrieve subscription by ID. err=', err)
          return of(undefined)
        })
      )
  }
}
