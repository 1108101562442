import { Observable } from 'rxjs'
import { Injectable } from '@angular/core'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { EnvironmentService } from '../../singleton-services/environment.service'

/**
 * The purpose of this interceptor is to route HTTP requests from the local version of the WebApp to the local version
 * of the BFF.
 */
@Injectable()
export class HttpLocalBffInterceptor implements HttpInterceptor {
  constructor(private readonly environmentService: EnvironmentService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.environmentService.config.env === 'localbff' && req.url.includes('/experiences/web/')) {
      const host = this.environmentService.config.endpoints.experienceService
      const path = req.url.split('/experiences/')
      const url = `${host}/${path[1]}` // will always be second item regardless if host exists
      return next.handle(req.clone({ url, withCredentials: false }))
    }
    return next.handle(req)
  }
}
