import { Injectable } from '@angular/core'
import { ReplaySubject } from 'rxjs'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class HttpStabilityService {
  private readonly httpRequestCount$ = new ReplaySubject<number>(1)
  private httpRequestCount = 0
  public readonly hasOutstandingHttpRequests$ = this.httpRequestCount$.pipe(map(count => count !== 0))
  public incrementHttpRequestCount() {
    this.httpRequestCount$.next((this.httpRequestCount = this.httpRequestCount + 1))
  }
  public decrementHttpRequestCount() {
    this.httpRequestCount$.next((this.httpRequestCount = this.httpRequestCount - 1))
  }
}
