import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { NavModel } from '@flocasts/experience-service-types'
import { trackById } from 'src/app/shared/utility-functions/track-by-id.utility'

@Component({
  selector: 'flo-nav-fly-out',
  templateUrl: './nav-fly-out.component.html',
  styleUrls: ['./nav-fly-out.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlyOutNavComponent {
  @Input() nav: NavModel

  readonly trackById = trackById
}
