import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core'
import { LogoData } from './logo-data.interface'
import { logoDataMap } from './logo-data.map'
import { SiteIds } from '../../models/site.model'

/**
 * This component pulls SVG logos from a sprite sheet and displays them inline with the HTML
 * The attr.xlink:href needs to point to the current svg sprite sheet version
 *
 * @param siteId - the site id of the logo you would like to display
 *
 * Classes can still be used to style the SVG
 * @see src/styles/_logos.scss
 *   <flo-svg-logo class="dark" [siteId]="icon-times"></flo-svg-icon>
 */

@Component({
  selector: 'flo-svg-logo',
  template: `
    <img
      *ngIf="isVarsity; else floLogo"
      data-test="varsity-logo"
      class="no-skeleton"
      height="25"
      src="{{ varsityLogoPath }}"
    />
    <ng-template #floLogo>
      <svg [style.maxWidth]="maxWidth" [attr.width]="width" height="15px" data-test="flo-logo">
        <title>{{ logoData?.title }} Logo</title>
        <use attr.xlink:href="../../../../assets/svg/symbol/logo-sprite-f1c55f45.svg#{{ logoData?.id }}"></use>
      </svg>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SvgLogoComponent implements OnChanges {
  @Input() private readonly siteId: number = SiteIds.FLOSPORTS
  @Input() isResponsive = false
  @Input() showAlt = false

  public getLogoData(): LogoData | undefined {
    return logoDataMap.get(this.siteId)
  }

  public logoData?: LogoData

  public width: string
  public maxWidth: string

  public isVarsity: boolean
  public varsityLogoPath = '../../assets/svg/logo-varsity.svg'

  ngOnChanges() {
    this.isVarsity = this.siteId === SiteIds.VARSITY

    this.logoData = this.getLogoData()

    if (this.logoData) {
      if (this.isResponsive) {
        this.width = '100%'
        this.maxWidth = this.logoData.maxWidth.toString()
      } else {
        this.width = this.logoData.maxWidth.toString()
        this.maxWidth = this.logoData.maxWidth.toString()
      }
    }

    if (this.showAlt) {
      this.varsityLogoPath = '../../assets/svg/logo-varsity-alt.svg'
    }
  }
}
