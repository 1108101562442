import { Inject, Injectable, NgZone, Renderer2, RendererFactory2 } from '@angular/core'
import { Meta, Title } from '@angular/platform-browser'
import { DOCUMENT } from '@angular/common'
import { LoggerService } from '../../logger/logger.interface'
import { LOGGER_SERVICE } from '../../logger/logger.config'
import { ArticleMeta, PageMeta, TAG_DATA_ATTRIBUTE, TAG_DATA_ATTRIBUTE_VALUE } from './page-meta.interface'
import { absurd } from 'fp-ts/function'
import { UpdateHeadService } from '../../singleton-services/seo/update-head.service'
import { buildCanonicalUrl, generateArticleSchema } from '../../singleton-services/seo/seo.utility'
import { Subject } from 'rxjs'

export const DEFAULT_OG_IMAGE_WIDTH = 680
export const DEFAULT_OG_IMAGE_HEIGHT = 382

const getDatasetProperties = () => ({
  [TAG_DATA_ATTRIBUTE]: TAG_DATA_ATTRIBUTE_VALUE
})

/**
 * Remove trailing slash
 */
export const removeTrailingSlash = (str: string): string => {
  return str.replace(/\/$/, '')
}

const getDatasetSelector = (): string => `[${TAG_DATA_ATTRIBUTE}="${TAG_DATA_ATTRIBUTE_VALUE}"]`

enum META_TAG_PROPERTY {
  ogDescription = 'og:description',
  ogImage = 'og:image',
  ogImageWidth = 'og:image:width',
  ogImageHeight = 'og:image:height',
  ogTitle = 'og:title',
  ogType = 'og:type',
  ogUrl = 'og:url',
  ogSiteName = 'og:site_name',
  twitterCard = 'twitter:card',
  // Article Specific @see {@link https://ogp.me/#type_article}
  articlePublishedTime = 'article:published_time',
  articleModifiedTime = 'article:modified_time',
  articleAuthor = 'article:author', // comma-separated list of authors
  articleSection = 'article:section', // sport name
  articleTag = 'article:tag'
}

/**
 * This service is intended to replace the behavior of @ngx-meta/core. It can
 * set and clear page titles, descriptions, canonical url, and social graph
 * meta tags.
 *
 * @remarks
 * The basic steps for using this service:
 *
 *  1. call `setPageMeta` in or after ngOnInit when with as much information as possible
 *  2. before moving to a new page call `clearMeta`
 *
 * This service will load the proper meta tags during SSR loading. When testing
 * the usage of this service, check that the server response contains the
 * expected tags in the <head>.
 */
@Injectable({
  providedIn: 'root'
})
export class PageMetaService {
  private readonly renderer: Renderer2

  /**
   * This is used in pages so that the global site navigation does not
   * override the page meta.
   *
   * @see SiteNavigationComponent in the setPageMeta method
   */
  private isMetaClearable = new Subject<boolean>()
  public isMetaClearable$ = this.isMetaClearable.asObservable()

  // prettier-ignore
  constructor(
    @Inject(DOCUMENT) private readonly dom: Document,
    @Inject(LOGGER_SERVICE) private readonly log: LoggerService,
    private readonly meta: Meta,
    private readonly title: Title,
    private readonly zone: NgZone,
    private readonly updateHeadService: UpdateHeadService,
    rendererFac: RendererFactory2,
  ) {
    this.renderer = rendererFac.createRenderer(null, null)
  }

  /**
   * Keep track of elements made in the HEAD so we can remove them when no longer needed
   */
  private tagIds: string[] = []

  /**
   * Set the title, description, meta, and canonical URL.
   *
   * @remarks
   * Remember that meta mostly matters during an
   * SSR load, and users have no real interaction with it. Keeping it up-to-date
   * as the user moves through the SPA is still important to drive a good user
   * experience, and handle any advanced crawlers.
   *
   * Remember to call `clearMeta` when you move to a new page view.
   *
   * @see clearMeta
   */
  public setPageMeta(meta: PageMeta): void {
    this.log.trace('PageMetaService: Setting page meta tags', {
      meta
    })

    // Set title and description
    this.zone.runOutsideAngular(() => {
      this.title.setTitle(meta.title)
    })
    this.setDescription(meta.description)

    // Set the Canonical URL
    if (meta.canonicalUrl !== undefined) {
      this.setCanonicalUrl(meta.canonicalUrl)
    }

    // Set social graph meta for OpenGraph and Twitter
    this.setGraphAndSchemaMeta(meta)
  }

  /**
   * Clear the currently set page meta.
   *
   * @remarks
   * This needs to be done to clear up any outstanding meta on the page. It
   * should be called during the onDestroy lifecycle generally, to ensure that
   * meta tags don't stick around from page to page.
   */
  public clearMeta(): void {
    this.zone.runOutsideAngular(() => {
      const elements = this.dom.querySelectorAll(getDatasetSelector())
      elements.forEach(el => {
        this.log.trace('PageMetaService: clearing meta tag', {
          name: el.getAttribute('name'),
          property: el.getAttribute('property'),
          tag: el.tagName,
          rel: el.getAttribute('rel'),
          content: el.getAttribute('content')
        })
        this.renderer.removeChild(this.dom.head, el)
      })

      this.tagIds.forEach(id => {
        this.updateHeadService.removeElement(id)
      })
      this.tagIds = []
      this.setIsMetaClearable(true)
    })
  }

  private setDescription(description: string): void {
    this.zone.runOutsideAngular(() => {
      this.log.trace('PageMetaService: setting description', {
        description
      })
      this.meta.removeTag('name=description')
      this.meta.addTag({
        name: 'description',
        content: description,
        ...getDatasetProperties()
      })
    })
  }

  private addGraphTag(property: META_TAG_PROPERTY, content: string): void {
    this.zone.runOutsideAngular(() => {
      this.log.trace('PageMetaService: Adding social graph tag', {
        property,
        content
      })
      const tag = this.meta.addTag({
        property,
        content,
        ...getDatasetProperties()
      })
      if (tag === null) {
        this.meta.updateTag({
          property,
          content,
          ...getDatasetProperties()
        })
      }
    })
  }

  private addTwitterTag(name: META_TAG_PROPERTY, content: string): void {
    this.zone.runOutsideAngular(() => {
      this.log.trace('PageMetaService: Adding Twitter tag', {
        name,
        content
      })
      const tag = this.meta.addTag({
        name,
        content,
        ...getDatasetProperties()
      })
      if (tag === null) {
        this.meta.updateTag({
          name,
          content,
          ...getDatasetProperties()
        })
      }
    })
  }

  private setCanonicalUrl(url: string): void {
    this.zone.runOutsideAngular(() => {
      const canonical = buildCanonicalUrl(url)
      this.updateHeadService.addElementToHead(canonical)
    })
  }

  private setGraphAndSchemaMeta(meta: PageMeta): void {
    // Set the meta required for all pages.
    this.addGraphTag(META_TAG_PROPERTY.ogTitle, meta.title)
    this.addGraphTag(META_TAG_PROPERTY.ogType, meta.type)
    this.addTwitterTag(META_TAG_PROPERTY.twitterCard, meta.twitterCard)
    this.addGraphTag(META_TAG_PROPERTY.ogDescription, meta.description)
    this.addGraphTag(META_TAG_PROPERTY.ogUrl, meta.canonicalUrl)
    this.addGraphTag(META_TAG_PROPERTY.ogImage, meta.image.url)
    this.addGraphTag(META_TAG_PROPERTY.ogImageHeight, meta.image.height.toString())
    this.addGraphTag(META_TAG_PROPERTY.ogImageWidth, meta.image.width.toString())
    if (meta.siteName) this.addGraphTag(META_TAG_PROPERTY.ogSiteName, meta.siteName)

    // Set the meta specific to certain page types.
    switch (meta.type) {
      case 'article':
        return this.setArticleMeta(meta)
      case 'website':
        // The website type doesn't have any specific meta to add.
        return
      default:
        // This will throw an error if we don't handle each page type.
        return absurd(meta)
    }
  }

  private setArticleMeta(meta: ArticleMeta): void {
    // add any article-specific graph tags here
    const firstName = `${meta.authorFirstname} ` || ''
    const lastName = meta.authorLastname || ''

    this.addGraphTag(META_TAG_PROPERTY.articleAuthor, `${firstName}${lastName}`)
    if (meta.contentTags) this.addGraphTag(META_TAG_PROPERTY.articleTag, meta.contentTags)
    if (meta.datePublished) this.addGraphTag(META_TAG_PROPERTY.articlePublishedTime, meta.datePublished)
    if (meta.modifiedAt) this.addGraphTag(META_TAG_PROPERTY.articleModifiedTime, meta.modifiedAt)
    if (meta.sportName) this.addGraphTag(META_TAG_PROPERTY.articleSection, meta.sportName)

    // schema
    const articleSchema = generateArticleSchema(meta)

    // store id to remove schema later
    this.tagIds.push(articleSchema.id)
    this.updateHeadService.addElementToHead(articleSchema)
  }

  public setIsMetaClearable(value: boolean) {
    this.isMetaClearable.next(value)
  }
}
