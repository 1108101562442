import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TrackByFunction
} from '@angular/core'
import { combineLatest, BehaviorSubject } from 'rxjs'
import { distinctUntilChanged, map } from 'rxjs/operators'
import { maybe } from 'typescript-monads'

import { IVideoQuality } from '../../models/video-quality.model'

interface VideoQualityProps {
  label: string
  id: number
}

@Component({
  selector: 'flo-video-quality-control',
  templateUrl: './video-quality-control.component.html',
  styleUrls: ['./video-quality-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoQualityControlComponent implements OnChanges {
  @Input()
  get eventAnalytics(): any {
    return {
      ...this._eventAnalytics
    }
  }

  set eventAnalytics(val: any) {
    this._eventAnalytics = val
  }

  private _eventAnalytics: any

  @Output()
  readonly qualityChanged = new EventEmitter<number>()
  @Input()
  qualityControlState: IVideoQuality
  private qualityControlSource = new BehaviorSubject<any>([])
  private qualityControl$ = this.qualityControlSource.pipe(distinctUntilChanged())
  private qualitySelectedSource = new BehaviorSubject<number>(-1)
  private qualitySelected$ = this.qualitySelectedSource.pipe(distinctUntilChanged())
  public dropdownExpanded = false

  view$ = combineLatest([this.qualityControl$, this.qualitySelected$]).pipe(
    map(([controls, selectedQuality]) => {
      const qualityLevels = controls
        .concat({ label: 'Auto', id: -1 })
        .map((level: VideoQualityProps, index: number) => ({
          ...level,
          selectedQuality: selectedQuality === level.id
        }))
        .reverse()
      return {
        qualityLevels,
        controls,
        analyticQualityPickerProps: {
          ...this.eventAnalytics,
          name: 'Quality Picker'
        }
      }
    })
  )
  @HostListener('document:click', ['$event.srcElement'])
  onClick() {
    if (this.dropdownExpanded) {
      this.dropdownExpanded = false
    }
  }

  public toggleDropdown(event: any): void {
    event.stopPropagation()
    this.dropdownExpanded = !this.dropdownExpanded
  }

  public changeQuality(qualityLevel: number): void {
    this.qualityChanged.next(qualityLevel)
    this.qualitySelectedSource.next(qualityLevel)
    this.dropdownExpanded = false
  }

  public getQualityAnalyticsProperties = (resolution: number | string) => ({
    ...this.eventAnalytics,
    resolution,
    name: 'resolution'
  })

  ngOnChanges(changes: SimpleChanges) {
    maybe(changes.qualityControlState)
      .flatMap(change => maybe<any>(change.currentValue))
      .tapSome(control => {
        this.qualityControlSource.next(control.levels)
        this.qualitySelectedSource.next(control.manualLevel)
      })
  }

  trackByQuality: TrackByFunction<any> = (_index: number, item: any) => {
    return item.height
  }
}
