import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { EMPTY } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { LOGGER_SERVICE } from 'src/app/logger/logger.config'
import { LoggerService } from 'src/app/logger/logger.interface'
import { EnvironmentService } from 'src/app/singleton-services/environment.service'
import { IVideoProgress } from '../shared/video-player/models/video-progress.model'

@Injectable({
  providedIn: 'root'
})
export class ContinueWatchingService {
  constructor(
    private readonly http: HttpClient,
    private readonly es: EnvironmentService,
    @Inject(LOGGER_SERVICE) private readonly logger: LoggerService
  ) {}

  private baseUrl = this.es.config.endpoints.continueWatching

  /**
   * saves users video time to the continue-watching service
   */
  public saveProgress(videoProgress: IVideoProgress) {
    const url = `${this.baseUrl}/save-progress`
    return this.http
      .post(url, videoProgress)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.logger.error('ContinueWatchingService saveProgress Error: ', err)
          return EMPTY
        })
      )
      .subscribe()
  }
}
