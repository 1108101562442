import { Inject, Injectable, NgZone } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router'
import { HttpClient } from '@angular/common/http'
import { combineLatest, Observable, of, Subscription } from 'rxjs'
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  mergeMap,
  map,
  share,
  shareReplay,
  startWith,
  take
} from 'rxjs/operators'
import { PlatformService } from '../../../singleton-services/platform.service'
import { AuthService } from '../../../singleton-services/auth/auth.service'
import type { IAnalyticsData, IUserIdentity } from '../../../singleton-services/auth/auth.interfaces'
import { DeviceService } from '../../../singleton-services/device.service'
import { VerticalService } from '../../../singleton-services/vertical.service'
import { UserAgentService } from '../../../singleton-services/user-agent.service'
import { AccountService } from '../../../singleton-services/account.service'
import { LocationService } from '../../../singleton-services/location.service'
import { NodePropsProvider, NodeService } from '../../services/node.service'
import { filterDeepRouterDataByPredicate } from '../../rx-pipes/router'
import { SegmentEvents, SegmentTrackEvent } from '../models/segment-events.model'
import { SegmentTagMap } from '../models/segment-tag-map.model'
import { SegmentPageProperties, SegmentTag, SegmentTagV2 } from '../models/segment-tag.model'
import { HasOffersService } from './has-offers.service'
import SegmentOpts = SegmentAnalytics.SegmentOpts
import { Author, ContentNode, LiveEvent } from '@flocasts/flosports30-types/dist/entity'
import { IAnalyticsNode } from '../../models/node.model'
import { DisplayAdTrackEvent, SegmentTrackDisplayAds } from '../../interfaces/segment-track-display-ads.interface'
import { SegmentTrackVodPrerollAds } from '../../interfaces/segment-track-vod-preroll-ads.interface'
import { SegmentTrackLiveAds } from '../../interfaces/segment-track-live-ads.interface'
import { capitalizeFirstChar } from '../../utility-functions/string.utility'
import { StreamPreviewDisplayedAnalytics } from '../../../watch/utility/watch.interfaces'
import { FunnelType } from '../../../funnel/funnel-analytics.utility'
import { buildAnalyticTrackProps } from './segment.utility'
import { WINDOW } from 'src/app/app.injections'
import { CookieService } from 'src/app/singleton-services/cookie.service'
import { FLO_COUNTRY_CODE } from 'src/app/app.config'

interface HasOffersTransaction {
  hasoffers: {
    transaction_id: string | undefined
  }
}
export function hasOffersTransaction(
  hasOffers: boolean,
  transactionId: string | undefined
): HasOffersTransaction | false {
  const tid = hasOffers && transactionId
  return !!tid && { hasoffers: { transaction_id: transactionId } }
}

export enum FUNNEL_TYPE {
  GENERIC = 'Generic',
  PREMIUM = 'Premium Content',
  LIVE = 'Live Event'
}

/**
 * return type for SegmentService.supplementalData$
 */
interface SupplementalData {
  userIdentity: IUserIdentity | undefined
  routeData: { tag: SegmentTag; liveEvent: any; funnel: any } | undefined
  device: 'Desktop' | 'Tablet' | 'Mobile' | 'Unknown' | undefined
  platform: string | undefined
  transaction_id: string | undefined
  milesplit_ref_code: any
  milesplit_sub_site_id: any
  browser: string | undefined
  site_id: number | undefined
  subscriber_portal_id: string
  component: string | undefined
  live_event_status: string
  live_id: number
  page_category: string | undefined
  subpage_category: string | undefined
  vertical: string | undefined
  site_name: string | undefined
  funnel_type: string | undefined
  initialPageLoad: boolean
  anonymousId: string | undefined
  subscription_start_date: string | undefined
}

export interface ISegmentService {
  track(name: string, properties?: any): void

  page(section: string, name: string, properties?: any, node?: PageableNode): void

  identify(userId: string, properties?: any): void
}

interface Segment {
  analytics: SegmentAnalytics.AnalyticsJS
}

interface ChartBeat {
  _cbq: {
    push(userType: ReadonlyArray<any>): void
  }
}

/**
 * Required page info for track calls; replaces @NodePropsProvider, which is deprecated
 *
 * Types coming from CollectionFloApiResponse and elsewhere still use the
 * deprecated Node and INode types, so we are allowing for very loose typings here
 *
 * TODO: remove all uses of INODE and NODE from webapp;
 * @see {@link https://flocasts.atlassian.net/browse/FLO-13569}
 */
export interface TrackPageDetails {
  premium?: boolean | null
  live_event?: LiveEvent | any
  id?: number | any
  node?: Pick<ContentNode, 'primary_event_association'> | any
  type?: string | any
  author?: Author | any
}

export type PageableNode = NodePropsProvider

@Injectable({
  providedIn: 'root'
})
export class SegmentService implements ISegmentService {
  constructor(
    private readonly authService: AuthService,
    private readonly deviceService: DeviceService,
    private readonly userAgentService: UserAgentService,
    private readonly verticalService: VerticalService,
    private readonly platformService: PlatformService,
    @Inject(WINDOW) private readonly window: Window,
    private readonly location: LocationService,
    private readonly nodeService: NodeService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly http: HttpClient,
    private readonly zone: NgZone,
    private readonly hasOffersService: HasOffersService,
    private readonly cookieService: CookieService,
    private readonly accountService: AccountService
  ) {}

  private get userId(): string | undefined {
    return (this.segmentIsDefined && typeof this.segment?.user === 'function' && this.segment?.user().id()) || undefined
  }

  public get anonymousId(): string | undefined {
    const anonymousId = this.router.parseUrl(this.router.url).queryParams.anonymousId
    if (this.segmentIsDefined && anonymousId) {
      this.segment?.setAnonymousId(anonymousId)
    }
    return (
      (this.segmentIsDefined && typeof this.segment?.user === 'function' && this.segment?.user().anonymousId()) ||
      undefined
    )
  }

  private get segment() {
    return this.platformService.isBrowser ? (this.window as Window & Segment).analytics : undefined
  }

  private get chartbeat() {
    return this.platformService.isBrowser ? (this.window as Window & ChartBeat)._cbq : undefined
  }

  private get segmentIsDefined(): boolean {
    return this.platformService.isBrowser && typeof this.segment !== 'undefined'
  }
  private navEndEvents$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    share()
  )

  private subscriptionStartDate$: Observable<string | undefined> = this.accountService.activeSubscription$.pipe(
    map(sub => sub?.purchase_date)
  )

  private segmentRouteData$ = this.navEndEvents$.pipe(
    filterDeepRouterDataByPredicate(this.activatedRoute)(a => a.data),
    map(data => ({
      tag: data.segment as SegmentTag,
      liveEvent: data.liveEvent,
      funnel: data.funnel
    })),
    shareReplay(1)
  )

  /**
   *  Provide page information (node_id, etc) for track calls to segment
   *
   *  Replaces NodeService.getPropsFromNode, which is deprecated
   */
  private trackPageDetails = (node: TrackPageDetails | undefined): IAnalyticsNode | null => {
    if (!node) return null
    return {
      author: node.author ? `${node.author?.first_name} ${node.author?.last_name}` : undefined,
      is_premium: node.premium,
      live_id: node.live_event && node.live_event.id,
      live_event_status: node.live_event && node.live_event.status,
      node_id: node.id,
      node_type: node.type && capitalizeFirstChar(node.type),
      primary_association_id:
        node.node && node.node.primary_event_association && node.node.primary_event_association.id,
      section: node.type && `${capitalizeFirstChar(node.type)}s`
    }
  }

  /**
   * Global Data that can be used for all Segment calls.
   */
  public supplementalData$: Observable<SupplementalData> = combineLatest([
    this.verticalService.siteSettings$,
    this.authService.userIdentity$,
    this.activatedRoute.queryParams,
    this.segmentRouteData$.pipe(startWith(undefined)),
    this.deviceService.loadedDevice$.pipe(startWith(undefined)),
    this.subscriptionStartDate$
  ])
    .pipe(
      map(([siteSettings, userIdentity, queryParams, routeData, device, subStartDate]) => {
        const isInFunnel = routeData && routeData.funnel
        const liveId = routeData && routeData.liveEvent && routeData.liveEvent.id

        return {
          userIdentity,
          routeData,
          device,
          platform: 'Web',
          transaction_id: this.hasOffersService.retrieveTransactionId(),
          milesplit_ref_code: queryParams.ref,
          milesplit_sub_site_id: queryParams.site,
          browser: this.userAgentService.userAgent.browser,
          site_id: siteSettings.site_id,
          subscriber_portal_id: `${siteSettings.site_id}`, // Marketo requires as a string :(
          component: routeData && routeData.tag && routeData.tag.component,
          live_event_status: routeData && routeData.liveEvent && routeData.liveEvent.status,
          live_id: liveId,
          page_category: routeData && routeData.tag && routeData.tag.page_category,
          subpage_category: routeData && routeData.tag && routeData.tag.subpage_category,
          vertical: siteSettings.site_name.toLowerCase(),
          site_name: siteSettings.site_name,
          funnel_type: isInFunnel ? mapToFunnelType(queryParams, liveId) : undefined,
          initialPageLoad: !this.router.getCurrentNavigation()?.previousNavigation,
          anonymousId: this.anonymousId,
          subscription_start_date: subStartDate
        }
      })
    )
    .pipe(shareReplay(1))

  // For sending user account type to chartbeat when segment is ready
  public pushChartBeatUserOnSegmentReady$: Subscription | false =
    this.segmentIsDefined &&
    this.authService.userTier$
      .pipe(
        mergeMap(user => {
          const userAccountType = mapUserToChartBeat(user)
          return of(
            this.segment?.ready(() => {
              this.zone.runOutsideAngular(() => {
                this.chartbeat?.push(['_acct', userAccountType])
              })
            })
          )
        }),
        take(1)
      )
      .subscribe()

  public ready(cb: () => void): void {
    if (this.segmentIsDefined) this.segment?.ready(cb)
  }

  /**
   * When user logs out, send a couple events event before calling segment.reset()
   * BUTTON_CLICKED/DROPDOWN_OPTION_SELECTED are included here because of race conditions
   *    when using the floAnalytics directive on the HeaderComponent template.
   */
  public handleLogout() {
    this.deviceService.isMobile$.pipe(take(1)).subscribe(isMobile => {
      const eventName = isMobile ? SegmentEvents.BUTTON_CLICKED : SegmentEvents.DROPDOWN_OPTION_SELECTED
      this.track(eventName, {
        action: 'Click',
        name: 'Log Out',
        text: 'Log Out'
      })
      this.track(SegmentEvents.ACCOUNT_LOGGED_OUT, {})
      this.reset()
    })
  }

  /**
   * Send Segment .reset() call to reset Segment's cookies & localStorage
   */
  public reset() {
    if (this.segmentIsDefined) this.segment?.reset()
  }

  /**
   * Send an analytics call indicating that the user has visited a page.
   *
   * @param tag This is the basic page information for a particular route. This
   *            is usually found in the SegmentTagMapV2.
   * @param props Optional props that may be needed for a specific page.
   *
   * @see {@link https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#page}
   */
  public pageV2(tag: SegmentTagV2, props: SegmentPageProperties): void {
    if (!this.segmentIsDefined) return

    const name =
      tag.subpage_category !== undefined && tag.subpage_category !== ''
        ? `${tag.page_category} - ${tag.subpage_category}`
        : tag.page_category

    this.supplementalData$
      .pipe(
        map(supp => {
          // FLO-14284 - reset referrer to empty string on SPA navigation.
          const newProps = this.router.getCurrentNavigation()?.previousNavigation ? { ...props, referrer: '' } : props
          const geoCountryCode = this.cookieService.get(FLO_COUNTRY_CODE)

          const getCountryCodeProps = geoCountryCode ? { country_code: geoCountryCode } : {}

          // TODO refactor method to use getCommonProps and ensure accurate types
          this.zone.runOutsideAngular(() =>
            this.segment?.page(tag.category, name, {
              browser: supp.browser,
              device: supp.device,
              component: supp.component,
              platform: supp.platform,
              milesplit_sub_site_id: supp.milesplit_sub_site_id,
              milesplit_ref_code: supp.milesplit_ref_code,
              live_event_status: supp.live_event_status,
              live_id: supp.live_id,
              section: tag.node_type && `${capitalizeFirstChar(tag.node_type)}s`,
              site_id: supp.site_id,
              vertical: supp.vertical,
              site_name: supp.site_name,
              page_category: tag.page_category,
              subpage_category: tag.subpage_category,
              node_type: tag.node_type,
              subscriber_portal_id: supp.subscriber_portal_id,
              subscription_start_date: supp.subscription_start_date,
              ...getCountryCodeProps,
              ...newProps
            })
          )
        }),
        take(1)
      )
      .subscribe()
  }

  /**
   * @deprecated This method was designed with a certain concept of our data in
   *             mind, and our data and needs have changed in a way that this
   *             method really can't support any longer. Switch to `pageV2` for
   *             a simpler type-driven option which supports all of our data
   *             while still promoting consistency.
   *
   * @see pageV2
   */
  public page(section: string | undefined, name?: string, properties?: any, node?: PageableNode) {
    if (!this.segmentIsDefined) {
      return
    }

    const nodeProps = (node ? this.nodeService.getPropsFromNode(node) : undefined) as any

    // If node provided a page_category then set it back on the nodeProps. This allows us
    // to pass this data along to segment
    const pageCategory: string = node && (node as any).page_category
    if (nodeProps && pageCategory) {
      nodeProps.page_category = pageCategory
    }
    // If node provided a sugpage_category then set it back on the nodeProps. This allows us
    // to pass this data along to segment
    const subPageCategory: string = node && (node as any).subpage_category
    if (nodeProps && subPageCategory) {
      nodeProps.subpage_category = subPageCategory
    }

    const metaDataFilters: string = node && (node as any).metadata_filters
    if (nodeProps && metaDataFilters) {
      nodeProps.metadata_filters = metaDataFilters
    }

    const geoCountryCode = this.cookieService.get(FLO_COUNTRY_CODE)
    const getCountryCodeProps = geoCountryCode ? { country_code: geoCountryCode } : {}

    this.supplementalData$
      .pipe(
        map(supp => {
          let routeName = name || (supp.routeData && supp.routeData.tag && supp.routeData.tag.name)
          if (node && routeName === SegmentTagMap.ARTICLE_LIST.name) {
            routeName = SegmentTagMap.ARTICLE_DETAIL.name
          }

          return {
            ...this.getCommonProps(supp),
            ...nodeProps,
            name: routeName,
            ...getCountryCodeProps,
            ...properties
          }
        }),
        take(1)
      )
      .subscribe((props: any) => {
        this.zone.runOutsideAngular(() => this.segment?.page(props.section, props.name, props))
      })
  }

  /**
   * New track method to enforce stronger typing
   *
   * @param event SegmentTrackEvent, name and properties for the track call
   * @param hasOffers Add the HasOffers/TUNE transaction ID from the user traits
   */
  public trackEvent(event: SegmentTrackEvent, hasOffers = false) {
    if (!this.segmentIsDefined) {
      return
    }

    const { node, ...propsWithoutNode } = event.properties as any
    const typedProps: { [key: string]: string } = propsWithoutNode as any
    const importantNodeProps = node ? this.nodeService.getPropsFromNode(node) : undefined

    this.supplementalData$
      .pipe(
        map(supp => {
          return {
            props: {
              ...this.getCommonProps(supp),
              ...importantNodeProps,
              ...Object.keys(typedProps).reduce((acc: { [key: string]: string }, cur: string) => {
                // filter out props that don't have values
                if (typedProps[cur] !== undefined) {
                  acc[cur] = typedProps[cur]
                }
                return acc
              }, {})
            },
            transactionId: supp.transaction_id
          }
        }),
        take(1)
      )
      .subscribe(res => {
        const hasOffersOpts = hasOffersTransaction(hasOffers, res.transactionId)
        this.zone.runOutsideAngular(() => this.segment?.track(event.name, res.props, hasOffersOpts as SegmentOpts))
      })
  }

  /** Track display ads firing */
  public trackDisplayAd(props: SegmentTrackDisplayAds, eventName: DisplayAdTrackEvent): void {
    this.supplementalData$
      .pipe(
        map(supp => {
          return {
            ...props,
            ...this.getCommonProps(supp),
            ...this.trackPageDetails(props.tracking)
          }
        }),
        take(1)
      )
      .subscribe(formattedProps => {
        this.zone.runOutsideAngular(() => this.segment?.track(eventName, formattedProps))
      })
  }

  /** Track video ads firing */
  public trackVODPrerollAd(props: SegmentTrackVodPrerollAds, eventName: string): void {
    this.supplementalData$
      .pipe(
        map(supp => {
          return {
            browser: supp.browser,
            component: supp.component,
            device: supp.device,
            platform: supp.platform,
            page_category: supp.page_category,
            site_id: supp.site_id,
            subpage_category: supp.subpage_category,
            transaction: supp.transaction_id,
            subscriber_portal_id: supp.subscriber_portal_id,
            vertical: supp.vertical,
            subscription_start_date: supp.subscription_start_date,
            ...props
          }
        }),
        take(1)
      )
      .subscribe(formattedProps => {
        this.zone.runOutsideAngular(() => this.segment?.track(eventName, formattedProps))
      })
  }

  public trackLiveAd(props: SegmentTrackLiveAds, eventName: string): void {
    this.supplementalData$
      .pipe(
        map(supp => {
          return {
            ...this.getCommonProps(supp),
            ...props
          }
        }),
        take(1)
      )
      .subscribe(formattedProps => {
        this.zone.runOutsideAngular(() => this.segment?.track(eventName, formattedProps))
      })
  }

  /**
   * Track a LIVE preview stream being displayed to a user.
   */
  public trackPreviewStreamDisplayed(eventName: string, props: StreamPreviewDisplayedAnalytics) {
    this.supplementalData$
      .pipe(
        map(supp => {
          return {
            ...this.getCommonProps(supp),
            ...props
          }
        }),
        take(1)
      )
      .subscribe(formattedProps => {
        this.zone.runOutsideAngular(() => this.segment?.track(eventName, formattedProps))
      })
  }

  /**
   * @deprecated Unsafe types; create a specific function for each type of track call,
   * @see trackEvent
   *
   * Wraps the segment-analytics track function to provide all necessary props from streaming sources
   *
   * @param name The name of the tracked event
   * @param properties Analytics data associated with the event
   * @param hasOffers Add the HasOffers transaction ID from the user traits
   */
  public track(name: string, properties: any = {}, hasOffers = false) {
    if (!this.segmentIsDefined) {
      return
    }

    const { node, ...propsWithoutNode } = properties
    const importantNodeProps = node ? this.nodeService.getPropsFromNode(node) : undefined

    this.supplementalData$
      .pipe(
        map(supp => {
          const commonProps = this.getCommonProps(supp)
          return buildAnalyticTrackProps(commonProps, importantNodeProps, propsWithoutNode)
        }),
        take(1)
      )
      .subscribe(props => {
        const tid = hasOffers && (this.segment?.user().traits() as any).transaction_id
        const hasOffersOpts = !!tid && { hasoffers: { transaction_id: tid } }

        this.zone.runOutsideAngular(() => this.segment?.track(name, props, hasOffersOpts as SegmentOpts))
      })
  }

  tix(props: any) {
    return this.http.post('tix', JSON.stringify(props), {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  public serverTrack(name: string, properties?: any) {
    this.zone
      .runOutsideAngular(() =>
        this.supplementalData$.pipe(
          filter(supp => !!supp.device),
          map(supp => {
            return {
              event: name,
              anonymous_id: this.anonymousId,
              user_id: this.userId,
              page: this.location.href,
              path: this.location.pathname,
              ...this.getCommonProps(supp),
              ...properties
            }
          }),
          mergeMap(props => this.tix(props)),
          take(1)
        )
      )
      .subscribe(
        res => undefined,
        err => undefined
      )
  }

  public identify(userId: string, userData: IAnalyticsData) {
    if (this.segmentIsDefined) {
      this.supplementalData$
        .pipe(
          map(supp => {
            return {
              ...userData,
              site_id: supp.site_id,
              subscriber_portal_id: supp.subscriber_portal_id,
              transaction_id: supp.transaction_id,
              subscription_start_date: supp.subscription_start_date
            }
          }),
          take(1)
        )
        .subscribe(props => {
          this.zone.runOutsideAngular(() => this.segment?.identify(userId, props))
        })
    }
  }

  public beginUserTracking() {
    if (!this.platformService.isBrowser) return

    this.hasOffersService.storeTransactionId().subscribe()
    this.authService.analyticsData$
      .pipe(
        // This allows time for the entitlements call that determines isPremium to complete
        // after a user logs in. This is necessary in order to prevent redundant 'identify'
        // calls that include the incorrect isPremium value.
        debounceTime(2000),
        distinctUntilChanged()
      )
      .subscribe(analyticsData => {
        this.identify(analyticsData.id || '', analyticsData)
      })
  }

  // tslint:disable-next-line:prefer-function-over-method
  private getCommonProps(suppData: Omit<SupplementalData, 'userIdentity' | 'routeData'>) {
    const props = {
      browser: suppData.browser,
      component: suppData.component,
      device: suppData.device,
      platform: suppData.platform,
      live_event_status: suppData.live_event_status,
      live_id: suppData.live_id,
      milesplit_sub_site_id: suppData.milesplit_sub_site_id,
      milesplit_ref_code: suppData.milesplit_ref_code,
      page_category: suppData.page_category,
      site_id: suppData.site_id,
      subscriber_portal_id: suppData.subscriber_portal_id,
      subpage_category: suppData.subpage_category,
      vertical: suppData.vertical,
      site_name: suppData.site_name,
      subscription_start_date: suppData.subscription_start_date,
      ...(suppData.funnel_type && { funnel_type: suppData.funnel_type })
    }

    // FLO-14284 - reset referrer to empty string on SPA navigation.
    return suppData.initialPageLoad ? props : { ...props, referrer: '' }
  }
}

/**
 * @deprecated This uses Params.
 *
 * @see funnel-analytics.utility.ts
 */
export function mapToFunnelType(queryParam: Params, liveId: number | undefined): FunnelType {
  switch (true) {
    case !!liveId:
      return FUNNEL_TYPE.LIVE
    case !!queryParam.redirect:
      return FUNNEL_TYPE.PREMIUM
    default:
      return FUNNEL_TYPE.GENERIC
  }
}

function mapUserToChartBeat(user: any) {
  return chartBeatUser[user]
}

export const chartBeatUser: any = {
  free: 'lgdin',
  anon: 'anon',
  isPremium: 'paid',
  isAdmin: 'internal'
}
