import { HttpClient, HttpParams } from '@angular/common/http'
import { PlatformService } from './platform.service'
import { Injectable } from '@angular/core'
import { take } from 'rxjs/operators'
import { EXPERIENCE_API_LEGACY_CORE_NODES_PATH, EXPERIENCE_API_VERSION } from '../app.config'

export interface IViewCountService {
  /**
   * Sends a PATCH request to increase the view_count by 1.
   */
  incrementViewCount(nodeId: number): void
}

@Injectable({
  providedIn: 'root'
})
export class ViewCountService implements IViewCountService {
  constructor(private http: HttpClient, private ps: PlatformService) {}

  public incrementViewCount(nodeId: number) {
    if (this.ps.isBrowser) {
      const params = new HttpParams({
        fromObject: {
          version: EXPERIENCE_API_VERSION
        }
      })
      this.http
        .patch(`${EXPERIENCE_API_LEGACY_CORE_NODES_PATH}/${nodeId}/views`, undefined, { params })
        .pipe(take(1))
        .subscribe()
    }
  }
}
