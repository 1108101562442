<div class="text-center" data-test="error-state">
  <p class="headline bold">Uh oh! We failed to load content for this section.</p>
  <p class="headline" *ngIf="!btnPrimaryText">Reload to try again.</p>
  <button floAnalytics [elementName]="btnPrimarySegmentName || 'Reload Clicked'" data-test="error-primary-button" class="btn btn-primary mt-2" (click)="handlePrimaryClicked()">{{btnPrimaryText || 'Reload' }}</button>
  <div *ngIf="!!btnSecondaryText" class="mt-3">
    <button
      data-test="error-secondary-button"
      class="btn btn-link color-500 hover-grey-500"
      floAnalytics
      [elementName]="btnSecondarySegmentName || 'Secondary Link Clicked'"
      (click)="btnSecondaryClicked.emit()">{{ btnSecondaryText }}</button>
  </div>
</div>
