<ng-container *ngIf="action?.url; else showMatchReplayText">
  <a
    floAnalytics
    tagName="Link"
    [elementName]="'Score Bug'"
    class="d-block text-center footnote color-900 primary-color-text-hover"
    [floCustomLink]="action?.url"
    data-test="wrestling-match-replay-link"
  >

    <div class="font-bold text-decoration-underline apply-text-hover">{{ outcome }}</div>
    <div class="text-decoration-underline apply-text-hover mb-1" data-test="overtime-link">{{ overtime }}</div>
    <span class="img-overlay-badge caption bg-white border border-grey-300">
      <span class="color-500">{{ weightClass }}</span>
    </span>
  </a>
</ng-container>

<ng-template #showMatchReplayText>
  <div class="text-center footnote color-900" data-test="wrestling-match-replay-text">
    <div class="font-bold">
      {{ outcome }}
    </div>
    <div class="mb-1" data-test="overtime-link">
      {{ overtime }}
    </div>
    <span class="img-overlay-badge caption bg-white border border-grey-300">
      <span class="color-500">{{ weightClass }}</span>
    </span>
  </div>
</ng-template>
