import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MessageComponent } from './message.component'
import { AnalyticsModule } from '../../shared/analytics/analytics.module'
import { RouterModule } from '@angular/router'

@NgModule({
  imports: [CommonModule, AnalyticsModule, RouterModule],
  declarations: [MessageComponent],
  exports: [MessageComponent]
})
export class MessageModule {}
