<div
  #carouselContainer
  class="scroll-container position-relative"
  data-test="parent-container"
>
  <div
    *ngIf="scrollControls$ | async as controls"
    class="
      handle-container handle-left-container
      position-absolute
      bottom
      h-100
      align-items-center
      left
    "
    floAnalytics
    name="Navigate Filters Left"
    tagName="Button"
    data-test="left-scroll-control"
    [ngClass]="{ 'show-handler-web': controls.showLeft }"
  >
    <flo-svg-icon
      class="rotate-svg-180 ms-2 cursor-pointer color-600 icon-chevron"
      icon="icon-chevron-circle"
      width="24px"
      height="24px"
      (click)="scrollClick$.next(-controls.clientWidth)"
    ></flo-svg-icon>
  </div>
  <div
    #scrollContainer
    class="d-flex flex-row content-container position-relative px-3 px-sm-0"
    data-test="scroll-container"
  >
    <ng-container *ngFor="let item of items; let i = index; trackBy: trackById">
      <ng-container [ngSwitch]="item.style">
        <ng-container *ngSwitchCase="'dropdown'">
          <flo-dropdown
            [multiselect]="multiselect"
            [id]="item.id"
            [title]="item.title"
            [options]="item.options"
            [order]="i"
            [analytics]="item.analytics"
            (dropdownClosed)="handleDropdownClosed()"
            (optionClicked)="handleOptionClicked($event)"
            [clearable]="item.clearable"
          >
          </flo-dropdown>
        </ng-container>
        <ng-container *ngSwitchCase="'toggle'">
          <ng-container *ngIf="item.options?.length > 0">
            <flo-toggle
              [toggleFilter]="item"
              (toggledTo)="handleOptionClicked($event)"
              toggleKey="0"
            ></flo-toggle>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
    <a
      class="
        primary-color-text
        text-nowrap
        footnote
        font-weight-500
        d-flex
        align-items-center
      "
      *ngIf="showClearAll && !clearAll"
      tabIndex="0"
      name="Clear All"
      data-test="event-clear-all-filters"
      floAnalytics
      (click)="handleClearAllClicked()"
    >
      Clear All
    </a>
    <a
      class="
        primary-color-text
        text-nowrap
        footnote
        font-weight-500
        d-flex
        align-items-center
      "
      *ngIf="clearAll"
      tabIndex="0"
      name="Clear All"
      data-test="event-clear-all-filters-bff"
      floAnalytics
      [analyticsProperties]="clearAll.analytics"
      [routerLink]="[]"
      [queryParams]="clearAll?.mergeParams"
      queryParamsHandling="merge"
      (click)="handleClearAllClicked()"
    >
      {{ clearAll.title }}
    </a>
  </div>
  <div
    *ngIf="scrollControls$ | async as controls"
    class="
      handle-container handle-right-container
      position-absolute
      bottom
      h-100
      right
      justify-content-end
      align-items-center
    "
    floAnalytics
    name="Navigate Filters Right"
    tagName="Button"
    data-test="right-scroll-control"
    [ngClass]="{ 'show-handler-web': controls.showRight }"
  >
    <flo-svg-icon
      class="me-2 cursor-pointer color-600 icon-chevron"
      icon="icon-chevron-circle"
      width="24px"
      height="24px"
      (click)="scrollClick$.next(controls.clientWidth)"
    ></flo-svg-icon>
  </div>
</div>
