import { FloApiResponse } from '../shared/models/api-response.model'
import { Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { map, catchError } from 'rxjs/operators'
import { Observable, throwError } from 'rxjs'
import { LoggingService } from 'src/app/singleton-services/logging/logging.service'
import { LiveEvent } from '@flocasts/flosports30-types/dist/entity'
import { EXPERIENCE_API_LEGACY_CORE_LIVE_EVENTS_PATH, EXPERIENCE_API_VERSION } from '../app.config'

export enum EventRoutePolicy {
  AUTO = 'auto',
  V2 = '2.5',
  V3 = '3.0'
}

@Injectable({
  providedIn: 'root'
})
export class LiveEventsService {
  constructor(private http: HttpClient, private log: LoggingService) {}

  public readonly getLiveEvent = (id: number): Observable<LiveEvent> =>
    this.http
      .get<FloApiResponse<LiveEvent>>(`${EXPERIENCE_API_LEGACY_CORE_LIVE_EVENTS_PATH}/${id}`, {
        params: { version: EXPERIENCE_API_VERSION }
      })
      .pipe(
        map(a => a.data),
        catchError((err: HttpErrorResponse) => {
          this.log.warn(err.message)

          return throwError(err)
        })
      )
}
