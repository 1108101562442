<div class="fly-out-menu d-flex flex-column">
  <div class="bg-900 border-bottom border-grey-800 d-flex px-3 header">
  </div>
  <div class="bg-900 px-3 header">
  </div>
  <div class="bg-white p-4 flex-grow-1 overflow-auto">
    <div style="width: 96px; height: 12px;" class="h1-skeleton"></div>
    <div class="d-flex flex-column gap-2 p-3">
      <div *ngFor="let menuItem of [].constructor(5)">
        <div class="d-flex gap-2 pb-2">
          <div style="width: 32px" class="h1-skeleton rounded-circle"></div>
          <div class="h1-skeleton w-100"></div>
        </div>
      </div>
    </div>
    <div style="width: 96px; height: 12px;" class="h1-skeleton mt-2"></div>
    <div class="d-flex flex-column gap-2 p-3">
      <div *ngFor="let menuItem of [].constructor(25)">
        <div class="d-flex gap-2 pb-2">
          <div style="width: 32px" class="h1-skeleton rounded-circle"></div>
          <div class="h1-skeleton w-100"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-200 footer">
  </div>
</div>
<div class="fly-out-backdrop h-100 w-100 bg-900"></div>
