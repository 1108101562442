import { Meta } from '@angular/platform-browser'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { EnvironmentService } from './environment.service'
import { SiteIds } from '../shared/models/site.model'
import { UrlService } from './url.service'
import { LocationService } from './location.service'
import { PlatformService } from './platform.service'
import { filterUndefined } from '../shared/functions/filters'
import { maybe, IMaybe } from 'typescript-monads'
import { FloApiResponse, selectFloApiCollectionData } from '../shared/models/api-response.model'
import { FloQueryParamsService } from '../shared/queryparams/queryparams.service'
import { map, pluck, shareReplay, tap, catchError, take, filter, switchMap } from 'rxjs/operators'
import { CookieService } from './cookie.service'
import { EXPERIENCE_API_LEGACY_CORE_SITES_PATH, EXPERIENCE_API_VERSION } from '../app.config'
import { FEAT_SITE_SETTINGS_PASS_THROUGH_ID } from '../shared/experimentation/experiment.model'
import { SiteSettings } from '../shared/models/site-settings.model'
import { notNullOrUndefined } from '../shared/functions/type-guards'
import { getSiteSettings } from './utility/vertical-service.utility'

export interface IVerticalService {
  siteSettings$: Observable<SiteSettings>
  siteId$: Observable<number>
  siteName$: Observable<string>
  isMileSplit$: Observable<boolean>
  isTrackWrestling$: Observable<boolean>
  isVarsity$: Observable<boolean>
  isMileSplitOrVarsity$: Observable<boolean>
  isVersusVerticalWithSportData$: Observable<boolean>
  isFavoriteFeatureVertical$: Observable<boolean>
  apiUrl$: Observable<string>
  title$: Observable<string>
  tokenDomain$: Observable<string>
  coreSiteUrl$: Observable<string>
  getAllSiteCodes: Observable<ReadonlyArray<string>>
  getInfoAboutAllSites$: Observable<ReadonlyArray<Partial<ISiteInfo>>>
  getSportBySiteId(id: number): Observable<IMaybe<Partial<ISiteInfo>>>
  translate(key: string): Observable<string>
}

export interface ISiteInfo {
  readonly domain: string
  readonly id: number
  readonly name: string
  readonly code: string
  readonly host: string
  readonly active: boolean
  readonly type: string
  readonly version: number
  readonly color: string
  readonly hero_image: string
  readonly show_on_mobile: boolean
  readonly sport_name: string
}

@Injectable({
  providedIn: 'root'
})
export class VerticalService implements IVerticalService {
  constructor(
    private readonly http: HttpClient,
    private readonly urlService: UrlService,
    private readonly locationService: LocationService,
    private readonly platformService: PlatformService,
    private readonly env: EnvironmentService,
    private readonly qp: FloQueryParamsService,
    private readonly meta: Meta,
    private readonly cookieService: CookieService
  ) {}

  public readonly siteCode$ = this.qp.queryParams$.pipe(
    map<any, string>(a => a.flosite || this.urlService.parseRootDomain(this.locationService.location)),
    take(1)
  )

  public readonly siteSettings$: Observable<SiteSettings> = this.siteCode$.pipe(
    switchMap(siteCode => {
      /**
       * This was implemented here, client side, after a FloWrestling outage on 11/4/2023.
       * Since this call out for site settings can block all follow-up calls in the event
       * of heavy traffic, we've hard-coded settings here as a safety precaution.
       */
      return of(getSiteSettings(this.env.config.useProdSettings, siteCode))
    }),
    filter(notNullOrUndefined),
    tap(settingsData => {
      if (settingsData.facebook_app_id) {
        this.meta.addTag({
          property: 'fb:app_id',
          content: settingsData.facebook_app_id
        })
      }
    }),
    shareReplay(1)
  )

  public readonly apiUrl$ = this.siteSettings$.pipe(map(a => `${a.api_url}/api`))
  public readonly siteId$ = this.siteSettings$.pipe(
    map(settings => settings.site_id),
    shareReplay(1)
  )
  public readonly siteName$ = this.siteSettings$.pipe(map(a => a.site_name))
  public readonly isMileSplit$ = this.siteId$.pipe(map(a => a === SiteIds.MILESPLIT))
  public readonly isTrackWrestling$ = this.siteId$.pipe(map(a => a === SiteIds.TRACK_WRESTLING))
  public readonly isMileSplitOrVarsity$ = this.siteId$.pipe(map(a => a === SiteIds.MILESPLIT || a === SiteIds.VARSITY))
  public readonly isVarsity$ = this.siteId$.pipe(map(a => a === SiteIds.VARSITY))

  /**
   * TODO: move into stateless utility function
   */
  public readonly isVersusVerticalWithSportData$ = this.siteId$.pipe(
    map(
      a =>
        a === SiteIds.FLOWRESTLING ||
        a === SiteIds.FLOHOOPS ||
        a === SiteIds.FLOGRAPPLING ||
        a === SiteIds.FLOSOFTBALL ||
        a === SiteIds.FLOVOLLEYBALL ||
        a === SiteIds.FLOCOMBAT ||
        a === SiteIds.FLOHOCKEY ||
        a === SiteIds.FLORUGBY ||
        a === SiteIds.FLOFOOTBALL ||
        a === SiteIds.FLOBOWLING ||
        a === SiteIds.FLOBASEBALL
    )
  )
  // We will show the user preferences/favorites feature to users on these verticals
  public readonly isFavoriteFeatureVertical$ = this.siteId$.pipe(
    map(
      a =>
        a === SiteIds.FLOHOOPS ||
        a === SiteIds.FLOFOOTBALL ||
        a === SiteIds.FLOHOCKEY ||
        a === SiteIds.FLOSOFTBALL ||
        a === SiteIds.FLOGRAPPLING ||
        a === SiteIds.FLOWRESTLING ||
        a === SiteIds.FLORACING ||
        a === SiteIds.FLORUGBY ||
        a === SiteIds.FLOBASEBALL
    )
  )

  public readonly title$ = this.siteSettings$.pipe(map(a => a.site_name))
  public readonly tokenDomain$ = this.siteSettings$.pipe(
    map(a => {
      return this.env.config.domainWlOverride
        ? this.env.config.domainWlOverride
        : this.platformService.isBrowser
        ? `.${this.urlService.parseBaseDomain(this.locationService.hostname as string)}`
        : this.urlService.parseBaseDomain(a.api_url)
    })
  )
  public readonly coreSiteUrl$ = this.siteSettings$.pipe(map(a => a.core_site_url))

  /**
   * Gets basic information about all FloSport verticals.
   * Cached with shareReplay() in order to only call once per visit
   * since site information of this kind rarely changes.
   */
  public readonly getInfoAboutAllSites$ = this.getInfoAboutAllSites().pipe(shareReplay(1))

  private getInfoAboutAllSites() {
    const url = this.cookieService.hasAbForcedCookie(FEAT_SITE_SETTINGS_PASS_THROUGH_ID)
      ? `${this.env.config.endpoints.discovery}/api/${EXPERIENCE_API_LEGACY_CORE_SITES_PATH}?version=${EXPERIENCE_API_VERSION}`
      : `${this.env.config.endpoints.discovery}/api/sites`
    const params = new HttpParams({
      fromObject: { show_on_mobile: 1, limit: 30 }
    })

    return this.http.get<FloApiResponse<ReadonlyArray<Partial<ISiteInfo>>>>(url, { params }).pipe(
      selectFloApiCollectionData,
      catchError(_ => of<ReadonlyArray<Partial<ISiteInfo>>>([])),
      shareReplay(1)
    )
  }

  /**
   * Return a collection of FloSports site codes
   * ex: ['flowrestling', 'flobowling', 'flotrack]
   */
  public readonly getAllSiteCodes = this.getInfoAboutAllSites$.pipe(
    map(a => a.map(b => b.code)),
    map(filterUndefined)
  )

  public translate(key: string): Observable<string> {
    if (!key || typeof key !== 'string') {
      return this.siteName$.pipe(map(a => (a ? (a as string) : '')))
    } else if (key && key.includes('core_site_url')) {
      return this.coreSiteUrl$.pipe(map(a => (a ? key.replace('core_site_url', a) : key)))
    }
    return this.siteSettings$.pipe(
      pluck(key),
      map(a => (a ? (a as string) : key))
    )
  }

  public getSportBySiteId(siteId: number) {
    return this.getInfoAboutAllSites$.pipe(
      map(a => a.find(b => b.id === siteId)),
      map(maybe)
    )
  }
}
