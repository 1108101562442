import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { LargeContentCardComponentProps } from '../content-card.interface'

@Component({
  selector: 'flo-featured-content-card',
  templateUrl: './featured-content-card.component.html',
  styleUrls: ['./featured-content-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeaturedContentCardComponent {
  @Input() props: LargeContentCardComponentProps | undefined | null
}
