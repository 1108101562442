import { Inject, Injectable } from '@angular/core'
import { filter, map, switchMap, take } from 'rxjs/operators'
import { AuthService } from '../../../singleton-services/auth/auth.service'
import { notNullOrUndefined } from '../../functions/type-guards'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class UserIdentifierService {
  constructor(private readonly authService: AuthService) {}

  private readonly userIdentity$ = this.authService.userIdentity$.pipe(take(1))
  private readonly email$ = this.userIdentity$.pipe(map(userIdentity => userIdentity && userIdentity.email))

  public static hasStoredHash(hash: string | null) {
    const storedHash = localStorage.getItem('userHash')
    if (hash) {
      localStorage.setItem('userHash', hash)
      return hash
    } else {
      if (storedHash) {
        localStorage.removeItem('userHash')
      }
      return null
    }
  }
  public hashEmail$: Observable<string | null> = this.email$.pipe(
    switchMap(email => {
      return !!email && !!crypto.subtle
        ? crypto.subtle.digest('SHA-256', new TextEncoder().encode(email))
        : Promise.resolve(null)
    }),
    map(digest => {
      return !!digest
        ? Array.from(new Uint8Array(digest))
            .map(b => b.toString(16).padStart(2, '0'))
            .join('')
        : null
    }),
    map(hash => {
      return UserIdentifierService.hasStoredHash(hash)
    })
  )
}
