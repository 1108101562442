<div *ngIf="debug.debugOn$ | async" class="debug-menu p-2">
  <flo-experimentation-widget #expWidget></flo-experimentation-widget>
  <flo-select-input
    [options]="selectMenuItems$ | async"
    [initial]="vs.siteCode$ | async"
    (selected$)="changeVertical($event)"
  ></flo-select-input>
  <button
    class="btn btn-primary primary-color-bg w-100 mt-1"
    (click)="expWidget.toggleWindow()"
    >Experiments</button
  >
</div>
