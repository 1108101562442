import { NgModule } from '@angular/core'
import { SelectInputComponent } from './select-input.component'
import { OldSvgModule } from 'src/app/libs/_deprecated/svg/old-svg.module'

@NgModule({
  imports: [OldSvgModule],
  declarations: [SelectInputComponent],
  exports: [SelectInputComponent]
})
export class FloSelectInputModule {}
