import { Injectable, Renderer2, NgZone, Inject } from '@angular/core'
import { combineLatest, of, Subject } from 'rxjs'
import { filter, map, shareReplay, startWith, take, tap } from 'rxjs/operators'
import { PlatformService } from '../../../singleton-services/platform.service'
import { IVideo } from '../../models/video.model'
import { AdBlockService } from '@flosportsinc/ng-ad-block'
import { VerticalService } from '../../../singleton-services/vertical.service'
import { LocationService } from '../../../singleton-services/location.service'
import { AuthService } from '../../../singleton-services/auth/auth.service'
import {
  IVideoLoggingPostBody,
  VideoEventLoggingService
} from '../../video-player/services/video-event-logging.service'
import * as videoAdFunctions from '../../functions/video-ads'
import { notNullOrUndefined } from '../../functions/type-guards'
import { UserIdentifierService } from '../advertising-service/user-identifier.service'
import { WINDOW } from 'src/app/app.injections'

@Injectable()
export class VideoAdsService {
  constructor(
    private readonly zone: NgZone,
    private readonly locationService: LocationService,
    private readonly verticalService: VerticalService,
    private readonly adBlockService: AdBlockService,
    private readonly videoEventLoggingService: VideoEventLoggingService,
    private readonly authService: AuthService,
    private readonly platformService: PlatformService,
    private readonly userIdentifierService: UserIdentifierService,
    @Inject(WINDOW) private readonly window: Window
  ) {}

  private readonly dfpAccountCode$ = of('43625987')
  private readonly userIdentity$ = this.authService.userIdentity$.pipe(startWith(undefined))

  private readonly adServerBaseUrl$ = of('https://pubads.g.doubleclick.net/gampad/ads')

  private readonly _init = new Subject<Renderer2>()
  private readonly init$ = this._init.pipe(take(1))
  private readonly hashEmail$ = this.userIdentifierService.hashEmail$.pipe(take(1))
  private readonly canLoadAds$ = this.adBlockService
    .isAnAdBlockerActive()
    .pipe(map(adBlockerIsActive => this.platformService.isBrowser && !adBlockerIsActive))

  public readonly videoAdsLibrary$ = combineLatest([this.init$, this.canLoadAds$]).pipe(
    filter(([_, canLoadAds]) => canLoadAds),
    videoAdFunctions.mapGetHead(),
    videoAdFunctions.mapVideoAdsLibrary(this.window),
    filter(notNullOrUndefined),
    tap(videoAdsLibrary => videoAdsLibrary.settings.setDisableCustomPlaybackForIOS10Plus(true)),
    take(1),
    shareReplay(1)
  )
  public init = (renderer2: Renderer2) => this._init.next(renderer2)

  public getAdsLoader$ = (adDisplayContainer: google.ima.AdDisplayContainer) =>
    this.videoAdsLibrary$.pipe(
      videoAdFunctions.mapNewAdsLoader(adDisplayContainer),
      tap((adsLoader: google.ima.AdsLoader) => adsLoader.getSettings().setDisableCustomPlaybackForIOS10Plus(true))
    )

  public formatAdTagUrl$ = (adTagUrl: string) =>
    this.verticalService.siteName$.pipe(take(1), videoAdFunctions.mapFormatAdTagUrl(this.locationService, adTagUrl))

  public getAdDisplayContainer$ = (contentVideoElement: HTMLVideoElement, adContainerElement: HTMLElement) =>
    this.videoAdsLibrary$.pipe(
      videoAdFunctions.mapInitializedAdContainer(contentVideoElement, adContainerElement, this.zone)
    )

  public logAdEvent = (tokenId: string, log: IVideoLoggingPostBody) =>
    this.videoEventLoggingService.logAdEvent(tokenId, log)

  public logAdBlock = (tokenId: string, log: IVideoLoggingPostBody) =>
    this.adBlockService
      .isAnAdBlockerActive()
      .pipe(filter(Boolean), take(1))
      .subscribe(_ => this.videoEventLoggingService.logAdEvent(tokenId, log))

  public getAdsRequest$ = (adTagUrl: string, contentVideoElement: HTMLVideoElement) =>
    this.videoAdsLibrary$.pipe(videoAdFunctions.mapNewAdsRequest(adTagUrl, contentVideoElement))

  public initAdsManager = (adsManager: google.ima.AdsManager, contentVideoElement: HTMLVideoElement) =>
    combineLatest([this.zone.onStable, this.videoAdsLibrary$])
      .pipe(take(1))
      .subscribe(res => {
        this.zone.runOutsideAngular(() => {
          try {
            // Initialize the ads manager. Ad rules playlist will start at this time.
            adsManager.init(contentVideoElement.clientWidth, contentVideoElement.clientHeight, res[1].ViewMode.NORMAL)
            // Call start to show ads. Single video and overlay ads will
            // start at this time; this call will be ignored for ad rules, as ad rules
            // ads start when the adsManager is initialized.
            adsManager.start()
          } catch (adError) {
            console.error('adError', (adError as google.ima.AdError).toString())
            // An error may be thrown if there was a problem with the VAST response.
            // Play content here, because we won't be getting an ad.
            contentVideoElement.play()
          }
        })
      })

  public getPrerollAdTagUrl$ = (video: IVideo) =>
    combineLatest([
      this.verticalService.siteSettings$,
      this.dfpAccountCode$,
      this.adServerBaseUrl$,
      this.videoAdsLibrary$,
      this.userIdentity$,
      this.hashEmail$,
      this.authService.isPremium$
    ]).pipe(
      map(([siteSettings, dfpAccountCode, adServerBaseUrl, videoAdsLibrary, userIdentity, hashEmail, isPremium]) => {
        const description = this.locationService.href

        // Reminder: Varsity TV's site name has a space bar character in it.
        const siteName = siteSettings.site_name?.toLowerCase().replace(/\s+/g, '')

        const iu = `/${dfpAccountCode}/${siteName}.3/videos`

        // tslint:disable:variable-name
        const ad_ids = `ad_ids=${video.aggregated_node_ids}`
        const ad_categories = `ad_categories=${video.aggregated_category_slugs}`
        const user_is_pro = `user_is_pro=${!!userIdentity && isPremium}`
        const video_is_pro = `video_is_pro=${video.premium}`
        const ppid = hashEmail
        const cust_params = [ad_ids, ad_categories, user_is_pro, video_is_pro]
          .filter(param => param && param.length)
          .join('&')
        const defaultAdParams = {
          impl: 's',
          cust_params,
          ppid,
          gdfp_req: 1,
          env: 'vp',
          unviewed_position_start: 1,
          sz: '640x360',
          plcmt: 1,
          output: 'vast',
          tfcd: '0',
          description_url: description,
          npa: '0',
          vpmute: '1'
        }

        const adParams = { ...defaultAdParams, ad_rule: '1' }
        return {
          iu,
          adParams
        }
      }),
      shareReplay(1)
    )
}
