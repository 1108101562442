import { HttpParams } from '@angular/common/http'
import { FAVORITE_COLLECTION_TYPES } from './types'

// Important: HttpParams is immutable, MUST overwrite it each iteration.
export const getUserCustomizationParams = (site?: number, showAll: boolean = false) => {
  let params = new HttpParams()

  if (site) params = params.set('site', String(site))
  if (showAll) params = params.set('showAll', 'true')

  // create something like ?type[]='team'&type[]='person' for each collection type
  FAVORITE_COLLECTION_TYPES.forEach(type => {
    params = params.append('type[]', type as string)
  })
  return params
}
