import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap'
import { AnalyticsModule } from 'src/app/shared/analytics/analytics.module'
import { SvgModule } from 'src/app/shared/svg/svg.module'
import { DropdownModule } from '../dropdown/dropdown.module'
import { LinkModule } from '../link/link.module'
import { PrimaryNavigationComponent } from './primary-navigation.component'
import { CustomLinkModule } from '../custom-link/custom-link.directive.module'
import { RemoveTitlePipe } from './remove-title.pipe'

@NgModule({
  imports: [CommonModule, LinkModule, AnalyticsModule, DropdownModule, NgbDropdownModule, SvgModule, CustomLinkModule],
  declarations: [PrimaryNavigationComponent, RemoveTitlePipe],
  exports: [PrimaryNavigationComponent]
})
export class PrimaryNavigationModule {}
