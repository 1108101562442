import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { FunnelHeaderPromptStatus } from '../shared/funnel-checkout-header/funnel-checkout-header.component'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { BatmanDataService, CarouselBackgroundStyleGen, ResponsiveCarouselInstance } from './batman-data.service'
import { CUSTOMER_SERVICE_URL } from 'src/app/account-settings/customer-service'
import { SiteSettings } from 'src/app/shared/models/site-settings.model'
import { LiveEventStatus } from '../../events/events.enum'

export interface ILandingPageComponent {
  siteSettings: SiteSettings
  showLoginPrompt: FunnelHeaderPromptStatus
  liveEventHeader: { title: string; subtitle: string | null } | undefined
  isLiveFunnel: boolean
  liveEventRedirect: { status: LiveEventStatus; eventId: number } | undefined
}

@Component({
  selector: 'flo-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LandingPageComponent {
  @Input() public landingPageProps: ILandingPageComponent
  @Input() public readonly carouselBackgroundGen: CarouselBackgroundStyleGen
  @Input() public readonly carouselData: readonly ResponsiveCarouselInstance[]

  public CUSTOMER_SERVICE_URL = CUSTOMER_SERVICE_URL

  private _partialRender = {
    hero: true,
    calendar: true,
    carousel: true,
    devices: true,
    faq: true
  }

  @Input()
  set partialRender(config: any) {
    this._partialRender = config
  }

  get partialRender() {
    return this._partialRender
  }

  public showCarousel = false

  public analyticsProps = {
    subpage_category: 'Page Speed Experiment'
  }

  public readonly headline$: Observable<{
    isLiveFunnel: boolean
    text: string
  }> = this.batmanDataService.getCalendarHeadline$.pipe(
    map(calendarHeadline => {
      return {
        text: this.landingPageProps.isLiveFunnel ? calendarHeadline : '',
        isLiveFunnel: this.landingPageProps.isLiveFunnel
      }
    })
  )

  public constructor(private readonly batmanDataService: BatmanDataService) {}
}
