import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

import { FloPipeModule } from '../pipes/flo-pipe.module'
import { PaywallModule } from '../paywall/paywall.module'
import { AnalyticsModule } from '../analytics/analytics.module'
import { AdsModule } from '../ads/ads.module'
import { VideoPlayerComponent } from './components/video-player/video-player.component'
import { VideoPlayerByCodeComponent } from './components/video-player-by-code/video-player-by-code.component'
import { VideoScrubberControlComponent } from './components/video-scrubber-control/video-scrubber-control.component'
import { VideoPlayerControlsComponent } from './components/video-player-controls/video-player-controls.component'
import { VideoVolumeControlComponent } from './components/video-volume-control/video-volume-control.component'
import { VideoQualityControlComponent } from './components/video-quality-control/video-quality-control.component'
import { FloFullscreenModule } from '@flosportsinc/ng-fullscreen'
import { VideoErrorComponent } from './components/video-error/video-error.component'
import { VideoEventLoggingService } from './services/video-event-logging.service'
import { UnMuteButtonComponent } from './components/unmute-button/unmute-button.component'
import { KeyboardControlsModule } from '../keyboard-controls/keyboard-controls.module'
import { FullscreenDetectorModule } from '../../../../projects/fullscreen-detector/src/lib/fullscreen-detector.module'
import { OldSvgModule } from 'src/app/libs/_deprecated/svg/old-svg.module'
import { ScrollToElementOnNavigationModule } from 'src/app/libs/_directives/scroll-to-element-on-navigation/scroll-to-element-on-navigation.module'
import { FloHlsModule } from 'src/app/hls/hls.module'

@NgModule({
  imports: [
    CommonModule,
    AdsModule,
    FloPipeModule,
    PaywallModule,
    AnalyticsModule,
    OldSvgModule,
    FloFullscreenModule,
    FloHlsModule,
    KeyboardControlsModule,
    FullscreenDetectorModule,
    ScrollToElementOnNavigationModule
  ],
  declarations: [
    VideoPlayerByCodeComponent,
    VideoScrubberControlComponent,
    VideoPlayerComponent,
    VideoPlayerControlsComponent,
    VideoQualityControlComponent,
    VideoVolumeControlComponent,
    VideoErrorComponent,
    UnMuteButtonComponent
  ],
  exports: [VideoPlayerComponent, VideoPlayerByCodeComponent],
  providers: [VideoEventLoggingService]
})
export class VideoPlayerModule {}
