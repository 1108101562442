import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { FlyOutCtaAccountViewModel } from '@flocasts/experience-service-types'

@Component({
  selector: 'flo-fly-out-cta-account',
  templateUrl: './fly-out-cta-account.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlyOutCtaAccountComponent {
  @Input() ctaAccount: FlyOutCtaAccountViewModel
}
