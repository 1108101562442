import { ChangeDetectionStrategy, Component, Inject } from '@angular/core'
import { Router } from '@angular/router'
import { catchError, map, of } from 'rxjs'
import { LOGGER_SERVICE } from 'src/app/logger/logger.config'
import { LoggerService } from 'src/app/logger/logger.interface'
import { StepperModel, funnelStepper, giftStepper } from './stepper.model'

@Component({
  selector: 'flo-stepper-wrapper',
  templateUrl: './stepper-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepperWrapperComponent {
  constructor(private router: Router, @Inject(LOGGER_SERVICE) private readonly loggingService: LoggerService) {}

  public steps$ = of(this.router.url).pipe(
    map(url => {
      let actualStepper: StepperModel[] = funnelStepper

      if (url.startsWith('/gift')) {
        actualStepper = giftStepper
      }

      const currentStep: StepperModel | undefined = actualStepper.find(stepper => url.includes(stepper.path))

      if (currentStep) {
        return this.updateSteps(currentStep.id, actualStepper)
      }

      throw new Error(`Failed to render the stepper for the url: ${url}`)
    }),
    catchError(error => {
      this.loggingService.error(error.message, error.error)
      return of([])
    })
  )

  private updateSteps = (id: number, stepper: StepperModel[]): StepperModel[] => {
    return stepper.map(step => {
      if (step.id < id) {
        return { ...step, isCompleted: true, isCurrent: false }
      } else if (step.id === id) {
        return { ...step, isCompleted: false, isCurrent: true }
      } else {
        return { ...step, isCompleted: false, isCurrent: false }
      }
    })
  }
}
