import { HttpParams, HttpHeaders } from '@angular/common/http'
import { StateKey, makeStateKey } from '@angular/platform-browser'

export interface TransferHttpResponse {
  body?: any | null
  // tslint:disable-next-line:readonly-array
  headers?: { [k: string]: string[] }
  status?: number
  statusText?: string
  url?: string
}

const makeRelativeIfLocalhost = (url: string) => {
  if (!/^http/.test(url)) {
    return url
  }
  try {
    const urlParts = new URL(url)
    return urlParts.hostname === '127.0.0.1' ? urlParts.href.replace(urlParts.origin, '') : url
  } catch {
    return url
  }
}

export const makeCacheKey = (method: string, inputUrl: string, params: HttpParams): StateKey<TransferHttpResponse> => {
  const url = makeRelativeIfLocalhost(inputUrl)

  // make the params encoded same as a url so it's easy to identify
  const encodedParams =
    params &&
    params
      .keys()
      .sort()
      .map(k => `${k}=${params.get(k)}`)
      .join('&')

  const baseKey = method === 'GET' ? `G.${url}` : `H.${url}`

  const key = url.includes('?') ? `${baseKey}&${encodedParams}` : `${baseKey}?${encodedParams}`

  return makeStateKey<TransferHttpResponse>(key)
}

export type FieldFilter = {
  fieldFilter: {
    field: {
      fieldPath: string
    }
    value: {
      integerValue: string | number
    }
    op: string
  }
}
export type StructuredQuery = {
  structuredQuery: {
    where: {
      compositeFilter: {
        filters: ReadonlyArray<FieldFilter>
      }
    }
  }
}
export const makeCacheKeyForFirestorePost = (
  inputUrl: string,
  body: StructuredQuery
): StateKey<TransferHttpResponse> => {
  const url = makeRelativeIfLocalhost(inputUrl)
  let key = `P.${url}?`
  if (body?.structuredQuery?.where?.compositeFilter?.filters) {
    body.structuredQuery.where.compositeFilter.filters.map(x => {
      key = `${key}&${x.fieldFilter.field.fieldPath}${x.fieldFilter.op}${x.fieldFilter.value.integerValue}`
    })
  }

  return makeStateKey<TransferHttpResponse>(key)
}

export const getHeadersMap = (headers: HttpHeaders) => {
  const headersMap: { [k: string]: string[] } = {}
  for (const key of headers.keys()) {
    headersMap[key] = headers.getAll(key) || []
  }

  return headersMap
}
