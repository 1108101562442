import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { trackById } from '../../shared/utility-functions/track-by-id.utility'
import { LinkModel, SegmentedControlModel } from '@flocasts/experience-service-types'

@Component({
  selector: 'flo-segmented-control',
  templateUrl: './segmented-control.component.html',
  styles: [
    `
      a.btn {
        min-width: auto;
        white-space: nowrap;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SegmentedControlComponent {
  @Input()
  segmentedControl: SegmentedControlModel

  public trackById = trackById

  handleClick(option: LinkModel): void {
    // unselect previous option
    const prevOption = this.segmentedControl.options.find(x => x.state === 'selected')
    if (prevOption) {
      prevOption.state = 'unselected'
    }

    // select current option
    option.state = 'selected'
  }
}
