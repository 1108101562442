import { FunnelPageProperties, SegmentTagV2 } from '../shared/analytics/models/segment-tag.model'
import { ParamMap } from '@angular/router'

/**
 * Default funnel_type is 'Generic'.
 * The other two types refer to the content type a user will be redirected to
 * Ex: If a user wanted to watch a live event and was redirected to the funnel,
 * funnel_type is 'Live Event'
 */
export type FunnelType = 'Generic' | 'Live Event' | 'Premium Content'

export interface FunnelRouteData {
  /** Is this page part of the funnel */
  funnel: boolean

  liveEvent?: {
    id: number
  }
}

/**
 * The standard Page tags for funnel pages.
 *
 * @see SegmentService#pageV2
 * @see SegmentTagV2
 */
export const LEGACY_PLANS_SEGMENT_TAG: SegmentTagV2 = {
  page_category: 'Plans',
  category: 'Plans',
  autoPageCall: false
}
export const PAY_SEGMENT_TAG: SegmentTagV2 = {
  page_category: 'Pay',
  category: 'Pay',
  autoPageCall: false
}
export const THANK_YOU_SEGMENT_TAG: SegmentTagV2 = {
  page_category: 'Thank You',
  category: 'Thank You',
  autoPageCall: false
}

function mapToFunnelType(queryParams: ParamMap, liveId: number | undefined): FunnelType {
  switch (true) {
    case !!liveId:
      return 'Live Event'
    case queryParams.has('redirect'):
      return 'Premium Content'
    default:
      return 'Generic'
  }
}

export function getFunnelProperties(routeData: FunnelRouteData, queryParams: ParamMap): FunnelPageProperties {
  // Do we need to do an isFunnel check using the routeData.funnel boolean?
  // This should only be called from a funnel page
  const liveId = routeData && routeData.liveEvent && routeData.liveEvent.id

  return {
    _tag: 'funnel',
    funnel_type: mapToFunnelType(queryParams, liveId)
  }
}
