import { SiteIds } from 'src/app/shared/models/site.model'

/**
 * This is a map containing the header, description text, and vertical of the sub promo banners
 *
 * The promo banner pulls the data from this map to know what content and backgrounds to render
 * in the banner. Using the map here creates a clean solution for having dynamic behavior for content
 * that shares the same container component
 */

export const promoBannerDataMap = new Map<number, PromoBannerData>([
  [
    SiteIds.FLOTRACK,
    {
      header: 'Your #1 Source for Track & Field.',
      description: 'Stream hundreds of events all year long.',
      vertical: 'track'
    }
  ],
  [
    SiteIds.FLOWRESTLING,
    {
      header: 'Your #1 Source for Wrestling.',
      description: 'Stream high school, NCAA, USA Wrestling events, and more.',
      vertical: 'wrestling'
    }
  ],
  [
    SiteIds.FLOGRAPPLING,
    {
      header: 'Your #1 Source for Jiu-Jitsu.',
      description: "Stream ADCC, IBJJF, Who's Number One, and more.",
      vertical: 'grappling'
    }
  ],
  [
    SiteIds.FLOCHEER,
    {
      header: 'Your #1 Source for Cheer & Dance.',
      description: 'Stream hundreds of events all year long.',
      vertical: 'cheer'
    }
  ],
  [
    SiteIds.VARSITY,
    {
      header: 'The Ultimate Cheer & Dance Experience.',
      description: 'Stream hundreds of events all year long.',
      vertical: 'varsity'
    }
  ],
  [
    SiteIds.FLOHOCKEY,
    {
      header: 'Your #1 Source for All Things Hockey.',
      description: 'Stream ECHL, USHL, College, Juniors, and more.',
      vertical: 'hockey'
    }
  ],
  [
    SiteIds.FLORACING,
    {
      header: 'Your #1 Source for All Things Racing.',
      description: 'Stream the biggest dirt, drag, pavement, and snow races.',
      vertical: 'racing'
    }
  ],
  [
    SiteIds.FLOMARCHING,
    {
      header: 'Your #1 Source for Marching.',
      description: 'Stream the biggest Marching events all year long.',
      vertical: 'marching'
    }
  ],
  [
    SiteIds.FLORUGBY,
    {
      header: 'Your #1 Source for Rugby.',
      description: 'Stream EPCR, URC, SANZAAR, and more events all year long.',
      vertical: 'rugby'
    }
  ],
  [
    SiteIds.FLOBIKES,
    {
      header: 'Your #1 Source for All Things Cycling.',
      description: 'Stream your favorite Cycling events all year long.',
      vertical: 'bikes'
    }
  ],
  [
    SiteIds.FLOBASEBALL,
    {
      header: 'Your #1 Source for Baseball.',
      description: 'Stream thousands of games all year long.',
      vertical: 'baseball'
    }
  ]
])

export interface PromoBannerData {
  header: string
  description: string
  vertical: string
}
