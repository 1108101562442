import { FilterScrollDetails } from './types'

/**
 * @param scrollWidth the width of the child container, including content not visible on the screen due to overflow.
 * @param clientWidth the width of the child container's content that is visible
 * @param computedPadding is additional CSS padding on end elements
 */
export const calculateElementSizes = (
  scrollWidth: number,
  clientWidth: number,
  computedPadding: number
): {
  clientWidth: number
  endScrollPosition: number
} => {
  // Take into account CSS padding
  const newWidth = clientWidth + computedPadding
  return {
    clientWidth: newWidth,
    endScrollPosition: scrollWidth - newWidth // should be positive int or scrolling isn't possible
  }
}

/**
 * User's current scroll position state
 * @param scrollWidth the width of the child container, including content not visible on the screen due to overflow.
 * @param clientWidth the width of the child container's content that is visible
 * @param computedPadding is additional CSS padding on end elements
 * @param transformAmount, transform position value to animate scrolling left/right
 */
export const calculateScrollPosition = (
  scrollWidth: number,
  clientWidth: number,
  computedPadding: number,
  transformAmount: number
): number => {
  const endScrollPosition = scrollWidth - (clientWidth + computedPadding)
  // ensure we never scroll past the starting point
  if (transformAmount < 0) {
    return 0
  }

  if (transformAmount >= endScrollPosition) {
    return endScrollPosition
  } else {
    return transformAmount
  }
}

export const calculateScrollControls = (
  scrollWidth: number,
  clientWidth: number,
  padding: number,
  scrollPosition: number
): FilterScrollDetails => {
  const endScrollPosition = scrollWidth - (clientWidth + padding)
  const cWidth = clientWidth + padding

  return {
    endScrollPosition,
    clientWidth: cWidth,
    showLeft: scrollPosition !== 0,
    showRight: scrollPosition < endScrollPosition
  }
}
