import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { NavComponent } from './nav.component'
import { FloCssClassSanitizePipeModule } from 'src/app/libs/_pipes/css-class-sanitize/css-class-sanitize.pipe.module'
import { RouterModule } from '@angular/router'
import { AnalyticsModule } from 'src/app/shared/analytics/analytics.module'
import { LinkModule } from '../link/link.module'
import { SvgModule } from 'src/app/shared/svg/svg.module'
import { CustomLinkModule } from '../custom-link/custom-link.directive.module'
import { SubNavComponent } from './nav-sub/nav-sub.component'
import { FlyOutNavComponent } from './nav-fly-out/nav-fly-out.component'

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    AnalyticsModule,
    FloCssClassSanitizePipeModule,
    LinkModule,
    SvgModule,
    CustomLinkModule
  ],
  exports: [NavComponent],
  declarations: [NavComponent, SubNavComponent, FlyOutNavComponent]
})
export class NavModule {}
