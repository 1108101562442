import { Pipe, PipeTransform } from '@angular/core'
import { CardModel, VodMetadata } from '@flocasts/experience-service-types'
import { convertSecondsToDuration } from '../../shared/functions/date-time'
import { SmallContentCardComponentProps } from '../../libs/content-card/small-content-card/small-content-card.component'

/**
 * Pipe to transform a ContinueWatching item to a CardModel.
 */
@Pipe({ name: 'cwItemToSmallContentCardModel' })
export class CwItemToSmallContentCardModelPipe implements PipeTransform {
  public transform(item: CardModel): SmallContentCardComponentProps {
    return {
      id: `small-card-${item.id}`,
      type: 'video',
      title: item?.title ?? null,
      style: item.style,
      previewVideoMetadata: item.previewVideoMetadata,
      subtitle1: null,
      subtitle2: null,
      subtitle1Parts: null,
      showPlayIcon: true,
      description: null,
      previewImage: {
        url: item.previewVideoMetadata?.assetUrl ?? '',
        id: 'non-unique-id',
        type: item.previewVideoMetadata?.type || 'image',
        altText: null,
        style: 'standard',
        zoom: 1
      },
      label1: this.setLabel(item.previewVideoMetadata),
      iconImage: null,
      progress: this.setProgressBar(item.previewVideoMetadata),
      action: item.action
    }
  }

  private setLabel = (vodData: VodMetadata | null): string | null =>
    vodData?.duration ? convertSecondsToDuration(vodData.duration, vodData.progressSeconds) : null

  private setProgressBar = (vodData: VodMetadata | null): string | undefined => {
    return vodData && vodData.progressSeconds && vodData.progressSeconds > 0 && vodData.duration
      ? String(((vodData.progressSeconds / vodData.duration) * 100).toFixed(2)) + '%'
      : undefined
  }
}
