import { Injectable } from '@angular/core'
import {
  GiftingPageProperties,
  SegmentPageProperties,
  SegmentTagV2
} from '../shared/analytics/models/segment-tag.model'
import { SegmentService } from '../shared/analytics/services/segment.service'

/**
 * The type of user in a gift subscription.
 * Gifter is the person who is giving/purchasing the gift.
 * Giftee is the person who is receiving/redeeming the gift.
 * Landing is the landing page where the user is could be either the gifter or giftee.
 */
export type GiftUserType = 'Gifter' | 'Giftee' | 'Landing'

/**
 * The standard Page tag for the Gift Subscription landing page.
 *
 * @remarks
 * This is expected to be used with pageV2, as indicated by the use of the
 * SegmentTagV2 interface and `autoPageCall: false`.
 *
 * @see SegmentService#pageV2
 * @see SegmentTagV2
 */
export const GIFT_SUBSCRIPTION_ANALYTICS_TAG: SegmentTagV2 = {
  page_category: 'Gift Sub',
  subpage_category: 'Landing',
  category: 'Gift Sub',
  autoPageCall: false
}
export const GIFT_SUBSCRIPTION_OFFERS_ANALYTICS_TAG: SegmentTagV2 = {
  page_category: 'Gift Sub',
  subpage_category: 'Offers',
  category: 'Gift Sub',
  autoPageCall: false
}
export const GIFT_SUBSCRIPTION_INFO_ANALYTICS_TAG: SegmentTagV2 = {
  page_category: 'Gift Sub',
  subpage_category: 'Info',
  category: 'Gift Sub',
  autoPageCall: false
}
export const GIFT_SUBSCRIPTION_PAY_ANALYTICS_TAG: SegmentTagV2 = {
  page_category: 'Gift Sub',
  subpage_category: 'Pay',
  category: 'Gift Sub',
  autoPageCall: false
}
export const GIFT_SUBSCRIPTION_THANK_YOU_ANALYTICS_TAG: SegmentTagV2 = {
  page_category: 'Gift Sub',
  subpage_category: 'Thank You',
  category: 'Gift Sub',
  autoPageCall: false
}
export const GIFT_SUBSCRIPTION_REDEEM_ANALYTICS_TAG: SegmentTagV2 = {
  page_category: 'Gift Sub',
  subpage_category: 'Redeem',
  category: 'Gift Sub',
  autoPageCall: false
}
export const GIFT_SUBSCRIPTION_CHOOSE_SPORT_ANALYTICS_TAG: SegmentTagV2 = {
  page_category: 'Gift Sub',
  subpage_category: 'Choose Sport',
  category: 'Gift Sub',
  autoPageCall: false
}
export const GIFT_SUBSCRIPTION_OVERVIEW_ANALYTICS_TAG: SegmentTagV2 = {
  page_category: 'Gift Sub',
  subpage_category: 'Overview',
  category: 'Gift Sub',
  autoPageCall: false
}

/**
 * Handler for gift subscription analytics activities like page/track calls.
 *
 * @see SegmentService
 * @see SegmentTagV2
 */
@Injectable()
export class GiftSubscriptionAnalyticsService {
  constructor(private readonly segmentService: SegmentService) {}

  /**
   * Send a page call for the Gift Subscription landing page.
   */
  public sendGiftPageCall(tag: SegmentTagV2, giftUserType: GiftUserType): void {
    const pageProperties: GiftingPageProperties = {
      _tag: 'gift-sub',
      user: giftUserType
    }

    return this.segmentService.pageV2(tag, pageProperties)
  }
}
