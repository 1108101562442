import { ChangeDetectionStrategy, Component } from '@angular/core'
import { ServerResponseService } from '../shared/services/server-response.service'

@Component({
  selector: 'flo-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotFoundComponent {
  constructor(private responseService: ServerResponseService) {
    this.responseService.setNotFound()
  }
}
