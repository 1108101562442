<ng-container [ngSwitch]="theme">
  <flo-event-collection-skeleton
    *ngSwitchCase="'collection'"
  ></flo-event-collection-skeleton>
  <flo-event-collection-skeleton
    *ngSwitchCase="'event-hub'"
  ></flo-event-collection-skeleton>
  <flo-standings-list-skeleton
    *ngSwitchCase="'standings-list'"
  ></flo-standings-list-skeleton>
  <flo-standings-list-view-all-skeleton
    *ngSwitchCase="'standings-list-view-all'"
  ></flo-standings-list-view-all-skeleton>
  <flo-search-skeleton
    *ngSwitchCase="'search-page'"></flo-search-skeleton>
  <flo-favorite-skeleton
    *ngSwitchCase="'choose-favorites'"
  ></flo-favorite-skeleton>
  <flo-category-list-skeleton
    *ngSwitchCase="'category-list'"
  ></flo-category-list-skeleton>
  <flo-entity-hub-skeleton
    *ngSwitchCase="'entity-hub'"
  ></flo-entity-hub-skeleton>
  <flo-fly-out-skeleton
    *ngSwitchCase="'fly-out'"
  ></flo-fly-out-skeleton>
  <flo-my-stuff-skeleton *ngSwitchCase="'my-stuff'">
  </flo-my-stuff-skeleton>
  <flo-vertical-buttons-skeleton *ngSwitchCase="'two-vertical-buttons'">
  </flo-vertical-buttons-skeleton>
  <flo-vertical-buttons-full-width-skeleton *ngSwitchCase="'vertical-buttons-full-width'">
  </flo-vertical-buttons-full-width-skeleton>
  <flo-horizontal-buttons-skeleton *ngSwitchCase="'two-horizontal-buttons'">
  </flo-horizontal-buttons-skeleton>
</ng-container>
