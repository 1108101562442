  <div
    role="menu"
    [id]="id"
    [attr.aria-labelledby]="id"
    data-test="dropdown-list"
    *ngIf="options?.length"
  >
  <ng-container *ngFor="let option of options; trackBy: trackById">
    <ng-container *ngIf="routeWithActionUrls else noLink">
      <a
      floAnalytics
      [analyticsProperties]="option.action?.analytics ?? { parent: title }"
      tagName="Button"
      [elementName]="option.title"
      [eventName]="option.isSelected ? dropDownOptionDeselected : dropDownOptionSelected"
      [floCustomLink]="option.action?.url"
      [customLinkQueryParams]="option.action?.queryParams"
      tabIndex="-1">
        <button
        class="subhead"
        [class.primary-color-text]="option.isSelected"
        data-test="dropdown-link"
        ngbDropdownItem
        >
          <span class="dropdown-title">{{ option.title }}</span>
          <span *ngIf="option.isSelected && clearable" class="close-icon">
            <flo-svg-icon
              icon="icon-times"
              width="20px"
            ></flo-svg-icon>
          </span>
        </button>
      </a>
    </ng-container>
    <ng-template #noLink>
      <button
      class="subhead"
      [class.primary-color-text]="option.isSelected"
      ngbDropdownItem
      (click)="clickOption(option)"
      data-test="dropdown-option"
      floAnalytics
      [eventName]="option.isSelected ? dropDownOptionDeselected : dropDownOptionSelected"
      [elementName]="option.title"
      [analyticsProperties]="option.action?.analytics ?? { parent: title }"
    >
      <span class="dropdown-title">{{ option.title }}</span>
      <span *ngIf="option.isSelected && clearable" class="close-icon">
        <flo-svg-icon
          icon="icon-times"
          width="20px"
        ></flo-svg-icon>
      </span>
    </button>
    </ng-template>
  </ng-container>
  </div>
