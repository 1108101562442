import { ChangeDetectionStrategy, Component, Output, EventEmitter, Input, OnChanges } from '@angular/core'
import { AnalyticsModel, LinkModel } from '@flocasts/experience-service-types'
import { SegmentEvents } from 'src/app/shared/analytics/models/segment-events.model'
import { trackById } from 'src/app/shared/utility-functions/track-by-id.utility'

export interface DropdownOption {
  id: string | number
  title: string
  isSelected: boolean | null | undefined
  action?: LinkModel
  analytics?: AnalyticsModel | null
}

export interface Dropdown {
  id: string | number
  title: string
  options: DropdownOption[]
}
@Component({
  selector: 'flo-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownComponent implements OnChanges {
  /**
   * Unique identifier of the dropdown, useful when emitting back to the parent cmp
   */
  @Input()
  id: string | number
  /**
   * The initial value of the dropdown button
   */
  @Input()
  title = 'Select'
  /**
   * The dropdown options
   */
  @Input()
  options: DropdownOption[] = []
  /**
   * When true, keeps the dropdown open so multiple options can be selected
   */
  @Input()
  multiselect = false
  /**
   * Used for analytics so we know in what order each dropdown is diplayed,
   * and can test engagement in different order.
   * Defaults to zero if used as a single dropdown.
   */
  @Input()
  order = 0
  /**
   * Used to optionally pass in analytic parameters from the BFF.
   * If no data is passed then it will just use { item_order: order } in the html
   */
  @Input()
  analytics?: AnalyticsModel | null
  /**
   * When true, allow the user to clear the selected value
   */
  @Input()
  clearable = true
  /**
   * When true, the dropdown menu will wrap the menu items
   * in anchor tags for user routing
   */
  @Input()
  routeWithActionUrls = false

  /**
   * Emits the updated dropdown id, title, and options when the dropdown is closed
   */
  @Output()
  dropdownClosed = new EventEmitter<Dropdown>()
  /**
   * Emits the selected option each time an option is selected or deselected
   */
  @Output()
  optionClicked = new EventEmitter<DropdownOption>()

  /**
   * How many options have been selected
   */
  optionsSelectedCount = 0
  /**
   * If single select, the title of the selected option
   */
  selectedOptionTitle?: string
  /**
   * True if the dropdown is open, used for rotating chevron icon
   */
  dropdownOpen = false

  dropDownOptionSelected: string = SegmentEvents.DROPDOWN_OPTION_SELECTED
  dropDownOptionDeselected: string = SegmentEvents.DROPDOWN_OPTION_DESELECTED

  ngOnChanges(): void {
    // Handle selection changes (titles, styling)
    this.onSelectionChange()
  }

  /**
   * Update styles, titles, and other dropdown metadata
   *
   * We want to update styles and titles in a few different situations
   * 1. on initial render
   * 2. on changing tabs
   * 3. on click of an option
   *
   * We can handle 1 and 2 in `ngOnChanges` and 3 in our `clickOption` handler
   */
  onSelectionChange(option?: DropdownOption) {
    this.optionsSelectedCount = this.options.filter(x => x.isSelected).length

    if (!this.multiselect) {
      // If single select and given a selected option, set the dropdown title and deselect the other options
      if (option) {
        this.selectedOptionTitle = option.isSelected ? option.title : undefined
        // Deselect all previously selected options
        this.options.map(x => {
          if (x.id !== option.id) x.isSelected = false
          return x
        })
      } else {
        // Not given a particular option that we've selected
        // Find the selected option if it exists and set the title. Otherwise clear selected title
        const title: string | undefined = this.options.find(x => x.isSelected)?.title
        this.selectedOptionTitle = title ? title : undefined
      }
    }
  }

  /**
   * Click event that fires when an option has been selected
   * @param option The option clicked.
   */
  clickOption(option: DropdownOption) {
    this.onSelectionChange(option)

    // Emit the selected option to the parent cmp
    this.optionClicked.emit(option)
  }

  /**
   * Event that fires when the dropdown opens and closes (triggered by ngb-bootstrap directive)
   * @param isOpened Boolean to indicate if the dropdown is open or closed
   */
  handleOpenChange(isOpened: boolean) {
    // Property used to style the dropdown open/close state
    this.dropdownOpen = isOpened

    // If dropdown has closed, emit the entire dropdown object to the parent cmp
    if (!isOpened) {
      this.dropdownClosed.emit({ id: this.id, title: this.title, options: this.options })
    }
  }

  // Track by to reduce DOM repainting as new options are dynamically loaded
  readonly trackById = trackById
}
