<div *ngIf="listItems" class="d-flex flex-column">
  <h3 *ngIf="listItems.title" class="h6 ps-3 color-gray-900 text-uppercase" data-test="titled-list-title">{{ listItems.title.text }}</h3>
  <div *ngFor="let item of listItems.items; trackBy: trackById; let first = first;"
       class="border-bottom border-grey-300 "
       [ngClass]="{'border-md-top': first}"
       data-test="titled-list-items">
    <div class="ps-3" *ngIf="item.type === 'cell:decorated-text-web'">
      <flo-decorated-text-cell [item]="item"></flo-decorated-text-cell>
    </div>
    <div *ngIf="item.type === 'link'">
      <flo-link *ngIf="item.type === 'link'" [link]="item" class="h-100"></flo-link>
    </div>
  </div>
</div>


