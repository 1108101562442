/**
 * Allows tracking breaking changes to the performance calculations
 *
 * If you make a breaking change to the way performance is calculated, then this
 * constant MUST be updated. Not doing so will invalidate experiment results
 * which rely on these values. Rather than restarting experiments if a change
 * needs to be made, we can bump this version and use new performance metrics
 * only. This is not ideal, but the data team can leverage this value to make
 * decisions about calculating performance metrics.
 */
export const PERFORMANCE_CALCULATION_VERSION = 1

export type ITiming = Omit<PerformanceTiming, 'toJSON'>

export interface IPerformance {
  dom_interactive: number
  dom_content_loaded: number
  dom_complete: number
  ttfb: number
  dns: number
  calculation_version: number
}

export const timeToInteractive = (t: ITiming): number => {
  return t.domInteractive - t.domLoading
}

export const domContentLoaded = (t: ITiming): number => {
  return t.domContentLoadedEventStart - t.domLoading
}

export const domContentComplete = (t: ITiming): number => {
  return t.domComplete - t.domLoading
}

export const dnsLookupTime = (t: ITiming): number => {
  return t.domainLookupEnd - t.domainLookupStart
}

export const tcpConnectionTime = (t: ITiming): number => {
  return t.connectEnd - t.connectStart
}

export const timeToFirstByte = (t: ITiming): number => {
  return t.responseStart - t.navigationStart
}

export const getPerformanceMeasurements = (t: ITiming): IPerformance => {
  return {
    ttfb: timeToFirstByte(t),
    dom_interactive: timeToInteractive(t),
    dom_content_loaded: domContentLoaded(t),
    dom_complete: domContentComplete(t),
    dns: dnsLookupTime(t),
    calculation_version: PERFORMANCE_CALCULATION_VERSION
  }
}
