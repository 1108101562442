import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core'
import { EventCardModel } from '@flocasts/experience-service-types'
import { BtnClass, calculateBtnCss } from '../event-card.utility'

@Component({
  selector: 'flo-event-card-vertical',
  templateUrl: './event-card-vertical.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventCardVerticalComponent implements OnInit {
  @Input()
  public item: EventCardModel

  @Input()
  geoBlockedIds: ReadonlyArray<number> | null

  public cta1Class: BtnClass
  public cta2Class: BtnClass

  public isCta1GeoBlockableId?: number
  public isCta2GeoBlockableId?: number

  ngOnInit(): void {
    this.cta1Class = calculateBtnCss(this.item.cta1?.style || '')
    this.cta2Class = calculateBtnCss(this.item.cta2?.style || '')

    const geoBlockableCtas = ['Watch', 'Replays']
    this.isCta1GeoBlockableId =
      this.item.cta1?.title && geoBlockableCtas.includes(this.item.cta1.title)
        ? this.item.liveEventMetadata?.liveEventId
        : undefined
    this.isCta2GeoBlockableId =
      this.item.cta2?.title && geoBlockableCtas.includes(this.item.cta2.title)
        ? this.item.liveEventMetadata?.liveEventId
        : undefined
  }
}
