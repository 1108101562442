import { Inject, Injectable, NgZone } from '@angular/core'
import { WINDOW } from 'src/app/app.injections'
import { LOGGER_SERVICE } from 'src/app/logger/logger.config'
import { LoggerService } from 'src/app/logger/logger.interface'
import { Observable, ReplaySubject } from 'rxjs'
import { filter, map, shareReplay, take } from 'rxjs/operators'
import { ApsSlotData, ApsTargetedSlot } from './aps-slot-data'
import { UserIdentifierService } from './user-identifier.service'
import { notNullOrUndefined } from '../../functions/type-guards'

@Injectable({
  providedIn: 'root'
})
export class ApsService {
  private readonly init = new ReplaySubject<void>(1)

  /**
   * Emits true when aps is initialized and ready for bidding.
   *
   * When running bids, none should be requested until this observable emits true.
   * It will only emit once, and then the value will remain.
   */
  public readonly apsInitialized$: Observable<true> = this.init.pipe(
    take(1),
    map(_ => true),
    shareReplay<true>(1)
  )

  /** Check to ensure that the window actually has apstag library on it. */
  public hasApsTag(u: unknown): u is ApsTagProvider {
    return (
      typeof u === 'object' &&
      u !== null &&
      'apstag' in u &&
      typeof (u as { apstag: unknown }).apstag === 'object' &&
      (u as { apstag: object }).apstag !== null &&
      'init' in (u as { apstag: object }).apstag
    )
  }
  private get apstag(): ApsTag {
    return (this.window as any).apstag
  }
  private static ApsHashedRecords(hashedRecord: ApsHashedRecords) {
    return {
      type: hashedRecord.type,
      record: hashedRecord.record
    }
  }
  // prettier-ignore
  constructor(
    @Inject(WINDOW) private readonly window: unknown,
    @Inject(LOGGER_SERVICE) private readonly log: LoggerService,
    private readonly ngZone: NgZone,
    private readonly userIdentifier: UserIdentifierService
  ) {
    if (!this.hasApsTag(window)) {
      this.log.fatal('Could not obtain apstag from the window.', {
        windowType: typeof window,
        apstag: (window as any)?.apstag
      })
    } else {
      this.userIdentifier.hashEmail$.pipe(filter(notNullOrUndefined), take(1)).subscribe(hashedEmail => {
        const _hashedRecords: ApsHashedRecords = { type: 'email', record: hashedEmail }
        const tokenConfig: TokenConfigOpts = {
          hashedRecords: [ApsService.ApsHashedRecords(_hashedRecords)],
          optOut: false
        }
        this.renewId(tokenConfig).pipe(take(1)).subscribe()
      })
      this.init.next()
    }
  }

  /**
   * Create an observable which emits when bid fetch is complete.
   *
   * @see {@link https://ams.amazon.com/webpublisher/uam/docs/reference/api-reference.html#apstagfetchbidsbidconfig-callback ApsTag Docs}
   */
  public fetchBids(options: FetchBidsOpts): Observable<readonly ApsTargetedSlot[]> {
    return this.ngZone.runOutsideAngular(
      () =>
        new Observable(observer => {
          this.apstag.fetchBids(options, slots => {
            this.log.trace('APS: Fetch display bids')
            observer.next(slots)
            observer.complete()
          })
        })
    )
  }

  /**
   * @see {@link https://ams.amazon.com/webpublisher/uam/docs/reference/api-reference.html#setDisplayBids ApsTag Docs}
   */
  public setDisplayBids(): Observable<void> {
    return new Observable(subscriber => {
      this.ngZone.runOutsideAngular(() => {
        const none = this.apstag.setDisplayBids()
        this.log.trace('APS: Set display bids on googletag.')
        subscriber.next(none)
        subscriber.complete()
      })
    })
  }

  /**
   * Renews hash IDs for Amazon Audience
   * @see {@link https://flocasts.atlassian.net/browse/FLO-14888}
   */
  public renewId(tokenConfig: TokenConfigOpts): Observable<void> {
    return this.ngZone.runOutsideAngular(
      () =>
        new Observable(subscriber => {
          this.apstag.renewId(tokenConfig, () => {
            this.log.trace('APS: Renew Amazon Audience')
            subscriber.next()
            subscriber.complete()
          })
        })
    )
  }

  /**
   * Update Amazon Audience with hash IDs
   * @see {@link https://flocasts.atlassian.net/browse/FLO-14888}
   */
  public updateId(tokenConfig: TokenConfigOpts): Observable<void> {
    return this.ngZone.runOutsideAngular(
      () =>
        new Observable(subscriber => {
          this.apstag.updateId(tokenConfig, () => {
            this.log.trace('APS: Update Amazon Audience')
            subscriber.next()
            subscriber.complete()
          })
        })
    )
  }

  /**
   * Delete hash ID from Amazon Audience
   * @see {@link https://flocasts.atlassian.net/browse/FLO-14888}
   */
  public deleteId(): Observable<void> {
    return new Observable(subscriber => {
      this.ngZone.runOutsideAngular(() => {
        this.apstag.deleteId()
        this.log.trace('APS: Delete Amazon Audience hash records')
        subscriber.next()
        subscriber.complete()
      })
    })
  }
}

interface ApsTagProvider {
  apstag: ApsTag
}

/**
 * @see {@link https://ams.amazon.com/webpublisher/uam/docs/reference/api-reference.html ApsTag Docs}
 */
interface ApsTag {
  /**
   * @see {@link https://ams.amazon.com/webpublisher/uam/docs/reference/api-reference.html#apstaginitconfig-callback ApsTag Docs }
   */
  init(options: InitOpts, cb?: () => void): void

  fetchBids(options: FetchBidsOpts, cb?: (slots: readonly ApsTargetedSlot[]) => void): void

  setDisplayBids(): void

  renewId(options: TokenConfigOpts, cb?: () => void): void
  updateId(options: TokenConfigOpts, cb?: () => void): void
  deleteId(cb?: () => void): void
}

interface ApsHashedRecords {
  type: string
  record: string
}

interface TokenConfigOpts {
  gdpr?: {
    enabled: boolean
    consent?: string
  }
  optOut?: boolean
  hashedRecords: ReadonlyArray<ApsHashedRecords>
  duration?: number
}

interface InitOpts {
  pubID: string
  adServer: string
  bidTimeout: number
}

interface FetchBidsOpts {
  slots: ReadonlyArray<ApsSlotData>
  timeout?: number
}
