import { Observable, of } from 'rxjs'
import { HttpParams } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { catchError, filter, map, switchMap } from 'rxjs/operators'
import { LOGGER_SERVICE } from '../../logger/logger.config'
import { LoggerService } from '../../logger/logger.interface'
import { EXPERIENCE_API_NAVIGATION_BASE_PATH, EXPERIENCE_API_VERSION } from '../../app.config'
import {
  WebFlyOutNavigationPartial,
  WebFlyOutViewModel,
  WebNavigationPartial,
  WebNavigationViewModel
} from '@flocasts/experience-service-types'
import { isFlyOutViewModel, isNavigationViewModel } from 'src/app/shared/functions/type-guards'
import { CacheDataService } from 'src/app/singleton-services/cache-data.service'
import { VerticalService } from 'src/app/singleton-services/vertical.service'
import { SiteIds } from 'src/app/shared/models/site.model'
import { stripQueryParams } from '../url/url.functions'

/**
 * Service to fetch data needed to populate the navigation from experience-service
 */
@Injectable()
export class SiteNavigationDataService {
  constructor(
    private readonly cacheDataService: CacheDataService,
    private readonly verticalService: VerticalService,
    @Inject(LOGGER_SERVICE) private readonly logger: LoggerService
  ) {}

  /**
   * Fetch navigation data from Experience Service's navigation partial endpoint.
   */
  public getNav(route: string, getNavV2: boolean): Observable<WebNavigationViewModel | undefined> {
    return this.verticalService.siteId$.pipe(
      switchMap(siteId => {
        const params = new HttpParams({
          fromObject: {
            version: EXPERIENCE_API_VERSION,
            site_id: siteId || SiteIds.FLODOGS,
            useCoreApi: getNavV2,
            route: stripQueryParams(route)
          }
        })

        const endpoint = EXPERIENCE_API_NAVIGATION_BASE_PATH
        return this.cacheDataService.wrapper<WebNavigationPartial>(endpoint, params, true).pipe(
          map(res => res?.data),
          catchError(err => {
            this.logger.error(err.message, err.error)
            return of({} as WebNavigationViewModel)
          }),
          filter(isNavigationViewModel)
        )
      })
    )
  }

  /**
   * Fetch fly out data from Experience Service's navigation flyout partial endpoint.
   */
  public getFlyOut(inGiftVariation: boolean): Observable<WebFlyOutViewModel | undefined> {
    return this.verticalService.siteId$.pipe(
      switchMap(siteId => {
        const params = new HttpParams({
          fromObject: {
            version: EXPERIENCE_API_VERSION,
            site_id: siteId || SiteIds.FLODOGS,
            showGifting: inGiftVariation
          }
        })

        const endpoint = `${EXPERIENCE_API_NAVIGATION_BASE_PATH}/flyout`
        return this.cacheDataService.wrapper<WebFlyOutNavigationPartial>(endpoint, params, true).pipe(
          map(res => res?.data),
          catchError(err => {
            this.logger.error(err.message, err.error)
            return of({} as WebFlyOutViewModel)
          }),
          filter(isFlyOutViewModel)
        )
      })
    )
  }
}
