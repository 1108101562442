<div
  *ngIf="item"
  class="event-card d-flex flex-column h-100"
>
  <div class="event-card-status event-status subhead mb-1" data-test="event-status">
    <flo-event-status [props]="item.label1Parts" showBadge="true"></flo-event-status>
  </div>
  <div class="event-card-main mb-3 primary-color-text-hover">
    <a
      floAnalytics
      [analyticsProperties]="item.action?.analytics"
      class="event-card-main__title"
      [floCustomLink]="item.action?.url"
      data-test="title"
    >
      <h4 class="text-line-clamp-2 heavy apply-text-hover mb-3">{{ item.title }}</h4>
    </a>
    <a
      floAnalytics
      [analyticsProperties]="item.action?.analytics"
      class="event-card-main__image image w-100 h-auto cursor-pointer"
      [floCustomLink]="item.action.url"
    >
      <flo-image-thumbnail [imageUrl]="item.previewImage?.url"
                           [altText]="item.title"
                           imageSize="528"
                           [showPlayIcon]="false">
      </flo-image-thumbnail>
    </a>
  </div>
  <ng-container *ifLoaded="geoBlockedIds; template: 'two-horizontal-buttons'">
    <div class="event-card-buttons d-flex flex-row-reverse align-self-stretch">
      <div
        *ngIf="item.cta1 && item.cta1.url && !geoBlockedIds?.includes(isCta1GeoBlockableId)"
        class="event-card-buttons__cta-1 flex-fill"
      >
        <a
          floAnalytics
          eventName="Button Clicked"
          [analyticsProperties]="item.cta1?.analytics"
          [floCustomLink]="item.cta1.url"
          data-test="event-card-cta-1"
        >
          <button [ngClass]="cta1Class" class="btn btn-sm w-100">{{ item.cta1.title }}</button>
        </a>
      </div>
      <div
        *ngIf="item.cta2 && item.cta2.url && !geoBlockedIds?.includes(isCta2GeoBlockableId)"
        class="event-card-buttons__cta-2 flex-fill pe-35"
      >
        <a
          floAnalytics
          eventName="Button Clicked"
          [analyticsProperties]="item.cta2?.analytics"
          [floCustomLink]="item.cta2.url"
          data-test="event-card-cta-2"
        >
          <button [ngClass]="cta2Class" class="btn btn-sm w-100">{{ item.cta2.title }}</button>
        </a>
      </div>
    </div>
  </ng-container>
</div>
