<flo-simple-header [siteId]="siteId$ | async"></flo-simple-header>
<div class="container">
  <flo-stepper-wrapper *ngIf="!(isEmbeddedPurchase$ | async)" data-test="stepper" ></flo-stepper-wrapper>
  <h1 data-test="funnel-title" class="funnel-title mt-25">Choose Your Plan</h1>

  <div class="funnel-button-wrapper">
    <button
      data-id="pay-button"
      floAnalytics
      name="Go To Pay"
      class="btn btn-primary selection-btn btn-lg w-100 lg-font-btn"
      (click)="goToNextPage()"
      data-test="go-to-pay-button"
      >Next
    </button>
  </div>

  <div class="main-content">
    <ng-container *ngIf="isInNewPricingArchitectureVariation$ | async; else displayOldPlans">
      <flo-offers
        data-test="offers"
        [selectedOffer]="selectedOffer$ | async"
        [offers]="offers$ | async"
      ></flo-offers>
    </ng-container>
    <ng-template #displayOldPlans>
      <flo-offers
        *ngIf="legacyPlansConfig$ | async as legacyPlansConfig"
        data-test="legacy-plans"
        [legacyPlans]="legacyPlans"
        [offers]="legacyPlansConfig.offers"
        [selectedOffer]="legacyPlansConfig.selectedOffer"
      ></flo-offers>
    </ng-template>
    <!-- only displayed on mobile view -->
    <div
      *ngIf="valueProps$ | async as valueProps"
      class="mobile-value-props d-block d-md-none"
      data-test="mobile-value-props"
    >
      <div class="plan-props-header"><h4>Included with the Selected Plan</h4></div>
      <flo-value-prop
        *ngFor="let valueProp of valueProps; trackBy: trackById"
        label="checkmark-green"
        [proposition]="valueProp"
        svgWidth="18">
      </flo-value-prop>
    </div>
  </div>
</div>
