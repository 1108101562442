import {
  Directive,
  OnInit,
  OnDestroy,
  Input,
  TemplateRef,
  ViewContainerRef,
  ComponentFactoryResolver
} from '@angular/core'
import { ReplaySubject, Subject } from 'rxjs'
import { SkeletonTheme } from '../../../libs/skeletons/skeleton/skeleton.interface'
import { takeUntil, tap, withLatestFrom } from 'rxjs/operators'
import { SkeletonComponent } from '../../../libs/skeletons/skeleton/skeleton.component'

export type IfLoadedTruthy<T = unknown> = Exclude<T, false | 0 | '' | null | undefined>

/**
 * This directive is meant to show a skeleton template while content is still loading.
 * Provide the skeleton template as the second argument.
 * Ex: *ifLoaded="true; template: 'right-rail'"
 * See tests for additional examples.
 * If there is no HTML element to add the directive to, use <ng-container *ifLoaded="condition; template='skeleton'">
 */
@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[ifLoaded]'
})
export class IfLoadedDirective<T = unknown> implements OnInit, OnDestroy {
  private skeletonTheme = new ReplaySubject<SkeletonTheme>()
  private dataLoaded = new ReplaySubject<T>()
  private readonly ngOnDestroy$ = new Subject<void>()

  /**
   * If you provide false | 0 | '' | null | undefined, the skeleton will show. Empty [] are considered loaded content
   * because we may want to show a different view for no content found
   */
  @Input('ifLoaded')
  set showSkeleton(condition: T) {
    this.dataLoaded.next(condition)
  }
  static ngTemplateGuard_showSkeleton<T>(dir: IfLoadedDirective<T>, expr: unknown): expr is IfLoadedTruthy {
    return true
  }

  /**
   * This takes the skeleton template name as a string
   */
  @Input('ifLoadedTemplate')
  set template(theme: SkeletonTheme) {
    this.skeletonTheme.next(theme)
  }

  constructor(
    private readonly templateRef: TemplateRef<HTMLElement>,
    private readonly viewContainer: ViewContainerRef,
    private readonly componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngOnInit() {
    this.dataLoaded
      .pipe(
        withLatestFrom(this.skeletonTheme),
        tap(([dataLoaded, theme]) => {
          this.viewContainer.clear()

          if (!!dataLoaded) {
            this.viewContainer.createEmbeddedView(this.templateRef)
            return
          }

          const skeletonView = this.viewContainer.createComponent(
            this.componentFactoryResolver.resolveComponentFactory(SkeletonComponent)
          )
          skeletonView.instance.theme = theme
          skeletonView.changeDetectorRef.detectChanges()
        }),
        takeUntil(this.ngOnDestroy$)
      )
      .subscribe()
  }

  ngOnDestroy() {
    this.ngOnDestroy$.next()
    this.ngOnDestroy$.complete()
  }
}
