<div *ngIf="!!offer">
  <div class="offer-header-section d-none d-md-block" [ngClass]="{'selected': isSelected}">
    <span
      *ngIf="!!offer.caption?.text"
      [ngClass]="offer.caption?.style | floCssClassSanitize"
      data-test="yearly-savings-caption"
    >{{ offer.caption?.text | uppercase }}</span>
  </div>
  <div
    class="offer-selection-card-horizontal d-none d-md-block"
    data-test="offer-selection-card-horizontal"
    [ngClass]="{ 'selected': isSelected }"
  >
    <div
      class="offer-selection-section"
      [ngClass]="{ 'selected': isSelected }"
    >
      <div class="d-flex flex-column align-items-center">
        <h3 class="offer-title" [ngClass]="{'selected': isSelected}" data-test="plan-title-desktop">
          {{ offer.title }}
        </h3>
        <h3
          class="offer-cost funnel-title d-flex"
          data-test="plan-cost-desktop">
          {{ offer.priceLabel }}<span class="d-flex flex-column per-period justify-content-center ms-1 subhead">
          <span class="per-period">/mo</span></span>
        </h3>
        <ng-container *ngIf="offer.originalPriceMonthly">
          <p class="offer-original-price-desktop" data-test="plan-original-price-desktop">
            <span>
              {{ offer.originalPriceMonthly }}
            </span>
          </p>
        </ng-container>
        <p class="font-size-2">{{ offer.subtitle }}</p>
        <label
          class="control-radio primary-color-radio"
          htmlFor="offer-selection-card"
          [ngClass]="offer?.offerMetadata.period"
        >
          <input
            id="offer-selection-card"
            type="radio"
            [checked]="isSelected"
            [value]="offer"
          />
          <div class="control-radio-overlay primary-overlay"></div>
        </label>
      </div>
    </div>
    <div class="offer-props-section d-none d-md-block">
      <flo-value-prop
        *ngFor="let bullet of offer.bulletPoints; trackBy: trackById"
        [proposition]="bullet.text"
        [label]="bullet.style"
        svgWidth="18"
      ></flo-value-prop>
    </div>
  </div>

  <!-- Mobile layout -->
  <div data-test="offer-selection-card" class="offer-selection-card-mobile d-md-none" [ngClass]="{ selected: isSelected}">
    <div class="offer-heading">
      <h5 class="heading" data-test="offer-heading">
        {{ offer.heading | uppercase }}
        <span *ngIf="offer.defaultSelection" class="heading-caption" data-test="offer-heading-caption">
          {{ offer.caption?.text | uppercase }}
        </span>
      </h5>
    </div>
    <div class="offer-title">
      <h4 class="title" data-test="offer-title">
        {{ offer.title }}
      </h4>
      <h5 class="price" data-test="offer-price">
        {{ offer.priceLabel }}
        <label class="period" data-test="offer-period">/mo</label>
      </h5>
    </div>
    <div>
      <div class="offer-details d-flex justify-content-between">
        <label data-test="offer-billing" class="text-start">{{ offer.subtitle }}</label>
        <ng-container *ngIf="offer.originalPriceMonthly">
          <p class="offer-original-price-desktop ms-1 text-end" data-test="plan-original-price-desktop">
            <span>
              {{ offer.originalPriceMonthly }}
            </span>
          </p>
        </ng-container>
      </div>
    </div>

  </div>
</div>
