<nav class="d-flex flex-column" aria-label="flyout menu navigation">
  <div class="d-flex justify-content-between align-items-center">
  <p *ngIf="nav.title && nav.title.type === 'text'" [ngClass]="nav.title.style | floCssClassSanitize" class="color-900 my-2">{{nav.title.text}}</p>
    <flo-link *ngIf="nav.control" [link]="nav.control"></flo-link>
  </div>
  <div *ngFor="let item of nav.items; trackBy: trackById;" class="flyout-nav-item-container">
    <span *ngIf="item.type === 'text'" [ngClass]="item.style | floCssClassSanitize">{{item.text}}</span>
    <flo-link *ngIf="item.type === 'link'" [link]="item" class="flyout-nav-item"></flo-link>
  </div>
</nav>
