import { HttpClient, HttpParams } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { catchError, map } from 'rxjs/operators'
import { of, Observable } from 'rxjs'
import { LOGGER_SERVICE } from 'src/app/logger/logger.config'
import { LoggerService } from 'src/app/logger/logger.interface'
import { EnvironmentService } from '../environment.service'
import {
  Category,
  SiteCombinedSelections,
  DataPagination,
  UserCustomization,
  CategoryType
} from '@flocasts/user-preferences-types'
import { CollectionEntityType } from 'src/io/models/collection'
import { getUserCustomizationParams } from './user-preference-data.utility'

const API_VERSION = 'v1'
@Injectable({
  providedIn: 'root'
})
export class UserPreferenceDataService {
  constructor(
    private readonly http: HttpClient,
    private readonly es: EnvironmentService,
    @Inject(LOGGER_SERVICE) private readonly loggingService: LoggerService
  ) {}

  public getCategoriesByUrl(
    url: string,
    page?: number, // defaults to 1
    limit?: number, // defaults to 30
    search?: string
  ): Observable<DataPagination<Category> | undefined> {
    const path = new URL(`${this.es.config.endpoints.userPrefs}${url}`)

    if (page) path.searchParams.append('page', String(page))
    if (limit) path.searchParams.append('limit', String(limit))
    if (search) path.searchParams.append('search', search)

    return this.http.get<DataPagination<Category> | undefined>(path.toString()).pipe(
      catchError(err => {
        this.loggingService.error(err.message, err.error)
        return of(undefined)
      })
    )
  }

  public getCategoryByCoreNodeId(
    coreNodeId: number,
    itemType: CategoryType | CollectionEntityType
  ): Observable<Category | undefined> {
    return this.http
      .get<Category | undefined>(
        `${this.es.config.endpoints.userPrefs}/${API_VERSION}/categories/externalId/${itemType}/${coreNodeId}`
      )
      .pipe(
        catchError(err => {
          this.loggingService.error(err.message, err.error)
          return of(undefined)
        })
      )
  }

  public getCategorySelections(site: number): Observable<ReadonlyArray<SiteCombinedSelections> | undefined> {
    let params = new HttpParams()
    if (site) params = params.append('site', String(site))
    return this.http
      .get<ReadonlyArray<SiteCombinedSelections> | undefined>(
        `${this.es.config.endpoints.userPrefs}/${API_VERSION}/category-selections`,
        {
          params
        }
      )
      .pipe(
        catchError(err => {
          this.loggingService.error(err.message, err.error)
          return of(undefined)
        })
      )
  }

  /**
   * Get All User Customizations by SiteId
   *
   * A user's customizations is their list of athletes (persons), teams, etc saved in the user_prefs API database. The
   * user's JWT is passed to the API to get the current webapp user's data
   * @param site The site id for filtering user customizations
   * @param showAll Flag to indicate to show all user customizations, even non-curated ones
   */
  public getUserCustomizations(
    site?: number,
    showAll: boolean = false
  ): Observable<ReadonlyArray<UserCustomization> | undefined> {
    const params = getUserCustomizationParams(site, showAll)

    return this.http
      .get<ReadonlyArray<UserCustomization> | undefined>(
        `${this.es.config.endpoints.userPrefs}/${API_VERSION}/users/me/user-customizations`,
        { params }
      )
      .pipe(
        catchError(err => {
          this.loggingService.error(err.message, err.error)
          return of(undefined)
        })
      )
  }

  /**
   * Gets the single user customization by the core api id
   *
   * Used for checking whether a collection has been favorited or not
   *
   * The getUserCustomizations endpoint returns the entire array of user favorites
   * Some client-side filtering returns only the user customization/favorite desired
   * @param coreNodeId The core node id of the collection/category
   * @returns A single user customization if the collection has been favorited
   * or undefined if it has not been favorited
   */
  public getUserCustomizationByCoreNodeId(
    coreNodeId: number,
    categoryType: CategoryType | CollectionEntityType | 'contentcategory',
    showAll: boolean
  ): Observable<UserCustomization | undefined> {
    return this.getUserCustomizations(undefined, showAll).pipe(
      map(categories => {
        if (!categories) return
        return categories.filter(cat => cat.category.coreApiId === coreNodeId && cat.category.type === categoryType)[0]
      }),
      catchError(err => {
        this.loggingService.error(err.message, err.error)
        throw err
      })
    )
  }

  /**
   * POST user customization by CategoryId
   *
   * A user's customizations is their list of athletes (persons), teams, etc saved in the user_prefs API database. The
   * user's JWT is passed to the API to POST the current webapp user's data
   *
   * @param categoryId the unique ID of the athlete (person), team, etc in the user_prefs API database, not the coreAPI
   * database.
   */
  public postUserCustomization(categoryId: number): Observable<UserCustomization | undefined> {
    return this.http
      .post<UserCustomization | undefined>(`${this.es.config.endpoints.userPrefs}/${API_VERSION}/user-customizations`, {
        category: categoryId
      })
      .pipe(
        catchError(err => {
          this.loggingService.error(err.message, err.error)
          throw err
        })
      )
  }

  /**
   * POST user customization by core node id
   *
   * A user's customizations is their list of athletes (persons), teams, etc saved in the user_prefs API database. The
   * user's JWT is passed to the API to POST the current webapp user's data, and the category is retrieved by the
   * Core Node Id, NOT the user_prefs id.
   *
   *
   * @param coreNodeId: the Unique ID, Node ID, of the entity in the Core Api database
   * @param categoryType: Either the CategoryType enum from User Prefs API
   *                      or CollectionEntityType from Core API.
   *                      Either are just string values like 'person', 'team', 'contentcategory', etc.
   */
  public postUserCustomizationByCoreNodeId(
    coreNodeId: number,
    type: CategoryType | CollectionEntityType | 'contentcategory'
  ): Observable<UserCustomization | undefined> {
    return this.http
      .post<UserCustomization | undefined>(`${this.es.config.endpoints.userPrefs}/${API_VERSION}/user-customizations`, {
        category: { externalId: coreNodeId, type }
      })
      .pipe(
        catchError(err => {
          this.loggingService.error(err.message, err)
          throw err
        })
      )
  }

  public deleteUserCustomization(userCustomizationId: number): Observable<void | undefined> {
    return this.http
      .delete<void>(`${this.es.config.endpoints.userPrefs}/${API_VERSION}/user-customizations/${userCustomizationId}`)
      .pipe(
        catchError(err => {
          this.loggingService.error(err.message, err.error)
          throw err
        })
      )
  }
}
