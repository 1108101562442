import { Inject, Injectable, OnDestroy, Optional, PLATFORM_ID } from '@angular/core'
import { DOCUMENT, isPlatformBrowser } from '@angular/common'
import { BehaviorSubject } from 'rxjs'
import { shareReplay } from 'rxjs/operators'

/**
 * This service may be used to determine if there is currently an element in fullscreen mode.
 *
 * When using this service, bare in mind that any element which goes fullscreen
 * must have the `DetectIosSafariFullscreenDirective` on it in order for its
 * fullscreen state to be detected in Safari. This is to avoid expensive DOM
 * queries and unexpected numbers of event listeners.
 *
 * @see DetectIosSafariFullscreenDirective
 */
@Injectable({
  providedIn: 'root'
})
export class FullscreenStatusService implements OnDestroy {
  private readonly isBrowser: boolean
  private readonly isFullscreen = new BehaviorSubject<boolean>(false)

  /**
   * Emits the current fullscreen state. True means an element is fullscreen.
   *
   * It is given that multiple elements cannot go fullscreen at once currently.
   * If you're using this and that's no longer true, this service is probably
   * not working correctly.
   */
  public readonly isFullscreen$ = this.isFullscreen.pipe(
    shareReplay(1)
  )

  constructor(
    // tslint:disable-next-line:ban-types
    @Inject(PLATFORM_ID) platform: Object,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    this.isBrowser = isPlatformBrowser(platform)

    if (!this.isBrowser) {
      this.isFullscreen.complete()
      return
    }

    // desktop safari
    this.document.addEventListener('webkitfullscreenchange', () => {
      if (!(document as any).webkitFullscreenElement) {
        this.endFullscreen()
      } else {
        this.beginFullscreen()
      }
    })

    // every other browser
    this.document.addEventListener('fullscreenchange', () => {
      if (!document.fullscreenElement) {
        this.endFullscreen()
      } else {
        this.beginFullscreen()
      }
    })
  }

  /**
   * Call this method to globally indicate that an element has gone fullscreen.
   *
   * Generally, this method should only be called by directives which are
   * watching elements for fullscreen changes.
   */
  public beginFullscreen(): void {
    this.isFullscreen.next(true)
  }

  /**
   * Call this method to globally indicate that the element is no longer fullscreen
   *
   * Generally, this method should only be called by directives which are
   * watching elements for fullscreen changes.
   */
  public endFullscreen(): void {
    this.isFullscreen.next(false)
  }

  public ngOnDestroy(): void {
    if (!this.isFullscreen.isStopped) this.isFullscreen.complete()
  }
}
