import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap'
import { AnalyticsModule } from 'src/app/shared/analytics/analytics.module'
import { SvgModule } from 'src/app/shared/svg/svg.module'
import { DropdownModule } from '../dropdown/dropdown.module'
import { ImageModule } from '../image/image.component.module'
import { LinkModule } from '../link/link.module'
import { NavModule } from '../navigation/nav.module'
import { FloCssClassSanitizePipeModule } from '../_pipes/css-class-sanitize/css-class-sanitize.pipe.module'
import { SubnavigationComponent } from './subnavigation.component'

@NgModule({
  imports: [
    CommonModule,
    LinkModule,
    FloCssClassSanitizePipeModule,
    NavModule,
    AnalyticsModule,
    DropdownModule,
    NgbDropdownModule,
    SvgModule,
    ImageModule
  ],
  declarations: [SubnavigationComponent],
  exports: [SubnavigationComponent]
})
export class SubnavigationModule {}
