<div>
  <div class="row mb-0 mb-md-4 pb-0">
    <div class="px-4 p-md-0 skeleton tabs-skeleton w-100"></div>
  </div>
  <div class="px-4 px-md-0 pb-5">
    <div *ngFor="let image of [0, 1, 2, 3, 4, 5]" class="w-100 d-flex flex-row search-result">
      <div
        class="img-fluid rounded mb-3 me-3 skeleton"
        style="height: 132px; width: 100%"
      ></div>
      <p class="p-skeleton"></p>
    </div>
  </div>
</div>
