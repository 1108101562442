import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { TableModel } from '@flocasts/experience-service-types'
import { trackById } from 'src/app/shared/utility-functions/track-by-id.utility'

@Component({
  selector: 'flo-table',
  templateUrl: './table.component.html',
  styleUrls: [
    './table.component.scss',
    // Override styles for specific tables
    // This should correlate with BFF table style enums
    // Column width, font color & weight, text alignment, image size, etc.
    './table-styles/schedule.component.scss',
    './table-styles/schedule-hockey.component.scss',
    './table-styles/schedule-college.component.scss',
    './table-styles/standings-varsity.component.scss',
    './table-styles/results-varsity.component.scss',
    './table-styles/results-grappling-athlete.component.scss',
    './table-styles/results-wrestling-athlete.component.scss',
    './table-styles/results-stats-aggregations-athlete.scss',
    './table-styles/standings-hockey.component.scss',
    './table-styles/rosters-hockey.component.scss',
    './table-styles/stats-hockey.component.scss',
    './table-styles/table-dark-theme.scss',
    './table-styles/subscription-list.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent {
  @Input()
  table: TableModel

  @Input()
  index = 0

  readonly trackById = trackById
}
