import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { HeadlineModel, SectionModel } from '@flocasts/experience-service-types'
import { trackById } from '../../shared/utility-functions/track-by-id.utility'

@Component({
  selector: 'flo-headlines',
  templateUrl: './headlines.component.html',
  styleUrls: ['./headlines.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeadlinesComponent {
  @Input() readonly section: SectionModel<HeadlineModel>

  public trackById = trackById
}
