<div class="scoreboard">
  <div class="scoreboard__top mb-2">
    <div class="scoreboard__header d-flex justify-content-between align-items-baseline mb-2">
      <ng-container *ngIf="!result.competitor1.nameAction; else showCompetitor1Link">
        <span
          class="scoreboard__header--title headline text-truncate"
          [ngClass]="{
            'me-1 font-family-bold': result.competitor1.isWinner,
            'color-500': !result.competitor1.isWinner
          }"
        >
          {{ result.competitor1.name }}
        </span>
      </ng-container>
      <ng-template #showCompetitor1Link>
        <a
          class="scoreboard__header--title headline text-truncate me-1 link-secondary"
          [ngClass]="{
            'font-family-bold': result.competitor1.isWinner
          }"
          routerLink="{{ result.competitor1.nameAction?.url }}"
          floAnalytics
          [name]="result.competitor1.name"
        >
          {{ result.competitor1.name }}
        </a>
      </ng-template>
      <ng-container *ngIf="result.competitor1.subtitle">
        <span class="scoreboard__header--title footnote text-truncate">
          - {{ result.competitor1.subtitle }}
        </span>
      </ng-container>
      <span class="scoreboard__header--score headline font-family-bold ms-auto"
            [ngClass]="{ 'color-500': !result.competitor1.isWinner }"
      >{{ result.competitor1.scoreText }}</span
      >
    </div>
    <div class="scoreboard__header d-flex justify-content-between align-items-baseline">
      <ng-container *ngIf="!result.competitor2.nameAction; else showCompetitor2Link">
        <span
          class="scoreboard__header--title headline text-truncate"
          [ngClass]="{
            'me-1 font-family-bold': result.competitor2.isWinner,
            'color-500': !result.competitor2.isWinner
          }"
        >
          {{ result.competitor2.name }}
        </span>
      </ng-container>
      <ng-template #showCompetitor2Link>
        <a
          class="scoreboard__header--title headline text-truncate me-1 link-secondary"
          [ngClass]="{
            'font-family-bold': result.competitor2.isWinner
          }"
          routerLink="{{ result.competitor2.nameAction?.url }}"
          floAnalytics
          [name]="result.competitor2.name"
        >
          {{ result.competitor2.name }}
        </a>
      </ng-template>
      <ng-container *ngIf="result.competitor2.subtitle">
        <span class="scoreboard__header--title footnote text-truncate">
          - {{ result.competitor2.subtitle }}
        </span>
      </ng-container>
      <span class="scoreboard__header--score headline font-family-bold ms-auto"
            [ngClass]="{ 'color-500': !result.competitor2.isWinner }"
      >{{ result.competitor2.scoreText }}</span
      >
    </div>
  </div>
  <div class="scoreboard__bottom d-flex justify-content-between align-items-center">
    <p class="scoreboard__bottom--stage footnote color-500 mb-0">
      {{ result.footer1 }}</p
    >
    <ng-container *ngIf="result.footerAction">
      <a
        routerLink="{{ result.footerAction?.url }}"
        [queryParams]="result.footerAction?.mergeParams"
        class="scoreboard__bottom--replay footnote text-primary ms-auto mb-0"
        floAnalytics
        name="{{ result.footerAction?.title }}"
      >{{ result.footerAction?.title }}</a
      >
    </ng-container>
  </div>
</div>
