import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { WrestlingCompetitorModel } from '@flocasts/experience-service-types'

@Component({
  selector: 'flo-wrestling-competitor-detail',
  templateUrl: './wrestling-competitor-detail.component.html',
  styleUrls: ['./wrestling-competitor-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WrestlingCompetitorDetailComponent {
  @Input() competitor: WrestlingCompetitorModel
  @Input() alignElement: 'left' | 'right'
}
