import { HttpHeaders, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http'
import { Injectable, Inject, InjectionToken } from '@angular/core'
import { TransferState } from '@angular/platform-browser'
import { Observable, of, timer, SchedulerLike } from 'rxjs'
import { take } from 'rxjs/operators'
import { makeCacheKey, TransferHttpResponse } from './http-transfer-state.utility'
import { RXJS_SCHEDULER } from '../../app.config'

export const TRANSFER_STATE_CACHE_TIMEOUT = new InjectionToken<number>('app.transferStateCacheTimeout')
export const CLIENT_CACHE_BYPASS_REGEX = new InjectionToken<ReadonlyArray<RegExp>>('client.cache.bypass.regex')
/*
  Purpose of this client transfer state interceptor is to
  retrieve data from transfer state cache on initial page load
  instead of making initial duplicate GET calls from the client
*/
@Injectable()
export class HttpClientTransferStateInterceptor implements HttpInterceptor {
  private _useCache = true
  public get useCache() {
    return this._useCache
  }
  public set useCache(value) {
    this._useCache = value
  }
  private _cacheTimer: Observable<number>
  public get cacheTimer(): Observable<number> {
    return this._cacheTimer
  }
  public set cacheTimer(value: Observable<number>) {
    this._cacheTimer = value
  }

  constructor(
    private transferState: TransferState,
    @Inject(RXJS_SCHEDULER) private readonly scheduler: SchedulerLike,
    @Inject(TRANSFER_STATE_CACHE_TIMEOUT)
    private transferStateCacheTimeout: number,
    @Inject(CLIENT_CACHE_BYPASS_REGEX) private cacheBypassRegex: ReadonlyArray<RegExp>
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Stop using cache if cache times out
    if (!this.useCache) {
      return next.handle(req)
    }

    /*
      The cache timer is necessary because our app never goes stable
      (thanks external libraries executing inside ngzone and never resolving).
      Stop using transfer state cache after some arbirtrary seconds.
      This allows us some time for the frontend to get responses from tranfer state cache
      But we don't want fe to get data from ts cache indefinitely
      Because the data from page load will eventually go stale
      Try removing this when we go to angular 9
    */
    if (!this.cacheTimer) {
      this.cacheTimer = timer(this.transferStateCacheTimeout, this.scheduler)
      this.cacheTimer.pipe(take(1)).subscribe(() => {
        this.useCache = false
      })
    }

    // Pass mutating calls through, skip the ts cache
    if (req.method !== 'GET' && req.method !== 'HEAD') {
      return next.handle(req)
    }

    const bypassCache = (url: string) => this.cacheBypassRegex.some(exp => exp.test(url))

    if (bypassCache(req.url)) {
      return next.handle(req)
    }

    const storeKey = makeCacheKey(req.method, req.url, req.params)

    if (this.transferState.hasKey(storeKey)) {
      // Request found in cache. Respond using it.
      const response = this.transferState.get(storeKey, {} as TransferHttpResponse)

      return of(
        new HttpResponse<any>({
          body: response.body,
          headers: new HttpHeaders(response.headers),
          status: response.status,
          statusText: response.statusText,
          url: response.url
        })
      )
    } else {
      // Request not found in cache. Make the request and just let the browser cache it
      return next.handle(req)
    }
  }
}
