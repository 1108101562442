import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { AnalyticsModel } from '@flocasts/experience-service-types'

@Component({
  selector: 'flo-avatar-card',
  templateUrl: './avatar-card.component.html',
  styleUrls: ['./avatar-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarCardComponent {
  @Input() image: string
  @Input() primaryLabel: string
  @Input() secondaryLabel?: string
  @Input() href: string
  @Input() analyticsProperties?: AnalyticsModel
}
