import { AuthService } from '../../singleton-services/auth/auth.service'
import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router'
import { CheckoutNavigationService } from '../services/checkout-navigation.service'
import { tap, flatMap } from 'rxjs/operators'
import { combineLatest, Observable, of } from 'rxjs'
import { VerticalService } from '../../singleton-services/vertical.service'

@Injectable()
export class RegisterGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private checkoutNavService: CheckoutNavigationService,
    private verticalService: VerticalService
  ) {}

  canActivate(ars: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return combineLatest([this.authService.userIdentity$, this.verticalService.isMileSplit$]).pipe(
      flatMap(([user, isMileSplit]) => {
        if (user || isMileSplit) {
          return of(false).pipe(tap(() => this.checkoutNavService.navigateToFinalDestination(ars.queryParams)))
        }
        return of(true)
      })
    )
  }
}
