import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FloCssClassSanitizePipeModule } from '../_pipes/css-class-sanitize/css-class-sanitize.pipe.module'
import { ImageComponent } from './image.component'

@NgModule({
  imports: [CommonModule, FloCssClassSanitizePipeModule],
  declarations: [ImageComponent],
  exports: [ImageComponent]
})
export class ImageModule {}
