import { Injectable } from '@angular/core'

export interface IDynamicStyleService {
  primaryColor(color: string): string
}

// tslint:disable:max-line-length
/**
 * @deprecated
 *
 * TODO: needs to be removed, @see {link https://flocasts.atlassian.net/browse/FLO-13079}
 */
@Injectable()
export class DynamicStyleService implements IDynamicStyleService {
  primaryColor(color: string): string {
    const colorHex = color
    const darkHex = this.shadeColor(color, -0.1)

    return `
      .white-color-text { color: #fff !important }
      .primary-color-text { color: ${colorHex} !important }
      .primary-color-text:hover, .primary-color-text:focus { color: ${colorHex} !important; transition: all 250ms }
      .primary-color-text-active.active .apply-text-active, .primary-color-text-active.active.apply-text-active,
      .nested-primary-color-text-active.active .nested-apply-text-active, .nested-primary-color-text-active.nested-active.apply-text-active{ color: ${colorHex} !important }
      .primary-color-text-hover:hover .apply-text-hover, .primary-color-text-hover:hover.apply-text-hover { color: ${colorHex} !important }
      .hover-text-primary:hover { color: ${colorHex}!important }
      .primary-color-bg { background: ${colorHex} !important }
      input[type="checkbox"].check-target:checked ~ .checkbox-toggle-primary-color-bg { background: ${colorHex} !important }
      input[type="checkbox"].check-target:checked ~ .checkbox-toggle-primary-color-text { color: ${colorHex} !important }
      .primary-color-bg-hover:hover .apply-bg-hover, .primary-color-bg-hover:hover.apply-bg-hover { background: ${colorHex} !important; transition: all 250ms; }
      .primary-color-outline { outline-color: ${colorHex} !important }
      .primary-color-stroke { stroke: ${colorHex} !important }
      a, a.visited { color: ${colorHex} }
      h1:hover > a, h1:focus > a, h2:hover > a, h2:focus > a, h3:hover > a, h3:focus > a, h4:hover > a, h4:focus > a, h5:hover > a, h5:focus > a, h6:hover > a, h6:focus > a, .title:hover > a, .title:focus > a { color: ${color} }
      .flo-footnote span.color-text { color: ${colorHex} }
      .primary-color-svg svg path { fill: ${colorHex} }
      .primary-color-svg-hover svg:hover path { fill: ${colorHex} }
      .primary-color-svg-apply svg .primary-color-apply { fill: ${colorHex} }
      .primary-color-svg-hover-apply svg:hover .primary-color-apply { fill: ${colorHex} }

      .btn-primary, .btn-primary-small, .btn-primary-tiny { background: ${colorHex} }
      .btn-primary:hover, .btn-primary-small:hover, .btn-primary-tiny:hover { background-color: ${darkHex} }

      .btn-secondary, .btn-secondary-small, .btn-secondary-tiny { color: ${colorHex} }
      .btn-secondary a, .btn-secondary-small a, .btn-secondary-tiny a { color: ${colorHex} }
      .btn-secondary:hover, .btn-secondary-small:hover, .btn-secondary-tiny:hover { color: ${darkHex} }
      .primary-color-radio:hover input ~ .primary-overlay,
      .primary-color-radio input:focus ~ .primary-overlay,
      .primary-color-radio input:checked ~ .primary-overlay { border: 1px solid ${colorHex} }
      .primary-color-radio .primary-overlay:after { background: ${colorHex} }
      input.primary-color-toggle:checked + .primary-color-apply { background: ${colorHex} }

      `.replace(/\n/gm, '')
  }

  // see: https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
  // tslint:disable:no-bitwise
  // tslint:disable:max-line-length
  // tslint:disable:prefer-template
  // tslint:disable:binary-expression-operand-order
  shadeColor(color: string, percent: number) {
    const f = parseInt(color.slice(1), 16)
    const t = percent < 0 ? 0 : 255
    const p = percent < 0 ? percent * -1 : percent
    const R = f >> 16
    const G = (f >> 8) & 0x00ff
    const B = f & 0x0000ff

    return (
      '#' +
      (
        0x1000000 +
        (Math.round((t - R) * p) + R) * 0x10000 +
        (Math.round((t - G) * p) + G) * 0x100 +
        (Math.round((t - B) * p) + B)
      )
        .toString(16)
        .slice(1)
    )
  }
}
