<div
  class="d-flex flex-nowrap align-items-center"
  [ngClass]="{'toggle-wrapper rounded p-2 p-md-0 justify-content-between justify-content-md-end w-100': toggleOutline }">
  <label class="footnote me-2">{{ toggleFilter.title }}</label>
  <a
    (click)="toggleClick()"
    floAnalytics
    [analyticsProperties]="toggleFilter.analytics"
    elementName="Toggle"
    eventName="Toggle Clicked"
  >
    <div
      data-test="toggle-filter"
      [ngClass]="isToggleOn ? 'toggle-bg bg-primary' : 'toggle-bg'">
      <div [@isToggleOn]="isToggleOn" class="toggle"></div>
    </div>
  </a>
</div>
