import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { AdModel } from '@flocasts/experience-service-types'
import { AdComponentConfig } from 'src/app/shared/ads/ad/ad-component.interface'

/**
 * Wrapper component that supports ad configuration from the BFF.
 */
@Component({
  selector: 'flo-ad',
  templateUrl: './ad.component.html',
  styleUrls: ['./ad.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdComponent {
  /**
   * Transforms the new BFF AdModel into the old web app AdComponentConfig
   */
  _ad: AdModel
  get ad(): AdModel {
    return this._ad
  }
  @Input() set ad(ad: AdModel) {
    this._ad = ad
    this._config = {
      config: {
        adConfig: {
          adDirectory: ad.directory,
          sizes: ad.sizes,
          adSlotId: ad.slotId,
          targeting: {
            contentIds: !!ad.targeting ? ad.targeting.aggregatedNodeIds.map(String) : [],
            categorySlugs: !!ad.targeting ? ad.targeting.aggregatedCategorySlugs : [],
            isPremium: ad.targeting?.isUserPremium || undefined,
            liveId: ad.targeting?.liveId || undefined
          }
        },
        additionalOpts: {
          deferClick: ad.deferClick || undefined,
          maxRefreshTime: ad.maxRefreshTime || undefined
        }
      }
    }
  }
  _config: { config: AdComponentConfig }
  get config(): { config: AdComponentConfig } {
    return this._config
  }

  /**
   * The dynamically loaded ad component which does all the heavy lifting.
   */
  public AdLoadingComponent = import('src/app/shared/ads/ad/ad.component').then(m => m.AdComponent)
}
