import { Inject, Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { of, BehaviorSubject } from 'rxjs'
import { LoggerService } from 'src/app/logger/logger.interface'
import { LOGGER_SERVICE } from 'src/app/logger/logger.config'

@Injectable()
export class GiftService {
  constructor(private readonly router: Router, @Inject(LOGGER_SERVICE) private readonly logger: LoggerService) {}

  public isInGiftSubscription$ = of(this.router.url.startsWith('/gift/'))

  public gifterEmail$ = new BehaviorSubject<string | undefined>(undefined)
  public setGifterEmail(email: string) {
    this.gifterEmail$.next(email)
  }

  public giftCode$ = new BehaviorSubject<string | undefined>(undefined)
  /**
   * set on the gift/redeem page
   */
  public setGiftCode(code: string) {
    this.giftCode$.next(code)
  }
}
