import { legacyParse } from '@date-fns/upgrade/v2'
import { isAfter } from 'date-fns'
import { SupplementedUserSubscription, IUserSubscription } from '../../account-settings/account-settings.interfaces'
import { MinimalSubscription } from './subscriptions.interface'

// Status from JWT and for analytics
export enum StripeSubscriptionStatus {
  ACTIVE = 'Active',
  CANCELLED = 'Cancelled',
  DEACTIVATED = 'Deactivated',
  DELETED = 'Deleted',
  EXPIRED = 'Expired',
  INVALID = 'Invalid',
  PAST_DUE = 'Past Due',
  PENDING = 'Pending',
  TRIALING = 'Trialing',
  UNKNOWN = 'Unknown'
}

export const subscriptionStatusDict: {
  [status: string]: StripeSubscriptionStatus
} = {
  cancelled: StripeSubscriptionStatus.CANCELLED,
  active: StripeSubscriptionStatus.ACTIVE,
  deactivated: StripeSubscriptionStatus.EXPIRED,
  deleted: StripeSubscriptionStatus.EXPIRED,
  expired: StripeSubscriptionStatus.EXPIRED,
  invalid: StripeSubscriptionStatus.EXPIRED,
  'past due': StripeSubscriptionStatus.PAST_DUE,
  pending: StripeSubscriptionStatus.ACTIVE,
  unknown: StripeSubscriptionStatus.UNKNOWN,
  trialing: StripeSubscriptionStatus.TRIALING
}

export const checkSubscriptionStatus = (statusToCheck: StripeSubscriptionStatus, status?: string): boolean => {
  return statusToCheck === status
}

/**
 * Given an array of subscriptions, find the one whose id matches a given id.
 */
export const findUserSubscriptionMatch = (
  subscriptions: ReadonlyArray<SupplementedUserSubscription | MinimalSubscription>,
  subscriptionId: number
): SupplementedUserSubscription | MinimalSubscription | undefined => {
  return subscriptions.find(subscription => subscription.id.toString() === subscriptionId.toString())
}

/**
 * Return a "clean" subscription status for better user readability.
 */
export const getCleanSubscriptionStatus = (sub?: IUserSubscription): StripeSubscriptionStatus => {
  const shouldModifyStatus =
    !!sub && !isStripeSubscription(sub.type) && checkSubscriptionStatus(StripeSubscriptionStatus.CANCELLED, sub.status)
  const status = (sub && shouldModifyStatus && getNonStripeSubscriptionStatus(sub)) || (sub && sub.status) || 'unknown'

  return subscriptionStatusDict[status.toLowerCase()]
}

/**
 * Return whether signed up subscription is Stripe (and, therefore, user signed up on web).
 */
export const isStripeSubscription = (subType?: string): boolean => {
  return subType === 'stripe'
}

/**
 * Return whether signed up subscription is Roku.
 */
export const isRokuSubscription = (subType?: string): boolean => {
  return subType === 'roku'
}

/**
 * Return whether signed up subscription is Itunes.
 */
export const isItunesSubscription = (subType?: string): boolean => {
  return subType === 'itunes'
}

/**
 * For non-Stripe subscriptions (iTunes and Roku) with status "cancelled"
 * If end date is in past (end_date < NOW()), return "Expired"
 * If end date is in future (end_date >= NOW()), return "Cancelled"
 */
const getNonStripeSubscriptionStatus = (sub: IUserSubscription): string => {
  const endDateInFuture = isAfter(legacyParse(sub.end_date), new Date(new Date().setHours(0, 1, 0)))

  return endDateInFuture ? StripeSubscriptionStatus.CANCELLED : StripeSubscriptionStatus.EXPIRED
}

export enum SubscriptionSource {
  APPLE_TV = 'Apple TV',
  IOS = 'iOS',
  ROKU = 'Roku',
  WEB = 'Web'
}

export const findWhereUserSignedUp = (source: string): SubscriptionSource => subscriptionSourceDict[source]

export const subscriptionSourceDict: {
  [status: string]: SubscriptionSource
} = {
  atv: SubscriptionSource.APPLE_TV,
  ios: SubscriptionSource.IOS,
  roku: SubscriptionSource.ROKU,
  stripe: SubscriptionSource.WEB,
  unknown: SubscriptionSource.WEB
}

/**
 * @deprecated - Use `subscriptions.utility.ts`'s `canCancelSubscription` for subscriptions in the new
 * pricing/entitlements architecture.
 *
 * Return whether a user's subscription status makes them eligible for cancellation.
 */
export const legacyCanCancel = (subStatus: StripeSubscriptionStatus): boolean => {
  return (
    subStatus === StripeSubscriptionStatus.PENDING ||
    subStatus === StripeSubscriptionStatus.TRIALING ||
    subStatus === StripeSubscriptionStatus.ACTIVE
  )
}

/**
 * @deprecated - Use `subscription.utility.ts`'s `canUpgrade` for subscriptions in the new
 * pricing/entitlements architecture.
 *
 * Can upgrade a user from a monthly to a yearly if they're an active, monthly, stripe user
 */
export const legacyCanUpgrade = (subscription: IUserSubscription): boolean => {
  return (subscription.is_active ?? false) && subscription.plan.monthly && isStripeSubscription(subscription.type)
}

/**
 * Return whether a user's subscription status is canceled (and, therefore, eligible to be uncanceled).
 */
export const isCanceled = (subStatus: StripeSubscriptionStatus): boolean => {
  return subStatus === StripeSubscriptionStatus.CANCELLED
}

/**
 * Return whether a user's subscription status is expired (and, therefore, unable to be reactivated).
 */
export const isExpired = (subStatus: StripeSubscriptionStatus): boolean => {
  return subStatus === StripeSubscriptionStatus.EXPIRED
}

/**
 * Return whether the plan associated with a user subscription is yearly.
 */
export const subscriptionPlanIsYearly = (subscription: IUserSubscription) => {
  return subscription.plan && subscription.plan.yearly
}

/**
 * Take array of IUserSubscriptions (returned from API) and supplement each.
 */
export const supplementAllUserSubscriptions = (
  subscriptions: ReadonlyArray<IUserSubscription | null>
): ReadonlyArray<SupplementedUserSubscription> => {
  return subscriptions.map(supplementUserSubscription)
}

/**
 * Take IUserSubscription and supplement with extra details needed for the UI/UX.
 */
export const supplementUserSubscription = (subscription: IUserSubscription): SupplementedUserSubscription => {
  const status = getCleanSubscriptionStatus(subscription)
  return {
    ...subscription,
    status,
    signed_up_on: findWhereUserSignedUp(subscription.source || subscription.type || 'unknown'),
    is_stripe: isStripeSubscription(subscription.type),
    is_roku: isRokuSubscription(subscription.type),
    is_itunes: isItunesSubscription(subscription.type),
    is_paused: !!subscription.paused_subscription,
    is_canceled: isCanceled(status),
    is_expired: isExpired(status),
    can_cancel: legacyCanCancel(status),
    can_upgrade: legacyCanUpgrade(subscription),
    plan_is_yearly: subscriptionPlanIsYearly(subscription)
  }
}
