import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { Observable, combineLatest, of } from 'rxjs'
import { switchMap, tap } from 'rxjs/operators'
import { AuthService } from '../../singleton-services/auth/auth.service'
import { CheckoutNavigationService } from '../services/checkout-navigation.service'
@Injectable()
export class PayGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private checkoutNavService: CheckoutNavigationService,
    private router: Router
  ) {}

  canActivate(ars: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return combineLatest([this.authService.userIdentity$, this.authService.isPremium$]).pipe(
      switchMap(([user, isPremium]) => {
        const queryParams = ars.queryParams

        if (!user) {
          return of(false).pipe(tap(() => this.router.navigate(['signup'], { queryParams })))
        }

        if (isPremium) {
          return of(false).pipe(tap(() => this.checkoutNavService.navigateToFinalDestination(queryParams)))
        }

        return of(true)
      })
    )
  }
}
