export const stripQueryParams = (url: string): string => {
  return url.split('?')[0]
}

export const getQueryParam = (url: string, param: string): string | null => {
  if (!url || !param) {
    return null
  }
  const params = url.split('?')[1]
  if (!params) {
    return null
  }
  return new URLSearchParams(params).get(param)
}
