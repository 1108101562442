import { iif, Observable, ReplaySubject } from 'rxjs'
import { concatMap, distinctUntilChanged, filter, take, tap, map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http'

import { CookieService } from '../../singleton-services/cookie.service'

export const FLO_FLAGS_ALL_KEY = 'x-flo-flags-all'

const isCoreApiCall = (url: string) => /api\..*\/api\/(?!tix)(?!view)/.test(url)

const isHttpResponse = (httpEvent: HttpEvent<any>): httpEvent is HttpResponse<any> =>
  httpEvent && 'headers' in httpEvent && 'body' in httpEvent && httpEvent.ok

const setXFloFlagsAllHeaderOnRequest = (
  request: HttpRequest<any>,
  xFloFlagsAll: string | undefined
): HttpRequest<any> =>
  xFloFlagsAll
    ? request.clone({
        headers: request.headers.set(FLO_FLAGS_ALL_KEY, xFloFlagsAll as string)
      })
    : request

@Injectable()
export class HttpXFloFlagsAllInterceptor implements HttpInterceptor {
  constructor(private readonly cookieService: CookieService) {
    this.xFloFlagsAll$.next(this.cookieService.get(FLO_FLAGS_ALL_KEY))
  }

  private readonly xFloFlagsAll$ = new ReplaySubject<string>(1)

  public readonly updateCookie = this.xFloFlagsAll$
    .pipe(filter(Boolean), distinctUntilChanged())
    .subscribe(xFloFlagsAll =>
      this.cookieService.set(FLO_FLAGS_ALL_KEY, xFloFlagsAll, {
        path: '/',
        expires: 180
      })
    )

  private readonly nextXFloFlagsAllFromResponse = (response: HttpResponse<any>) => {
    const xFloFlagsAll =
      isHttpResponse(response) &&
      response &&
      response.headers &&
      response.headers.getAll(FLO_FLAGS_ALL_KEY) &&
      (response.headers.getAll(FLO_FLAGS_ALL_KEY) as ReadonlyArray<string>).filter(Boolean).pop()

    if (!!xFloFlagsAll) {
      this.xFloFlagsAll$.next(xFloFlagsAll)
    }
  }

  private readonly handleXFloFlagsAllHeader$ = (
    inputRequest: HttpRequest<any>,
    httpHandler: HttpHandler
  ): Observable<HttpEvent<any>> =>
    this.xFloFlagsAll$.pipe(
      take(1),
      map(xFloFlagsAll => setXFloFlagsAllHeaderOnRequest(inputRequest, xFloFlagsAll)),
      concatMap(newRequest => httpHandler.handle(newRequest)),
      tap(response => isHttpResponse(response) && this.nextXFloFlagsAllFromResponse(response))
    )

  public readonly intercept = (request: HttpRequest<any>, httpHandler: HttpHandler): Observable<HttpEvent<any>> =>
    iif(
      () => isCoreApiCall(request.url),
      this.handleXFloFlagsAllHeader$(request, httpHandler),
      httpHandler.handle(request)
    )
}
