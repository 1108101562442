import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core'
import { FloDebugService } from '../services/debug.service'
import { VerticalService } from '../../../singleton-services/vertical.service'
import { map, mergeMap, filter } from 'rxjs/operators'
import { HttpParams } from '@angular/common/http'
import { LocationService } from '../../../singleton-services/location.service'
import { maybe } from 'typescript-monads'
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'flo-debug-menu',
  styleUrls: ['./debug-menu.component.scss'],
  templateUrl: './debug-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FloDebugMenuComponent {
  constructor(
    public readonly debug: FloDebugService,
    public readonly vs: VerticalService,
    public readonly loc: LocationService,
    public readonly ar: ActivatedRoute
  ) {}

  selectMenuItems$ = this.ar.queryParamMap.pipe(
    filter(pmap => pmap.has('flotest') || pmap.has('flosite')),
    mergeMap(() => this.siteCodes$)
  )

  siteCodes$ = this.vs.getAllSiteCodes.pipe(map(a => a.reduce((acc, curr) => ({ ...acc, [curr]: curr }), {})))

  @HostListener('document:keydown', ['$event'])
  onKeyDown(evt: KeyboardEvent) {
    if (evt.ctrlKey && evt.shiftKey && evt.key === 'D') {
      this.selectMenuItems$ = this.siteCodes$
      this.debug.toggle()
    }
  }

  trackBySiteCode(_idx: number, code: string) {
    return code
  }

  changeVertical(flosite: string) {
    const split = this.loc.href.split('?')
    const base = split[0]
    const href = maybe(split[1])
      .map(fromString => new HttpParams({ fromString }).set('flosite', flosite).toString())
      .match({
        none: () => `${base}?${new HttpParams({ fromObject: { flosite } }).toString()}`,
        some: params => `${base}?${params}`
      })
    window.location.href = href
  }
}
