import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { FloEvent } from '@flocasts/flosports30-types/dist/entity'

@Component({
  selector: 'flo-landing-batman-list-item',
  templateUrl: './batman-calendar-list-item.component.html',
  styleUrls: ['./batman-calendar-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BatmanCalendarListItemComponent {
  @Input()
  readonly event: FloEvent

  @Input()
  geoBlockedIds: ReadonlyArray<number>
}
