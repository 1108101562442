import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core'
import { takeUntil, Subject, take, filter, combineLatest, map, switchMap, shareReplay, iif } from 'rxjs'
import {
  GIFT_SUBSCRIPTION_OFFERS_ANALYTICS_TAG,
  GiftSubscriptionAnalyticsService
} from 'src/app/gift-subscription/gift-subscription-analytics.service'
import {
  EXP_FLOGRAPPLING_INSTALLMENT_ID,
  EXP_FLOGRAPPLING_INSTALLMENT_VARIATION_1,
  EXP_FLOGRAPPLING_INSTALLMENT_VARIATION_2,
  EXP_YEARLY_PRICING_INCREASE_ID,
  FEAT_PRICING_ARCH_CONTROL,
  FEAT_PRICING_ARCH_ID,
  FEAT_PRICING_ARCH_VARIATION,
  FEAT_PRICING_ARCH_VERTICALS
} from 'src/app/shared/experimentation/experiment.model'
import { ExperimentationService } from 'src/app/shared/experimentation/experimentation.service'
import { GiftService } from 'src/app/shared/services/gift.service'
import { FunnelRouteData, LEGACY_PLANS_SEGMENT_TAG, getFunnelProperties } from '../funnel-analytics.utility'
import { ActivatedRoute, Router } from '@angular/router'
import { SegmentService } from 'src/app/shared/analytics/services/segment.service'
import { AuthService } from 'src/app/singleton-services/auth/auth.service'
import { ProductService } from 'src/app/shared/services/product.service'
import { OffersService } from 'src/app/singleton-services/offers.service'
import { CouponStatusUtility } from '../coupon/coupon-status.utility'
import { trackById } from 'src/app/shared/utility-functions/track-by-id.utility'
import { getBillingPlanLabel, mapPlansToOfferSelectionCardModel } from './legacy-plans/legacy-plans.utility'
import { OfferSelectionCardModel } from '@flocasts/experience-service-types'
import { VerticalService } from '../../singleton-services/vertical.service'
import { Plan } from '@flocasts/flosports30-types/dist/subscriptions'
import { PartialsDataService } from '../../singleton-services/partials/partials-data.service'

export const filterInstallmentOffers = (offers: OfferSelectionCardModel[]): OfferSelectionCardModel[] => {
  return offers.filter(offer => !offer.offerMetadata?.isInstallment)
}

@Component({
  selector: 'flo-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlansComponent implements OnInit, OnDestroy {
  constructor(
    private readonly ar: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly couponStatusUtility: CouponStatusUtility,
    private readonly experimentService: ExperimentationService,
    private readonly giftService: GiftService,
    private readonly giftSubAnalytics: GiftSubscriptionAnalyticsService,
    private readonly offersService: OffersService,
    private readonly productService: ProductService,
    private readonly router: Router,
    private readonly segment: SegmentService,
    private readonly verticalService: VerticalService,
    private readonly partialsDataService: PartialsDataService
  ) {}

  private readonly ngOnDestroy$ = new Subject<void>()
  legacyPlans: Plan[] = []
  public selectedOffer$ = this.offersService.selectedOffer$
  public isInGiftSubscription$ = this.giftService.isInGiftSubscription$.pipe(shareReplay(1))
  public isEmbeddedPurchase$ = this.ar.queryParams.pipe(map(queryParams => !!queryParams.embeddedPurchase))
  public siteId$ = this.verticalService.siteId$

  // TODO: refactor to subscribe to the ngOnInit instead
  ngOnInit() {
    this.isInGiftSubscription$.pipe(takeUntil(this.ngOnDestroy$)).subscribe(isInGiftSubscription => {
      if (isInGiftSubscription) {
        this.giftSubAnalytics.sendGiftPageCall(GIFT_SUBSCRIPTION_OFFERS_ANALYTICS_TAG, 'Gifter')
        return
      }
      this.sendPageCall()
      this.ensureLoggedIn()
    })

    // CXP-6133. Force new pricing architecture variation based on vertical to prevent
    // users from subscribing on the legacy architecture.
    this.verticalService.siteId$.pipe(takeUntil(this.ngOnDestroy$)).subscribe(siteId => {
      if (FEAT_PRICING_ARCH_VERTICALS.includes(siteId)) {
        this.experimentService.forceVariation(FEAT_PRICING_ARCH_ID, FEAT_PRICING_ARCH_VARIATION)
        this.router.navigate([], { queryParamsHandling: 'merge' })
      } else {
        this.experimentService.forceVariation(FEAT_PRICING_ARCH_ID, FEAT_PRICING_ARCH_CONTROL)
        this.router.navigate([], { queryParamsHandling: 'merge' })
      }
    })
  }

  public isInNewPricingArchitectureVariation$ = this.experimentService.isInVariation(
    FEAT_PRICING_ARCH_ID,
    FEAT_PRICING_ARCH_VARIATION
  )

  // CXP-4854: FloGrappling Installment Experiment - Variation 1 new design
  public isInInstallmentVariation1$ = this.experimentService.isInVariation(
    EXP_FLOGRAPPLING_INSTALLMENT_ID,
    EXP_FLOGRAPPLING_INSTALLMENT_VARIATION_1,
    true
  )

  // CXP-4854: FloGrappling Installment Experiment - Variation 2 new design w/ installment
  public isInInstallmentVariation2$ = this.experimentService.isInVariation(
    EXP_FLOGRAPPLING_INSTALLMENT_ID,
    EXP_FLOGRAPPLING_INSTALLMENT_VARIATION_2,
    true
  )

  public offers$ = combineLatest([this.offersService.offers$, this.isInInstallmentVariation2$]).pipe(
    map(([offers, isInInstallmentVariation2]) => {
      this.experimentService.activate(EXP_YEARLY_PRICING_INCREASE_ID)

      return isInInstallmentVariation2 ? offers : filterInstallmentOffers(offers)
    })
  )

  public legacyPlansConfig$ = combineLatest([
    this.productService.plans$,
    this.productService.selectedPlan$,
    this.verticalService.siteSettings$
  ]).pipe(
    map(([planData, selectedPlan, siteSetting]) => {
      this.experimentService.activate(EXP_YEARLY_PRICING_INCREASE_ID)
      this.legacyPlans = [...planData.plans]
      return {
        selectedOffer: selectedPlan ? mapPlansToOfferSelectionCardModel([selectedPlan], siteSetting)[0] : null,
        offers: mapPlansToOfferSelectionCardModel(planData.plans, siteSetting)
      }
    })
  )

  // plan props change as the user selects a plan while on the old pricing architecture
  private legacyPlanProps$ = combineLatest([
    this.productService.savingsProp$,
    this.productService.selectedPlan$,
    this.productService.planProps$
  ]).pipe(
    map(([savingsProp, selectedPlan, planProps]) => {
      // TODO: create a utility function for this
      const savings = selectedPlan?.yearly ? [savingsProp] : [] // if it isn't yearly, don't show savings
      const billedProps = selectedPlan ? [getBillingPlanLabel(selectedPlan)] : []
      return [...savings, billedProps, ...planProps]
    })
  )

  // plan props change as the user selects a plan while on the new pricing architecture
  public newPlanProps$ = this.offersService.selectedOffer$.pipe(
    map(selectedOffer => selectedOffer?.bulletPoints.map(bullet => bullet.text))
  )

  // only displayed on mobile because the value props are dynamic upon selection
  public valueProps$ = this.isInNewPricingArchitectureVariation$.pipe(
    switchMap(isInNewPricingArchitectureVariation =>
      iif(() => isInNewPricingArchitectureVariation, this.newPlanProps$, this.legacyPlanProps$)
    )
  )

  public goToNextPage(): void {
    combineLatest([
      this.ar.queryParams,
      this.isInGiftSubscription$,
      this.authService.loggedIn$,
      this.partialsDataService.hasPastDueSubscription$
    ])
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(([queryParams, isInGiftSubscription, isLoggedIn, isPastDue]) => {
        this.couponStatusUtility.hideCouponErrorSource.next(true)
        // TODO: use checkoutNavigation service
        let urlPath

        if (isPastDue) {
          urlPath = '/account/subscriptions'
        } else if (isInGiftSubscription) {
          urlPath = '/gift/info'
        } else if (!isLoggedIn) {
          urlPath = '/create-account'
        } else {
          urlPath = '/pay'
        }
        this.router.navigate([urlPath], { queryParams })
      })
  }

  private sendPageCall(): void {
    const qp = this.ar.snapshot.queryParamMap
    const routeData = this.ar.snapshot.data as unknown as FunnelRouteData
    this.segment.pageV2(LEGACY_PLANS_SEGMENT_TAG, getFunnelProperties(routeData, qp))
  }

  private ensureLoggedIn() {
    this.ar.queryParams
      .pipe(
        take(1),
        filter(queryParams => !!queryParams.embeddedPurchase)
      )
      .subscribe(_ => {
        // this ensures the cookies are properly set across the whole domain coming from embedded purchase
        this.authService.refreshViaToken()
      })
  }

  public ngOnDestroy(): void {
    this.ngOnDestroy$.next()
    this.ngOnDestroy$.complete()
  }

  readonly trackById = trackById
}
