import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { LinkModel } from '@flocasts/experience-service-types'

@Component({
  selector: 'flo-wrestling-match-replay',
  templateUrl: './wrestling-match-replay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WrestlingMatchReplayComponent {
  @Input() outcome: string
  @Input() overtime: string | null
  @Input() weightClass: string
  @Input() action: LinkModel | null
}
