import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { BrowserModule, BrowserTransferStateModule, TransferState } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { REQUEST } from '@nguniversal/express-engine/tokens'
import { AppModule, REQ_KEY } from './app.module'
import { AppComponent } from './app.component'
import { DATA_DOG_INSTANCE, ENV_CONFIG, ENV_CONFIG_TS_KEY } from './app.config'
import { FloAdBlockBrowserModule } from '@flosportsinc/ng-ad-block/browser'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import {
  HttpClientTransferStateInterceptor,
  TRANSFER_STATE_CACHE_TIMEOUT
} from './shared/services/http-client-transfer-state.interceptor'
import { WINDOW } from './app.injections'
import { DISPLAY_ADVERTISING_SERVICE } from './shared/ads/advertising-service/advertising.token'
import { BrowserDisplayAdvertisingService } from './shared/ads/advertising-service/browser-display-advertising.service'
import { AdBlockService } from '@flosportsinc/ng-ad-block'
import { AuthService } from './singleton-services/auth/auth.service'
import { ApsService } from './shared/ads/advertising-service/aps.service'
import { GptService } from './shared/ads/advertising-service/gpt.service'
import { VerticalService } from './singleton-services/vertical.service'
import { AD_PAGE_VIEW, BrowserAdPageViewService } from './shared/ads/advertising-service/ad-page-view.service'
import { BrowserSportDataModule } from './shared/sport-data/browser-sport-data.module'
import { PubmaticService } from './shared/ads/advertising-service/pubmatic.service'
import { FirebaseBrowserModule } from './shared/firebase/firebase-browser.module'
import { LOGGER_SERVICE } from './logger/logger.config'
import { BrowserLoggerModule } from './logger/browser-logger.module'
import { HttpLocalBffInterceptor } from './shared/services/http-local-bff-interceptor'
import { EnvConfig } from '../environments/environment.interface'
import { GrowthBookBrowserModule } from './shared/experimentation/growthbook/growthbook-browser.module'
import { HttpApiCustomResponseHeadersInterceptor } from './shared/services/http-api-custom-response-headers-interceptor'
export function getRequest(transferState: TransferState): any {
  return transferState.get<any>(REQ_KEY, {})
}

export function fuseBoxConfigFactory(ts: TransferState) {
  return ts.get<EnvConfig | {}>(ENV_CONFIG_TS_KEY, {})
}

@NgModule({
  bootstrap: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'flo-app' }),
    BrowserTransferStateModule,
    BrowserAnimationsModule,
    FloAdBlockBrowserModule.usingUrl(
      'https://app30.flosports.tv/ad-server.js?doubleclick=1&gen_204=1&id=wfocus&gqid=advertisment&advert=ads&pagead=1&ad_code=0'
    ),
    AppModule,
    BrowserSportDataModule.forRoot(),
    FirebaseBrowserModule.forRoot(),
    GrowthBookBrowserModule.forRoot(),
    BrowserLoggerModule.forRoot()
  ],
  providers: [
    {
      provide: AD_PAGE_VIEW,
      useClass: BrowserAdPageViewService,
      deps: [GptService]
    },
    {
      provide: ENV_CONFIG,
      useFactory: fuseBoxConfigFactory,
      deps: [TransferState]
    },
    {
      provide: REQUEST,
      useFactory: getRequest,
      deps: [TransferState]
    },
    {
      provide: DATA_DOG_INSTANCE,
      useValue: undefined
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpClientTransferStateInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpLocalBffInterceptor,
      multi: true
    },
    { provide: TRANSFER_STATE_CACHE_TIMEOUT, useValue: 10000 },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpApiCustomResponseHeadersInterceptor,
      multi: true
    },
    { provide: WINDOW, useValue: window },
    {
      provide: DISPLAY_ADVERTISING_SERVICE,
      useClass: BrowserDisplayAdvertisingService,
      deps: [LOGGER_SERVICE, AdBlockService, AuthService, ApsService, GptService, VerticalService, PubmaticService]
    }
  ]
})
export class AppBrowserModule {}
