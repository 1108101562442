<div class="d-flex flex-row" *ngIf="showPlayIcon">
  <div class="play-icon bg-primary color-100 d-flex justify-content-center me-1"
       [ngClass]="{'no-duration': !showDuration}">
    <flo-svg-icon icon="vid_play" width="12px"></flo-svg-icon>
  </div>
  <div
    *ngIf="showDuration && !!duration"
    class="img-overlay-badge caption"
    data-test="duration"
  >{{ duration | floTime }}</div>
</div>
