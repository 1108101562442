import { Observable, of } from 'rxjs'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { catchError, filter, map, switchMap } from 'rxjs/operators'
import { LOGGER_SERVICE } from '../../logger/logger.config'
import { LoggerService } from '../../logger/logger.interface'
import { EventTickerPartial, EventTickerViewModel } from '@flocasts/experience-service-types'
import { EXPERIENCE_API_EVENT_TICKER_PARTIAL_PATH, EXPERIENCE_API_VERSION } from '../../app.config'
import { isEventTickerViewModel } from 'src/app/shared/functions/type-guards'
import { CacheDataService } from 'src/app/singleton-services/cache-data.service'
import { VerticalService } from 'src/app/singleton-services/vertical.service'
import { SiteIds } from 'src/app/shared/models/site.model'

const DEFAULT_EVENT_LIMIT = 15

/**
 * Service to fetch data needed to populate the event-ticker from experience-service
 */
@Injectable()
export class EventTickerDataService {
  constructor(
    private readonly cacheDataService: CacheDataService,
    private readonly verticalService: VerticalService,
    @Inject(LOGGER_SERVICE) private readonly logger: LoggerService
  ) {}

  /**
   * Fetch data from Experience Service's event-ticker partial endpoint.
   */
  public getEventTicker(limit: number = DEFAULT_EVENT_LIMIT): Observable<EventTickerViewModel> {
    return this.verticalService.siteId$.pipe(
      switchMap(siteId => {
        const params = new HttpParams({
          fromObject: {
            version: EXPERIENCE_API_VERSION,
            limit: limit.toString(),
            site_id: siteId || SiteIds.FLODOGS
          }
        })

        const endpoint = EXPERIENCE_API_EVENT_TICKER_PARTIAL_PATH
        return this.cacheDataService.wrapper<EventTickerPartial>(endpoint, params, true).pipe(
          map(res => res?.data),
          catchError(err => {
            this.logger.error(err.message, err.error)
            return of({} as EventTickerViewModel)
          }),
          filter(isEventTickerViewModel)
        )
      })
    )
  }
}
