// Return Format "8:00 AM CDT"  with timezone
export const convertDateToLocaleTimeWithTimezone = (date: Date) =>
  date.toLocaleTimeString('en', {
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short'
  })

// Return Format "8:00 AM"  no timezone
export const convertDateToLocaleTimeNoTimezone = (date: Date) =>
  date.toLocaleTimeString('en', {
    hour: '2-digit',
    minute: '2-digit'
  })

/**
 * Given a number of seconds, return a clean "hh:mm:ss" format.
 * e.g., return "44:22" when given 2662.12 seconds
 */
export const convertSecondsToDuration = (seconds: number, progress?: number | null): string => {
  let duration = ''

  if (progress) {
    seconds -= progress
    duration = '-'
  }

  const h = Math.floor(seconds / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  const s = Math.floor((seconds % 3600) % 60)

  const hDisplay = h > 0 ? `${h}:` : ''
  const mDisplay = m.toString().length === 1 && h ? `0${m}:` : `${m}:`
  const sDisplay = s > 0 ? (s.toString().length === 1 ? `0${s}` : s) : '00'

  return duration + hDisplay + mDisplay + sDisplay
}
