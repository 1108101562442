import { Injectable } from '@angular/core'
import { v4 } from 'uuid'

export interface IIdGeneratorService {
  getUniqueId(): string
  getShortId(): string
  getUniqueIdWithPrefix(prefix: string): string
}

@Injectable({
  providedIn: 'root'
})
export class IdGeneratorService {
  public getUniqueId() {
    return v4()
  }

  public getUniqueIdWithPrefix(prefix: string) {
    return `${prefix}-${this.getUniqueId()}`
  }

  public getShortId() {
    return this.getUniqueId().substring(0, 8)
  }
}
