import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { LargeContentCardComponentProps } from '../content-card.interface'

@Component({
  selector: 'flo-large-content-card',
  templateUrl: './large-content-card.component.html',
  styleUrls: ['./large-content-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LargeContentCardComponent {
  @Input() props: LargeContentCardComponentProps | undefined | null
}
