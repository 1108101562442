import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ImageThumbnailComponent } from './image-thumbnail.component'
import { FloPipeModule } from '../pipes/flo-pipe.module'
import { ResponsiveImageModule } from '../responsive-image/responsive-image.module'
import { VideoPlayIconModule } from '../../libs/video-play-icon/video-play-icon.module'

@NgModule({
  imports: [CommonModule, FloPipeModule, ResponsiveImageModule, VideoPlayIconModule],
  declarations: [ImageThumbnailComponent],
  exports: [ImageThumbnailComponent]
})
export class ThumbnailModule {}
