import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  InjectionToken,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  NgZone
} from '@angular/core'
import { EnvironmentService } from '../../../singleton-services/environment.service'
import { PlatformService } from '../../../singleton-services/platform.service'
import { IVideoAnalyticsData } from '../models/video-analytics-data.model'
import { maybe } from 'typescript-monads'

export const YOUBORA_LIB = new InjectionToken<any>('app.youbora.lib')

export const YOUBORA_HTML5_ADAPTER = new InjectionToken<any>('app.youbora.html5adapter')

export const YOUBORA_IMA_ADAPTER = new InjectionToken<any>('app.youbora.ima.adapter')

@Directive({
  selector: 'video[floVideoAnalytics]'
})
export class VideoAnalyticsDirective implements AfterViewInit, OnChanges, OnDestroy {
  @Input()
  public readonly videoAnalyticsData: IVideoAnalyticsData

  @Output() public readonly analyticsPlugin$ = new EventEmitter<any>()
  private plugin: any = undefined

  constructor(
    private readonly elementRef: ElementRef<HTMLVideoElement>,
    private readonly environmentService: EnvironmentService,
    private readonly platformService: PlatformService,
    private readonly zone: NgZone,
    @Inject(YOUBORA_LIB) private readonly youboralib: any,
    @Inject(YOUBORA_HTML5_ADAPTER) private readonly youboraHtml5Adapter: any
  ) {}

  private readonly youboraAccountCode = this.environmentService.config.youboraAccountCode
  private readonly youboraHost = this.environmentService.config.youboraHost

  private update() {
    this.zone.runOutsideAngular(() => this.updatePlugin())
  }

  public ngOnChanges(_simpleChanges: SimpleChanges): void {
    this.update()
  }

  public ngAfterViewInit(): void {
    this.update()
  }

  public ngOnDestroy(): void {
    if (this.plugin) {
      this.plugin.fireStop()
      this.plugin.disable()
    }
  }

  private updatePlugin(
    elementId = this.elementId,
    pluginConstructor = this.youboralib.Plugin,
    accountCode = this.youboraAccountCode,
    adapterConstructor = this.youboraHtml5Adapter,
    videoAnalyticsData = this.videoAnalyticsData,
    plugin = this.plugin,
    isBrowser = this.platformService.isBrowser,
    elementRef = this.elementRef
  ): void {
    if (
      !elementId ||
      !elementRef ||
      !pluginConstructor ||
      !accountCode ||
      !adapterConstructor ||
      !videoAnalyticsData ||
      !videoAnalyticsData['content.rendition'] ||
      !isBrowser ||
      plugin
    ) {
      return
    }
    const options: IVideoAnalyticsData = {
      ...videoAnalyticsData,
      username: videoAnalyticsData.username || '0',
      host: this.youboraHost,
      'app.name': `FloSports Web Player 3.0 - ${videoAnalyticsData['content.isLive'] ? 'Live' : 'VoD'}`,
      accountCode
    }

    /**
     * Override default adapter values.
     *
     * @see https://bitbucket.org/npaw/html5-adapter-js/src/4c83d1971b4b7acb7a8fcf76c62b6501e92901fa/src/adapter.js?at=master
     */
    adapterConstructor.prototype.getPlayerName = () => 'hls.js' // TODO: make DI token
    adapterConstructor.prototype.getPlayerVersion = () => '0.13.2'
    adapterConstructor.prototype.getVersion = () => 'youbora 6.4.25 | hls.js - 0.13.2' // TODO: sync this value with package.json

    const adapter = new adapterConstructor(elementId)
    this.plugin = new pluginConstructor(options, adapter)
    this.analyticsPlugin$.next(this.plugin)
  }

  private get elementId(): string | undefined {
    return maybe(this.elementRef.nativeElement)
      .map(elm => elm.attributes)
      .flatMapAuto(attrs => attrs.getNamedItem('id'))
      .map(a => a.value)
      .valueOrUndefined()
  }
}
