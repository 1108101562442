import { Component, Input } from '@angular/core'
import { SkeletonTheme } from './skeleton.interface'

@Component({
  selector: 'flo-skeleton',
  templateUrl: './skeleton.component.html'
})
export class SkeletonComponent {
  @Input() public theme: SkeletonTheme
}
