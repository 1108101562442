import { Component, Input } from '@angular/core'

/**
 * This component pulls SVG from a sprite sheet and displays them inline with the HTML
 * The attr.xlink:href needs to point to the current svg sprite sheet version
 *
 * @param icon - the SVG id attribute on the sprite sheet,
 *   ex: id="icon-times", <flo-svg-icon [icon]="icon-times" </flo-svg-icon>
 * @param width - width of the SVG. If no value is passed in, defaults to 1em
 *   <flo-svg-icon [icon]="icon-times" width="1em"></flo-svg-icon>
 * @param height - height of the SVG. If no value is passed in, defaults to width
 *   <flo-svg-icon [icon]="icon-times" width="1.6em" height="1em"></flo-svg-icon>
 *
 * Classes can still be used to style the SVG
 * @see src/styles/_svgs.scss
 *   <flo-svg-icon class="svg-icon-lg svg-vertical-align-middle grey-500" [icon]="icon-times"></flo-svg-icon>
 */
@Component({
  selector: 'flo-svg-icon',
  template: `
    <svg [attr.width]="width" [attr.height]="height || width">
      <title *ngIf="!!altText">{{ altText }}</title>
      <use attr.xlink:href="../../../assets/svg/symbol/icon-sprite-b14660e8.svg#{{ icon }}"></use>
    </svg>
  `
})
export class SvgIconComponent {
  @Input() icon: string
  @Input() width = '1em'
  @Input() height?: string
  @Input() altText?: string | null
}
