import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core'
import { VideoService } from 'src/app/shared/services/video.service'
import { IVideo } from 'src/app/shared/models/video.model'
import { VerticalService } from 'src/app/singleton-services/vertical.service'
import { combineLatest, Observable } from 'rxjs'
import { map } from 'rxjs/operators'

@Component({
  selector: 'flo-video-player-by-code',
  templateUrl: './video-player-by-code.component.html',
  styleUrls: ['./video-player-by-code.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoPlayerByCodeComponent implements OnInit {
  @Input() embedHash: string

  public video$: Observable<IVideo>

  ngOnInit() {
    this.video$ = combineLatest([
      this.videoService.getVideoByHash(this.embedHash),
      this.verticalService.siteSettings$
    ]).pipe(
      map(([video, settings]) => {
        return {
          ...video,
          adsetId: settings.ooyala_master_ad_set || '',
          playerId: settings.ooyala_player_id || '',
          playerColor: settings.primary_color || '#fff'
        }
      })
    )
  }

  constructor(private readonly videoService: VideoService, private readonly verticalService: VerticalService) {}
}
