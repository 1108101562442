<div class="header-container">
  <div class="header-content">
    <a
      routerLink="/"
      routerLinkActive="active"
      class="logo"
      floAnalytics
      tabindex="0"
    >
      <flo-svg-logo [siteId]="siteId"></flo-svg-logo>
    </a>
  </div>
</div>
