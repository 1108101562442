<ng-container *ngIf="{ adConfigs: adConfigs$ | async } as subs">
  <div class="bg-grey-200">
    <div class="ad-skin d-none d-xl-block">
      <flo-lazy
        [componentConstructor]="AdComponent"
        [initialProps]="subs.adConfigs | mapGet: 'skin'"
      ></flo-lazy>
    </div>
    <flo-lazy
      [componentConstructor]="AdComponent"
      [initialProps]="subs.adConfigs | mapGet: 'interstitial'"
    ></flo-lazy>
    <div class="pt-4 d-none d-lg-flex position-relative justify-content-center lazy-leaderboard-container">
      <flo-lazy
        [componentConstructor]="AdComponent"
        [initialProps]="subs.adConfigs | mapGet: 'leaderboard-large'"
        [detectorHeight]="90"
      ></flo-lazy>
    </div>
    <main class="container p-0 mt-0 mt-lg-4 min-vh-100 border-lg-top border-grey-300">
      <!-- padding around entire content, need this div so we can go full width on mobile -->
      <div class="d-flex flex-row justify-content-center">
        <!--  user utility -->
        <div class="user-utility bg-grey-150 border-end border-grey-300">
          <div class="gutter-28">
            <ng-container *ngTemplateOutlet="quickLinks"></ng-container>
            <ng-container *ngTemplateOutlet="continueWatching"></ng-container>
          </div>
        </div>
        <!-- content feed -->
        <div class="content-feed gutter-lg-28">
          <div *ngIf="errorSection$ | async" class="pt-4">
            <flo-error></flo-error>
          </div>
          <div data-test="main-hero" *ngIf="hero$ | async as hero">
            <div
              *ngFor="let item of hero.items; trackBy: trackById"
              class="content-max-width py-4 pt-lg-0 border-bottom border-grey-300"
              [ngClass]="{ 'dynamic-left-align': item.type !== 'collection', 'is-collection': item.type === 'collection' }"
            >
              <ng-container *ngIf="item.type === 'card:extra-large-content'">
                <flo-extra-large-content-card [item]="item">
                </flo-extra-large-content-card>
              </ng-container>
              <div *ngIf="item.type === 'collection'" class="add-mobile-title-padding">
                <flo-collection-content-card [section]="item"></flo-collection-content-card>
              </div>
              <ng-container *ngIf="item.type === 'card:event'">
                <flo-event-card-vertical
                  [item]="item"
                  [geoBlockedIds]="geoBlockedIds$ | async"
                ></flo-event-card-vertical>
              </ng-container>
            </div>
          </div>
          <!-- content utility will show on tablet and smaller -->
          <div class="pt-4 d-flex flex-column d-lg-none">
            <!-- headlines component -->
            <ng-container *ngTemplateOutlet="contentUtility"></ng-container>
          </div>

          <!-- banner list -->
          <ng-container *ngIf="bannerSection$ | async as bannerSection">
            <div data-test="main-banners" *ngFor="let item of bannerSection.items; let i = index; trackBy: trackById" [ngClass]="{ 'dynamic-left-align': item.type !== 'collection', 'is-collection': item.type === 'collection' }">
              <!-- PLACE INLINE MOBILE/TABLET AD AFTER THE 4TH ITEM (INCLUDING HERO ABOVE) -->
              <ng-container *ngIf="i === 3">
                <div class="py-4 content-max-width d-lg-none border-bottom border-grey-300 leaderboard-center lazy-flex-container">
                  <flo-lazy
                    [componentConstructor]="AdComponent"
                    [initialProps]="subs.adConfigs | mapGet: 'rectangle-mobile'"
                    [detectorHeight]="50"
                  ></flo-lazy>
                </div>
              </ng-container>
              <ng-container [ngSwitch]="item.type">
                <div *ngSwitchCase="'card'" class="py-4 content-max-width border-bottom border-grey-300">
                  <flo-static-content-card [card]="item" imagePosition="right"></flo-static-content-card>
                </div>
                <div *ngSwitchCase="'card:extra-large-content'" class="content-max-width py-4 border-bottom border-grey-300">
                  <flo-extra-large-content-card [item]="item"></flo-extra-large-content-card>
                </div>
                <div *ngSwitchCase="'collection'" class="content-max-width py-4 border-bottom border-grey-300 add-mobile-title-padding">
                  <flo-collection-content-card [section]="item"></flo-collection-content-card>
                </div>
                <div *ngSwitchCase="'card:event'" class="content-max-width py-4 border-bottom border-grey-300">
                  <flo-event-card-vertical
                    [item]="item"
                    [geoBlockedIds]="geoBlockedIds$ | async"
                  ></flo-event-card-vertical>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
        <!-- content utility -->
        <div class="content-utility d-none d-lg-flex border-start border-grey-300">
          <div class="gutter-28">
            <ng-container *ngTemplateOutlet="contentUtility"></ng-container>
            <div class="pt-4 stick-to-header">
              <flo-lazy
                [componentConstructor]="AdComponent"
                [initialProps]="subs.adConfigs | mapGet: 'rectangle-large-sticky'"
              ></flo-lazy>
            </div>
          </div>
        </div>
      </div>  <!-- end padding around entire content -->
    </main>
    <div class="stick-to-footer d-sm-none">
      <div class="adhesion-ad">
        <flo-lazy
          [componentConstructor]="AdComponent"
          [initialProps]="subs.adConfigs | mapGet: 'adhesion'"
        ></flo-lazy>
      </div>
    </div>
  </div>

  <!-- templates -->
  <ng-template #quickLinks>
    <div data-test="quick-links" *ngIf="pinnedLinks$ | async as pinnedLinks" class="w-100 pb-4 border-bottom border-grey-300">
      <div>
        <div class="dynamic-left-align content-max-width pinned-links__title d-flex justify-content-between align-items-baseline mb-2 mb-md-1">
          <h6 class="heavy">{{ pinnedLinks.title }}</h6>
          <a
            *ngIf="pinnedLinks.action"
            [floCustomLink]="pinnedLinks.action.url"
            class="subhead hover-underline color-500"
          >{{ pinnedLinks.action.title }}</a>
        </div>
      </div>
      <div class="d-flex flex-row flex-nowrap content-container">
        <div class="d-block d-lg-none left-handle-container"></div>
        <flo-pinned-links-favorites [section]="pinnedLinks"></flo-pinned-links-favorites>
      </div>
    </div>
  </ng-template>

  <ng-template #continueWatching>
    <div data-test="continue-watching" *ngIf="continueWatchingVideos$ | async as continueWatchingVideos" class="w-100 pt-xl-4">
      <div>
        <div class="dynamic-left-align content-max-width d-flex justify-content-between align-items-baseline mb-2 mb-md-1">
          <h6 class="heavy">{{continueWatchingVideos.title}}</h6>
        </div>
      </div>
      <div class="d-flex flex-row flex-nowrap content-container">
        <div class="d-block d-lg-none left-handle-container"></div>
        <flo-continue-watching [videos]="continueWatchingVideos.items"></flo-continue-watching>
      </div>
    </div>
  </ng-template>

  <ng-template #favorites>
    <div>
      <div class="dynamic-left-align content-max-width pinned-links__title d-flex justify-content-between align-items-baseline mb-25 mb-lg-0">
        <h2>future prompt to anonymous users</h2>
        <!-- TODO: All these props, and the placement of this, will be updated in https://flocasts.atlassian.net/browse/CXP-1353 -->
  <!--      <flo-personalization-cta-->
  <!--        [image]='"https://d2779tscntxxsw.cloudfront.net/60b2558f98e28.png?quality=90&width=300"'-->
  <!--        [title]='"Add your favorites!"'-->
  <!--        [subtitle]='"Personalize your app by adding your favorite teams and athletes."'-->
  <!--        [btnTitle]='"Sign Up"'-->
  <!--        [href]='"/login?redirect=%2Faccount%2Fchoose-favorites&register=1"'-->
  <!--      ></flo-personalization-cta>-->
      </div>
    </div>
  </ng-template>

  <ng-template #contentUtility>
    <div data-test="headlines" class="w-100 dynamic-left-align content-max-width pb-4 border-bottom border-grey-300" *ngIf="headlines$ | async as headlines">
      <flo-headlines [section]="headlines"></flo-headlines>
    </div>
    <!-- TODO: This ad goes away on desktop if there are no favorites -->
    <div class="rectangle-small dynamic-left-align content-max-width py-4 justify-content-center lazy-flex-container">
      <flo-lazy
        [componentConstructor]="AdComponent"
        [initialProps]="subs.adConfigs | mapGet: 'rectangle-small'"
      ></flo-lazy>
    </div>
    <div *ngIf="pinnedLinks$ | async" class="pt-4 quick-links-rail border-top border-grey-300">
      <ng-container *ngTemplateOutlet="quickLinks"></ng-container>
    </div>
    <!-- TODO Favorites will only show on select sites -->
    <div *ngIf="isInVariationToCreateFreeAccount$ | async" class="py-4 favorites-rail border-bottom border-grey-300">
      <ng-container *ngTemplateOutlet="favorites"></ng-container>
    </div>
    <div *ngIf="continueWatchingVideos$ | async" class="continue-watching-rail pt-4 pb-4 border-bottom border-grey-300">
      <ng-container *ngTemplateOutlet="continueWatching"></ng-container>
    </div>
  </ng-template>
</ng-container>
