import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

import { AnalyticsDirective } from './directives/analytics.directive'
import {
  VideoAnalyticsDirective,
  YOUBORA_LIB,
  YOUBORA_HTML5_ADAPTER,
  YOUBORA_IMA_ADAPTER
} from './directives/video-analytics.directive'
import * as youboralib from 'youboralib'
import * as youboraHtml5Adapter from 'youbora-adapter-html5'
import * as youboraImaAdapter from 'youbora-adapter-ima'
import { HasOffersService } from './services/has-offers.service'

@NgModule({
  imports: [CommonModule],
  declarations: [AnalyticsDirective, VideoAnalyticsDirective],
  exports: [AnalyticsDirective, VideoAnalyticsDirective],
  providers: [
    HasOffersService,
    {
      provide: YOUBORA_LIB,
      useValue: youboralib
    },
    {
      provide: YOUBORA_IMA_ADAPTER,
      useValue: youboraImaAdapter
    },
    {
      provide: YOUBORA_HTML5_ADAPTER,
      useValue: youboraHtml5Adapter
    }
  ]
})
export class AnalyticsModule {}
