<ng-container *ngIf="table">
  <div
    class="
      d-flex
      justify-content-between
      align-items-baseline
      mb-2 mx-3 mx-sm-0
    "
  >
    <h5
      *ngIf="!!table.title"
      class="h5 m-0"
      data-test="table-title"
    >
      {{ table.title }}
    </h5>
    <a
      *ngIf="table.subtitle | floIsLink; else displayText"
      floAnalytics
      [analyticsProperties]="table.subtitle?.analytics"
      [floCustomLink]="table.subtitle?.url"
      data-test="table-subtitle-link"
      class="
        footnote
        color-500
        ms-auto
        hover-underline hover-grey-500
      "
    >
      {{ table.subtitle?.title }}
    </a>
    <ng-template #displayText>
      <h5
        *ngIf="table.subtitle | floIsText"
        class="footnote mb-0 ms-auto"
        data-test="table-subtitle-text"
      >
        {{ table.subtitle?.text }}
      </h5>
    </ng-template>
  </div>
  <div class="section-tables table-responsive">
    <table
      class="table-striped"
      [ngClass]="table.style | floCssClassSanitize"
    >
      <thead
        *ngIf="table.headers"
        data-test="table-header"
        [ngClass]="table.headers.style | floCssClassSanitize"
      >
        <tr scope="row" class="table-header-row">
          <th
            scope="col"
            *ngFor="
              let cell of table.headers.cells;
              trackBy: trackById;
              let headerIndex = index;
              let lastHeader = last
            "
            class="overtext text-truncate"
            [attr.data-test]="'header-cell-' + headerIndex"
            title="{{ cell.data?.text }}"
            [ngClass]="[
              'table-header-cell-' + cell.key,
              cell.style | floCssClassSanitize,
              lastHeader && cell.sticky
                ? 'sticky-right'
                : cell.sticky && headerIndex === 0
                ? 'sticky-left'
                : cell.sticky && headerIndex === 1
                ? 'sticky-left-additional'
                : ''
            ]"
          >
            <div
              *ngIf="cell.data"
              [ngClass]="cell.data.style | floCssClassSanitize"
              [attr.data-test]="'header-cell-' + headerIndex + '-data'"
            >
              <ng-container [ngSwitch]="cell.data.type">
                <ng-container *ngSwitchCase="'text'">
                  {{ cell.data.text | uppercase }}
                </ng-container>
                <ng-container *ngSwitchCase="'icon'">
                  <flo-svg-icon
                    [icon]="cell.data.style"
                    [title]="cell.data.title"
                  ></flo-svg-icon>
                </ng-container>
                <ng-container *ngSwitchCase="'link'">
                  <div class="link">
                    {{ cell.data.title ?? 'Link' }}
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          scope="row"
          *ngFor="let row of table.rows; trackBy: trackById; let rowIndex = index"
          class="table-row {{ 'table-row-' + rowIndex }}"
          [attr.data-test]="'row-' + rowIndex"
          [ngClass]="row.style | floCssClassSanitize"
        >
          <td
            *ngFor="
              let cell of row.cells;
              trackBy: trackById;
              let cellIndex = index;
              let lastCell = last
            "
            class="text-truncate"
            [ngClass]="[
              'table-cell-' + cell.key,
              rowIndex % 2 === 0 ? 'dark-cell' : 'light-cell',
              cell.style | floCssClassSanitize,
              lastCell && table.headers.cells[cellIndex].sticky
                ? 'sticky-right'
                : cellIndex === 0 && table.headers.cells[cellIndex].sticky
                ? 'sticky-left'
                : cellIndex === 1 && table.headers.cells[cellIndex].sticky
                ? 'sticky-left-additional'
                : ''
            ]"
            [attr.data-test]="'row-' + rowIndex + '-table-cell-' + cellIndex"
          >
            <a
              [floCustomLink]="
                cell.data?.type === 'link' ? cell.data?.url : row.action?.url
              "
              [attr.data-test]="
                'row-' + rowIndex + '-table-cell-' + cellIndex + '-anchor'
              "
              floAnalytics
              [analyticsProperties]="
                cell.data?.type === 'link'
                  ? cell.data?.analytics
                  : row.action?.analytics
              "
              [ngClass]="
                (cell.data?.type === 'link' &&
                  cell.data.asset?.type === 'icon') ||
                cell.data?.type === 'icon'
                  ? 'icon-center'
                  : ''
              "
              [style.cursor]="
                row.action?.url || (cell.data?.url && cell.data?.type === 'link')
                  ? 'pointer'
                  : 'inherit'
              "
            >
              <div
                *ngIf="cell.data"
                class="text-truncate"
                [ngClass]="cell.data.style | floCssClassSanitize"
                [attr.data-test]="
                  'row-' + rowIndex + '-cell-' + cellIndex + '-data'
                "
              >
                <ng-container [ngSwitch]="cell.data.type">
                  <ng-container *ngSwitchCase="'text'">
                    <ng-container *ngIf="cell.data.textParts">
                      <div class="d-flex">
                        <flo-event-status
                          class="text-truncate text-parts"
                          [props]="cell.data.textParts"
                          showBadge="true"
                        ></flo-event-status>
                        <ng-container *ngIf="cell.data.textParts.favoriteIcon as icon">
                          <flo-svg-icon
                            class="svg-icon svg-vertical-align-middle ms-2"
                            [ngClass]="icon.style | floCssClassSanitize"
                            [icon]="icon.style"
                            [altText]="icon.accessibility?.altText"
                          ></flo-svg-icon>
                        </ng-container>
                      </div>

                    </ng-container>
                    <div class="text-truncate text">{{ cell.data.text }}</div>
                    <div class="text-truncate sub-text">{{
                      cell.data.subText
                    }}</div>
                  </ng-container>
                  <ng-container *ngSwitchCase="'image'">
                    <ng-container *ngIf="!!row.sport && cell.key === 'streaming'">
                      <div class="d-md-none mb-auto text-truncate" data-test="sport-text">{{ row.sport.text }}</div>
                    </ng-container>
                    <img
                      [src]="cell.data.url"
                      [alt]="cell.data.altText"
                      class="no-skeleton"
                    />
                  </ng-container>
                  <ng-container *ngSwitchCase="'link'">
                    <div
                      [attr.data-test]="
                        'row-' + rowIndex + '-table-cell-' + cellIndex + '-link'
                      "
                      class="text-truncate link"
                    >
                      <ng-container [ngSwitch]="cell.data.asset?.type">
                        <ng-container *ngSwitchCase="'icon'">
                          <flo-svg-icon
                            class="svg-icon svg-vertical-align-middle"
                            [icon]="cell.data.asset.style"
                            [altText]="cell.data.asset.accessibility?.altText"
                          ></flo-svg-icon>
                        </ng-container>
                        <ng-container *ngSwitchCase="'image'">
                          <img
                            [src]="cell.data.asset.url"
                            [alt]="cell.data.asset.altText"
                            class="no-skeleton"
                          />
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                          <div class="link-title text-truncate">{{
                            cell.data.title ?? 'Link'
                          }}</div>
                        </ng-container>
                      </ng-container>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="'icon'">
                    <div class="d-flex align-items-baseline">
                      <flo-svg-icon
                        data-test="icon-svg"
                        class="svg-icon svg-vertical-align-middle"
                        [icon]="cell.data.style"
                        [title]="cell.data.title"
                      ></flo-svg-icon>
                      <span data-test="icon-text" *ngIf="cell.data.text">{{
                        cell.data.text
                      }}</span>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="table.caption" class="caption">
    <ng-container *ngIf="table.caption.text">
      <span class="caption-text">{{ table.caption.text }}</span>
    </ng-container>
    <ng-container *ngIf="table.caption.textParts?.type === 'legend-parts'">
      <div class="d-flex flex-wrap legend">
        <div *ngFor="let legendItem of table.caption?.textParts?.items">
          <div class="d-flex legend-item">
            <span class="legend-key">{{legendItem[0]}}: </span>
            <span class="legend-value">{{legendItem[1]}}</span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
