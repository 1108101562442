import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { BehaviorSubject, combineLatest, fromEvent, Subject, merge } from 'rxjs'

import { filter, flatMap, map, shareReplay, startWith, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators'
import { maybe } from 'typescript-monads'
import { SegmentService } from '../../../analytics/services/segment.service'
import { IVideoScrubber } from '../../../../live/components/video-player/video-player.interfaces'

@Component({
  selector: 'flo-video-scrubber-control',
  templateUrl: './video-scrubber-control.component.html',
  styleUrls: ['./video-scrubber-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoScrubberControlComponent implements AfterViewInit, OnChanges {
  constructor(
    private readonly segmentService: SegmentService,
    @Inject(DOCUMENT) private readonly document: HTMLDocument
  ) {}
  @Input()
  get eventAnalytics(): any {
    return {
      ...this._eventAnalytics
    }
  }

  set eventAnalytics(val: any) {
    this._eventAnalytics = val
  }

  @Input()
  currentTime: IVideoScrubber
  @Input()
  bufferAmount: number
  @Input()
  duration: number
  @Output()
  readonly videoPositionChange = new EventEmitter<number>()
  @Output()
  readonly pauseVideoOnSeeking = new EventEmitter<any>()
  @ViewChildren('progressBar')
  progressBar?: QueryList<ElementRef<HTMLDivElement>>
  private _eventAnalytics: any

  public ngAfterViewInit$ = new Subject<void>()
  public scrubberElementSource = new Subject<HTMLDivElement>()
  public scrubberElement$ = this.scrubberElementSource.pipe(shareReplay(1))
  public currentTimeSource = new BehaviorSubject<IVideoScrubber>({
    currentTime: 0,
    duration: 0
  })
  public currentTime$ = this.currentTimeSource
  public durationSource = new BehaviorSubject<number>(0)
  public duration$ = this.durationSource
  public updateScrubberSource = new Subject<number>()

  public updateScrubber$ = this.updateScrubberSource.pipe(
    withLatestFrom(this.scrubberElement$),
    map(([pageX, scrubberElement]) => mapPositionOnProgressBar(scrubberElement, pageX)),
    filter(position => position <= 100 && position >= 0)
  )

  public scrubberChange$ = this.updateScrubber$.pipe(
    withLatestFrom(this.duration$),
    map(([position, duration]) => {
      const currentTime = (duration * position) / 100
      return { currentTime, duration }
    }),
    tap(videoTime => this.videoPositionChange.next(videoTime.currentTime))
  )

  public bufferAmountSource = new BehaviorSubject<number>(0)
  public bufferAmount$ = this.bufferAmountSource
  public hoverAmountSource = new BehaviorSubject<number>(0)

  public hoverAmount$ = this.hoverAmountSource.pipe(
    withLatestFrom(this.scrubberElement$),
    map(([pageX, scrubberElement]) => mapPositionOnProgressBar(scrubberElement, pageX))
  )

  public mouseDown$ = this.scrubberElement$.pipe(
    flatMap(scrubberElement =>
      merge(
        fromEvent(scrubberElement, 'mousedown'),
        fromEvent(scrubberElement, 'touchstart').pipe(
          map((touchEvent: TouchEvent) => ({
            clientX: touchEvent.touches[0].clientX
          }))
        )
      )
    )
  )

  public mouseMove$ = merge(
    fromEvent(this.document, 'mousemove'),
    fromEvent(this.document, 'touchmove').pipe(
      map((touchEvent: TouchEvent) => ({
        clientX: touchEvent.touches[0].clientX
      }))
    )
  )

  public mouseUp$ = merge(fromEvent(this.document, 'mouseup'), fromEvent(this.document, 'touchend'))

  moveScrubber$ = this.mouseDown$
    .pipe(
      tap((mouseDown: MouseEvent) => {
        this.updateScrubberSource.next(mouseDown.clientX)
        this.segmentService.track('Player Control Clicked', {
          name: 'Scrubber',
          ...this.eventAnalytics
        })
      }),
      switchMap(() => {
        return this.mouseMove$.pipe(takeUntil(this.mouseUp$))
      })
    )
    .subscribe((mouseEvent: MouseEvent) => {
      this.updateScrubberSource.next(mouseEvent.clientX)
    })

  view$ = combineLatest(
    merge(this.currentTime$, this.scrubberChange$),
    this.bufferAmount$,
    this.hoverAmount$.pipe(startWith(0)),
    this.scrubberElement$.pipe(startWith({} as HTMLDivElement))
  ).pipe(
    map(([timeObj, bufferAmount, hoverPosition, scrubberElement]) => {
      const scrubberBar = scrubberElement && scrubberElement.clientWidth
      const duration = timeObj.duration || 100
      const percentage = timeObj.currentTime / duration
      const hoverTime = (hoverPosition * duration) / 100
      const mousePosition = (hoverTime / duration) * scrubberBar
      const scrubberPosition = percentage * scrubberBar

      return {
        duration,
        bufferAmount,
        currentPositionStyle: { width: `${percentage * 100}%` },
        bufferStyle: { width: `${bufferAmount}%` },
        scrubberStyle: { transform: `translateX(${scrubberPosition}px)` },
        hoverAmount: { width: `${hoverPosition}%` },
        mousePosition: { left: `${mousePosition - 20}px` },
        percentage,
        hoverTime
      }
    })
  )

  ngAfterViewInit() {
    if (!!this.progressBar) {
      this.progressBar.changes
        .pipe(
          startWith(this.progressBar),
          filter(res => res.first)
        )
        .subscribe(res => this.scrubberElementSource.next(res.first.nativeElement))
    }

    this.ngAfterViewInit$.next()
    this.ngAfterViewInit$.complete()
  }

  mouseHoverPosition(mouseMove: MouseEvent) {
    this.hoverAmountSource.next(mouseMove.clientX)
  }

  ngOnChanges(changes: SimpleChanges): void {
    maybe(changes.currentTime)
      .flatMap(change => maybe<IVideoScrubber>(change.currentValue))
      .tapSome(val => this.currentTimeSource.next(val))

    maybe(changes.duration)
      .flatMap(change => maybe<number>(change.currentValue))
      .tapSome(val => this.durationSource.next(val))

    maybe(changes.bufferAmount)
      .flatMap(change => maybe<number>(change.currentValue))
      .tapSome(val => this.bufferAmountSource.next(val))
  }
}

// Used to calculate the position of both the scrubber while a user seeks on the video and when hovering over
// the scrub bar.
const mapPositionOnProgressBar = (elem: HTMLDivElement, pageX: number) => {
  const scrubberRect = elem && elem.getBoundingClientRect()
  const position = elem && ((pageX - scrubberRect.left) * 100) / elem.clientWidth
  return position
}
