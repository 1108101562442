// tslint:disable:variable-name
export class Site {
  /**
   * This is the universal site id for all the verticals
   */
  public static readonly UNIVERSAL_SITE: number = 13

  active?: boolean
  code?: string
  color?: string
  created_by?: string
  deleted_by?: string
  host?: string
  id?: number
  modified_by?: string
  name?: string
  show_on_mobile?: boolean
  sport_name?: string
  type?: string
  version?: number
}

export enum SiteIds {
  FLOTRACK = 1,
  FLOWRESTLING = 2,
  FLOGYMNASTICS = 4,
  PUREFIGHT = 5,
  FLOBOXING = 6,
  FLOHOOPS = 7,
  FLOGRAPPLING = 8,
  FLOCATS = 9,
  FLOCHEER = 10,
  FLOMUSCLE = 11,
  FLOELITE = 12,
  FLOSPORTS = 13,
  FLOSOFTBALL = 14,
  MILESPLIT = 15,
  MILESPLIT_TRACK = 16,
  MILESPLIT_CROSS_COUNTRY = 17,
  VARSITY = 20,
  UNITED_WORLD_WRESTLING = 21,
  FLOVOLLEYBALL = 22,
  FLOCOMBAT = 23,
  FLOKO = 24,
  FLOTENNIS = 25,
  FLOCLIMBING = 26,
  FLOMARCHING = 27,
  FLOSWIMMING = 28,
  FLOHOCKEY = 29,
  FLORODEO = 30,
  FLOSLAM = 31,
  FLORACING = 32,
  FLOVOICE = 33,
  FLORUGBY = 34,
  FLOLIVE = 35,
  FLODANCE = 36,
  FLOBIKES = 37,
  FLOFOOTBALL = 38,
  FLOMARCHINGVIDEOS = 39,
  FLOLACROSSE = 40,
  FLOBOWLING = 41,
  FLOBASEBALL = 42,
  FLOFC = 43,
  TRACK_WRESTLING = 44,
  FLOCOLLEGE = 45,
  FLOCASTS = 9000,
  FLODOGS = 9001
}

/**
 * This enum is primarily used for retrieving data from the site settings map where
 * site codes (pulled from the domain) are used as keys.
 */
export enum SiteCode {
  Baseball = 'flobaseball',
  Bikes = 'flobikes',
  Bowling = 'flobowling',
  Cheer = 'flocheer',
  College = 'flocollege',
  Combat = 'flocombat',
  Dance = 'flodance',
  Dogs = 'flodogs',
  Elite = 'floelite',
  Football = 'flofootball',
  Grappling = 'flograppling',
  Gymnastics = 'flogymnastics',
  Hockey = 'flohockey',
  Hoops = 'flohoops',
  Live = 'flolive',
  Marching = 'flomarching',
  MileSplit = 'milesplit',
  Racing = 'floracing',
  Rodeo = 'florodeo',
  Rugby = 'florugby',
  Soccer = 'flofc',
  Softball = 'flosoftball',
  Swimming = 'floswimming',
  Track = 'flotrack',
  TrackWrestling = 'trackwrestling',
  Universal = 'flosports',
  Varsity = 'varsity',
  Voice = 'flovoice',
  Volleyball = 'flovolleyball',
  Wrestling = 'flowrestling'
}

export const EXTERNAL_SITE_IDS = [SiteIds.MILESPLIT, SiteIds.TRACK_WRESTLING]

export const TRACK_WRESTLING_STAG_URL = 'https://staging.trackwrestling.com'
export const TRACK_WRESTLING_PROD_URL = 'https://www.trackwrestling.com'
