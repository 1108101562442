import { Observable } from 'rxjs'
import { ITokenResponse } from '../../shared/services/user.service'
import { FacebookTokenInterface } from '../../shared/services/facebook-access-token.service'

export const USER_STATUS = {
  FREE: 'free',
  ANONYMOUS: 'anon',
  PREMIUM: 'isPremium',
  ADMIN: 'isAdmin'
}

export interface IAnalyticsData {
  id?: string
  account_type: string
  created_at: string
  email: string
  facebook_id?: string
  first_name?: string
  last_name?: string
  name?: string
  plan_type?: string
  site_id: number
  subscription_end_date?: string
  subscription_status?: string
  universal: boolean
  username: string
}

export interface IUserIdentity {
  analyticsData: IAnalyticsData
  id: string
  claims: any
  roles: Set<string>
  username?: string
  email?: string
  new_facebook_user?: boolean
  tokenHash: string
  subscriptionStatus?: string
  isAdmin(): boolean
  isPremium(): boolean
  isAdFree(): boolean
  isInRole(role: string): boolean
}

export interface ITokenSchema {
  analyticsData: string
  id: string
  email: string
  username: string
  firstName: string
  lastName: string
  roles: string
  roleDelimeter: string
  subscriptionStatus: string
  adminRoleNames: ReadonlyArray<string>
  adFreeRoleNames: ReadonlyArray<string>
}

export interface IAuthServiceConfig {
  authTokenStorageKey: string
  authTokenRefreshStorageKey: string
  authTokenPayloadKey: string
  tokenSchema: ITokenSchema
  userFactory(token: string, schema: ITokenSchema): IUserIdentity
}

export interface IUser {
  id?: string
  isAdmin: boolean
  loggedIn: boolean
  analyticsData?: IAnalyticsData
  isPremium: boolean
  subscriptionStatus?: string
}

export interface IFacebookAuth extends ITokenResponse {
  facebook_id: number
  new_facebook_user: boolean
}

export interface IAuthService {
  userIdentity$: Observable<IUserIdentity | undefined>
  loggedIn$: Observable<boolean>
  isAdmin$: Observable<boolean>
  getTokenFromStore(): string | undefined
  authorizeViaHttp(tokenRequest: Observable<ITokenResponse>): Observable<IUserIdentity | undefined>
  logout(redirect?: string): void
  getUserFromStoredToken(): IUserIdentity | undefined
  facebookLogin(
    authResponse: FacebookTokenInterface | undefined,
    mileSplitRefCode?: string,
    mileSplitSiteId?: string
  ): Observable<
    | {
        user: IUserIdentity
        facebook_id: number
        new_facebook_user?: boolean
      }
    | undefined
  >
  refreshViaToken(): Observable<IUserIdentity | undefined>
}

export enum ResourceType {
  Node = 'node',
  LiveEvent = 'liveEvent',
  Site = 'site'
}

/**
 * Content that is being checked for entitlements
 *
 * This can be an article, ranking, VOD, or live event. All these types share these properties, and could be a complex
 * union type but this is simpler and easier to read.
 *
 * Premium and type are optional because IVideo extends deprecated INode, which incorrectly labels these optional.
 */
export interface EntitlementResource {
  id: string | number
  premium: boolean
}

/**
 * The Entitlements response
 *
 * This project is still in development, and there isn't a solid response object yet.
 * The various properties will be present or absent depending on response status (forbidden, success, etc)
 */
export interface EntitlementsResponse {
  statusCode?: number
  message?: string
  status?: string
}

export function getHighestTierForUser(user: IUser) {
  return user.isAdmin
    ? USER_STATUS.ADMIN
    : user.isPremium
    ? USER_STATUS.PREMIUM
    : user.loggedIn
    ? USER_STATUS.FREE
    : USER_STATUS.ANONYMOUS
}
