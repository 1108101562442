import { ChangeDetectionStrategy, Component, Output, EventEmitter, Input } from '@angular/core'
import { SegmentEvents } from 'src/app/shared/analytics/models/segment-events.model'
import { trackById } from 'src/app/shared/utility-functions/track-by-id.utility'
import { DropdownOption } from './dropdown.component'

@Component({
  selector: 'flo-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownMenuComponent {
  /**
   * Unique identifier of the dropdown, useful when emitting back to the parent cmp
   */
  @Input()
  id: string | number

  /**
   * The title of the parent dropdown
   */
  @Input()
  title: string

  /**
   * The dropdown options
   */
  @Input()
  options: DropdownOption[] = []

  /**
   * When true, allow the user to clear the selected value
   */
  @Input()
  clearable = true

  /**
   * When true, the dropdown menu will wrap the menu items
   * in anchor tags for user routing
   */
  @Input()
  routeWithActionUrls = false

  /**
   * Emits the selected option each time an option is selected or deselected
   */
  @Output()
  optionClicked = new EventEmitter<DropdownOption>()

  dropDownOptionSelected: string = SegmentEvents.DROPDOWN_OPTION_SELECTED
  dropDownOptionDeselected: string = SegmentEvents.DROPDOWN_OPTION_DESELECTED

  /**
   * Click event that fires when an option has been selected
   * @param option The option clicked.
   */
  clickOption(option: DropdownOption) {
    // Disable the behavior where clicking an already selected option clears it
    if (!this.clearable && option.isSelected) {
      return
    }

    // Toggle the selected state for the given option
    option.isSelected = !option.isSelected

    this.optionClicked.emit(option)
  }

  // Track by to reduce DOM repainting as new options are dynamically loaded
  readonly trackById = trackById
}
