<div *ngIf="!!legend" class="d-flex flex-column footnote">
  <span *ngIf="!!legend.title" class="legend-title font-bold">{{legend.title | uppercase }}</span>
  <div class="legend-list d-flex">
    <ng-container *ngFor="let item of legend.values; trackBy: trackById;">
      <div *ngIf="item[0].type === 'text' && item[1].type === 'text'" class="legend-item d-flex">
        <span class="font-bold">{{item[0].text | uppercase }}{{legend.separator}}</span>
        <span class="legend-value">{{item[1].text}}</span>
      </div>
    </ng-container>
  </div>
</div>