/**
 * TODO: Move this to the experience service.
 * @see {@link https://flocasts.atlassian.net/browse/FLO-15156}
 */
export type BtnClass = 'btn-primary' | 'btn-secondary' | 'btn-tertiary'

/**
 * Return the proper CSS class for each button.
 *
 * TODO In the BFF create a more strict enum (like CtaStyle) in the experience.enum file
 * with only these three options and we can export in types pkg and use here.
 *
 * @see {@link https://flocasts.atlassian.net/browse/FLO-15156}
 */
export function calculateBtnCss(style: 'cta:primary' | 'cta:secondary' | 'cta:tertiary' | string | null): BtnClass {
  switch (style) {
    case 'cta:primary':
      return 'btn-primary'
    case 'cta:secondary':
      return 'btn-secondary'
    case 'cta:tertiary':
      return 'btn-tertiary'
    default:
      return 'btn-secondary'
  }
}
