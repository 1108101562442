<div #volumeIcon class="volume-container" *ngIf="view$ | async as volume">
  <div
    class="icon-container"
    (click)="_muteVolume()"
    floAnalytics
    tagName="Player Control"
    events="click"
    [analyticsProperties]="volume.analyticProps"
  >
    <i [hidden]="!volume.highVolume" floSvg="volume_high" title="Mute"></i>
    <i [hidden]="!volume.midVolume" floSvg="volume_medium" title="Mute"></i>
    <i [hidden]="!volume.lowVolume" floSvg="volume_low" title="Mute"></i>
    <i [hidden]="!volume.mutedVolume" floSvg="volume_mute" title="Unmute"></i>
  </div>

  <input
    id="vol-control"
    type="range"
    min="0"
    max="1"
    step=".01"
    [value]="volume.volume"
    (change)="_volumeChanged($event.target.value)"
    (input)="_volumeDragged($event.target.value)"
  />
</div>
