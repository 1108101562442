<a
  *ngIf="props"
  floAnalytics
  [analyticsProperties]="props.analyticsProps"
  tagName="List Item"
  elementName="Large Content Card"
  class="large-content-card d-flex flex-column cursor-pointer image-opacity-hover primary-color-text-hover"
  [floCustomLink]="props.destination"
>
  <div class="large-content-card__image image w-100 h-auto image-opacity-hover-target">
    <flo-image-thumbnail
      [imageUrl]="props.imageThumbnailProps.imageUrl"
      [imageSize]="props.imageThumbnailProps.imageSize"
      [altText]="props.imageThumbnailProps.altText"
      [duration]="props.imageThumbnailProps.duration"
      [mobileSize]="props.imageThumbnailProps.mobileSize"
    >
      <div *ngIf="props.badgeText !== null" class="large-content-card__image-badge-text d-flex flex-row w-100">
        <div class="img-overlay-badge caption">{{ props.badgeText }}</div>
      </div>
    </flo-image-thumbnail>
  </div>
  <div
    class="large-content-card__text d-flex flex-column justify-content-between"
  >
    <h3 class="large-content-card__title font-family-heavy h3 text-line-clamp-2 mb-0 apply-text-hover">
      {{ props.displayTitle }}
    </h3>
    <p *ngIf="props.subtext !== null"  class="large-content-card__subtext color-500 subtext footnote m-0">
      {{ props.subtext }}
    </p>
  </div>
</a>
