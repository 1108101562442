import { HttpClient } from '@angular/common/http'
import { Observable, of } from 'rxjs'
import { Inject, Injectable, InjectionToken } from '@angular/core'
import { tap, catchError } from 'rxjs/operators'
import { LOGGER_SERVICE } from 'src/app/logger/logger.config'
import { LoggerService } from 'src/app/logger/logger.interface'
import { OldSvgCacheService } from './old-svg-cache.service'

export interface OldSvgLoader {
  load(name: string): Observable<string>
}

export type OldSvgRequest = (name: string) => string
export const OLD_SVG_REQUEST_PATTERN = new InjectionToken<OldSvgRequest>('cfg.svg.pattern')

@Injectable({
  providedIn: 'root'
})
export class OldSvgLoaderService implements OldSvgLoader {
  constructor(
    @Inject(LOGGER_SERVICE) private readonly log: LoggerService,
    @Inject(OLD_SVG_REQUEST_PATTERN) private readonly reqPattern: OldSvgRequest,
    private readonly http: HttpClient,
    private readonly svgCache: OldSvgCacheService
  ) {}

  load(name: string): Observable<string> {
    const cached = this.svgCache.retrieve(name)

    return cached
      ? of(cached)
      : this.http
          .get(this.reqPattern(name), {
            responseType: 'text',
            withCredentials: false
          })
          .pipe(
            tap(svg => this.svgCache.store(name, svg)),
            catchError(err => {
              this.log.error('Failed to load SVG.', { err })
              return this.http.get(this.reqPattern('not-found'), {
                responseType: 'text',
                withCredentials: false
              })
            }),
            catchError(err => {
              this.log.error('Failed to load fallback SVG.', { err })
              return of('')
            })
          )
  }
}
