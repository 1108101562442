import { Injectable } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { map, skip, take, tap } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class ViewModeService {
  public isMobileWebViewMode = false
  public isMinimal$ = this.activatedRoute.queryParamMap.pipe(
    map(params => params.get('viewMode') === 'minimal'),
    // This is a BehaviorSubject, so the initial value pushed to the sequence is actually an empty object.
    // So the first emission is always false, even when ?viewMode=minimal is present.
    // Therefore, we skip the first and always empty value.
    skip(1),
    // We only want to take the first emission after the skip
    // because we want to put the user into minimal mobile web view mode
    // despite the query param possibly being stripped out by future client-side navigation.
    take(1),
    // Store the value as a variable to show/hide event ticker
    // and other app-level components.
    tap(res => (this.isMobileWebViewMode = res))
  )

  constructor(private activatedRoute: ActivatedRoute) {}
}
