<div
  ngbDropdown
  container="body"
  class="dropdown-container d-inline-block"
  [autoClose]="multiselect ? 'outside' : true"
  (openChange)="handleOpenChange($event)"
  data-test="dropdown"
>
  <button
    class="btn button-dropdown footnote"
    floAnalytics
    tagName="Dropdown"
    [elementName]="title"
    [analyticsProperties]="analytics ?? { item_order: order }"
    [ngClass]="{ 'selected-option': optionsSelectedCount > 0, 'dropdown-open': dropdownOpen }"
    [id]="id"
    ngbDropdownToggle
    data-test="dropdown-button"
    >
    <span class="selected-option-text">{{ selectedOptionTitle || title }} </span>
    <span *ngIf="optionsSelectedCount && multiselect">({{ optionsSelectedCount }})</span>
    <span
      class="dropdown-arrow"
      [class.rotate-svg-180]="dropdownOpen"
    >
      <flo-svg-icon
        class="svg-vertical-align-top"
        icon="angle-down"
        width="20px"
      ></flo-svg-icon>
    </span>
  </button>
    <flo-dropdown-menu
      ngbDropdownMenu
      [id]="id"
      [title]="title"
      [options]="options"
      [clearable]="clearable"
      [routeWithActionUrls]="routeWithActionUrls"
      (optionClicked)="clickOption($event)"
      >
    </flo-dropdown-menu>
</div>
