import { NgModule } from '@angular/core'
import { FloDebugMenuComponent } from './debug-menu/debug-menu.component'
import { FloDebugService } from './services/debug.service'
import { CommonModule } from '@angular/common'
import { FloSelectInputModule } from '../select-input/select-input.module'
import { FloExperimentationModule } from '../experimentation/experimentation.module'

@NgModule({
  imports: [CommonModule, FloSelectInputModule, FloExperimentationModule],
  exports: [FloDebugMenuComponent, FloExperimentationModule],
  declarations: [FloDebugMenuComponent],
  providers: [FloDebugService]
})
export class FloDebugModule {}
