import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { DecoratedTextCellComponent } from './decorated-text-cell.component'
import { RouterModule } from '@angular/router'
import { SvgModule } from '../../shared/svg/svg.module'
import { ImageModule } from '../image/image.component.module'
import { FloTypeGuardModule } from '../../shared/pipes/flo-type-guard-pipe.module'
import { AnalyticsModule } from '../../shared/analytics/analytics.module'
import { CustomLinkModule } from '../custom-link/custom-link.directive.module'

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CustomLinkModule,
    SvgModule,
    FloTypeGuardModule,
    ImageModule,
    AnalyticsModule,
    FloTypeGuardModule
  ],
  declarations: [DecoratedTextCellComponent],
  exports: [DecoratedTextCellComponent]
})
export class DecoratedTextCellModule {}
