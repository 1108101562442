import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core'
import { CardModel, SectionModel } from '@flocasts/experience-service-types'
import { isTypeCard } from 'src/app/shared/functions/type-guards'
import { SmallContentCardComponentProps } from '../../libs/content-card/small-content-card/small-content-card.component'
import { trackById } from '../../shared/utility-functions/track-by-id.utility'

@Component({
  selector: 'flo-collection-content-card',
  templateUrl: './collection-content-card.component.html',
  styleUrls: ['./collection-content-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollectionContentCardComponent implements OnInit {
  public items: ReadonlyArray<SmallContentCardComponentProps>
  @Input() section: SectionModel<CardModel>

  ngOnInit() {
    this.items = this.section.items.filter(isTypeCard)
  }

  public trackById = trackById
}
