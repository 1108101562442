import { Pipe, PipeTransform } from '@angular/core'
import { NodeDetailPartsModel } from '@flocasts/experience-service-types'
import { getSubtitleWithRelativeDate } from '../utility-functions/string.utility'

@Pipe({ name: 'floRelativeDate' })
export class FloRelativeDatePipe implements PipeTransform {
  public transform(details: NodeDetailPartsModel): string {
    return getSubtitleWithRelativeDate(details.displayDate, details.isPremium) // TODO: Can add user.isPremium
  }
}
