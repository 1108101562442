import { HttpClient, HttpParams } from '@angular/common/http'
import { UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms'
import { Injectable } from '@angular/core'
import { catchError, debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators'
import { Observable, of } from 'rxjs'
import { EmailPaymentsResponse } from '../../funnel/pay/payments.interfaces'
import { EXPERIENCE_API_EMAIL_VALIDATION_PATH, EXPERIENCE_API_VERSION } from '../../app.config'

@Injectable()
export class EmailPaymentsValidator {
  public static readonly DEBOUNCE_TIME_SECONDS = 1000

  constructor(private readonly http: HttpClient) {}

  public validateEmail(): ValidatorFn {
    return (control: UntypedFormControl): Observable<ValidationErrors | null> => {
      if (control && control.value) {
        const params = new HttpParams({
          fromObject: {
            version: EXPERIENCE_API_VERSION,
            email: control.value
          }
        })
        const errorMessage = 'Invalid email address, please try again'
        return of(control.value).pipe(
          debounceTime(EmailPaymentsValidator.DEBOUNCE_TIME_SECONDS),
          distinctUntilChanged(),
          switchMap(() => {
            return this.http.get<EmailPaymentsResponse>(EXPERIENCE_API_EMAIL_VALIDATION_PATH, { params })
          }),
          map(response => (response && response.reason ? { invalid_email: { customText: errorMessage } } : null)),
          catchError(() => {
            return of({
              invalid_email: {
                customText: errorMessage
              }
            })
          })
        )
      }
      return of(null)
    }
  }
}
