import { AdBlockService } from '@flosportsinc/ng-ad-block'
import { ChangeDetectionStrategy, Component, Inject, OnInit, Renderer2, ViewEncapsulation } from '@angular/core'
import { PlatformService } from './singleton-services/platform.service'
import { DynamicStyleService } from './shared/services/dynamic-style.service'
import { VerticalService } from './singleton-services/vertical.service'
import { SegmentService } from './shared/analytics/services/segment.service'
import { SegmentTagMap } from './shared/analytics/models/segment-tag-map.model'
import { FooterService } from './shared/footer/footer.service'
import { ViewModeService } from './singleton-services/view-mode.service'
import { ThemeService } from './singleton-services/theme.service'
import { InjectionService } from './singleton-services/injection.service'
import { MinifierService } from './shared/services/css-minifier.service'
import { NavigationEnd, NavigationStart, Router, ActivatedRoute } from '@angular/router'
import { SegmentEvents } from './shared/analytics/models/segment-events.model'
import { footerAnimation, routeAnimation } from './shared/animations/route-animations'
import { debounceTime, delay, filter, map, share, shareReplay, startWith, switchMap, take, tap } from 'rxjs/operators'
import { combineLatest, Observable, Scheduler, merge, BehaviorSubject, of, distinctUntilChanged } from 'rxjs'
import { INode } from './shared/models/node.model'
import { RXJS_SCHEDULER } from './app.config'
import { RouterService } from './singleton-services/router.service'
import { AD_PAGE_VIEW, AdPageViewService } from './shared/ads/advertising-service/ad-page-view.service'
import { SkeletonTheme } from './libs/skeletons/skeleton/skeleton.interface'
import { getSkeletonThemeFromUrl } from './libs/skeletons/skeleton/skeleton.utility'
import { notNullOrUndefined } from './shared/functions/type-guards'
import { ExternalRoutingService } from './shared/services/external-routing.service'
import { EventTickerDataService } from './libs/event-ticker/event-ticker-data.service'
import { SiteIds } from './shared/models/site.model'
import { WINDOW } from './app.injections'
import { ReCaptchaV3Service } from '@flocasts/ng-recaptcha'
import {
  EXP_EXPANDED_SUB_PROMO_BANNER,
  EXP_EXPANDED_SUB_PROMO_BANNER_VARIATION,
  EXP_SUB_PROMO_BANNER,
  EXP_SUB_PROMO_BANNER_TEST_3,
  EXP_SUB_PROMO_BANNER_TEST_3_VARIATION,
  EXP_SUB_PROMO_BANNER_VARIATION,
  FEAT_EVENT_TICKER_ID,
  FEAT_EVENT_TICKER_VARIATION,
  EXP_FLOCOLLEGE_PROD_ID,
  EXP_FLOCOLLEGE_PROD_VARIATION
} from './shared/experimentation/experiment.model'
import { ExperimentationService } from './shared/experimentation/experimentation.service'
import { DOCUMENT } from '@angular/common'
import { CookieService } from './singleton-services/cookie.service'
import { SPECIAL_PRICING_COOKIE } from './shared/services/product.service'
import { SiteSettings } from './shared/models/site-settings.model'
import { EventTickerViewModel } from '@flocasts/experience-service-types'
import { AuthService } from './singleton-services/auth/auth.service'
import { FavoriteService } from './singleton-services/user-preferences/favorite.service'
import { ImpactService } from './shared/services/impact.service'

interface RouteData {
  shouldShowEventTicker?: boolean
  shouldShowAdSkin?: boolean
  hideFooter?: boolean
  noTopScroll?: boolean
  hideHeader?: boolean
  segment?: {
    category: string
    name: string
    /**
     * When false, prevents segment page calls from automatically being made from
     * app.component. New page calls and refactored page calls should be made
     * using pageV2.
     *
     * @see ArticleAnalyticsService V2 Analytics Example
     * @see ArticlesRoutingModule Route Data Example
     * @see SegmentService#pageV2
     */
    autoPageCall: false | undefined
  }
  node?: {
    data: INode
  }
}

function onlyNavigationEndEvents(event: any) {
  return event instanceof NavigationEnd
}

function isTrue(prop: boolean) {
  return prop
}

function filterForNoTopScrollInBrowserEnvironment(ps: PlatformService) {
  return (rd: RouteData) => !rd.noTopScroll && ps.isBrowser
}

function scrollTop(window: Window) {
  return () => window.scroll(0, 0)
}

function toSegmentNodeData(rd: RouteData) {
  const node = rd && rd.node && rd.node.data
  return node
}

function outExcludedRoutes(excludedRoutes: ReadonlyArray<string>) {
  return (data: RouteData) => {
    const name = (data.segment && (data.segment.name as string)) || undefined
    return data.segment?.autoPageCall !== false && !excludedRoutes.some(excludedRouteName => excludedRouteName === name)
  }
}

@Component({
  selector: 'flo-root',
  templateUrl: './app.component.html',
  animations: [routeAnimation, footerAnimation],
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  constructor(
    public fs: FooterService,
    private segmentService: SegmentService,
    private adblockService: AdBlockService,
    private verticalService: VerticalService,
    private readonly routerService: RouterService,
    private readonly eventTickerService: EventTickerDataService,
    private router: Router,
    private platformService: PlatformService,
    private renderer: Renderer2,
    private stylesService: DynamicStyleService,
    private viewModeService: ViewModeService,
    private readonly themeService: ThemeService,
    private injectionService: InjectionService,
    private minifier: MinifierService,
    private externalRoutingService: ExternalRoutingService,
    private readonly recaptchaV3Service: ReCaptchaV3Service,
    private readonly experimentationService: ExperimentationService,
    private readonly ar: ActivatedRoute,
    private readonly cookieService: CookieService,
    private readonly authService: AuthService,
    private readonly favoriteService: FavoriteService,
    private readonly impactService: ImpactService,
    @Inject(AD_PAGE_VIEW) private readonly adPageView: AdPageViewService,
    @Inject(RXJS_SCHEDULER) private scheduler: Scheduler,
    @Inject(WINDOW) private readonly window: Window,
    @Inject(DOCUMENT) private doc: Document
  ) {
    this.externalRoutingService.checkExternalSiteNeedsRedirect$.subscribe()

    this.segmentService.beginUserTracking()
    this.segmentService.supplementalData$.pipe(take(1)).subscribe()

    /**
     * This is to prevent legacy pages that use routing-module from firing segment page calls
     * These pages will need to call segment.pageV2 within the component
     */
    const segmentExcludedRouteNames: ReadonlyArray<string> = [
      SegmentTagMap.ARTICLE_DETAIL.name,
      SegmentTagMap.ATHLETE_DETAIL.name,
      SegmentTagMap.COLLECTIONS_TAB_VIEW.name,
      SegmentTagMap.COLLECTIONS_VIEW.name,
      SegmentTagMap.EVENT_DETAIL.name,
      SegmentTagMap.FLOFILMS.name,
      SegmentTagMap.HOME.name,
      SegmentTagMap.PAY.name,
      SegmentTagMap.PLANS.name,
      SegmentTagMap.THANK_YOU.name,
      SegmentTagMap.TRAINING_HOME.name,
      SegmentTagMap.TRAINING_VIDEO.name
    ]

    /**
     * See RouteData for details on preventing automatic page calls.
     *
     * @see RouteData.segment.autoPageCall Prevent Automatic Page Calls
     */
    this.routeData$
      .pipe(
        debounceTime(200, this.scheduler),
        filter(outExcludedRoutes(segmentExcludedRouteNames)),
        map(toSegmentNodeData)
      )
      .subscribe(node => {
        this.segmentService.page(undefined, undefined, undefined, node)
      })

    // keeps app scrolled to top unless noTopScroll is true on the route
    this.routeData$
      .pipe(
        filter(filterForNoTopScrollInBrowserEnvironment(this.platformService)),
        // Adding delay to fix scroll behavior on safari. https://stackoverflow.com/a/75763546
        delay(10)
      )
      .subscribe(scrollTop(this.window))
  }

  /**
   * When the router outlet changes components, update the advertising page view.
   *
   * @see AdPageViewService
   */
  public changeCorrelator(): void {
    this.adPageView.change()
  }

  /**
   * Feature flag to conceal FloCollege Production site until we are ready to launch.
   * If in variation, show an iframe display flosports.tv instead. Control will see site as expected.
   */
  public hideFloCollegeProd$ = this.experimentationService
    .isInVariation(EXP_FLOCOLLEGE_PROD_ID, EXP_FLOCOLLEGE_PROD_VARIATION, false)
    .pipe(
      map(isInCollegeVariation => {
        return isInCollegeVariation ? true : null
      })
    )

  public isMinimal$ = this.viewModeService.isMinimal$.pipe(
    tap(isMinimal => {
      if (isMinimal) {
        this.renderer.addClass(this.doc.body, 'view-mode-minimal')
      }
    })
  )

  public isDarkTheme$ = this.themeService.isDarkTheme$.pipe(
    tap((isDarkTheme: boolean) => {
      // Applies dark background color to the body when in dark theme for mobile webviews only
      if (isDarkTheme && this.platformService.isBrowser) {
        this.renderer.addClass(this.doc.body, 'bg-grey-900')
      }
    })
  )

  public skeletonTheme$: Observable<SkeletonTheme | null> = this.router.events.pipe(
    filter(event => event instanceof NavigationStart),
    map((event: NavigationStart) => event.url),
    map(getSkeletonThemeFromUrl),
    shareReplay(1)
  )

  private navigationEndEvents$ = this.router.events.pipe(filter<NavigationEnd>(onlyNavigationEndEvents), share())
  private routeData$ = this.routerService.route$.pipe(
    map(route => route.data as RouteData),
    shareReplay(1)
  )
  public showSkeletons$ = merge(
    this.skeletonTheme$.pipe(map(notNullOrUndefined)),
    this.navigationEndEvents$.pipe(map(() => false))
  )

  public readonly navId$ = this.routerService.queryParamMap$.pipe(map(queryParamMap => queryParamMap.get('navId')))

  public readonly shouldShowAdSkin$ = this.routeData$.pipe(map(routeData => routeData.shouldShowAdSkin))

  public isHomePage$ = this.routeData$.pipe(map(routeData => routeData?.segment?.category === 'Home'))
  public isInWrestlingPromoBannerVariation$ = this.experimentationService.isInVariation(
    EXP_SUB_PROMO_BANNER,
    EXP_SUB_PROMO_BANNER_VARIATION,
    false
  )
  public isInExpandedPromoBannerVariation$ = this.experimentationService.isInVariation(
    EXP_EXPANDED_SUB_PROMO_BANNER,
    EXP_EXPANDED_SUB_PROMO_BANNER_VARIATION,
    false
  )
  public isInPromoBannerTest3Variation$ = this.experimentationService.isInVariation(
    EXP_SUB_PROMO_BANNER_TEST_3,
    EXP_SUB_PROMO_BANNER_TEST_3_VARIATION,
    false
  )

  public showFooter$: Observable<boolean> = this.routeData$.pipe(
    map(data => !data.hideFooter && !this.viewModeService.isMobileWebViewMode)
  )

  public showHeader$: Observable<boolean> = this.routeData$.pipe(
    map(routeData => !routeData.hideHeader && !this.viewModeService.isMobileWebViewMode)
  )

  public isDropdownExpanded$ = new BehaviorSubject<boolean>(false)

  public shouldShowPromoBanner$ = combineLatest([
    this.isHomePage$,
    this.isInWrestlingPromoBannerVariation$,
    this.isInExpandedPromoBannerVariation$,
    this.isInPromoBannerTest3Variation$,
    this.isDropdownExpanded$
  ]).pipe(
    // We only want to show this on the home page and if in the variation.
    // Also need to account for the mobile dropdown being open.
    map(
      ([
        isHome,
        shouldShowWrestlingPromoBanner,
        shouldShowExpandedPromoBanner,
        shouldShowPromoBannerTest3,
        isDropdownExpanded
      ]) => {
        if (isHome && !isDropdownExpanded) {
          this.experimentationService.activate(EXP_SUB_PROMO_BANNER)
          this.experimentationService.activate(EXP_EXPANDED_SUB_PROMO_BANNER)
          this.experimentationService.activate(EXP_SUB_PROMO_BANNER_TEST_3)
        }
        return (
          (shouldShowWrestlingPromoBanner || shouldShowExpandedPromoBanner || shouldShowPromoBannerTest3) &&
          isHome &&
          !isDropdownExpanded
        )
      }
    ),
    shareReplay(1)
  )

  public handleDropdownExpanded(value: boolean) {
    this.isDropdownExpanded$.next(value)
  }

  public readonly siteSettings$ = this.verticalService.siteSettings$
  public readonly isVarsity$ = this.siteSettings$.pipe(map(siteSetting => siteSetting.site_id === SiteIds.VARSITY))

  /**
   * Stream of Event Ticker data.
   *
   * We use combineLatest to dynamically update the event ticker when the user logs in or out,
   * and also when a user updates their favorites.
   */
  public readonly eventTickerVm$: Observable<EventTickerViewModel | undefined> = combineLatest([
    this.authService.loggedIn$.pipe(startWith(false)),
    this.favoriteService.hasUpdatedFavorites$.pipe(startWith(false)),
    this.navId$
  ]).pipe(
    distinctUntilChanged(),
    switchMap(([_loggedin, _hasUpdatedFavorites, _navId]) => this.eventTickerService.getEventTicker()),
    filter(notNullOrUndefined),
    shareReplay(1)
  )

  private readonly eventTickerItems$ = this.eventTickerVm$.pipe(
    map(vm => vm?.sections),
    filter(notNullOrUndefined),
    map(sections => sections[0]),
    filter(notNullOrUndefined)
  )
  /**
   * Stream used to determine whether to show/hide Event Ticker. (If zero items or bad data, hide it.)
   */
  private readonly eventTickerHasItems$: Observable<boolean> = this.eventTickerItems$.pipe(
    map(section => section.items.length > 0)
  )

  // On the new nav experience, always show event ticker if that route has shouldShow = true and there's events
  public readonly shouldShowEventTicker$ = combineLatest([
    this.routeData$,
    this.experimentationService.isInVariation(FEAT_EVENT_TICKER_ID, FEAT_EVENT_TICKER_VARIATION)
  ]).pipe(
    switchMap(([routeData, isInVariation]) => {
      if (routeData.shouldShowEventTicker && isInVariation) {
        return this.eventTickerHasItems$
      }

      return of(false)
    }),
    shareReplay(1)
  )

  ngOnInit() {
    // change dynamic site-wide elements based on vertical settings
    this.siteSettings$.pipe(take(1)).subscribe(settings => this.injectVerticalStyles(settings))

    // Report Ad Blocked once userIdentity
    this.adblockService
      .isAnAdBlockerActive()
      .pipe(filter(isTrue), take(1))
      .subscribe(() => {
        this.segmentService.serverTrack(SegmentEvents.AD_BLOCKED)
      })

    // Trigger Recaptcha V3 to load and capture user interactions
    this.recaptchaV3Service.onExecute.pipe(take(1)).subscribe()

    // Fix issue where special pricing query param is removed when users come from https://go.flosports.tv/partner/caa
    this.ar.queryParams.subscribe(params => {
      if (params && params.sp === 'conf-partner') {
        // Save the special pricing on cookies instead
        this.cookieService.set(SPECIAL_PRICING_COOKIE, 'conf-partner', {
          expires: 1 // Valid only for 24 hours
        })
      }
    })

    // Identify users upon initial load
    if (this.platformService.isBrowser) {
      this.impactService.identify()
      this.impactService.generateClickId()
    }
  }

  private injectVerticalStyles(settings: SiteSettings): void {
    // TODO: remove dynamic styles service. Only used for trackwrestling funnel now
    const siteColorOverride = this.stylesService.primaryColor(settings.primary_color || '')
    const appliedStyles = `${siteColorOverride} ${settings.site_styles ? settings.site_styles : ''}`
    const finalStyles = this.minifier.css(appliedStyles)
    this.injectionService.injectInlineStylesheets(this.renderer, [finalStyles], true).pipe(take(1)).subscribe()
  }
}
