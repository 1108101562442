import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core'
import { ExpandableTextModel } from '@flocasts/experience-service-types'

@Component({
  selector: 'flo-expandable-text',
  templateUrl: './expandable-text.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpandableTextComponent implements OnChanges {
  @Input() expandableText: ExpandableTextModel

  expandText = 'Show More'
  shouldShowReadMore = true

  ngOnChanges() {
    if (this.expandableText?.shouldShowReadMore) {
      this.shouldShowReadMore = this.expandableText.shouldShowReadMore
    }
  }

  toggleExpand(): void {
    this.shouldShowReadMore = !this.shouldShowReadMore
    this.expandText = this.shouldShowReadMore ? 'Show More' : 'Show Less'
  }
}
