import { Injectable, Inject } from '@angular/core'
import { SportDataSummary } from './types'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { map, shareReplay, distinctUntilChanged, catchError, startWith, tap } from 'rxjs/operators'
import { LOGGER_SERVICE } from 'src/app/logger/logger.config'
import { LoggerService } from 'src/app/logger/logger.interface'
import { SportDataService } from './sport-data.interface'
import { getQuery1, getQuery2, SPORT_DATA_FIRESTORE_TOKEN, STATUS_COMPLETED, STATUS_STARTED } from './sport-data.config'
import { TransferState } from '@angular/platform-browser'
import { makeCacheKeyForFirestorePost, TransferHttpResponse } from '../services/http-transfer-state.utility'
import { EnvironmentService } from 'src/app/singleton-services/environment.service'
import { compareDesc } from 'date-fns'
import { Firestore } from '@angular/fire/firestore'
import { collection, where, query, limit, orderBy } from 'firebase/firestore'
import { collectionData } from 'rxfire/firestore'
import { DocumentData } from 'rxfire/firestore/interfaces'

@Injectable()
export class BrowserSportDataService implements SportDataService {
  constructor(
    @Inject(SPORT_DATA_FIRESTORE_TOKEN)
    private readonly firestore: Firestore,
    @Inject(LOGGER_SERVICE) private readonly logger: LoggerService,
    private readonly ts: TransferState,
    private readonly env: EnvironmentService
  ) {}

  /**
   *
   * @param liveEventId Live Event Id
   * @param liveStreamId Live Stream Id
   */
  public getMostRecentSportDataSummaryById = (
    liveEventId: number,
    liveStreamId: number
  ): Observable<SportDataSummary | undefined> =>
    this.getSportDataSummaryById(liveEventId, liveStreamId).pipe(map(sportData => sportData && sportData[0]))

  /**
   * @param liveEventId Live Event Id
   * @return true if there is data, else false if data is empty or undefined
   */
  public liveEventHasSportData = (liveEventId: number): Observable<boolean> => {
    return this.getSportDataSnapshotByLiveEventId(liveEventId).pipe(
      map(data => {
        if (!data) return false
        if ((Array.isArray(data) && (data as DocumentData[]).length > 0) || Object.keys(data).length > 0) {
          return true
        }
        return false
      }),
      shareReplay(1)
    )
  }
  private readonly readFromCacheSource = new BehaviorSubject(true)
  private readonly turnOffCache = () => this.readFromCacheSource.next(false)
  /**
   * This is a one time http request to limit firestore usage.
   * @param liveEventId Live Event Id
   */
  private getSportDataSnapshotByLiveEventId = (
    liveEventId: number
  ): Observable<DocumentData[] | undefined | { docs: string }> => {
    const tsUrl = `${this.env.config.firestoreApiHost}${this.env.config.sportDatabase.projectId}/databases/(default)/documents:runQuery`
    const storeKey = makeCacheKeyForFirestorePost(tsUrl, getQuery1(liveEventId))
    const cacheValue = this.ts.get(storeKey, {} as TransferHttpResponse).body

    const ref = query(
      collection(this.firestore, 'summary'),
      where('statusCode', '>=', STATUS_STARTED),
      where('statusCode', '<', STATUS_COMPLETED),
      where('identity.liveEventId', '==', liveEventId),
      limit(1)
    )
    const data$ = collectionData(ref).pipe(
      shareReplay(1),
      distinctUntilChanged(),
      catchError(err => {
        this.logger.error('Browser Sport Data Error', err)
        return of(undefined)
      })
    )

    return this.readFromCacheSource.getValue()
      ? data$.pipe(
          tap(() => this.turnOffCache()),
          startWith(cacheValue)
        )
      : data$
  }

  /**
   * Reads from sport data firestore real time.
   * This is an observable that emits everytime scorecard is updated
   * so we are careful to only call it when necessary to preserve firebase usage.
   * @param liveEventId Live Event Id
   * @param liveStreamId Live Stream Id
   */
  private getSportDataSummaryById = (
    liveEventId: number,
    liveStreamId: number
  ): Observable<SportDataSummary[] | undefined> => {
    const tsUrl = `${this.env.config.firestoreApiHost}${this.env.config.sportDatabase.projectId}/databases/(default)/documents:runQuery`
    const storeKey = makeCacheKeyForFirestorePost(tsUrl, getQuery2(liveEventId, liveStreamId))
    const cacheValue = this.ts.get(storeKey, {} as TransferHttpResponse).body
    const ref = query(
      collection(this.firestore, 'summary'),
      where('statusCode', '>=', STATUS_STARTED),
      where('statusCode', '<', STATUS_COMPLETED),
      where('identity.liveEventId', '==', liveEventId),
      where('identity.liveStreamId', '==', liveStreamId),
      orderBy('statusCode', 'asc'),
      orderBy('lastUpdated', 'desc'),
      limit(5)
    )

    return collectionData(ref).pipe(
      // Start this stream with transfer state value to avoid app flash
      startWith(cacheValue ? [cacheValue] : []),
      distinctUntilChanged(),
      map(docs =>
        docs?.sort((a: SportDataSummary, b: SportDataSummary) => {
          return compareDesc(a.lastUpdated, b.lastUpdated)
        })
      ),
      catchError(err => {
        this.logger.error('Browser Sport Data Error', err)
        return of(undefined)
      }),
      shareReplay(1)
    )
  }
}
