import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TableComponent } from './table.component'
import { AnalyticsModule } from 'src/app/shared/analytics/analytics.module'
import { SvgModule } from 'src/app/shared/svg/svg.module'
import { CustomLinkModule } from '../custom-link/custom-link.directive.module'
import { EventStatusModule } from '../events/components/event-status/event-status.module'
import { FloCssClassSanitizePipeModule } from '../_pipes/css-class-sanitize/css-class-sanitize.pipe.module'
import { RouterModule } from '@angular/router'
import { SectionTableComponent } from './section-table.component'
import { FloTypeGuardModule } from 'src/app/shared/pipes/flo-type-guard-pipe.module'

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CustomLinkModule,
    EventStatusModule,
    AnalyticsModule,
    FloCssClassSanitizePipeModule,
    SvgModule,
    FloTypeGuardModule
  ],
  declarations: [SectionTableComponent, TableComponent],
  exports: [SectionTableComponent, TableComponent]
})
export class TableModule {}
