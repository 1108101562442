import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'flo-chevron',
  styleUrls: ['./chevron.component.scss'],
  templateUrl: './chevron.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChevronComponent {
  @Input()
  readonly headerText: string
  @Input()
  readonly direction = 'down' // for future use, if we want other directions
  @Input()
  readonly analyticsProps = {}
  @Output()
  readonly chevronClicked = new EventEmitter<void>()

  public emitClick() {
    this.chevronClicked.next()
  }
}
