import { ChangeDetectionStrategy, Component, Input, TrackByFunction } from '@angular/core'
import { AdBlockService } from '@flosportsinc/ng-ad-block'
import { VerticalService } from '../../singleton-services/vertical.service'
import { map } from 'rxjs/operators'
import { StringDictionary } from '../models/generics'
import { OsanoService } from '../services/osano.service'
import { SiteSettings } from '../models/site-settings.model'

@Component({
  selector: 'flo-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {
  @Input() public links: ReadonlyArray<any> = []
  public adBlockerActive$ = this.adBlockService.isAnAdBlockerActive()
  public analyticsProperties = { component: 'FooterComponent' }

  constructor(
    private readonly adBlockService: AdBlockService,
    private readonly verticalService: VerticalService,
    private readonly osanoService: OsanoService
  ) {}

  trackByLink: TrackByFunction<any> = (index: number, item: any) => {
    return item.name
  }

  public socialLinks$ = this.verticalService.siteSettings$.pipe(
    map(settings => {
      return Object.keys(this.socialSvgMapping).map(key => {
        if (key in settings) {
          return {
            name: this.socialNameMapping[key],
            // we know that 'key' is in settings because of the if statement above
            // @ts-expect-error
            href: settings[key],
            svg: this.socialSvgMapping[key]
          }
        } else return
      })
    })
  )

  openOsanoDrawer(event: Event): void {
    event.preventDefault()

    this.osanoService.openOsano()
  }

  get socialSvgMapping(): StringDictionary {
    return {
      facebook_page_url: 'icon-social-facebook',
      twitter_page_url: 'icon-social-twitter',
      instagram_page_url: 'icon-social-instagram',
      youtube_page_url: 'icon-social-youtube'
    }
  }

  get socialNameMapping(): StringDictionary {
    return {
      facebook_page_url: 'Facebook',
      twitter_page_url: 'Twitter',
      instagram_page_url: 'Instagram',
      youtube_page_url: 'YouTube'
    }
  }

  trackBySocialLink: TrackByFunction<any> = (index: number, item: any) => {
    return item.href
  }
}
