import { Pipe, PipeTransform } from '@angular/core'
import { IconModel, ImageModel, LinkModel, TextModel } from '@flocasts/experience-service-types'
import { assetIsIconModel, assetIsImageModel, isLinkModel, isTextModel } from 'src/app/shared/functions/type-guards'

@Pipe({ name: 'floIsIcon' })
export class FloIsIconPipe implements PipeTransform {
  public transform(asset: ImageModel | IconModel | null | undefined): asset is IconModel {
    return assetIsIconModel(asset)
  }
}

@Pipe({ name: 'floIsImage' })
export class FloIsImagePipe implements PipeTransform {
  public transform(asset: ImageModel | IconModel | null | undefined): asset is ImageModel {
    return assetIsImageModel(asset)
  }
}

@Pipe({ name: 'floIsLink' })
export class FloIsLinkPipe implements PipeTransform {
  public transform(model: TextModel | LinkModel | null): model is LinkModel {
    return !!model && isLinkModel(model)
  }
}

@Pipe({ name: 'floIsText' })
export class FloIsTextPipe implements PipeTransform {
  public transform(model: TextModel | LinkModel | null): model is TextModel {
    return !!model && isTextModel(model)
  }
}
