import { REQUEST } from '@nguniversal/express-engine/tokens'
import { PlatformService } from './platform.service'
import { Inject, Injectable } from '@angular/core'

const MINIMUM_SUPPORTED_IOS_VERSION = 13
export interface IUserAgentService {
  userAgent: IUserAgent
}

export interface IUserAgent {
  browser?: string
  device?: string
  iosVersion?: number | undefined
  platform?: Platform
  isMobile?: boolean
  isTablet?: boolean
  isDesktop?: boolean
  source?: string
}

@Injectable({
  providedIn: 'root'
})
export class UserAgentService implements IUserAgentService {
  constructor(private platformService: PlatformService, @Inject(REQUEST) private req: any) {}

  private isSupportedIosVersion = (iosVersion: number | undefined) => {
    return !!iosVersion && iosVersion >= MINIMUM_SUPPORTED_IOS_VERSION
  }

  public get deviceIsAndroidOrSupportedIPhone(): boolean {
    const isIPhone = this.userAgent.device === DEVICES.IPHONE
    const isAndroid = this.userAgent.device === DEVICES.ANDROID
    return isAndroid || (isIPhone && this.isSupportedIosVersion(this.userAgent.iosVersion))
  }

  public get deviceIsAndroidOrSupportedIos(): boolean {
    const isIPhoneOrIPad = this.userAgent.device === DEVICES.IPHONE || this.userAgent.device === DEVICES.I_PAD
    const isAndroid = this.userAgent.device === DEVICES.ANDROID
    return isAndroid || (isIPhoneOrIPad && this.isSupportedIosVersion(this.userAgent.iosVersion))
  }

  public get userAgent(): IUserAgent {
    if (this.platformService.isServer) {
      if (!this.req || !this.req.useragent) {
        return {}
      }
      return {
        browser: this.req.useragent.browser,
        isMobile: this.req.useragent.isMobile,
        isTablet: this.req.useragent.isTablet,
        isDesktop: this.req.useragent.isDesktop,
        source: this.req.useragent.source
      }
    } else {
      const ua = window.navigator.userAgent
      const appVersion = window.navigator.appVersion
      const reTree = new ReTree()

      /**
       * TODO: Combine device and browser
       * lines 26-48 here: https://github.com/KoderLabs/ng2-device-detector/blob/master/src/ng2-device.service.ts
       */
      let device = Object.keys(DEVICES).reduce((obj: any, item: any) => {
        obj[DEVICES[item]] = reTree.test(ua, DEVICES_RE[item])
        return obj
      }, {})

      let browser = Object.keys(BROWSERS).reduce((obj: any, item: any) => {
        obj[BROWSERS[item]] = reTree.test(ua, BROWSERS_RE[item])
        return obj
      }, {})

      device = [
        DEVICES.ANDROID,
        DEVICES.I_PAD,
        DEVICES.IPHONE,
        DEVICES.I_POD,
        DEVICES.BLACKBERRY,
        DEVICES.FIREFOX_OS,
        DEVICES.CHROME_BOOK,
        DEVICES.WINDOWS_PHONE,
        DEVICES.PS4,
        DEVICES.CHROMECAST,
        DEVICES.APPLE_TV,
        DEVICES.GOOGLE_TV,
        DEVICES.VITA
      ].reduce((previousValue, currentValue) => {
        return previousValue === DEVICES.UNKNOWN && device[currentValue] ? currentValue : previousValue
      }, DEVICES.UNKNOWN)

      browser = [
        BROWSERS.CHROME,
        BROWSERS.FB_MESSENGER,
        BROWSERS.FIREFOX,
        BROWSERS.IE,
        BROWSERS.MS_EDGE,
        BROWSERS.OPERA,
        BROWSERS.SAFARI,
        BROWSERS.UNKNOWN
      ].reduce((previousValue, currentValue) => {
        return previousValue === BROWSERS.UNKNOWN && browser[currentValue] ? currentValue : previousValue
      }, BROWSERS.UNKNOWN)

      const isIPhoneOrIPad = device === DEVICES.IPHONE || device === DEVICES.I_PAD
      const iosVersion = (isIPhoneOrIPad && getIosVersion(appVersion)) || undefined

      return {
        browser,
        device,
        iosVersion,
        source: ua,
        platform: getPlatformFromDevice(device),
        isMobile: deviceIsMobile(device),
        isTablet: deviceIsTablet(device),
        isDesktop: deviceIsDesktop(device)
      }
    }
  }
}

/**
 * Inspired by ahsanayaz on 08/11/2016.
 */

export const BROWSERS: any = {
  CHROME: 'Chrome',
  FIREFOX: 'Firefox',
  SAFARI: 'Safari',
  OPERA: 'Opera',
  IE: 'IE',
  MS_EDGE: 'MS-Edge',
  FB_MESSENGER: 'FB-Messenger',
  UNKNOWN: 'Unknown'
}

export const DEVICES: any = {
  ANDROID: 'Android',
  I_PAD: 'iPad',
  IPHONE: 'iPhone',
  I_POD: 'iPod',
  BLACKBERRY: 'Blackberry',
  FIREFOX_OS: 'Firefox-OS',
  CHROME_BOOK: 'Chrome-Book',
  WINDOWS_PHONE: 'Windows-Phone',
  PS4: 'PS4',
  VITA: 'Vita',
  CHROMECAST: 'Chromecast',
  APPLE_TV: 'AppleTV',
  GOOGLE_TV: 'GoogleTV',
  UNKNOWN: 'Unknown'
}

export const OS: any = {
  WINDOWS: 'windows',
  MAC: 'mac',
  IOS: 'ios',
  ANDROID: 'android',
  LINUX: 'linux',
  UNIX: 'unix',
  FIREFOX_OS: 'firefox-os',
  CHROME_OS: 'chrome-os',
  WINDOWS_PHONE: 'windows-phone',
  UNKNOWN: 'unknown'
}

export const OS_VERSIONS: any = {
  WINDOWS_3_11: 'windows-3-11',
  WINDOWS_95: 'windows-95',
  WINDOWS_ME: 'windows-me',
  WINDOWS_98: 'windows-98',
  WINDOWS_CE: 'windows-ce',
  WINDOWS_2000: 'windows-2000',
  WINDOWS_XP: 'windows-xp',
  WINDOWS_SERVER_2003: 'windows-server-2003',
  WINDOWS_VISTA: 'windows-vista',
  WINDOWS_7: 'windows-7',
  WINDOWS_8_1: 'windows-8-1',
  WINDOWS_8: 'windows-8',
  WINDOWS_10: 'windows-10',
  WINDOWS_PHONE_7_5: 'windows-phone-7-5',
  WINDOWS_PHONE_8_1: 'windows-phone-8-1',
  WINDOWS_PHONE_10: 'windows-phone-10',
  WINDOWS_NT_4_0: 'windows-nt-4-0',
  MACOSX_15: 'mac-os-x-15',
  MACOSX_14: 'mac-os-x-14',
  MACOSX_13: 'mac-os-x-13',
  MACOSX_12: 'mac-os-x-12',
  MACOSX_11: 'mac-os-x-11',
  MACOSX_10: 'mac-os-x-10',
  MACOSX_9: 'mac-os-x-9',
  MACOSX_8: 'mac-os-x-8',
  MACOSX_7: 'mac-os-x-7',
  MACOSX_6: 'mac-os-x-6',
  MACOSX_5: 'mac-os-x-5',
  MACOSX_4: 'mac-os-x-4',
  MACOSX_3: 'mac-os-x-3',
  MACOSX_2: 'mac-os-x-2',
  MACOSX: 'mac-os-x',
  UNKNOWN: 'unknown'
}

export const OS_RE: any = {
  WINDOWS: {
    and: [{ or: [/\bWindows|(Win\d\d)\b/, /\bWin 9x\b/] }, { not: /\bWindows Phone\b/ }]
  },
  MAC: { and: [/\bMac OS\b/, { not: /Windows Phone/ }] },
  IOS: {
    and: [{ or: [/\biPad\b/, /\biPhone\b/, /\biPod\b/] }, { not: /Windows Phone/ }]
  },
  ANDROID: { and: [/\bAndroid\b/, { not: /Windows Phone/ }] },
  LINUX: /\bLinux\b/,
  UNIX: /\bUNIX\b/,
  FIREFOX_OS: { and: [/\bFirefox\b/, /Mobile\b/] },
  CHROME_OS: /\bCrOS\b/,
  WINDOWS_PHONE: { or: [/\bIEMobile\b/, /\bWindows Phone\b/] },
  PS4: /\bMozilla\/5.0 \(PlayStation 4\b/,
  VITA: /\bMozilla\/5.0 \(Play(S|s)tation Vita\b/
}

export const BROWSERS_RE: any = {
  CHROME: {
    and: [{ or: [/\bChrome\b/, /\bCriOS\b/] }, { not: { or: [/\bOPR\b/, /\bEdge\b/] } }]
  },
  FIREFOX: /\bFirefox\b/,
  SAFARI: {
    and: [/^((?!CriOS).)*\Safari\b.*$/, { not: { or: [/\bOPR\b/, /\bEdge\b/, /Windows Phone/] } }]
  },
  OPERA: { or: [/Opera\b/, /\bOPR\b/] },
  IE: {
    or: [/\bMSIE\b/, /\bTrident\b/, /^Mozilla\/5\.0 \(Windows NT 10\.0 Win64 x64\)$/]
  },
  MS_EDGE: { or: [/\bEdge\b/] },
  PS4: /\bMozilla\/5.0 \(PlayStation 4\b/,
  VITA: /\bMozilla\/5.0 \(Play(S|s)tation Vita\b/,
  FB_MESSANGER: /\bFBAN\/MessengerForiOS\b/
}

export const DEVICES_RE: any = {
  ANDROID: { and: [/\bAndroid\b/, { not: /Windows Phone/ }] },
  I_PAD: /\biPad\b/,
  IPHONE: { and: [/\biPhone\b/, { not: /Windows Phone/ }] },
  I_POD: /\biPod\b/,
  BLACKBERRY: /\bblackberry\b/,
  FIREFOX_OS: { and: [/\bFirefox\b/, /\bMobile\b/] },
  CHROME_BOOK: /\bCrOS\b/,
  WINDOWS_PHONE: { or: [/\bIEMobile\b/, /\bWindows Phone\b/] },
  PS4: /\bMozilla\/5.0 \(PlayStation 4\b/,
  CHROMECAST: /\bCrKey\b/,
  APPLE_TV: /^iTunes-AppleTV\/4.1$/,
  GOOGLE_TV: /\bGoogleTV\b/,
  VITA: /\bMozilla\/5.0 \(Play(S|s)tation Vita\b/
}

export const OS_VERSIONS_RE: any = {
  WINDOWS_3_11: /Win16/,
  WINDOWS_95: /(Windows 95|Win95|Windows_95)/,
  WINDOWS_ME: /(Win 9x 4.90|Windows ME)/,
  WINDOWS_98: /(Windows 98|Win98)/,
  WINDOWS_CE: /Windows CE/,
  WINDOWS_2000: /(Windows NT 5.0|Windows 2000)/,
  WINDOWS_XP: /(Windows NT 5.1|Windows XP)/,
  WINDOWS_SERVER_2003: /Windows NT 5.2/,
  WINDOWS_VISTA: /Windows NT 6.0/,
  WINDOWS_7: /(Windows 7|Windows NT 6.1)/,
  WINDOWS_8_1: /(Windows 8.1|Windows NT 6.3)/,
  WINDOWS_8: /(Windows 8|Windows NT 6.2)/,
  WINDOWS_10: /(Windows NT 10.0)/,
  WINDOWS_PHONE_7_5: /(Windows Phone OS 7.5)/,
  WINDOWS_PHONE_8_1: /(Windows Phone 8.1)/,
  WINDOWS_PHONE_10: /(Windows Phone 10)/,
  WINDOWS_NT_4_0: {
    and: [/(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/, { not: /Windows NT 10.0/ }]
  },
  MACOSX: /(MAC OS X\s*[^ 0-9])/,
  MACOSX_3: /(Darwin 10.3|Mac OS X 10.3)/,
  MACOSX_4: /(Darwin 10.4|Mac OS X 10.4)/,
  MACOSX_5: /(Mac OS X 10.5)/,
  MACOSX_6: /(Mac OS X 10.6)/,
  MACOSX_7: /(Mac OS X 10.7)/,
  MACOSX_8: /(Mac OS X 10.8)/,
  MACOSX_9: /(Mac OS X 10.9)/,
  MACOSX_10: /(Mac OS X 10.10)/,
  MACOSX_11: /(Mac OS X 10.11)/,
  MACOSX_12: /(Mac OS X 10.12)/,
  MACOSX_13: /(Mac OS X 10.13)/,
  MACOSX_14: /(Mac OS X 10.14)/,
  MACOSX_15: /(Mac OS X 10.15)/
}

export const BROWSER_VERSIONS_RE_MAP: any = {
  CHROME: [/\bChrome\/([\d\.]+)\b/, /\bCriOS\/([\d\.]+)\b/],
  FIREFOX: /\bFirefox\/([\d\.]+)\b/,
  SAFARI: /\bVersion\/([\d\.]+)\b/,
  OPERA: [/\bVersion\/([\d\.]+)\b/, /\bOPR\/([\d\.]+)\b/],
  IE: [/\bMSIE ([\d\.]+\w?)\b/, /\brv:([\d\.]+\w?)\b/],
  MS_EDGE: /\bEdge\/([\d\.]+)\b/
}

export const BROWSER_VERSIONS_RE: any = Object.keys(BROWSER_VERSIONS_RE_MAP).reduce((obj: any, key: any) => {
  obj[BROWSERS[key]] = BROWSER_VERSIONS_RE_MAP[key]
  return obj
}, {})

// tslint:disable:no-parameter-reassignment
export class ReTree {
  public test(str: string, regex: any): any {
    const self = this
    if (typeof regex === 'string') {
      regex = new RegExp(regex)
    }

    if (regex instanceof RegExp) {
      return regex.test(str)
    } else if (regex && Array.isArray(regex.and)) {
      return regex.and.every((item: any) => {
        return self.test(str, item)
      })
    } else if (regex && Array.isArray(regex.or)) {
      return regex.or.some((item: any) => {
        return self.test(str, item)
      })
    } else if (regex && regex.not) {
      return !self.test(str, regex.not)
    } else {
      return false
    }
  }

  public exec(str: string, regex: any): any {
    const self = this
    if (typeof regex === 'string') {
      regex = new RegExp(regex)
    }

    if (regex instanceof RegExp) {
      return regex.exec(str)
    } else if (regex && Array.isArray(regex)) {
      return regex.reduce((res: any, item: any) => {
        return !!res ? res : self.exec(str, item)
      }, undefined)
    } else {
      return undefined
    }
  }
}

export enum Platform {
  DESKTOP = 'desktop',
  TABLET = 'tablet',
  MOBILE = 'mobile',
  UNKNOWN = 'unknown'
}

export function getIosVersion(appVersion: string) {
  const v = appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/)
  return v && parseInt(v[1], 10)
}

export function getPlatformFromDevice(device: string): Platform {
  return deviceIsDesktop(device)
    ? Platform.DESKTOP
    : deviceIsTablet(device)
    ? Platform.TABLET
    : deviceIsMobile(device)
    ? Platform.MOBILE
    : Platform.UNKNOWN
}

export function deviceIsMobile(device: string): boolean {
  return [
    DEVICES.ANDROID,
    DEVICES.IPHONE,
    DEVICES.I_POD,
    DEVICES.BLACKBERRY,
    DEVICES.FIREFOX_OS,
    DEVICES.WINDOWS_PHONE,
    DEVICES.VITA
  ].some(item => device === item)
}

export function deviceIsTablet(device: string): boolean {
  return [DEVICES.I_PAD, DEVICES.FIREFOX_OS].some(item => device === item)
}

export function deviceIsDesktop(device: string): boolean {
  return [DEVICES.PS4, DEVICES.CHROME_BOOK, DEVICES.UNKNOWN].some(item => device === item)
}
