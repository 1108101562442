import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core'
import { Router } from '@angular/router'
import { PaywallContentType, PaywallInputs } from './paywall.interface'

@Component({
  selector: 'flo-paywall',
  templateUrl: './paywall.component.html',
  styleUrls: ['./paywall.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaywallComponent implements PaywallInputs {
  @HostBinding('class.wrapped')
  @Input()
  public wrapped = false

  @HostBinding('class.overlayed')
  @Input()
  public overlayed = false

  @Input()
  public loginRoute = '/login'

  @Input()
  public loggedIn: boolean

  /** When provided, replaces the default prompt text for the paywall. */
  @Input()
  public promptOverride: string

  /** When provided, replaces the text on the primary join button. */
  @Input()
  public joinButtonTextOverride: string

  /** When provided, replaces the text on the login button. */
  @Input()
  public loginButtonTextOverride: string

  /** When provided, customizes the paywall to the content type. */
  @Input()
  public contentType: PaywallContentType = 'content'

  /** Set the URI to redirect to after login or joining */
  @Input()
  public redirect: string

  /** The route the user will be directed to when clicking the join button. */
  @Input()
  public joinRoute = '/signup'

  public defaultMessage = `Unlock live events and more with a subscription!`

  constructor(router: Router) {
    this.redirect = router.url
  }

  public get headerClasses(): Record<string, boolean> {
    return {
      header: true,
      'paywall-header': true,
      'paywall-header--light': this.contentType === 'video',
      'paywall-header--dark': this.contentType !== 'video'
    }
  }
}
