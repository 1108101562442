import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { LocationService } from 'src/app/singleton-services/location.service'

/*
  Add draft query params to requests, if applicable
*/
@Injectable()
export class DraftQueryParamsInterceptor implements HttpInterceptor {
  constructor(private readonly locationService: LocationService) {}
  public intercept(httpRequest: HttpRequest<any>, httpHandler: HttpHandler): Observable<HttpEvent<any>> {
    const href = this.locationService.href
    const url = new URL(href)
    const isVideosUrl = /videos\/\d+/.test(httpRequest.url)
    const isCollectionsUrl = /collections\/\d+/.test(httpRequest.url) || /collections\/tag/.test(httpRequest.url)
    if (isVideosUrl || isCollectionsUrl) {
      const queryParams: Record<string, string> = {}
      url.searchParams.forEach((value, key) => {
        if (['status', 'future'].includes(key) && !!value) {
          queryParams[key] = value
        }
      })
      const params = Object.keys(queryParams).reduce((videoParams, key) => {
        return videoParams.set(key, queryParams[key])
      }, httpRequest.params)
      const clone = httpRequest.clone({ params })
      return httpHandler.handle(clone)
    } else {
      return httpHandler.handle(httpRequest)
    }
  }
}
