import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import type { TwoColumnContainerModel } from '@flocasts/experience-service-types'
import { trackById } from '../../../shared/utility-functions/track-by-id.utility'

@Component({
  selector: 'flo-two-column-container',
  templateUrl: './two-column-container.component.html',
  styleUrls: ['./two-column-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TwoColumnContainerComponent {
  @Input()
  model: TwoColumnContainerModel

  // Track by to reduce DOM repainting as new options are dynamically loaded
  readonly trackById = trackById
}
