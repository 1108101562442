import { NavigationOptions, SwiperOptions } from 'swiper/types'
import { breakpointsInPixels } from 'src/app/app.config'

export const getSwiperConfig = (forwardClass: string, backwardClass: string): SwiperOptions => {
  const navOptions: NavigationOptions = {
    // Css classes that swiper can use to find our forward/backward chevrons
    nextEl: `.${forwardClass}`,
    prevEl: `.${backwardClass}`,
    disabledClass: 'nav-disabled' // Swiper will apply this class when a nav arrow should be disabled
  }
  const desktopOptions: SwiperOptions = {
    // Batch scrolling
    allowTouchMove: false,
    slidesPerGroup: 1,
    slidesPerGroupAuto: true,
    navigation: navOptions // Navigation arrows
  }

  const options: SwiperOptions = {
    freeMode: true, // Freely scroll with no stickiness
    slidesPerView: 'auto', // Fit as many event ticker items in view as possible
    // Responsive breakpoints
    breakpoints: {
      // When window width is >= 768px (screen-md)
      [breakpointsInPixels.medium]: {
        ...desktopOptions
      }
    }
  }

  return options
}
