import { NgModule } from '@angular/core'
import { StepperComponent } from './stepper.component'
import { SvgModule } from '../svg/svg.module'
import { CommonModule } from '@angular/common'

@NgModule({
  imports: [CommonModule, SvgModule],
  declarations: [StepperComponent],
  exports: [StepperComponent]
})
export class StepperModule {}
