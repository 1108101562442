import { NgModule, PLATFORM_ID, Optional, SkipSelf } from '@angular/core'
import { isPlatformServer } from '@angular/common'
import { FloQueryParamsService } from './queryparams.service'
import { REQUEST } from '@nguniversal/express-engine/tokens'
import { FLO_INITIAL_QUERY_PARAM_MAP } from './queryparams.tokens'
import { HttpParams } from '@angular/common/http'
import { maybe } from 'typescript-monads'

function extractParamMapFromLocation(fromString: string) {
  const ngParams = new HttpParams({ fromString })
  return ngParams.keys().reduce((acc, curr) => {
    return {
      ...acc,
      [curr]: ngParams.get(curr)
    }
  }, {})
}

export function getInitialQueryParamMap(platformId: string, req: any) {
  return isPlatformServer(platformId)
    ? req.query
    : extractParamMapFromLocation(
        maybe(window.location.search)
          .map(a => a.substr(1))
          .valueOr('')
      )
}

@NgModule({
  providers: [
    {
      provide: FLO_INITIAL_QUERY_PARAM_MAP,
      useFactory: getInitialQueryParamMap,
      deps: [PLATFORM_ID, REQUEST]
    },
    FloQueryParamsService
  ]
})
export class FloQueryParamsModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: FloQueryParamsModule
  ) {
    if (parentModule) throw new Error('FloQueryParamsModule already loaded.')
  }
}
