<div
  class="question-row my-3 d-flex align-items-center justify-content-between border-bottom border-grey-400"
  (click)="showAnswerContainer = !showAnswerContainer"
  data-test="checkout-question-row"
>
  <p class="question-text m-0">{{ question }}</p>
  <span
     data-test="faq-chevrons"
     class="primary-color-text rotate svg-icon-lg"
     [ngClass]="{ 'rotate-down': showAnswerContainer }"
     aria-hidden="true"
  >
   <flo-svg-icon [width]="26" icon="icon-chevron-down"></flo-svg-icon>
  </span>
</div>
<div
  class="answer-container"
  [ngClass]="{ show: showAnswerContainer, hide: !showAnswerContainer }"
  data-test="checkout-faq-answer-container"
>
  <div class="border-wrapper">
    <p class="answer-text">
      <ng-content select="[answer]"></ng-content>
    </p>
  </div>
</div>
