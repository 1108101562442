<nav *ngIf="flyOut" class="fly-out" aria-label="flyout menu">
  <div class="fly-out-menu d-flex flex-column" [ngClass]="show ? 'show' : 'hide'">
    <div class="fly-out-menu__header bg-900 border-bottom border-grey-800 d-flex ps-3 pe-35 justify-content-between header">
      <div class="d-flex justify-content-center align-items-center">
        <flo-svg-icon floAnalytics [analyticsProperties]="{name: 'Close Vertical Picker'}" tagName="Button" class="d-flex justify-content-center me-1 color-100" icon="icon-times" width="24px" (click)="handleExitClick()" aria-label="close flyout menu"></flo-svg-icon>
        <flo-svg-logo [siteId]="siteId === varsitySiteId ? varsitySiteId : floSportsSiteId" class="d-flex justify-content-center align-items-center"></flo-svg-logo>
      </div>
      <flo-link *ngIf="flyOut.headerButton?.id !== 'primary-aux-account'" [link]="flyOut.headerButton" class="flyout-header-button d-flex align-items-center" (click)="handleExitClick()"></flo-link>
      <a
        *ngIf="flyOut.headerButton?.id === 'primary-aux-account'"
        role="button"
        class="h-100"
        ngbDropdown
        ngbDropdownToggle
        >
        <flo-link-menu
          ngbDropdownMenu
          class="account-menu"
          id="account-dropdown"
          [options]="flyOut.accountDropdownLinks"
          (click)="handleExitClick()"
          >
        </flo-link-menu>
        <flo-link
          [link]="flyOut.headerButton"
          class="flyout-header-button d-flex align-items-center"
          >
        </flo-link>
      </a>
    </div>
    <div class="fly-out-menu__top-link bg-900 px-3">
      <flo-link [link]="flyOut.topLink" class="flyout-top-link" (click)="handleExitClick()"></flo-link>
    </div>
    <div *ngIf="flyOut.ctaAccount" class="fly-out-menu__cta border-bottom border-grey-300 bg-200 p-4 pt-3">
      <flo-fly-out-cta-account [ctaAccount]="flyOut.ctaAccount" (click)="handleExitClick()"></flo-fly-out-cta-account>
    </div>
    <div class="overflow-auto">
      <div *ngFor="let menu of flyOut.menus; trackBy: trackById" class="fly-out-menu__menus flex-grow-1 bg-100 py-3 px-4">
        <flo-nav [nav]="menu" (click)="handleExitClick()"></flo-nav>
      </div>
    </div>
    <div class="fly-out-menu__bottom-links bg-200 py-2 px-4">
        <div *ngFor="let bottomLink of flyOut.bottomLinks" class="fly-out-menu__bottom-link">
          <flo-link [link]="bottomLink"></flo-link>
        </div>
    </div>
  </div>
  <div floAnalytics [analyticsProperties]="{name: 'Close Vertical Picker', text: 'Non-X'}" tagName="Button" class="fly-out-backdrop h-100 w-100 bg-900" [ngClass]="show ? 'show' : 'hide'" (click)="handleExitClick()"></div>
</nav>
<ng-container *ngIf="!flyOut && show">
  <flo-skeleton [theme]="'fly-out'"></flo-skeleton>
</ng-container>
