import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { PromoBannerData, promoBannerDataMap } from './promo-banner.utility'

@Component({
  selector: 'flo-promo-banner',
  templateUrl: './promo-banner.component.html',
  styleUrls: ['./promo-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromoBannerComponent implements OnInit {
  @Input() siteId: number

  constructor(private readonly router: Router) {}

  public login = () =>
    this.router.navigate(['/login'], { queryParams: { go: this.router.url }, queryParamsHandling: 'merge' })
  public join = () =>
    this.router.navigate(['/create-account'], { queryParams: { go: this.router.url }, queryParamsHandling: 'merge' })

  public promoBannerData?: PromoBannerData

  public getPromoBannerData(): PromoBannerData | undefined {
    return promoBannerDataMap.get(this.siteId)
  }

  ngOnInit() {
    this.promoBannerData = this.getPromoBannerData()
  }
}
