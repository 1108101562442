import { SiteIds } from 'src/app/shared/models/site.model'

export const PRIMARY_AUX_LINK_IDS = {
  SCHEDULE: 'primary-aux-schedule',
  WATCH: 'primary-aux-watch',
  SEARCH: 'primary-aux-search',
  LOGIN: 'primary-aux-login',
  ACCOUNT: 'primary-aux-account'
}

export const PRIMARY_LINK_IDS = {
  WATCH: 'primary-watch'
}

export const SUBSCRIBE_CTA_ID = 'primary-nav-subscribe-cta'

// As the list of verticals showing the new nav v2 grows, we can add them to this array.
// This will prevent any nav conditional logic where we are checking on nav v1/v2 from containing long, duplicated lists of || operations.
export const VERTICALS_SHOWING_NAV_V2 = [SiteIds.FLOHOCKEY, SiteIds.FLORACING]
