<a
  *ngIf="props"
  floAnalytics
  [analyticsProperties]="props.action?.analytics"
  elementName="Small Content Card"
  class="small-content-card d-flex flex-column cursor-pointer primary-color-text-hover"
  [floCustomLink]="props.action?.url"
>
  <div
    class="image w-100 h-auto image-opacity-hover-target"
    [ngClass]="{'play-icon-badge': props.showPlayIcon}"
    data-test="small-content-thumbnail"
  >
    <flo-image-thumbnail
      [imageUrl]="props?.previewImage?.url"
      [altText]="props.title"
      [showPlayIcon]="props.showPlayIcon"
      imageSize="350"
      mobileSize="350"
    >
      <div *ngIf="props.label1 !== null" class="d-flex flex-row w-100">
        <div class="img-overlay-badge caption">{{ props.label1 }}</div>
      </div>
    </flo-image-thumbnail>
    <ng-container *ngIf="props.progress">
      <div class="progress-bar" data-test="progress-bar"><div class="progress bg-primary" [ngStyle]="{'width': props.progress}"></div></div>
    </ng-container>
  </div>
  <div
    class="small-content-card__text d-flex flex-column justify-content-between pt-2"
  >
    <div class="small-content-card__title">
      <h6 class="subhead text-line-clamp-3 mb-1 apply-text-hover">{{ props.title }}</h6>
    </div>
    <div *ngIf="!!props.subtitle1 && !props.subtitle1Parts" class="small-content-card__subtext mb-0">
      <p class="color-500 footnote mb-0">
        {{ props.subtitle1 }}
      </p>
    </div>
    <p *ngIf="!!props.subtitle1Parts" class="footnote mb-0 flex-grow-0 flex-md-grow-0 color-500 align-content-end"
       data-test="static-publish-date">{{ props.subtitle1Parts | floRelativeDate }}
    </p>
  </div>
</a>
