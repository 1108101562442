<!-- leaderboard ad -->
<div class="w-100 justify-content-center align-items-center d-none d-lg-flex my-3">
  <div
    class="leaderboard-center skeleton"
    style="height: 90px; width: 728px"
  ></div>
</div>

<!-- content -->
<main class="container bg-white color-900 min-vh-100">
  <div class="row">
    <div class="col-12 col-lg-8 pt-0 pt-sm-2">
      <div
        class="jumbotron rounded mb-0 mt-sm-4 bg-300 container-fluid p-0 ratio ratio-16x9"
      >
        <div class="img-fluid rounded skeleton"></div>
      </div>
      <nav
        class="d-flex mt-md-3 pt-md-3 pt-3 mt-2 justify-content-between w-100 flex-wrap"
      >
        <h1 class="h1-skeleton"></h1>
        <p class="p-skeleton mt-3"></p>
      </nav>
      <div class="mt-4 pt-2 position-relative d-block tab-container"> </div>
      <div class="w-100 mt-5" style="min-height: 480px">
        <div class="row">
          <div *ngFor="let image of [0, 1, 2, 3, 4, 5]" class="col-6 col-md-4">
            <div
              class="img-fluid rounded mb-3 skeleton"
              style="height: 120px; width: 100%"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="sidebar d-lg-block col-12 col-lg-4 border-start px-lg-0 px-3">
      <div class="px-md-4 mx-md-2 mb-4">
        <h5
          class="color-900 font-family-heavy border-bottom mb-0 pt-3 mt-1 list-title"
        ></h5>
        <ul>
          <li *ngFor="let number of [0, 1, 2, 3, 4]" class="py-3 border-bottom">
            <a class="primary-color-text-hover w-100">
              <div class="d-flex flex-row align-items-center col-12 px-0 py-1">
                <div
                  class="ps-0 pe-0 pe-sm-2 col-lg-4 col-md-2 col-sm-2 col-3 me-3 me-sm-2"
                >
                  <div
                    class="bg-300 rounded container-fluid ratio"
                    style="padding-top: 75%"
                  >
                    <div
                      class="skeleton img-fluid rounded"
                    ></div>
                  </div>
                </div>
                <div
                  class="col-lg-8 col-md-10 col-sm-10 col-9 px-0 align-self-start"
                >
                  <p class="p-skeleton"></p>
                  <span class="flo-footnote d-flex"></span>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>

      <div class="ad-rectangle-skeleton"></div>
    </div>
  </div>
</main>
<div
  class="bg-white color-900 bottom d-lg-none pt-2"
  style="position: sticky; z-index: 12"
>
</div>
