<div *ngIf="card" data-test="static-content-card" class="d-flex flex-row primary-color-text-hover cursor-pointer"
     [class.image-right]="imagePosition === 'right'">
  <a floAnalytics
     [analyticsProperties]="card.action?.analytics"
     [floCustomLink]="card.action?.url" class="d-flex flex-row w-100">
    <div class="text-content d-flex flex-column"
         data-test="static-content-text">
      <h5 class="h5 mb-1 flex-grow-0 color-900 mobile-header-resize text-line-clamp-2 apply-text-hover">{{ card.title }}</h5>
      <p class="description subhead mb-2 color-700 text-line-clamp-2"
         data-test="static-description" [innerHTML]="card.description"></p>
      <p *ngIf="!!card.subtitle1Parts" class="footnote mb-0 flex-grow-0 flex-md-grow-0 color-500 align-content-end"
         data-test="static-publish-date">{{ card.subtitle1Parts | floRelativeDate }}
      </p>
    </div>
    <div class="image-content" [ngClass]="imagePosition === 'right' ? 'ps-3' : 'pe-3'">
      <div class="d-none d-md-block play-icon-badge" data-test="static-content-image">
        <flo-image-thumbnail [imageUrl]="card.previewImage?.url"
                             [altText]="card.title"
                             imageSize="475"
                             [showPlayIcon]="card.showPlayIcon">
          <div *ngIf="card.label1" class="d-flex flex-row w-100">
            <div data-test="static-content-badge" class="img-overlay-badge caption">{{ card.label1 }}</div>
          </div>
        </flo-image-thumbnail>
      </div>
      <!-- mobile thumbnail needs to be square -->
      <div class="d-block d-md-none " data-test="static-content-image-mobile">
        <img
          class="mobile-square-image"
          [src]="card.previewImage?.url + '?width=180&quality=80'"
          [alt]="card.title"
          loading="lazy"
        />
      </div>
    </div>
  </a>
</div>
