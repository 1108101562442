import { Injectable } from '@angular/core'
import { SegmentTagV2 } from '../../shared/analytics/models/segment-tag.model'
import { SegmentService } from '../../shared/analytics/services/segment.service'

/**
 * The standard Page tag for the Next-Gen Homepage.
 *
 * @remarks
 * This is expected to be used with pageV2, as indicated by the use of the
 * SegmentTagV2 interface and `autoPageCall: false`.
 *
 * @see SegmentService#pageV2
 * @see SegmentTagV2
 */
export const HOME_ANALYTICS_TAG: SegmentTagV2 = {
  page_category: 'Home',
  category: 'Home',
  autoPageCall: false
}

/**
 * Handler for all home-related analytics activities like page/track calls.
 *
 * @see SegmentService
 * @see SegmentTagV2
 */
@Injectable()
export class HomeAnalyticsService {
  constructor(private readonly segmentService: SegmentService) {}

  /**
   * Send a page call for the Next-Gen Home landing page.
   */
  public sendPageCall(): void {
    return this.segmentService.pageV2(HOME_ANALYTICS_TAG, { _tag: 'three-column' })
  }
}
