import {
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
  Injectable,
  Renderer2,
  RendererFactory2,
  ViewContainerRef
} from '@angular/core'
import { from, Observable, of } from 'rxjs'
import { catchError, concatMap, map, take } from 'rxjs/operators'
import { LiveEventService } from '../../live/services/event.service'
import {
  EventStatusComponent,
  EventStatusComponentProps
} from '../../libs/events/components/event-status/event-status.component'
import { LOGGER_SERVICE } from '../../logger/logger.config'
import { LoggerService } from '../../logger/logger.interface'
import { isAfter, isBefore } from 'date-fns'
import { FloEvent, LiveEvent } from '@flocasts/flosports30-types/dist/entity'
import { getDataAttr, getDataId } from './cta.utility'
import { PlatformService } from '../platform.service'
import { calculateEndDate, floEventHasLiveEvent } from '../../libs/events/functions'
import { LiveEventStatus } from '@flocasts/experience-service-types'
import { OldSvgLoaderService } from 'src/app/libs/_deprecated/svg/old-svg-loader.service'

export interface CTADeps extends EventStatusComponentProps {
  live_id: number | undefined | null
  id: number
  title?: string
  slug_uri?: string
}

export interface FloEventWithLiveEvent extends FloEvent {
  live_id: number
  live_event: LiveEvent
}

/**
 * CTAs, or call-to-action, are stylized HTML elements inviting the user to
 * navigate to the event that an article is related to (typically the primary association).
 * These CTAs need to be dynamic in their messaging- if the related event is currently live,
 * the user needs to know! The date for the event also needs to be updated if/when an event is
 * cancelled or rescheduled. This logic is already in the event-status.component. Addtionally,
 * the CTA needs to link to the live player (instead of event hub) if the event is currently live.
 */
@Injectable({
  providedIn: 'root'
})
export class LiveEventCtaService {
  private readonly renderer: Renderer2

  constructor(
    private readonly rendererFactory: RendererFactory2,
    private readonly cfr: ComponentFactoryResolver,
    private readonly eventService: LiveEventService,
    private readonly platformService: PlatformService,
    private svgLoader: OldSvgLoaderService,
    @Inject(LOGGER_SERVICE) private readonly logger: LoggerService
  ) {
    this.renderer = rendererFactory.createRenderer(null, null)
  }

  /**
   * Update all CTAs on page using event-status.component to give users the appropriate message,
   * and link to either the event hub or live player.
   *
   * Initially, on SSR and SPA, the existing data-attribute values from the CMS are used. If the
   * page is not SSR, then asynchronously the event id is taken from the data-attribute and used to
   * get the latest info about the event, and the CTA is updated again, after the page has loaded
   * so that page load times aren't affected and the UI isn't blocked.
   */
  public updateCTAs(ctas: HTMLElement[], vcref: ViewContainerRef): void {
    // This first loops through all CTAs within an article body on both SSR and browser using data proved by CMS
    ctas.forEach(cta => {
      const eventStatusDeps = getDataAttr(cta)
      if (eventStatusDeps === null) {
        this.logger.info('CTA data attributes malformed', {
          event: getDataId(cta)
        })
        return
      }
      // Using exiting data-attributes, update the CTA
      this.eventStatusComponent(vcref, eventStatusDeps)
        .pipe(
          map(esComponent => {
            this.updateLiveEventLink(cta, eventStatusDeps)
            this.renderEventStatusToCta(cta, esComponent)
            this.checkAndAddSVGArrow(cta)
          }),
          take(1)
        )
        .subscribe()
    })

    // if browser (not SSR), make a network call to the /events endpoint to get the latest data for event, and update again
    if (this.platformService.isBrowser) {
      ctas.forEach(cta => {
        const eventId = getDataId(cta)

        if (eventId) {
          this.getEvent(eventId)
            .pipe(
              map(floEvent => {
                if (floEvent && floEventHasLiveEvent(floEvent)) {
                  // update link to live player if event is live
                  const ctaDeps = mapFloEventToCtaDeps(floEvent)
                  this.updateLiveEventLink(cta, ctaDeps)
                  return ctaDeps
                }
                // if event doesn't exist, or have a live_event, hide CTA, log, and exit pipe
                cta.style.display = 'none'
                this.logger.info('no live_event for event', { floEvent })
                throw new Error(`no live_event for event ${eventId}`)
              }),
              concatMap(ctaDeps => this.eventStatusComponent(vcref, ctaDeps)),
              map(esComponent => {
                // Finally, rerender CTA with event-status.component and updated event data
                this.renderEventStatusToCta(cta, esComponent)
              }),
              take(1)
            )
            .subscribe()
        } else {
          this.logger.info('data-id attribute for event CTA not found')
        }
      })
    }
  }

  /**
   * If the event is currently live, change the link from the event hub page to the live player
   * The event-status.component just checks the event.live_event.status, but since the status is
   * initially set by the CMS, it only reflects the state of the live_event when the article was
   * written. Therefore, we have to manually calculate whether the event is live
   */
  private updateLiveEventLink(
    cta: HTMLElement,
    event: Pick<CTADeps, 'startDateTime' | 'live_id' | 'numberOfDays'>
  ): void {
    // get our three dates; now, the start of the event, and the end of the event
    const startDate = new Date(event.startDateTime)
    const endDate = calculateEndDate(event.startDateTime, event.numberOfDays || undefined)
    const currentDate = new Date()

    // Only update to live event player if the event is currently live or intermission
    if (isAfter(currentDate, startDate) && isBefore(currentDate, endDate)) {
      const eventLink = cta.querySelector('a')
      this.logger.info('updating CTA link to live event player', { live_id: event.live_id })
      this.renderer.setAttribute(eventLink, 'href', `/live/${event.live_id}`)
    }
  }

  /**
   *  Previously generated event-status.component is rendered to page in CTA
   */
  private renderEventStatusToCta(cta: HTMLElement, renderedComponent: ComponentRef<EventStatusComponent>): void {
    const newStatus = renderedComponent.instance.elementRef.nativeElement
    const statusElement = cta.querySelector('.fr-event-status')
    const originalCmsChild = cta.querySelector('.fr-event-status > :first-child')
    if (!!statusElement) {
      this.renderer.removeChild(statusElement, originalCmsChild)
      this.logger.info('updating CTA')
      this.renderer.appendChild(statusElement, newStatus)
    }
  }

  /**
   * Sometimes, the CMS/Froala will remove SVG elements (among other things). This method reintroduces an
   * SVG icon-angle-right svg if missing. It only needs to be called once instead of twice like the others.
   * The new CMS templates use angle SVGs, however we still need to account for the older CTAs. So a check
   * was added  to ensure we account for both possibilities.
   */
  private checkAndAddSVGArrow(cta: HTMLElement): void {
    const chevronSvgElement = cta.querySelector('svg.icon-chevron-right')
    const angleRightSvgElement = cta.querySelector('svg.icon-angle-right')

    const angleSVGContainer = cta.querySelector('.angle-svg-container')

    if (!angleRightSvgElement && !chevronSvgElement) {
      const parentContainer = angleSVGContainer ? angleSVGContainer : cta
      const newSvg = this.renderer.createElement('span')

      this.renderer.setAttribute(newSvg, 'class', 'align-items-center ms-auto d-flex text-700 svg-icon-md h2')
      this.svgLoader
        .load('icon-angle-right')
        .pipe(take(1))
        .subscribe(svg => {
          this.renderer.setProperty(newSvg, 'innerHTML', svg)
          this.renderer.appendChild(parentContainer, newSvg)
        })
    }
  }

  /**
   * GET event from the /events/${eventId} endpoint
   */
  private getEvent = (eventId: number): Observable<FloEvent | undefined> => {
    return this.eventService.getEvent$(eventId).pipe(
      catchError(error => {
        this.logger.info(`could not get live event hub id ${eventId}`, { error })
        return of(undefined)
      })
    )
  }

  /**
   * Lazy load and generate event-status.component, with given props
   */
  private eventStatusComponent(
    vcref: ViewContainerRef,
    props: CTADeps
  ): Observable<ComponentRef<EventStatusComponent>> {
    return from(import('src/app/libs/events/components/event-status/event-status.component')).pipe(
      map(m => m.EventStatusComponent),
      map(eventStatusComponent => {
        return vcref.createComponent(this.cfr.resolveComponentFactory(eventStatusComponent))
      }),
      map(component => {
        component.instance.props = props
        return component
      })
    )
  }
}

export const mapFloEventToCtaDeps = (eventHub: FloEventWithLiveEvent): CTADeps => {
  return {
    id: eventHub.id,
    live_id: eventHub.live_id,
    // Must typecast here - what we get for mixing enums & type unions for LiveEventStatus
    status: eventHub.live_event.status as LiveEventStatus,
    startAtNext: eventHub.live_event.start_at_next,
    startDateTime: eventHub.start_date_time,
    numberOfDays: eventHub.number_of_days || null,
    startTimeTbd: eventHub.live_event.start_time_tbd,
    shortPrint: false
  }
}
