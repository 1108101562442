import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  Renderer2,
  Inject,
  PLATFORM_ID,
  ChangeDetectorRef,
  NgZone
} from '@angular/core'
import { trackById } from 'src/app/shared/utility-functions/track-by-id.utility'
import { FacetQuery, FilterScrollDetails } from './types'
import { combineLatest, ReplaySubject, Subject } from 'rxjs'
import {
  filter,
  map,
  scan,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom
} from 'rxjs/operators'
import { isPlatformBrowser } from '@angular/common'
import { notNullOrUndefined } from '../../shared/functions/type-guards'
import { calculateScrollControls, calculateScrollPosition } from './filter.functions'
import { FilterModel, FilterOptionModel, LinkModel } from '@flocasts/experience-service-types'
import { Router } from '@angular/router'

@Component({
  selector: 'flo-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FiltersComponent implements AfterViewInit, OnDestroy {
  /**
   * Boolean for indicating if filters should be multiselect, defaults to false (single select)
   * In the future we could allow a mix of multiselect and single select dropdowns in flo-filters (if needed)
   */
  @Input()
  multiselect = false

  /**
   * An array of filters to be displayed as dropdowns with options.
   */
  @Input()
  items: ReadonlyArray<FilterModel>

  /**
   * Hide Clear All button if no filters or search criteria are selected
   * @Deprecated Will be removed once Clear All is driven by BFF
   */
  @Input()
  showClearAll = false

  @Input()
  clearAll: LinkModel

  /**
   * Emits the dropdown option from a single select dropdown.
   */
  @Output()
  singleFilterClicked = new EventEmitter<FilterOptionModel>()

  /**
   * Emits the items and facet query param when a multiselect dropdown closes.
   */
  @Output()
  multipleFiltersClicked = new EventEmitter<{ items: ReadonlyArray<FilterModel>; facetQueryParam?: string }>()

  /**
   * Emit that clear all was clicked
   */
  @Output()
  clearAllClicked = new EventEmitter<void>()

  constructor(
    private readonly renderer2: Renderer2,
    private readonly cdr: ChangeDetectorRef,
    // tslint:disable-next-line:ban-types
    @Inject(PLATFORM_ID) private platformId: Object,
    private readonly zone: NgZone,
    private readonly router: Router
  ) {}

  private scrollElement$ = new ReplaySubject<HTMLDivElement>(1)
  private carouselContainer$ = new ReplaySubject<HTMLDivElement>(1)
  private scrollControlsSubject = new ReplaySubject<FilterScrollDetails>()
  private recalculateScrollElement$ = new Subject<boolean>()
  public scrollControls$ = this.scrollControlsSubject.asObservable()
  public scrollClick$ = new Subject<number>()
  public ngAfterViewInit$ = new Subject<void>()
  public ngOnDestroy$ = new Subject<void>()
  private clientWidth = 0

  @ViewChild('scrollContainer')
  public set scrollContainer(el: ElementRef<HTMLDivElement>) {
    if (el.nativeElement) {
      this.scrollElement$.next(el.nativeElement)
      this.clientWidth = el.nativeElement.clientWidth
      if (isPlatformBrowser(this.platformId)) {
        const observer = new ResizeObserver(() => this.zone.run(() => this.recalculateScrollElement$.next(true)))
        observer.observe(el.nativeElement)
        this.ngOnDestroy$.pipe(take(1)).subscribe(() => observer.disconnect())
      }
    }
  }

  @ViewChild('carouselContainer', { static: true })
  public set carouselContainer(el: ElementRef<HTMLDivElement>) {
    if (el.nativeElement) {
      this.carouselContainer$.next(el.nativeElement)
    }
  }

  /**
   * Handles the dropdown close event on each dropdown.
   * Builds the facet query param from the selected options and emits.
   */
  handleDropdownClosed = () => {
    if (this.multiselect) {
      // Build a facet dictionary of the selected options
      const selectedFacets = this.items.reduce((acc: FacetQuery, curr: FilterModel) => {
        const selectedOptions = curr.options.filter(option => option.isSelected)
        selectedOptions?.forEach((option: FilterOptionModel) => {
          // Where selected option.title is "Blue" (as an example)
          if (!curr.code) return
          // If accumulator is already an array: acc="{ Belt: ["Brown", "Black"] }"
          acc[curr.code] = Array.isArray(acc[curr.code])
            ? // Then spread another selected option into the existing array: acc="{ Belt: ["Brown", "Black", "Blue"] }"
              [...acc[curr.code], option.title]
            : // If it's not an array but already has a string value: acc="{ Belt: "Black" }"
            !!acc[curr.code]
            ? // Create an array with the incoming selection option: acc="{ Belt: ["Black", "Blue"] }"
              [acc[curr.code] as string, option.title]
            : // Otherwise just set the value as a string: acc="{ Belt: "Black" }"
              option.title
        })
        return acc
      }, {})
      const facetQueryParam =
        Object.keys(selectedFacets).length > 0 ? encodeURIComponent(JSON.stringify(selectedFacets)) : undefined
      this.multipleFiltersClicked.emit({ items: this.items, facetQueryParam })
    }
  }

  /**
   * Handles the click event on the dropdown option.
   * Simply emits the dropdown option on single select dropdowns.
   * Parent component can then call the api with this option's action.url.
   * @param option The dropdown option clicked.
   */
  handleOptionClicked = (option: FilterOptionModel) => {
    if (!this.multiselect) {
      if (this.singleFilterClicked.observed) {
        this.singleFilterClicked.emit(option)
      } else {
        if (!!option.action?.url) {
          this.router.navigateByUrl(option.action.url)
        }
      }
    }
  }

  /**
   * Signal to the parent that Clear All has been clicked
   * For BFF powered clear all this will signal that we need to show a loading spinner
   * For client side clear all, this will update the facets query param which will handle the loading spinner for us as
   * well
   * Remove the facets query param, effectively clearing all selected filter values
   */
  handleClearAllClicked() {
    this.clearAllClicked.emit()
  }

  // Track by to reduce DOM repainting as new options are dynamically loaded
  readonly trackById = trackById

  // Horizontal Scroll Logic
  private elements$ = this.scrollElement$.pipe(
    filter(el => notNullOrUndefined(el.children)),
    filter(el => el.children.length > 0),
    map(el => Array.from(el.children))
  )

  private computedPaddingChildElements$ = this.elements$.pipe(
    filter(() => isPlatformBrowser(this.platformId)),
    map(elements => {
      const computedStyles = getComputedStyle(elements[0])
      return !!computedStyles.paddingRight ? parseFloat(computedStyles.paddingRight) : 0
    }),
    shareReplay(1)
  )

  private transformAmount$ = this.scrollClick$.pipe(scan((acc, curr) => acc + curr, 0))

  /**
   * This triggers calculation of scroll position only when a user has clicked scroll controls or interacted with a dropdown
   */
  private triggerScrollPosition$ = combineLatest([
    this.transformAmount$.pipe(startWith(this.clientWidth)),
    this.recalculateScrollElement$.pipe(startWith(false))
  ])

  public scrollPosition$ = this.triggerScrollPosition$.pipe(
    withLatestFrom(this.scrollElement$, this.computedPaddingChildElements$),
    map(([[transformAmount, _], scrollElement, computedPadding]) => {
      return calculateScrollPosition(
        scrollElement.scrollWidth,
        scrollElement.clientWidth,
        computedPadding,
        transformAmount
      )
    }),
    shareReplay(1)
  )

  public showScrollControls$ = combineLatest([this.ngAfterViewInit$, this.scrollPosition$.pipe(startWith(0))])
    .pipe(
      switchMap(_ => {
        return combineLatest([
          this.scrollElement$,
          this.scrollPosition$.pipe(startWith(0)),
          this.computedPaddingChildElements$
        ])
      }),
      tap(([scrollElement, scrollPosition, computedPadding]) => {
        const scrollDetails: FilterScrollDetails = calculateScrollControls(
          scrollElement.scrollWidth,
          scrollElement.clientWidth,
          computedPadding,
          scrollPosition
        )
        this.scrollControlsSubject.next(scrollDetails)
      }),
      takeUntil(this.ngOnDestroy$)
    )
    .subscribe()

  public addScrollSnapClass$ = this.elements$.pipe(take(1)).subscribe(elements => {
    elements.forEach((element, i) => {
      this.renderer2.addClass(element, 'scroll-snap-by-device')
    })
  })

  // 'scrolling' with transform: translateX() to ensure animation/smooth scroll across browsers
  public scroll$ = this.scrollPosition$
    .pipe(withLatestFrom(this.scrollElement$), takeUntil(this.ngOnDestroy$))
    .subscribe(([position, scrollElement]) => {
      this.renderer2.setStyle(scrollElement, 'transform', `translateX(${-position}px)`)
    })

  public ngAfterViewInit(): void {
    this.ngAfterViewInit$.next()
    this.ngAfterViewInit$.complete()
    // This is needed to address this error: https://angular.io/errors/NG0100
    this.cdr.detectChanges()
  }

  public ngOnDestroy(): void {
    this.ngOnDestroy$.next()
    this.ngOnDestroy$.complete()
  }
}
