import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AnalyticsModule } from '../../shared/analytics/analytics.module'
import { EventTickerComponent } from './event-ticker.component'
import { EventStatusModule } from '../events/components/event-status/event-status.module'
import { RouterModule } from '@angular/router'
import { CustomLinkModule } from '../custom-link/custom-link.directive.module'
import { EventTickerDataService } from './event-ticker-data.service'
import { SvgModule } from 'src/app/shared/svg/svg.module'
import { SwiperModule } from 'swiper/angular'
import { LinkModule } from '../link/link.module'

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    AnalyticsModule,
    EventStatusModule,
    CustomLinkModule,
    SvgModule,
    SwiperModule,
    LinkModule
  ],
  declarations: [EventTickerComponent],
  exports: [EventTickerComponent],
  providers: [EventTickerDataService]
})
export class EventTickerModule {}
