import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { OfferSelectionCardModel } from '@flocasts/experience-service-types'
import { Plan } from '@flocasts/flosports30-types/dist/subscriptions'
import { trackById } from 'src/app/shared/utility-functions/track-by-id.utility'
import { OffersService } from 'src/app/singleton-services/offers.service'
import { ProductService } from '../../../shared/services/product.service'

@Component({
  selector: 'flo-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OffersComponent {
  constructor(private readonly offersService: OffersService, private readonly productService: ProductService) {}

  @Input()
  offers: OfferSelectionCardModel[] = []

  @Input()
  selectedOffer: OfferSelectionCardModel | null = null

  @Input()
  legacyPlans: Plan[] = []

  public updateSelectedOffer(offer: OfferSelectionCardModel) {
    if (this.legacyPlans.length > 0) {
      const selectedPlan = this.legacyPlans?.find(plan => plan.id === Number(offer.id))
      this.productService.updateSelectedPlan(selectedPlan)
    } else {
      this.offersService.updateSelectedOffer(offer)
    }
  }

  trackById = trackById
}
