import { Inject, Injectable } from '@angular/core'
import { combineLatest } from 'rxjs'
import { filter, map, share } from 'rxjs/operators'
import { getExternalHostURL, isExternalSite, isExternalSiteAllowedRoute } from './external-routing.utility'
import { NavigationEnd, Router } from '@angular/router'
import { VerticalService } from '../../singleton-services/vertical.service'
import { PlatformService } from '../../singleton-services/platform.service'
import { EnvironmentService } from '../../singleton-services/environment.service'
import { WINDOW } from '../../app.injections'

@Injectable({
  providedIn: 'root'
})
export class ExternalRoutingService {
  constructor(
    private router: Router,
    private verticalService: VerticalService,
    private platformService: PlatformService,
    private environmentService: EnvironmentService,
    @Inject(WINDOW) window: unknown
  ) {}

  private navigationEndEvents$ = this.router.events.pipe(
    filter<NavigationEnd>(nav => {
      return nav instanceof NavigationEnd
    }),
    share()
  )

  public checkExternalSiteNeedsRedirect$ = combineLatest([
    this.navigationEndEvents$,
    this.verticalService.siteId$,
    this.verticalService.tokenDomain$
  ]).pipe(
    map(([navigationEndEvents, siteId, tokenDomain]) => {
      return {
        navigationEndEvents,
        siteId,
        tokenDomain
      }
    }),
    // Filter to only external sites (ie Milesplit and TW)
    filter(data => isExternalSite(data.siteId)),
    map(site => {
      const hostURL = getExternalHostURL(site.siteId, site.tokenDomain, this.environmentService.config.env)
      const route = this.router.routerState.snapshot.url.split('?')[0]
      const destination = site.navigationEndEvents.url
      if (destination === '/' || !isExternalSiteAllowedRoute(hostURL, route)) {
        this.redirectUser(hostURL)
      }
    })
  )

  public redirectUser(url: string) {
    this.platformService.isBrowser ? (window.location.href = url) : this.router.navigateByUrl(url)
  }
}
