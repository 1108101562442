import { LiveEventStatus } from '@flocasts/experience-service-types'
import { CTADeps } from './live-event-cta.service'

// Utility file for pure functions. No side effects or weird dependencies allowed!

/**
 * Get needed event data for CTA and event-status.component from data-attributes generated by the CMS
 *
 * Since the data-attributes are generated by the CMS, they have dubious reliability. Luckily, CTADeps
 * and EventStatusComponentDeps allow for undefined for nearly all their attributes. However, if the
 * event-id is missing, don't bother with the rest and just return false. The default appearance of the
 * CTA straight from the CMS is usable.
 */
export const getDataAttr = (cta: HTMLElement): CTADeps | null => {
  // tslint:disable:variable-name
  const title = cta.getAttribute('data-title') ?? undefined
  const slug_uri = cta.getAttribute('data-sluguri') ?? undefined
  const liveEventStatus = cta.getAttribute('data-status') as LiveEventStatus
  const startDateTime = cta.getAttribute('data-startdatetime') ?? ''
  const start_at_next = cta.getAttribute('data-startatnext') ?? null
  const startTimeTbd = cta.getAttribute('data-starttimetbd') === 'true'
  const liveIdString = parseInt(cta.getAttribute('data-liveid') ?? '', 10)
  const idString = parseInt(cta.getAttribute('data-id') ?? '', 10)
  const numOfDaysString = parseInt(cta.getAttribute('data-numberofdays') ?? '', 10)
  const shortPrint = cta.getAttribute('data-shortprint')

  const live_id = isNaN(liveIdString) ? undefined : liveIdString
  const id = isNaN(idString) ? undefined : idString
  const numberOfDays = isNaN(numOfDaysString) ? undefined : numOfDaysString
  const startAtNext = start_at_next === 'null' ? null : start_at_next
  // tslint:enable:variable-name

  if (id) {
    const event: CTADeps = {
      title,
      slug_uri,
      id,
      live_id,
      startDateTime,
      numberOfDays: numberOfDays || null,
      status: liveEventStatus,
      startAtNext,
      startTimeTbd,
      shortPrint: false
    }
    return event
  } else {
    return null
  }
}

/**
 * Get the event-id from data-attributes generated by the CMS
 */
export const getDataId = (cta: HTMLElement): number | null => {
  const eventIdString = cta.getAttribute('data-id')
  const eventId = eventIdString ? parseInt(eventIdString, 10) : NaN

  if (isNaN(eventId)) {
    return null
  }
  return eventId
}
