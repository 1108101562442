import { Directive, Input } from '@angular/core'

import { IAnalyticsProperties } from 'src/app/shared/models/analytics-properties.interface'

@Directive({
  selector: '[floAnalytics]'
})
export class AnalyticsDirective {
  @Input() events: string | ReadonlyArray<string>
  @Input() analyticsProperties: IAnalyticsProperties = {}
  @Input() tagName: string
  @Input() elementName: string
  @Input() eventName: string = undefined as any
}
