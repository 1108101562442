import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { PinnedLinksFavoritesComponent } from './pinned-links-favorites.component'
import { AvatarCardModule } from '../../libs/avatar-card/avatar-card.module'
import { RouterModule } from '@angular/router'

@NgModule({
  imports: [CommonModule, AvatarCardModule, RouterModule],
  exports: [PinnedLinksFavoritesComponent],
  declarations: [PinnedLinksFavoritesComponent]
})
export class PinnedLinksFavoritesModule {}
