import { ChangeDetectionStrategy, Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core'
import { BehaviorSubject, Subject, Subscription, timer } from 'rxjs'
import { PlatformService } from '../../../singleton-services/platform.service'
import { SegmentService } from '../../../shared/analytics/services/segment.service'
import { takeUntil } from 'rxjs/operators'
import { CarouselBackgroundStyleGen, ResponsiveCarouselInstance } from '../../landing-page/batman-data.service'
import { SiteIds } from '../../../shared/models/site.model'

@Component({
  selector: 'flo-batman-features',
  templateUrl: './batman-feature.component.html',
  styleUrls: ['./batman-feature.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BatmanFeatureComponent implements OnInit, OnDestroy {
  @Input() public readonly carouselBackgroundGen: CarouselBackgroundStyleGen
  @Input() public readonly carouselData: readonly ResponsiveCarouselInstance[]

  @Input() public readonly inFlo10764: boolean
  @Input() public siteId: number

  @Input() public showCarousel: boolean

  public get trackWrestlingCssId() {
    return this.siteId === SiteIds.TRACK_WRESTLING ? 'trackwrestling' : ''
  }

  public carouselTimerSubscription$: Subscription

  public selectedCarouselSlide = new BehaviorSubject(1)

  private readonly ngOnDestroy$ = new Subject<void>()

  constructor(
    private readonly platformService: PlatformService,
    private readonly segment: SegmentService,
    private readonly zone: NgZone
  ) {}

  ngOnInit() {
    if (this.platformService.isServer) return

    const startingDelay = 5000
    const interval = 5000
    this.carouselTimerSubscription$ = this.zone
      .runOutsideAngular(() => timer(startingDelay, interval).pipe(takeUntil(this.ngOnDestroy$)))
      .subscribe(() => this.selectedCarouselSlide.next((this.selectedCarouselSlide.value % 3) + 1))
  }

  ngOnDestroy() {
    this.ngOnDestroy$.next()
    this.ngOnDestroy$.complete()
    if (this.carouselTimerSubscription$) {
      this.carouselTimerSubscription$.unsubscribe()
    }
  }

  public onInputChange(entry: any) {
    this.carouselTimerSubscription$.unsubscribe()

    const previouslySelectedCarouselSlide = this.selectedCarouselSlide.value
    const currentlySelectedCarouselSlide = parseInt(entry.target.id.split('-')[1], 10)

    this.segment.track('Carousel Navigated', {
      action: 'Click',
      subpage_category: 'Batman',
      navigate_to_position: currentlySelectedCarouselSlide,
      direction: previouslySelectedCarouselSlide < currentlySelectedCarouselSlide ? 'Right' : 'Left'
    })

    this.selectedCarouselSlide.next(currentlySelectedCarouselSlide)
  }

  public trackCarousel(idx: number, item: ResponsiveCarouselInstance): string {
    return item.imageUrl
  }
}
