import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ToggleComponent } from './toggle.component'
import { AnalyticsModule } from 'src/app/shared/analytics/analytics.module'

@NgModule({
  declarations: [ToggleComponent],
  imports: [CommonModule, AnalyticsModule],
  exports: [ToggleComponent]
})
export class ToggleModule {}
