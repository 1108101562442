import { NgModule } from '@angular/core'
import { AnalyticsModule } from '../../shared/analytics/analytics.module'
import { AvatarCardComponent } from './avatar-card.component'
import { ImagePipeModule } from '../../shared/pipes/image.pipe.module'
import { CustomLinkModule } from '../custom-link/custom-link.directive.module'
import { CommonModule } from '@angular/common'

@NgModule({
  imports: [CommonModule, AnalyticsModule, CustomLinkModule, ImagePipeModule],
  declarations: [AvatarCardComponent],
  exports: [AvatarCardComponent]
})
export class AvatarCardModule {}
