import { v3 as mumurhashv3 } from 'murmurhash'

// Seed to be used in bucketing hash.
const HASH_SEED = 1
// Maximum possible hash value
const MAX_HASH_VALUE = Math.pow(2, 32)
// Maximum traffic allocation
export const MAX_TRAFFIC_VALUE = 10000

export type Allocations = ReadonlyArray<{ id: string; rangeEnd: number }>

const EXPERIMENT_ALLOCATIONS: Record<string, Allocations> = {
  ['EXP_EXAMPLE_ID']: [
    { id: '0', rangeEnd: 1000 },
    { id: '1', rangeEnd: 7000 }
  ]
}
/**
 * Finds the variation id - usually '0' (control) or '1' (variation)
 * @param  bucketingId - The user id used to generate the user's bucket key.
 * @param  parentId - The experiment id used to generate the user's bucket key.
 * @param  allocationExperimentIds - The experiment ids that are to be slow rolled via fine grain allocations.
 */
export const findVariationId = (
  bucketingId: string,
  parentId: string,
  allocationExperimentIds: string[] = []
): string | undefined => {
  const bucketKey = getBucketKey(bucketingId, parentId)
  const bucket = generateBucketValue(bucketKey)
  if (allocationExperimentIds.includes(parentId)) {
    return getVariationByAllocation(bucket, EXPERIMENT_ALLOCATIONS[parentId])
  }

  return bucket < MAX_TRAFFIC_VALUE / 2 ? '0' : '1'
}

export const getBucketKey = (bucketingId: string, parentId: string) => {
  return `${bucketingId}${parentId}`
}

export const generateBucketValue = (bucketingKey: string): number => {
  const hashCode = mumurhashv3(bucketingKey, HASH_SEED)
  const ratio = hashCode / MAX_HASH_VALUE
  return Math.floor(ratio * MAX_TRAFFIC_VALUE)
}

/** Used to slow roll experiments by allocating traffic to control or variation.
 * Gives fine grain control for more sensitive experiments, like pricing.
 * Note: experiment model must have "userBased: true" for these allocations to be applied
 * @param  bucket - The user's unique experiment bucket value (a rando combo hash of userid and experiment id).
 * @param  allocations - The traffic allocation.
 */
export const getVariationByAllocation = (bucket: number, allocations: Allocations): string | undefined => {
  for (const allocation of allocations) {
    if (bucket < allocation.rangeEnd) {
      return allocation.id
    }
  }
  return undefined
}
