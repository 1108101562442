import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { EnvironmentService } from '../../../singleton-services/environment.service'
import { take } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { noop } from '../../functions/noop'

export interface IVideoLoggingContext {
  /**
   * Unique GUID for a single live experience (equates to a unique key per browser tab)
   */
  readonly playerId: string

  /**
   * Unique GUID of the video player instance (<video> element)
   * Since 2 - n players can exists on a single screen, we need to determine which player error runs to
   */
  readonly playerInstance: string

  /**
   * EX: http://flosportshls-i.akamaihd.net/hls/live/593115/event/wrp252310_m19/playlist.m3u8?hdnea:st=1516...1d61a99e0563144eb6e
   */
  readonly file: string

  /**
   * URL where video is started
   */
  readonly referer: string

  /**
   * Name of the app making requests. This is to differentiate between multiple apps using the API
   */
  readonly appName: string

  /**
   * App version
   */
  readonly appVersion: string

  /**
   * Primary video player plugin
   */
  readonly pluginName: string

  /**
   * Primary video player plugin version
   */
  readonly pluginVersion: string
}

export interface IVideoLoggingExtendedContext<TValue = any> extends IVideoLoggingContext {
  value: TValue
  message?: string
}

export interface IVideoLoggingUser {
  id: number
  resolution: string
}

export interface IVideoLoggingPreviousStateValueType {
  previous?: string
}

export interface IVideoLoggingVolumeValueType {
  volume: number
}

export interface IVideoLoggingErrorValueType {
  code: number
}

export interface IVideoLoggingMuteValueType {
  mute: boolean
}

export interface IVideoLoggingSeekValueType {
  position: number
  offset: number
}

export interface IVideoLoggingVisualQualityValueType {
  bitrate: number
  width: number
  height: number
  label: string
  automatic: boolean
}

export interface IVideoLoggingPostBody {
  type: LogEventType
  context: IVideoLoggingContext
  user: IVideoLoggingUser
}

enum LogEventType {
  AD = 'ad',
  READY = 'ready',
  PLAY = 'play',
  PAUSE = 'pause',
  BUFFER = 'buffer',
  VISUAL_QUALITY = 'visualquality',
  SEEK = 'seek',
  VOLUME = 'volume',
  MUTE = 'mute',
  ERROR = 'error'
}

const fireAndForget = (obs: Observable<any>) => obs.pipe(take(1)).toPromise().catch(noop)

@Injectable()
export class VideoEventLoggingService {
  private baseUrl = `${this.es.config.endpoints.live}/tokens`

  constructor(private http: HttpClient, private es: EnvironmentService) {}

  private readonly postLog = (tokenId: string, body: IVideoLoggingPostBody) =>
    this.http.post(`${this.baseUrl}/${tokenId}/logs`, body)

  private readonly postFail = (tokenId: string, body: IVideoLoggingPostBody) =>
    this.http.post(`${this.baseUrl}/${tokenId}/failures`, body)

  readonly logReadyEvent = (tokenId: string, context: IVideoLoggingContext, user: IVideoLoggingUser) =>
    fireAndForget(
      this.postLog(tokenId, {
        type: LogEventType.READY,
        context,
        user
      })
    )

  readonly logPlayEvent = (
    tokenId: string,
    context: IVideoLoggingExtendedContext<IVideoLoggingPreviousStateValueType>,
    user: IVideoLoggingUser
  ) =>
    fireAndForget(
      this.postLog(tokenId, {
        type: LogEventType.PLAY,
        context,
        user
      })
    )

  readonly logPauseEvent = (
    tokenId: string,
    context: IVideoLoggingExtendedContext<IVideoLoggingPreviousStateValueType>,
    user: IVideoLoggingUser
  ) =>
    fireAndForget(
      this.postLog(tokenId, {
        type: LogEventType.PAUSE,
        context,
        user
      })
    )

  readonly logBufferEvent = (
    tokenId: string,
    context: IVideoLoggingExtendedContext<IVideoLoggingPreviousStateValueType>,
    user: IVideoLoggingUser
  ) =>
    fireAndForget(
      this.postLog(tokenId, {
        type: LogEventType.BUFFER,
        context,
        user
      })
    )

  readonly logVisualQualityEvent = (
    tokenId: string,
    context: IVideoLoggingExtendedContext<IVideoLoggingVisualQualityValueType>,
    user: IVideoLoggingUser
  ) =>
    fireAndForget(
      this.postLog(tokenId, {
        type: LogEventType.VISUAL_QUALITY,
        context,
        user
      })
    )

  readonly logSeekEvent = (
    tokenId: string,
    context: IVideoLoggingExtendedContext<IVideoLoggingSeekValueType>,
    user: IVideoLoggingUser
  ) =>
    fireAndForget(
      this.postLog(tokenId, {
        type: LogEventType.SEEK,
        context,
        user
      })
    )

  readonly logVolumeEvent = (
    tokenId: string,
    context: IVideoLoggingExtendedContext<IVideoLoggingVolumeValueType>,
    user: IVideoLoggingUser
  ) =>
    fireAndForget(
      this.postLog(tokenId, {
        type: LogEventType.VOLUME,
        context,
        user
      })
    )

  readonly logMuteEvent = (
    tokenId: string,
    context: IVideoLoggingExtendedContext<IVideoLoggingMuteValueType>,
    user: IVideoLoggingUser
  ) =>
    fireAndForget(
      this.postLog(tokenId, {
        type: LogEventType.MUTE,
        context,
        user
      })
    )

  readonly logErrorEvent = (
    tokenId: string,
    context: IVideoLoggingExtendedContext<IVideoLoggingErrorValueType>,
    user: IVideoLoggingUser
  ) =>
    fireAndForget(
      this.postLog(tokenId, {
        type: LogEventType.ERROR,
        context,
        user
      })
    )

  public readonly logAdEvent = (tokenId: string, log: IVideoLoggingPostBody) =>
    fireAndForget(this.postLog(tokenId, log))
  public readonly logVideoErrorEvent = (tokenId: string, log: IVideoLoggingPostBody) =>
    fireAndForget(this.postFail(tokenId, log))
}
