import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { CouponStatusUtility } from '../../coupon/coupon-status.utility'
import { mergeMap, map, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators'
import { combineLatest, iif, Observable, of, Subject, Subscription } from 'rxjs'
import { BatmanDataService } from '../../landing-page/batman-data.service'
import { DeviceService } from '../../../singleton-services/device.service'
import { SiteIds } from '../../../shared/models/site.model'
import { AccountService } from 'src/app/singleton-services/account.service'
import { CheckoutNavigationService } from '../../../shared/services/checkout-navigation.service'
import {
  FEAT_ADCC_FUNNEL_ID,
  FEAT_ADCC_FUNNEL_VARIATION,
  FEAT_PRICING_ARCH_ID,
  FEAT_PRICING_ARCH_VARIATION
} from 'src/app/shared/experimentation/experiment.model'
import { ExperimentationService } from 'src/app/shared/experimentation/experimentation.service'
import { ILandingPageComponent } from '../../landing-page/landing-page.component'

export interface IPartnerLogo {
  mobileLogo: string
  desktopLogo: string
  backgroundImage: {
    'background-image': string
  }
}

@Component({
  selector: 'flo-batman-hero',
  templateUrl: './batman-hero.component.html',
  styleUrls: ['./batman-hero.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BatmanHeroComponent implements OnDestroy {
  public constructor(
    private readonly ar: ActivatedRoute,
    private readonly couponStatusUtility: CouponStatusUtility,
    private readonly batmanDataService: BatmanDataService,
    private readonly deviceService: DeviceService,
    private readonly accountService: AccountService,
    private readonly experimentService: ExperimentationService,
    private readonly checkoutNavigationService: CheckoutNavigationService
  ) {}

  @Input()
  landingPageProps: ILandingPageComponent
  @Input()
  analyticsProps: any

  get isTrackWrestling() {
    return this.landingPageProps.siteSettings.site_id === SiteIds.TRACK_WRESTLING
  }

  get isRacing() {
    return this.landingPageProps.siteSettings.site_id === SiteIds.FLORACING
  }

  get isGrappling() {
    return this.landingPageProps.siteSettings.site_id === SiteIds.FLOGRAPPLING
  }

  private readonly ngOnDestroy$ = new Subject<void>()

  /**
   * CXP-6882: Temporary update the batman content for FloGrappling ADCC event
   * Feature is turned on July 24th 2024 to August 19th 2024
   */
  private readonly isInADCCVariation$: Observable<boolean> = this.experimentService.isInVariation(
    FEAT_ADCC_FUNNEL_ID,
    FEAT_ADCC_FUNNEL_VARIATION
  )
  public readonly heroData$ = combineLatest([this.batmanDataService.heroData$, this.isInADCCVariation$]).pipe(
    map(([heroData, isInADCCVariation]) => {
      return {
        ...heroData,
        secondLine: isInADCCVariation ? 'Stream ADCC 2024. August 17 & 18.' : heroData.secondLine
      }
    }),
    shareReplay(1)
  )

  public readonly signupOrChangeOffer$ = new Subject<void>()

  public readonly showHighlightVideo$: Observable<boolean | undefined> = combineLatest([
    this.heroData$,
    this.deviceService.isDesktop$
  ]).pipe(
    map(([heroData, isDesktop]) => {
      return !!heroData.videoUrl && isDesktop
    })
  )

  public readonly heroSectionStyle$: Observable<Record<string, string>> = this.heroData$.pipe(
    map((heroData): Record<string, string> => ({ 'background-image': `url(${heroData.imageUrl})` })),
    shareReplay()
  )

  public readonly partnerLogos$: Observable<IPartnerLogo> = combineLatest([
    this.heroData$,
    this.deviceService.isDesktop$
  ]).pipe(
    take(1),
    map(([heroData, isDesktop]) => {
      const partnerLogoUri = isDesktop ? heroData.logos : heroData.mobileLogos
      return {
        mobileLogo: heroData.mobileLogos,
        desktopLogo: heroData.logos,
        backgroundImage: { 'background-image': `url(${partnerLogoUri})` }
      }
    })
  )

  private hasNewPricingActiveSub$ = this.experimentService
    .isInVariation(FEAT_PRICING_ARCH_ID, FEAT_PRICING_ARCH_VARIATION, false)
    .pipe(
      mergeMap((isInNewPricingArchitecture: boolean) => {
        return iif(
          () => isInNewPricingArchitecture,
          this.accountService.activeSubscription$.pipe(map(sub => !!sub)),
          of(false)
        )
      })
    )

  public goToSignupOrChangeOffer: Subscription = this.signupOrChangeOffer$
    .pipe(
      tap(() => this.couponStatusUtility.hideCouponErrorSource.next(true)),
      switchMap(() => combineLatest([this.hasNewPricingActiveSub$, this.ar.queryParams])),
      takeUntil(this.ngOnDestroy$)
    )
    .subscribe(([hasActiveNewPricingSub, queryParams]) => {
      this.checkoutNavigationService.navigateAfterLandingPage(
        queryParams,
        hasActiveNewPricingSub,
        this.landingPageProps.liveEventRedirect
      )
    })

  public ngOnDestroy(): void {
    this.ngOnDestroy$.next()
    this.ngOnDestroy$.complete()
  }
}
