import { Inject, Renderer2, Injectable, RendererFactory2 } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { HeadElement } from './head-element.interface'
import { absurd } from 'fp-ts/function'

interface Attributes {
  name: string
  value: string
}
/**
 * Service for adding any arbitrary HTML tag to the HEAD using Renderer2.
 * Currently does not support nested html or <selfClosing /> tags
 * Angular does already [provide a service](https://angular.io/api/platform-browser/Meta)
 * to update meta tags, title, and description in the <head>, which we use in our NodeService
 * class, but it doesnt allow for any other type of HTML to be added to the <head>
 */
@Injectable({
  providedIn: 'root'
})
export class UpdateHeadService {
  private readonly head: HTMLHeadElement
  private readonly renderer: Renderer2

  constructor(
    @Inject(DOCUMENT)
    doc: Document,
    rendererFactory: RendererFactory2
  ) {
    this.head = doc.head
    this.renderer = rendererFactory.createRenderer(null, null)
  }

  /**
   * Add or update element in HTML head
   *
   * Function checks by id if element exists, if it does it removes it first and then recreates it.
   */
  public addElementToHead(element: HeadElement): void {
    // TODO: look into updating element if found, see @link{https://flocasts.atlassian.net/browse/FLO-13087}
    this.removeElement(element.id)
    const queryNode = this.renderer.createElement(element.tag)

    this.renderer.setAttribute(queryNode, 'id', element.id)

    element.attributes.forEach((attr: Attributes) => {
      this.renderer.setAttribute(queryNode, attr.name, attr.value)
    })

    switch (element.tag) {
      case 'script':
        this.renderer.setProperty(queryNode, 'innerText', element.innerText)
        this.renderer.appendChild(this.head, queryNode)
        return

      case 'link':
        this.renderer.appendChild(this.head, queryNode)
        return

      default:
        // This will throw an error if all element types aren't being handled in cases.
        return absurd(element)
    }
  }

  public removeElement(id: string): void {
    const element = this.head.querySelector(`#${id}`)
    if (element !== null && element !== undefined) {
      this.renderer.removeChild(this.head, element)
    }
  }
}
