/**
 * The OpenGraph type for the page content.
 *
 * @remarks
 * Currently, only the article type is included, but there are several other
 * types that we may wish to use. Feel free to extend this union with those
 * types.
 *
 * @see {@link https://ogp.me/#types}
 */
type OgType = 'article' | 'website'

/** @see {@link https://developer.twitter.com/en/docs/twitter-for-websites/cards/guides/getting-started} */
type TwitterCard = 'summary_large_image' | 'summary' | 'app' | 'player'

interface RequiredMeta {
  /**
   * This is the discriminator for the meta. Switch on it to operate on the
   * correct meta type.
   *
   * @see {@link https://www.typescriptlang.org/docs/handbook/unions-and-intersections.html#discriminating-unions}
   */
  type: OgType
  twitterCard: TwitterCard
  title: string
  description: string
  canonicalUrl: string
  image: {
    url: string
    /** @see DEFAULT_OG_IMAGE_HEIGHT */
    height: number
    /** @see DEFAULT_OG_IMAGE_WIDTH */
    width: number
  }
}

export interface ArticleMeta extends RequiredMeta {
  id: number
  type: 'article'
  datePublished: string | null
  modifiedAt: string | null
  authorFirstname: string | null
  authorLastname: string | null
  categories: ReadonlyArray<{ name: string }>
  premium: boolean
  sportName: string | null
  siteName: string | null
  contentTags: string | null
}

export interface WebsiteMeta extends RequiredMeta {
  type: 'website'
  siteName: string | null
}

/**
 * This is a union of different page meta types.
 *
 * To set meta specific for a specific
 */
export type PageMeta = ArticleMeta | WebsiteMeta

/**
 * This is the data-attribute name which is used to manage the meta created by
 * this service.
 *
 * @remarks
 * We have to manage this services meta in several ways to avoid errors. We
 * don't want the video meta to persist into an article, or anywhere else. We
 * also want to be able to clear all set meta from the prior page to set it up
 * for the current page. This attribute allows us to easily perform that targeting.
 */
export const TAG_DATA_ATTRIBUTE = 'data-page-meta-service-tag'

/** An arbitrary value to look for in the dataset attribute. */
export const TAG_DATA_ATTRIBUTE_VALUE = 'true'
