<div *ngIf="config$ | async as config" class="checkout-header-outer-wrapper">
  <div class="checkout-header-wrapper" [ngClass]="{ 'center': isCentered }" data-test="checkout-header-wrapper">
    <a floAnalytics [analyticsProperties]="analyticsProps" routerLink="/" routerLinkActive="active" class="logo ms-sm-3" tabindex="0">
      <flo-svg-logo [siteId]="siteId" [ngClass]="config.ngClass" [showAlt]="showDarkLogo"></flo-svg-logo>
    </a>
    <i
      floAnalytics
      class="activate-logo logo"
      [ngClass]="config.ngClass"
      [attr.title]="config.siteName"
      floSvg="hawk_flosports_logo"
    ></i>
    <p *ngIf="config.showLoginPrompt && !isCentered" class="flo-footnote mb-0"
      >Already have an account?
      <a
        floAnalytics
        [analyticsProperties]="analyticsProps"
        [routerLink]="['../login']"
        name="Go To Login"
        queryParamsHandling="merge"
        routerLinkActive="active"
        data-test="checkout-login"
        class="text-900 text-decoration-underline hover-grey-500"
        >Log In</a
      ></p
    >
    <p *ngIf="config.showJoinNowPrompt && !isCentered" class="flo-footnote mb-0"
      >Don't have an account?
      <a
        floAnalytics
        [analyticsProperties]="analyticsProps"
        [routerLink]="['../create-account']"
        name="Go To Join Now"
        queryParamsHandling="merge"
        routerLinkActive="active"
        class="text-900 text-decoration-underline hover-grey-500"
        >Sign Up</a
      ></p
    >
  </div>
</div>
