import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { AnalyticsModule } from 'src/app/shared/analytics/analytics.module'
import { ErrorComponent } from './error.component'

@NgModule({
  imports: [CommonModule, AnalyticsModule],
  declarations: [ErrorComponent],
  exports: [ErrorComponent]
})
export class ErrorModule {}
