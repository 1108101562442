import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { TransferState } from '@angular/platform-browser'
import { Observable, of } from 'rxjs'
import { catchError, startWith, switchMap } from 'rxjs/operators'
import { LOGGER_SERVICE } from '../logger/logger.config'
import { LoggerService } from '../logger/logger.interface'
import { CLIENT_CACHE_BYPASS_REGEX } from '../shared/services/http-client-transfer-state.interceptor'
import { makeCacheKey } from '../shared/services/http-transfer-state.utility'
import { PlatformService } from './platform.service'
import { VerticalService } from './vertical.service'

/**
 * These params will be different for server vs client,
 * so we must remove to have a common key shared between server and client
 * and a smooth transition between server and client.
 */
export const TRANSFER_STATE_PARAMS_TO_REMOVE = ['tz', 'nav_id']
@Injectable({
  providedIn: 'root'
})
export class CacheDataService {
  constructor(
    private readonly http: HttpClient,
    private readonly transferState: TransferState,
    private readonly platformService: PlatformService,
    private readonly vs: VerticalService,
    @Inject(CLIENT_CACHE_BYPASS_REGEX) private cacheBypassRegex: ReadonlyArray<RegExp>,
    @Inject(LOGGER_SERVICE) private readonly logger: LoggerService
  ) {}

  /**
   * When you have an api call that returns client-specific data on the browser side,
   * wrap it in this function to prevent SSR flash.
   * It handles server/browser logic necessary for a smooth transfer state.
   * @param endpoint The url of the request.
   * @param params The http params of the request.
   * @param isCoreApi Is this a core api endpoint? (If so, this method prepends the api_url from site settings.)
   * @returns The http request observable
   * @example this.cacheDataService
   * .wrapper<WatchResponse>(WATCH_API_BASE_PATH, params, true)
   * // Map after we get data from the cache & handle undefined in data service
   * .pipe(map(x => x?.sections || []))
   */
  public wrapper<T>(endpoint: string, params: HttpParams, isCoreApi: boolean): Observable<T | undefined> {
    // Build the dumb http request, should have no mapping, data mutation, etc.
    const request$ = this.http
      .get<T>(endpoint, {
        params
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.logger.error(err.error, err)
          return of(undefined)
        })
      )
    // If on the server, we just pass along the request as-is
    // Our interceptor will automatically put it in transfer state for us
    if (this.platformService.isServer) return request$

    // Developer validation check since the other half of this logic lives in an interceptor
    const bypassCache = (url: string) => this.cacheBypassRegex.some(exp => exp.test(url))
    if (!bypassCache(endpoint)) {
      // Add the endpoint url to this regex constant so we know to skip the ts cache for this request
      // Otherwise you will never get user-specific data browser-side
      const msg = `Developer Error: Must add ${endpoint} to ${CLIENT_CACHE_BYPASS_REGEX.toString()}.`
      this.logger.error(msg)
      return request$
    }

    // We pipe off site settings bc we need the base url to build the cache key
    return this.vs.siteSettings$.pipe(
      switchMap(x => {
        TRANSFER_STATE_PARAMS_TO_REMOVE.forEach(param => {
          params = params.delete(param)
        })
        const storeKeyParams = params
        // We build the unique cache key by passing the method, url, and params
        const storeKey = makeCacheKey('GET', isCoreApi ? `${x.api_url}/api/${endpoint}` : endpoint, storeKeyParams)
        // We retrieve the cache value with the key
        const cacheValue = this.transferState.get(storeKey, {})

        // Without this check we would return undefined data on client-side navigation
        if (cacheValue.body !== undefined) {
          return request$.pipe(
            // We start the stream with the cached value
            // This prevents the flash
            startWith(cacheValue.body)
          )
        }

        // When navigating client-side we won't always have cached data for this request so we just return it
        return request$
      })
    )
  }
}
