<div
  *ngIf="view$ | async as view"
  #progressBar
  id="progress-bar"
  class="progress-bar-container"
  (mousemove)="mouseHoverPosition($event)"
>
  <div class="scrubber-progress-bar">
    <div class="progress-bar-padding"></div>
    <div class="progress-list">
      <div class="remaining-time"></div>
      <div class="current-time" [ngStyle]="view?.currentPositionStyle"></div>
      <div class="buffer-time" [ngStyle]="view?.bufferStyle"></div>
    </div>
    <div class="hover-time" [ngStyle]="view?.hoverAmount"></div>
    <div class="scrubber-container" [ngStyle]="view?.scrubberStyle">
      <div class="scrubber"></div>
    </div>
    <div class="time-box" [ngStyle]="view?.mousePosition">
      <span class="hover-time-amount">{{ view?.hoverTime | floTime }}</span>
    </div>
  </div>
</div>
