import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HeadlinesComponent } from './headlines.component'
import { AnalyticsModule } from '../../shared/analytics/analytics.module'
import { RouterModule } from '@angular/router'
import { CustomLinkModule } from '../../libs/custom-link/custom-link.directive.module'
import { HeadlineFooterPipe } from './headline-footer.pipe'

@NgModule({
  imports: [CommonModule, AnalyticsModule, RouterModule, CustomLinkModule],
  exports: [HeadlinesComponent],
  declarations: [HeadlinesComponent, HeadlineFooterPipe]
})
export class HeadlinesModule {}
