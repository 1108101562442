import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

@Component({
  selector: 'flo-video-error',
  templateUrl: './video-error.component.html',
  styleUrls: ['./video-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoErrorComponent {
  @Input() svg?: string
  @Input() title?: string
}
