<main class="bg-white color-900">
  <div class="container">
    <div class="row">
      <h1 class="page-header">Whoops! That page does not exist.</h1>
    </div>
    <div class="row">
      <div class="col-sm-12 mt-5">
        <button routerLink class="btn btn-primary">Back to Home Page</button>
      </div>
    </div>
  </div>
</main>
