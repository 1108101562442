import { interval, SchedulerLike } from 'rxjs'
import { filter, map, takeWhile, mapTo } from 'rxjs/operators'
import { isPlatformBrowser } from '@angular/common'
import { getPerformanceMeasurements } from './performance-metrics.utility'
import { FloPerfTrackFn, FloPerfWindow } from './performance-metrics.interface'

export const floPerformanceInitFactory =
  (
    platformId: string,
    trackFn: FloPerfTrackFn,
    intervalMs: number,
    scheduler: SchedulerLike,
    perfWindow?: FloPerfWindow
  ) =>
  () => {
    if (!isPlatformBrowser(platformId)) return
    if (perfWindow === undefined) return
    interval(intervalMs, scheduler)
      .pipe(
        filter(() => typeof perfWindow.performance !== 'undefined'),
        mapTo(perfWindow.performance.timing),
        takeWhile(timing => timing.loadEventEnd <= 0, true),
        filter(timing => timing.loadEventEnd > 0),
        map(getPerformanceMeasurements)
      )
      .subscribe(performance => trackFn(performance))
  }
