<section
  *ngIf="heroData$ | async as heroData"
  class="bg-wrapper"
  data-test="hero-section"
  [ngStyle]="heroSectionStyle$ | async"
  [ngClass]="{'partner-logos-multi-line': isRacing }"
>
  <div
    *ngIf="showHighlightVideo$ | async"
    class="highlight-video-container position-absolute"
    data-test="hero-highlight-video"
  >
    <video
      class="highlight-video"
      autoplay
      muted
      loop
      data-test="highlight-video"
      oncanplay="play()"
      onloadedmetadata="muted = true"
    >
      <source src="{{ heroData?.videoUrl }}" type="video/mp4" />
    </video>
  </div>
  <div class="content-wrapper" data-test="paywall-content-wrapper">
    <div *ngIf="landingPageProps.liveEventHeader">
      <h1
        class="funnel-title title-first-line"
        [innerHTML]="landingPageProps.liveEventHeader.title"
      ></h1>
      <p *ngIf="landingPageProps.liveEventHeader.subtitle" class="mt-3 text-center">
        {{ landingPageProps.liveEventHeader.subtitle }}
      </p>
    </div>

    <ng-container *ngIf="isTrackWrestling && !landingPageProps.liveEventHeader; else genericTitle">
      <h1 class="trackwrestling funnel-title">
        <span class="font-family-heavy fst-italic">GET IT ALL </span><br class="d-block d-md-none">with <br class="d-none d-md-block">Trackwrestling <br class="d-block d-md-none"><span class="font-family-heavy font-italic">+</span> FloWrestling.
      </h1>
      <p class="trackwrestling funnel-title text-center px-1 px-md-0">Get the best of both Trackwrestling
        <span class="font-family-bold fst-italic">AND</span> FloWrestling <br class="d-none d-md-block"> <span class="font-family-bold primary-color-text d-inline-block d-md-none">+</span> Watch
        <span class="font-family-bold fst-italic">ALL</span> live streams <span class="font-family-bold primary-color-text">+</span>
        Access original content, films, and training videos!
      </p>
    </ng-container>

    <ng-template #genericTitle>
      <div *ngIf="!landingPageProps.liveEventHeader">
        <h1
          data-test="title"
          class="funnel-title title-first-line"
          [ngClass]="{ 'grappling-title': isGrappling }"
          >
            {{ heroData.title }}
        </h1>
        <h2 class="funnel-title title-second-line">{{ heroData?.secondLine }}</h2>
      </div>
    </ng-template>

    <ng-container *ngIf="partnerLogos$ | async as partnerLogos">
      <div
        class="partner-logos"
        data-test="partner-logo-desktop"
      >
        <img
          class="no-skeleton"
          *ngIf="partnerLogos?.desktopLogo"
          [lazyLoad]="partnerLogos?.desktopLogo | floImage: 770 | async"
        />
      </div>
      <div
        class="partner-logos-mobile"
        data-test="partner-logo-mobile"
      >
        <img
          class="no-skeleton"
          *ngIf="partnerLogos?.mobileLogo"
          [lazyLoad]="partnerLogos?.mobileLogo | floImage: 320 | async"
        />
      </div>
    </ng-container>
    <button
      floAnalytics
      events="click"
      name="Sign Up"
      class="btn btn-primary btn-lg mb-3 lg-font-btn"
      [ngClass]="heroData.buttonClass"
      data-test="paywall-button"
      (click)="signupOrChangeOffer$.next()"
      [analyticsProperties]="analyticsProps"
      >Sign Up</button
    >
    <p class="flo-footnote">Cancel anytime.</p>
    <p *ngIf="landingPageProps.showLoginPrompt" class="login-prompt flo-footnote"
      >Already have an account?
      <a
        floAnalytics
        [analyticsProperties]="analyticsProps"
        name="Go To Login"
        [routerLink]="['../login']"
        routerLinkActive="active"
        queryParamsHandling="merge"
        class="primary-color-text-hover apply-text-hover"
        >Log In</a
      >
    </p>
  </div>
</section>
