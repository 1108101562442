import { Pipe, PipeTransform } from '@angular/core'

/**
 * Get a value from a map in a template performantly.
 *
 * This is the same as calling map.get in javascript. Calling functions directly
 * in the template is bad for change detection. Maps are used for params,
 * queryParams, and other record-like data where values and keys vary.
 */
@Pipe({
  name: 'mapGet'
})
export class MapGetPipe implements PipeTransform {
  transform<A, B>(value: Map<A, B> | null, key: A): null | B {
    return value?.has(key) ? (value.get(key) as B) : null
  }
}
