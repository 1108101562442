import { AuthService } from '../../singleton-services/auth/auth.service'
import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { tap, filter, switchMap, map } from 'rxjs/operators'
import { combineLatest, Observable, of } from 'rxjs'
import { notNullOrUndefined } from '../functions/type-guards'
import { CheckoutNavigationService } from '../services/checkout-navigation.service'

@Injectable()
export class ThankYouGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private checkoutNavService: CheckoutNavigationService,
    private router: Router
  ) {}

  /**
   * Determines whether a user is entitled to a vertical
   *
   * Update https://flocasts.atlassian.net/browse/CXP-3028: Removed delay. Instead we will check the router state for isValid property.
   * If isValid exists we will use that instead of the entitlements check.
   * It means a user created a subscription successfully and the entitlements check has been done in the payments service.
   * Potentially better solutions will be handled in this ticket: https://flocasts.atlassian.net/browse/CXP-3032
   */
  private hasAccess$ = this.authService.isPremium$.pipe(
    filter(notNullOrUndefined),
    map(isPremium => {
      const routeState = this.router.getCurrentNavigation()?.extras.state
      const isValidUser = routeState && routeState.isValid ? (routeState.isValid as boolean) : null

      return isValidUser ? true : isPremium
    })
  )

  canActivate(ars: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return combineLatest([this.authService.userIdentity$, this.hasAccess$]).pipe(
      switchMap(([user, hasAccess]) => {
        const queryParams = ars.queryParams

        if (!user) {
          return of(false).pipe(tap(() => this.router.navigate(['signup'], { queryParams })))
        }

        if (!hasAccess) {
          return of(false).pipe(tap(() => this.checkoutNavService.navigateToPlans(queryParams)))
        }

        return of(true)
      })
    )
  }
}
