<div
  class="image-wrapper rounded w-100 h-100 position-relative"
  *ngIf="canShowThumbnail"
>
  <div
    class="inner-div rounded position-absolute d-flex justify-content-center"
  >
    <img
      *ngIf="!thumbnailError && imageUrl && imageSize && mobileImageSize"
      [src]="imageUrl | floImage: imageSize:mobileImageSize | async"
      [alt]="altText"
      loading="lazy"
      data-test="thumbnail"
      (error)="thumbnailError = true"
      class="no-skeleton"
    />
  </div>
  <div class="d-flex flex-row img-badge-position position-absolute w-100">
    <flo-play-icon
      [duration]="duration"
      [showPlayIcon]="showPlayIcon"
    ></flo-play-icon>
    <ng-content></ng-content>
  </div>
</div>
