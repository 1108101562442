<nav class="sub-nav-container d-flex align-items-center pe-1" data-test="sub-navigation" aria-label="sub navigation" [ngClass]="[nav.axis | floCssClassSanitize, nav.style | floCssClassSanitize]">
  <div class="d-block d-lg-none spacer"></div>
  <flo-link
    [id]="item.id"
    *ngFor="let item of navItems; trackBy: trackById;"
    class="h-100"
    [link]="item"
    [tagName]="tagName"
    (click)="handleLinkClick(item)"
  >
  </flo-link>
</nav>
