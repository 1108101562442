import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { PlansComponent } from './plans.component'
import { FunnelSharedModule } from '../shared/funnel-shared.module'
import { SharedModule } from 'src/app/shared/shared.module'
import { OfferSelectionCardComponent } from './offers/offer-selection/offer-selection-card.component'
import { OffersComponent } from './offers/offers.component'
import { FloPipeModule } from 'src/app/shared/pipes/flo-pipe.module'
import { FloCssClassSanitizePipeModule } from 'src/app/libs/_pipes/css-class-sanitize/css-class-sanitize.pipe.module'
import { PlansRoutingModule } from './plans-routing.module'
import { StepperWrapperModule } from '../../shared/stepper-wrapper/stepper-wrapper.module'

@NgModule({
  declarations: [PlansComponent, OfferSelectionCardComponent, OffersComponent],
  imports: [
    CommonModule,
    SharedModule,
    FunnelSharedModule,
    PlansRoutingModule,
    FloPipeModule,
    FloCssClassSanitizePipeModule,
    StepperWrapperModule
  ],
  exports: [OfferSelectionCardComponent, OffersComponent, PlansComponent]
})
export class PlansModule {}
