<section class="standings-list__section p-0 p-sm-3 p-xl-4 col-md-12 col-lg-8">
  <div class="p-xl-1">
    <!--Content Header-->
    <div class="standings-list__content-header mb-4 skeleton"></div>
    <!--Small Header-->
    <div class="px-3 px-sm-0 mb-4">
      <div class="d-flex align-items-center">
        <h1 class="h1-skeleton mb-1" style="width: 220px"></h1>
      </div>
      <h1 class="h1-skeleton" style="width: 185px;"></h1>
    </div>
    <!--Filters & Search-->
    <div class="d-flex flex-wrap flex-md-nowrap mb-4 mt-0">
      <div class="w-100 w-md-auto px-3 px-sm-0 mb-25 flex-grow-1">
        <h1 class="h1-skeleton skeleton-input d-flex align-items-center"></h1>
      </div>
      <div class="ps-md-3 pe-0">
        <h1 class="h1-skeleton d-flex flex-row px-3 px-sm-0" style="min-height: 30px; width: 250px;"></h1>
      </div>
    </div>
    <!--Tables-->
    <!--Callout-->
    <div class="px-3 px-sm-0 mb-4">
      <h1 class="h1-skeleton w-100"></h1>
    </div>
    <div class="pb-4 w-100 skeleton" style="height: 1200px;"></div>
  </div>
</section>