import { Component, ChangeDetectionStrategy, OnDestroy, OnInit } from '@angular/core'
import { HomeAnalyticsService } from './services/home-analytics.service'
import { HomeDataService } from './services/home-data.service'
import { GeoBlockingService } from '../singleton-services/geo-blocking.service'
import { PlatformService } from '../singleton-services/platform.service'
import { combineLatest, forkJoin, Observable, Subject } from 'rxjs'
import { distinctUntilChanged, filter, map, shareReplay, switchMap, takeUntil } from 'rxjs/operators'
import { isEventCardSection, isMessageModelSection, notNullOrUndefined } from '../shared/functions/type-guards'
import { mapLiveEventMetadataToPossibleGeoBlockedIds } from './home.utility'
import { GeoIPResponseItem } from '../shared/geo-ip/geo-ip.service'
import { AdComponentInputs } from '../shared/ads/ad/ad-component.interface'
import { AdConfig, AdTargeting, AdTypes, EqAdTargeting } from '../shared/ads/advertising-service/ad-config'
import {
  AD_ADHESION_SIZE,
  AD_INTERSTITIAL_SIZE,
  AD_LEADERBOARD_LARGE_SIZE,
  AD_RECTANGLE_LARGE_SIZE,
  AD_RECTANGLE_SMALL_SIZE,
  AD_SKIN_SIZE
} from '../shared/ads/ads.utility'
import { trackById } from '../shared/utility-functions/track-by-id.utility'
import { VerticalService } from '../singleton-services/vertical.service'
import { ExperimentationService } from '../shared/experimentation/experimentation.service'
import {
  EXP_FLOWRESTLING_EVENT_HUB_BRACKETS_ID,
  EXP_FLOWRESTLING_EVENT_HUB_BRACKETS_VARIATION,
  EXP_HOMEPAGE_CREATE_FREE_ACCOUNT_ID,
  EXP_HOMEPAGE_CREATE_FREE_ACCOUNT_VARIATION
} from '../shared/experimentation/experiment.model'
import { AuthService } from '../singleton-services/auth/auth.service'
import { SeoService } from '../singleton-services/seo/seo.service'

@Component({
  selector: 'flo-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent implements OnInit, OnDestroy {
  constructor(
    private readonly analytics: HomeAnalyticsService,
    private readonly homeDataService: HomeDataService,
    private readonly geoBlockingService: GeoBlockingService,
    private readonly platformService: PlatformService,
    private readonly verticalService: VerticalService,
    private readonly es: ExperimentationService,
    private readonly authService: AuthService,
    private readonly seoService: SeoService
  ) {
    this.analytics.sendPageCall()
  }

  private ngOnDestroy$ = new Subject<void>()

  public isInVariationToCreateFreeAccount$ = this.es
    .isInVariation(EXP_HOMEPAGE_CREATE_FREE_ACCOUNT_ID, EXP_HOMEPAGE_CREATE_FREE_ACCOUNT_VARIATION)
    .pipe(takeUntil(this.ngOnDestroy$), shareReplay(1))

  public isInEventHubBracketsVariation$ = this.es.isInVariation(
    EXP_FLOWRESTLING_EVENT_HUB_BRACKETS_ID,
    EXP_FLOWRESTLING_EVENT_HUB_BRACKETS_VARIATION,
    true
  )

  public sections$ = combineLatest([this.verticalService.siteId$, this.isInEventHubBracketsVariation$]).pipe(
    filter(([siteId, _isInEventHubBracketsVariation]) => notNullOrUndefined(siteId)),
    switchMap(([siteId, isInEventHubBffVariation]) =>
      this.homeDataService.getHomeSections(Number(siteId), isInEventHubBffVariation)
    ),
    filter(notNullOrUndefined),
    map(experience => experience.sections),
    shareReplay(1)
  )
  public geoBlockedIds$: Observable<ReadonlyArray<number>> = this.sections$.pipe(
    filter(_ => this.platformService.isBrowser),
    map(sections => sections.find(isEventCardSection)),
    filter(notNullOrUndefined),
    map(mapLiveEventMetadataToPossibleGeoBlockedIds),
    map(ids => (ids.length > 0 ? ids : [])),
    switchMap(this.geoBlockingService.getGeoBlockedLiveEvents),
    map((geoIpResponseItem: GeoIPResponseItem[]) => geoIpResponseItem.map(res => res.id)),
    takeUntil(this.ngOnDestroy$),
    shareReplay(1)
  )

  public trackById = trackById

  public errorSection$ = this.sections$.pipe(
    map(sections => sections.find(isMessageModelSection)),
    takeUntil(this.ngOnDestroy$),
    shareReplay(1)
  )

  public pinnedLinks$ = this.sections$.pipe(
    map(sections => sections?.find(section => section.id === 'pinned-links')),
    filter(notNullOrUndefined)
  )

  public continueWatchingVideos$ = this.sections$.pipe(
    map(sections => sections?.find(section => section.id === 'continue-watching')),
    filter(notNullOrUndefined)
  )

  public hero$ = this.sections$.pipe(
    map(sections => sections?.find(section => section.id === 'main-hero')),
    filter(notNullOrUndefined)
  )

  public headlines$ = this.sections$.pipe(
    map(sections => sections?.find(section => section.id === 'headlines')),
    filter(notNullOrUndefined)
  )

  public bannerSection$ = this.sections$.pipe(
    map(sections => sections?.find(section => section.id === 'main-banners')),
    filter(notNullOrUndefined)
  )

  // Ads
  public AdComponent = import('../shared/ads/ad/ad.component').then(m => m.AdComponent)

  public readonly adConfigMap = new Map<AdTypes, AdComponentInputs>()

  private readonly adTargeting$: Observable<AdTargeting> = this.authService.isPremium$.pipe(
    map(isPremium => ({
      isPremium
    })),
    distinctUntilChanged(EqAdTargeting.equals),
    shareReplay(1)
  )

  public readonly adConfigs$: Observable<Map<AdTypes, AdComponentInputs>> = this.adTargeting$.pipe(
    map(targeting => {
      this.adConfigMap.set('skin', {
        config: {
          adConfig: AdConfig(`homepage/atf`, AD_SKIN_SIZE, 'ad-skin', targeting)
        }
      })

      this.adConfigMap.set('leaderboard-large', {
        config: {
          adConfig: AdConfig(`homepage/728x90_flex`, AD_LEADERBOARD_LARGE_SIZE, 'leaderboard-large', targeting)
        }
      })

      this.adConfigMap.set('rectangle-small', {
        config: {
          adConfig: AdConfig(`homepage/300x250_1`, AD_RECTANGLE_SMALL_SIZE, 'rectangle-small', targeting)
        }
      })

      this.adConfigMap.set('rectangle-mobile', {
        config: {
          adConfig: AdConfig('homepage/300x250_2', AD_RECTANGLE_SMALL_SIZE, 'rectangle-mobile', targeting)
        }
      })

      this.adConfigMap.set('rectangle-large-sticky', {
        config: {
          adConfig: AdConfig(`homepage/300x250_2`, AD_RECTANGLE_LARGE_SIZE, 'rectangle-large-sticky', targeting),
          additionalOpts: { maxRefreshTime: 30000 }
        }
      })

      this.adConfigMap.set('adhesion', {
        config: {
          adConfig: AdConfig(`homepage/adhesion`, AD_ADHESION_SIZE, 'adhesion', targeting),
          additionalOpts: { maxRefreshTime: 30000 }
        }
      })

      this.adConfigMap.set('interstitial', {
        config: {
          adConfig: AdConfig(`homepage/interstitial`, AD_INTERSTITIAL_SIZE, 'interstitial', targeting)
        }
      })

      return this.adConfigMap
    })
  )

  ngOnInit(): void {
    forkJoin({
      name: this.verticalService.siteName$,
      url: this.verticalService.coreSiteUrl$
    })
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(site => {
        this.seoService.setHomeSchema(site.name, site.url)
      })
  }

  ngOnDestroy(): void {
    this.seoService.removeHomeSchema()
    this.ngOnDestroy$.next()
    this.ngOnDestroy$.complete()
  }
}
