<div
  role="navigation"
  aria-label="Pagination Navigation"
  class="d-flex flex-column justify-content-center w-100">
  <h6 class="pagination-title subhead color-600 align-self-center pb-1" data-test="pagination-title">{{ pagination.title }}</h6>
  <ul class="pagination d-flex flex-row justify-content-center" data-test="pagination">
    <li
      *ngFor="let link of pagination.links; let last = last; trackBy: trackById"
      class="page-item btn-icon icon-tertiary me-25"
      [ngClass]="{'me-0': last, 'active': link.state === 'selected', 'disabled': link.state === 'disabled'}"
    >
      <a [floCustomLink]="link.url"
         floAnalytics
         eventName="Button Clicked"
         [analyticsProperties]="link.analytics"
         class="h-100 d-flex flex-column text-center justify-content-center">
          <ng-container *ngIf="link.asset; else title">
            <flo-svg-icon [icon]="link.asset.style" width="1.5em" class="pagination-icon svg-vertical-align-bottom"></flo-svg-icon>
          </ng-container>
          <ng-template #title>
            {{ link.title }}
          </ng-template>
      </a>
    </li>
  </ul>
</div>
