import { NgModule, ModuleWithProviders } from '@angular/core'

import { EnvironmentService } from '../../singleton-services/environment.service'
import { LOGGER_SERVICE } from 'src/app/logger/logger.config'
import { BrowserSportDataService } from './browser-sport-data.service'
import { SPORT_DATA_FIRESTORE_TOKEN, SPORT_DATA_SERVICE } from './sport-data.config'
import { TransferState } from '@angular/platform-browser'
import { initializeApp } from '@angular/fire/app/'
import { getFirestore } from '@angular/fire/firestore'

export function createFirestore(env: EnvironmentService) {
  const app = initializeApp(env.config.sportDatabase)
  return getFirestore(app)
}
@NgModule({
  providers: [
    {
      provide: SPORT_DATA_FIRESTORE_TOKEN,
      useFactory: createFirestore,
      deps: [EnvironmentService]
    },
    {
      provide: SPORT_DATA_SERVICE,
      useClass: BrowserSportDataService,
      deps: [SPORT_DATA_FIRESTORE_TOKEN, LOGGER_SERVICE, TransferState, EnvironmentService]
    }
  ]
})
export class BrowserSportDataModule {
  static forRoot(): ModuleWithProviders<BrowserSportDataModule> {
    return {
      ngModule: BrowserSportDataModule
    }
  }
}
