import { Observable } from 'rxjs'
import { Event, NavigationEnd, ActivatedRoute } from '@angular/router'
import { filter, map, mergeMap } from 'rxjs/operators'

export const filterDeepRouterDataByPredicate = (ar: ActivatedRoute) => <T>(
  predicate: (ar: ActivatedRoute) => Observable<T>
) => (source: Observable<Event>) =>
  source.pipe(
    filter<NavigationEnd>(event => event instanceof NavigationEnd),
    map(_ => ar),
    map(route => {
      while (route.firstChild) {
        // tslint:disable-next-line:no-parameter-reassignment
        route = route.firstChild
      }
      return route
    }),
    filter(route => route.outlet === 'primary'),
    mergeMap(predicate)
  )
