<div
  *ngIf="item"
  class="xl-content-card d-flex flex-column h-100"
>
  <a
    floAnalytics
    [analyticsProperties]="item.action?.analytics"
    class="xl-content-card-image image w-100 h-auto cursor-pointer"
    [floCustomLink]="item.action?.url"
  >
    <div class="d-block play-icon-badge">
      <flo-image-thumbnail [imageUrl]="item.previewImage?.url"
                           [altText]="item.title"
                           imageSize="1120"
                           [showPlayIcon]="item.showPlayIcon">
        <div *ngIf="item.label1 !== null" class="d-flex flex-row w-100">
          <div data-test="xl-content-card-badge" class="img-overlay-badge caption">{{ item.label1 }}</div>
        </div>
      </flo-image-thumbnail>
    </div>
  </a>
  <div class="xl-content-card-text mt-3">
    <div class="xl-content-card-text__details">
      <a
        *ngIf="item.subtitle1Action !== null"
        floAnalytics
        [analyticsProperties]="item.action?.analytics"
        elementName="Drill Through Link"
        class="xl-content-card-text__details--drill-through d-none d-sm-block drill-through mb-2"
        [floCustomLink]="item.subtitle1Action?.url"
      >
        {{ item.subtitle1Action.title }}
      </a>
      <a
        floAnalytics
        [analyticsProperties]="item.action?.analytics"
        [floCustomLink]="item.action?.url"
        data-test="xl-content-card-title"
        class="xl-content-card-text__details--title d-block cursor-pointer"
      >
        <h3 class="xl-content-card-text__details--title heavy h3 text-line-clamp-2 hover-text-primary mb-2">{{ item.title }}</h3>
      </a>
      <div *ngIf="item.description !== null" class="d-none d-sm-block">
        <p  class="xl-content-card__details--description color-700 subhead mb-2 text-line-clamp-2">
          {{ item.description }}
        </p>
      </div>
      <p *ngIf="item.footer !== null" class="footnote color-500 mb-0">{{ item.footer | floRelativeDate }}</p>
    </div>
  </div>
</div>
