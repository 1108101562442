import { Inject, Injectable, InjectionToken } from '@angular/core'
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Router } from '@angular/router'
import { throwError } from 'rxjs'
import { ServerResponseService } from './server-response.service'
import { catchError } from 'rxjs/operators'

export const NOT_FOUND_REDIRECT_IGNORES = new InjectionToken<ReadonlyArray<RegExp>>('app.http.ignore.404')

@Injectable()
export class HttpNotFoundInterceptor implements HttpInterceptor {
  constructor(
    @Inject(NOT_FOUND_REDIRECT_IGNORES) private ignored: ReadonlyArray<RegExp>,
    private router: Router,
    private srs: ServerResponseService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    return next.handle(req).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 404 && !this.ignored.some(a => new RegExp(a).test(req.url))) {
            this.srs.setNotFound()
            this.router.navigate(['**'], { skipLocationChange: true })
          }
        }
        return throwError(err)
      })
    )
  }
}
