import { Component, ChangeDetectionStrategy, Input } from '@angular/core'
import { trackById } from 'src/app/shared/utility-functions/track-by-id.utility'
import type { NavModel } from '@flocasts/experience-service-types'

@Component({
  selector: 'flo-titled-list',
  templateUrl: './titled-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TitledListComponent {
  /*
   * List of items to be iterated over for display with a title
   * Can support other item types in future
   */
  @Input()
  listItems: NavModel

  // Track by to reduce DOM repainting as new options are dynamically loaded
  readonly trackById = trackById
}
