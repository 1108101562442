import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

@Component({
  selector: 'flo-value-prop',
  templateUrl: './value-prop.component.html',
  styleUrls: ['./value-prop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValuePropComponent {
  @Input()
  proposition: string
  @Input()
  label: string
  @Input()
  svgLabel = true
  @Input()
  svgWidth = '24'
}
