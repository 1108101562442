<section class="stepper-container">
  <div class="icons">
    <div class="icons-row" *ngFor="let step of steps; let i = index; trackBy: trackById">
      
      <!-- Hide the first separator -->
      <div class="separator" [ngClass]="{ 'd-none': i === 0, 'bg-primary': (step.isCompleted || step.isCurrent) && i !== 0 }"></div>

      <!-- Completed steps -->
      <div *ngIf="step.isCompleted" class="icon-item completed" data-test="completed-step">
        <flo-svg-icon
          class="svg-wrapper checkmark svg-vertical-align-middle"
          icon="icon-check-mark"
          width="20px">
        </flo-svg-icon>
      </div>

      <!-- Current or Incomplete steps -->
      <ng-container *ngIf="!step.isCompleted">
        <div class="icon-item" [ngClass]="{ 'current': step.isCurrent }" data-test="incomplete-step">{{ step.id }}</div>
      </ng-container>
      
      <!-- Hide the last separator -->
      <div class="separator" [ngClass]="{ 'd-none': i === steps.length - 1, 'bg-primary': step.isCompleted }"></div>
    </div>
  </div>
</section>