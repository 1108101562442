import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

/** Mobile images will automatically be limited to this width for performance. */
const MOBILE_IMAGE_WIDTH_LIMIT = 500

@Component({
  selector: 'flo-image-thumbnail',
  styleUrls: ['./image-thumbnail.component.scss'],
  templateUrl: './image-thumbnail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageThumbnailComponent {
  /**
   * The error state of the thumbnail image.
   *
   * @remarks
   * If true, a default grey background will be shown.
   */
  public thumbnailError = false

  /** These properties must be present to show the thumbnail. */
  public get canShowThumbnail() {
    return this.imageUrl != null && this.altText != null && this.imageSize != null
  }

  /** We limit the image size to 400 on mobile to ensure good performance. */
  public get mobileImageSize() {
    return this.mobileSize ? this.mobileSize : this.setImageSize()
  }

  public setImageSize(): number {
    if (this.imageSize) {
      return this.imageSize > MOBILE_IMAGE_WIDTH_LIMIT ? MOBILE_IMAGE_WIDTH_LIMIT : this.imageSize
    } else {
      return MOBILE_IMAGE_WIDTH_LIMIT
    }
  }

  /** Required. The URL from which to fetch the image */
  @Input() imageUrl: string | undefined | null

  /** Required. The display width of the thumbnail. */
  @Input() imageSize: number | undefined | null

  /** Required. Alternate text for hover and screen readers. */
  @Input() altText: string | undefined | null

  /**
   * @deprecated
   * Backwards compatibility for pages that hit Core API
   * If provided, will display the duration of the content.
   */
  @Input() duration?: number | null

  /** This will show the vid_play SVG alongside duration */
  @Input() showPlayIcon = false

  @Input() mobileSize?: number | undefined | null
}
