import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core'
import { EventCardModel } from '@flocasts/experience-service-types'
import { trackById } from 'src/app/shared/utility-functions/track-by-id.utility'
import { BtnClass, calculateBtnCss } from '../event-card.utility'

@Component({
  selector: 'flo-event-card-horizontal',
  templateUrl: './event-card-horizontal.component.html',
  styleUrls: ['./event-card-horizontal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventCardHorizontalComponent implements OnInit {
  /**
   * Input for component. Directly takes experience-service response
   * Each item in the results section items array
   */
  @Input()
  public item: EventCardModel

  @Input()
  geoBlockedIds: ReadonlyArray<number> | null

  public showCta1: boolean
  public showCta2: boolean

  public cta1Class: BtnClass
  public cta2Class: BtnClass

  public isCta1GeoBlockableId?: number
  public isCta2GeoBlockableId?: number

  public trackById = trackById

  ngOnInit(): void {
    this.cta1Class = calculateBtnCss(this.item.cta1?.style || '')
    this.cta2Class = calculateBtnCss(this.item.cta2?.style || '')

    this.showCta1 = !!(this.item.cta1 && this.item.cta1.url)
    this.showCta2 = !!(this.item.cta2 && this.item.cta2.url)

    const geoBlockableCtas = ['Watch', 'Replays']
    this.isCta1GeoBlockableId =
      this.item.cta1?.title && geoBlockableCtas.includes(this.item.cta1.title)
        ? this.item.liveEventMetadata?.liveEventId
        : undefined
    this.isCta2GeoBlockableId =
      this.item.cta2?.title && geoBlockableCtas.includes(this.item.cta2.title)
        ? this.item.liveEventMetadata?.liveEventId
        : undefined
  }
}
