import { HttpClient, HttpParams } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { OffersListPartialModel, VideoPlayerCardPartial, ContainerPartial } from '@flocasts/experience-service-types'
import { Observable, catchError, of, map } from 'rxjs'
import {
  EXPERIENCE_API_EVENTS_LIVE_UPCOMING_PARTIAL_PATH,
  EXPERIENCE_API_OFFERS_BASE_PATH,
  EXPERIENCE_API_VERSION,
  EXPERIENCE_API_VIDEO_PARTIAL_PATH
} from 'src/app/app.config'
import { LOGGER_SERVICE } from 'src/app/logger/logger.config'
import { LoggerService } from 'src/app/logger/logger.interface'
import { SiteIds } from 'src/app/shared/models/site.model'
import { ICoupon } from 'src/app/shared/services/coupon.service'
import { CacheDataService } from '../cache-data.service'
import { EXPERIENCE_API_SUBSCRIPTIONS_PARTIAL_PATH } from '../../app.config'

/**
 * For requesting data from /partials endpoints in the BFF.
 */
@Injectable({
  providedIn: 'root'
})
export class PartialsDataService {
  constructor(
    @Inject(LOGGER_SERVICE) private readonly logger: LoggerService,
    private readonly http: HttpClient,
    private readonly cacheDataService: CacheDataService
  ) {}

  public getVideoPlayerCardPartial(nodeId: number): Observable<VideoPlayerCardPartial | undefined> {
    const params = new HttpParams({
      fromObject: {
        version: EXPERIENCE_API_VERSION,
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/Los_Angeles'
      }
    })

    return this.http.get<VideoPlayerCardPartial>(`${EXPERIENCE_API_VIDEO_PARTIAL_PATH}/${nodeId}`, { params }).pipe(
      catchError(err => {
        this.logger.error(err.message, err.error)
        return of(undefined)
      })
    )
  }

  /**
   * Fetch offers for the funnel from Experience Service's offers partial endpoint
   */
  public getOffersListPartial(
    siteId: number | undefined,
    coupon?: ICoupon,
    specialPricing?: any
  ): Observable<OffersListPartialModel | undefined> {
    let params = new HttpParams({
      fromObject: {
        site_id: siteId || SiteIds.FLODOGS,
        version: EXPERIENCE_API_VERSION
      }
    })

    // set coupon query param if coupon has a value
    params = coupon?.id ? params.set('coupon', coupon.id) : params

    // set special pricing query param if cookie exists
    params = specialPricing ? params.set('sp', specialPricing) : params

    const endpoint = EXPERIENCE_API_OFFERS_BASE_PATH

    return this.cacheDataService.wrapper<OffersListPartialModel | undefined>(endpoint, params, true).pipe(
      catchError(err => {
        this.logger.error(err.message, err.error)
        return of(undefined)
      })
    )
  }

  /**
   * Partial endpoint providing user's with a view of their active and inactive subscriptions
   *
   * @see {@link https://experience-service.stag.flokubernetes.com/docs#/Partials/PartialsController_getSubscriptionsExperience}
   */
  public getSubscriptionsListPartial(onlyActive: boolean = false): Observable<ContainerPartial | undefined> {
    const params = new HttpParams({
      fromObject: {
        version: EXPERIENCE_API_VERSION,
        onlyActive
      }
    })

    return this.http.get<ContainerPartial>(EXPERIENCE_API_SUBSCRIPTIONS_PARTIAL_PATH, { params }).pipe(
      catchError(err => {
        this.logger.error('Error fetching user subscriptions partial from BFF', err)
        throw err
      })
    )
  }

  public getLiveAndUpcomingEventsPartial(): Observable<ContainerPartial | undefined> {
    const params = new HttpParams({
      fromObject: {
        version: EXPERIENCE_API_VERSION,
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/Los_Angeles',
        limit: 20,
        offset: 0
      }
    })

    return this.http.get<ContainerPartial>(EXPERIENCE_API_EVENTS_LIVE_UPCOMING_PARTIAL_PATH, { params }).pipe(
      catchError(err => {
        this.logger.error('Error fetching live and upcoming events partial from BFF', err)
        throw err
      })
    )
  }

  public hasPastDueSubscription$: Observable<boolean> = this.getSubscriptionsListPartial().pipe(
    map(subData => subData?.data.items.some(subscription => subscription.id === 'past-due-subscription')),
    map(hasPastDueSub => !!hasPastDueSub),
    catchError(() => {
      return of(false)
    })
  )
}
