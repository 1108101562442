import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { LegendModel } from '@flocasts/experience-service-types'
import { trackById } from 'src/app/shared/utility-functions/track-by-id.utility'

@Component({
  selector: 'flo-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegendComponent {
  @Input() legend: LegendModel

  public trackById = trackById
}
