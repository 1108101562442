import { NgModule } from '@angular/core'
import { SvgModule } from '../svg/svg.module'
import { CommonModule } from '@angular/common'
import { StepperWrapperComponent } from './stepper-wrapper.component'
import { StepperModule } from '../stepper/stepper.module'

@NgModule({
  imports: [CommonModule, SvgModule, StepperModule],
  declarations: [StepperWrapperComponent],
  exports: [StepperWrapperComponent]
})
export class StepperWrapperModule {}
