import { HttpClient } from '@angular/common/http'
import { UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms'
import { Injectable } from '@angular/core'
import { catchError, debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators'
import { Observable, of } from 'rxjs'
import { EnvironmentService } from '../../singleton-services/environment.service'
import { PendingSubscriptionResponse, PendingSubscriptionError } from '../../funnel/pay/payments.interfaces'
import { isPendingSubscriptionResponse } from '../functions/type-guards'

@Injectable()
export class RedeemValidator {
  public static readonly DEBOUNCE_TIME_SECONDS = 1000
  public static readonly REDEEM_CODE_REGEX = /[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}$/i

  constructor(private readonly http: HttpClient, private readonly environmentService: EnvironmentService) {}

  public validateRedeemCodeFormat = (redeemCode: string): boolean => {
    return redeemCode.match(RedeemValidator.REDEEM_CODE_REGEX) ? true : false
  }

  public validateRedeemCode(): ValidatorFn {
    return (control: UntypedFormControl): Observable<ValidationErrors | null> => {
      if (control && control.value) {
        if (!this.validateRedeemCodeFormat(control.value)) {
          return of({
            invalid_format_redeem_code: true
          })
        }
        const url = `${this.environmentService.config.endpoints.payments}/gift-subscription/${control.value}/validate`

        return of(control.value).pipe(
          debounceTime(RedeemValidator.DEBOUNCE_TIME_SECONDS),
          distinctUntilChanged(),
          switchMap(() => {
            return this.http.get<PendingSubscriptionResponse | PendingSubscriptionError>(url)
          }),
          map(response =>
            isPendingSubscriptionResponse(response) ? null : { invalid_redeem_code: { customText: response.message } }
          ),
          catchError(err => {
            return of({
              invalid_redeem_code: {
                customText:
                  err.error.message ||
                  'Invalid redemption code. If you believe you have received this message in error, please contact customer support'
              }
            })
          })
        )
      }
      return of(null)
    }
  }
}
