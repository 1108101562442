import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { CardModel } from '@flocasts/experience-service-types'

/**
 * This is called "static" content by design, because its intent is to display
 * a ranking, video, article or result. (Our less data-heavy content types)
 */
@Component({
  selector: 'flo-static-content-card',
  templateUrl: './static-content-card.component.html',
  styleUrls: ['./static-content-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StaticContentCardComponent {
  @Input() card: CardModel
  @Input() imagePosition: 'left' | 'right' = 'left'
}
