import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ValuePropComponent } from './value-prop/value-prop.component'
import { ResponsiveImageModule } from '../../shared/responsive-image/responsive-image.module'
import { FunnelCheckoutHeaderComponent } from './funnel-checkout-header/funnel-checkout-header.component'
import { AnalyticsModule } from '../../shared/analytics/analytics.module'
import { RouterModule } from '@angular/router'
import { FacebookAuthButtonComponent } from '../../libs/facebook-auth-button/facebook-auth-button.component'
import { FloPipeModule } from '../../shared/pipes/flo-pipe.module'
import { GoogleAuthButtonComponent } from 'src/app/libs/google-auth-button/google-auth-button.component'
import { SvgModule } from '../../shared/svg/svg.module'
import { SimpleHeaderComponent } from './simple-header/simple-header.component'

@NgModule({
  imports: [CommonModule, ResponsiveImageModule, AnalyticsModule, RouterModule, FloPipeModule, SvgModule],
  declarations: [
    ValuePropComponent,
    FacebookAuthButtonComponent,
    GoogleAuthButtonComponent,
    FunnelCheckoutHeaderComponent,
    SimpleHeaderComponent
  ],
  exports: [
    ValuePropComponent,
    FunnelCheckoutHeaderComponent,
    FacebookAuthButtonComponent,
    GoogleAuthButtonComponent,
    SimpleHeaderComponent
  ]
})
export class FunnelSharedModule {}
