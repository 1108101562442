import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core'
import { trackById } from '../../shared/utility-functions/track-by-id.utility'
import { EventTickerViewModel } from '@flocasts/experience-service-types'
import SwiperCore, { FreeMode, Navigation } from 'swiper'
import type { SwiperOptions } from 'swiper/types'
import { getSwiperConfig } from './event-ticker.utility'
import { assetIsIconModel } from 'src/app/shared/functions/type-guards'

SwiperCore.use([Navigation, FreeMode])

@Component({
  selector: 'flo-event-ticker',
  templateUrl: './event-ticker.component.html',
  styleUrls: ['./event-ticker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventTickerComponent implements OnInit {
  /**
   * This input is needed when multiple instances of this component exist in the DOM.
   * Swiperjs needs unique ids.
   */
  @Input()
  swiperId: string

  @Input() eventTickerViewModel: EventTickerViewModel
  forwardIcon: string
  backwardIcon: string
  swiperForwardId: string
  swiperBackwardId: string
  swiperConfig: SwiperOptions

  ngOnInit(): void {
    const forwardAsset = this.eventTickerViewModel?.forward?.asset
    const backwardAsset = this.eventTickerViewModel?.backward?.asset
    this.forwardIcon = assetIsIconModel(forwardAsset) ? forwardAsset.style : 'icon-chevron-right'
    this.backwardIcon = assetIsIconModel(backwardAsset) ? backwardAsset.style : 'icon-chevron-left'
    this.swiperForwardId = `${this.swiperId}-${this.forwardIcon}`
    this.swiperBackwardId = `${this.swiperId}-${this.backwardIcon}`
    this.swiperConfig = getSwiperConfig(this.swiperForwardId, this.swiperBackwardId)
  }

  public trackById = trackById
}
