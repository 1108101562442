import { NgModule } from '@angular/core'
import { FloDatePipe } from './flodate.pipe'
import { DatePipe } from '@angular/common'

@NgModule({
  declarations: [FloDatePipe],
  providers: [FloDatePipe, DatePipe],
  exports: [FloDatePipe]
})
export class DatePipeModule {}
