import { getQueryParam, stripQueryParams } from '../url/url.functions'

/*
 * Function that returns true if the route has changed in such a way that we want to re-fetch our navigation
 */
export const shouldFetchNav = (prevUrl: string, url: string) => {
  // Get path (stripping off any query params from url) and check if it has changed
  // Or see if there is a nav_id query param and that has changed
  // If neither has changed
  // Then our router event was just unrelated query params changing and we don't need to re-fetch nav
  const prevNavId = getQueryParam(prevUrl, 'nav_id')
  const navId = getQueryParam(url, 'nav_id')
  const eitherHasNavId = !!prevNavId || !!navId
  const hasNavIdChanged = eitherHasNavId && prevNavId !== navId
  const hasPathChanged = stripQueryParams(prevUrl) !== stripQueryParams(url)
  return hasNavIdChanged || hasPathChanged
}
