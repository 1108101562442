import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { SvgLogoComponent } from './logo/svg-logo.component'
import { SvgIconComponent } from './svg-icon.component'

@NgModule({
  imports: [CommonModule],
  declarations: [SvgIconComponent, SvgLogoComponent],
  exports: [SvgIconComponent, SvgLogoComponent]
})
export class SvgModule {}
