import { IAnalyticsNode } from '../../models/node.model'

/**
 * Build out track call properties
 * @param clientProps, props supplied by Segment Service and Client, ex: browser
 * @param nodeProps, props around node_id
 * @param propsWithoutNode, props passed in via [analyticsProperties] in analytics directive
 */
export const buildAnalyticTrackProps = (
  clientProps: Record<string, string | number | undefined>,
  nodeProps: IAnalyticsNode | undefined,
  propsWithoutNode: Record<string, string | number | null | boolean>
): Record<string, string | number | null | boolean> => {
  return {
    ...clientProps,
    ...nodeProps,
    ...filterAnalyticsProperties(clientProps, propsWithoutNode)
  }
}

/**
 * Removes undefined values and replaces null site info with
 * client-side props from Angular. Will remove null values if the propert does
 * not exist in client-side props from Angular.
 */
export const filterAnalyticsProperties = (
  clientProps: Record<string, string | number | undefined>,
  props: Record<string, string | number | null | boolean | undefined>
): Record<string, string | number | null | boolean> => {
  return Object.keys(props).reduce((acc: Record<string, string | number | null | boolean>, curKey: string) => {
    // if the value is null in analyticsProperties (BFF data), and the key exists in commonProps,
    // use the commonProps value
    if (props[curKey] === null && curKey in clientProps) {
      return acc
    }

    // filter out props that don't have values
    if (props[curKey] !== undefined && props[curKey] !== null) {
      // confirming it won't be undefined, typecasting because TS complains
      acc[curKey] = props[curKey] as string | number | boolean
    }

    return acc
  }, {})
}

export type CoreAPITag = { name: string; slug_uri: string }

/**
 * CXP-1568: Include content tags to Segment calls
 *
 * @param tags: Used to display tags on content like articles, rankings, videos, etc.
 * Returning as string: 'Watch,Feature,10k'
 */
export const buildContentTagString = (tags: ReadonlyArray<CoreAPITag>): string | undefined => {
  if (!tags || tags.length === 0) return undefined
  return tags.map(tag => tag.name).join()
}
