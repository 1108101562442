import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { FloApiResponse, Meta, selectFloApiData } from '../models/api-response.model'
import { SearchService } from '../../singleton-services/search.service'
import { PlatformService } from '../../singleton-services/platform.service'
import { IVideo } from '../models/video.model'
import {
  EXPERIENCE_API_LEGACY_CORE_RIGHT_RAIL_VIDEOS_PATH,
  EXPERIENCE_API_LEGACY_CORE_VIDEOS_PATH,
  EXPERIENCE_API_LEGACY_CORE_FILMS_PATH,
  EXPERIENCE_API_VERSION,
  EXPERIENCE_API_LEGACY_CORE_VIDEOS_EMBED_PATH,
  CORE_API_VIDEOS_PATH
} from 'src/app/app.config'
import { getIdFromSlug } from '../utility-functions/slug.utility'
import { Params } from '@angular/router'
export { IVideo } from '../models/video.model'

export interface IVideoRail {
  ooyalaAdSetId: string
  ooyalaPlayerId: string
  ooyalaColor: string
  source_video: IVideo
  container2: ReadonlyArray<any> | undefined
  container2_title: string
  container3: ReadonlyArray<any> | undefined
  container3_title: string
}

interface HasNumericId {
  id: number
}

@Injectable()
export class VideoService {
  public getVideoBySlug(params: Params): Observable<{ data: IVideo; meta: Meta }> {
    const nodeId = getIdFromSlug(params.slug)
    return this.http.get<FloApiResponse<IVideo, Meta>>(`${EXPERIENCE_API_LEGACY_CORE_VIDEOS_PATH}/${nodeId}`, {
      params: new HttpParams({ fromObject: { version: EXPERIENCE_API_VERSION } })
    })
  }

  public getVideo(id: number) {
    return this.http.get<FloApiResponse<IVideoRail>>(`${EXPERIENCE_API_LEGACY_CORE_RIGHT_RAIL_VIDEOS_PATH}/${id}`, {
      params: new HttpParams({ fromObject: { version: EXPERIENCE_API_VERSION } })
    })
  }

  public getVideoRailBySlug(params: Params): Observable<IVideoRail> {
    const nodeId = getIdFromSlug(params.slug)
    return this.getVideo(nodeId).pipe(selectFloApiData)
  }

  public getVideoByHash(embedHash: any): Observable<IVideo> {
    return this.http
      .get<FloApiResponse<IVideo>>(`${EXPERIENCE_API_LEGACY_CORE_VIDEOS_EMBED_PATH}/${embedHash}`, {
        params: new HttpParams({ fromObject: { version: EXPERIENCE_API_VERSION } })
      })
      .pipe(selectFloApiData)
  }

  public queryVideos(params: any = {}, category = '', limit = 16) {
    const searchQueryParams = {
      limit,
      type: 'video' as 'video',
      category: category.toString(),
      sort: 'recent' as 'recent',
      ...Object.keys(params).reduce((acc, cur) => {
        return {
          ...acc,
          [cur === 'playing' ? 'include_node' : cur]: params[cur]
        }
      }, {})
    }
    return this.searchService.search<ReadonlyArray<IVideo>>(searchQueryParams)
  }

  public getVideoAssociations(id: number) {
    const params = {
      version: EXPERIENCE_API_VERSION
    }
    return this.http
      .get<FloApiResponse<any>>(`${EXPERIENCE_API_LEGACY_CORE_FILMS_PATH}/${id}`, { params })
      .pipe(selectFloApiData)
  }

  public isVideoGeoblocked$ = (video: HasNumericId) =>
    this.platformService.isBrowser
      ? this.http.get(`${CORE_API_VIDEOS_PATH}/${video.id}/geo`).pipe(
          map(
            (response: FloApiResponse<{ statusCode: number }>) =>
              // we don't actually care what the response is,
              // we don't want to risk playing a video in a restricted location
              // assume everything is blocked unless we get an explicit 200 OK
              response && response.data && response.data.statusCode !== 200
          ),
          catchError(err => of(true))
        )
      : of(true)

  constructor(
    private http: HttpClient,
    private searchService: SearchService,
    private readonly platformService: PlatformService
  ) {}
}
