import { Observable, EMPTY, throwError } from 'rxjs'
import { map, catchError } from 'rxjs/operators'
import { HttpErrorResponse } from '@angular/common/http'
import { Node } from 'src/io/models/node'

// tslint:disable:variable-name
export interface Meta {
  duration: number
  limit?: number
  max_limit?: number
  max_pages?: number
  offset?: number
  page?: number
  pages?: number
  sites: ReadonlyArray<number>
  source: string
  total: number
  type: string
  extra: ReadonlyArray<MetaExtra>
}

export interface PreFacetedTypes {
  value: string
  count: number
}

export interface MetaExtra {
  request: any
  pre_faceted_types: ReadonlyArray<PreFacetedTypes>
  include_node: Node
}

/**
 * @deprecated This uses a deprecated type. Use the modernized version.
 * Meta is full of "any"s and most properties have been made optional when they are not
 *
 * @see FloResponse from 'flosports30-types'
 */
export interface FloApiResponse<TEntity, TMeta = Meta> {
  data: TEntity
  meta: TMeta
}

export type FloApiResponseCollection<TEntity, TMeta = Meta> = FloApiResponse<ReadonlyArray<TEntity>, TMeta>

export const selectFloApiData = <TEntity, TMeta = Meta>(source: Observable<FloApiResponse<TEntity, TMeta>>) =>
  source.pipe(map(a => a.data))

export const selectFloApiCollectionData = <TEntity, TMeta = Meta>(
  source: Observable<FloApiResponseCollection<TEntity, TMeta>>
) => source.pipe(map<FloApiResponseCollection<TEntity, TMeta>, ReadonlyArray<TEntity>>(a => a.data || []))

export const catch301Errors = <T>(source: Observable<T>): Observable<T> =>
  source.pipe(
    catchError((err: unknown) => {
      // if we are caught in a redirect, terminate the stream wihout emitting values (EMPTY)
      // otherwise throw the error again to continue failure branch
      if (err instanceof HttpErrorResponse) {
        if (err?.status === 301) {
          return EMPTY
        } else {
          return throwError(err)
        }
      }

      if (err instanceof Error) {
        return throwError(err)
      }

      if (typeof err === 'object' && err !== null) {
        return throwError(Error(JSON.stringify(err)))
      }

      return throwError(Error(err as any))
    })
  )
