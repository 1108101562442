import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { AvatarModel, SectionModel } from '@flocasts/experience-service-types'
import { trackById } from '../../shared/utility-functions/track-by-id.utility'

@Component({
  selector: 'flo-pinned-links-favorites',
  templateUrl: './pinned-links-favorites.component.html',
  styleUrls: ['./pinned-links-favorites.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PinnedLinksFavoritesComponent {
  @Input() readonly section: SectionModel<AvatarModel>

  public trackById = trackById
}
