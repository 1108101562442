import { LoggingService } from 'src/app/singleton-services/logging/logging.service'
import { Inject, Injectable, Renderer2 } from '@angular/core'
import { PlatformService } from './platform.service'
import { InjectionService } from './injection.service'
import { empty, Observable, of } from 'rxjs'
import { map, catchError } from 'rxjs/operators'
import { WINDOW } from '../app.injections'

declare let twttr: any

@Injectable({
  providedIn: 'root'
})
export class TwitterService {
  private twttrScript = 'https://platform.twitter.com/widgets.js'

  constructor(
    private ps: PlatformService,
    private log: LoggingService,
    private injectionService: InjectionService,
    @Inject(WINDOW) private readonly window: Window
  ) {}

  public load(renderer: Renderer2): Observable<any> {
    if (this.ps.isServer) return empty()

    return this.injectionService.injectScripts(renderer, [this.twttrScript]).pipe(
      map((res: any) => twttr),
      catchError(err => {
        this.log.warn('Twitter failed to load', err)
        return empty()
      })
    )
  }

  get twitter() {
    return this.window?.hasOwnProperty('twttr') ? of(twttr) : of(undefined)
  }
}
