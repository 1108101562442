import { empty, Observable } from 'rxjs'
import { sampleTime, share, startWith } from 'rxjs/operators'

const scrollListeners = new WeakMap<any, Observable<any>>()

export function sampleObservable(obs: Observable<any>, scheduler?: any) {
  return obs.pipe(sampleTime(100, scheduler), share(), startWith(''))
}

// Only create one scroll listener per target and share the observable.
// Typical, there will only be one observable per application
export const getScrollListener = (scrollTarget: any) => {
  if (!scrollTarget || typeof scrollTarget.addEventListener !== 'function') {
    return empty()
  }
  if (scrollListeners.has(scrollTarget)) {
    return scrollListeners.get(scrollTarget)
  }

  const srollEvent = Observable.create((observer: any) => {
    const eventName = 'scroll'
    const handler = (event: any) => observer.next(event)
    const options = { passive: true, capture: false }
    scrollTarget.addEventListener(eventName, handler, options)
    return () => scrollTarget.removeEventListener(eventName, handler, options)
  })

  const listener = sampleObservable(srollEvent)
  scrollListeners.set(scrollTarget, listener)
  return listener
}
