import { Injectable } from '@angular/core'
import { ActivatedRoute, Router, NavigationEnd, Params } from '@angular/router'
import { filter, map, shareReplay } from 'rxjs/operators'

export interface RouteData {
  data: unknown
  params: Record<string, unknown>
}

/**
 * Wrapping the router with this service is unnecessary and can cause funky behavior in the app.
 * If you need to use the angular router, just import it without this wrapper.
 * @deprecated
 * @see Router from @angular/router
 */
@Injectable({
  providedIn: 'root'
})
export class RouterService {
  constructor(public readonly activatedRoute: ActivatedRoute, public readonly router: Router) {}

  public readonly route$ = this.router.events.pipe(
    filter(routerEvent => routerEvent instanceof NavigationEnd),
    map(() => getRouteData(this.activatedRoute.root)),
    shareReplay({
      bufferSize: 1,
      refCount: false
    })
  )

  /**
   * Remove a single query parameter from the route
   * This is just a convenience method since removeQueryParams can accept a single string
   */
  removeQueryParam(param: string): void {
    this.removeQueryParams(param)
  }

  /**
   * Remove any number of query params from the route
   */
  removeQueryParams(...params: string[]): void {
    const newParams: Params = params.reduce((allParams, param) => {
      return {
        ...allParams,
        [param]: null
      }
    }, {})
    this.updateQueryParams(newParams)
  }

  // TODO: Update to use ParamMap instead of params
  updateQueryParams(params: Params): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: params,
      queryParamsHandling: 'merge',
      replaceUrl: true
    })
  }

  public readonly queryParams$ = this.activatedRoute.queryParams
  public readonly queryParamMap$ = this.activatedRoute.queryParamMap
}

const getRouteData = (route: ActivatedRoute | null): RouteData => {
  let params = {}
  let data = {}
  while (!!route && !!route.snapshot) {
    // tslint:disable-next-line:no-parameter-reassignment
    params = {
      ...params,
      ...route.snapshot.params
    }
    data = {
      ...data,
      ...route.snapshot.data
    }
    // tslint:disable-next-line:no-parameter-reassignment
    route = route.firstChild
  }
  return {
    params,
    data
  }
}
