import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { ImageModel } from '@flocasts/experience-service-types'

@Component({
  selector: 'flo-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageComponent {
  @Input() image: ImageModel

  /**
   * The error state of the image.
   *
   * @remarks
   * If true, will not show broken image.
   */
  public error = false

  public handleError() {
    this.error = true
  }
}
