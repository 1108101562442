import { ErrorHandler, Inject, Injectable } from '@angular/core'
import { LocationStrategy, PathLocationStrategy } from '@angular/common'
import { REQUEST } from '@nguniversal/express-engine/tokens'
import { LOGGER_SERVICE } from '../logger/logger.config'
import { LoggerService } from '../logger/logger.interface'
import { Request } from 'express'
import { HttpErrorResponse } from '@angular/common/http'

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    @Inject(LOGGER_SERVICE) private readonly log: LoggerService,
    @Inject(REQUEST) private readonly req: Request,
    private readonly location: LocationStrategy
  ) {}

  handleError(error: unknown) {
    const url = this.location instanceof PathLocationStrategy ? this.location.path() : this.req.url

    // Setting context was erroring out for non http errors.
    const context = !!this.req?.get
      ? {
          hostname: this.req.hostname,
          origin: this.req.get('origin'),
          referer: this.req.get('referer'),
          userAgent: this.req.get('user-agent'),
          url,
          body: this.req.body
        }
      : undefined

    if (error instanceof Error) {
      const errorContext: Record<string, any> = {
        ...context,
        errorName: error.name,
        errorMessage: error.message,
        errorStack: error.stack?.split('\n')
      }

      this.log.error('Uncaught Error: ' + error.message, errorContext)
    } else if (error instanceof HttpErrorResponse) {
      const errorContext: Record<string, any> = {
        ...context,
        errorName: error.name,
        errorMessage: error.message,
        errorStatus: error.status,
        errorStatusText: error.statusText,
        errorUrl: error.url,
        error: error.error
      }

      this.log.error('Uncaught HTTP Error: ' + error.message, errorContext)
    } else {
      /**
       * If an error occurs with an unknown type, we have no way to safely display
       * it via pino logger. Instead we have to use the regular console to log it
       * in the hope that Node's error handling can provide us with more insight.
       * These errors should be fixed ASAP because they make monitoring so
       * difficult.
       */
      this.log.fatal('An uncaught error with unknown type was thrown.', context)
      console.error(error)
    }
  }
}
