import { Observable } from 'rxjs'

/**
 * @deprecated `@ngx-meta/core` is deprecated. Use `PageMetaService` instead.
 * @see PageMetaService
 */
export const isPromise = (obj: any): obj is Promise<any> => !!obj && typeof obj.then === 'function'

/**
 * @deprecated `@ngx-meta/core` is deprecated. Use `PageMetaService` instead.
 * @see PageMetaService
 */
export const isObservable = (obj: any | Observable<any>): obj is Observable<any> => !!obj && typeof obj.subscribe === 'function'
