import { Injectable } from '@angular/core'
import { Logger } from './logging.interface'

/**
 * @deprecated Inject LOGGER_SERVICE instead. This service has been replaced by separated server and browser loggers.
 * @see BrowserLoggerService
 * @see ServerLoggerService
 * @see LOGGER_SERVICE
 */
@Injectable()
export class LoggingService implements Logger {
  private readonly logger = console
  constructor() {}

  trace(msg: any, ...args: any[]): void {
    this.logger.trace(msg, ...args)
  }

  debug(msg: any, ...args: any[]): void {
    this.logger.debug(msg, ...args)
  }

  info(msg: any, ...args: any[]): void {
    this.logger.info(msg, ...args)
  }

  warn(msg: any, ...args: any[]): void {
    this.logger.warn(msg, ...args)
  }

  error(msg: any, ...args: any[]): void {
    this.logger.error(msg, ...args)
  }

  fatal(msg: any, ...args: any[]): void {
    this.logger.error(msg, ...args)
  }
}
