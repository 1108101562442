<div class="list-item-wrapper">
  <div class="date-time-box">
    <h4 class="event-date small primary-color-text">
      {{ event?.live_event?.start_date | floDate: event?.live_event?.end_date }}
    </h4>
    <span *ngIf="!event.live_event?.start_time_tbd">
      <p *ngIf="event?.live_event?.start_date_time | date: 'shortTime' as startDateTime"
      class="event-footnote flo-footnote">{{ event?.live_event?.start_time_tbd ? 'TBD' : startDateTime | lowercase }}</p>
    </span>
  </div>
  <div class="title-location-box">
    <h4 class="event-title small">{{ event?.title }}</h4>
    <h4 *ngIf="geoBlockedIds?.includes(event?.live_id)" class="geoblock small">Not available in your location</h4>
  </div>

  <div class="mobile-box">
    <!-- prettier-ignore -->
    <p class="event-footnote mobile-date-time flo-footnote">
      {{ event?.live_event?.start_date | floDate: event?.live_event?.start_date }}
      <span *ngIf="event?.live_event?.start_date_time | date: 'shortTime' as startDateTime">
        , {{ event?.live_event?.start_time_tbd ? 'TBD' : startDateTime | lowercase }}
      </span>
    </p>
    <h4 class="event-title small">{{ event?.short_title }}</h4>
    <h4 *ngIf="geoBlockedIds?.includes(event?.live_id)" class="geoblock small">Not available in your location</h4>

  </div>
</div>
