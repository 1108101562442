import { REQUEST } from '@nguniversal/express-engine/tokens'
import { Inject, Injectable } from '@angular/core'
import { makeStateKey, TransferState } from '@angular/platform-browser'
import { Observable, of } from 'rxjs'
import { ExperimentationService, IExperiment, IExperiments } from './experimentation.service'
import { PlatformService } from '../../singleton-services/platform.service'
import { SegmentService } from '../analytics/services/segment.service'
import { ALL_EXPERIMENT_DEFINITIONS } from './experiment.model'
import { VerticalService } from '../../singleton-services/vertical.service'
import { CookieService } from '../../singleton-services/cookie.service'
import { LocationService } from '../../singleton-services/location.service'
import { UserAgentService } from '../../singleton-services/user-agent.service'
import { AuthService } from '../../singleton-services/auth/auth.service'
import {
  FLO_FLAGS_FORCED_KEY,
  EXPERIMENT_VARIATION_SEPARATOR,
  EXPERIMENT_SEPARATOR,
  FLO_FLAGS_HEADER
} from 'src/app/app.config'

@Injectable()
export class FloFlaggerService extends ExperimentationService {
  private allDefinitions = ALL_EXPERIMENT_DEFINITIONS

  constructor(
    @Inject(REQUEST) private readonly req: any,
    protected readonly segment: SegmentService,
    protected readonly ps: PlatformService,
    protected readonly vs: VerticalService,
    protected readonly userAgentService: UserAgentService,
    protected readonly authService: AuthService,
    private readonly ts: TransferState,
    private readonly cookieService: CookieService,
    private readonly locationService: LocationService
  ) {
    super(ps, segment, vs, userAgentService, authService)
    // Pre-Load all pre-bucketed experiments
    this.experimentsSource.next(this.experimentMapper())
    this.forcedExperimentsSource.next(this.forcedExperimentMapper())
    this.fetchAllExperiments()
  }

  public fetchAllExperiments(): Observable<IExperiments> {
    this.allExperimentsSource.next(this.allDefinitions)
    return of(this.allDefinitions)
  }

  public forceVariation(experimentId: string, variationId: string): void {
    const cookie = this.cookieService.get(FLO_FLAGS_FORCED_KEY) || ''
    const existenceRegExp = new RegExp(`${experimentId}${EXPERIMENT_VARIATION_SEPARATOR}([^${EXPERIMENT_SEPARATOR}]+)`)
    const updatedFlagPair = `${experimentId}${EXPERIMENT_VARIATION_SEPARATOR}${variationId}`
    const updatedCookie = cookie.match(existenceRegExp)
      ? cookie.replace(existenceRegExp, updatedFlagPair)
      : cookie
      ? `${cookie}${EXPERIMENT_SEPARATOR}${updatedFlagPair}`
      : updatedFlagPair
    this.cookieService.set(FLO_FLAGS_FORCED_KEY, updatedCookie)
    super.forceVariation(experimentId, variationId)
  }

  public clearForcedVariations() {
    this.cookieService.remove(FLO_FLAGS_FORCED_KEY)
    this.locationService.reload()
    super.clearForcedVariations()
  }

  private getFloFlags(headerKey: string) {
    const header = this.ps.isBrowser
      ? this.ts.get(makeStateKey<string>(headerKey), '')
      : (this.req && this.req.header(headerKey)) || ''
    if (!this.ps.isBrowser) {
      this.ts.set(makeStateKey(headerKey), header)
    }
    return header.split(EXPERIMENT_SEPARATOR)
  }

  private experimentMapper(): IExperiments {
    return this.getFloFlags(FLO_FLAGS_HEADER).reduce((prevValue: IExperiments, flagPair: any) => {
      const flag = flagPair.split(EXPERIMENT_VARIATION_SEPARATOR)
      if (flag && flag.length > 1) {
        prevValue[flag[0]] = {
          id: flag[0],
          variationId: flag[1]
        } as IExperiment
      }
      return prevValue
    }, {}) as IExperiments
  }

  private forcedExperimentMapper(): IExperiments {
    return this.getFloFlags(FLO_FLAGS_FORCED_KEY).reduce((prevValue: IExperiments, flagPair: any) => {
      const flag = flagPair.split(EXPERIMENT_VARIATION_SEPARATOR)
      if (flag && flag.length > 1) {
        prevValue[flag[0]] = {
          id: flag[0],
          variationId: flag[1],
          forced: true
        } as IExperiment
      }
      return prevValue
    }, {}) as IExperiments
  }
}
