import { addDays, isEqual, isFuture, isToday, isTomorrow, isYesterday, isBefore, startOfToday } from 'date-fns'
import { legacyParse } from '@date-fns/upgrade/v2'
import { safeDateString } from '../../shared/utility-functions/date.utility'
import { FloEvent, LiveEventStatus } from '@flocasts/flosports30-types/dist/entity'
import { FloEventWithLiveEvent } from '../../singleton-services/cta/live-event-cta.service'
import { EventStatusComponentProps } from './components/event-status/event-status.component'

/**
 * ExtendedLiveEventStatus is created because experience service types has additional types that
 * FloSports30 (deprecated) does not cover
 */
export type ExtendedLiveEventStatus = LiveEventStatus | 'ADS-POST' | 'ADS-PRE'

export type EventCTAText = 'Replay' | 'Replays' | 'Watch' | 'Watch Live' | ''

// TODO: This is a dupe with...
// 1) getEventVideosUrl() in event-watch-link.directive
// 2) getEventVideosUrlTimeline() in event-status.ts
export const getEventVideosUrl = (slugUri: string, status?: LiveEventStatus, liveId?: number) => {
  switch (status ?? '') {
    case 'LIVE':
    case 'PRE-AIR':
      return `/live/${liveId}`
    default:
      return `${slugUri}/videos`
  }
}

export const getEventVideosText = (replayVideoCount: number | null, status?: LiveEventStatus): EventCTAText => {
  switch (status ?? '') {
    case 'LIVE':
      return 'Watch Live'
    case 'PRE-AIR':
      return 'Watch'
    case 'CONCLUDED':
      return replayVideoCount !== 1 ? 'Replays' : 'Replay'
    default:
      return ''
  }
}

export const getEventAnalyticsProperties = (
  eventId: number,
  liveId?: number,
  status?: LiveEventStatus,
  itemOrder?: number | string,
  eventsThisDay?: number | string
) => ({
  event_status: status?.toLowerCase(),
  live_id: liveId,
  node_id: eventId,
  item_order: itemOrder,
  events_this_day: eventsThisDay
})

export const getDayFromTimestamp = (timestamp: number | Date) =>
  isToday(timestamp)
    ? 'Today'
    : isTomorrow(timestamp)
    ? 'Tomorrow'
    : isYesterday(timestamp)
    ? 'Yesterday'
    : safeDateString(timestamp, 'EEEE')

/**
 * returns event status text from EventStatusComponentProps values
 */
export const mapLiveEventPartsToDisplayText = (props: EventStatusComponentProps): string | undefined => {
  if (props.status === 'LIVE') {
    if (props.startDateTime && isFuture(legacyParse(props.startDateTime))) {
      return 'Live'
    }
    return 'Live Now'
  } else if (
    props.status === 'CONCLUDED' ||
    isBefore(calculateEndDate(props.startDateTime, props.numberOfDays || undefined), startOfToday())
  ) {
    if ((props.startDateTime && isToday(legacyParse(props.startDateTime))) || props.shortPrint) {
      return 'Completed'
    }
    return undefined
  } else {
    return undefined
  }
}

/**
 * returns a boolean to determine if displayText$ should show in event status
 */

export const filterEventStatusText = (
  status: ExtendedLiveEventStatus,
  shortPrint: boolean,
  showBadge: boolean
): boolean => {
  return (!showBadge && !shortPrint) || (shortPrint && status !== 'LIVE') || (showBadge && status !== 'LIVE')
}

/**
 * returns a boolean to determine if middot should show in event status
 */

export const showMiddot = (props: EventStatusComponentProps, showBadge: boolean): boolean => {
  return (
    (props.status === 'LIVE' || (props.status === 'CONCLUDED' && isToday(legacyParse(props.startDateTime)))) &&
    !props.shortPrint &&
    !showBadge
  )
}

/**
 * Until INTERDAY status is available from the API,
 * Interday events currently return PRE-AIR status
 * and this is our hack to determine their status
 */
export const mapInterDayStatus = (
  startAtNext: string | null,
  startDateTime: string,
  status: ExtendedLiveEventStatus
): ExtendedLiveEventStatus => {
  return !!startAtNext && status === 'PRE-AIR' && !isEqual(legacyParse(startDateTime), legacyParse(startAtNext))
    ? 'INTERDAY'
    : status
}

/**
 * Get an event's end date based on start date and number of days.
 */
export const calculateEndDate = (startDate: string, numberOfDays: number | undefined) =>
  addDays(legacyParse(startDate), (numberOfDays || 1) - 1)

/**
 * Return .color-ignite class if an event is 'LIVE'.
 */
export const mapLiveEventStatusToDisplayTextClass = (status: LiveEventStatus) =>
  status === 'LIVE' ? 'color-ignite' : ''

/**
 * Add a page=brackets query param to arena URL to ensure it opens on the brackets tab.
 * TODO: This should be API logic, not client-side. As of now every client has to add this.
 */
export const getArenaBracketUrl = (url: string | null | undefined): string | null => {
  return !!url ? `${url}?page=brackets` : null
}

export const floEventHasLiveEvent = (event: FloEvent): event is FloEventWithLiveEvent => {
  return event.live_id !== null && !!event.live_event
}
