import { InjectionToken } from '@angular/core'
import { LoggerService } from './logger.interface'
import * as pino from 'pino'
import { Logger } from '@datadog/browser-logs'

/** Use this token to inject different loggers for servers and browsers */
export const LOGGER_SERVICE = new InjectionToken<LoggerService>('LOGGER_SERVICE')

export const PINO_INSTANCE = new InjectionToken<pino.Logger>('PINO_INSTANCE')

export const DATADOG_BROWSER_INSTANCE = new InjectionToken<Logger>('DATADOG_BROWSER_INSTANCE')
