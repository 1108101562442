import { Pipe, PipeTransform } from '@angular/core'
import { LinkModel } from '@flocasts/experience-service-types'

@Pipe({ name: 'removeTitle' })
export class RemoveTitlePipe implements PipeTransform {
  transform(items: Array<LinkModel>, hasCta: boolean): Array<LinkModel> {
    if (!hasCta) {
      return items
    }
    // On XS, when the subscribe CTA is present, we remove aux link text to save space
    // The logo will still be visible.
    return items.map(link => ({ ...link, title: null }))
  }
}
