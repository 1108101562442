import { MetaSettings } from './models/meta-settings'
import { PageTitlePositioning } from './models/page-title-positioning'

/**
 * @deprecated `@ngx-meta/core` is deprecated. Use `PageMetaService` instead.
 * @see PageMetaService
 */
export abstract class MetaLoader {
  abstract get settings(): MetaSettings;
}

/**
 * @deprecated `@ngx-meta/core` is deprecated. Use `PageMetaService` instead.
 * @see PageMetaService
 */
export class MetaStaticLoader implements MetaLoader {
  get settings(): MetaSettings {
    return this.providedSettings
  }

  constructor(
    private readonly providedSettings: MetaSettings = {
      pageTitlePositioning: PageTitlePositioning.PrependPageTitle,
      defaults: {}
    }
  ) {}
}
