import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  Directive
} from '@angular/core'
import { DOCUMENT } from '@angular/common'

export const standardWidth = 560
export const smallWidth = 300

@Directive()
export abstract class BaseModalDirective implements OnChanges {
  constructor(private renderer: Renderer2, @Inject(DOCUMENT) private doc: HTMLDocument) {}

  @Input() public active = false
  @Input() public width = standardWidth
  @Output() public readonly isActive = new EventEmitter<boolean>()

  public closeModal() {
    this.isActive.emit(false)
  }

  private preventScrolling(val: boolean) {
    if (val) {
      this.renderer.addClass(this.doc.body, 'show-modal')
    } else {
      this.renderer.removeClass(this.doc.body, 'show-modal')
    }
  }

  ngOnChanges(change: SimpleChanges) {
    if (change.active) {
      this.preventScrolling(change.active.currentValue)
    }
  }
}

@Component({
  selector: 'flo-modal',
  templateUrl: './base-modal.component.html',
  styleUrls: ['./base-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalComponent extends BaseModalDirective {
  // Don't use the base class as it is abstract. Instead, if you want to use the base modal component, use this class.
  // If you're doing something simple, I recommend StandardModal. If you're doing something more complicated, extend
  // from BaseModalDirective.
}
