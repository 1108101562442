import { FunnelComponent } from './funnel.component'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { SegmentTagMap } from '../shared/analytics/models/segment-tag-map.model'
import { PayGuard } from '../shared/guards/pay.guard'
import { LoginSignupGuard } from '../shared/guards/login-signup.guard'
import { ThankYouGuard } from '../shared/guards/thank-you.guard'
import { ActivateGuard } from '../shared/guards/activate.guard'
import { LogoutGuard } from '../shared/guards/logout.guard'
import { MetaGuard } from 'meta'
import { RegisterGuard } from '../shared/guards/register.guard'
import { CreateAccountGuard } from '../shared/guards/create-account.guard'
import { LiveQueryParamResolver } from '../shared/services/live-query-param.resolver'
import { LEGACY_PLANS_SEGMENT_TAG, PAY_SEGMENT_TAG, THANK_YOU_SEGMENT_TAG } from './funnel-analytics.utility'

export function loginModule() {
  return import('./login/login.module').then(m => m.LoginModule)
}

export function signupModule() {
  return import('./signup/signup.module').then(m => m.SignupModule)
}

export function thankYouModule() {
  return import('./thank-you/thank-you.module').then(m => m.ThankYouModule)
}

export function activateModule() {
  return import('./activate/activate.module').then(m => m.ActivateModule)
}

export function createAccountModule() {
  return import('./create-account/create-account.module').then(m => m.CreateAccountModule)
}

export function appInterstitialModule() {
  return import('./app-interstitial/app-interstitial.module').then(m => m.AppInterstitialModule)
}

export function payModule() {
  return import('./pay/pay.module').then(m => m.PayModule)
}

export function plansModule() {
  return import('./plans/plans.module').then(m => m.PlansModule)
}

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '',
        component: FunnelComponent,
        canActivate: [MetaGuard],
        data: {
          shouldShowEventTicker: false
        },
        children: [
          {
            path: 'create-account',
            loadChildren: createAccountModule,
            canActivate: [MetaGuard, CreateAccountGuard],
            data: {
              segment: SegmentTagMap.CREATE_ACCOUNT,
              hideHeader: true,
              coupon: {
                showRibbon: true,
                hasBeenApplied: false
              },
              funnel: true,
              meta: {
                title: 'meta_signup_page_title',
                description: 'meta_signup_page_description'
              }
            },
            resolve: {
              liveEvent: LiveQueryParamResolver
            }
          },
          {
            path: 'signup',
            loadChildren: signupModule,
            canActivate: [MetaGuard, LoginSignupGuard],
            data: {
              segment: SegmentTagMap.SIGNUP,
              hideHeader: true,
              coupon: {
                showRibbon: true,
                hasBeenApplied: false
              },
              funnel: true,
              meta: {
                title: 'meta_signup_page_title',
                description: 'meta_signup_page_description'
              }
            },
            resolve: {
              liveEvent: LiveQueryParamResolver
            }
          },
          {
            path: 'register',
            loadChildren: signupModule,
            canActivate: [MetaGuard, RegisterGuard],
            data: {
              segment: SegmentTagMap.SIGNUP,
              hideHeader: true,
              coupon: {
                showRibbon: false
              },
              funnel: true,
              meta: {
                title: 'meta_signup_page_title',
                description: 'meta_signup_page_description'
              }
            },
            resolve: {
              liveEvent: LiveQueryParamResolver
            }
          },
          {
            path: 'plans',
            loadChildren: plansModule,
            canActivate: [MetaGuard],
            data: {
              /**
               * This will both prevent automatic page calls and also allow the
               * analytics directive to work.
               *
               * @see outExcludedRoutes
               */
              segment: LEGACY_PLANS_SEGMENT_TAG,
              hideHeader: true,
              coupon: {
                showRibbon: false,
                hasBeenApplied: false
              },
              funnel: true,
              meta: {
                title: 'meta_pay_page_title',
                description: 'meta_pay_page_description'
              }
            },
            resolve: {
              liveEvent: LiveQueryParamResolver
            }
          },
          {
            path: 'pay',
            loadChildren: payModule,
            canActivate: [MetaGuard, PayGuard],
            data: {
              /**
               * This will both prevent automatic page calls and also allow the
               * analytics directive to work.
               *
               * @see outExcludedRoutes
               */
              segment: PAY_SEGMENT_TAG,
              hideHeader: true,
              coupon: {
                showRibbon: false,
                hasBeenApplied: true
              },
              funnel: true,
              meta: {
                title: 'meta_pay_page_title',
                description: 'meta_pay_page_description'
              }
            },
            resolve: {
              liveEvent: LiveQueryParamResolver
            }
          },
          {
            path: 'login',
            loadChildren: loginModule,
            canActivate: [MetaGuard, LoginSignupGuard],
            data: {
              segment: SegmentTagMap.LOGIN,
              hideHeader: true,
              coupon: {
                showRibbon: true,
                hasBeenApplied: false
              },
              funnel: true,
              meta: {
                title: 'meta_login_page_title',
                description: 'meta_login_page_description'
              }
            },
            resolve: {
              liveEvent: LiveQueryParamResolver
            }
          },
          {
            path: 'thank-you',
            loadChildren: thankYouModule,
            canActivate: [MetaGuard, ThankYouGuard],
            data: {
              /**
               * This will both prevent automatic page calls and also allow the
               * analytics directive to work.
               *
               * @see outExcludedRoutes
               */
              segment: THANK_YOU_SEGMENT_TAG,
              hideHeader: true,
              coupon: {
                showRibbon: false
              },
              funnel: true
            },
            resolve: {
              liveEvent: LiveQueryParamResolver
            }
          },
          {
            path: 'app-interstitial',
            loadChildren: appInterstitialModule,
            canActivate: [MetaGuard],
            data: {
              segment: SegmentTagMap.APP_INTERSTITIAL,
              hideHeader: true,
              coupon: {
                showRibbon: false
              },
              funnel: true
            },
            resolve: {
              liveEvent: LiveQueryParamResolver
            }
          },
          {
            path: 'activate',
            loadChildren: activateModule,
            canActivate: [MetaGuard, ActivateGuard],
            data: {
              segment: SegmentTagMap.ACTIVATE,
              hideHeader: true,
              coupon: {
                showRibbon: false
              },
              funnel: true
            }
          },
          {
            path: 'logout',
            loadChildren: loginModule,
            canActivate: [LogoutGuard]
          }
        ]
      }
    ])
  ],
  exports: [RouterModule],
  providers: [PayGuard, LoginSignupGuard, CreateAccountGuard, ThankYouGuard, ActivateGuard, LogoutGuard, RegisterGuard]
})
export class FunnelRoutingModule {}
