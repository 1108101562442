<a class="decorated-text-cell-container p-0 py-3 w-100 d-flex flex-row primary-color-text-hover" floAnalytics [floCustomLink]="item.action.url">
  <div *ngIf="!!item.leadingDecorator" class="leading-decorator d-flex flex-row align-items-center me-3">
    <div>
      <ng-container *ngIf="item.leadingDecorator | floIsIcon">
        <flo-svg-icon
          data-test="leading-decorator-icon"
          class="pe-2 leading-decorator-icon"
          [icon]="item.leadingDecorator.style"
          width="19px"
        ></flo-svg-icon>
      </ng-container>
      <ng-container *ngIf="item.leadingDecorator | floIsImage">
        <flo-image
          class="leading-decorator-image"
          [image]="item.leadingDecorator"
          data-test="leading-decorator-image"
        >
        </flo-image>
      </ng-container>
    </div>
  </div>
  <div class="details d-flex flex-row align-items-center">
    <p data-test="decorated-text-cell-title" class="m-0 color-900 apply-text-hover text-truncate">
      {{ item.title.text }}
      <span *ngIf="item.subtitle" data-test="item-subtitle" class="color-500 ps-2">&middot; <span class="ps-2">{{ item.subtitle.text }}</span></span>
    </p>
  </div>
  <div class="trailing-decorator d-flex flex-row align-items-center justify-content-end pe-2">
   <ng-container *ngIf="!!item.trailingDecorator">
     <ng-container *ngFor="let icon of item.trailingDecorator; trackBy: trackByStyle">
       <div *ngIf="icon | floIsIcon" [class]="icon.style">
         <flo-svg-icon
           data-test="decorated-text-icon"
           class="ps-2 text-trailing-decorator-icon svg-vertical-align-text-bottom color-900"
           [ngClass]="{'text-ignite': icon.style === 'icon-star-filled'}"
           [icon]="icon.style"
           width="20px"
         ></flo-svg-icon>
       </div>
     </ng-container>
   </ng-container>
  </div>
</a>
