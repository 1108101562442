import { ChangeDetectionStrategy, Component, Input, ChangeDetectorRef } from '@angular/core'
import { ExperimentationService, IExperiment, IExperimentVariation } from '../experimentation.service'
import { shareReplay } from 'rxjs/operators'

@Component({
  selector: 'flo-experimentation-widget',
  styleUrls: ['./experimentation-widget.component.scss'],
  templateUrl: './experimentation-widget.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExperimentationWidgetComponent {
  @Input()
  public expanded = false
  public experiments$ = this.experimentService.experimentsAsArray.pipe(shareReplay(1))

  constructor(private experimentService: ExperimentationService, private cd: ChangeDetectorRef) {}

  trackByExp(_idx: number, experiment: IExperiment) {
    return experiment.id
  }

  trackByVariation(_idx: number, data: IExperimentVariation) {
    return data.variationId
  }

  onClick(experimentId: string, variationId: string) {
    this.experimentService.forceVariation(experimentId, variationId)
  }

  onClickClear() {
    this.experimentService.clearForcedVariations()
  }

  closeWindow() {
    this.expanded = false
    this.cd.detectChanges()
  }

  openWindow() {
    this.expanded = true
    this.cd.detectChanges()
  }

  toggleWindow() {
    this.expanded = !this.expanded
    this.cd.detectChanges()
  }

  public experimentId(experimentId: string, variationId: string) {
    // Using ID here for QA's benefit, to have a unique identifier with automated testing
    return `${experimentId}-${variationId}`
  }
}
