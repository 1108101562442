import { AnalyticsModel, OfferSelectionCardModel, TextModel } from '@flocasts/experience-service-types'
import { ICoupon } from '../../../shared/services/coupon.service'
import type { Money, Plan } from '@flocasts/flosports30-types/dist/subscriptions'
import { SiteSettings } from 'src/app/shared/models/site-settings.model'
import { getPlanProps } from 'src/app/shared/services/product.service'

export interface PlanSelectionProps {
  displayPriceAnnually: boolean
  svgLabel: 'checkmark' | 'x-mark'
  planPrice: string
  planPriceAmount: number
  perMonthPrice: string
  originalPrice?: string
}

/**
 * If input is 12500 (cents)
 * If scale is 100, output will look like: 125.00
 * @deprecated, use localized_text property on Money interface
 */
export const setPriceToCurrencyFormat = (amount: number, scale = 100, digits = 2): string => {
  return (amount / scale).toFixed(digits)
}

/**
 * Gets the discounted price of a plan if there is a coupon, or returns normal price
 * Returns localized_text string
 */
export const getPlanPrice = (plan: Plan, coupon?: ICoupon): string => {
  return !!coupon && plan.discounted_price && !!plan.amount_discounted
    ? plan.discounted_price.localized_text
    : plan.price.localized_text
}

/**
 * Gets the discounted price amount of a plan if there is a coupon, or returns normal price amount
 * Returns amount number
 */
export const getPlanPriceAmount = (plan: Plan, coupon?: ICoupon): number => {
  return !!coupon && plan.discounted_price && !!plan.amount_discounted
    ? plan.discounted_price.amount
    : plan.price.amount
}

/**
 * Gets the discounted price of a plan if there is a coupon (in months),
 * or returns the normal price (in months)
 */
export const getPlanPerMonthPrice = (plan: Plan, coupon?: ICoupon) => {
  return !!coupon && plan.cost_per_month_discounted
    ? plan.cost_per_month_discounted.localized_text
    : plan.cost_per_month.localized_text
}

// TODO: update ICoupon everywhere
/**
 * displayPriceAnnually - To show the full annual price, or in monthly increments
 * svgLabel - The top SVG Value Prop Icon on Plan Selection, all others are checkmarks
 * planPrice - Price user will pay now with or w/o coupon discount applied
 * perMonthPrice - The price the user will pay now, broken down in cost per month
 */
export const getPlanSelectionProps = (plan: Plan, coupon: ICoupon | undefined): PlanSelectionProps => {
  return {
    displayPriceAnnually: plan.yearly && plan.display_price_annually,
    svgLabel: plan.yearly ? 'checkmark' : 'x-mark',
    planPrice: getPlanPrice(plan, coupon),
    planPriceAmount: getPlanPriceAmount(plan, coupon),
    perMonthPrice: getPlanPerMonthPrice(plan, coupon)
  }
}

/**
 * Use getPlanSelectionProps to generate Plans array
 * TODO: move away from ICoupon
 */
export const buildPlanData = (
  plans: ReadonlyArray<Plan> | undefined,
  coupon: ICoupon | undefined
): ReadonlyArray<Plan & { selectionProps: PlanSelectionProps }> | undefined => {
  if (!!plans) {
    return plans.map((plan: Plan) => {
      return {
        ...plan,
        selectionProps: getPlanSelectionProps(plan, coupon)
      }
    })
  } else {
    // should never get here - types may be wrong
    return undefined
  }
}

/**
 * Calculates the annual markup or annual savings and returns
 * localized text string of amount saved, or '0'
 */
export const calculateAnnualMarkup = (annualMarkupMoney: Money | null, annualSavingsMoney: Money | null): string => {
  if (!!annualMarkupMoney && !!annualSavingsMoney) {
    return annualMarkupMoney?.amount > annualSavingsMoney?.amount
      ? annualMarkupMoney?.localized_text
      : annualSavingsMoney?.localized_text
  } else if (!!annualMarkupMoney && !annualSavingsMoney) {
    return annualMarkupMoney?.localized_text
  } else if (!!annualSavingsMoney && !annualMarkupMoney) {
    return annualSavingsMoney?.localized_text
  } else {
    return '0'
  }
}

/**
 * Gets the plan that should be selected by default from stripe metadata
 */
export const getDefaultSelectedPlan = (a: { plans: ReadonlyArray<Plan>; publicKey: string }): Plan => {
  const defaultSelectionArray = a.plans.filter(plan => plan.default_selection)
  // TODO: if no plan has a default_selection=true, return yearly
  return defaultSelectionArray[defaultSelectionArray.length - 1]
}

// Plan Selection Card Utilities
export const buildHeaderBannerLanguage = (percentageSaved: number | null, hasOneShownPlan: boolean) => {
  // don't need to compare costs if there is only one plan
  return percentageSaved && !hasOneShownPlan ? `Save ${percentageSaved}%` : null
}

// Display the price for the cost of the full year, or cost per month
export const buildPeriodCostLanguage = (displayPriceAnnually: boolean, plan: Plan): string => {
  return displayPriceAnnually ? plan.price.localized_text : plan.cost_per_month.localized_text
}

export const buildBilledAsLanguage = (yearly: boolean) => {
  return yearly ? 'BILLED ANNUALLY' : `BILLED MONTHLY`
}

export const buildPlanTitle = (yearly: boolean) => {
  return yearly ? 'Annual Plan' : 'Monthly Plan'
}

export const getBillingPlanLabel = (plan: Plan): string => {
  return `Billed as ${plan.amount_discounted ? plan.discounted_price?.localized_text : plan.price.localized_text} ${
    plan.yearly ? 'Annually' : 'Monthly'
  }`
}

export function mapPlansToOfferSelectionCardModel(
  plans: ReadonlyArray<Plan>,
  siteSettings: SiteSettings
): OfferSelectionCardModel[] {
  const offerSelectionCardModel: OfferSelectionCardModel[] = plans.map(plan => {
    return {
      id: plan.id.toString(), // will usually be an id from offer service, in this case it the plan id
      type: 'card:offer-selection',
      style: 'legacy-plan',
      title: buildPlanTitle(plan.yearly),
      subtitle: buildBilledAsLanguage(plan.yearly),
      heading: plan.yearly ? 'BEST VALUE' : undefined,
      caption: plan.percentage_saved
        ? {
            id: 'best-universal-caption',
            style: 'primary',
            subText: null,
            subTextStyle: 'standard',
            text: buildHeaderBannerLanguage(plan.percentage_saved, false),
            textParts: null,
            type: 'text'
          }
        : null,
      originalPriceMonthly: plan.amount_discounted ? `Original Price ${plan.cost_per_month?.localized_text}` : null,
      originalPriceYearly:
        plan.amount_discounted && plan.period === 'year' ? `Original Price ${plan.price.localized_text}` : null,
      yearlyPriceLabel:
        plan.period === 'month' && plan.annual_markup_money
          ? plan.annual_markup_money.localized_text
          : plan.price.localized_text,
      priceLabel:
        plan.amount_discounted && plan.cost_per_month_discounted
          ? plan.cost_per_month_discounted.localized_text
          : plan.cost_per_month.localized_text,
      periodSubtext: plan.display_price_annually ? '/yr' : '/mo',
      bulletPoints: buildValuePropTextModel(plan, siteSettings),
      analytics: buildOfferSelectionAnalytics(plan, siteSettings),
      offerMetadata: {
        bestValueOrder: 1,
        currency: plan.price.currency,
        isInstallment: false,
        period: plan.period,
        siteId: siteSettings.site_id,
        type: 'offer-metadata'
      },
      defaultSelection: plan.yearly
    }
  })

  return offerSelectionCardModel
}

function buildOfferSelectionAnalytics(plan: Plan, siteSetting: SiteSettings): AnalyticsModel {
  return {
    type: 'analytics',
    siteId: siteSetting.site_id,
    siteName: siteSetting.site_name,
    nodeId: null,
    nodeType: null,
    primaryAssociationId: null,
    primaryAssociationTitle: null,
    primaryAssociationShortTitle: null,
    collectionSlug: null,
    slugUri: null,
    isPremium: false,
    entityId: null,
    entityType: null,
    extraContext: {
      isInstallmentPlan: false,
      planId: plan.id,
      offerStyle: 'legacy-plan',
      amount: plan.price.amount
    },
    name: buildPlanTitle(plan.yearly),
    videoAnalytics: null
  }
}

function buildValuePropTextModel(plan: Plan, siteSettings: SiteSettings): TextModel[] {
  const defaultTextModel: TextModel = {
    id: 'watch-unlimited-bullet-point',
    type: 'text',
    style: 'checkmark',
    text: null,
    textParts: null,
    subText: null,
    subTextStyle: 'standard'
  }

  const savingsProp: TextModel[] = plan?.yearly
    ? [
        {
          ...defaultTextModel,
          text: `Save ${calculateAnnualMarkup(plan.annual_markup_money, plan.annual_savings_money)} per year`
        }
      ]
    : []

  const billedProp: TextModel = {
    ...defaultTextModel,
    text: getBillingPlanLabel(plan)
  }

  const defaultProps: TextModel[] = getPlanProps(siteSettings.site_id, siteSettings.site_name).map(valueProp => ({
    ...defaultTextModel,
    text: valueProp
  }))

  return [...savingsProp, billedProp, ...defaultProps]
}
