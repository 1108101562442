const ACTIVATE = {
  component: 'ActivateComponent',
  name: 'Activate',
  page_category: 'Activate'
}

const ARTICLES = {
  component: 'ArticlesComponent',
  page_category: 'News'
}

const APP_INTERSTITIAL = {
  component: 'AppInterstitialComponent',
  name: 'App Interstitial',
  page_category: 'App Interstitial'
}

const CLAIM_ACCOUNT = {
  component: 'ClaimAccountComponent',
  name: 'Claim Account - Request Link',
  page_category: 'Claim Account',
  subpage_category: 'Request Link'
}

const CREATE_ACCOUNT = {
  component: 'CreateAccountComponent',
  name: 'Create Account',
  page_category: 'Create Account'
}

const LOGIN = {
  component: 'LoginComponent',
  name: 'Login',
  page_category: 'Login'
}

const PAY = {
  component: 'PayComponent',
  name: 'Pay',
  page_category: 'Pay'
}

const PLANS = {
  component: 'PlansComponent',
  name: 'Plans',
  page_category: 'Plans'
}

const RANKINGS = {
  page_category: 'Rankings'
}

const RESULTS = {
  page_category: 'Results'
}

const SETTINGS = {
  page_category: 'Settings'
}

const SIGNUP = {
  component: 'SignupComponent',
  name: 'Signup',
  page_category: 'Signup'
}

const THANK_YOU = {
  component: 'ThankYouComponent',
  name: 'Thank You',
  page_category: 'Thank You'
}

const WEB_TO_NATIVE_PROMO = {
  component: 'WebToNativePromoComponent',
  name: 'Web To Native',
  page_category: 'Web To Native'
}

/**
 * Mapping of routing properties sent to Segment.
 *
 * @deprecated The tag map is no longer needed when using the pageV2 call.
 *             Create the tag at the location of the page call instead. Use the
 *             SegmentTagV2 interface.
 * @see SegmentTagV2
 * @see ArticleAnalyticsService Example V2 Usage
 */
// tslint:disable-next-line:variable-name
export const SegmentTagMap = {
  ARTICLES,
  ARTICLE_LIST: {
    ...ARTICLES,
    name: 'News - Home'
  },

  ARTICLE_DETAIL: {
    ...ARTICLES,
    name: 'News - Article'
  },

  CLAIM_ACCOUNT,

  CLAIM_ACCOUNT_TOKEN: {
    ...CLAIM_ACCOUNT,
    name: 'Claim Account - Set New Password',
    subpage_category: 'Set New Password'
  },

  EVENT_DETAIL: {
    page_category: 'Event Hub',
    name: 'Event Hub'
  },

  FORGOT_PASSWORD: {
    component: 'ForgotPasswordComponent',
    name: 'Forgot Password',
    page_category: 'Forgot Password'
  },

  // TODO (FLO-15062): Remove/consolidate this with HOME_ANALYTICS_TAG when launching.
  HOME: {
    component: 'HomeComponent',
    name: 'Home',
    page_category: 'Home'
  },

  LOGIN,

  NOT_FOUND: {
    component: 'NotFoundComponent'
  },

  RESET_PASSWORD: {
    component: 'ResetPasswordComponent',
    name: 'Reset Password',
    page_category: 'Reset Password'
  },

  PAY,

  PLANS,

  RESULTS,

  RESULTS_DETAIL: {
    ...RESULTS,
    component: 'CmsResultsExperienceComponent',
    name: 'Results'
  },

  RANKINGS_DETAIL: {
    ...RANKINGS,
    component: 'RankingComponent',
    name: 'Rankings'
  },

  SETTINGS,

  SETTINGS_CHANGE_PASSWORD: {
    ...SETTINGS,
    component: 'PasswordComponent',
    name: 'Settings - Change Password',
    subpage_category: 'Change Password'
  },

  SETTINGS_INFORMATION: {
    ...SETTINGS,
    component: 'InformationComponent',
    name: 'Settings - Account',
    subpage_category: 'Account'
  },

  SETTINGS_SUBSCRIPTION_DETAILS: {
    ...SETTINGS,
    component: 'SubscriptionDetailComponent',
    name: 'Settings - Subscription Details',
    subpage_category: 'Subscription Details'
  },

  SETTINGS_SUBSCRIPTION_DETAILS_CANCEL: {
    ...SETTINGS,
    component: 'CancelSubscriptionComponent',
    name: 'Settings - Confirm Subscription Cancellation',
    subpage_category: 'Confirm Subscription Cancellation'
  },

  SETTINGS_SUBSCRIPTION_DETAILS_CANCEL_REASON: {
    ...SETTINGS,
    component: 'CancelReasonComponent',
    name: 'Settings - Subscription Cancellation Reason',
    subpage_category: 'Subscription Cancellation Reason'
  },

  SETTINGS_SUBSCRIPTION_DETAILS_CANCEL_CONFIRMATION: {
    ...SETTINGS,
    component: 'CancelConfirmationComponent',
    name: 'Settings - Subscription Cancellation Confirmed',
    subpage_category: 'Subscription Cancellation Confirmed'
  },

  SETTINGS_SUBSCRIPTION_DETAILS_CANCEL_FEEDBACK: {
    ...SETTINGS,
    component: 'AdditionalFeedbackComponent',
    name: 'Settings - Additional Feedback',
    subpage_category: 'Additional Feedback'
  },

  SETTINGS_SUBSCRIPTION_DETAILS_CANCEL_THANK_YOU: {
    ...SETTINGS,
    component: 'CancelThankYouComponent',
    name: 'Settings - Thank You',
    subpage_category: 'Thank You'
  },

  SETTINGS_SUBSCRIPTION_DETAILS_CANCEL_OFFER: {
    ...SETTINGS,
    component: 'ChurnPreventionComponent',
    name: 'Settings - Churn Prevention',
    subpage_category: 'Churn Prevention'
  },

  SETTINGS_SUBSCRIPTION_DETAILS_CANCEL_OFFER_SUCCESS: {
    ...SETTINGS,
    component: 'ChurnPreventionSuccessComponent',
    name: 'Settings - Churn Prevention Success',
    subpage_category: 'Churn Prevention Success'
  },

  SETTINGS_SUBSCRIPTION_DETAILS_REACTIVATION: {
    ...SETTINGS,
    component: 'ReactivationComponent',
    name: 'Settings - Reactivate Subscription',
    subpage_category: 'Reactivate Subscription'
  },

  SETTINGS_SUBSCRIPTION_DETAILS_REACTIVATION_SUCCESS: {
    ...SETTINGS,
    component: 'ReactivationSuccessComponent',
    name: 'Settings - Reactivation Success',
    subpage_category: 'Reactivation Success'
  },

  SETTINGS_SUBSCRIPTION_LIST: {
    ...SETTINGS,
    component: 'SubscriptionListComponent',
    name: 'Settings - Subscriptions',
    subpage_category: 'Subscriptions'
  },

  SIGNUP,

  CREATE_ACCOUNT,

  ACTIVATE,

  APP_INTERSTITIAL,

  THANK_YOU,

  WEB_TO_NATIVE_PROMO,

  TRAINING_HOME: {
    component: 'TrainingComponent',
    name: 'Training - Home',
    page_category: 'Training'
  },

  TRAINING_VIDEO: {
    component: 'TrainingComponent',
    name: 'Training - Video',
    page_category: 'Training'
  },

  FLOFILMS: {
    component: 'FloFilmsComponent',
    name: 'FloFilms',
    page_category: 'FloFilms'
  },

  ATHLETE_DETAIL: {
    component: 'AthleteDetailComponent',
    name: 'Arena Athlete Profile'
  },

  ATHLETE_DETAIL_RESULTS: {
    component: 'AthleteDetailComponent',
    name: 'Arena Athlete Profile - Results',
    page_category: 'Arena Athlete Profile',
    subpage_category: 'Results'
  },

  ATHLETE_DETAIL_PLACEMENTS: {
    component: 'AthleteDetailComponent',
    name: 'Arena Athlete Profile - Placements',
    page_category: 'Arena Athlete Profile',
    subpage_category: 'Placements'
  },

  COLLECTIONS_VIEW: {
    component: 'CollectionViewComponent',
    name: 'Collections View'
  },

  COLLECTIONS_TAB_VIEW: {
    component: 'CollectionsTabViewComponent',
    name: 'Collections Tab View'
  }
}
