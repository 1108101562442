import { NgModule } from '@angular/core'
import { EventStatusComponent } from './event-status.component'
import { CommonModule } from '@angular/common'
import { SvgModule } from '../../../../shared/svg/svg.module'

@NgModule({
  imports: [CommonModule, SvgModule],
  exports: [EventStatusComponent],
  declarations: [EventStatusComponent]
})
export class EventStatusModule {}
