import { Inject, Injectable } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { LocalStorageService } from 'ngx-webstorage'
import { CookieService } from '../../../singleton-services/cookie.service'
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { LoggerService } from 'src/app/logger/logger.interface'
import { LOGGER_SERVICE } from 'src/app/logger/logger.config'

/** Lifetime of the HasOffers cookie in days */
const COOKIE_LIFETIME = 30

@Injectable()
export class HasOffersService {
  public readonly STORAGE_NAME = 'flo_has_offers'

  constructor(
    private readonly cookieService: CookieService,
    private readonly localStorageService: LocalStorageService,
    @Inject(LOGGER_SERVICE) private readonly logger: LoggerService,
    private readonly route: ActivatedRoute
  ) {}

  /** Stores a HasOffers transaction ID from a link decoration */
  public storeTransactionId(): Observable<string> {
    return this.route.queryParamMap.pipe(
      map(params => params.get('tid') || params.get('transaction_id')),
      distinctUntilChanged(),
      tap(tid => {
        if (!!tid) {
          this.logger.info('Storing affiliate referral link for HasOffer.', {
            'transaction id': tid
          })
          this.cookieService.set(this.STORAGE_NAME, tid, {
            expires: COOKIE_LIFETIME
          })
          this.localStorageService.store(this.STORAGE_NAME, tid)
        }

        this.restoreCookie()
      }),
      filter<string>(Boolean)
    )
  }

  /** Restores the HasOffers cookie from local storage to circumvent ITP 2.2 */
  private restoreCookie(): void {
    const cookieExists = !!this.cookieService.get(this.STORAGE_NAME)
    if (cookieExists) return

    const tid = this.localStorageService.retrieve(this.STORAGE_NAME)
    if (!tid) return
    this.logger.info('HasOffer transaction ID was restored from local storage.', { 'transaction id': tid })
    this.cookieService.set(this.STORAGE_NAME, tid, {
      expires: COOKIE_LIFETIME
    })
  }

  /** Obtains the transaction ID from the cookie */
  public retrieveTransactionId(): string | undefined {
    return this.cookieService.get(this.STORAGE_NAME)
  }
}
