import { Injectable } from '@angular/core'
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs'
import { pluck, map, filter, mergeMap, take, tap } from 'rxjs/operators'
import { maybe } from 'typescript-monads'
import { SegmentService } from '../analytics/services/segment.service'
import { SegmentEvents } from '../analytics/models/segment-events.model'
import { SegmentExperimentViewed } from '../analytics/models/segment-experiment-viewed.model'
import { PlatformService } from '../../singleton-services/platform.service'
import { VerticalService } from '../../singleton-services/vertical.service'
import { Platform, UserAgentService } from '../../singleton-services/user-agent.service'
import { AuthService } from '../../singleton-services/auth/auth.service'
import type { IUser } from '../../singleton-services/auth/auth.interfaces'

import { findVariationId } from './experimentation.utility'

export interface IExperimentationService {
  readonly activeExperiments: Observable<string>
  readonly experimentsAsArray: Observable<ReadonlyArray<IExperiment>>

  getExperiment(experimentId: string): Observable<IExperiment>
  isActive(experimentId: string): Observable<boolean>
  isInVariation(experimentId: string, variationId: string): Observable<boolean>
  push(options: any): void
  activate(experimentId: string): void
  fetchAllExperiments(): Observable<IExperiments>
  forceVariation(experimentId: string, variationId: string): void
  clearForcedVariations(): void
}

export interface IExperiments {
  [experimentId: string]: IExperiment
}

export interface IExperiment {
  id: string
  name?: string
  variationId?: string
  variationName?: string
  variations?: ReadonlyArray<IExperimentVariation>
  forced?: boolean // Used to determine whether the experiment has been forced or not
  criteria?: ReadonlyArray<IExperimentCriteria> // TODO - Replace with CoinToss audience
  userBased?: boolean // TODO - Replace when CoinToss goes live
}

export interface IExperimentVariation {
  variationId: string
  variationName?: string
}

/** Factors by which an experiment can be limited */
type CriterionType = 'vertical' | 'device' | 'iosVersion' | 'platform' | 'user' | 'subscription' | 'browser'

/** Describes the limitations set on an experiment */
export interface IExperimentCriteria {
  /** Limit the experiment based on this `CriterionType` */
  type: CriterionType

  /** Only the verticals specified in `data` if true. All but these verticals if false. */
  inclusive: boolean

  /** List of the verticals/devices/users/etc to include or exclude */
  data: ReadonlyArray<string | number>
}
@Injectable()
export class ExperimentationService implements IExperimentationService {
  constructor(
    protected readonly ps: PlatformService,
    protected readonly segment: SegmentService,
    protected readonly vs: VerticalService,
    protected readonly userAgentService: UserAgentService,
    protected readonly authService: AuthService
  ) {}

  public get activeExperiments(): Observable<string> {
    return this.experiments$.pipe(
      map((experiments: IExperiments) => {
        return Object.keys(experiments)
          .map(key => {
            const experiment = experiments[key] as IExperiment
            return `${experiment.id}-${experiment.variationId}`
          })
          .join(',')
      })
    )
  }

  public get experimentsAsArray(): Observable<ReadonlyArray<IExperiment>> {
    return this.experiments$.pipe(
      map((experiments: IExperiments) => {
        return Object.keys(experiments).map(k => experiments[k])
      })
    )
  }
  forcedExperimentsSource = new BehaviorSubject<IExperiments>({})
  private forcedExperiments$ = this.forcedExperimentsSource.asObservable()

  allExperimentsSource = new BehaviorSubject<IExperiments>({})
  private allExperiments$ = this.allExperimentsSource.asObservable()

  experimentsSource = new BehaviorSubject<IExperiments>({})
  private experiments$ = combineLatest(
    this.allExperiments$,
    this.experimentsSource.asObservable(),
    this.forcedExperiments$,
    (allExperiments, source, forcedExperiments) => {
      const experiments = {} as IExperiments
      const keys = [...Object.keys(allExperiments), ...Object.keys(source), ...Object.keys(forcedExperiments)].filter(
        (e, i, arr) => arr.indexOf(e) === i
      ) as ReadonlyArray<string>
      keys.forEach(key => {
        experiments[key] = {
          ...allExperiments[key],
          ...source[key],
          ...forcedExperiments[key]
        } as IExperiment
      })
      return experiments
    }
  )

  private activatedExperiments: { [key: string]: IExperiment } = {}

  public push(options: any): void {
    // abstract - to be implemented
  }

  public fetchAllExperiments(): Observable<IExperiments> {
    // abstract - to be implemented
    return of({})
  }

  /**
   * checks if the experiment is present in the cookie, NOT if the user is in variation or not
   */
  public isActive(experimentId: string): Observable<boolean> {
    return this.experiments$.pipe(
      pluck(experimentId),
      map((data: IExperiment) => {
        return data && !!data.variationId
      })
    )
  }

  public getExperiment(experimentId: string): Observable<IExperiment> {
    return this.experiments$.pipe(
      pluck(experimentId),
      mergeMap((exp: IExperiment) => {
        if (exp && exp.userBased && !exp.forced) {
          return this.tossForVariation(experimentId).pipe(
            mergeMap((variationId: string) => {
              return of({
                ...exp,
                ...{ variationId }
              })
            })
          )
        } else {
          return of(exp)
        }
      })
    )
  }

  public activate(experimentId: string): void {
    this.matchesCriteria(experimentId)
      .pipe(
        filter(Boolean),
        mergeMap(() => this.getExperiment(experimentId)),
        filter((exp: IExperiment) => this.ps.isBrowser && exp && !!exp.variationId),
        take(1)
      )
      .subscribe((experiment: IExperiment) => {
        if (!this.activatedExperiments[experiment.id]) {
          this.activatedExperiments[experiment.id] = experiment
          this.segment.track(SegmentEvents.EXPERIMENT_VIEWED, {
            experimentId: experiment.id,
            experimentName: experiment.name,
            variationId: experiment.variationId,
            forced: !!experiment.forced,
            experimentSource: 'Web App',
            // NOTE: Segment - Setting `nonInteraction=1` will not push this event to 3rd-party tools like Google Analytics
            nonInteraction: 1
          } as SegmentExperimentViewed)
        }
      })
  }

  public activateApiExperiment(experimentId: string): void {
    const [id, variationId] = experimentId.split('-')
    if (!this.activatedExperiments[id]) {
      this.activatedExperiments[id] = {
        id,
        variationId
      } as IExperiment

      this.segment.track(SegmentEvents.EXPERIMENT_VIEWED, {
        variationId,
        experimentId: id,
        experimentSource: 'Platform API'
      } as SegmentExperimentViewed)
    }
  }

  /**
   * checks if user is in variation
   */
  public isInVariation(experimentId: string, variationId: string, autoActivate = true): Observable<boolean> {
    return combineLatest([this.matchesCriteria(experimentId), this.getExperiment(experimentId)]).pipe(
      tap(([matches, _exp]) => matches && autoActivate && this.activate(experimentId)),
      map(([matches, exp]) => matches && exp && exp.variationId === variationId)
    )
  }

  public matchesCriteria(experimentId: string): Observable<boolean> {
    return this.getExperiment(experimentId).pipe(
      map((exp: IExperiment) => {
        return exp && exp.criteria
          ? exp.criteria.map((c: IExperimentCriteria) => {
              switch (c.type) {
                case 'vertical':
                  return this.matchesVertical(c.data, c.inclusive)
                case 'device':
                  return this.matchesDevice(c.data, c.inclusive)
                case 'browser':
                  return this.matchesBrowser(c.data, c.inclusive)
                case 'iosVersion':
                  return this.matchesIOSVersion(c.data, c.inclusive)
                case 'platform':
                  return this.matchesPlatform(c.data as any, c.inclusive)
                case 'user':
                  return this.matchesUserTier(c.data as any, c.inclusive)
                case 'subscription':
                  return this.matchesUserSubscription(c.data as any, c.inclusive)
                default:
                  return of(false)
              }
            })
          : [of(true)]
      }),
      mergeMap(allCriteriaArray$ => combineLatest(...allCriteriaArray$)),
      map(all => {
        return all.reduce((acc, current) => {
          return acc && current
        }, true) as boolean
      })
    )
  }

  public matchesVertical(data: ReadonlyArray<string | number>, inclusive: boolean): Observable<boolean> {
    return this.vs.siteId$.pipe(map((id: number) => (inclusive ? data.includes(id) : !data.includes(id))))
  }

  public matchesUserTier(data: ReadonlyArray<string>, inclusive: boolean): Observable<boolean> {
    return this.authService.userTier$.pipe(
      map((tier: string) => (inclusive ? data.includes(tier) : !data.includes(tier)))
    )
  }

  public matchesPlatform(data: ReadonlyArray<Platform | undefined>, inclusive: boolean): Observable<boolean> {
    const matches = maybe(data.includes(this.userAgentService.userAgent.platform))
    return matches
      .map(match => {
        return inclusive ? of(match) : of(!match)
      })
      .valueOr(of(false))
  }

  public matchesDevice(data: ReadonlyArray<string | number | undefined>, inclusive: boolean): Observable<boolean> {
    const matches = data.includes(this.userAgentService.userAgent.device)
    return inclusive ? of(matches) : of(!matches)
  }

  public matchesBrowser(data: ReadonlyArray<string | number | undefined>, inclusive: boolean): Observable<boolean> {
    const matches = data.includes(this.userAgentService.userAgent.browser)
    return inclusive ? of(matches) : of(!matches)
  }

  public matchesIOSVersion(data: ReadonlyArray<string | number | undefined>, inclusive: boolean): Observable<boolean> {
    const matches = data.includes(this.userAgentService.userAgent.iosVersion)
    return inclusive ? of(matches) : of(!matches)
  }

  public matchesUserSubscription(data: ReadonlyArray<string | undefined>, inclusive: boolean): Observable<boolean> {
    return this.authService.user$.pipe(
      take(1),
      filter<IUser>(Boolean),
      map(user => (inclusive ? data.includes(user.subscriptionStatus) : !data.includes(user.subscriptionStatus)))
    )
  }

  public clearForcedVariations(): void {
    this.forcedExperimentsSource.next({})
  }

  public forceVariation(experimentId: string, variationId: string): void {
    this.forcedExperiments$
      .pipe(
        map(forcedExperiments => {
          return {
            ...forcedExperiments,
            ...{
              [experimentId]: {
                id: experimentId,
                forced: true,
                variationId
              } as IExperiment
            }
          } as IExperiments
        }),
        take(1)
      )
      .subscribe(result => this.forcedExperimentsSource.next(result))
  }

  // TODO: Use Coin Toss - Changed for FLO-7663 - New Live Player to be based on visitor
  private tossForVariation(experimentId: string): Observable<string | undefined> {
    return this.authService.userIdentity$.pipe(
      mergeMap(userIdentity => {
        const userId = (userIdentity && userIdentity.id) || 'default-id'
        return of(findVariationId(userId, experimentId))
      })
    )
  }
}
