import { fromEventPattern } from 'rxjs'
import { distinctUntilChanged, flatMap, map } from 'rxjs/operators'
import { VideoAdsService } from '../ads/services/video-ads.service'
import { SegmentEvents } from '../analytics/models/segment-events.model'
import { IVideoAdsLibrary } from '../interfaces/video-ads-library.interface'

export const fromAdsEvent = (
  ads: google.ima.AdsLoader | google.ima.AdsManager,
  eventName: google.ima.AdEvent.Type | google.ima.AdErrorEvent.Type | google.ima.AdsManagerLoadedEvent.Type
) =>
  fromEventPattern(
    (callback: any) => ads.addEventListener(eventName as any, callback, false),
    (callback: any) => ads.removeEventListener(eventName as any, callback)
  )

export const flatMapAdImpression = () =>
  flatMap(
    // tslint:disable: readonly-array
    ([adsManager, videoAdsLibrary]: [google.ima.AdsManager, IVideoAdsLibrary]) =>
      fromAdsEvent(adsManager, videoAdsLibrary.AdEvent.Type.IMPRESSION)
  )

export const flatMapGetAdsRequest = (videoAdsService: VideoAdsService) =>
  flatMap(([adTagUrl, contentVideoElement]: [string, HTMLVideoElement]) =>
    videoAdsService.getAdsRequest$(adTagUrl, contentVideoElement)
  )

export const flatMapAdLoaded = () =>
  flatMap(([adsManager, videoAdsLibrary]: [google.ima.AdsManager, IVideoAdsLibrary]) =>
    fromAdsEvent(adsManager, videoAdsLibrary.AdEvent.Type.LOADED)
  )

export const flatMapVolumeChanged = () =>
  flatMap(([adsManager, videoAdsLibrary]: [google.ima.AdsManager, IVideoAdsLibrary]) =>
    fromAdsEvent(adsManager, videoAdsLibrary.AdEvent.Type.VOLUME_CHANGED)
  )

export const mapGetAdsManager = () =>
  map(([adsManagerLoadedEvent, contentVideoElement]: [google.ima.AdsManagerLoadedEvent, HTMLVideoElement]) =>
    adsManagerLoadedEvent.getAdsManager(contentVideoElement)
  )

export const flatMapAdErrors = () =>
  flatMap(([ads, videoAdsLibrary]: [google.ima.AdsManager | google.ima.AdsLoader, IVideoAdsLibrary]) =>
    fromAdsEvent(ads, videoAdsLibrary.AdErrorEvent.Type.AD_ERROR)
  )

export const flatMapAdDisplayContainer = (videoAdsService: VideoAdsService) =>
  flatMap(([adContainerElement, contentVideoElement]: [HTMLElement, HTMLVideoElement]) =>
    videoAdsService.getAdDisplayContainer$(contentVideoElement, adContainerElement)
  )

export const distinctUntilAdTagUrlChanged = () =>
  distinctUntilChanged(
    (oldValue: google.ima.AdsRequest, newValue: google.ima.AdsRequest) => oldValue.adTagUrl === newValue.adTagUrl
  )

export const flatten = <T>(array: ReadonlyArray<T>): ReadonlyArray<T> =>
  array.reduce(
    (accumulator: ReadonlyArray<T>, element: T) =>
      accumulator.concat(Array.isArray(element) ? flatten(element) : element),
    []
  )

export const getDescendantElements = (element: HTMLElement): ReadonlyArray<any> => [
  ...Array.from(element.children),
  ...(Array.from(element.children) || []).map((child: HTMLElement) => getDescendantElements(child))
]

export const mapAdEventsToSegment = (videoAdsLibrary: IVideoAdsLibrary) => {
  return {
    [videoAdsLibrary.AdEvent.Type.CONTENT_PAUSE_REQUESTED]: SegmentEvents.AD_STARTED,
    [videoAdsLibrary.AdEvent.Type.CONTENT_RESUME_REQUESTED]: SegmentEvents.AD_COMPLETED,
    [videoAdsLibrary.AdEvent.Type.CLICK]: SegmentEvents.AD_CLICKED,
    [videoAdsLibrary.AdEvent.Type.PAUSED]: SegmentEvents.AD_PAUSED,
    [videoAdsLibrary.AdEvent.Type.RESUMED]: SegmentEvents.AD_RESUMED,
    [videoAdsLibrary.AdEvent.Type.FIRST_QUARTILE]: SegmentEvents.AD_FIRST_QUARTILE,
    [videoAdsLibrary.AdEvent.Type.MIDPOINT]: SegmentEvents.AD_MIDPOINT,
    [videoAdsLibrary.AdEvent.Type.THIRD_QUARTILE]: SegmentEvents.AD_THIRD_QUARTILE,
    [videoAdsLibrary.AdErrorEvent.Type.AD_ERROR]: SegmentEvents.AD_ERRORED
  }
}
