import { ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy } from '@angular/core'
import { ReplaySubject } from 'rxjs'
import { map, takeUntil, shareReplay, filter } from 'rxjs/operators'
import { isToday, isSameMonth, isThisYear, isTomorrow, isPast, startOfToday, isBefore } from 'date-fns'
import {
  getDayFromTimestamp,
  mapInterDayStatus,
  calculateEndDate,
  mapLiveEventStatusToDisplayTextClass,
  filterEventStatusText,
  showMiddot,
  mapLiveEventPartsToDisplayText,
  ExtendedLiveEventStatus
} from '../../functions'
import { legacyParse } from '@date-fns/upgrade/v2'
import { safeDateString } from '../../../../shared/utility-functions/date.utility'
import { LiveEventStatus } from '@flocasts/flosports30-types/dist/entity'
import { EventPartsModel } from '@flocasts/experience-service-types'
import { LiveEventStatus as EventStatusEnum } from 'src/app/events/events.enum'

export type EventStatusComponentProps = Pick<
  EventPartsModel,
  'status' | 'startAtNext' | 'startDateTime' | 'startTimeTbd' | 'numberOfDays' | 'shortPrint'
>

@Component({
  selector: 'flo-event-status',
  templateUrl: './event-status.component.html',
  styleUrls: ['./event-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventStatusComponent implements OnDestroy {
  public readonly props$ = new ReplaySubject<EventStatusComponentProps>(1)
  private readonly ngOnDestroy$ = new ReplaySubject<void>(1)

  @Input()
  set props(props: EventStatusComponentProps) {
    if (!!props) {
      this.props$.next(props)
    }
  }

  @Input()
  showBadge = false

  public readonly displayText$ = this.props$.pipe(
    filter(props => filterEventStatusText(props.status, props.shortPrint, this.showBadge)),
    map(props => mapLiveEventPartsToDisplayText(props)),
    takeUntil(this.ngOnDestroy$),
    shareReplay(1)
  )

  public readonly displayDate$ = this.props$.pipe(
    map(props =>
      props.shortPrint
        ? mapEventToDisplayShortPrint(
            props.startAtNext,
            props.startDateTime,
            props.status,
            props.numberOfDays || undefined,
            props.startTimeTbd || false
          )
        : mapEventToDisplayDate(
            props.startAtNext ?? '',
            props.startDateTime,
            props.status,
            props.numberOfDays || undefined,
            props.startTimeTbd || false
          )
    ),
    takeUntil(this.ngOnDestroy$)
  )

  public readonly displayTextClass$ = this.props$.pipe(
    map(props => props.status),
    map(mapLiveEventStatusToDisplayTextClass),
    takeUntil(this.ngOnDestroy$)
  )

  public readonly showLiveBadge$ = this.props$.pipe(
    // always show live badge when shortPrint is true and the status is live
    map(props => (this.showBadge || props.shortPrint) && props.status === EventStatusEnum.Live),
    takeUntil(this.ngOnDestroy$)
  )

  public readonly showMiddot$ = this.props$.pipe(
    map(props => showMiddot(props, this.showBadge)),
    takeUntil(this.ngOnDestroy$)
  )

  constructor(public elementRef: ElementRef) {}

  public ngOnDestroy(): void {
    this.ngOnDestroy$.next()
    this.ngOnDestroy$.complete()
  }
}

const getTimeZoneLabel = () => {
  const timeZoneStringArray = new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')
  return timeZoneStringArray?.length > 0 ? timeZoneStringArray[timeZoneStringArray.length - 1] : ''
}

const mapEventToDisplayDate = (
  startAtNext: string,
  startDateTime: string,
  status: ExtendedLiveEventStatus,
  numberOfDays: number | undefined,
  startTimeTbd: boolean
) => {
  const liveEventStatus = mapInterDayStatus(startAtNext, startDateTime, status)
  const timeZone = getTimeZoneLabel()
  const date = defaultDateFormat(legacyParse(startDateTime), calculateEndDate(startDateTime, numberOfDays))

  if (liveEventStatus === EventStatusEnum.Intermission || liveEventStatus === EventStatusEnum.Interday) {
    const day = getDayFromTimestamp(legacyParse(startAtNext))
    const time = safeDateString(startAtNext, 'h:mm aa')

    return `${date} · Resumes ${day} at ${time} ${timeZone}`.trim()
  } else if (liveEventStatus === EventStatusEnum.Concluded) {
    return `${date}`
  } else {
    const time = eventStartTime(legacyParse(startDateTime), startTimeTbd, timeZone)
    if (!liveEventStatus && isPast(legacyParse(startDateTime))) {
      return `${date}`
    }
    if (time.includes('Today') || time.includes('Tomorrow')) {
      return `${time}`.trim()
    }
    return time ? `${date}, ${time}`.trim() : date.trim()
  }
}

export const mapEventToDisplayShortPrint = (
  startAtNext: string | null,
  startDateTime: string,
  status: ExtendedLiveEventStatus,
  numberOfDays: number | undefined,
  startTimeTbd: boolean
) => {
  const liveEventStatus = mapInterDayStatus(startAtNext, startDateTime, status)
  const timeZone = getTimeZoneLabel()
  const time = safeDateString(startDateTime, 'h:mm aa')
  const startNextTime = !!startAtNext ? safeDateString(startAtNext, 'h:mm aa') : ''
  const endDate = calculateEndDate(startDateTime, numberOfDays)

  if (liveEventStatus === EventStatusEnum.Intermission && !!startAtNext) {
    if (isToday(legacyParse(startAtNext))) {
      return `Resumes ${startNextTime} ${timeZone}`.trim()
    } else if (isTomorrow(legacyParse(startAtNext))) {
      const day = getDayFromTimestamp(legacyParse(startAtNext))
      return `Resumes ${day}`
    } else {
      const startNextDate = safeDateString(startAtNext, 'MMM d')
      return `Resumes ${startNextDate}`
    }
  } else if (liveEventStatus === EventStatusEnum.Interday && !!startAtNext) {
    return `${startNextTime} ${timeZone}`
  } else if (liveEventStatus === EventStatusEnum.Concluded || isBefore(endDate, startOfToday())) {
    return ''
  } else {
    return startTimeTbd ? 'TBD' : `${time} ${timeZone}`
  }
}

// use lowercase d and yyyy, see @{https://git.io/fxCyr}
const defaultDateFormat = (startTimestamp: number | string | Date, endTimestamp: number | string | Date): string => {
  const parsedStart = legacyParse(startTimestamp)
  const parsedEnd = legacyParse(endTimestamp)
  const formattedStart = safeDateString(parsedStart, 'MMM d')
  const formattedEnd = safeDateString(parsedEnd, 'MMM d')
  const year = safeDateString(parsedEnd, 'yyyy')

  switch (true) {
    case formattedStart === formattedEnd:
      if (isThisYear(parsedStart)) {
        return formattedStart
      } else {
        return `${formattedStart}, ${year}`
      }
    case isSameMonth(parsedStart, parsedEnd):
      if (isThisYear(parsedStart)) {
        return `${formattedStart}-${safeDateString(endTimestamp, 'd')}`
      } else {
        return `${formattedStart}-${safeDateString(endTimestamp, 'd')}, ${year}`
      }
    default:
      if (isThisYear(parsedStart)) {
        return `${formattedStart}-${formattedEnd}`
      } else {
        return `${formattedStart}-${formattedEnd}, ${year}`
      }
  }
}

const eventStartTime = (startTimestamp?: number | string | Date | null, isTbd?: boolean, timeZone?: string): string => {
  if (!startTimestamp) {
    return ''
  }
  const startWithTime = safeDateString(startTimestamp, 'h:mm aa')
  let time = `${startWithTime} ${timeZone}`

  if (isToday(legacyParse(startTimestamp))) {
    time = `Today · ${startWithTime} ${timeZone} `
  }

  if (isTomorrow(legacyParse(startTimestamp))) {
    time = `Tomorrow · ${startWithTime} ${timeZone}`
  }

  return `${isTbd ? 'TBD ' : `${time}`}`
}
