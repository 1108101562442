import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AnalyticsModule } from 'src/app/shared/analytics/analytics.module'
import { SvgModule } from '../../shared/svg/svg.module'
import { PersonalizationCtaComponent } from './personalization-cta.component'

@NgModule({
  imports: [CommonModule, SvgModule, AnalyticsModule],
  exports: [PersonalizationCtaComponent],
  declarations: [PersonalizationCtaComponent]
})
export class PersonalizationCtaModule {}
