import { NgZone } from '@angular/core'
import { map } from 'rxjs/operators'
import { LocationService } from '../../singleton-services/location.service'
import { IVideoAdsLibrary } from '../interfaces/video-ads-library.interface'

export const mapNewAdsRequest = (adTagUrl: string, contentVideoElement: HTMLVideoElement) =>
  map(
    videoAdsLibrary =>
      Object.assign(new (videoAdsLibrary as any).AdsRequest(), {
        adTagUrl,
        linearAdSlotWidth: '100%',
        linearAdSlotHeight: '100%',
        nonLinearAdSlotWidth: '100%',
        nonLinearAdSlotHeight: '100%'
      }) as google.ima.AdsRequest
  )

export const mapVideoAdsLibrary = (window: Window) =>
  map(() => {
    const googleIMA = (window as Window & { google: { ima: IVideoAdsLibrary } }).google
    return googleIMA ? googleIMA.ima : undefined
  })

export const mapInitializedAdContainer = (
  contentVideoElement: HTMLVideoElement,
  adContainerElement: HTMLElement,
  zone: NgZone
) =>
  map((videoAdsLibrary: IVideoAdsLibrary) => {
    videoAdsLibrary.settings.setVpaidMode(videoAdsLibrary.ImaSdkSettings.VpaidMode.ENABLED)

    return zone.runOutsideAngular(() => {
      videoAdsLibrary.settings.setDisableCustomPlaybackForIOS10Plus(true)
      const adDisplayContainer = new (videoAdsLibrary as any).AdDisplayContainer(
        adContainerElement,
        contentVideoElement
      ) as google.ima.AdDisplayContainer
      adDisplayContainer.initialize()
      return adDisplayContainer
    })
  })

export const mapFormatAdTagUrl = (locationService: LocationService, adTagUrl: string) =>
  map((siteName: string) =>
    adTagUrl
      .replace('http:', 'https:')
      .replace('%url%', encodeURIComponent(locationService.href))
      .replace('%description_url%', encodeURIComponent(locationService.href))
  )

export const mapNewAdsLoader = (adDisplayContainer: google.ima.AdDisplayContainer) =>
  map(videoAdsLibrary => new (videoAdsLibrary as any).AdsLoader(adDisplayContainer) as google.ima.AdsLoader)

const getHead = (array: ReadonlyArray<any>) => array[0]

export const mapGetHead = () => map(getHead)
