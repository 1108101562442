import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AnalyticsModule } from '../../shared/analytics/analytics.module'
import { CustomLinkModule } from '../custom-link/custom-link.directive.module'
import { PaginationComponent } from './pagination.component'
import { SvgModule } from '../../shared/svg/svg.module'

@NgModule({
  imports: [CommonModule, AnalyticsModule, CustomLinkModule, SvgModule],
  declarations: [PaginationComponent],
  exports: [PaginationComponent]
})
export class PaginationModule {}
