import { NgModule } from '@angular/core'
import { SmallContentCardComponent } from './small-content-card/small-content-card.component'
import { LargeContentCardComponent } from './large-content-card/large-content-card.component'
import { FeaturedContentCardComponent } from './featured-content-card/featured-content-card.component'
import { CommonModule } from '@angular/common'
import { ThumbnailModule } from '../../shared/thumbnails/thumbnail.module'
import { RouterModule } from '@angular/router'
import { AnalyticsModule } from '../../shared/analytics/analytics.module'
import { CustomLinkModule } from '../custom-link/custom-link.directive.module'
import { FloPipeModule } from '../../shared/pipes/flo-pipe.module'

@NgModule({
  declarations: [LargeContentCardComponent, SmallContentCardComponent, FeaturedContentCardComponent],
  exports: [LargeContentCardComponent, SmallContentCardComponent, FeaturedContentCardComponent],
  imports: [CommonModule, RouterModule, ThumbnailModule, AnalyticsModule, CustomLinkModule, FloPipeModule]
})
export class ContentCardModule {}
