import { Inject, Injectable } from '@angular/core'
import { DisplayAdvertisingService } from './display-advertising-service.interface'
import { combineLatest, EMPTY, Observable, of, Subject } from 'rxjs'
import {
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  shareReplay,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators'
import { AdConfig } from './ad-config'
import { LOGGER_SERVICE } from '../../../logger/logger.config'
import { LoggerService } from '../../../logger/logger.interface'
import { GptService, SlotRenderEndedEvent, Slot, SlotVisibilityChangedEvent } from './gpt.service'
import { ApsService } from './aps.service'
import { ApsSlotData, refreshApsSlotData } from './aps-slot-data'
import { AuthService } from '../../../singleton-services/auth/auth.service'
import { VerticalService } from '../../../singleton-services/vertical.service'
import { AdBlockService } from '@flosportsinc/ng-ad-block'
import { PubmaticService } from './pubmatic.service'
import { buildSiteCode } from '../ads.utility'

const CONTENT_IDS_TARGET_KEY = 'ad_ids'
const CATEGORY_SLUGS_TARGET_KEY = 'ad_categories'
const PREMIUM_USER_TARGET_KEY = 'user_is_pro'
const LIVE_ID_TARGET_KEY = 'live_id'
const AD_POSITION_TARGET_KEY = 'ad_position'
const EXPERIMENT_TARGET_KEY = 'experiment'

/**
 * This function sets the ad targeting for a single ad slot.
 *
 * @see https://developers.google.com/doubleclick-gpt/reference#googletag.Slot_updateTargetingFromMap
 */
function targetAd(conf: AdConfig, isPremiumUser: boolean, slot: Slot): void {
  slot.updateTargetingFromMap({
    [CONTENT_IDS_TARGET_KEY]: conf.targeting?.contentIds?.map(String) ?? [],
    [CATEGORY_SLUGS_TARGET_KEY]: conf.targeting?.categorySlugs ?? []
  })

  if (typeof isPremiumUser === 'boolean') {
    slot.setTargeting(PREMIUM_USER_TARGET_KEY, String(isPremiumUser))
  }

  if (typeof conf.targeting?.liveId === 'number') {
    slot.setTargeting(LIVE_ID_TARGET_KEY, String(conf.targeting.liveId))
  }

  if (typeof conf.targeting?.adPosition === 'number') {
    slot.setTargeting(AD_POSITION_TARGET_KEY, String(conf.targeting.adPosition))
  }

  if (typeof conf.targeting?.experiment === 'string') {
    slot.setTargeting(EXPERIMENT_TARGET_KEY, conf.targeting?.experiment)
  }
}

const GPT_AD_ACCOUNT_ID = '43625987'

@Injectable()
export class BrowserDisplayAdvertisingService implements DisplayAdvertisingService {
  /**
   * Emits a function which can be used to create the full ad directory path.
   *
   * @see processedAdConfig$ Usage Example
   */
  private makeFullAdPath$ = this.verticalService.siteSettings$.pipe(
    map(siteSettings => siteSettings.site_name),
    distinctUntilChanged(),
    map(siteName => (siteName ? buildSiteCode(siteName) : '')),
    map(siteCode => (conf: AdConfig): AdConfig => {
      return {
        ...conf,
        adDirectory: `/${GPT_AD_ACCOUNT_ID}/${siteCode}/${conf.adDirectory}`
      }
    }),
    shareReplay(1)
  )

  /** Queue a new ad to be loaded by passing it through this subject. */
  private readonly adConfig = new Subject<AdConfig>()

  private readonly adBlockerActive$ = this.adBlockService.isAnAdBlockerActive().pipe(take(1), shareReplay(1))

  /** Processes each ad config serially to load it via GPT. */
  // prettier-ignore
  private readonly _processedAdConfig$ = combineLatest([
    this.adConfig,
    this.aps.apsInitialized$,
  ]).pipe(
    map(([conf]) => conf),
    withLatestFrom(this.makeFullAdPath$),
    map(([conf, makeFullAdPath]) => makeFullAdPath(conf)),
    // This single concurrency merge map ensures only one ad will be loaded concurrently.
    // Ads will always be loaded in the order they are passed in.
    mergeMap(conf => this.defineAdSlot(conf), 1),
    mergeMap((data) => {
      return this.adBlockerActive$.pipe(
        mergeMap(active => active ? EMPTY : of(data))
      )
    }),
  )

  /**
   * This is split from the _processedAdConfig observable because the pipe was too
   * long to support good typing.
   */
  // prettier-ignore
  private readonly processedAdConfig$ = this._processedAdConfig$.pipe(
    mergeMap(([slot, conf]) => {
      return this.aps
        .fetchBids({ slots: [ApsSlotData(conf)] })
        .pipe(
          tap(_ => this.pubmatic.a9BidsReceived()),
          switchMap(_ =>
            this.pubmatic.requestBids([slot])
          ),
          map(_ => [slot, conf] as [Slot, AdConfig]),
        )
    }),
    withLatestFrom(this.auth.user$.pipe(map(user => user.isPremium))),
    mergeMap(([[slot, conf], isPremium]) => {
      return this.aps.setDisplayBids().pipe(
        tap(_ => targetAd(conf, isPremium, slot)),
        mergeMap(_ => {
          return this.gpt.display(slot.getSlotElementId())
        }),
        mergeMap(_ => this.refreshPubAds([slot])),
        map(_ => slot)
      )
    }),
    shareReplay<Slot>(1)
  )

  // prettier-ignore
  public constructor(
    @Inject(LOGGER_SERVICE) private readonly log: LoggerService,
    private readonly adBlockService: AdBlockService,
    private readonly auth: AuthService,
    private readonly aps: ApsService,
    private readonly gpt: GptService,
    private readonly verticalService: VerticalService,
    private readonly pubmatic: PubmaticService,
  ) {
    // We subscribe to this immediately to ensure ads load even if the caller doesn't subscribe.
    // If we let the components do this, a mistake would prevent all ads from loading.
    this.processedAdConfig$.subscribe()
  }

  /** @inheritDoc */
  public loadAd(config: AdConfig): Observable<Slot> {
    this.log.trace('Ad Service: Queuing new ad config.', { config })
    this.adConfig.next(config)
    return this.processedAdConfig$.pipe(
      filter(slot => slot.getSlotElementId() === config.adSlotId),
      take(1)
    )
  }

  /** @inheritDoc */
  public destroySlot(slot: Slot): Observable<boolean> {
    this.log.trace('Ad Service: Destroy Single Slot', {
      slot: slot.getSlotElementId()
    })
    return this.gpt.destroySlots([slot])
  }

  /** @inheritDoc */
  public refreshPubAds(slots?: Slot[] | null): Observable<void> {
    this.log.trace('Ad Service: Refresh Slots', {
      slots: slots?.map(s => s.getSlotElementId())
    })
    return this.gpt.refreshPubAds(slots)
  }

  /**
   * When refreshing an ad, we need to re-request our amazon and pubmatic bids first
   * For pubmatic, pwt.removeKeyValuePairsFromGPTSlots needs to be called before pwt.requestBids()
   * @see {@link https://flocasts.atlassian.net/browse/FLO-14059}
   */
  public refreshBids(slots: Slot[]): Observable<void> {
    this.log.trace('Ad Service: Refresh Bids', {
      slots: slots?.map(s => s.getSlotElementId())
    })

    return this.aps
      .fetchBids({
        slots: refreshApsSlotData(slots)
      })
      .pipe(
        switchMap(_ => this.pubmatic.removeKeyValuePairsFromGPTSlots(slots)),
        tap(_ => this.pubmatic.a9BidsReceived()),
        switchMap(_ => this.pubmatic.requestBids(slots)),
        switchMap(_ => this.refreshPubAds(slots))
      )
  }

  /** Obtain a stream of the GPT Viewability change events for an ad slot. */
  public getSlotVisibilityChanges(slot: Slot): Observable<SlotVisibilityChangedEvent> {
    return this.gpt.slotVisibilityChanged$.pipe(
      filter(event => event.slot.getSlotElementId() === slot.getSlotElementId())
    )
  }

  /** This event is fired when the creative code is injected into a slot */
  public getSlotRenderEndedEvent(slot: Slot): Observable<SlotRenderEndedEvent> {
    return this.gpt.slotRenderEnded$.pipe(filter(event => event.slot.getSlotElementId() === slot.getSlotElementId()))
  }

  private defineAdSlot(conf: AdConfig): Observable<[Slot, AdConfig]> {
    return this.gpt.defineSlot(conf).pipe(
      map(slot => [slot, conf] as [Slot, AdConfig]),
      take(1)
    )
  }
}
