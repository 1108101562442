import { REQUEST } from '@nguniversal/express-engine/tokens'
import { PlatformService } from '../../singleton-services/platform.service'
import { Observable } from 'rxjs'
import { Inject, Injectable, InjectionToken, Injector } from '@angular/core'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { AuthService } from '../../singleton-services/auth/auth.service'
import { UrlService } from '../../singleton-services/url.service'
import * as express from 'express'

export interface AnonRequestPatterns {
  [key: string]: ReadonlyArray<string>
}

export type RegExps = ReadonlyArray<RegExp>

export const AUTH_BEARER_HOSTS = new InjectionToken<RegExps>('auth.bearer.hosts')

export const ANON_REQUEST_PATTERNS = new InjectionToken<AnonRequestPatterns>('auth.cookie.anon')

export const AUTH_HEADERS_NO_SEND = new InjectionToken<RegExps>('auth.cookie.send')

@Injectable()
export class HttpJWTInterceptor implements HttpInterceptor {
  constructor(
    @Inject(AUTH_BEARER_HOSTS) private bearerHosts: RegExps,
    @Inject(AUTH_HEADERS_NO_SEND) private noCredPatterns: RegExps,
    @Inject(ANON_REQUEST_PATTERNS) private anonPatterns: AnonRequestPatterns,
    private ps: PlatformService,
    private injector: Injector,
    private url: UrlService
  ) {}

  useBearerCredentials = (req: HttpRequest<any>) => this.bearerHosts.some(exp => exp.test(req.url))

  get bearerToken() {
    const token = this.injector.get(AuthService).getTokenFromStore()
    return token ? `Bearer ${token}` : undefined
  }

  get serverCookies() {
    const serverRequest = this.injector.get(REQUEST) as express.Request

    return Object.keys(serverRequest.cookies || {}).reduce(
      (accumulator, cookieName) => `${accumulator}${cookieName}=${serverRequest.cookies[cookieName]};`,
      ''
    )
  }

  bearerTokenBasedRequest(req: HttpRequest<any>) {
    if (
      /streams\/\d+\/tokens/.test(req.url) ||
      /tokens\/.+\/logs/.test(req.url) ||
      /tokens\/.+\/heartbeat/.test(req.url)
    ) {
      return req.clone({
        withCredentials: false,
        headers: req.headers.set('Authorization', this.bearerToken || 'Bearer')
      })
    } else {
      return !this.bearerToken
        ? req.clone({ withCredentials: false })
        : req.clone({
            withCredentials: false,
            headers: req.headers.set('Authorization', this.bearerToken)
          })
    }
  }

  cookieBasedRequest(req: HttpRequest<any>) {
    const withCredentials = !this.noCredPatterns.some(a => (req.url.match(a) ? true : false))

    return this.ps.isBrowser
      ? req.clone({ withCredentials })
      : req.clone({
          withCredentials,
          headers: req.headers.set('Cookie', this.serverCookies)
        })
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const applyToAllHosts = this.anonPatterns['*'] || []
    const base = this.url.parseBaseDomain(req.url)
    const anonRequest =
      applyToAllHosts.some(a => req.url.includes(a)) ||
      (this.anonPatterns[base] && applyToAllHosts.some(a => req.url.includes(a)))

    return anonRequest
      ? next.handle(req)
      : this.useBearerCredentials(req)
      ? next.handle(this.bearerTokenBasedRequest(req))
      : next.handle(this.cookieBasedRequest(req))
  }
}
