import { Inject, Injectable } from '@angular/core'
import { HttpParams } from '@angular/common/http'
import { Observable, of } from 'rxjs'
import { EXPERIENCE_API_VERSION, EXPERIENCE_API_HOME_BASE_PATH } from 'src/app/app.config'
import { MultipleSectionsViewModel } from '@flocasts/experience-service-types'
import { LOGGER_SERVICE } from 'src/app/logger/logger.config'
import { LoggerService } from 'src/app/logger/logger.interface'
import { catchError, map } from 'rxjs/operators'
import { CacheDataService } from 'src/app/singleton-services/cache-data.service'

@Injectable()
export class HomeDataService {
  constructor(
    @Inject(LOGGER_SERVICE) private readonly logger: LoggerService,
    private readonly cacheDataService: CacheDataService
  ) {}

  public getHomeSections(
    siteId: number,
    isInEventHubBracketsVariation?: boolean
  ): Observable<MultipleSectionsViewModel> {
    const params = new HttpParams({
      fromObject: {
        version: EXPERIENCE_API_VERSION,
        site_id: siteId.toString(),
        isEventHubLayoutEnabled: true,
        ...(!!isInEventHubBracketsVariation && { isEventHubBracketsEnabled: isInEventHubBracketsVariation.toString() })
      }
    })

    return this.cacheDataService.wrapper<MultipleSectionsViewModel>(EXPERIENCE_API_HOME_BASE_PATH, params, true).pipe(
      map(res => res ?? ({} as MultipleSectionsViewModel)),
      catchError(err => {
        this.logger.error(err.message, err.error)
        return of({} as MultipleSectionsViewModel)
      })
    )
  }
}
