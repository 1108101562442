import { SkeletonTheme } from './skeleton.interface'

export const SKELETON_THEMES: Record<string, { route: string; theme: SkeletonTheme }> = {
  ['collections']: { route: '/collections/', theme: 'collection' },
  ['event-hub']: { route: '/events/', theme: 'event-hub' },
  ['choose-favorites']: { route: '/account/choose-favorites', theme: 'choose-favorites' },
  ['team']: { route: '/teams/', theme: 'collection' },
  ['teams']: { route: '/teams', theme: 'category-list' },
  ['person']: { route: '/people/', theme: 'collection' },
  ['people']: { route: '/people', theme: 'category-list' }
}

export function getSkeletonThemeFromUrl(url: string): SkeletonTheme | null {
  for (const key in SKELETON_THEMES) {
    if (url.includes(SKELETON_THEMES[key].route)) {
      return SKELETON_THEMES[key].theme
    }
  }

  return null
}
