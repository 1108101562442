import { SegmentEvents } from '../analytics/models/segment-events.model'
import { Inject, Injectable, NgZone } from '@angular/core'
import { SegmentService } from '../analytics/services/segment.service'
import { LOGGER_SERVICE } from '../../logger/logger.config'
import { LoggerService } from '../../logger/logger.interface'
import { WINDOW } from '../../app.injections'

/**
 * Osano external Javascript library, included in /src/index.html:4
 *
 * Shows the drawer that contains cookie settings/permissions for users to manage
 *
 * @see {@link https://docs.osano.com/trigging-the-drawer-storage-preferences}
 */
export interface OsanoInterface extends Window {
  Osano?: {
    cm: {
      showDrawer(arg: 'osano-cm-dom-info-dialog-open' | string): void
    }
  }
}

@Injectable()
export class OsanoService {
  /**
   * Open the Storage (cookie) Preferences drawer, using an external JS library
   */
  public openOsano(): void {
    this.segment.trackEvent({
      name: SegmentEvents.LINK_CLICKED,
      properties: {
        name: 'Cookie Settings'
      }
    })

    // @see {@link https://docs.osano.com/trigging-the-drawer-storage-preferences}
    this.zone.runOutsideAngular(() => {
      // try/catch to catch any errors from Osano
      try {
        if (this.window && (this.window as OsanoInterface).Osano) {
          // prettier-ignore
          (this.window as OsanoInterface).Osano?.cm.showDrawer('osano-cm-dom-info-dialog-open')
        } else {
          throw new Error("TypeError: Cannot read property 'Osano' of undefined")
        }
      } catch (error) {
        this.log.info('Osano.cm.showDrawer() failed', { error: error.toString() })
      }
    })
  }

  constructor(
    private readonly zone: NgZone,
    private readonly segment: SegmentService,
    @Inject(LOGGER_SERVICE) private readonly log: LoggerService,
    @Inject(WINDOW) private readonly window: unknown
  ) {}
}
