import { Inject, Injectable, Renderer2 } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { Observable, of } from 'rxjs'
import { InjectionService } from './injection.service'

@Injectable({
  providedIn: 'root'
})
export class PollDaddyService {
  get pollDaddyElements() {
    return Array.from(this.doc.querySelectorAll('div.PDS_Poll'))
  }

  constructor(@Inject(DOCUMENT) private doc: HTMLDocument, private injectionService: InjectionService) {}

  public load(renderer: Renderer2): Observable<any> {
    if (this.pollDaddyElements.length === 0) return of(undefined)

    const scripts = this.pollDaddyElements
      .filter((element: any) => element.dataset && element.dataset.id)
      .map((element: any) => `https://static.polldaddy.com/p/${element.dataset.id}.js`)
    return this.injectionService.injectScripts(renderer, scripts)
  }

  public destroyAll() {
    const scriptTag = Array.from(this.doc.getElementsByTagName('script'))
    const polldaddy = scriptTag.filter(tag => tag.src.includes('polldaddy'))
    polldaddy.forEach((poll: any) => {
      poll.remove()
    })
    this.pollDaddyElements.forEach(el => el.remove())
  }
}
