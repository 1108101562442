<a
  *ngIf="props"
  floAnalytics
  [analyticsProperties]="props.analyticsProps"
  tagName="List Item"
  elementName="Featured Content Card"
  class="featured-content-card d-flex flex-column flex-md-row cursor-pointer image-opacity-hover primary-color-text-hover rounded"
  [floCustomLink]="props.destination"
>
  <div class="featured-content-card__image no-rounded-bottom image h-auto image-opacity-hover-target col-md-6 p-0">
    <flo-image-thumbnail
      [imageUrl]="props.imageThumbnailProps.imageUrl"
      [imageSize]="props.imageThumbnailProps.imageSize"
      [altText]="props.imageThumbnailProps.altText"
      [duration]="props.imageThumbnailProps.duration"
      [mobileSize]="props.imageThumbnailProps.mobileSize"
    >
      <div *ngIf="props.badgeText !== null" class="featured-content-card__image-badge-text d-flex flex-row w-100">
        <div class="img-overlay-badge caption">{{ props.badgeText }}</div>
      </div>
    </flo-image-thumbnail>
  </div>
  <div class="featured-content-card__text d-flex flex-column col-md-6">
    <h4 class="featured-content-card__title font-family-heavy h4 text-line-clamp-2 apply-text-hover">
      {{ props.displayTitle }}
    </h4>
    <p *ngIf="props.subtext !== null"  class="featured-content-card__subtext color-500 subtext footnote">
      {{ props.subtext }}
    </p>
    <p *ngIf="props.description !== null" class="featured-content-card__description color-500 description text-line-clamp-2 m-0 footnote">
      {{ props.description }}
    </p>
  </div>
</a>
