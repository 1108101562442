import { IAnalyticsNode, INode } from '../models/node.model'
import { Inject, Injectable, Renderer2 } from '@angular/core'
import { IVideo } from './video.service'
import { DOCUMENT } from '@angular/common'
import { Meta, Title, MetaDefinition } from '@angular/platform-browser'
import type { Author } from '@flocasts/flosports30-types/dist/entity'
import { capitalizeFirstChar } from '../utility-functions/string.utility'

const OG_IMAGE_WIDTH = '680'
const OG_IMAGE_HEIGHT = '382'
export interface INodeService {
  applyNodeMetaToPage(node: INode): void
  applyVideosMetaToPage(renderer2: Renderer2, videos: ReadonlyArray<IVideo>, primaryVideoId?: number): void
  getPropsFromNode(node: NodePropsProvider): IAnalyticsNode
}

const createImageMetaDefinitions = (assetUrl: string): ReadonlyArray<MetaDefinition> => {
  return [
    { property: 'og:image', content: assetUrl },
    { property: 'og:image:width', content: OG_IMAGE_WIDTH },
    { property: 'og:image:height', content: OG_IMAGE_HEIGHT }
  ]
}

/**
 * @deprecated; see @TrackPageDetails
 */
export type NodePropsProvider = Pick<INode, 'premium' | 'live_event' | 'id' | 'node' | 'type'> & {
  author: Author | null
}

/**
 * @deprecated Because of the generalization of this service, and the incorrect types,
 * we have to either completely refactor this service or move to the use of a new
 * service such as PageMetaService.
 *
 * @see PageMetaService
 */
@Injectable()
export class NodeService implements INodeService {
  private doc: HTMLDocument
  private head: HTMLHeadElement

  constructor(private meta: Meta, private title: Title, @Inject(DOCUMENT) doc: HTMLDocument) {
    this.doc = doc
    this.head = this.doc.head
  }

  setTitle(title: string) {
    this.title.setTitle(title)
    this.meta.updateTag({ property: 'og:title', content: title })
  }

  setDescription(description: string) {
    this.meta.updateTag({ name: 'description', content: description })
    this.meta.updateTag({ property: 'og:description', content: description })
  }

  setImage(assetUrl: string) {
    createImageMetaDefinitions(assetUrl).forEach(def => {
      this.meta.updateTag(def)
    })
  }

  /**
   * Clear the social image meta tags.
   *
   * Removes og:image along with its height and width tags.
   */
  public clearImage() {
    this.meta.removeTag('property="og:image"')
    this.meta.removeTag('property="og:image:width"')
    this.meta.removeTag('property="og:image:height"')
  }

  /**
   * @deprecated
   * @see PageMetaService
   */
  applyNodeMetaToPage(node: INode): void {
    if (!node) {
      return
    }

    const description = node.seo_description || (node as any).description_plain || 'No description provided'
    this.setTitle(node.title)
    this.setDescription(description)
    if (node.asset && node.asset.url) {
      this.setImage(node.asset.url)
    }
  }

  applyVideosMetaToPage(renderer: Renderer2, videos: ReadonlyArray<IVideo>, firstVideo?: number) {
    renderer.setAttribute(
      this.head,
      'prefix',
      'og: http://ogp.me/ns# fb: http://ogp.me/ns/fb# video: http://ogp.me/ns/video#'
    )
    this.meta.updateTag({ property: 'og:type', content: 'video.other' })

    const _videos = firstVideo
      ? ([videos.find(v => v.id === firstVideo), ...videos.filter(v => v.id !== firstVideo)].filter(
          a => a !== undefined
        ) as ReadonlyArray<IVideo>)
      : videos

    if (_videos.length === 0) {
      return
    }
    if (_videos[0].title) {
      this.setTitle(_videos[0].title as string)
    }
    if (_videos[0].description_plain) {
      this.setDescription(_videos[0].description_plain as string)
    }
    if (_videos[0].shareable_link) {
      const canonicalLinkElement = renderer.createElement('link') as HTMLLinkElement
      canonicalLinkElement.setAttribute('rel', 'canonical')
      canonicalLinkElement.setAttribute('href', _videos[0].shareable_link)
      renderer.appendChild(this.head, canonicalLinkElement)
    }

    this.meta.updateTag({ property: 'og:image', content: '' })

    const videoTags = _videos.reduce((acc, cur: IVideo) => {
      const imgUrl = cur.asset && cur.asset.url
      const baseProps: ReadonlyArray<MetaDefinition> = [
        { property: 'og:video', content: cur.shareable_link },
        { property: 'og:video:secure_url', content: cur.shareable_link },
        { property: 'og:video:type', content: 'application/x-shockwave-flash' }
      ]

      const finalProps = imgUrl ? [...baseProps, ...createImageMetaDefinitions(imgUrl)] : baseProps

      return [...acc, ...finalProps]
    }, [])

    this.meta.addTags(videoTags)
  }

  public getPropsFromNode(node: NodePropsProvider): IAnalyticsNode {
    return {
      author: node.author ? `${node.author?.first_name} ${node.author?.last_name}` : undefined,
      is_premium: node.premium,
      live_id: node.live_event && node.live_event.id,
      live_event_status: node.live_event && node.live_event.status,
      node_id: node.id,
      node_type: node.type && capitalizeFirstChar(node.type),
      primary_association_id:
        node.node && node.node.primary_event_association && node.node.primary_event_association.id,
      section: node.type && `${capitalizeFirstChar(node.type)}s`
    }
  }
}
