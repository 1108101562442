import { ChangeDetectionStrategy, Component, Input, TrackByFunction } from '@angular/core'
import type { IconModel, WebDecoratedTextCellModel } from '@flocasts/experience-service-types'

@Component({
  selector: 'flo-decorated-text-cell',
  templateUrl: './decorated-text-cell.component.html',
  styleUrls: ['./decorated-text-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DecoratedTextCellComponent {
  @Input()
  item: WebDecoratedTextCellModel

  // Track by to reduce DOM repainting as new options are dynamically loaded
  trackByStyle: TrackByFunction<IconModel> = (index: number, icon: IconModel) => {
    return icon.style
  }
}
