import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { WebFlyOutViewModel } from '@flocasts/experience-service-types'
import { SiteIds } from 'src/app/shared/models/site.model'
import { trackById } from 'src/app/shared/utility-functions/track-by-id.utility'

@Component({
  selector: 'flo-fly-out',
  templateUrl: './fly-out.component.html',
  styleUrls: ['./fly-out.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlyOutComponent {
  @Input() flyOut: WebFlyOutViewModel
  @Input() siteId: number
  @Input() show = false

  @Output() showFlyOut = new EventEmitter<boolean>()

  readonly trackById = trackById

  public readonly varsitySiteId = SiteIds.VARSITY
  public readonly floSportsSiteId = SiteIds.FLOSPORTS

  public handleExitClick() {
    this.showFlyOut.emit(false)
  }
}
