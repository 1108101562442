<div class="carousel-wrapper" *ngIf="carouselData">
  <!-- carousel controls -->
  <input
    type="radio"
    name="carousel"
    id="carousel-{{ i + 1 }}"
    (change)="onInputChange($event)"
    [checked]="i + 1 == (selectedCarouselSlide | async)"
    *ngFor="
      let carouselInstance of carouselData;
      trackBy: trackCarousel;
      index as i
    "
  />

  <div class="carousel-nav-wrapper">
    <div class="carousel-nav">
      <label
        class="carousel-nav-label primary-color-border-bottom"
        for="carousel-{{ i + 1 }}"
        *ngFor="
          let carouselInstance of carouselData;
          trackBy: trackCarousel;
          index as i
        "
      >
        <h6 class="carousel-nav-label-text subheader">
          {{ carouselInstance.label }}
        </h6>
      </label>
    </div>
  </div>

  <div class="carousel-slides">
    <div
      *ngFor="let carouselInstance of carouselData; trackBy: trackCarousel"
      class="image-overlay-text-wrapper"
    >
      <div
        class="image-overlay-text"
        [ngStyle]="carouselBackgroundGen(carouselInstance)"
        data-test="carousel-control"
      >
        <div class="{{ trackWrestlingCssId }} content-wrapper px-4 position-relative">
          <h2 class="funnel-title carousel-title" [innerHtml]="carouselInstance.title | floSanitizeHtml"></h2>
          <p class="carousel-paragraph" [innerHtml]="carouselInstance.paragraph | floSanitizeHtml"></p>
        </div>
      </div>
    </div>
  </div>
</div>
