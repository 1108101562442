import { Component } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { map, startWith, shareReplay } from 'rxjs/operators'
import { filterDeepRouterDataByPredicate } from '../shared/rx-pipes/router'

@Component({
  selector: 'flo-funnel',
  templateUrl: './funnel.component.html',
  styleUrls: ['./funnel.component.scss']
})
export class FunnelComponent {
  constructor(private ar: ActivatedRoute, private router: Router) {}

  public isCouponApplied$ = this.router.events.pipe(
    filterDeepRouterDataByPredicate(this.ar)(a => a.data),
    map(data => data.coupon),
    startWith({ showRibbon: true, hasBeenApplied: false }), // have to start with true so it shows up on first page server-loaded in funnel
    map(couponRouteData => couponRouteData && couponRouteData.hasBeenApplied),
    shareReplay(1)
  )

  // FLO-9032 License Plate Activation Route
  public isActivateRedirect() {
    return this.ar.snapshot.queryParams.redirect === '/activate'
  }
}
