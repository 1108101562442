<ng-container *ngIf="(navModel$ | async) as navigation">
  <ng-container *ngIf="navigation?.primary">
    <flo-primary-navigation [nav]="navigation?.primary" [siteId]="siteId$ | async" (showFlyOut)="handleClick($event)"></flo-primary-navigation>
  </ng-container>
  <ng-container *ngIf="(showNavV2$ | async)">
    <ng-container *ngIf="navigation?.subnavigation">
      <flo-subnavigation [subnavigation]="navigation?.subnavigation" class="site-navigation-subnav"></flo-subnavigation>
    </ng-container>
  </ng-container>
</ng-container>
<flo-fly-out [flyOut]="(flyOut$ | async)" [siteId]="siteId$ | async" [show]="showFlyOut$ | async" (showFlyOut)="handleClick($event)"></flo-fly-out>
