<div class="d-flex justify-content-between pb-1">
  <div class="d-flex align-items-center gap-2 flex-grow-1">
    <ng-container *ngIf="link?.asset?.type === 'icon'">
      <a
        floAnalytics
        [analyticsProperties]="link.analytics"
        data-test="back-link"
        (click)="ctaClick(link)"
      >
        <flo-svg-icon
          [ngClass]="link.asset?.style | floCssClassSanitize"
          [icon]="link.asset?.style"
          class="d-flex text-900"
          width="24px"
          height="24px"
          data-test="back-icon-link"
        ></flo-svg-icon>
      </a>
    </ng-container>
    <div class="d-flex justify-content-between align-items-center w-100">
      <h2 class="h5 mb-0">{{ title }}</h2>
      <span
        *ngIf="cta"
        class="footnote color-500 cursor-pointer hover-underline"
        data-test="cta"
        (click)="ctaClick($event)"
        >{{ cta }}
      </span>
    </div>
  </div>
  <ng-container *ngIf="link?.url && link?.title">
    <a
      floAnalytics
      [analyticsProperties]="link.analytics"
      data-test="link-title"
      class="footnote color-500 hover-underline hover-grey-500 mb-0 pe-3 pe-sm-0"
      (click)="ctaClick(link)"
    >
      {{ link.title }}
    </a>
  </ng-container>
</div>