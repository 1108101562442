import { Inject, Injectable, InjectionToken } from '@angular/core'
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { timeout } from 'rxjs/operators'

export const DEFAULT_HTTP_TIMEOUT = new InjectionToken<number>('app.defaultTimeout')

@Injectable()
export class HttpTimeoutInterceptor implements HttpInterceptor {
  constructor(@Inject(DEFAULT_HTTP_TIMEOUT) private readonly defaultTimeout: number) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    return next.handle(req).pipe(timeout(this.getTimeout(req)))
  }

  getTimeout(req: HttpRequest<any>) {
    return Number(req.headers.get('timeout')) || this.defaultTimeout
  }
}
