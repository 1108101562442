import { BehaviorSubject, Observable } from 'rxjs'
import { shareReplay } from 'rxjs/operators'
import { Injectable } from '@angular/core'

@Injectable()
export class CouponStatusUtility {
  public readonly hideCouponErrorSource = new BehaviorSubject<boolean>(false)

  public readonly hideCouponError$: Observable<boolean> = this.hideCouponErrorSource.asObservable().pipe(shareReplay(1))
}
