import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { PlatformService } from '../../singleton-services/platform.service'
import { EnvironmentService } from '../../singleton-services/environment.service'
import { map, catchError } from 'rxjs/operators'
import { IResult, ok, fail } from 'typescript-monads'
import { GeoBlockingService } from '../../singleton-services/geo-blocking.service'

export interface GeoIPResponseItem {
  readonly id: number
  readonly statusCode: number
  readonly message: string
}
export type GeoIPItems = ReadonlyArray<GeoIPResponseItem>
export interface GeoIPResponse {
  readonly data: GeoIPItems
}

export interface GeoIPLoadFailure {
  readonly message: string
  readonly statusCode: number
}

export type GeoIPFetchResult = IResult<GeoIPItems, GeoIPLoadFailure>
export type GeoIPFetchResultResponse = Observable<GeoIPFetchResult>

export interface IGeoIPService {
  fetch(liveEventIds: ReadonlyArray<string>): GeoIPFetchResultResponse
}

const safeMapToCollection = <T extends GeoIPResponse>(source: Observable<T>) =>
  source.pipe(map(a => (a && a.data) || []))

const mapToOkResult = (source: Observable<GeoIPItems>) => source.pipe(map<GeoIPItems, GeoIPFetchResult>(ok))

const catchAndMapError = <T>(source: Observable<T>) =>
  source.pipe(catchError(apiFailureResponse => of(fail<GeoIPItems, GeoIPLoadFailure>(apiFailureResponse))))

const emptyResponse = of<GeoIPResponse>({ data: [] })

/**
 * @deprecated
 * @see {GeoBlockingService}
 *
 * Please follow patterns established in WatchComponent and EventDetailViewComponent
 */
@Injectable()
export class GeoIPService implements IGeoIPService {
  constructor(
    private readonly http: HttpClient,
    private readonly env: EnvironmentService,
    private readonly ps: PlatformService
  ) {}

  /**
   * Check if a users IP address should be able to watch a list of events matching the supplied eventIds
   * Does not execute during server-side render
   *
   * Responses:
   *   207: MultipleEventGeoCheck
   *   400: InvalidApiRequest
   *   404: EventNotFound
   *   504: GatewayTimeout
   */
  public readonly fetchRaw = (liveEventIds: ReadonlyArray<string>) =>
    this.ps.isServer
      ? emptyResponse
      : !liveEventIds.length
      ? emptyResponse
      : this.http.get<GeoIPResponse>(`${this.env.config.endpoints.live}/events/geo`, {
          params: new HttpParams({
            fromObject: { eventIds: liveEventIds.join(',') }
          }),
          headers: new HttpHeaders({ Authorization: 'Bearer' })
        })

  /**
   * Check if a users IP address should be able to watch a list of events matching the supplied eventIds.
   * Does not execute during server-side render
   */
  public readonly fetch = (liveEventIds: ReadonlyArray<string>): GeoIPFetchResultResponse =>
    this.fetchRaw(liveEventIds).pipe(safeMapToCollection, mapToOkResult, catchAndMapError)
}
