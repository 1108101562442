// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { EnvConfig } from './environment.interface'
import { svgCacheBuster } from './cache-busters'
import { StatusType } from '@datadog/browser-logs'

export const environment: EnvConfig = {
  browserLogger: {
    level: StatusType.info
  },
  serverLogger: {
    level: 'info',
    prettyPrint: false,
    redact: []
  },
  name: 'FloSports',
  description: 'FloSports',
  pageTitleSeparator: ' - ',
  useSecureCookies: false,
  defaultPrimaryColor: '#FD8224',
  appName: 'flosports-webapp',
  ddAgentHost: '',
  server: {
    host: 'http://127.0.0.1',
    port: 8000
  },
  rollbarBrowserToken: '6f7a8c19fd8840d199b445cf4cc37539',
  rollbarServerToken: '6f7a8c19fd8840d199b445cf4cc37539',
  youboraAccountCode: 'flosportsdev',
  youboraHost: 'wapn1.flosports.tv',
  useProdSettings: false,
  production: true,
  env: 'stag',
  endpoints: {
    discovery: 'https://staging-api.flodogs.com',
    cdn: 'http://www.flodogs.local:8000',
    live: 'https://staging-live-api-3.flosports.tv',
    loggingApi: 'https://player-log-service.stag.flokubernetes.com',
    floarenaApi: 'https://staging-floarena-api.flowrestling.org',
    identityApi: 'https://staging-identity-api.flosports.tv',
    statx: 'https://statx.stag.flokubernetes.com',
    userPrefs: 'https://user-preferences.stag.flokubernetes.com',
    continueWatching: 'https://continue-watching.stag.flokubernetes.com',
    entitlements: 'https://entitlements.stag-aws.flokubernetes.com',
    offers: 'https://offers.stag-aws.flokubernetes.com',
    payments: 'https://payments.stag-aws.flokubernetes.com'
  },
  firebase: {
    apiKey: 'AIzaSyCqTFB52GZm3uoTigdgpoFpe5tPVeq0RnM',
    authDomain: 'staging-live30-194a6.firebaseapp.com',
    databaseURL: 'https://staging-live30-194a6.firebaseio.com',
    projectId: 'staging-live30-194a6',
    storageBucket: 'staging-live30-194a6.appspot.com',
    messagingSenderId: '933702409934'
  },
  sportDatabase: {
    apiKey: 'AIzaSyA4252zCRABSuHfpmYQcpn2CxAKZCuPX3Q',
    authDomain: 'flosportdata-staging.firebaseapp.com',
    databaseURL: 'https://flosportdata-staging.firebaseio.com',
    projectId: 'flosportdata-staging',
    storageBucket: 'flosportdata-staging.appspot.com',
    messagingSenderId: '907319541919',
    appId: '1:907319541919:web:281f9eed5a14342113a2aa'
  },
  googleAdsVerificationCode: 'test',
  svgCacheBuster,
  advertising: {
    preventClickTimeout: 2000
  },
  firestoreApiHost: 'https://firestore.googleapis.com/v1/projects/',
  levelCategoryId: 39310,
  governingBodyCategoryId: 38950,
  recaptcha: {
    siteKey: '6LdBaOIjAAAAAEpWLiW1rAilaYtPYvD9F2mlTLex'
  },
  stripeKey:
    'pk_test_51MCtosHQxQQIDMAvLdeRBBs2O4KwIxhiM468rrE7Lue4t4rFsBa8r5OaaHh5Vr6OiOyQszwnRmeoezBPCyVMhm4i00hoblngF5',
  growthBook: {
    clientKey: 'sdk-1cwatJKv2KA7Pb9j',
    enableDevMode: true,
    subscribeToChanges: false,
    backgroundSync: false,
    apiHost: 'https://cdn.growthbook.io'
  }
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
