<ng-container *ngIf="!error && !!image?.url; else noImage">
  <img class="no-skeleton w-100 h-100" 
    data-test="image"
    [ngClass]="image.style | floCssClassSanitize"
    [style.transform]="'scale(' + image.zoom + ')'"
    [src]="image.url"
    [alt]="image.altText ? image.altText : ''"
    (error)="handleError()">
</ng-container>
<ng-template #noImage>
  <div 
    data-test="no-image" 
    class="no-image w-100 h-100"
    [class.rounded]="image?.style !== 'circle'"
    [ngClass]="image?.style | floCssClassSanitize">
  </div>
</ng-template>
