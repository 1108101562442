import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { VerticalService } from '../../../singleton-services/vertical.service'
import { map, shareReplay } from 'rxjs/operators'
import { SiteSettings } from 'src/app/shared/models/site-settings.model'
import { SiteIds } from 'src/app/shared/models/site.model'

export enum FunnelHeaderPromptStatus {
  HIDDEN = 'hidden',
  SHOW_LOGIN = 'login',
  SHOW_SIGNUP = 'signup'
}

@Component({
  selector: 'flo-funnel-checkout-header',
  templateUrl: './funnel-checkout-header.component.html',
  styleUrls: ['./funnel-checkout-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FunnelCheckoutHeaderComponent {
  @Input()
  readonly showPrompt: FunnelHeaderPromptStatus = FunnelHeaderPromptStatus.HIDDEN
  @Input()
  readonly showDarkLogo = true
  @Input()
  public analyticsProps: any
  @Input()
  public isCentered = false
  @Input()
  public siteId?: SiteIds

  public config$ = this.verticalService.siteSettings$.pipe(
    map((settings: SiteSettings) => {
      this.siteId = this.siteId ?? settings.site_id
      return {
        siteName: settings.site_name,
        ngClass: {
          'dark-alternate': this.showDarkLogo,
          alternate: !this.showDarkLogo
        },
        showLoginPrompt: this.showPrompt === FunnelHeaderPromptStatus.SHOW_LOGIN,
        showJoinNowPrompt: this.showPrompt === FunnelHeaderPromptStatus.SHOW_SIGNUP
      }
    })
  )

  public isVarsity$ = this.verticalService.isVarsity$.pipe(shareReplay(1))

  constructor(public verticalService: VerticalService) {}
}
