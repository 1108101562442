import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { LinkComponent } from './link.component'
import { CustomLinkModule } from 'src/app/libs/custom-link/custom-link.directive.module'
import { FloCssClassSanitizePipeModule } from 'src/app/libs/_pipes/css-class-sanitize/css-class-sanitize.pipe.module'
import { RouterModule } from '@angular/router'
import { AnalyticsModule } from 'src/app/shared/analytics/analytics.module'
import { SvgModule } from 'src/app/shared/svg/svg.module'
import { FloTypeGuardModule } from 'src/app/shared/pipes/flo-type-guard-pipe.module'
import { ImageModule } from '../image/image.component.module'

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CustomLinkModule,
    AnalyticsModule,
    FloCssClassSanitizePipeModule,
    SvgModule,
    FloTypeGuardModule,
    ImageModule
  ],
  exports: [LinkComponent],
  declarations: [LinkComponent]
})
export class LinkModule {}
