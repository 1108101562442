import { Injectable } from '@angular/core'
import { UntypedFormGroup } from '@angular/forms'

@Injectable()
export class FormService {
  /**
   * Means only meant to highlight the field
   */
  public static readonly ERROR_FIELD_ONLY: string = 'field_only'

  /**
   * Gets the FOS Symfony error and processes it
   */
  public processErrors(serverErrors: any, form: UntypedFormGroup): void {
    // todo
  }

  /**
   * A way to determine whether to show error message
   */
  public showErrorMessage(form: UntypedFormGroup, fieldName: string): boolean {
    const field = form.get(fieldName)

    if (!field) return false

    return field.invalid && field.touched
  }
}
