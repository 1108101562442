import { APP_INITIALIZER, NgModule, PLATFORM_ID, ModuleWithProviders } from '@angular/core'
import { floPerformanceInitFactory } from './performance-metrics.service'
import { FloPerformanceMetricsModuleOpts } from './performance-metrics.interface'
import { DEFAULT_FLO_PERF_INTERVAL_MS } from './performance-metrics.defaults'
import {
  FLO_PERF_INTERVAL_MS,
  FLO_PERF_TRACKING_PROVIDER,
  FLO_PERF_WINDOW,
  FLO_PERF_SCHEDULER
} from './performance-metrics.tokens'

@NgModule({
  providers: [
    { provide: FLO_PERF_INTERVAL_MS, useValue: DEFAULT_FLO_PERF_INTERVAL_MS },
    {
      provide: FLO_PERF_WINDOW,
      useValue: typeof window === 'undefined' ? undefined : window
    },
    { provide: FLO_PERF_SCHEDULER, useValue: undefined },
    {
      provide: APP_INITIALIZER,
      useFactory: floPerformanceInitFactory,
      deps: [PLATFORM_ID, FLO_PERF_TRACKING_PROVIDER, FLO_PERF_INTERVAL_MS, FLO_PERF_SCHEDULER, FLO_PERF_WINDOW],
      multi: true
    }
  ]
})
export class FloPerformanceMetricsModule {
  static config(config: Partial<FloPerformanceMetricsModuleOpts>): ModuleWithProviders<FloPerformanceMetricsModule> {
    return {
      ngModule: FloPerformanceMetricsModule,
      providers: [
        {
          provide: FLO_PERF_INTERVAL_MS,
          useValue: typeof config.intervalMs === 'number' ? config.intervalMs : DEFAULT_FLO_PERF_INTERVAL_MS
        }
      ]
    }
  }
}
