import { Observable } from 'rxjs'
import { Inject, Injectable, InjectionToken, Injector } from '@angular/core'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http'
import { PlatformService } from '../../singleton-services/platform.service'
import { tap } from 'rxjs/operators'
import { ExperimentationService } from '../experimentation/experimentation.service'

export const HTTP_API_EXPERIMENT_INCLUDED_URLS = new InjectionToken<ReadonlyArray<string>>('app.http.api.include')
@Injectable()
export class HttpApiExperimentViewedInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private platformService: PlatformService,
    @Inject(HTTP_API_EXPERIMENT_INCLUDED_URLS) private included: ReadonlyArray<string>
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.platformService.isServer
      ? next.handle(req)
      : next.handle(req).pipe(
          tap(event => {
            if (event instanceof HttpResponse && this.included.some(a => new RegExp(a).test(req.url))) {
              const experimentFlags = event.headers.getAll('x-flo-flags')

              if (!!experimentFlags) {
                ;(experimentFlags.filter(Boolean).pop() || '')
                  .split('|')
                  .filter(Boolean)
                  .forEach(exp => this.injector.get(ExperimentationService).activateApiExperiment(exp))
              }
            }
          })
        )
  }
}
