import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'flo-play-icon',
  templateUrl: './video-play-icon.component.html',
  styleUrls: ['./video-play-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoPlayIconComponent implements OnInit {
  private showIcon: boolean
  public showDuration = false

  /**
   * @deprecated
   * This is for backwards compatibility for Core API duration type.
   * As we move towards Experience Service, we will not need this
   */
  @Input() duration: number | null

  @Input()
  set showPlayIcon(val: boolean) {
    this.showIcon = val || (this.duration != null && this.duration > 0)
  }
  get showPlayIcon() {
    return this.showIcon
  }

  ngOnInit() {
    this.showDuration = this.duration !== null && this.duration > 0
  }
}
