<div
  class="no-rounded-bottom calendar-tile-wrapper"
  *ngIf="event$ | async as event"
>
  <flo-image-thumbnail
    [imageUrl]="event?.asset?.url"
    [imageSize]="250"
    [altText]="event?.title"
  ></flo-image-thumbnail>
  <div class="calendar-tile p-4 pt-45">
   
    <div *ngIf="geoBlockedIds?.includes(event?.live_id)" class="geoblock-label px-3 py-1">Not available in your location</div>
    <h4 class="event-date primary-color-text small">
      <flo-svg-icon *ngIf="event.live_event?.status === 'LIVE' || event.live_event?.status === 'INTERMISSION'" class="badge-live" [width]="29" icon="badge-live"></flo-svg-icon>
      {{ event.live_event?.start_date | floDate: event.live_event?.end_date }}
    </h4>
    <p *ngIf="event?.live_event?.start_date_time | date: 'shortTime' as startDateTime"
       class="event-time flo-footnote">{{ event.live_event?.start_time_tbd ? 'TBD' : startDateTime | lowercase }}</p>
    <h5 class="event-title header-font">{{ event.short_title }}</h5>
  </div>
</div>
