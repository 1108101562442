import { UserService } from '../shared/services/user.service'
import { VideoPlayerModule } from '../shared/video-player/video-player.module'
import { SubscriptionService } from '../shared/services/subscription.service'
import { FunnelComponent } from './funnel.component'
import { FunnelRoutingModule } from './funnel-routing.module'
import { SharedModule } from '../shared/shared.module'
import { FunnelSharedModule } from './shared/funnel-shared.module'
import { NgModule } from '@angular/core'
import { FacebookAccessTokenService } from '../shared/services/facebook-access-token.service'
import { PayService } from '../shared/services/pay.service'
import { EventsService } from '../events/services/events.service'
import { LiveEventService } from '../live/services/event.service'
import { ProductService } from '../shared/services/product.service'
import { ActivationCodeService } from '../shared/services/activation-code.service'
import { CouponStatusComponent } from './coupon/coupon-status.component'
import { CouponStatusService } from './coupon/coupon-status.service'
import { CouponStatusUtility } from './coupon/coupon-status.utility'
import { FormErrorsModule } from '../shared/form-errors/form-errors.module'
import { CheckoutNavigationService } from '../shared/services/checkout-navigation.service'
import { LandingPageModule } from './landing-page/landing-page.module'
import { PlansModule } from './plans/plans.module'
import { GiftSubscriptionAnalyticsService } from '../gift-subscription/gift-subscription-analytics.service'

@NgModule({
  imports: [
    SharedModule,
    FunnelRoutingModule,
    FunnelSharedModule,
    VideoPlayerModule,
    FormErrorsModule,
    LandingPageModule,
    PlansModule
  ],
  exports: [VideoPlayerModule, FunnelComponent],
  declarations: [FunnelComponent, CouponStatusComponent],
  providers: [
    ProductService,
    SubscriptionService,
    FacebookAccessTokenService,
    UserService,
    PayService,
    EventsService,
    LiveEventService,
    ActivationCodeService,
    CouponStatusService,
    CouponStatusUtility,
    CheckoutNavigationService,
    GiftSubscriptionAnalyticsService
  ]
})
export class FunnelModule {}
