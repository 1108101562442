import { ModuleWithProviders, NgModule } from '@angular/core'
import { EnvironmentService } from '../../singleton-services/environment.service'
import { AngularFireModule, FIREBASE_APP_NAME, FIREBASE_OPTIONS } from '@angular/fire/compat'
import { AngularFireDatabaseModule } from '@angular/fire/compat/database/'

export function firebaseConfigLoader(env: EnvironmentService) {
  return env.config.firebase
}

@NgModule({
  imports: [AngularFireModule, AngularFireDatabaseModule],
  exports: [AngularFireModule, AngularFireDatabaseModule],
  providers: [
    {
      provide: FIREBASE_APP_NAME,
      useValue: 'universal-webapp'
    },
    {
      provide: FIREBASE_OPTIONS,
      useFactory: firebaseConfigLoader,
      deps: [EnvironmentService]
    }
  ]
})
export class FirebaseModule {
  static forRoot(): ModuleWithProviders<FirebaseModule> {
    return {
      ngModule: FirebaseModule
    }
  }
}
