import { NgModule } from '@angular/core'
import { StaticContentCardComponent } from './static-content-card.component'
import { CommonModule } from '@angular/common'
import { AnalyticsModule } from '../../../shared/analytics/analytics.module'
import { ThumbnailModule } from '../../../shared/thumbnails/thumbnail.module'
import { FloPipeModule } from '../../../shared/pipes/flo-pipe.module'
import { CustomLinkModule } from '../../custom-link/custom-link.directive.module'

@NgModule({
  imports: [CommonModule, AnalyticsModule, ThumbnailModule, FloPipeModule, CustomLinkModule],
  declarations: [StaticContentCardComponent],
  exports: [StaticContentCardComponent]
})
export class StaticContentCardModule {}
