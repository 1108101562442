import { Pipe, PipeTransform } from '@angular/core'

/**
 * Transforms BFF style into a valid css class
 * by replacing colons with dashes
 */
@Pipe({ name: 'floCssClassSanitize' })
export class FloCssClassSanitizePipe implements PipeTransform {
  public transform(style?: string | null) {
    return style?.split(':').join('-')
  }
}
