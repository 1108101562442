<footer class="footer" data-test="footer">
  <div class="container p-0">
    <div class="d-block d-lg-flex justify-content-between flex-row-reverse">
      <div class="d-flex gap-2 justify-content-center pb-md" data-test="footer-social-icons">
        <div *ngFor="let link of socialLinks$ | async; trackBy: trackBySocialLink">
          <a
            *ngIf="link.href"
            target="_blank"
            rel="noopener"
            floAnalytics
            eventName="Social link in footer clicked"
            [name]="link.name"
            [analyticsProperties]="analyticsProperties"
            [href]="link.href">
            <flo-svg-icon class="grey-500" [icon]="link.svg" width="1.3em"></flo-svg-icon>
          </a>
        </div>
      </div>
      <div class="d-flex caption flex-wrap justify-content-center justify-content-lg-start footer-links" data-test="footer-links">
        <div *ngFor="let link of links; trackBy: trackByLink">
          <a class="text-nowrap" [href]="link?.href" target="_blank" rel="noopener">{{ link?.name }}</a>
        </div>
        <div>
          <a data-test="footer-cookie-settings"
             href="#"
             (click)="openOsanoDrawer($event)">Cookie Preferences / Do Not Sell or Share My Personal Information</a>
        </div>
      </div>
    </div>
    <div class="caption text-center text-xxl-start mt-lg-3 mt-2" data-test="copyright">
      <p>&copy;2006 - Present FloSports, Inc. All rights reserved.</p>
    </div>
  </div>
</footer>
