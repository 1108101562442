import { parseISO } from 'date-fns'
import { SupplementedUserSubscription } from '../../../account-settings/account-settings.interfaces'
import { MinimalSubscription } from 'src/app/shared/subscriptions/subscriptions.interface'

/**
 * Look for a users' most recent stripe plan, so they can use their saved card to pay again.
 */
export const getReactivationSubscription = (
  allUserSubscriptions: ReadonlyArray<SupplementedUserSubscription | MinimalSubscription> | undefined,
  siteId: number | undefined
): SupplementedUserSubscription | MinimalSubscription | undefined => {
  return !allUserSubscriptions || !siteId
    ? undefined
    : allUserSubscriptions
        .filter(sub => sub.is_expired && sub.card_last4 && sub.is_stripe && sub.site_id === siteId)
        .sort((a, b) => parseISO(b.end_date).getTime() - parseISO(a.end_date).getTime())
        .shift()
}

/**
 * Get error message from several possible nested values, with default message
 */
export function getErrorMessage(err: unknown): string {
  const defaultError =
    'An error occurred while processing your payment. You were not charged. Please try again or contact support.'

  if (!err) {
    return defaultError
  }
  if (typeof err === 'object') {
    if ('message' in err) {
      return (err as { message: string }).message as string
    }

    if ('error' in err) {
      const errType = err as { error: Record<string, string | Record<string, string>> }
      if (errType.error.message) {
        return (err as { error: { message: string } }).error.message
      }
      if (errType.error.errors) {
        return (err as { error: { errors: { message: string } } }).error.errors.message
      }
    }
  }

  return defaultError
}
