import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { LoggingService } from 'src/app/singleton-services/logging/logging.service'
import { FloApiResponse, Meta } from '../shared/models/api-response.model'
import { IVideo } from '../shared/models/video.model'
import { ISearchQueryParams } from '../shared/models/search-query-params.interface'
import { IVideoSearchQueryParams } from '../shared/models/video-search-query-params.interface'
import { Observable, of } from 'rxjs'
import { catchError, shareReplay } from 'rxjs/operators'
import {
  EXPERIENCE_API_LEGACY_CORE_SEARCH_PATH,
  EXPERIENCE_API_LEGACY_CORE_SEARCH_EVENTS_PATH,
  EXPERIENCE_API_VERSION
} from '../app.config'

/**
 * @deprecated Use SearchDataService instead
 * @see {SearchDataService}
 */
export interface ISearchService {
  search(inputParams: ISearchQueryParams): Observable<FloApiResponse<any>>
}

/**
 * *@deprecated Use SearchDataService instead
 * @see {SearchDataService}
 */
@Injectable({
  providedIn: 'root'
})
export class SearchService implements ISearchService {
  constructor(private httpClient: HttpClient, private loggingService: LoggingService) {}

  public search<NodeType>(
    inputParams: ISearchQueryParams
  ): Observable<FloApiResponse<NodeType, Meta & { extra?: ReadonlyArray<any>; error?: any }>> {
    const defaultParams = {
      limit: '12',
      published_only: 1,
      engine: 'cloudsearch',
      version: EXPERIENCE_API_VERSION
    }
    const fromObject: any = {
      ...defaultParams,
      ...inputParams
    }
    if (typeof inputParams.facets === 'object') {
      fromObject.facets = Object.keys(inputParams.facets).length < 1 ? undefined : JSON.stringify(inputParams.facets)
    }
    const params = new HttpParams({ fromObject })
    return this.httpClient.get<FloApiResponse<NodeType, Meta>>(EXPERIENCE_API_LEGACY_CORE_SEARCH_PATH, { params }).pipe(
      catchError(response => {
        this.loggingService.error(response.error.meta.message, response.error)
        return of({
          data: undefined,
          meta: response.error.meta as Meta & {
            extra?: ReadonlyArray<any>
            error?: any
          }
        } as FloApiResponse<any, any>)
      }),
      shareReplay(1)
    )
  }

  /**
   * Possible bug in Universal?
   *
   * Making 2 requests to the search api using the same base url yields the
   * same response when made on page load, even with different query params.
   * The requests work as expected when navigating in the app. Omitting the
   * trailing slash from the url on one of the requests yields the
   * expected response
   */
  public getFacets<NodeType>(
    inputParams: ISearchQueryParams
  ): Observable<FloApiResponse<NodeType, Meta & { extra?: ReadonlyArray<any>; error?: any }>> {
    const defaultParams = {
      limit: '12',
      published_only: 1,
      version: EXPERIENCE_API_VERSION
    }
    const fromObject: any = {
      ...defaultParams,
      ...inputParams,
      metadata_only: 1
    }
    const params = new HttpParams({ fromObject })
    return this.httpClient.get<FloApiResponse<NodeType, Meta>>(EXPERIENCE_API_LEGACY_CORE_SEARCH_PATH, { params }).pipe(
      catchError(response => {
        this.loggingService.error(response.error.meta.message, response.error)
        return of({
          data: undefined,
          meta: response.error.meta as Meta & {
            extra?: ReadonlyArray<any>
            error?: any
          }
        } as FloApiResponse<any, any>)
      }),
      shareReplay(1)
    )
  }

  public getEventFacets(
    eventNodeId: number | string,
    inputParams?: IVideoSearchQueryParams
  ): Observable<FloApiResponse<ReadonlyArray<IVideo>, Meta & { extra?: ReadonlyArray<any>; error?: any }>> {
    const defaultParams = {
      limit: '12',
      published_only: 1,
      version: EXPERIENCE_API_VERSION
    }
    const fromObject: any = {
      ...defaultParams,
      ...inputParams,
      metadata_only: 1
    }
    const params = new HttpParams({ fromObject })
    return this.httpClient
      .get<FloApiResponse<ReadonlyArray<IVideo>, Meta>>(
        `${EXPERIENCE_API_LEGACY_CORE_SEARCH_EVENTS_PATH}/${eventNodeId}/videos`,
        { params }
      )
      .pipe(
        catchError(response => {
          this.loggingService.error(response.error.meta.message, response.error)
          return of({
            data: undefined,
            meta: response.error.meta as Meta & {
              extra?: ReadonlyArray<any>
              error?: any
            }
          } as FloApiResponse<any, any>)
        })
      )
  }
}
