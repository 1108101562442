<h1>{{ title }}</h1>
<div *ngIf="view$ | async as view">
  <flo-video-scrubber-control
    *ngIf="isVOD"
    [currentTime]="view?.currentTime"
    [bufferAmount]="view?.bufferAmount"
    [duration]="view?.duration"
    [eventAnalytics]="view?.analytics"
    (pauseVideoOnSeeking)="_pauseVideoOnSeeking($event)"
    (videoPositionChange)="_videoPositionChange($event)"
  ></flo-video-scrubber-control>
  <div class="controls-container">
    <div class="left pointer-ok" data-test="vid-controls">
      <div *ngIf="isVOD" class="video-duration-container font-monospace fw-bold">
        <span class="video-current-time">{{
          view?.videoCurrentTime | floTime
        }}</span>
        /
        <span class="video-duration">{{ view?.videoDuration | floTime }}</span>
      </div>
      <div *ngIf="config.showLiveIndicator" [ngSwitch]="isLive" id="live-bdg">
        <i
          *ngSwitchCase="false"
          floSvg="badge_live"
          title="video is not live"
        ></i>
        <i *ngSwitchCase="true" floSvg="badge_live" title="video is live"></i>
      </div>
      <div *ngIf="isVOD">
        <i
          floAnalytics
          tagName="Player Control"
          events="click"
          [analyticsProperties]="view.analyticPropsSkipBack"
          floSvg="back_10"
          title="skip backwards"
          (click)="_clickedSkipBackward()"
        ></i>
      </div>
      <div>
        <i
          floAnalytics
          tagName="Player Control"
          events="click"
          [analyticsProperties]="view.analyticPropsPlayBtn"
          [hidden]="view?.isVideoPlaying"
          floSvg="vid_pause"
          title="pause video"
          (click)="_clickedPauseIcon()"
        ></i>
        <i
          floAnalytics
          tagName="Player Control"
          events="click"
          [analyticsProperties]="view.analyticPropsPlayBtn"
          [hidden]="!view?.isVideoPlaying"
          floSvg="vid_play"
          title="play video"
          (click)="_clickedPlayIcon()"
        ></i>
      </div>
      <div *ngIf="isVOD">
        <i
          floAnalytics
          tagName="Player Control"
          events="click"
          [analyticsProperties]="view.analyticPropsSkipForward"
          floSvg="forward_10"
          title="skip forward"
          (click)="_clickedSkipForward()"
        ></i>
      </div>
      <flo-video-volume-control
        [volumeState]="view?.volumeState"
        (volumeView)="setVolumeState($event)"
        [eventAnalytics]="view?.analytics"
      ></flo-video-volume-control>
    </div>
    <div class="right pointer-ok">
      <flo-video-quality-control
        [eventAnalytics]="view?.analytics"
        (qualityChanged)="videoQualityChange($event)"
        [qualityControlState]="view?.qualityControl"
        #clickOutAvoid
      ></flo-video-quality-control>
      <i
        *floIfNotFullscreen="fullScreenElement"
        [floClickToEnterFullscreen]="fullScreenElement"
        floAnalytics
        tagName="Player Control"
        events="click"
        [analyticsProperties]="view?.analyticPropsFullScreen"
        floSvg="expand"
        title="to fullscreen"
      ></i>
      <i
        *floIfFullscreen="fullScreenElement"
        floClickToExitFullscreen
        floAnalytics
        tagName="Player Control"
        events="click"
        [analyticsProperties]="view?.analyticPropsFullScreen"
        floSvg="collapse"
        title="minimize fullscreen"
      ></i>
    </div>
  </div>
</div>
