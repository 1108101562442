import { Injectable, Injector } from '@angular/core'
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { throwError } from 'rxjs'
import { AuthService } from '../../singleton-services/auth/auth.service'
import { Router } from '@angular/router'
import { catchError } from 'rxjs/operators'

interface IUrlMethod {
  url: string
  method: string
}

interface IApiMethodException {
  method: string
  regex: RegExp
}

type ApiMethodExceptions = ReadonlyArray<IApiMethodException>

const excludeByApiUrlAndMethod = (req: IUrlMethod, exceptions: ApiMethodExceptions): boolean => {
  return exceptions.some(exception => exception.method === req.method.toUpperCase() && exception.regex.test(req.url))
}

const excludeByList = (list: ReadonlyArray<RegExp>, str: string) => {
  return list.some(i => i.test(str))
}

const excludedByPageUrl = (url: string) => {
  return excludeByList([/login/, /claim-account/, /account\/confirm-upgrade/], url)
}

const excludedByApiUrl = (url: string) => {
  return excludeByList([/\/tix/], url)
}

const API_REDIRECT_EXCLUSIONS_BY_METHOD: ApiMethodExceptions = [
  {
    method: 'GET',
    regex: /entitlements\..+\//i
  },
  {
    method: 'DELETE',
    regex: /live-api.*\/tokens\//i
  },
  {
    method: 'POST',
    regex: /live-api.*\/tokens\/preview/i
  }
]

@Injectable()
export class HttpForbiddenInterceptorService implements HttpInterceptor {
  constructor(private injector: Injector, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authService = this.injector.get(AuthService)
    return next.handle(req).pipe(
      catchError(err => {
        if (
          err instanceof HttpErrorResponse &&
          err.status === 403 &&
          !(
            excludedByPageUrl(this.router.url) ||
            excludedByApiUrl(req.url) ||
            excludeByApiUrlAndMethod(req, API_REDIRECT_EXCLUSIONS_BY_METHOD)
          )
        ) {
          authService.logout('/login')
        }
        return throwError(err)
      })
    )
  }
}
