import { Injectable } from '@angular/core'
import { AuthService } from './auth/auth.service'
import {
  map,
  shareReplay,
  startWith,
  distinctUntilChanged,
  switchMap,
  catchError,
  take,
  mergeMap
} from 'rxjs/operators'
import { HttpClient } from '@angular/common/http'
import { FloApiResponse } from '../shared/models/api-response.model'
import { SupplementedUserSubscription, UserData } from '../account-settings/account-settings.interfaces'
import { Observable, Subject, of, combineLatest, EMPTY } from 'rxjs'
import { findUserSubscriptionMatch, supplementAllUserSubscriptions } from '../shared/subscriptions/subscriptions.common'
import { Router, ActivatedRoute } from '@angular/router'
import { filterDeepRouterDataByPredicate } from '../shared/rx-pipes/router'
import { SubscriptionService } from '../shared/services/subscription.service'
import { MinimalSubscription } from '../shared/subscriptions/subscriptions.interface'
import { CORE_API_USERS_PATH } from 'src/app/app.config'

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  constructor(
    private readonly auth: AuthService,
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly ar: ActivatedRoute,
    private readonly subscriptionService: SubscriptionService
  ) {}
  private userId$ = this.auth.userIdentity$.pipe(
    map(userIdentity => userIdentity && userIdentity.id),
    shareReplay(1)
  )
  private initialUserData$: Observable<UserData | undefined> = this.userId$.pipe(
    switchMap(userId => this.getUserData(userId))
  )
  private userDataSource = new Subject<UserData>()
  public userData$: Observable<UserData | undefined> = this.initialUserData$.pipe(
    mergeMap(initialUserData => {
      return this.userDataSource.pipe(startWith(initialUserData))
    }),
    distinctUntilChanged(),
    shareReplay(1)
  )

  /**
   * GET /users/{id}/subscriptions from payments service using SubscriptionsService
   */
  private newSubscriptions$: Observable<MinimalSubscription[]> = this.auth.userIdentity$.pipe(
    map(userIdentity => userIdentity && userIdentity.id),
    switchMap(userId => {
      if (!userId) return of([])
      return this.subscriptionService.getAllSubscriptions(userId)
    }),
    map(subs => subs.filter(sub => sub !== undefined) as MinimalSubscription[]),
    take(1)
  )

  /**
   * Combine all subscriptions from payments service and legacy core API and sort
   */
  public allUserSubscriptions$ = combineLatest([
    this.userData$.pipe(
      map(userData => userData && userData.user_subscriptions),
      map(subs => subs && supplementAllUserSubscriptions(subs))
    ),
    this.newSubscriptions$
  ]).pipe(
    // sort subscriptions regardless of source, most recent first
    map(([userSubs = [], newSubs]) => [...userSubs, ...newSubs].sort((a, b) => (b.end_date < a.end_date ? -1 : 1))),
    distinctUntilChanged()
  )

  public activeSubscription$ = this.allUserSubscriptions$.pipe(map(findActiveSub), shareReplay(1))

  // TECH DEBT (FLO-11319): Temporarily needed until v=mobile issues are solved
  public oldAccountSettings$ = combineLatest(
    this.router.events.pipe(
      filterDeepRouterDataByPredicate(this.ar)(a => a.params),
      startWith(undefined)
    ),
    this.ar.queryParams,
    (routeData: any, params: any) => {
      let routeSnapshot = this.ar.snapshot
      while (routeSnapshot.firstChild) {
        routeSnapshot = routeSnapshot.firstChild
      }

      // for mobile view
      let view = 'account'
      if (params.v || routeData) {
        view = params.v ? params.v : routeData.v
      }

      return {
        view
      }
    }
  )

  /**
   * Match a routeId (/account/subscriptions/:id) to the appropriate user subscription.
   * routeId must be passed from whatever component needs it because race conditions exist when trying to do that in the service.
   */
  public matchRouteIdToUserSubscription = (
    routeId: number
  ): Observable<SupplementedUserSubscription | MinimalSubscription | undefined> => {
    return this.allUserSubscriptions$.pipe(
      map(subscriptions => subscriptions && findUserSubscriptionMatch(subscriptions, routeId)),
      distinctUntilChanged(),
      shareReplay(1)
    )
  }

  private getUserData = (userId?: string): Observable<UserData | undefined> => {
    if (!userId) return of(undefined)

    return this.http.get<FloApiResponse<UserData>>(`${CORE_API_USERS_PATH}/${userId}`).pipe(
      map(res => res.data),
      catchError(() => EMPTY)
    )
  }

  public updateUserData = (userData: UserData) => {
    this.userDataSource.next(userData)
  }
}

export const findActiveSub = (subs?: ReadonlyArray<SupplementedUserSubscription | MinimalSubscription>) => {
  return subs && subs.find((sub: SupplementedUserSubscription) => sub.is_active)
}
