<flo-modal [active]="expanded" [width]="1600" (isActive)="closeWindow()">
  <div class="experimentation-widget">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <h2>FloTest Experiments</h2>
      <button class="btn-secondary btn" (click)="onClickClear()"
        >Clear Variations</button
      >
    </div>
    <ul *ngIf="experiments$ | async as experiments">
      <li
        *ngFor="let experiment of experiments; trackBy: trackByExp"
        class="d-flex mb-1 row"
      >
        <div class="d-flex col-6 btn-group">
          <button
            *ngFor="
              let variation of experiment.variations;
              trackBy: trackByVariation
            "
            class="btn-secondary btn"
            type="button"
            [id]="experimentId(experiment.id, variation.variationId)"
            (click)="onClick(experiment.id, variation.variationId)"
            [ngClass]="{
              active: variation.variationId === experiment.variationId
            }"
          >
            {{ variation.variationName }}
          </button>
        </div>
        <div class="d-flex col-6 flex-column title">
          <strong>{{ experiment.name || experiment.id }}</strong>
          <small>?ab-forced={{experiment.id}}-{{experiment.variationId}}</small>
        </div>
      </li>
    </ul>
  </div>
</flo-modal>
