import { AuthService } from '../../singleton-services/auth/auth.service'
import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router'
import { SegmentService } from '../analytics/services/segment.service'
import { Observable, of } from 'rxjs'
import { tap, take, flatMap } from 'rxjs/operators'

// This guard is in place because we need a /logout route for MileSplit
@Injectable()
export class LogoutGuard implements CanActivate {
  constructor(private auth: AuthService, private segment: SegmentService) {}

  canActivate(ars: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.loggedIn$.pipe(
      take(1),
      flatMap(isLoggedIn => {
        if (isLoggedIn) {
          this.segment.handleLogout()
        }

        return of(false).pipe(tap(() => this.auth.logout(ars.queryParams.next)))
      })
    )
  }
}
